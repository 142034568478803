define("adept-iq/pods/components/reoptimize-modal/operation-views/adjust-driver-break/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['adjust-driver-break'],
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    isTaskRunning: Ember.computed.alias('adjustDriverBreak.isRunning'),
    // Passed In Attributes
    title: null,
    currentSchedule: null,
    tableRef: null,
    refreshData: null,
    checkedRows: Ember.computed.alias('tableRef.checkedRows'),
    disableAdjustDriverBreakButton: Ember.computed.lt('checkedRows.length', 1),

    init() {
      this._super(...arguments);
    },

    // be able to adjust from selected rows
    adjustDriverBreak: (0, _emberConcurrency.task)(function* (routes) {
      const id = this.get('currentSchedule.id');
      const store = this.get('store');
      const scheduleAdapter = store.adapterFor('schedule');
      const routeId = routes.map(route => parseInt(route.get('id'), 10));
      yield scheduleAdapter.adjustDriverBreak(id, routeId).then(() => {
        this.get('notifications').success('Driver breaks successfully adjusted');
        return routes.forEach(record => {
          record.set('isAdjusted', true);
        });
      }).catch(() => {
        this.get('notifications').warning('Driver breaks failed to be adjusted');
      });
    }).drop(),
    actions: {
      onAdjustDriverBreakClick() {
        const routes = this.get('checkedRows') || [];
        this.adjustDriverBreak.perform(routes);
      }

    }
  });

  _exports.default = _default;
});