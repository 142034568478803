define("adept-iq/pods/components/form-components/enum/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-select'],
    value: null,
    disabled: false,
    extra: null,
    store: Ember.inject.service(),
    dataFromModel: false,
    options: Ember.computed.alias('extra.options'),
    allowMultiple: Ember.computed.alias('extra.allowMultiple'),
    optionModelName: Ember.computed.alias('extra.optionModelName'),
    optionDisplayName: Ember.computed.alias('extra.optionDisplayName'),

    init() {
      this._super(...arguments);

      if (!Ember.isNone(this.get('extra.optionModelName'))) {
        this.set('dataFromModel', true);
        this.set('options', this.get('store').peekAll(this.get('extra.optionModelName')));
      }
    },

    selected: Ember.computed('value', 'options', function () {
      if (!Ember.isNone(this.get('extra.optionModelName')) && !Ember.isNone(this.get('options'))) {
        return this.get('options').find(option => {
          return option.id === this.get('value.id');
        });
      }

      return false;
    }),
    actions: {
      onChange(option) {
        this.get('onChange')(option);
      }

    }
  });

  _exports.default = _default;
});