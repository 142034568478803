define("adept-iq/pods/components/side-drawer/system-config/modals/assign-remove-zones/assign-zone-table/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/side-drawer/system-config/modals/assign-remove-zones/assign-zone-table/config"], function (_exports, _component, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['zone-table'],
    config: _config.default,
    store: Ember.inject.service(),
    hideWidgetControls: true,
    // passed in from parent component
    routeTemplateChangeSet: null,

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    fetchDataQuery() {
      const affinities = this.get('routeTemplateChangeSet.affinities') || [];
      return this._super(...arguments).then(records => {
        return records.filter(record => {
          const affVal = affinities[record.get('id')];
          record.set('affinityValue', affVal);
          return Ember.isPresent(affVal);
        });
      });
    }

  });

  _exports.default = _default;
});