define("adept-iq/pods/components/iq-widgets/zones-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/zones-widget/config"], function (_exports, _component, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['zones-widget', 'data-test-zones-widget'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    config: _config.default,

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);
    }

  });

  _exports.default = _default;
});