define("adept-iq/pods/login/route", ["exports", "lodash", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _lodash, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    setupController(controller
    /*, model*/
    ) {
      controller.set('data', {});
    },

    actions: {
      login(userName, password) {
        const session = this.get('session');
        const authenticator = 'authenticator:sso';
        this.get('flashMessages').clearMessages();
        session.authenticate(authenticator, userName, password).then(() => {
          this.transitionTo().abort();
          this.get('workspace').initUserRecord().then(() => this.store.findAll('cs-config-permission')).then(configPermissions => {
            this.set('workspace.configPermissions', configPermissions.get('firstObject.value'));
            localStorage.setItem('currentUser', userName);
            const userDash = localStorage.getItem('userDash');

            if (!userDash) {
              localStorage.setItem('userDash', `${userName}*null`);
              localStorage.removeItem('lastDashboardId');
            } else {
              const arr = userDash.split(',');

              const user = _lodash.default.find(arr, users => {
                return users.split('*')[0] === userName;
              });

              if (!user) {
                arr.push(`${userName}*null`);
                localStorage.setItem('userDash', arr);
                localStorage.removeItem('lastDashboardId');
              } else {
                localStorage.setItem('lastDashboardId', user.split('*')[1]);
              }
            }

            this.transitionTo('index');
          }).catch(e => {
            let message;

            switch (e.message) {
              case 'Unauthorized':
                message = 'Email or password is not correct.';
                break;

              case 'Disabled':
                message = 'Your account is disabled. Please contact your system administrator.';
                break;

              default:
                message = '';
            }

            if (Ember.isPresent(message)) {
              return this.set('controller.data.errors', [message]);
            }

            throw e;
          });
        }).catch(e => {
          let message;

          switch (e.message) {
            case 'Unauthorized':
              message = 'Email or password is not correct.';
              break;

            case 'Disabled':
              message = 'Your account is disabled. Please contact your system administrator.';
              break;

            default:
              message = '';
          }

          if (Ember.isPresent(message)) {
            return this.set('controller.data.errors', [message]);
          }

          throw e;
        });
      }

    }
  });

  _exports.default = _default;
});