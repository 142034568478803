define("adept-iq/models/dashboard", ["exports", "ember-data", "adept-iq/classes/dashboard-instance", "lodash", "moment", "adept-iq/config/mapped-permIds"], function (_exports, _emberData, _dashboardInstance, _lodash, _moment, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SCHEDULE_WORSPACE_RESTRICT_WIDGETS = _exports.PLAYBACK_ROLENAME = _exports.ROAD_SUPERVISOR_ROLENAME = _exports.RECONCILE_ROLENAME = _exports.BOOK_ROLENAME = _exports.SCHEDULE_ROLENAME = _exports.DISPATCH_ROLENAME = void 0;
  const {
    Model,
    attr
  } = _emberData.default;
  const DISPATCH_ROLENAME = 'Dispatch';
  _exports.DISPATCH_ROLENAME = DISPATCH_ROLENAME;
  const SCHEDULE_ROLENAME = 'Schedule';
  _exports.SCHEDULE_ROLENAME = SCHEDULE_ROLENAME;
  const BOOK_ROLENAME = 'Book';
  _exports.BOOK_ROLENAME = BOOK_ROLENAME;
  const RECONCILE_ROLENAME = 'Reconcile';
  _exports.RECONCILE_ROLENAME = RECONCILE_ROLENAME;
  const ROAD_SUPERVISOR_ROLENAME = 'Road Supervisor';
  _exports.ROAD_SUPERVISOR_ROLENAME = ROAD_SUPERVISOR_ROLENAME;
  const PLAYBACK_ROLENAME = 'Playback';
  _exports.PLAYBACK_ROLENAME = PLAYBACK_ROLENAME;
  const CLONEABLE_PROPERTIES = ['name', 'role', 'startDate', 'endDate', 'referenceDate', 'mapState', 'widgets'];
  const SCHEDULE_WORSPACE_RESTRICT_WIDGETS = ['messages', 'alerts'];
  _exports.SCHEDULE_WORSPACE_RESTRICT_WIDGETS = SCHEDULE_WORSPACE_RESTRICT_WIDGETS;

  var _default = Model.extend({
    workspace: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    name: attr('string', {
      defaultValue: 'Untitled'
    }),
    role: attr('string', {
      defaultValue: DISPATCH_ROLENAME
    }),
    // 'workspaces-{users,default,admin}'; can't be changed once saved
    category: attr('string', {
      defaultValue: 'workspaces-users'
    }),
    // absolute timeframe as selected by user
    startDate: attr('date', {
      defaultValue: () => (0, _moment.default)().startOf('day').toDate()
    }),
    endDate: attr('date', {
      defaultValue: () => (0, _moment.default)().startOf('day').add(1, 'day').toDate()
    }),
    // when `startDate` or `endDate` is modified, store the user's current date
    // so that the relative timeframe can be calculated from a later date
    referenceDate: attr('date', {
      defaultValue: () => (0, _moment.default)().startOf('day').toDate()
    }),
    mapState: attr('object', {
      defaultValue: () => {}
    }),
    widgets: attr('object', {
      defaultValue: () => []
    }),
    isDefaultWorkspace: Ember.computed.equal('category', 'workspaces-default'),
    isScheduleDashboard: Ember.computed.equal('role', SCHEDULE_ROLENAME),
    isDispatchDashboard: Ember.computed.equal('role', DISPATCH_ROLENAME),
    isPlaybackWorkspace: Ember.computed.equal('category', 'workspaces-playback'),

    configureWidgetsOnPermission() {
      const configPermissions = this.get('workspace.configPermissions');
      const widgets = [];

      if (!Ember.isEmpty(this.get('widgets'))) {
        this.get('widgets').forEach(widget => {
          let configPermission, widgetPermType;
          let permWidgetGranted = false;

          if (!Ember.isNone(configPermissions)) {
            widgetPermType = widget.typeId;
            configPermission = configPermissions[widget.typeId];
          }

          switch (widgetPermType) {
            case 'trips':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showTrips, null);
              break;

            case 'stops':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showStops, null);
              break;

            case 'routes':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showRoutes, null);
              break;

            case 'map':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showMaps, null);
              break;

            case 'alerts':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showAlerts, null);
              break;

            case 'drivers':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showDrivers, null);
              break;

            case 'vehicles':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showVehicles, null);
              break;

            case 'passengers':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showPassengers, null);
              break;

            case 'messages':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showMessages, null);
              break;

            case 'subscriptions':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showSubscriptions, null);
              break;

            case 'users':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showUsers, null);
              break;

            case 'zones':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showZones, null);
              break;

            case 'schedule-metrics':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showSchedule, null);
              break;

            default:
              permWidgetGranted = false;
          }

          if (!Ember.isNone(configPermission) && configPermission.show && permWidgetGranted) {
            widgets.push(widget);
          }
        });
      }

      return widgets;
    },

    configureWidgetsOnWorkspace(widgets) {
      const role = this.get('role');

      if (!Ember.isEmpty(this.get('widgets'))) {
        return widgets.filter(widget => {
          if (widget.blackListedWorkspaces) {
            return widget.blackListedWorkspaces[role];
          }

          return true;
        });
      }

      return widgets;
    },

    makeInstance() {
      let widgets = this.configureWidgetsOnPermission();
      widgets = this.configureWidgetsOnWorkspace(widgets);

      const instance = _dashboardInstance.default.deserialize({
        schemaVersion: 1,
        attributes: {
          id: this.get('id'),
          name: this.get('name'),
          role: this.get('role'),
          startDate: this.get('startDate'),
          endDate: this.get('endDate'),
          referenceDate: this.get('referenceDate'),
          mapState: this.get('mapState'),
          widgets: widgets
        }
      });

      instance.set('model', this);
      return instance;
    },

    commitInstance(instance) {
      const {
        attributes
      } = instance.serialize();
      const {
        name,
        role,
        startDate,
        endDate,
        referenceDate,
        mapState,
        widgets
      } = attributes;
      this.setProperties({
        name,
        role,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        referenceDate: new Date(referenceDate),
        mapState,
        widgets
      });
    },

    getOptionsForClone() {
      return _lodash.default.cloneDeep(this.getProperties(CLONEABLE_PROPERTIES));
    }

  });

  _exports.default = _default;
});