define("adept-iq/mixins/async-schedule-operation", ["exports", "ember-concurrency", "adept-iq/config/api-urls"], function (_exports, _emberConcurrency, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const scheduleHost = _apiUrls.API.schedulingService.host;

  var _default = Ember.Mixin.create({
    createScheduleAsyncOperation: (0, _emberConcurrency.task)(function* (schedule, operation) {
      const session = this.get('session');
      const op = {
        'data': {
          'type': 'scheduleAsyncOperation',
          'attributes': {
            'operationType': operation
          },
          'relationships': {
            'schedule': {
              'data': {
                'type': 'schedule',
                'id': schedule.get('id')
              }
            }
          }
        }
      };
      const job = yield this.get('ajax').post(scheduleHost + '/schedule-async-operation', {
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: op
      });
      if (!job) return false;
      return yield this.get('asyncOperationState').perform(job);
    }).enqueue(),
    asyncOperationState: (0, _emberConcurrency.task)(function* (job) {
      const session = this.get('session');

      while (job.data.attributes.state !== 'success' && job.data.attributes.state !== 'failure') {
        const delay = this.get('timeout');
        yield (0, _emberConcurrency.timeout)(delay);

        try {
          // eslint-disable-next-line no-param-reassign
          job = yield this.get('ajax').request(scheduleHost + '/schedule-async-operation/' + job.data.id, {
            method: 'GET',
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            }
          });
        } catch (e) {
          throw e;
        }
      }

      return job.data.attributes.state === 'success';
    }).drop()
  });

  _exports.default = _default;
});