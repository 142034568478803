define("adept-iq/pods/components/table/cells/check-box/component", ["exports", "adept-iq/pods/components/table/cells/base-cell/component", "adept-iq/utils/mobile"], function (_exports, _component, _mobile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['table-check-box-cell', 'data-test-table-check-box-cell'],
    classNameBindings: ['isTouchDevice'],

    init() {
      this._super(...arguments);

      Ember.set(this, 'isTouchDevice', _mobile.isTouchDevice);
    },

    activeContext: Ember.inject.service(),
    isChecked: Ember.computed.alias('row.isChecked'),
    isAlertCheckBox: Ember.computed.alias('row.isAlertCheckBox'),
    onHidden: Ember.observer('row.hidden', function () {
      const hidden = this.get('row.hidden');

      if (hidden) {
        // Unchecked row when row is hidden.
        this.send('onToggleCheckbox', {
          target: {
            checked: false
          }
        });
      }
    }),
    // click(e) {
    //   // if (isTouchDevice && this.row.selected && this.extra.onRightClick) {
    //   //   this.extra.onRightClick(this.row, e);
    //   // } else {
    //     this.onToggleCheckbox(e);
    //   // }
    // },
    actions: {
      onToggleCheckbox(event) {
        const row = this.get('row');
        const rows = this.get('table.rows');
        const lastCheckedRow = rows.findBy('isLastChecked', true);
        const affectedRows = [];

        if (this.get('row').content._internalModel.modelName === 'avlm-alert') {
          for (let i = 0; i < rows.length; i++) {
            if (i === rows.indexOf(row)) {
              console.log('checked row is ', row); // eslint-disable-line no-console
            } else {
              rows.objectAt(i).set('isAlertCheckBox', event.target.checked);
            }

            affectedRows.push(rows.objectAt(i));
          }
        }

        if (event.shiftKey && lastCheckedRow) {
          const lastCheckedIndex = rows.indexOf(lastCheckedRow);
          const checkedIndex = rows.indexOf(row);
          const start = Math.min(checkedIndex, lastCheckedIndex);
          const end = Math.max(checkedIndex, lastCheckedIndex);

          for (let i = start; i <= end; i++) {
            rows.objectAt(i).set('isChecked', event.target.checked);
            rows.objectAt(i).set('selected', event.target.checked);
            affectedRows.push(rows.objectAt(i));
          }
        } else {
          row.set('isChecked', event.target.checked);
          row.set('selected', event.target.checked);
          affectedRows.push(row);
        }

        rows.setEach('isLastChecked', false);
        row.set('isLastChecked', true);
      }

    }
  });

  _exports.default = _default;
});