define("adept-iq/pods/components/form-components/datetimeflatpickr/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xSnbgRb/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"tr\"],[11,\"class\",\"datepickr-tr\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"label\"]]],null,{\"statements\":[[0,\"    \"],[7,\"td\"],[11,\"class\",\"cell-label\"],[9],[1,[21,\"label\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"td\"],[11,\"class\",\"edit-cell\"],[9],[0,\"\\n    \"],[1,[27,\"ember-flatpickr\",null,[[\"locale\",\"minuteIncrement\",\"allowInput\",\"dateFormat\",\"readonly\",\"clickOpens\",\"enableTime\",\"minDate\",\"maxDate\",\"date\",\"onChange\",\"onClose\"],[\"en\",1,true,[23,[\"dateFormat\"]],[23,[\"readonlyCell\"]],[23,[\"clickOpens\"]],true,[23,[\"minDate\"]],[23,[\"maxDate\"]],[23,[\"value\"]],[27,\"action\",[[22,0,[]],\"onChangeDate\"],null],[27,\"action\",[[22,0,[]],\"onClose\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/form-components/datetimeflatpickr/template.hbs"
    }
  });

  _exports.default = _default;
});