define("adept-iq/pods/components/side-drawer/donut-chart-settings/component", ["exports", "adept-iq/pods/components/side-drawer/donut-chart-settings/config", "adept-iq/pods/components/generic-widgets/column-widget/classes/filter-column"], function (_exports, _config, _filterColumn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    widget: Ember.computed.readOnly('workspace.topOptions.widget'),
    dataType: null,
    sourceOptions: null,

    init() {
      this._super(...arguments);

      this.set('sourceOptions', _config.default);
      const dataType = this.get('widget.state.dataType');
      this.set('dataType', dataType);

      if (Ember.isPresent(dataType)) {
        this.set('filterColumns', Object.values(dataType.columns).map(col => new _filterColumn.default(col)));
      }
    },

    actions: {
      onTypeSelect(dataType) {
        this.set('dataType', dataType);
        this.set('filterColumns', Object.values(dataType.columns).map(col => new _filterColumn.default(col)));
        this.get('widget').mergeState({
          dataType
        });
      },

      setVisualOptionVisibility(selected, event) {
        const dataType = this.get('dataType');
        Ember.set(selected, 'isChecked', event.target.checked);
        this.get('widget').mergeState({
          dataType
        });
      },

      onTitleInput(data) {
        this.set('dataType.title', data);
        this.get('widget').mergeState({
          dataType: this.get('dataType')
        });
      },

      setDisplayOptionVisibility(displaySet, selected) {
        Ember.set(displaySet, 'selected', selected); // Don't want to merge `valueCategories`.

        Ember.set(this.get('widget'), 'state.dataType', this.get('dataType'));
        this.get('widget').notifyPropertyChange('state');
      },

      setSortOrder(selected) {
        this.set('dataType.sortOrder.selected', selected);
        this.get('widget').mergeState({
          dataType: this.get('dataType')
        });
      },

      setLegendPlacement(selected) {
        this.set('dataType.legendPlacement.selected', selected);
        this.get('widget').mergeState({
          dataType: this.get('dataType')
        });
      },

      onFilterValueChange(column, index, event) {
        let filterValues = column.get('filterValues') || [];
        filterValues = filterValues.slice();
        filterValues[index] = event.target.value; // Update the displayed filter column.

        Ember.set(column, 'filterValues', filterValues);
        const id = column.get('id'); // Update the column config.

        Ember.set(this.get('dataType'), `columns.${id}.filterValues`, filterValues);
        this.get('widget').mergeState({
          dataType: this.get('dataType')
        });
      },

      onSelectFilterType(column, filterType) {
        // Update the displayed filter column.
        Ember.set(column, 'filterTypeId', filterType.id);
        const id = column.get('id'); // Update the column config.

        Ember.set(this.get('dataType'), `columns.${id}.filterTypeId`, filterType.id);
        this.get('widget').mergeState({
          dataType: this.get('dataType')
        });
      }

    }
  });

  _exports.default = _default;
});