define("adept-iq/services/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    activeContext: Ember.inject.service(),
    editModal: Ember.inject.service(),
    polyline: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    roadSupervisor: Ember.inject.service(),
    // stack
    mapContexts: null,
    // "top" map-context in stack; this is what the map widget shows right now
    topMapContext: Ember.computed.readOnly('mapContexts.lastObject'),
    isEmpty: Ember.computed.equal('mapContexts.length', 0),

    init() {
      this._super(...arguments);

      this.set('mapContexts', []);
    },

    pushContext(mapContext) {
      this.get('mapContexts').pushObject(mapContext);
    },

    popContext() {
      return this.get('mapContexts').popObject();
    },

    removeContext(identifier) {
      const mapContexts = this.get('mapContexts');
      const mapContext = typeof identifier === 'string' ? mapContexts.findBy('id', identifier) : identifier;
      return this.get('mapContexts').removeObject(mapContext);
    },

    // TODO: get rid of these and call them on the map-context directly
    setLayerVisibility(layerId, isVisible) {
      this.get('topMapContext').setLayerVisibility(layerId, isVisible);
    },

    setLayerOpacity(layerId, opacity) {
      this.get('topMapContext').setLayerOpacity(layerId, opacity);
    },

    setLayerTypeVisibility(layerId, typeId, isVisible) {
      this.get('topMapContext').setLayerTypeVisibility(layerId, typeId, isVisible);
    },

    setLayerLabelVisibility(layerId, labelId, isVisible) {
      this.get('topMapContext').setLayerLabelVisibility(layerId, labelId, isVisible);
    },

    setLayerLabelFilterType(layerId, labelId, filterTypeId) {
      this.get('topMapContext').setLayerLabelFilterType(layerId, labelId, filterTypeId);
    },

    setLayerLabelFilterValues(layerId, labelId, filterValues) {
      this.get('topMapContext').setLayerLabelFilterValues(layerId, labelId, filterValues);
    },

    setLayerLabelsOrder(layerId, labelIds) {
      this.get('topMapContext').setLayerLabelsOrder(layerId, labelIds);
    },

    setDisplayMapIconLabelAllTime(layerId, isDisplayMapIconLabel) {
      this.get('topMapContext').setDisplayMapIconLabelAllTime(layerId, isDisplayMapIconLabel);
    }

  });

  _exports.default = _default;
});