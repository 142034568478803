define("adept-iq/pods/components/side-drawer/system-config/system-config-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['g-side-drawer-header'],
    // passed into header
    title: null,
    newRecord: null,
    editRecord: null,
    saveRecord: null,
    deleteRecord: null,
    undoRecord: null,

    init() {
      this._super(...arguments);
    },

    actions: {
      new() {
        if (this.get('newRecord')) this.get('newRecord')();
      },

      edit() {
        if (this.get('editRecord')) this.get('editRecord')();
      },

      save() {
        if (this.get('saveRecord')) this.get('saveRecord')();
      },

      undo() {
        if (this.get('undoRecord')) this.get('undoRecord')();
      },

      delete() {
        if (this.get('deleteRecord')) this.get('deleteRecord')();
      }

    }
  });

  _exports.default = _default;
});