define("adept-iq/models/stop-point", ["exports", "ember-data", "adept-iq/mixins/otp-label", "moment", "lodash", "adept-iq/utils/unwrapProxy", "adept-iq/utils/convertDistance"], function (_exports, _emberData, _otpLabel, _moment, _lodash, _unwrapProxy, _convertDistance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PERFORMED_BREAK_STATUS = _exports.PENDING_STOP_STATUS = _exports.PLANNED_STOP_STATUS = _exports.NOSHOW_STOP_STATUS = _exports.ARRIVE_STOP_STATUS = _exports.PERFORMED_STOP_STATUS = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  const PERFORMED_STOP_STATUS = 'Depart';
  _exports.PERFORMED_STOP_STATUS = PERFORMED_STOP_STATUS;
  const ARRIVE_STOP_STATUS = 'Arrive';
  _exports.ARRIVE_STOP_STATUS = ARRIVE_STOP_STATUS;
  const NOSHOW_STOP_STATUS = 'No Show';
  _exports.NOSHOW_STOP_STATUS = NOSHOW_STOP_STATUS;
  const PLANNED_STOP_STATUS = 'planned';
  _exports.PLANNED_STOP_STATUS = PLANNED_STOP_STATUS;
  const PENDING_STOP_STATUS = 'pending';
  _exports.PENDING_STOP_STATUS = PENDING_STOP_STATUS;
  const PERFORMED_BREAK_STATUS = 'OffBreak';
  _exports.PERFORMED_BREAK_STATUS = PERFORMED_BREAK_STATUS;
  const ANCHOR_DROP_TYPE = 'drop';

  var _default = Model.extend(_otpLabel.default, {
    activeContext: Ember.inject.service(),
    stopType: attr('string'),
    parentType: attr('string'),
    waypointType: attr('string'),
    waypointSource: attr('string'),
    waypoint: attr('string'),
    onBoardCount: attr('number'),
    routeOrdinal: attr('number'),
    clusterOrdinal: attr('number'),
    lockedForOptimization: attr('boolean'),
    arriveTimestamp: attr('date'),
    departTimestamp: attr('date'),
    cluster: belongsTo('cluster'),
    noShow: belongsTo('no-show'),
    trip: belongsTo('trip'),
    tripStop: belongsTo('trip-stop'),
    etaNavStopPoint: belongsTo('etanav-stop-point'),
    routeBreak: belongsTo('route-break'),
    stopRoute: belongsTo('route'),
    dispatchSchedule: belongsTo('dispatch-schedule'),
    polyline: belongsTo('polyline'),
    // contains the polyline from one stop-point to another stop-point
    // populated by route-exec-event
    status: attr('string'),
    otp: attr('string'),
    otpValue: attr('number'),
    odometer: attr('string'),
    arriveLat: attr('string'),
    arriveLng: attr('string'),
    departLat: attr('string'),
    departLng: attr('string'),
    actualArriveTime: attr('date'),
    actualDepartTime: attr('date'),
    noShowTime: attr('date'),
    arrivePerformedBy: attr('string'),
    departPerformedBy: attr('string'),
    // We need to always find the scheduling route information by going through dispatch route, in case the stop has been moved onto a new route
    route: Ember.computed.alias('dispatchRoute.route.content'),
    routeName: Ember.computed.alias('route.name'),
    polylinePoints: Ember.computed.readOnly('polyline.coords'),
    // note `promisedTime` vs. `promiseTime`
    promisedTime: Ember.computed.readOnly('trip.promiseTime'),
    isPick: Ember.computed.equal('stopType', 'pick'),
    isDrop: Ember.computed.equal('stopType', 'drop'),
    isPickOrDrop: Ember.computed.or('isPick', 'isDrop'),
    isGaragePullin: Ember.computed.equal('stopType', 'pullinGarage'),
    isGaragePullout: Ember.computed.equal('stopType', 'pulloutGarage'),
    isGarage: Ember.computed.or('isGaragePullin', 'isGaragePullout'),
    isPerformed: Ember.computed.equal('computedStatus', PERFORMED_STOP_STATUS),
    isBreakPerformed: Ember.computed.equal('computedStatus', PERFORMED_BREAK_STATUS),
    notPerformed: Ember.computed.empty('computedStatus'),
    isNoShow: Ember.computed.equal('computedStatus', NOSHOW_STOP_STATUS),
    isBreak: Ember.computed.equal('stopType', 'break'),

    findPreviousPerformedStop() {
      const orderedStops = this.get('dispatchRoute.orderedStopsWithGaragesAndBreaks');
      if (Ember.isEmpty(orderedStops)) return null;
      const stopPointIndex = this.get('realRouteOrdinal') - 1;

      for (let i = stopPointIndex; i >= 0; i--) {
        const stop = orderedStops[i];

        if (stop.get('isBreakPerformed') || stop.get('depart') || stop.get('isPerformed') || stop.get('isGarage')) {
          return stop;
        }
      }

      return orderedStops[stopPointIndex];
    },

    computedArrivePerformedBy: Ember.computed('arrivePerformedBy', function () {
      const arrivePerformedBy = this.get('arrivePerformedBy');
      return arrivePerformedBy ? `by ${(0, _lodash.upperCase)(arrivePerformedBy)}` : '';
    }),
    computedDepartPerformedBy: Ember.computed('departPerformedBy', function () {
      const departPerformedBy = this.get('departPerformedBy');
      return departPerformedBy ? `by ${(0, _lodash.upperCase)(departPerformedBy)}` : '';
    }),
    computedStatus: Ember.computed('status', 'routeBreak.status', function () {
      const status = this.get('status');
      if (this.get('isBreak')) return this.get('routeBreak.status');
      return status;
    }),
    computedOdometer: Ember.computed('convertedOdometer', 'routeBreak.convertedOdometer', function () {
      const odometer = this.get('convertedOdometer');
      if (this.get('isBreak')) return this.get('routeBreak.convertedOdometer');
      return odometer;
    }),
    computedArriveLat: Ember.computed('arriveLat', 'routeBreak.arriveLat', function () {
      const arriveLat = this.get('arriveLat');
      if (this.get('isBreak')) return this.get('routeBreak.arriveLat');
      return arriveLat;
    }),
    computedArriveLng: Ember.computed('arriveLng', 'routeBreak.arriveLng', function () {
      const arriveLng = this.get('arriveLng');
      if (this.get('isBreak')) return this.get('routeBreak.arriveLng');
      return arriveLng;
    }),
    computedDepartLat: Ember.computed('departLat', 'routeBreak.departLat', function () {
      const departLat = this.get('departLat');
      if (this.get('isBreak')) return this.get('routeBreak.departLat');
      return departLat;
    }),
    computedDepartLng: Ember.computed('departLng', 'routeBreak.departLng', function () {
      const departLng = this.get('departLng');
      if (this.get('isBreak')) return this.get('routeBreak.departLng');
      return departLng;
    }),
    computedActualArriveTime: Ember.computed('actualArriveTime', 'routeBreak.actualArriveTime', 'status', 'stopType', function () {
      const actualArriveTime = this.get('actualArriveTime');
      if (this.get('isBreak')) return this.get('routeBreak.actualArriveTime');
      if (this.get('status') === '') return '';
      return actualArriveTime;
    }),
    computedActualDepartTime: Ember.computed('actualDepartTime', 'routeBreak.actualDepartTime', 'status', 'stopType', function () {
      const actualDepartTime = this.get('actualDepartTime');
      if (this.get('isBreak')) return this.get('routeBreak.actualDepartTime');
      if (this.get('status') === '') return '';
      return actualDepartTime;
    }),
    computedPromiseTime: Ember.computed('isPick', 'promisedTime', function () {
      const isPick = this.get('isPick');

      if (isPick) {
        return this.get('promisedTime');
      }

      return '';
    }),
    computedApptTime: Ember.computed('isDrop', 'trip.segment.leg.requestTime', function () {
      const anchor = this.get('trip.segment.anchor');
      const isDrop = this.get('isDrop');

      if (anchor === ANCHOR_DROP_TYPE && isDrop) {
        return this.get('trip.segment.leg.requestTime');
      }

      return '';
    }),
    // based on route ordinal changes get the stop before it
    previousStopPoint: Ember.computed('stopPoint.{realRouteOrdinal,arrive,depart}', function () {
      return this.findPreviousPerformedStop();
    }),
    minPerformedTime: Ember.computed('previousStopPoint.{actualArriveTime,actualDepartTime,arriveTimestamp,departTimestamp}', function () {
      const prevStop = this.get('previousStopPoint');
      let minTime = null;
      if (!prevStop) return minTime;
      const actualArriveTime = prevStop.get('computedActualArriveTime');
      const actualDepartTime = prevStop.get('computedActualDepartTime');
      const actualStartTime = prevStop.get('dispatchRoute.route.actualStartTimestamp');
      const actualArriveTimestamp = prevStop.get('arriveTimestamp');
      const actualDepartTimestamp = prevStop.get('departTimestamp');
      if (prevStop.get('isGaragePullout') && actualStartTime) minTime = actualStartTime;
      if (actualDepartTimestamp) minTime = actualDepartTimestamp;
      if (actualDepartTime) minTime = actualDepartTime;
      if (actualArriveTimestamp) minTime = actualArriveTimestamp;
      if (actualArriveTime) minTime = actualArriveTime;
      return minTime ? (0, _moment.default)(minTime).add(1, 'minute').startOf('minute').toDate() : null;
    }),
    arrivalTime: Ember.computed('tripStop.plannedEta', function () {
      return this.get('tripStop.plannedEta');
    }),
    departTime: Ember.computed('tripStop.plannedEta', 'trip.rider.{loadTime,unloadTime}', function () {
      const anchor = this.get('trip.segment.anchor');
      const plannedEta = (0, _moment.default)(this.get('tripStop.plannedEta'));

      if (anchor === 'pick') {
        const loadTime = this.get('trip.rider.loadTime');

        if (loadTime) {
          plannedEta.add(loadTime, 'seconds');
        }

        return plannedEta.toDate();
      }

      if (anchor === 'drop') {
        const unloadTime = this.get('trip.rider.loadTime');

        if (unloadTime) {
          plannedEta.add(unloadTime, 'seconds');
        }

        return plannedEta.toDate();
      }

      return '';
    }),
    realRouteOrdinal: Ember.computed('routeOrdinal', 'isBreak', 'routeBreak.plannedRouteOrdinal', function () {
      const routeOrdinal = this.get('routeOrdinal');

      if (Ember.isNone(routeOrdinal) && this.get('isBreak')) {
        const breakOrdinal = this.get('routeBreak.plannedRouteOrdinal');
        return breakOrdinal;
      }

      return routeOrdinal;
    }),
    otpValueWithSign: Ember.computed('otp', 'otpValue', function () {
      const otp = this.get('otp');
      const otpValue = this.get('otpValue');
      const sign = otp === 'O' || otp === 'E' ? '-' : '+';

      if (otpValue) {
        return `${sign}${Math.abs(otpValue)}`;
      }

      return '';
    }),
    convertedOdometer: Ember.computed('odometer', function () {
      const odometer = this.get('odometer');

      if (odometer) {
        return (0, _convertDistance.convertMetersToMiles)(odometer);
      }

      return '';
    }),
    dispatchRoute: Ember.computed('cluster.dispatchRoute', 'stopRoute.dispatchRoute', function () {
      const dispatchRoute = this.get('stopRoute.dispatchRoute.content');
      const dispatchRouteFromCluster = this.get('cluster.dispatchRoute.content');

      if (dispatchRouteFromCluster) {
        return dispatchRouteFromCluster;
      }

      return dispatchRoute;
    }),
    place: Ember.computed('trip.segment.{pick.place.location,drop.place.location}', 'stopType', 'routeBreak', 'isNoShow', function () {
      const routeBreak = (0, _unwrapProxy.unwrapProxy)(this.get('routeBreak'));
      const trip = (0, _unwrapProxy.unwrapProxy)(this.get('trip'));
      const stopType = this.get('stopType');
      const isNoShow = this.get('isNoShow');
      if (isNoShow && this.get('isDrop')) return null;
      if (routeBreak) return routeBreak.get('place');
      if (trip && stopType) return trip.get(`segment.${stopType}.place`);
      if (this.get('isGaragePullout')) return this.get('dispatchRoute.startGarage');
      if (this.get('isGaragePullin')) return this.get('dispatchRoute.endGarage');
      return null;
    }),
    notes: Ember.computed('trip', 'stopType', function () {
      const trip = this.get('trip.content');
      const stopType = this.get('stopType');
      if (trip && stopType) return trip.get(`segment.${stopType}.notes`);
      return null;
    }),
    tripStartTime: Ember.computed('trip.tripStartTime', 'actualDepartTime', 'tripStop.plannedEta', 'routeBreak.estimatedStart', 'etaNavStopPoint.{eta,notBefore}', function () {
      const tripStartTime = this.get('trip.tripStartTime');
      const minTripStartTime = this.get('dispatchRoute.route.tripStartTime');
      const actualDepartTime = this.get('actualDepartTime');
      const plannedEta = this.get('tripStop.plannedEta');
      const estimatedStart = this.get('routeBreak.estimatedStart');
      const parentType = this.get('parentType');
      const eta = this.get('etaNavStopPoint.eta');

      switch (parentType) {
        case 'break':
          return minTripStartTime ? minTripStartTime : estimatedStart;

        case 'pulloutGarage':
          return minTripStartTime ? minTripStartTime : this.get('etaNavStopPoint.notBefore');

        case 'pullinGarage':
          return minTripStartTime ? minTripStartTime : eta;

        default:
          // when the stop is performed
          if (actualDepartTime) return actualDepartTime; // currently happening
          else if (eta) return eta; // planned time is given as route hasn't started
            else if (minTripStartTime) return minTripStartTime;else if (tripStartTime) return tripStartTime;
          return plannedEta;
      }
    }),
    tripEndTime: Ember.computed('trip.tripEndTime', 'actualDepartTime', 'tripStop.plannedEta', 'routeBreak.estimatedEnd', 'etaNavStopPoint.{eta,notBefore}', function () {
      const tripEndTime = this.get('trip.tripEndTime');
      const maxTripEndTime = this.get('dispatchRoute.route.tripEndTime');
      const plannedEta = this.get('tripStop.plannedEta');
      const actualDepartTime = this.get('actualDepartTime');
      const estimatedEnd = this.get('routeBreak.estimatedEnd');
      const parentType = this.get('parentType');
      const eta = this.get('etaNavStopPoint.eta');

      switch (parentType) {
        case 'break':
          return maxTripEndTime ? maxTripEndTime : estimatedEnd;

        case 'pulloutGarage':
          return maxTripEndTime ? maxTripEndTime : this.get('etaNavStopPoint.notBefore');

        case 'pullinGarage':
          return maxTripEndTime ? maxTripEndTime : eta;

        default:
          // when the stop is performed
          if (actualDepartTime) return actualDepartTime; // currently happening
          else if (eta) return eta; // planned end time is given as route hasn't started
            else if (maxTripEndTime) return maxTripEndTime;else if (tripEndTime) return tripEndTime;
          return plannedEta;
      }
    }),
    routeTripStartTime: Ember.computed('dispatchRoute.tripStartTime', 'tripStartTime', function () {
      const routeStartTime = this.get('dispatchRoute.tripStartTime');
      const tripStartTime = this.get('tripStartTime');

      if (routeStartTime) {
        return routeStartTime;
      }

      return tripStartTime;
    }),
    routeTripEndTime: Ember.computed('dispatchRoute.tripEndTime', 'tripEndTime', function () {
      const routeEndTime = this.get('dispatchRoute.tripEndTime');
      const tripEndTime = this.get('tripEndTime');

      if (routeEndTime) {
        return routeEndTime;
      }

      return tripEndTime;
    }),
    lat: Ember.computed('estimatedBreakLatLng', 'place.location.lat,etaNavStopPoint.lat', 'realRouteOrdinal', function () {
      const dsStopLat = this.get('place.location.lat');
      const breakLatLng = this.get('estimatedBreakLatLng');
      const etaLat = this.get('etaNavStopPoint.lat'); // Remove For AVLM 2.1

      if (!dsStopLat && breakLatLng) {
        return breakLatLng[0];
      }

      if (etaLat) return etaLat;
      return dsStopLat;
    }),
    lng: Ember.computed('estimatedBreakLatLng', 'place.location.lng,etaNavStopPoint.lng', 'realRouteOrdinal', function () {
      const dsStopLng = this.get('place.location.lng');
      const breakLatLng = this.get('estimatedBreakLatLng');
      const etaLng = this.get('etaNavStopPoint.lng');

      if (!dsStopLng && breakLatLng) {
        return breakLatLng[1];
      }

      if (etaLng) return etaLng;
      return dsStopLng;
    }),
    scheduleLocked: Ember.computed('dispatchSchedule.locked', function () {
      return this.get('dispatchSchedule.locked');
    }),
    stopDate: Ember.computed('dispatchRoute.route.plannedStartTime', function () {
      return this.get('dispatchRoute.route.plannedStartTime');
    }),
    mapId: Ember.computed('status', 'stopType', function () {
      const status = this.get('status');
      const stopType = this.get('stopType');
      if (status === NOSHOW_STOP_STATUS) return 'noshow';
      if (status === PERFORMED_STOP_STATUS) return 'performedStop';
      return stopType;
    }),
    isLocked: Ember.computed('dispatchRoute.isLocked', function () {
      return this.get('dispatchRoute.isLocked');
    }),
    lockedBy: Ember.computed('dispatchRoute.lockedBy', function () {
      return this.get('dispatchRoute.lockedBy');
    }),
    lockedId: Ember.computed('isLocked', function () {
      return this.get('dispatchRoute.id');
    }),
    isLockedByOtherUser: Ember.computed('dispatchRoute.isLockedByOtherUser', function () {
      return this.get('dispatchRoute.isLockedByOtherUser');
    }),
    eta: Ember.computed('isNoShow', 'etaNavStopPoint.eta', 'routeBreak.etaNavRouteBreak.eta', function () {
      const etaNavStopPoint = this.get('etaNavStopPoint.eta');
      const etaNavRouteBreak = this.get('routeBreak.etaNavRouteBreak.eta'); // discard eta if stop becomes no-show

      if (this.get('isNoShow')) return this.get('noShowTime');

      if (this.get('isBreak')) {
        return etaNavRouteBreak;
      }

      return etaNavStopPoint;
    }),
    computedEta: Ember.computed('isNoShow', 'etaNavStopPoint.eta', 'routeBreak.etaNavRouteBreak.eta', function () {
      const etaNavStopPoint = this.get('etaNavStopPoint.eta');
      const etaNavRouteBreak = this.get('routeBreak.etaNavRouteBreak.eta');

      if (this.get('isBreak')) {
        return etaNavRouteBreak;
      }

      return etaNavStopPoint;
    }),
    stopCloseToBreak: Ember.computed('realRouteOrdinal', 'dispatchRoute.orderedStopsWithGaragesAndBreaks', function () {
      const ordinal = this.get('realRouteOrdinal');
      const orderedStops = this.get('dispatchRoute.orderedStopsWithGaragesAndBreaks') || [];
      const orderedStopsLength = orderedStops.length;

      if (this.get('parentType') === 'break' && ordinal && ordinal > 0 && orderedStops) {
        // either a stop in front or behind the break
        const behind = ordinal - 1;
        const inFront = ordinal + 1;
        const isBreakAtLast = orderedStopsLength - 1 === inFront; // get behind stop if break is last

        let stop = orderedStops.find(s => s.get('realRouteOrdinal') === behind);

        if (stop) {
          return stop;
        } // get infront stop first as the closer stop


        stop = orderedStops.find(s => s.get('realRouteOrdinal') === inFront);

        if (stop && !isBreakAtLast) {
          return stop;
        }
      }

      return null;
    }),
    estimatedBreakLatLng: Ember.computed('stopCloseToBreak', 'routeBreak.{arriveLat,arriveLng,departLat,departLng}', function () {
      const stop = this.get('stopCloseToBreak');
      const breakLat = this.get('routeBreak.departLat') || this.get('routeBreak.arriveLat');
      const breakLng = this.get('routeBreak.departLng') || this.get('routeBreak.arriveLng');

      if (breakLat && breakLng) {
        return [breakLat, breakLng];
      }

      if (stop) {
        // otherwise return an estimated lat,lng
        const polyline = stop.get('polyline.coords');

        if (polyline) {
          const median = Math.floor(polyline.length / 2);
          return polyline[median];
        }
      }

      return null;
    }),
    computedStartTime: Ember.computed('isNoShow', '{etaNavStopPoint.eta,tripStop.plannedEta,routeBreak.estimatedStart}', function () {
      const stopType = this.get('stopType');
      const plannedEta = this.get('tripStop.plannedEta');
      const eta = this.get('etaNavStopPoint.eta');
      const breakPlannedEta = this.get('routeBreak.estimatedStart'); // discard eta if stop becomes no-show

      if (this.get('isNoShow')) return null;

      if (stopType !== 'break') {
        if (Ember.isEmpty(eta)) {
          return plannedEta;
        }

        return eta;
      }

      return breakPlannedEta;
    })
  });

  _exports.default = _default;
});