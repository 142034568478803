define("adept-iq/mixins/fetch-vehicle-events", ["exports", "ember-concurrency", "adept-iq/utils/geolib", "moment"], function (_exports, _emberConcurrency, _geolib, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    radiusOptions: Ember.computed(function () {
      const options = [];

      for (let i = 1; i <= 10; i++) {
        options.push({
          id: i,
          value: i,
          title: i + (i === 1 ? ' mile' : ' miles')
        });
      }

      return options;
    }),
    fetchVehiclesTask: (0, _emberConcurrency.task)(function* () {
      const vehicles = yield this.get('store').peekAll('avlm-vehicle');

      if (!vehicles || !vehicles.length) {
        this.set('vehicles', []);
        return;
      }

      this.set('vehicles', vehicles);
    }),
    fetchRadiusVehiclesTask: (0, _emberConcurrency.task)(function* () {
      const selectedDateTimeRage = this.get('selectedDateTimeRage');
      const selectedAddress = this.get('selectedAddress');
      const selectedRadius = this.get('selectedRadius');
      const queryOptions = {
        lat: selectedAddress.lat,
        lng: selectedAddress.lng,
        radius: selectedRadius.value,
        startTime: selectedDateTimeRage.starttime,
        endTime: selectedDateTimeRage.endtime
      };
      const radiusVehicles = yield this.get('store').query('avlm-vehicle-radius', queryOptions);

      if (!radiusVehicles || !radiusVehicles.length) {
        this.set('radiusVehicles', []);
        return;
      }

      this.set('radiusVehicles', radiusVehicles.sortBy('vehicleId'));
    }).restartable(),
    fetchEventsTask: (0, _emberConcurrency.task)(function* () {
      const selectedVehicle = this.get('selectedVehicle');
      const selectedRadiusVehicle = this.get('selectedRadiusVehicle');
      const selectedDateTimeRage = this.get('selectedDateTimeRage');
      const activeTabModeId = this.get('activeTabModeId');
      if (Ember.isNone(selectedVehicle) && Ember.isNone(selectedRadiusVehicle)) return;
      yield this.get('store').unloadAll('avlm-vehicle-event');
      const vehicleId = activeTabModeId === 'byVehicle' ? selectedVehicle.id : selectedRadiusVehicle.id;
      const queryOptions = {
        // These two fields for archived avl
        // vehicleId: vehicleId,
        // date: moment(selectedDateTimeRage.starttime).format('YYYY-MM-DD'),
        // These three fields for avl
        vehicleIds: vehicleId,
        startTime: selectedDateTimeRage.starttime,
        endTime: selectedDateTimeRage.endtime + ',4000' // we use maximum 4000 events

      };
      const events = yield this.get('store').query('avlm-vehicle-event', queryOptions);
      events.forEach(event => {
        event.set('isChecked', true);
      });
      this.set('vehicleEvents', events.sortBy('timestamp').toArray());
    }).restartable(),
    fetchPolylineTask: (0, _emberConcurrency.task)(function* () {
      const route = yield this.get('store').peekRecord('avlm-route', this.get('selectedRoute.model.id')); // route is layerRecords.

      const events = [];
      const stopPoints = route.get('orderedStopsWithGaragesAndBreaks');
      let timebase;
      stopPoints.forEach(stopPoint => {
        const points = stopPoint.get('polylinePoints');
        const start = stopPoint.get('tripStartTime');
        const end = stopPoint.get('tripEndTime');

        if (!timebase) {
          timebase = start;
        }

        if (points && points.length > 0) {
          const totalDistance = _geolib.default.computePolylineLength(points);

          const diff = (0, _moment.default)(end).unix() - (0, _moment.default)(start).unix();
          points.forEach((point, i) => {
            const travelPoints = points.slice(0, i + 1);

            const travelDistance = _geolib.default.computePolylineLength(travelPoints);

            const seconds = diff * travelDistance / totalDistance;
            const timestamp = (0, _moment.default)(timebase).add(seconds, 'seconds').format();
            const event = this.get('store').createRecord('avlm-vehicle-event', {
              lat: point[0],
              lng: point[1],
              timestamp,
              vehicleId: route.get('latestVehicle.id'),
              isChecked: true
            });
            events.push(event);
          }); // continue to next stopPoints.

          timebase = (0, _moment.default)(timebase).add(diff, 'seconds').format();
        }
      });
      this.set('vehicleEvents', events);
    })
  });

  _exports.default = _default;
});