define("adept-iq/models/rider-eligibility", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    from: attr('date', {
      defaultValue: () => new Date()
    }),
    to: attr('date', {
      defaultValue: () => new Date()
    }),
    rider: belongsTo('rider'),
    eligibilityType: belongsTo('eligibility-type'),
    // used for `@each.name`-type computed property dependent keys
    eligibilityTypeName: Ember.computed.readOnly('eligibilityType.name')
  });

  _exports.default = _default;
});