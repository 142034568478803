define("adept-iq/topic-transforms/avlm-route-exec-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transform;

  // don't want to use Ember imports here
  const isPresent = x => typeof x !== 'undefined' && x !== null;

  const TYPE_CONVERT = {
    N: 'No Show',
    R: 'Arrive',
    O: 'OnStop',
    L: 'Depart',
    C: 'Complete',
    A: 'Accept',
    D: 'Reject',
    B: 'OnBreak',
    F: 'OffBreak',
    W: 'work',
    E: 'ETA',
    V: 'AVL',
    T: 'Pullout',
    I: 'Pullin',
    Q: 'noShowRequest',
    Y: 'noShowAccepted',
    X: 'noShowDeclined',
    S: 'noShowCancelled',
    Z: 'removed',
    U: 'added',
    P: 'otpStatus',
    G: 'emergency',
    H: 'resolved',
    J: 'canceled',
    K: 'acknowledged',
    '1': 'preCheckDone',
    '2': 'postCheckDone',
    '3': 'altPath',
    '4': 'lateCancel'
  };

  function transform(payload, ENV) {
    const prefix = ENV.APP.avlmLite ? 'avlm-' : '';
    const transformedPayloads = [];
    if (!payload.data || !payload.data.relationships || !payload.data.attributes) return transformedPayloads;
    payload.data.type = 'route-exec-event';
    const {
      route,
      trip,
      routeBreak,
      vehicle,
      stopPoint
    } = payload.data.relationships;
    const routeId = route ? route.data.id : null;
    const tripId = trip ? trip.data.id : null;
    const breakId = routeBreak ? routeBreak.data.id : null;
    const vehicleId = vehicle ? vehicle.data.id : null;
    const stopId = stopPoint ? stopPoint.data.id : null;
    let {
      type
    } = payload.data.attributes;
    const routeAttributes = {};
    const tripAttributes = {};
    const stopAttributes = {};
    const vehicleAttributes = {};
    const routeBreakAttributes = {};
    type = TYPE_CONVERT[type];

    switch (type) {
      case 'No Show':
        {
          const {
            createdAt,
            source
          } = payload.data.attributes;

          if (ENV.APP.avlmLite) {
            tripAttributes.status = type;
          }

          stopAttributes.status = type;
          stopAttributes.noShowTime = createdAt;
          stopAttributes.arrivePerformedBy = source;
          break;
        }

      case 'Arrive':
        {
          const {
            lat,
            lng,
            receivedAt,
            createdAt,
            source
          } = payload.data.attributes;
          stopAttributes.arriveLat = lat;
          stopAttributes.arriveLng = lng;
          stopAttributes.status = type;
          stopAttributes.actualArriveTime = receivedAt || createdAt;
          stopAttributes.arrivePerformedBy = source;

          if (!ENV.APP.avlmLite) {
            routeAttributes.status = type;
          }

          break;
        }

      case 'Depart':
        {
          const {
            lat,
            lng,
            receivedAt,
            createdAt,
            source
          } = payload.data.attributes;
          stopAttributes.departLat = lat;
          stopAttributes.departLng = lng;
          stopAttributes.status = type;
          stopAttributes.actualDepartTime = receivedAt || createdAt;
          stopAttributes.departPerformedBy = source;

          if (!ENV.APP.avlmLite) {
            routeAttributes.status = type;
          }

          break;
        }

      case 'Accept':
        {
          stopAttributes.status = '';
          stopAttributes.noShowTime = null;
          stopAttributes.arrivePerformedBy = '';
          stopAttributes.departPerformedBy = '';
          routeBreakAttributes.arriveLat = null;
          routeBreakAttributes.arriveLng = null;
          routeBreakAttributes.status = '';
          routeBreakAttributes.actualArriveTime = null;
          routeBreakAttributes.actualDepartTime = null;
          break;
        }

      case 'removed':
        {
          // IQUX-1860 because of an issue in ESS to sent delete record from
          // a cancel or trip transfer, we are just hiding the trip and stop
          // for now. Post avlm, we will show the trip/stop and just have a status
          // of Cancelled.
          if (ENV.APP.avlmLite) {
            stopAttributes.status = 'Removed';
            tripAttributes.status = 'Removed';
          } else {
            stopAttributes.status = 'Cancelled';
            tripAttributes.status = 'Cancelled';
          }

          break;
        }

      case 'noShowCancelled':
      case 'noShowDeclined':
        {
          tripAttributes.status = ENV.APP.avlmLite ? 'A' : 'scheduled'; // Currently the status should revert back to its previous status; need to talk to Paul or Ladya

          break;
        }

      case 'noShowAccepted':
        {
          break;
        }

      case 'noShowRequest':
        {
          const {
            noShowCode
          } = payload.data.attributes;
          tripAttributes.status = type;
          tripAttributes.statusReason = noShowCode;
          break;
        }

      case 'Reject':
      case 'OnBreak':
        {
          const {
            lat,
            lng,
            receivedAt,
            createdAt,
            source
          } = payload.data.attributes;
          routeBreakAttributes.arriveLat = lat;
          routeBreakAttributes.arriveLng = lng;
          routeBreakAttributes.status = type;
          routeBreakAttributes.actualArriveTime = receivedAt || createdAt;
          routeBreakAttributes.arrivePerformedBy = source;

          if (!ENV.APP.avlmLite) {
            routeAttributes.status = type;
          }

          break;
        }

      case 'OffBreak':
        {
          const {
            lat,
            lng,
            receivedAt,
            createdAt,
            source
          } = payload.data.attributes;
          routeBreakAttributes.departLat = lat;
          routeBreakAttributes.departLng = lng;
          routeBreakAttributes.status = type;
          routeBreakAttributes.actualDepartTime = receivedAt || createdAt;
          routeBreakAttributes.departPerformedBy = source;

          if (!ENV.APP.avlmLite) {
            routeAttributes.status = type;
          }

          break;
        }
      // these are currently defined but unused

      case 'OnStop':
      case 'Complete':
        {
          routeAttributes.status = type;
          break;
        }

      case 'work': // TODO: check if this should be capitalized

      case 'ETA':
      case 'AVL':
      case 'Pullout':
        {
          const {
            receivedAt
          } = payload.data.attributes;
          stopAttributes.status = type;

          if (!ENV.APP.avlmLite) {
            routeAttributes.status = type;
          }

          routeAttributes.actualStartTimestamp = receivedAt;
          break;
        }

      case 'Pullin':
        {
          const {
            receivedAt
          } = payload.data.attributes;
          stopAttributes.status = type;

          if (!ENV.APP.avlmLite) {
            routeAttributes.status = type;
          }

          routeAttributes.actualEndTimestamp = receivedAt;
          vehicleAttributes.otp = '';
          break;
        }

      default:
        break;
    }

    if (routeId) {
      const {
        odo,
        otpRouteStatus,
        otpRouteValue
      } = payload.data.attributes;

      if (isPresent(otpRouteStatus)) {
        routeAttributes.otp = otpRouteStatus;
      }

      if (isPresent(otpRouteValue)) {
        routeAttributes.otpValue = otpRouteValue;
      }

      routeAttributes.odometer = odo;
      const routePayload = {
        data: {
          id: routeId,
          type: `${prefix}route`,
          attributes: routeAttributes
        }
      };
      transformedPayloads.push(routePayload);
    }

    if (tripId) {
      const {
        otpTripStatus,
        otpTripValue
      } = payload.data.attributes;

      if (isPresent(otpTripStatus)) {
        tripAttributes.otp = otpTripStatus;
      }

      if (isPresent(otpTripValue)) {
        tripAttributes.otpValue = otpTripValue;
      }

      const tripPayload = {
        data: {
          id: tripId,
          type: `${prefix}trip`,
          attributes: tripAttributes
        }
      };
      transformedPayloads.push(tripPayload);
    }

    if (vehicleId) {
      const {
        otpRouteStatus
      } = payload.data.attributes;

      if (isPresent(otpRouteStatus)) {
        vehicleAttributes.otp = otpRouteStatus;
      } else {
        vehicleAttributes.otp = null;
      }

      const vehiclePayload = {
        data: {
          id: vehicleId,
          type: `${prefix}vehicle`,
          attributes: vehicleAttributes
        }
      };
      transformedPayloads.push(vehiclePayload);
    }

    if (stopId) {
      const {
        odo,
        otpStopStatus,
        otpStopValue
      } = payload.data.attributes;

      if (isPresent(otpStopStatus)) {
        stopAttributes.otp = otpStopStatus;
      }

      if (isPresent(otpStopValue)) {
        stopAttributes.otpValue = otpStopValue;
      } // we don't want to include odometer with otpStatus type because
      // It is always set to the route start and this comes after the stop
      // performs.


      if (!isPresent(otpStopValue)) {
        stopAttributes.odometer = odo;
      }

      const stopPayload = {
        data: {
          id: stopId,
          type: `${prefix}stop-point`,
          attributes: stopAttributes
        }
      };
      transformedPayloads.push(stopPayload);
    }

    if (breakId) {
      const {
        odo
      } = payload.data.attributes;
      routeBreakAttributes.odometer = odo;
      const routeBreakPayload = {
        data: {
          id: `${breakId}`,
          type: `${prefix}route-break`,
          attributes: routeBreakAttributes
        }
      };
      transformedPayloads.push(routeBreakPayload);
    }

    return transformedPayloads;
  }
});