define("adept-iq/adapters/avlm-vehicle-radius", ["exports", "adept-iq/adapters/-avlService"], function (_exports, _avlService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_PATH_MAP = {
    'avlm-vehicle-radius': 'vehicle-radius'
  };

  var _default = _avlService.default.extend({
    findRecord()
    /* store, type, id, snapshot */
    {
      throw new Error('adapter method unsupported');
    },

    buildURL(modelName
    /*, id, snapshot, requestType, query*/
    ) {
      return this._buildUrlForPath(modelName);
    },

    _buildUrlForPath(model) {
      const host = this.get('host');
      const path = DEFAULT_PATH_MAP[model];
      return `${host}/${path}`;
    }

  });

  _exports.default = _default;
});