define("adept-iq/pods/components/side-drawer/gauge-chart-settings/component", ["exports", "adept-iq/pods/components/side-drawer/gauge-chart-settings/config", "adept-iq/pods/components/generic-widgets/column-widget/classes/filter-column"], function (_exports, _config, _filterColumn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    workspace: Ember.inject.service(),
    widget: Ember.computed.readOnly('workspace.topOptions.widget'),
    title: 'Gauge Chart',
    dataType: null,
    sourceOptions: null,
    displayOptions: Ember.computed('dataType.displayOptions', function () {
      return Object.values(this.get('dataType.displayOptions'));
    }),
    visualOptions: Ember.computed('dataType.visualOptions', function () {
      return Object.values(this.get('dataType.visualOptions'));
    }),

    init() {
      this._super(...arguments);

      this.set('sourceOptions', _config.default);
      const dataType = this.get('widget.state.dataType');
      this.set('dataType', dataType);

      if (Ember.isPresent(dataType)) {
        this.set('filterColumns', Object.values(dataType.columns).map(col => new _filterColumn.default(col)));
      }
    },

    actions: {
      onTypeSelect(dataType) {
        this.set('dataType', dataType);
        this.set('filterColumns', Object.values(dataType.columns).map(col => new _filterColumn.default(col)));
        this.get('widget').mergeState({
          dataType
        });
      },

      setDisplayOptionVisibility(selected) {
        this.set('dataType.displayOptions.selected', selected);
        this.get('widget').mergeState({
          dataType: this.get('dataType')
        });
      },

      setDisplayOptionMetric(selected) {
        this.set('dataType.displayOptions.selected.metricOption', selected);
        this.get('widget').mergeState({
          dataType: this.get('dataType')
        });
      },

      setDisplayOptionValueCategory(selected) {
        this.set('dataType.displayOptions.selected.valueCategory', selected);
        this.get('widget').mergeState({
          dataType: this.get('dataType')
        });
      },

      onTitleInput(data) {
        this.set('dataType.title', data);
        this.get('widget').mergeState({
          dataType: this.get('dataType')
        });
      },

      setVisualOptionVisibility(selected, event) {
        Ember.set(selected, 'isChecked', event.target.checked);
        this.get('widget').mergeState({
          dataType: this.get('dataType')
        });
      },

      onFilterValueChange(column, index, event) {
        let filterValues = column.get('filterValues') || [];
        filterValues = filterValues.slice();
        filterValues[index] = event.target.value; // Update the displayed filter column.

        Ember.set(column, 'filterValues', filterValues);
        const id = column.get('id'); // Update the column config.

        Ember.set(this.get('dataType'), `columns.${id}.filterValues`, filterValues);
        this.get('widget').mergeState({
          dataType: this.get('dataType')
        });
      },

      onSelectFilterType(column, filterType) {
        // Update the displayed filter column.
        Ember.set(column, 'filterTypeId', filterType.id);
        const id = column.get('id'); // Update the column config.

        Ember.set(this.get('dataType'), `columns.${id}.filterTypeId`, filterType.id);
        this.get('widget').mergeState({
          dataType: this.get('dataType')
        });
      },

      onGoColorChange(color, data) {
        /*eslint-disable */
        data = parseInt(data);
        /*eslint-enable */

        if (data) {
          Ember.set(this.get('dataType'), `boxColor.${color}`, data);
          this.get('widget').mergeState({
            dataType: this.get('dataType')
          });
        }
      }

    }
  });

  _exports.default = _default;
});