define("adept-iq/serializers/dispatch-route", ["exports", "adept-iq/serializers/-dispatch", "@mapbox/polyline"], function (_exports, _dispatch, _polyline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dispatch.default.extend({
    normalize(typeClass, resourceHash) {
      // de-code polylines; for `navigationPolyline`, this is done by an
      // ember-data transform, but the nested structure here means we have to
      // do it manually
      if (resourceHash.attributes) {
        // Handles error if attributes is undefined
        const {
          stopPolylines
        } = resourceHash.attributes;

        if (stopPolylines) {
          stopPolylines.forEach(obj => {
            if (!obj.polyline) return;
            obj.polyline = _polyline.default.decode(obj.polyline);
          });
        }

        if (!Ember.isNone(resourceHash.attributes.providerName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.provider = {
            data: {
              type: 'provider',
              id: resourceHash.attributes.providerName
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.attributes.providerName;
        }
      }

      const normalized = this._super(...arguments);

      return normalized;
    }

  });

  _exports.default = _default;
});