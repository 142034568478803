define("adept-iq/pods/components/reoptimize-modal/operation-tables/release-routes-table/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/reoptimize-modal/operation-tables/release-routes-table/config"], function (_exports, _component, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['release-routes-table'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    config: _config.default,
    currentSchedule: null,

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
        this.set('checkAll', false);
      }
    },

    willDestroyElement() {
      this._super(...arguments);
    },

    fetchDataQuery() {
      return this._super(...arguments).then(records => {
        return records.uniqBy('provider.id');
      });
    }

  });

  _exports.default = _default;
});