define("adept-iq/classes/active-contexts/playback/graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.activeContextGraph = _exports.activeContextNodes = _exports.ssoUserNode = _exports.avlmVehicleEventNode = _exports.avlmRouteNode = _exports.avlmVehicleNode = void 0;
  // Vehicle Related Nodes
  const avlmVehicleNode = {
    id: 'avlm-vehicle',
    modelName: 'avlm-vehicle',
    links: [{
      type: 'belongsTo',
      nodeId: 'avlm-route',
      path: 'latestRoute'
    }],
    isActive: false,
    providerFilterKey: 'provider'
  };
  _exports.avlmVehicleNode = avlmVehicleNode;
  const avlmRouteNode = {
    id: 'avlm-route',
    modelName: 'avlm-route',
    links: [{
      type: 'belongsTo',
      nodeId: 'avlm-vehicle',
      path: 'latestVehicle'
    }],
    isActive: false,
    providerFilterKey: 'provider'
  };
  _exports.avlmRouteNode = avlmRouteNode;
  const avlmVehicleEventNode = {
    id: 'avlm-vehicle-event',
    modelName: 'avlm-vehicle-event',
    links: [],
    isActive: false,
    providerFilterKey: null
  };
  _exports.avlmVehicleEventNode = avlmVehicleEventNode;
  const ssoUserNode = {
    id: 'sso-user',
    modelName: 'sso-user',
    links: [],
    providerFilterKey: false
  };
  _exports.ssoUserNode = ssoUserNode;
  const activeContextNodes = [// sign-on
  ssoUserNode, // avlm graph
  avlmRouteNode, // vehicle graph
  avlmVehicleNode, // vehicle event graph
  avlmVehicleEventNode];
  _exports.activeContextNodes = activeContextNodes;
  const activeContextGraph = activeContextNodes.reduce((obj, node) => {
    obj[node.id] = node;
    return obj;
  }, {});
  _exports.activeContextGraph = activeContextGraph;
  var _default = activeContextGraph;
  _exports.default = _default;
});