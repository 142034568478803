define("adept-iq/pods/components/iq-widgets/message-widget/avlmConfig", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/message-widget',
    rowComponent: 'table/rows/action-row',
    modelName: 'avlm-canned-message',
    title: 'Messages',
    defaultSortId: 'time',
    defaultSortAsc: false,
    columns: [{
      id: 'read',
      index: 9,
      type: 'text',
      label: 'Read',
      valuePath: 'readString',
      searchable: true,
      defaultWidth: 70
    }, {
      id: 'status',
      index: 2,
      type: 'text',
      label: 'Status',
      valuePath: 'status',
      searchable: true
    }, {
      id: 'deliveredTime',
      index: 1,
      type: 'date',
      label: 'Delivered Time',
      valuePath: 'deliveredTime',
      format: 'MM-DD-YYYY hh:mm A',
      searchable: true,
      defaultWidth: 200
    }, {
      id: 'time',
      index: 0,
      type: 'date',
      label: 'Sent Time',
      valuePath: 'createdTime',
      format: 'MM-DD-YYYY hh:mm A',
      searchable: true,
      defaultWidth: 200
    }, {
      id: 'message',
      index: 3,
      type: 'text',
      label: 'Message',
      valuePath: 'body',
      searchable: true,
      defaultWidth: 100
    }, {
      id: 'driverId',
      index: 6,
      type: 'uuid',
      label: 'Driver ID',
      valuePath: 'driverId',
      searchable: true,
      defaultWidth: 40
    }, {
      id: 'lastName',
      index: 5,
      type: 'text',
      label: 'Driver Last Name',
      valuePath: 'avlmDriver.lastName',
      searchable: true,
      defaultWidth: 75
    }, {
      id: 'firstName',
      index: 8,
      type: 'text',
      label: 'Driver First Name',
      valuePath: 'avlmDriver.firstName',
      searchable: true,
      defaultWidth: 60
    }, {
      id: 'vehicleId',
      index: 4,
      type: 'text',
      label: 'Vehicle Id',
      valuePath: 'avlmVehicle.callSign',
      searchable: true,
      defaultWidth: 40
    }, {
      id: 'routeName',
      index: 7,
      type: 'text',
      label: 'Route Name',
      valuePath: 'avlmRoute.trackingId',
      searchable: true,
      defaultWidth: 40
    }, {
      id: 'responseType',
      index: 10,
      type: 'text',
      label: 'Response Type',
      valuePath: 'responseType.status',
      searchable: true,
      defaultWidth: 175
    }, {
      id: 'responseMessage',
      index: 11,
      type: 'text',
      label: 'Response Received',
      valuePath: 'responseMessage',
      searchable: true,
      defaultWidth: 175
    }, {
      id: 'ackTime',
      index: 12,
      type: 'date',
      label: 'Response Received Time',
      format: 'MM-DD-YYYY hh:mm A',
      valuePath: 'ackTime',
      searchable: true,
      defaultWidth: 200
    }]
  };
  _exports.default = _default;
});