define("adept-iq/pods/components/side-drawer/trip-activity-log/config", ["exports", "moment", "lodash"], function (_exports, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.logEntryTypes = _exports.editableSections = _exports.columns = void 0;
  const TIME_FORMAT = 'YYYY-MM-DD hh:mmA';
  const TEMPLATE_INTERPOLATION = /{{([\s\S]+?)}}/g;

  const interpolate = (template, attrs) => {
    const compiledTemplate = _lodash.default.template(template, {
      interpolate: TEMPLATE_INTERPOLATION
    });

    return compiledTemplate(attrs);
  };

  const columns = [{
    label: 'Activity',
    valuePath: 'activity'
  }, {
    label: 'Time',
    valuePath: 'date',
    format: date => (0, _moment.default)(date).format(TIME_FORMAT)
  }, {
    label: 'Details',
    valuePath: 'details'
  }, {
    label: 'User',
    valuePath: 'user'
  }];
  _exports.columns = columns;
  const editableSections = [{
    title: 'Add Activity Log Entry',
    fields: [{
      id: 'activity-type',
      type: 'enum',
      label: 'Activity',
      valuePath: 'userActivitySelected',
      cellDesc: 'Activity',
      editable: true,
      hidden: false,
      extra: {
        optionModelName: 'trip-activity-log-type',
        optionSearchPath: 'name'
      }
    }, {
      id: 'Details',
      type: 'text-area',
      editable: true,
      label: 'Details',
      valuePath: 'details'
    }]
  }];
  _exports.editableSections = editableSections;
  const logEntryTypes = [{
    activity: 'Arrive Stop',
    routeExecEventType: 'arrive',

    condition(routeExecEvent, trip) {
      const tripId = trip.get('id') || trip.get('externalId');
      const passengerEvents = routeExecEvent.get('content.passengerEvents');
      return Ember.makeArray(passengerEvents).findBy('tripId', tripId);
    },

    buildAttrs(routeExecEvent, tripId) {
      const dsTripId = tripId;
      let passengerEvents = routeExecEvent.get('content.passengerEvents');
      passengerEvents = Ember.makeArray(passengerEvents);
      const pickupEvent = passengerEvents.findBy('type', 'pickup');
      const dropoffEvent = passengerEvents.findBy('type', 'dropoff');
      const attrs = {
        driverId: routeExecEvent.get('driver.badgeNr')
      };

      if (pickupEvent) {
        attrs.stopType = 'pickup';
      } else if (dropoffEvent) {
        attrs.stopType = 'drop-off';
      }

      attrs.tripId = dsTripId || '<loading>';
      return attrs;
    },

    buildDetails(attrs) {
      const template = 'Trip {{tripId}} {{stopType}} arrived.';
      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'Depart Stop',
    routeExecEventType: 'depart',

    condition(routeExecEvent, trip) {
      const passengerEvents = routeExecEvent.get('content.passengerEvents');
      const tripId = trip.get('id') || trip.get('externalId');
      return Ember.makeArray(passengerEvents).any(passengerEvent => {
        return Ember.get(passengerEvent, 'tripId') === tripId && ['pickup', 'dropoff'].includes(Ember.get(passengerEvent, 'type'));
      });
    },

    buildAttrs(routeExecEvent, tripId) {
      const dsTripId = tripId;
      let passengerEvents = routeExecEvent.get('content.passengerEvents');
      passengerEvents = Ember.makeArray(passengerEvents);
      const pickupEvent = passengerEvents.findBy('type', 'pickup');
      const dropoffEvent = passengerEvents.findBy('type', 'dropoff');
      const noShowEvent = passengerEvents.findBy('type', 'noShow');
      const attrs = {
        driverId: routeExecEvent.get('driver.badgeNr')
      };

      if (pickupEvent) {
        attrs.stopType = 'pickup';
      } else if (dropoffEvent) {
        attrs.stopType = 'drop-off';
      } else if (noShowEvent) {
        attrs.stopType = 'no-show';
      }

      attrs.tripId = dsTripId || '<loading>';
      return attrs;
    },

    buildDetails(attrs) {
      const template = 'Trip {{tripId}} {{stopType}} departed.';
      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'No Show Trip',
    routeExecEventType: 'noShow',

    condition(routeExecEvent, trip) {
      const passengerEvents = routeExecEvent.get('content.passengerEvents');
      const tripId = trip.get('id') || trip.get('externalId');
      return Ember.makeArray(passengerEvents).any(passengerEvent => {
        return Ember.get(passengerEvent, 'tripId') === tripId && ['pickup', 'dropoff'].includes(Ember.get(passengerEvent, 'type'));
      });
    },

    buildAttrs(routeExecEvent, tripId) {
      const dsTripId = tripId;
      let passengerEvents = routeExecEvent.get('content.passengerEvents');
      passengerEvents = Ember.makeArray(passengerEvents);
      const pickupEvent = passengerEvents.findBy('type', 'pickup');
      const dropoffEvent = passengerEvents.findBy('type', 'dropoff');
      const noShowEvent = passengerEvents.findBy('type', 'noShow');
      const attrs = {
        driverId: routeExecEvent.get('driver.badgeNr'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng')
      };

      if (pickupEvent) {
        attrs.stopType = 'pickup';
      } else if (dropoffEvent) {
        attrs.stopType = 'drop-off';
      } else if (noShowEvent) {
        attrs.stopType = 'no-show';
      }

      attrs.tripId = dsTripId || '<loading>';
      return attrs;
    },

    buildDetails(attrs) {
      let template = 'Trip {{tripId}} {{stopType}} no-showed';

      if (attrs.lat && attrs.lng) {
        template += ' Lat {{lat}}. Lng {{lng}}.';
      }

      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'No Show Trip Request',
    routeExecEventType: 'noShowRequest',

    condition(routeExecEvent, trip) {
      const passengerEvents = Ember.makeArray(routeExecEvent.get('content.passengerEvents'));
      const tripId = trip.get('id') || trip.get('externalId'); // there must be a client noShow event to render this activity log event

      return passengerEvents.any(passengerEv => {
        return Ember.get(routeExecEvent, 'content.type') === 'noShowRequest' && Ember.get(passengerEv, 'tripId') === tripId;
      });
    },

    buildAttrs(routeExecEvent, tripId) {
      return {
        tripId: tripId || '<loading>',
        driverId: routeExecEvent.get('driver.badgeNr'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng')
      };
    },

    buildDetails(attrs) {
      let template = 'Trip {{tripId}} requested as no show.';

      if (attrs.lat && attrs.lng) {
        template += ' Lat {{lat}}. Lng {{lng}}.';
      }

      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'No Show Request Accepted',
    routeExecEventType: 'noShowAccepted',

    condition(routeExecEvent, trip) {
      const passengerEvents = Ember.makeArray(routeExecEvent.get('content.passengerEvents'));
      const tripId = trip.get('id') || trip.get('externalId'); // there must be a client noShow event to render this activity log event

      return passengerEvents.any(passengerEv => {
        return Ember.get(routeExecEvent, 'content.type') === 'noShowAccepted' && Ember.get(passengerEv, 'tripId') === tripId;
      });
    },

    buildAttrs(routeExecEvent, tripId) {
      const passengerEvents = routeExecEvent.get('content.passengerEvents');
      const passengerEvent = Ember.makeArray(passengerEvents).find(() =>
      /*passengerEv*/
      {
        return routeExecEvent.get('content.type') === 'noShowAccepted';
      });
      return {
        tripId: tripId || '<loading>',
        driverId: routeExecEvent.get('driver.badgeNr'),
        details: Ember.get(passengerEvent, 'details.message'),
        code: Ember.get(passengerEvent, 'details.code'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng')
      };
    },

    buildDetails(attrs) {
      let template = 'Trip {{tripId}} no show request accepted.';

      if (attrs.details) {
        template += ' Reason {{details}}.';
      }

      if (attrs.lat && attrs.lng) {
        template += ' Lat {{lat}}. Lng {{lng}}.';
      }

      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'No Show Request Declined',
    routeExecEventType: 'noShowDeclined',

    condition(routeExecEvent, trip) {
      const passengerEvents = Ember.makeArray(routeExecEvent.get('content.passengerEvents'));
      const tripId = trip.get('id') || trip.get('externalId'); // there must be a client noShow event to render this activity log event

      return passengerEvents.any(passengerEv => {
        return Ember.get(routeExecEvent, 'content.type') === 'noShowDeclined' && Ember.get(passengerEv, 'tripId') === tripId;
      });
    },

    buildAttrs(routeExecEvent, tripId) {
      const passengerEvents = routeExecEvent.get('content.passengerEvents');
      const passengerEvent = Ember.makeArray(passengerEvents).find(() =>
      /*passengerEv*/
      {
        return routeExecEvent.get('content.type') === 'noShowDeclined';
      });
      return {
        tripId: tripId || '<loading>',
        driverId: routeExecEvent.get('driver.badgeNr'),
        details: Ember.get(passengerEvent, 'details.message'),
        code: Ember.get(passengerEvent, 'details.code'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng')
      };
    },

    buildDetails(attrs) {
      let template = 'Trip {{tripId}} no show request declined.';

      if (attrs.details) {
        template += ' Reason {{details}}.';
      }

      if (attrs.lat && attrs.lng) {
        template += ' Lat {{lat}}. Lng {{lng}}.';
      }

      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'Cancel Trip',
    routeExecEventType: 'cancel',

    condition(routeExecEvent, trip) {
      const passengerEvents = routeExecEvent.get('content.passengerEvents');
      const tripId = trip.get('id') || trip.get('externalId');
      return Ember.makeArray(passengerEvents).any(passengerEvent => {
        return Ember.get(passengerEvent, 'tripId') === tripId && ['pickup', 'dropoff'].includes(Ember.get(passengerEvent, 'type'));
      });
    },

    buildAttrs(routeExecEvent, tripId) {
      const passengerEvents = routeExecEvent.get('content.passengerEvents');
      const passengerEvent = Ember.makeArray(passengerEvents).find(() =>
      /*passengerEv*/
      {
        return routeExecEvent.get('content.type') === 'cancel';
      });
      const dsTripId = tripId;
      const attrs = {
        userId: routeExecEvent.get('user.userId'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng')
      };

      if (passengerEvent) {
        attrs.details = passengerEvent.get('details.message');
        attrs.code = passengerEvent.get('details.code');
      }

      attrs.tripId = dsTripId || '<loading>';
      return attrs;
    },

    buildDetails(attrs) {
      let template = 'Trip {{tripId}} cancelled. Cancel reason {{code}} {{details}}';

      if (attrs.lat && attrs.lng) {
        template += ' Lat {{lat}}. Lng {{lng}}.';
      }

      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'User {{userId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'Transfer Trip',
    attrs: () => {},
    details: () => 'Trip <tripId> transferred to route <routeName>.',
    user: () => null
  }, {
    activity: 'Waitlist Trip',
    routeExecEventType: 'waitlist',

    condition(routeExecEvent, trip) {
      const passengerEvents = routeExecEvent.get('content.passengerEvents');
      const tripId = trip.get('id') || trip.get('externalId');
      return Ember.makeArray(passengerEvents).any(passengerEvent => {
        return Ember.get(passengerEvent, 'tripId') === tripId && ['pickup', 'dropoff'].includes(Ember.get(passengerEvent, 'type'));
      });
    },

    buildAttrs(routeExecEvent, tripId) {
      const passengerEvents = routeExecEvent.get('content.passengerEvents');
      const passengerEvent = Ember.makeArray(passengerEvents).find(() =>
      /*passengerEv*/
      {
        return routeExecEvent.get('content.type') === 'waitlist';
      });
      const dsTripId = tripId;
      const attrs = {
        userId: routeExecEvent.get('user.userId'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng')
      };

      if (passengerEvent) {
        attrs.details = passengerEvent.get('details.message');
        attrs.code = passengerEvent.get('details.code');
      }

      attrs.tripId = dsTripId || '<loading>';
      return attrs;
    },

    buildDetails(attrs) {
      let template = 'Trip {{tripId}} waitlisted.';

      if (attrs.lat && attrs.lng) {
        template += ' Lat {{lat}}. Lng {{lng}}.';
      }

      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'User {{userId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'Manual Entry',
    routeExecEventType: 'userEntry',

    buildAttrs(routeExecEvent) {
      return {
        activity: routeExecEvent.get('content.activity'),
        userId: routeExecEvent.get('content.userId'),
        type: routeExecEvent.get('content.type'),
        details: routeExecEvent.get('content.details'),
        actualTime: routeExecEvent.get('content.actualTime')
      };
    },

    buildDetails(attrs) {
      let template = '';

      if (attrs.details) {
        template += '{{details}}.';
      }

      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = '{{userId}}';
      return interpolate(template, attrs);
    }

  }];
  _exports.logEntryTypes = logEntryTypes;
});