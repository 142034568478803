define("adept-iq/pods/components/iq-widgets/drivers-form-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Read Only',
      fields: [{
        id: 'id',
        type: 'text',
        label: 'ID',
        valuePath: 'driverId',
        editable: false
      }, {
        id: 'fullName',
        type: 'text',
        label: 'Full Name',
        valuePath: 'fullName',
        editable: false
      }]
    }, {
      title: 'Personal Information',
      fields: [{
        id: 'driverId',
        type: 'text',
        label: 'Driver ID',
        valuePath: 'driverId'
      }, {
        id: 'firstName',
        type: 'text',
        label: 'First Name',
        valuePath: 'firstName'
      }, {
        id: 'lastName',
        type: 'text',
        label: 'Last Name',
        valuePath: 'lastName'
      }, {
        id: 'areaCode',
        type: 'text',
        label: 'Area Code',
        valuePath: 'phoneNumber.areaCode'
      }, {
        id: 'phoneNumber',
        type: 'text',
        label: 'Phone Number',
        valuePath: 'phoneNumber.phoneNumber'
      }, {
        id: 'active',
        type: 'booleanSlider',
        label: 'Active',
        valuePath: 'active',
        editable: true,
        hidden: false,
        extra: {
          sliderId: 'driver-active-switch'
        }
      }, {
        id: 'preferredVehicle',
        type: 'searchable-enum',
        label: 'Preferred Vehicle',
        valuePath: 'preferredVehicle',
        extra: {
          optionModelName: 'vehicle',
          optionSearchPath: 'name',
          optionSearchEnabled: true
        }
      }, {
        id: 'provider',
        type: 'enum',
        label: 'Service Provider',
        valuePath: 'provider',
        cellDesc: 'provider',
        editable: true,
        hidden: false,
        extra: {
          optionModelName: 'provider',
          optionSearchPath: 'name'
        }
      }]
    }, {
      title: 'Availabilities',
      fields: [{
        id: 'availabilities',
        type: 'scheduleavailabilities',
        label: '',
        valuePath: 'driverAvailabilities',
        modelName: 'driver-availability',
        isMultiType: true,
        extra: {
          startTimePath: 'startTime',
          endTimePath: 'endTime',
          shiftStartPath: 'localShiftStart',
          shiftEndPath: 'localShiftEnd'
        }
      }]
    }]
  };
  _exports.default = _default;
});