define("adept-iq/utils/format-text-extension", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatTextExtension = formatTextExtension;

  function formatTextExtension(className, value) {
    return {
      className: className,
      value: value
    };
  }
});