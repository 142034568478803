define("adept-iq/pods/components/secondary-window/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['secondary-window'],
    title: null,
    isLoading: false,
    onXButtonClick: null,
    disableXButtonClick: false,

    didInsertElement() {
      this._super(...arguments);

      this._onKeyDown = event => {
        Ember.run.schedule('afterRender', this, 'onKeyDown', event);
      };

      this.$().on('keydown', this._onKeyDown);
    },

    willDestroyElement() {
      this.$().off('keydown', this._onKeyDown);
      this._onKeyDown = null;

      this._super(...arguments);
    },

    /*eslint-disable */
    onKeyDown(event) {
      switch (event.key) {
        case 'Escape':
          {
            event.preventDefault();
            return this.get('onXButtonClick')();
          }

        default:
          break;
      }
    },

    /*eslint-enable */
    actions: {
      onXButtonClick() {
        this.get('onXButtonClick')();
      }

    }
  });

  _exports.default = _default;
});