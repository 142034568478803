define("adept-iq/models/trip-stop", ["exports", "ember-data", "adept-iq/utils/unwrapProxy"], function (_exports, _emberData, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    plannedDistanceToNext: attr('string'),
    // in miles
    plannedTravelTimeToNext: attr('string'),
    // in minutes
    plannedEta: attr('date'),
    plannedRouteOrdinal: attr('number'),
    stopPoint: belongsTo('stop-point', {
      inverse: 'tripStop'
    }),
    pickTripStop: belongsTo('trip', {
      inverse: 'pick'
    }),
    dropTripStop: belongsTo('trip', {
      inverse: 'drop'
    }),
    segmentStop: belongsTo('segment-stop', {
      inverse: 'tripStop'
    }),
    travelNeeds: Ember.computed.readOnly('trip.segment.leg.legTravelNeeds'),
    lat: Ember.computed.readOnly('segmentStop.lat'),
    lng: Ember.computed.readOnly('segmentStop.lng'),
    type: Ember.computed.readOnly('segmentStop.type'),
    stopType: Ember.computed.readOnly('type'),
    // stops-widget uses stopType property for active context filtering
    trip: Ember.computed('pickTripStop', 'dropTripStop', function () {
      const trip = (0, _unwrapProxy.unwrapProxy)(this.get('pickTripStop'));
      return trip ? trip : (0, _unwrapProxy.unwrapProxy)(this.get('dropTripStop'));
    }),
    mapId: Ember.computed('type', function () {
      const stopType = this.get('type');
      return stopType;
    })
  });

  _exports.default = _default;
});