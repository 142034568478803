define("adept-iq/config/otp-criteria", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.OTP_DANGER = _exports.OTP_LATE = void 0;
  const OTP_LATE = 1;
  _exports.OTP_LATE = OTP_LATE;
  const OTP_DANGER = 0.8;
  _exports.OTP_DANGER = OTP_DANGER;
});