define("adept-iq/utils/regex-functions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.REGEX_EMAIL = _exports.REGEX_FLOAT = _exports.REGEX_NUMBER = _exports.REGEX_HHMM = void 0;
  const REGEX_HHMM = /^\d?\d?(:\d?\d?)?$/im;
  _exports.REGEX_HHMM = REGEX_HHMM;
  const REGEX_NUMBER = /^\d*$/im;
  _exports.REGEX_NUMBER = REGEX_NUMBER;
  const REGEX_FLOAT = /^\d*\.\d*$/im;
  _exports.REGEX_FLOAT = REGEX_FLOAT;
  const REGEX_EMAIL = /([0-9a-z]+@[a-z.]+\.[a-zA-Z0-9-.])\w+/im;
  _exports.REGEX_EMAIL = REGEX_EMAIL;
});