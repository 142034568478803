define("adept-iq/serializers/etanav-route", ["exports", "adept-iq/serializers/-dispatch", "adept-iq/config/environment"], function (_exports, _dispatch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dispatch.default.extend({
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (requestType === 'createRecord' && Ember.typeOf(payload.data) === 'array') {
        if (payload.data.length === 1) {
          payload.data = payload.data[0];
        }
      }

      return this._super(...arguments);
    },

    normalize(typeClass, hash) {
      if (_environment.default.APP.avlmLite && hash.relationships) {
        const {
          avlmRoute
        } = hash.relationships; // we are keeping the dispatchRoute as id so
        // only update 1 eta-route record per dispatchRoute

        hash.id = avlmRoute.data.id;
        return this._super(...arguments);
      }

      if (hash.relationships) {
        const {
          route
        } = hash.relationships; // we are keeping the dispatchRoute as id so
        // only update 1 eta-route record per dispatchRoute

        hash.id = route.data.id;
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});