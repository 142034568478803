define("adept-iq/classes/markers/stops/base", ["exports", "adept-iq/classes/markers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    label: Ember.computed.readOnly('stopLabel'),
    style: Ember.computed.readOnly('stopStyle'),
    // isHidden: computed.readOnly('isStopHidden'),
    // stop-specific params
    stopLabel: null,
    stopType: null,
    // 'pick', 'drop', 'garagePullin', 'garagePullout', 'break'
    otp: null,
    // 'L', 'D'
    isNext: false,
    isWheelchair: false,
    isPerformed: false,
    isNoShow: false
  });

  _exports.default = _default;
});