define("adept-iq/pods/components/iq-widgets/trips-widget/avlmConfig", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/trips-widget',
    rowComponent: 'table/rows/text-formatted-row',
    modelName: 'avlm-trip',
    defaultSortId: 'id',
    defaultSortAsc: false,
    title: 'Trips',
    columns: [{
      id: 'id',
      index: 3,
      type: 'text',
      label: 'ID',
      valuePath: 'id',
      valuePreview: '1',
      hidden: false,
      isMapLabelVisible: true,
      searchable: true,
      defaultWidth: 60
    }, {
      id: 'riderFirstName',
      index: 0,
      type: 'text',
      label: 'Passenger First Name',
      valuePath: 'avlmTripRider.avlmRider.firstName',
      hidden: false,
      searchable: true,
      defaultWidth: 75
    }, {
      id: 'riderLastName',
      index: 1,
      type: 'text',
      label: 'Passenger Last Name',
      valuePath: 'avlmTripRider.avlmRider.lastName',
      hidden: false,
      searchable: true,
      defaultWidth: 75
    }, {
      id: 'riderPhone',
      type: 'enum',
      index: 2,
      label: 'Passenger Phone Number',
      valuePath: 'rider.formattedPhoneNumbers',
      hidden: false,
      searchable: true,
      defaultWidth: 70,
      unAvailable: true
    }, {
      id: 'externalId',
      index: 11,
      type: 'text',
      label: 'Passenger ID',
      valuePath: 'avlmTripRider.avlmRider.bookingClientId',
      editable: true,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'tripType',
      index: 9,
      type: 'text',
      label: 'Type',
      valuePath: 'type',
      editable: true,
      hidden: false,
      defaultWidth: 60,
      unAvailable: true
    }, {
      id: 'routeName',
      index: 4,
      type: 'text',
      label: 'Route Name',
      valuePath: 'avlmRoute.trackingId',
      hidden: false,
      searchable: true,
      defaultWidth: 70
    }, {
      id: 'status',
      index: 5,
      type: 'text',
      label: 'Status',
      valuePath: 'statusLabel',
      hidden: false,
      isMapLabelVisible: true,
      defaultWidth: 75
    }, {
      id: 'statusReason',
      index: 6,
      type: 'text',
      label: 'Status Reason',
      valuePath: 'noShowCodeId',
      hidden: false,
      defaultWidth: 75
    }, {
      id: 'statusNotes',
      index: 27,
      type: 'text',
      label: 'Status Notes',
      valuePath: 'dsTrip.noShow.notes',
      hidden: true,
      defaultWidth: 75
    }, {
      id: 'otpStatus',
      index: 7,
      type: 'text',
      label: 'OTP Status',
      valuePath: 'otpLabel',
      valuePreview: 'On Time',
      hidden: false,
      highlightable: true
    }, {
      id: 'otp',
      index: 28,
      type: 'number',
      label: 'OTP',
      valuePath: 'otpValueWithSign',
      hidden: true,
      highlightable: true
    }, {
      id: 'promisedTime',
      index: 8,
      type: 'datetime',
      label: 'Promise Time',
      valuePath: 'avlmTripRider.avlmRider.anchoredRiderEvent.promisedTime',
      valuePreview: '09:00 AM',
      editable: true,
      format: 'MM-DD-YYYY hh:mm A',
      defaultWidth: 50
    }, {
      id: 'requestedTime',
      index: 10,
      type: 'time',
      label: 'Request Time',
      valuePath: 'avlmTripRider.avlmRider.anchoredRiderEvent.scheduledTime',
      valuePreview: '08:00 AM',
      editable: true,
      hidden: false,
      format: 'hh:mm A',
      defaultWidth: 50,
      searchable: true
    }, {
      id: 'anchorETA',
      index: 12,
      type: 'time',
      label: 'Anchor ETA',
      valuePath: 'avlmTripRider.avlmRider.anchoredRiderEvent.avlmStopPoint.eta',
      valuePreview: '16',
      hidden: true,
      format: 'hh:mm A',
      defaultWidth: 50
    }, {
      id: 'serviceStartTime',
      index: 13,
      type: 'date',
      label: 'Service Start Time',
      valuePath: 'avlmTripRider.avlmRider.anchoredRiderEvent.serviceStartTime',
      valuePreview: '?',
      editable: true,
      hidden: true,
      format: 'hh:mm A',
      defaultWidth: 50
    }, {
      id: 'serviceEndTime',
      index: 14,
      type: 'date',
      label: 'Service End Time',
      valuePath: 'avlmTripRider.avlmRider.anchoredRiderEvent.serviceEndTime',
      valuePreview: '?',
      editable: true,
      hidden: true,
      format: 'hh:mm A',
      defaultWidth: 50
    }, {
      id: 'anchor',
      index: 15,
      type: 'text',
      label: 'Anchor',
      valuePath: 'avlmTripRider.avlmRider.anchorStop',
      valuePreview: 'Pick',
      editable: true,
      hidden: false,
      defaultWidth: 60
    }, {
      id: 'pickupaddress',
      index: 16,
      type: 'text',
      label: 'Pickup Address',
      valuePath: 'pick.avlmAddress.streetAddress',
      editable: true,
      hidden: true,
      defaultWidth: 80
    }, {
      id: 'pickupCity',
      index: 17,
      type: 'text',
      label: 'Pickup City',
      valuePath: 'pick.avlmAddress.locality',
      editable: true,
      hidden: true,
      defaultWidth: 80
    }, {
      id: 'dropaddress',
      index: 18,
      type: 'text',
      label: 'Drop off Address',
      valuePath: 'drop.avlmAddress.streetAddress',
      editable: true,
      hidden: true,
      defaultWidth: 80
    }, {
      id: 'dropCity',
      index: 19,
      type: 'text',
      label: 'Drop off City',
      valuePath: 'drop.avlmAddress.locality',
      editable: true,
      hidden: true,
      defaultWidth: 80
    }, {
      id: 'passengerNotes',
      index: 21,
      type: 'text',
      label: 'Passenger Notes',
      valuePath: 'rider.notes',
      editable: true,
      hidden: true,
      searchable: true,
      defaultWidth: 80,
      unAvailable: true
    }, {
      id: 'notes',
      index: 20,
      type: 'text',
      label: 'Driver Notes',
      valuePath: 'avlmTripRider.avlmRider.anchoredRiderEvent.noteForDriver',
      editable: true,
      hidden: true,
      searchable: true,
      defaultWidth: 50
    }, {
      id: 'travelNeeds',
      index: 22,
      type: 'enum',
      label: 'Travel Needs',
      valuePath: 'dsTrip.formattedTravelNeeds',
      cellDesc: 'Travel Needs',
      editable: false,
      hidden: true,
      unAvailable: true
    }, {
      id: 'plannedMiles',
      index: 23,
      type: 'number',
      label: 'Planned Trip Miles',
      valuePath: 'plannedMiles',
      editable: false,
      hidden: true,
      defaultWidth: 50,
      unAvailable: true
    }, {
      id: 'actualMiles',
      index: 24,
      type: 'number',
      valuePath: 'avlmTrip.actualMiles',
      editable: false,
      hidden: true,
      defaultWidth: 50,
      unAvailable: true
    }, {
      id: 'fare',
      index: 25,
      type: 'text',
      label: 'Fare',
      valuePath: 'fare',
      editable: true,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'paymentType',
      index: 26,
      type: 'text',
      label: 'Payment Type',
      valuePath: 'dsTrip.segment.fareType.name',
      valuePreview: 'Single',
      editable: true,
      hidden: true,
      defaultWidth: 50,
      unAvailable: true
    }, {
      id: 'provider',
      type: 'string',
      label: 'Provider',
      valuePath: 'avlmRoute.providerName',
      valuePreview: 'MAG',
      hidden: true,
      highlightable: false
    }]
  };
  _exports.default = _default;
});