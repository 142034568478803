define("adept-iq/models/avlm-vehicle-radius", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    lat: attr('number'),
    lng: attr('number'),
    timestamp: attr('date'),
    vehicleId: attr('number'),
    receivedAt: attr('date'),
    heading: attr('number'),
    speed: attr('number'),
    odo: attr('number'),
    time: Ember.computed('timestamp', function () {
      return (0, _moment.default)(this.get('timestamp')).format('hh:mm A');
    }),
    latlng: Ember.computed('lat', 'lng', function () {
      if (!Ember.isNone(this.get('lat')) && !Ember.isNone(this.get('lng'))) {
        return [`${this.get('lat')}`, `${this.get('lng')}`];
      }

      return null;
    })
  });

  _exports.default = _default;
});