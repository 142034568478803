define("adept-iq/pods/index/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    user: Ember.inject.service(),

    model() {
      return new Ember.RSVP.Promise((resolve
      /*, reject*/
      ) => {
        this.fetchLastDashboard().then(dashboard => {
          if (dashboard) {
            resolve(dashboard);
            return;
          } // try to get default dashboard instead


          this.store.findAll('dashboard').then(dashboards => {
            let dashboard2 = dashboards.findBy('id', this.fetchDefaultDashboard());

            if (!dashboard2) {
              // default is missing, use whatever we can find
              dashboard2 = dashboards.get('firstObject');
            }

            resolve(dashboard2);
          });
        });
      });
    },

    fetchLastDashboard() {
      const lastDashboardId = localStorage.getItem('lastDashboardId'); // Road supervisor should not keep dashboard ID

      if (Ember.isNone(lastDashboardId) || this.user.isRoadSupEnable()) {
        return Ember.RSVP.resolve(null);
      }

      return new Ember.RSVP.Promise(resolve => {
        this.store.findRecord('dashboard', lastDashboardId).then(resolve).catch(() => {
          // don't try this again
          localStorage.removeItem('lastDashboardId');
          resolve(null);
        });
      });
    },

    fetchDefaultDashboard() {
      let DEFAULT_DASHBOARD_ID = 'workspaces-default/default';

      if (this.user.isRoadSupEnable()) {
        DEFAULT_DASHBOARD_ID = 'workspaces-default/road_supervisor_monitor';
      }

      return DEFAULT_DASHBOARD_ID;
    },

    afterModel(dashboard) {
      if (!dashboard) {
        return this.transitionTo('index.modals.create-dashboard');
      }

      this.transitionTo('dashboard', dashboard);
    }

  });

  _exports.default = _default;
});