define("adept-iq/pods/components/iq-widgets/edit-trip-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/edit-trip-form-widget/config", "moment"], function (_exports, _component, _config, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SCHEDULING_MODE = 'Scheduling Mode';

  var _default = _component.default.extend({
    editModal: Ember.inject.service(),
    geocode: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    editableSections: _config.default.editableSections,
    store: Ember.inject.service(),
    classNames: ['new-trip-form-widget'],

    init() {
      this._super(...arguments);

      const records = this.get('editableRecords') || [];
      const store = this.get('store');
      records.forEach(record => {
        const pickPlaceId = record.get('pick.segmentStop.place.id');
        const dropPlaceId = record.get('drop.segmentStop.place.id');

        if (!record.get('pick.segmentStop.place.address.id')) {
          Promise.all([store.findRecord('place', pickPlaceId), store.findRecord('place', dropPlaceId)]).then(result => {
            record.get('pick.segmentStop').set('place', result[0]);
            record.get('drop.segmentStop').set('place', result[1]);
          });
        } // if trip has a not a status of schedule we need to disable the scheduling mode option


        if (!record.get('isScheduled')) {
          const filteredSections = this.get('filteredSections');
          const modeSectionData = filteredSections.find(section => section.title === SCHEDULING_MODE);
          modeSectionData.fields.forEach(field => {
            field.editable = false;
          });
        }
      });
    },

    validateRecords() {
      let valid = true;
      const records = this.get('editableRecords') || [];
      this.set('editModal.errors', []);
      records.forEach(record => {
        const requestedTime = (0, _moment.default)(record.get('requestedTime'));

        if (Ember.isNone(requestedTime)) {
          valid = false;
          this.get('editModal.errors').pushObject('Date should be there');
        }

        if (Ember.isEmpty(record.get('pick.segmentStop.place.location.latlng'))) {
          valid = false;
          this.get('editModal.errors').pushObject('Pickup address should be there');
        } else if (Ember.isEmpty(record.get('pick.segmentStop.place.location.geoNode'))) {
          valid = false;
          this.get('editModal.errors').pushObject('Pickup address must be a valid mailing address');
        }

        if (Ember.isEmpty(record.get('drop.segmentStop.place.location.latlng'))) {
          valid = false;
          this.get('editModal.errors').pushObject('Drop address should be there');
        } else if (Ember.isEmpty(record.get('drop.segmentStop.place.location.geoNode'))) {
          valid = false;
          this.get('editModal.errors').pushObject('Drop address must be a valid mailing address');
        }
      });
      return valid;
    },

    actions: {
      // override undo because we will have to deal with undoing created
      // models for addresses, travel needs, and eligibility later.
      // IQUX-510
      onUndoClick() {
        const lastUndoState = this.get('editModal.undoHistory').popObject();

        if (lastUndoState === null) {
          const records = this.get('editableRecords') || [];
          records.forEach(record => {
            record.set('isForceDirty', false);
          });
        } else {
          lastUndoState.forEach(({
            record,
            properties
          }) => {
            record.setProperties(properties);
          });
        }

        this.set('isLastUndoStateCommitted', true);
      },

      onApplyClick() {
        if (this.validateRecords()) {
          const title = this.get('title');
          Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
          this.get('geocode').activateGeocode(false);
          this.set('addressRecord', null);
          this.get('service').apply(title);
        }
      }

    }
  });

  _exports.default = _default;
});