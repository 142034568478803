define("adept-iq/classes/active-contexts/avlm/map-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.mapContextGraph = _exports.mapContextNodes = _exports.alertNode = _exports.avlmMessageNode = _exports.avlmTripNode = _exports.avlmStopNode = _exports.avlmRouteNode = _exports.avlmVehicleNode = _exports.avlmDriverNode = _exports.avlmRiderNode = _exports.avlmTripRiderNode = void 0;
  // Rider Managament Related Nodes
  const avlmTripRiderNode = {
    id: 'avlm-trip-rider',
    modelName: 'avlm-trip-rider',
    links: [{
      type: 'belongsTo',
      nodeId: 'avlm-trip',
      path: 'avlmTrip'
    }],
    isActive: false,
    providerFilterKey: false
  };
  _exports.avlmTripRiderNode = avlmTripRiderNode;
  const avlmRiderNode = {
    id: 'avlm-rider',
    modelName: 'avlm-rider',
    links: [{
      type: 'belongsTo',
      nodeId: 'avlm-trip-rider',
      path: 'avlmTripRider'
    }],
    isActive: false,
    providerFilterKey: false
  }; // Driver Related Nodes

  _exports.avlmRiderNode = avlmRiderNode;
  const avlmDriverNode = {
    id: 'avlm-driver',
    modelName: 'avlm-driver',
    links: [{
      type: 'hasMany',
      nodeId: 'avlm-vehicle',
      path: 'latestVehicle'
    }],
    isActive: false,
    providerFilterKey: false
  }; // Vehicle Related Nodes

  _exports.avlmDriverNode = avlmDriverNode;
  const avlmVehicleNode = {
    id: 'avlm-vehicle',
    modelName: 'avlm-vehicle',
    links: [],
    isActive: false,
    providerFilterKey: 'providerName'
  };
  _exports.avlmVehicleNode = avlmVehicleNode;
  const avlmRouteNode = {
    id: 'avlm-route',
    modelName: 'avlm-route',
    links: [{
      type: 'belongsTo',
      nodeId: 'avlm-vehicle',
      path: 'latestVehicle'
    }, {
      type: 'hasMany',
      nodeId: 'avlm-stop',
      path: 'orderedStopsWithGaragesAndBreaks'
    }],
    isActive: true,
    leftTimeConstraint: 'tripStartTime',
    rightTimeConstraint: 'tripEndTime',
    providerFilterKey: 'providerName'
  };
  _exports.avlmRouteNode = avlmRouteNode;
  const avlmStopNode = {
    id: 'avlm-stop',
    modelName: 'avlm-stop-point',
    links: [],
    isActive: true,
    leftTimeConstraint: 'tripStartTime',
    rightTimeConstraint: 'tripEndTime',
    providerFilterKey: 'providerName'
  };
  _exports.avlmStopNode = avlmStopNode;
  const avlmTripNode = {
    id: 'avlm-trip',
    modelName: 'avlm-trip',
    links: [{
      type: 'hasMany',
      nodeId: 'avlm-stop',
      path: 'avlmStopPoints'
    }],
    isActive: true,
    leftTimeConstraint: 'routeTripStartTime',
    rightTimeConstraint: 'routeTripEndTime',
    providerFilterKey: 'avlmRoute.providerName'
  };
  _exports.avlmTripNode = avlmTripNode;
  const avlmMessageNode = {
    id: 'avlm-message',
    modelName: 'avlm-canned-message',
    links: [{
      type: 'belongsTo',
      nodeId: 'avlm-vehicle',
      path: 'avlmVehicle'
    }],
    isActive: true,
    leftTimeConstraint: 'tripStartTime',
    rightTimeConstraint: 'tripEndTime',
    providerFilterKey: 'avlmRoute.providerName'
  };
  _exports.avlmMessageNode = avlmMessageNode;
  const alertNode = {
    id: 'avlm-alert',
    modelName: 'avlm-alert',
    links: [{
      type: 'belongsTo',
      nodeId: 'avlm-vehicle',
      path: 'avlmVehicle'
    }],
    isActive: true,
    leftTimeConstraint: 'timestamp',
    rightTimeConstraint: 'timestamp',
    providerFilterKey: 'avlmVehicle.providerName'
  };
  _exports.alertNode = alertNode;
  const mapContextNodes = [// avlm graph
  avlmRouteNode, avlmTripNode, avlmStopNode, avlmVehicleNode, avlmMessageNode, // vehicle graph
  avlmVehicleNode, // driver graph
  avlmDriverNode, // rider graph
  avlmRiderNode, avlmTripRiderNode, // alerts
  alertNode];
  _exports.mapContextNodes = mapContextNodes;
  const mapContextGraph = mapContextNodes.reduce((obj, node) => {
    obj[node.id] = node;
    return obj;
  }, {});
  _exports.mapContextGraph = mapContextGraph;
  var _default = mapContextGraph;
  _exports.default = _default;
});