define("adept-iq/pods/components/iq-widgets/vehicles-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/vehicles-form-widget/config", "moment"], function (_exports, _component, _config, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import ENV from 'adept-iq/config/environment';
  // const TIME_ZONE = ENV.moment.timeZone ? ENV.moment.timeZone : 'America/Los_Angeles';
  var _default = _component.default.extend({
    editableSections: _config.default.editableSections,
    editModal: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['vehicle-form-widget', 'data-test-vehicle-form-widget'],

    validateRecords() {
      let valid = true;
      const zonetype = this.store.peekAll('zone-type').get('firstObject');
      const records = this.get('editableRecords') || [];
      this.set('editModal.errors', []);
      records.forEach(record => {
        const availabilities = record.vehicleAvailabilities || [];
        const startGarage = record.get('startGarageCopy');
        const endGarage = record.get('endGarageCopy');
        const shiftBreaks = record.shiftBreaks || [];
        const startAddress = startGarage.get('address');
        const endAddress = endGarage.get('address');

        if (Ember.isNone(record.get('mobileId'))) {
          record.set('mobileId', record.get('name'));
        }

        startAddress.set('zoneName', zonetype);
        endAddress.set('zoneName', zonetype);

        if (Ember.isNone(record.name) || record.name.trim().length < 1) {
          this.get('editModal.errors').pushObject('Vehicle Id must be present');
          valid = false;
        }

        if (!Ember.isNone(record.name) && isNaN(record.name)) {
          this.get('editModal.errors').pushObject('Vehicle Id must be a number');
          valid = false;
        }

        if (Ember.isNone(record.get('provider.id'))) {
          this.get('editModal.errors').pushObject('Provider cannot be empty');
          valid = false;
        }

        if (Ember.isNone(record.get('vehicleType.id'))) {
          this.get('editModal.errors').pushObject('Vehicle Type');
          valid = false;
        }

        if (Ember.isEmpty(startGarage.get('location.content.latlng'))) {
          valid = false;
          this.get('editModal.errors').pushObject('Start Garage address is required');
        } else if (Ember.isEmpty(startGarage.get('location.content.geoNode'))) {
          valid = false;
          this.get('editModal.errors').pushObject('Start Garage address is required');
        }

        if (Ember.isEmpty(endGarage.get('location.content.latlng'))) {
          valid = false;
          this.get('editModal.errors').pushObject('End Garage address is required');
        } else if (Ember.isEmpty(endGarage.get('location.content.geoNode'))) {
          valid = false;
          this.get('editModal.errors').pushObject('End Garage address is required');
        }

        availabilities.forEach(availability => {
          const startTime = (0, _moment.default)(availability.startTime);
          const endTime = (0, _moment.default)(availability.endTime);
          const shiftStartLocal = (0, _moment.default)('2020-01-01T' + availability.shiftStart); // converts time with timezone

          const shiftEndLocal = (0, _moment.default)('2020-01-01T' + availability.shiftEnd); // converts time with timezone

          const shiftStart = (0, _moment.default)(shiftStartLocal.format('HH:mm:ss'), 'HH:mm:ss'); // get local time only

          const shiftEnd = (0, _moment.default)(shiftEndLocal.format('HH:mm:ss'), 'HH:mm:ss'); // get local time only

          if (endTime.isBefore(startTime)) {
            valid = false;
            this.get('editModal.errors').pushObject('Effective end date cannot be before effective start date');
          }

          if (shiftEnd.isBefore(shiftStart)) {
            valid = false;
            this.get('editModal.errors').pushObject('Shift end cannot be before shift start');
          }
        });
        shiftBreaks.forEach((shiftBreak, index) => {
          const shiftStart = (0, _moment.default)(shiftBreak.shiftStart, 'HH:mm:ss');
          const shiftEnd = (0, _moment.default)(shiftBreak.shiftEnd, 'HH:mm:ss');
          shiftBreak.set('startTime', availabilities.objectAt(index).startTime);
          shiftBreak.set('endTime', availabilities.objectAt(index).endTime);
          shiftBreak.set('provider', record.get('provider'));

          if (Ember.isNone(shiftBreak.get('breakType.id'))) {
            valid = false;
            this.get('editModal.errors').pushObject('Break type cannot be empty');
          }

          if (shiftBreak.get('breakType.name') === 'fixed' && Ember.isNone(shiftBreak.get('place.location.geoNode'))) {
            valid = false;
            this.get('editModal.errors').pushObject('Break Address cannot be empty if it is of fixed type');
          }

          if (shiftEnd.isSameOrBefore(shiftStart)) {
            valid = false;
            this.get('editModal.errors').pushObject('Break end time cannot be same or before break start time');
          }
        });
      });
      return valid;
    },

    actions: {
      // override undo because we will have to deal with undoing created
      // models for addresses, travel needs, and eligibility later.
      // IQUX-510
      onUndoClick() {
        /*let lastUndoState = this.get('editModal.undoHistory').popObject();
         if (lastUndoState === null) {
          let records = this.get('editableRecords');
           records.forEach(record => {
            record.set('isForceDirty', false);
          });
         }
        else {
          lastUndoState.forEach(({ record, properties }) => {
            record.setProperties(properties);
          });
        }
         this.set('isLastUndoStateCommitted', true);*/
      },

      onApplyClick() {
        if (this.validateRecords()) {
          this.get('service').apply();
        }
      } // onCellValueChange(record, valuePath, value, options) {
      //   if (valuePath === 'vehicles.firstObject.name') {
      //     let vehicle = record.get('vehicles.firstObject');
      //     record.vehicles.removeObject(vehicle);
      //   }
      //   else {
      //     this.get('service').setRecordValue(record, valuePath, value, options);
      //   }
      // }


    }
  });

  _exports.default = _default;
});