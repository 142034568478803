define("adept-iq/pods/components/form-components/text-area/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L2RbG7qI",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"textarea\",null,[[\"rows\",\"disabled\",\"placeholder\",\"maxlength\",\"value\",\"focus-out\"],[\"3\",[27,\"readonly\",[[23,[\"disabled\"]]],null],\"Write a user activity log\",\"500\",[23,[\"value\"]],[27,\"action\",[[22,0,[]],\"onInput\",[23,[\"value\"]]],null]]]],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"hint\"],[9],[1,[23,[\"value\",\"length\"]],false],[0,\"/500\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/form-components/text-area/template.hbs"
    }
  });

  _exports.default = _default;
});