define("adept-iq/models/location", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    lat: attr('number'),
    lng: attr('number'),
    geoNode: attr('number'),
    address: belongsTo('address'),
    places: hasMany('place'),
    latlng: Ember.computed('lat', 'lng', function () {
      if (!Ember.isNone(this.get('lat')) && !Ember.isNone(this.get('lng'))) {
        return `${this.get('lat')}/${this.get('lng')}`;
      }

      return '';
    })
  });

  _exports.default = _default;
});