define("adept-iq/serializers/avlm-canned-message", ["exports", "adept-iq/serializers/-avlm-avlmService", "adept-iq/models/avlm-emergency-message"], function (_exports, _avlmAvlmService, _avlmEmergencyMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _avlmAvlmService.default.extend({
    attrs: {
      responseMessage: {
        serialize: false
      },
      ackTime: {
        serialize: false
      },
      driverEndPointType: {
        serialize: false
      }
    },

    serialize()
    /* snapshot, options */
    {
      const json = this._super(...arguments);

      let driver = null;

      if (json.data.relationships) {
        if (json.data.relationships.driver) {
          driver = this.store.peekRecord('driver', json.data.relationships.driver.data.id);
        }
      }

      const responseType = json.data.attributes.responseTypeText;
      json.data.attributes.dispatcher = {
        endPointType: 'sender'
      };
      json.data.attributes.responseType = {
        statusCode: _avlmEmergencyMessage.CANNED_RESPONSE_TYPE_CODES[responseType],
        status: responseType
      };

      if (driver) {
        json.data.attributes.driver = {
          endPointType: 'recipient',
          id: driver.get('driverId')
        };
      }

      if (!json.data.attributes.deletedTime) delete json.data.attributes.deletedTime;
      if (!json.data.attributes.readTime) delete json.data.attributes.readTime;
      if (!json.data.attributes.deliveredTime) delete json.data.attributes.deliveredTime;
      if (!json.data.attributes.type) delete json.data.attributes.type;
      delete json.data.attributes.responseTypeText;
      delete json.data.relationships;
      return json;
    }

  });

  _exports.default = _default;
});