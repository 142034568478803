define("adept-iq/serializers/sso-user", ["exports", "adept-iq/serializers/-sso-ssoService", "lodash"], function (_exports, _ssoSsoService, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ssoSsoService.default.extend({
    serialize(snapshot, options = {}) {
      options.includeId = true;

      const json = this._super(snapshot, options);

      if (json.data && json.data.attributes) {
        const roles = _lodash.default.map(json.data.attributes.roleNames, role => {
          return role.roleName;
        });

        return {
          displayName: json.data.attributes.displayName,
          email: json.data.attributes.email,
          status: json.data.attributes.status,
          roles: roles
        };
      }

      return {
        displayName: '',
        email: '',
        status: '',
        roles: ''
      };
    },

    /*eslint-disable */
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      // sso endpoint is not JSON-API compliant
      if (Ember.isArray(payload)) {
        payload = {
          data: payload
        };
      } else if (!payload.hasOwnProperty('jsonapi')) {
        // single record returns as a POJO for editing a user's status or role
        const userPayload = {
          data: {
            attributes: {
              email: payload.email,
              firstName: payload.firstName,
              lastName: payload.lastName,
              status: payload.status,
              roleNames: []
            },
            type: 'sso-user',
            id: ''
          }
        };

        const roles = _lodash.default.map(payload.UserRoleRoles, role => {
          return {
            roleName: role.roleName,
            displayName: role.displayName
          };
        });

        userPayload.data.attributes.roleNames = roles;
        userPayload.data.id = payload.userId;
        payload = userPayload;
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    /*eslint-enable */
    normalize(modelClass, resourceHash) {
      // reconciles difference between streaming API and app model
      const {
        roleNames
      } = resourceHash.attributes;

      if (roleNames) {
        resourceHash.attributes.roleNames = roleNames.map(item => {
          if (typeof item === 'object') return item;
          return {
            roleName: item.toString(),
            displayName: Ember.String.capitalize(item.toString())
          };
        });
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});