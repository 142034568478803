define("adept-iq/config/widget-groups", ["exports", "adept-iq/config/widget-types"], function (_exports, _widgetTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 'widgets-1',
    title: 'Resources',
    widgetTypes: [_widgetTypes.passengersWidgetType, _widgetTypes.driversWidgetType, _widgetTypes.vehiclesWidgetType]
  }, {
    id: 'widgets-2',
    title: 'Work',
    widgetTypes: [_widgetTypes.mapWidgetType, _widgetTypes.messagesWidgetType, _widgetTypes.alertsWidgetType, _widgetTypes.routesWidgetType, _widgetTypes.tripsWidgetType, _widgetTypes.stopsWidgetType, _widgetTypes.subscriptionsWidgetType, _widgetTypes.zonesWidgetType]
  }, {
    id: 'metrics',
    title: 'Metrics',
    widgetTypes: [_widgetTypes.scheduleWidgetType, _widgetTypes.metricsWidgetType, _widgetTypes.gaugeWidgetType, _widgetTypes.boxWidgetType, _widgetTypes.donutChartWidgetType, _widgetTypes.barChartWidgetType]
  }, {
    id: 'config',
    title: 'Configuration',
    widgetTypes: [_widgetTypes.usersWidgetType, _widgetTypes.rolesWidgetType]
  }, {
    id: 'test',
    title: 'Test Tools',
    widgetTypes: [_widgetTypes.scatterChartWidgetType]
  }];
  _exports.default = _default;
});