define("adept-iq/pods/components/iq-widgets/message-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/message-widget/config", "adept-iq/pods/components/iq-widgets/message-widget/avlmConfig", "adept-iq/config/environment", "adept-iq/config/mapped-permIds"], function (_exports, _component, _config, _avlmConfig, _environment, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['message-widget', 'data-test-message-widget'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    user: Ember.inject.service(),
    config: _config.default,

    init() {
      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      if (this.user.isRoadSupEnable()) {
        this.set('tableActions', [{
          id: 'sendMessage',
          permId: [_mappedPermIds.default.cannedMessageToDriver, _mappedPermIds.default.freeTypeMessageToDriver, _mappedPermIds.default.messageToDispatch],
          name: 'Send Message',
          action: () => {
            this.sendMessage();
          }
        }, {
          id: 'showAll',
          permId: _mappedPermIds.default.showAllMessage,
          name: 'Show All',
          action: () => {
            const rows = this.get('table.rows') || [];
            rows.forEach(r => {
              r.set('hidden', false);
            });
          }
        }]);
      } else {
        this.set('tableActions', [{
          id: 'showAll',
          permId: _mappedPermIds.default.showAllMessage,
          name: 'Show All',
          action: () => {
            const rows = this.get('table.rows') || [];
            rows.forEach(r => {
              r.set('hidden', false);
            });
          }
        }]);
      }

      this.set('bulkActions', [{
        id: 'readAll',
        permId: _mappedPermIds.default.readMessages,
        name: 'Read All',
        action: models => {
          const promises = models.map(model => {
            model.set('readTime', new Date());
            return model.save();
          });
          return Promise.all(promises);
        }
      }]);
      this.set('readAction', {
        id: 'read',
        permId: _mappedPermIds.default.readMessages,
        name: 'Read',
        action: model => {
          model.set('readTime', new Date());
          return model.save();
        }
      });
      this.set('hideAction', {
        id: 'hide',
        permId: _mappedPermIds.default.hideMessages,
        name: 'Hide',
        action: model => {
          const row = this.get('table.rows').findBy('id', model.get('id'));
          row.set('hidden', true);
          model.set('readTime', new Date());
          return model.save();
        }
      });
    },

    async sendMessage() {
      if (localStorage.getItem('dashboard-info')) {
        const dashboardInfo = localStorage.getItem('dashboard-info');
        const parsedDashboardInfo = JSON.parse(dashboardInfo);

        if (parsedDashboardInfo && parsedDashboardInfo.accessData) {
          const accessDataObj = parsedDashboardInfo.accessData;
          this.loggedRoadSupervisorVehicle = accessDataObj.vehicleId;
          this.loggedRoadSupervisorDriver = accessDataObj.driverId;
        }
      }

      const draftMessages = [this.get('store').createRecord('avlm-canned-message', {
        vehicleId: this.loggedRoadSupervisorVehicle,
        driverId: this.loggedRoadSupervisorDriver
      })];
      this.get('workspace').pushState('addNewMessage', {
        draftMessages
      });
    },

    dynamicSingleActions: Ember.computed('table.rows.@each.{selected,status}', function () {
      const hideAction = this.get('hideAction');
      const readAction = this.get('readAction');
      const rows = this.get('table.rows') || [];
      const row = rows.findBy('selected', true);

      if (!row) {
        return [];
      } // @TODO: extract statuses to constants


      switch (row.get('status')) {
        case 'Received':
          if (row.get('readString') === 'Yes') {
            return [hideAction];
          }

          return [readAction, hideAction];

        case 'Sending':
        case 'Sent':
        case 'Deleted':
          return [hideAction];

        default:
          return [];
      }
    })
  });

  _exports.default = _default;
});