define("adept-iq/services/speech", ["exports", "adept-iq/config/phonetic-substitutions"], function (_exports, _phoneticSubstitutions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_OPTIONS = {
    pitch: 1,
    rate: 0.8
  };
  const PREFERRED_VOICE_URI = 'Samantha';
  const INTERVAL_TIME = 250;
  const SPEECH_SYNTHESIS = window.speechSynthesis;

  function getVoices() {
    if (!SPEECH_SYNTHESIS) {
      return Promise.resolve();
    }

    return new Promise(function (resolve) {
      (function checkVoices() {
        const voices = SPEECH_SYNTHESIS.getVoices();

        if (voices.length === 0) {
          setTimeout(checkVoices, INTERVAL_TIME);
        } else {
          resolve(voices);
        }
      })();
    });
  }

  var _default = Ember.Service.extend({
    voiceConfig: null,
    voiceConfigs: null,

    getVoiceConfig() {
      const {
        voiceConfig
      } = this;

      if (voiceConfig) {
        return Promise.resolve(voiceConfig);
      }

      return getVoices().then(voices => {
        const voiceConfigs = voices.filterBy('lang', 'en-US').map(voice => {
          return {
            voice,
            rate: DEFAULT_OPTIONS.rate,
            pitch: DEFAULT_OPTIONS.pitch
          };
        }); // this.set('voiceConfigs', voiceConfigs);

        const preferredVoiceConfig = voiceConfigs.findBy('voice.voiceURI', PREFERRED_VOICE_URI);
        return this.set('voiceConfig', preferredVoiceConfig || voiceConfigs[0]);
      });
    },

    speak(text, options = {}) {
      if (!SPEECH_SYNTHESIS) return Promise.resolve();
      return this.getVoiceConfig().then(voiceConfig => {
        const phoneticText = _phoneticSubstitutions.phoneticSubstitutions.reduce((str, substitution) => {
          const {
            regex,
            replacement
          } = substitution;
          return str.replace(regex, replacement);
        }, text);

        const utterance = new SpeechSynthesisUtterance(phoneticText);
        Object.assign(utterance, voiceConfig, options);
        speechSynthesis.speak(utterance);
      }).then(() => this.resolveSpeech());
    },

    // rotateVoiceConfig() {
    //   const voiceConfig = this.get('voiceConfig');
    //   const voiceConfigs = this.get('voiceConfigs');
    //   const index = voiceConfigs.indexOf(voiceConfig);
    //   const nextIndex = (index + 1) % voiceConfigs.length;
    //   this.set('voiceConfig', voiceConfigs[nextIndex]);
    // },
    resolveSpeech() {
      if (!SPEECH_SYNTHESIS.speaking) {
        return Promise.resolve();
      }

      return new Ember.RSVP.Promise(resolve => {
        const interval = setInterval(() => {
          if (!SPEECH_SYNTHESIS.speaking) {
            clearInterval(interval);
            resolve();
          }
        }, INTERVAL_TIME);
      });
    }

  });

  _exports.default = _default;
});