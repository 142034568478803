define("adept-iq/pods/components/generic-widgets/form-widget/section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    section: null,
    isOpen: true,
    actions: {
      onHeaderClick() {
        this.toggleProperty('isOpen');
      }

    }
  });

  _exports.default = _default;
});