define("adept-iq/classes/markers/vehicles/iq", ["exports", "adept-iq/classes/markers/vehicles/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RS_ID_PREFIX = 'SUP';

  var _default = _base.default.extend({
    record: null,
    // this is an `iq-vehicle` model
    iqVehicle: Ember.computed.alias('record'),
    // `BaseMarker` overrides
    isActive: true,
    lat: Ember.computed.readOnly('iqVehicle.lat'),
    lng: Ember.computed.readOnly('iqVehicle.lng'),
    rotationAngle: Ember.computed.readOnly('iqVehicle.heading'),
    otp: Ember.computed.readOnly('iqVehicle.otp'),
    // `BaseVehicleMarker` overrides
    isMoving: Ember.computed('iqVehicle.heading', function () {
      return !Ember.isNone(this.get('iqVehicle.heading'));
    }),
    isBreakdown: Ember.computed.readOnly('iqVehicle.isBroken'),
    isOnBreak: Ember.computed.readOnly('iqVehicle.isOnBreak'),
    isCommFailure: Ember.computed.readOnly('iqVehicle.isCommFailure'),
    isLeaveGarageSignOff: Ember.computed.readOnly('iqVehicle.isLeaveGarageSignOff'),
    isPoweredOn: Ember.computed.readOnly('iqVehicle.isPoweredOn'),
    isGpsFailure: Ember.computed.readOnly('iqVehicle.isGpsFailure'),
    isHighLighted: Ember.computed.readOnly('iqVehicle.isHighLighted'),
    isEmergencyVehicle: Ember.computed.readOnly('iqVehicle.isEmergencyVehicle'),
    isApprovedEmergency: Ember.computed.readOnly('iqVehicle.isApprovedEmergency'),
    isRoadSupervisor: Ember.computed.equal('trackingIdPrefix', RS_ID_PREFIX),
    isGrouped: Ember.computed.not('isEmergencyVehicle'),
    isLoggedInRoadSupervisor: Ember.computed('iqVehicle.id', 'myRSVehicleId', function () {
      if (this.get('iqVehicle.id') === this.get('myRSVehicleId')) {
        return true;
      }

      return false;
    }),
    // local properties
    trackingIdPrefix: Ember.computed('iqVehicle.latestRoute.trackingId', function () {
      const trackingId = this.get('iqVehicle.latestRoute.trackingId');
      if (Ember.isNone(trackingId)) return null;
      return trackingId.split('-')[0];
    }),
    myRSVehicleId: Ember.computed('', function () {
      const dashboardInfo = localStorage.getItem('dashboard-info');
      if (!dashboardInfo) return null;

      try {
        const parsedDashboardInfo = JSON.parse(dashboardInfo);

        if (parsedDashboardInfo && parsedDashboardInfo.accessData) {
          return parsedDashboardInfo.accessData.vehicleId;
        }
      } catch (e) {
        // malformed JSON
        return null;
      }

      return null;
    })
  });

  _exports.default = _default;
});