define("adept-iq/pods/components/side-drawer/perform-stop/pick/ui/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INTERVAL_DELAY = 60000;
  const TravelNeedItem = Ember.Object.extend({
    type: null,
    count: null
  });

  var _default = Ember.Component.extend({
    classNames: ['perform-stop-drawer'],
    timeFormat: 'm-d-Y G:i K',
    pcaCount: null,
    serviceAnimalCount: null,
    companionCount: null,
    shouldArrive: true,
    // pass in data to component
    stopPoint: null,
    travelNeedOptions: null,
    travelNeedItems: null,
    errorText: null,
    actualPickTime: null,
    actualArriveTime: null,
    minCompanionCount: null,
    maxCompanionCount: null,
    arrive: false,
    depart: false,
    isArrived: null,
    isNextDisabled: null,
    maxDate: null,
    // progress interval every minute
    _interval: null,
    trip: Ember.computed.readOnly('stopPoint.trip'),
    dispatchRoute: Ember.computed.readOnly('stopPoint.dispatchRoute'),
    route: Ember.computed.readOnly('dispatchRoute.route'),
    rider: Ember.computed.readOnly('trip.rider'),
    address: Ember.computed.readOnly('stopPoint.tripStop.segmentStop.place.address'),
    isArriveDisabled: Ember.computed.or('depart', 'isArrived'),
    routeVehicleDriver: Ember.computed.readOnly('dispatchRoute.latestRouteVehicleDriver'),
    driver: Ember.computed.readOnly('routeVehicleDriver.driver'),
    minTime: Ember.computed.readOnly('stopPoint.minPerformedTime'),
    unusedTravelNeedOptions: Ember.computed('travelNeedOptions', 'travelNeedItems.@each.type', function () {
      const travelNeedOptions = this.get('travelNeedOptions') || [];
      const travelNeedItems = this.get('travelNeedItems') || [];
      const usedTravelNeedTypes = travelNeedItems.mapBy('type');
      return travelNeedOptions.reject(({
        type
      }) => {
        return usedTravelNeedTypes.includes(type);
      });
    }),

    setMinMaxTravelNeedCount(travelNeedItem) {
      const count = travelNeedItem ? travelNeedItem.get('count') : 0; // create min count for each travel need

      if (count === 0) travelNeedItem.set('minCount', count);else travelNeedItem.set('minCount', count - 1); // create max count for each travel need

      travelNeedItem.set('maxCount', count + 1);
    },

    init() {
      this._super(...arguments);

      this.set('maxDate', new Date());
      this.get('minTime'); // create a maxDate ticker that progresses by a minute

      this._interval = setInterval(() => {
        Ember.run.schedule('afterRender', () => {
          if (this.get('isDestroyed')) return;
          this.set('maxDate', new Date());
        });
      }, INTERVAL_DELAY);
    },

    destroy() {
      clearInterval(this._interval);
      this._interval = null;
    },

    setPickTTimeInArrivalTime() {
      const pickTime = this.get('stopPoint.departTimestamp');
      const arriveTime = this.get('stopPoint.arriveTimestamp');

      if ((0, _moment.default)(pickTime).isBefore(arriveTime, 'minutes')) {
        this.set('stopPoint.departTimestamp', arriveTime);
      }
    },

    actions: {
      deleteTravelNeedItem(travelNeedItem) {
        this.get('travelNeedItems').removeObject(travelNeedItem);
      },

      addTravelNeedItem() {
        const travelNeedItems = this.get('travelNeedItems');
        const unusedTravelNeedOptions = this.get('unusedTravelNeedOptions');
        if (Ember.isEmpty(unusedTravelNeedOptions)) return;
        const travelNeedItem = TravelNeedItem.create({
          type: unusedTravelNeedOptions[0].type,
          count: 1
        });
        travelNeedItems.pushObject(travelNeedItem);
        this.setMinMaxTravelNeedCount(travelNeedItem);
      },

      onTravelNeedChange(travelNeed, event) {
        const value = event.currentTarget.value ? parseInt(event.currentTarget.value, 10) : null;
        const minCount = travelNeed.get('minCount');
        const maxCount = travelNeed.get('maxCount');

        if (Ember.isNone(value)) {
          travelNeed.set('count', value);
        } else if (value > maxCount) {
          travelNeed.set('count', maxCount);
        } else if (value < minCount) {
          travelNeed.set('count', minCount);
        } else travelNeed.set('count', value);

        const count = travelNeed.get('count');
        event.currentTarget.value = count;
      },

      onCompanionChange(event) {
        const value = event.currentTarget.value ? parseInt(event.currentTarget.value, 10) : null;
        const minCount = this.get('minCompanionCount');
        const maxCount = this.get('maxCompanionCount');

        if (Ember.isNone(value)) {
          this.set('companionCount', value);
        } else if (value > maxCount) {
          this.set('companionCount', maxCount);
        } else if (value < minCount) {
          this.set('companionCount', minCount);
        } else this.set('companionCount', value);

        const companionCount = this.get('companionCount');
        event.currentTarget.value = companionCount;
      },

      selectTravelNeedTypeOption(travelNeedItem, option) {
        travelNeedItem.set('type', option.type);
      },

      onClickDepart(depart) {
        if (depart) {
          this.set('arrive', depart);
          this.set('depart', depart);
          this.set('isNextDisabled', false);
          return;
        }

        this.set('depart', depart);
        this.set('isNextDisabled', true);
      },

      onPCAToggle(event) {
        this.set('pcaCount', event.target.checked ? 1 : 0);
      },

      onServiceAnimalToggle(event) {
        this.set('serviceAnimalCount', event.target.checked ? 1 : 0);
      },

      onActualPickTimeChange(dateValues) {
        const momentTime = (0, _moment.default)(dateValues[0]);
        this.set('stopPoint.departTimestamp', momentTime.toISOString());
        this.setPickTTimeInArrivalTime();
      },

      onActualArriveTimeChange(dateValues) {
        const momentTime = (0, _moment.default)(dateValues[0]);
        this.set('stopPoint.arriveTimestamp', momentTime.toISOString());
        this.setPickTTimeInArrivalTime();
      }

    }
  });

  _exports.default = _default;
});