define("adept-iq/pods/components/iq-widgets/routes-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/routes-form-widget/config", "adept-iq/pods/components/iq-widgets/routes-form-widget/scheduleConfig", "moment", "adept-iq/models/dashboard"], function (_exports, _component, _config, _scheduleConfig, _moment, _dashboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    editableSections: _config.default.editableSections,
    editModal: Ember.inject.service(),
    notifications: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    classNames: ['data-test-route-form-widget'],

    init() {
      this._super(...arguments);

      const role = this.get('workspace.dashboardModel.role');

      if (role === _dashboard.SCHEDULE_ROLENAME) {
        this.set('editableSections', _scheduleConfig.default.editableSections);
      }
    },

    validateRecords() {
      let valid = true;
      const records = this.get('editableRecords');
      const routeMaxParam = this.get('store').peekRecord('cs-config-item', 'config-UX_parameters/routeMaxExtension');
      const routeMaxExtension = routeMaxParam ? parseInt(routeMaxParam.get('value'), 10) : 60; // minutes

      this.set('editModal.errors', []);
      records.forEach(record => {
        const rdv = record.get('latestRouteVehicleDriver'); // make sure we apply the date to the plannedStartTime and plannedEndTime

        const plannedStartTime = (0, _moment.default)(rdv.get('startTime'));
        const plannedEndTime = (0, _moment.default)(rdv.get('endTime'));
        const originalPlannedStartTime = (0, _moment.default)(rdv.get('originalPlannedStartTime'));
        const originalPlannedEndTime = (0, _moment.default)(rdv.get('originalPlannedEndTime'));
        const lastDropEta = record.get('lastDropStop.eta');
        const lastDropPromisedTime = record.get('lastDropStop.promisedTime');

        if (plannedStartTime.isAfter(plannedEndTime)) {
          valid = false;
          this.get('editModal.errors').pushObject('Start Time cannot be after End Time');
        }

        if (lastDropEta) {
          const lastDropEtaMoment = (0, _moment.default)(lastDropEta);

          if (plannedEndTime.isBefore(lastDropEtaMoment)) {
            valid = false;
            this.get('editModal.errors').pushObject('End Time cannot be before the last stop');
          }
        } else if (lastDropPromisedTime) {
          const lastDropPromisTimeMoment = (0, _moment.default)(lastDropPromisedTime);

          if (plannedEndTime.isBefore(lastDropPromisTimeMoment)) {
            valid = false;
            this.get('editModal.errors').pushObject('End Time cannot be before the last stop');
          }
        }

        const routeMaxAdjustments = routeMaxExtension;
        const startTimeDiff = plannedStartTime.diff(originalPlannedStartTime, 'minutes');

        if (Math.abs(startTimeDiff) > routeMaxAdjustments) {
          valid = false;
          this.get('editModal.errors').pushObject(`Start Time cannot be adjusted more than ${routeMaxAdjustments} minutes`);
        }

        const endTimeDiff = plannedEndTime.diff(originalPlannedEndTime, 'minutes');

        if (Math.abs(endTimeDiff) > routeMaxAdjustments) {
          valid = false;
          this.get('editModal.errors').pushObject(`End Time cannot be adjusted more than ${routeMaxAdjustments} minutes`);
        }

        if (Ember.isEmpty(record.get('route.tripLimit'))) {
          record.set('route.tripLimit', 0);
        }

        if (Ember.isEmpty(record.get('route.distanceLimit'))) {
          record.set('route.distanceLimit', 0);
        }
      });
      return valid;
    },

    validateRecordsForScheduling() {
      let valid = true;
      const records = this.get('editableRecords');
      const routeMaxParam = this.get('store').peekRecord('cs-config-item', 'config-UX_parameters/routeMaxExtension');
      const routeMaxExtension = routeMaxParam ? parseInt(routeMaxParam.get('value'), 10) : 60; // minutes

      this.set('editModal.errors', []);
      records.forEach(record => {
        // make sure we apply the date to the plannedStartTime and plannedEndTime
        const plannedStartTime = (0, _moment.default)(record.get('plannedStartTime'));
        const plannedEndTime = (0, _moment.default)(record.get('plannedEndTime'));
        const originalPlannedStartTime = (0, _moment.default)(record.get('plannedStartTime'));
        const originalPlannedEndTime = (0, _moment.default)(record.get('plannedEndTime'));

        if (plannedStartTime.isAfter(plannedEndTime)) {
          valid = false;
          this.get('editModal.errors').pushObject('Start Time cannot be after End Time');
        }

        const routeMaxAdjustments = routeMaxExtension;
        const startTimeDiff = plannedStartTime.diff(originalPlannedStartTime, 'minutes');

        if (Math.abs(startTimeDiff) > routeMaxAdjustments) {
          valid = false;
          this.get('editModal.errors').pushObject(`Start Time cannot be adjusted more than ${routeMaxAdjustments} minutes`);
        }

        const endTimeDiff = plannedEndTime.diff(originalPlannedEndTime, 'minutes');

        if (Math.abs(endTimeDiff) > routeMaxAdjustments) {
          valid = false;
          this.get('editModal.errors').pushObject(`End Time cannot be adjusted more than ${routeMaxAdjustments} minutes`);
        }

        if (Ember.isEmpty(record.get('tripLimit'))) {
          record.set('tripLimit', 0);
        }

        if (Ember.isEmpty(record.get('distanceLimit'))) {
          record.set('distanceLimit', 0);
        }
      });
      return valid;
    },

    actions: {
      // override undo because we will have to deal with undoing created
      // models for addresses, travel needs, and eligibility later.
      // IQUX-510
      onUndoClick() {},

      onApplyClick() {
        const role = this.get('workspace.dashboardModel.role');

        if (role === _dashboard.DISPATCH_ROLENAME) {
          if (this.validateRecords()) {
            const title = this.get('title');
            this.get('service').apply(title);
          }

          return;
        }

        if (this.validateRecordsForScheduling()) {
          const title = this.get('title');
          this.get('service').apply(title);
        }
      }

    }
  });

  _exports.default = _default;
});