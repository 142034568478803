define("adept-iq/serializers/driver-availability", ["exports", "adept-iq/serializers/-driver", "moment"], function (_exports, _driver, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _driver.default.extend({
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (requestType === 'createRecord' && Ember.typeOf(payload.data) === 'array') {
        if (payload.data.length === 1) {
          payload.data = payload.data[0];
        }
      }

      return this._super(...arguments);
    },

    normalize(typeClass, resourceHash) {
      const {
        attributes
      } = resourceHash;

      if (attributes) {
        const {
          days
        } = resourceHash.attributes;

        if (days) {
          delete resourceHash.attributes.days;
          days.forEach((value, i) => {
            const weekday = _moment.default.weekdays(i).toLowerCase();

            resourceHash.attributes[weekday] = value;
          });
        }
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});