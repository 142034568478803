define("adept-iq/pods/components/iq-widgets/playback-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    title: 'Playback',
    vehiclesTableColumns: [{
      width: '40px',
      resizable: false,
      cellComponent: 'table/cells/radio-button'
    }, {
      label: 'Vehicle ID',
      valuePath: 'vehicleId',
      resizable: true,
      minResizeWidth: 50,
      component: 'iq-widgets/playback-widget/table-header'
    }],
    vehicleEventsTableColumns: [{
      width: '40px',
      resizable: false,
      valuePath: 'isChecked',
      cellComponent: 'iq-widgets/playback-widget/check-box',
      component: 'table/columns/check-box'
    }, {
      label: 'Time',
      valuePath: 'time',
      resizable: true,
      minResizeWidth: 50,
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Vehicle ID',
      valuePath: 'vehicle.id',
      resizable: true,
      minResizeWidth: 50,
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Latitude',
      valuePath: 'lat',
      resizable: true,
      minResizeWidth: 50,
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Longitude',
      valuePath: 'lng',
      resizable: true,
      minResizeWidth: 50,
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Event Type',
      valuePath: 'eventType',
      resizable: true,
      minResizeWidth: 50,
      component: 'iq-widgets/playback-widget/table-header'
    }],
    route: {
      model: 'route',
      indexPath: 'route.name',
      vehiclePath: 'vehicle',
      driverPath: 'latestDriver'
    },
    vehicle: {
      model: 'vehicle',
      indexPath: 'name',
      routePath: 'latestRoute',
      driverPath: 'latestDriver'
    },
    driver: {
      model: 'driver',
      indexPath: 'driverId'
    }
  };
  _exports.default = _default;
});