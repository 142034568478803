define("adept-iq/pods/components/form-components/datetime/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DATETIME_FORMAT = /^\d\d\d\d-\d\d-\d\d \d\d:\d\d (AM|PM)$/;

  var _default = Ember.Component.extend({
    classNames: ['form-components-datetime'],
    value: null,
    disabled: false,
    actions: {
      onInput(value) {
        if (!DATETIME_FORMAT.test(value)) return;
        this.get('onChange')((0, _moment.default)(value, 'YYYY-MM-DD hh:mm A').toDate());
      }

    }
  });

  _exports.default = _default;
});