define("adept-iq/serializers/rider-eligibility", ["exports", "adept-iq/serializers/rider", "adept-iq/serializers/-rms-riderManagement"], function (_exports, _rider, _rmsRiderManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rmsRiderManagement.default.extend({
    attrs: {},

    serialize()
    /* snapshot, options */
    {
      const json = this._super(...arguments);

      if (json.data && json.data.attributes) {
        // startDate and endDate should be set to start and end of day.
        const startDate = new Date(json.data.attributes.from);
        const endDate = new Date(json.data.attributes.to);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        json.data.attributes.from = startDate.toISOString();
        json.data.attributes.to = endDate.toISOString();
        json.data.type = 'riderEligibility';

        if (!Ember.isNone(json.data.relationships)) {
          const eligibilityTypeRelationship = json.data.relationships.eligibilityType;

          if (!Ember.isNone(eligibilityTypeRelationship)) {
            eligibilityTypeRelationship.data.type = 'eligibilityTypeName';
            json.data.relationships.eligibilityTypeName = json.data.relationships.eligibilityType;
            delete json.data.relationships.eligibilityType;
          }

          const riderRelationship = json.data.relationships.rider;

          if (!Ember.isNone(riderRelationship)) {
            riderRelationship.data.type = 'rider';
          }
        }
      }

      return json;
    },

    payloadKeyFromModelName(modelName) {
      return modelName;
    },

    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.attributes) && !Ember.isNone(resourceHash.attributes.eligibilityTypeName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.eligibilityType = {
          data: {
            type: 'eligibility-type',
            id: resourceHash.attributes.eligibilityTypeName
          }
        };
        resourceHash.relationships = relationships;
        delete resourceHash.attributes.eligibilityTypeName;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeResponse: function (store, primaryModelClass, payload) {
      const riderSerializer = new _rider.default();

      if (!Ember.isNone(payload.included) && !Ember.isNone(payload.included.length)) {
        for (let i = 0; i < payload.included.length; i++) {
          const include = payload.included[i];

          if (include.type === 'rider') {
            riderSerializer.normalizeResponse(store, primaryModelClass, {
              data: include
            });
          }

          if (include.type === 'eligibilityType' || include.type === 'eligibilityTypeName') {
            include.type = 'eligibility-type';
          }
        }
      }

      return payload;
    }
  });

  _exports.default = _default;
});