define("adept-iq/pods/components/iq-widgets/vehicles-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/vehicles-widget/config", "adept-iq/pods/components/iq-widgets/vehicles-widget/avlmConfig", "lodash", "adept-iq/config/api-urls", "adept-iq/config/environment", "adept-iq/models/avlm-emergency-message", "adept-iq/config/mapped-permIds", "adept-iq/utils/vehicle-info"], function (_exports, _component, _config, _avlmConfig, _lodash, _apiUrls, _environment, _avlmEmergencyMessage, _mappedPermIds, _vehicleInfo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['vehicles-widget'],
    classNameBindings: ['isActiveClass'],
    editModal: Ember.inject.service(),
    editComponent: 'iq-widgets/vehicles-form-widget',
    breakdownComponent: 'iq-widgets/vehicles-breakdown-form-widget',
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    geocode: Ember.inject.service(),
    notifications: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    roadSupervisor: Ember.inject.service(),
    user: Ember.inject.service(),
    config: _config.default,
    defaultIncludes: INCLUDES,
    isActiveClass: Ember.computed('table.rows', 'table.columns.@each.{id}', function () {
      if (this.user.isRoadSupEnable()) {
        if (this.get('table.columns').isAny('id')) {
          const columnArray = this.get('table.columns');
          columnArray.filter(obj => {
            if (obj.get('id') === 'name') {
              obj.set('classNames', 'vehicle-widget-id-custom-class');
            }
          });
          return ['vehicle-widget-gear-icon'];
        }
      }

      return;
    }),

    init() {
      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      this._super(...arguments);
    },

    saveRecordFunction(records) {
      const store = this.get('store');
      const locationAdapter = store.adapterFor('location');
      const addressAdapter = store.adapterFor('address');
      const placeAdapter = store.adapterFor('place');
      const placeSerializer = store.serializerFor('place');
      const placeModelClass = store.modelFor('place');

      function saveDeleteAllObjects(objects) {
        const deleteObjects = _lodash.default.difference(objects.content.initialState, objects.content.currentState) || []; // delete objects that have been removed from the array

        return Promise.all(deleteObjects.map(deleteObject => {
          deleteObject._record.deleteRecord();

          return deleteObject.save();
        })).then(() => {
          return Promise.all(objects.map(object => object.save()));
        });
      }

      this.set('editModal.errors', []);
      return new Promise((resolve, reject) => {
        if (!Ember.isEmpty(records)) {
          records.forEach(async record => {
            const startGarage = record.get('startGarageCopy');
            const endGarage = record.get('endGarageCopy');

            try {
              // save location for start garage and end garage
              const locationResponse1 = await locationAdapter.postLocation(startGarage.get('location.content'));
              const locationResponse2 = await locationAdapter.postLocation(endGarage.get('location.content'));

              if (Ember.isNone(startGarage.get('address.zoneName.name'))) {
                startGarage.get('address').set('zoneName', this.store.peekAll('zone-type').get('firstObject'));
              }

              if (Ember.isNone(endGarage.get('address.zoneName.name'))) {
                endGarage.get('address').set('zoneName', this.store.peekAll('zone-type').get('firstObject'));
              } // save addresses for start garage and end garage


              const address1 = await addressAdapter.postAddress(startGarage.get('address.content'));
              const address2 = await addressAdapter.postAddress(endGarage.get('address.content')); // save address location

              await addressAdapter.postAddressLocation(locationResponse1, address1);
              await addressAdapter.postAddressLocation(locationResponse2, address2); // save places for start garage and end garage

              const startPlaceResponse = await placeAdapter.postPlace(startGarage, address1.data.id, locationResponse1.data.id, 'passenger');
              const endPlaceResponse = await placeAdapter.postPlace(endGarage, address2.data.id, locationResponse2.data.id, 'passenger');
              const startPlaceNorm = placeSerializer.normalizeResponse(store, placeModelClass, startPlaceResponse, startPlaceResponse.data.id, 'createRecord');
              const endPlaceNorm = placeSerializer.normalizeResponse(store, placeModelClass, endPlaceResponse, endPlaceResponse.data.id, 'createRecord');
              store.push(startPlaceNorm);
              store.push(endPlaceNorm);
              const startPlace = store.peekRecord('place', startPlaceResponse.data.id);
              const endPlace = store.peekRecord('place', endPlaceResponse.data.id);
              record.set('startGarage', startPlace);
              record.set('endGarage', endPlace); // save vehicle

              await record.save(); // save vehicleAvailabilites

              await saveDeleteAllObjects(record.get('vehicleAvailabilities')); // save shiftBreaks

              if (record.get('shiftBreaks').length > 0) {
                const shiftBreaks = record.get('shiftBreaks') || [];
                await shiftBreaks.reduce(async (acu, shiftBreak) => {
                  if (!Ember.isNone(shiftBreak.get('place.location.geoNode'))) {
                    // save location
                    const locationResponse = await locationAdapter.postLocation(shiftBreak.get('place.content.location.content'));
                    shiftBreak.get('place.content.address').set('zoneName', this.store.peekAll('zone-type').get('firstObject')); // save address

                    const address = await addressAdapter.postAddress(shiftBreak.get('place.content.address.content')); // save address location

                    await addressAdapter.postAddressLocation(locationResponse, address);
                    return acu.then(() => {
                      // save place
                      return placeAdapter.postPlace(shiftBreak.get('place.content'), address.data.id, locationResponse.data.id, 'passenger');
                    });
                  }
                }, Promise.resolve());
                saveDeleteAllObjects(record.get('shiftBreaks')).then(() => {
                  record.set('isForceDirty', false);
                  this.get('notifications').success(`VEHICLE ${record.name} EDIT CONFIRMED`);
                  resolve();
                });
              } else {
                saveDeleteAllObjects(record.get('shiftBreaks')).then(() => {
                  record.set('isForceDirty', false);
                  this.get('activeContext').get('refreshTableContent').perform(this.get('vehicle'));
                  this.get('notifications').success(`VEHICLE ${record.name} SAVED CONFIRMED`);
                  resolve();
                });
              }
            } catch (error) {
              this.get('notifications').warning(`VEHICLE ${record.name} SAVE CONFIRMATION FAILED`);
              reject(error);
            }
          });
        }
      });
    },

    // 1. Create a new place in the Address service that represents the breakdown location, address and place
    // 2. POST breakdown vehicle to vehicle service
    saveBreakdownVehicleFunction(records) {
      const store = this.get('store');
      const locationAdapter = store.adapterFor('location');
      const addressAdapter = store.adapterFor('address');
      const placeAdapter = store.adapterFor('place');
      const dispatchRouteAdapter = store.adapterFor('dispatch-route');
      this.set('editModal.errors', []);
      return new Promise((resolve, reject) => {
        if (!Ember.isEmpty(records)) {
          return records.forEach(async record => {
            const breakdownPlace = record.get('breakdownPlace');

            try {
              const locationResponse = await locationAdapter.postLocation(breakdownPlace.get('location'));
              const address = await addressAdapter.postAddress(breakdownPlace.get('address'));
              await addressAdapter.postAddressLocation(locationResponse, address);
              const place = await placeAdapter.postPlace(breakdownPlace, address.data.id, locationResponse.data.id, 'ad_hoc');
              record.set('status', 'breakdown');
              const route = record.get('latestRouteVehicleDriver.dispatchRoute.content');

              if (route) {
                await dispatchRouteAdapter.postBreakdown(route, place.data.id, record);
              }

              this.get('notifications').success(`VEHICLE ${record.name} BREAKDOWN CONFIRMED`);
              resolve();
            } catch (error) {
              this.get('notifications').warning(`VEHICLE ${record.name} BREAKDOWN CONFIRMATION FAILED`);
              reject(error);
            }
          });
        }
      });
    },

    didInsertElement() {
      this._super(...arguments);

      this.get('tableActions').unshiftObject({
        id: 'newVehicle',
        permId: _mappedPermIds.default.newVehicle,
        name: 'New Vehicle',
        action: function () {
          const store = this.get('store');
          const origin = store.createRecord('location');
          const destination = store.createRecord('location');
          const startAddress = store.createRecord('address');
          const endAddress = store.createRecord('address');
          const startPlace = store.createRecord('place', {
            address: startAddress,
            location: origin
          });
          const endPlace = store.createRecord('place', {
            address: endAddress,
            location: destination
          });
          const vehicleTypes = store.peekAll('vehicle-type');
          const newModel = store.createRecord('vehicle');
          newModel.set('startGarageCopy', startPlace);
          newModel.set('endGarageCopy', endPlace);
          newModel.set('shiftBreaks', []);
          newModel.set('vehicleType', vehicleTypes.firstObject);
          newModel.set('active', true);
          this.get('records').pushObject(newModel);
          this.set('editModal.editableRecords', []);
          const editComponent = this.get('editComponent');
          this.get('editModal').open(editComponent, [newModel], this.get('saveRecordFunction').bind(this));
        }.bind(this)
      });
      this.set('sendMessageAction', {
        id: 'sendMessage',
        permId: _mappedPermIds.default.sendMessageVehicle,
        name: 'Send Message',
        action: vehicle => {
          this.sendMessagesToVehicles([vehicle], 'singleMessage');
        }
      });
      this.set('editAction', {
        id: 'edit',
        permId: _mappedPermIds.default.editVehicle,
        name: 'Edit',
        action: model => {
          const store = this.get('store');
          const vehicleModel = model;
          const editComponent = this.get('editComponent');

          if (!Ember.isNone(vehicleModel.get('startGarage.id'))) {
            const origin = vehicleModel.get('startGarage');
            origin.set('type', 'pick');
            const location = store.createRecord('location', origin.get('location.content').toJSON());
            const address = store.createRecord('address', origin.get('address.content').toJSON());
            address.set('zoneName', origin.get('address.zoneName.content')); // user copy of place/address/location so it does not modify the other vehicle's garage places

            const startPlaceCopy = store.createRecord('place', {
              geocodingMethod: origin.get('geocodingMethod'),
              address: address,
              location: location
            });
            vehicleModel.set('startGarageCopy', startPlaceCopy);
          } else {
            const location = store.createRecord('location');
            const address = store.createRecord('address');
            address.set('zoneName', this.store.peekAll('zone-type').get('firstObject'));
            const startPlaceCopy = store.createRecord('place', {
              address: address,
              location: location
            });
            vehicleModel.set('startGarageCopy', startPlaceCopy);
          }

          if (!Ember.isNone(vehicleModel.get('endGarage.id'))) {
            const destination = vehicleModel.get('endGarage');
            destination.set('type', 'pick');
            const location = store.createRecord('location', destination.get('location.content').toJSON());
            const address = store.createRecord('address', destination.get('address.content').toJSON());
            address.set('zoneName', destination.get('address.zoneName')); // user copy of place/address/location so it does not modify the other vehicle's garage places

            const endPlaceCopy = store.createRecord('place', {
              geocodingMethod: destination.get('geocodingMethod'),
              address: address,
              location: location
            });
            vehicleModel.set('endGarageCopy', endPlaceCopy);
          } else {
            const location = store.createRecord('location');
            const address = store.createRecord('address');
            address.set('zoneName', this.store.peekAll('zone-type').get('firstObject'));
            const endPlaceCopy = store.createRecord('place', {
              address: address,
              location: location
            });
            vehicleModel.set('endGarageCopy', endPlaceCopy);
          } // shift break in scheduling not being sent with GET shift-break


          if (Ember.isNone(vehicleModel.get('shiftBreaks.firstObject.location.tomtomFormattedAddress'))) {
            const shiftBreaks = vehicleModel.get('shiftBreaks') || [];
            shiftBreaks.forEach(shiftBreak => {
              const fixedBreakLocation = this.get('store').createRecord('location');
              shiftBreak.set('location', fixedBreakLocation);
            });
          }

          this.get('editModal').open(editComponent, [vehicleModel], this.get('saveRecordFunction').bind(this));
        }
      });
      this.set('vehicleBreakdown', {
        id: 'vehicleBreakdown',
        permId: _mappedPermIds.default.vehicleBreakdown,
        name: 'Report Breakdown',
        action: model => {
          const store = this.get('store');
          const dsVehicleModel = model;
          const breakdownComponent = this.get('breakdownComponent'); // vehicle breakdown fields

          const location = store.createRecord('location');
          const address = store.createRecord('address'); // TODO: how does zone types fit in the address

          address.set('zoneName', this.store.peekAll('zone-type').get('firstObject'));
          const place = store.createRecord('place', {
            address: address,
            location: location
          });

          if (dsVehicleModel.get('currentAVLLocation')) {
            place.set('location.lat', dsVehicleModel.get('currentAVLLocation.lat'));
            place.set('location.lng', dsVehicleModel.get('currentAVLLocation.lng'));
          }

          dsVehicleModel.set('breakdownPlace', place);
          dsVehicleModel.set('breakdownTime', new Date());
          dsVehicleModel.set('breakdownDescription', '');
          dsVehicleModel.set('breakdownType', null);
          this.get('editModal').open(breakdownComponent, [dsVehicleModel], this.get('saveBreakdownVehicleFunction').bind(this));
        }
      });
      this.set('resetDeviceAction', {
        id: 'resetDevice',
        permId: _mappedPermIds.default.resetDeviceAction,
        name: 'Reset device',
        action: model => {
          return new Promise((resolve, reject) => {
            const vehicleInfo = (0, _vehicleInfo.vehicleInfoData)();
            const prepareVehicleInfo = (0, _vehicleInfo.prepareVehicleInfoData)(vehicleInfo, model);
            const notifications = this.get('notifications');
            const title = 'Reset Device';
            this.get('tooltip').setProperties({
              tip: 'This will disassociate the device ID from the vehicle.',
              title,
              primaryActionText: 'Proceed',
              primaryAction: () => {
                //sending PATCH to avlm/vehicle-info/{id} requires the payload id/ URL id to equal the avlm.vehicle.id
                const session = this.get('session');
                return this.get('ajax').request(_apiUrls.API.avlmService.host + `/vehicle-info/?callSign=${model.name}`, {
                  method: 'GET',
                  contentType: 'text',
                  headers: {
                    'Authorization': `Bearer ${session.data.authenticated.token}`
                  }
                }).then(response => {
                  // if there is no device ID, inform the user and return
                  if (!response.data[0].attributes.device || !response.data[0].attributes.device.hardwareId) {
                    notifications.info('There is no device associated with this vehicle.');
                    return;
                  }

                  prepareVehicleInfo.data.id = response.data[0].id;
                  const json = JSON.stringify(prepareVehicleInfo);
                  return this.get('ajax').request(_apiUrls.API.avlmService.host + `/vehicle-info/${response.data[0].id}`, {
                    method: 'PATCH',
                    contentType: 'text',
                    headers: {
                      'Authorization': `Bearer ${session.data.authenticated.token}`
                    },
                    data: json
                  }).then(() => {
                    notifications.success('RESET DEVICE SUCCESSFULL');
                  });
                }).then(() => {
                  this.get('tooltip').reset();
                }).catch(function (error) {
                  notifications.warning('Error: Reset did not work correctly');
                  this.get('tooltip').reset();
                  reject(error);
                });
              },
              secondaryActionText: 'Cancel',
              secondaryAction: () => {
                this.get('tooltip').reset();
                return;
              }
            });
          });
        }
      });
      this.set('acknowledgeAction', {
        id: 'emergencyAcknowledge',
        permId: _mappedPermIds.default.emergencyAcknowledge,
        name: 'Acknowledge Emergency',
        action: vehicle => {
          if (_environment.default.APP.avlmLite) {
            this.emergencyAcknowledgeActionAvlmLite(vehicle);
          } else {
            this.emergencyAcknowledgeAction(vehicle);
          }
        }
      });
      this.set('cancelAction', {
        id: 'emergencyCancel',
        permId: _mappedPermIds.default.emergencyCancel,
        name: 'Cancel Emergency',
        action: vehicle => {
          if (_environment.default.APP.avlmLite) {
            this.emergencyCancelActionAvlmLite(vehicle);
          } else {
            this.emergencyCancelAction(vehicle);
          }
        }
      });
      this.set('resolveAction', {
        id: 'emergencyResolve',
        permId: _mappedPermIds.default.emergencyResolve,
        name: 'Resolve Emergency',
        action: vehicle => {
          if (_environment.default.APP.avlmLite) {
            this.emergencyResolveActionAvlmLite(vehicle);
          } else {
            this.emergencyResolveAction(vehicle);
          }
        }
      });
      this.set('navigateToAction', {
        id: 'navigateTo',
        permId: _mappedPermIds.default.navigateIntercept,
        name: 'Navigate To',
        action: vehicle => {
          this.get('roadSupervisor').navigateToVehicle(vehicle);
        }
      });
      this.set('bulkActions', [{
        id: 'bulkMessage',
        permId: _mappedPermIds.default.sendMessageVehicle,
        name: 'Bulk Message',
        action: vehicles => {
          this.sendMessagesToVehicles(vehicles, 'bulkMessage');
        }
      }]);
      this.set('activityLogAction', {
        id: 'activityLog',
        permId: _mappedPermIds.default.activityLogVehicle,
        name: 'View Activity Log',
        action: vehicle => {
          const id = vehicle.get('id');
          this.get('workspace').pushState('vehicleActivityLog', {
            displayName: `Vehicle #${id} Activity Log`,
            vehicle
          });
        },
        disableOnLocked: false
      });
    },

    avlmDriverApps: Ember.computed('table.rows.@each.avlmDriverApp', function () {
      const tableRows = this.get('table.rows') || [];
      const avlmDriverApps = tableRows.map(row => {
        return row.get('record.avlmDriverApp.content');
      });
      return avlmDriverApps;
    }),
    dynamicSingleActions: Ember.computed('table.rows.@each.{selected}', 'avlmDriverApps.@each.deviceHardwareId', function () {
      const sendMessageAction = this.get('sendMessageAction');
      const editAction = this.get('editAction'); // disable edit for 4.2. Insert back in later in 5.0

      const resetDeviceAction = this.get('resetDeviceAction');
      const cancelAction = this.get('cancelAction');
      const acknowledgeAction = this.get('acknowledgeAction');
      const resolveAction = this.get('resolveAction');
      const activityLogAction = this.get('activityLogAction');
      const vehicleBreakdown = this.get('vehicleBreakdown');
      const rows = this.get('table.rows') || [];
      const row = rows.findBy('selected', true);
      const actions = [sendMessageAction, activityLogAction, vehicleBreakdown, editAction];
      if (!row) return [];
      actions.push(resetDeviceAction); // add acknowledge, cancel actions for recent emergencies

      if (row.get('isEmergencyVehicle') || row.get('isApprovedEmergencyPending')) {
        actions.push(acknowledgeAction, cancelAction);
      } // add resolved, cancel actions for approved emergencies
      else if (row.get('isApprovedEmergency')) {
          actions.push(resolveAction, cancelAction);
        } // navigation to moving vehicles is disabled until further notice
      //
      // const navigateToAction = this.get('navigateToAction');
      // actions.push(navigateToAction);


      return actions;
    }),

    async sendMessagesToVehicles(vehicles, messageType) {
      const draftMessages = vehicles.map(vehicle => {
        const trueVehicle = vehicle;
        const vehicleId = trueVehicle.get('id');
        const vehicleName = trueVehicle.get('name');
        const driverId = trueVehicle.get('latestDriver') ? trueVehicle.get('latestDriver.id') : null;
        const badgeNumber = trueVehicle.get('latestDriver') ? trueVehicle.get('latestDriver.badgeNumber') : null;

        if (messageType === 'singleMessage' && !badgeNumber) {
          this.get('notifications').warning(`Can not send message due to missing driver id on vehicle id = ${vehicleName}`);
          return null;
        }

        if (messageType === 'bulkMessage' && !badgeNumber) {
          // if some vehicle missing driver just return null
          return null;
        }

        trueVehicle.set('driver', {
          id: driverId,
          endPointType: 'recipient',
          fullName: trueVehicle.get('latestDriver.fullName'),
          badgeNumber: badgeNumber
        });
        trueVehicle.set('vehicle', {
          id: vehicleId,
          name: vehicleName
        });
        const routeId = trueVehicle.get('latestRouteVehicleDriver.route.id');
        const routeName = trueVehicle.get('latestRouteVehicleDriver.dispatchRoute.route.name');
        trueVehicle.set('route', {
          id: routeId,
          name: routeName
        });
        trueVehicle.set('messageNr', '0');

        if (_environment.default.APP.avlmLite) {
          trueVehicle.set('avlmDriver', {
            id: driverId,
            endPointType: 'recipient'
          });
          trueVehicle.set('avlmVehicle', {
            id: vehicleId
          });
        }

        return trueVehicle;
      }).filter(vehicle => !Ember.isNone(vehicle));

      if (messageType === 'singleMessage' && draftMessages.firstObject) {
        this.get('workspace').pushState('addNewMessage', {
          draftMessages
        });
      } else if (messageType === 'bulkMessage') {
        if (draftMessages.length < vehicles.length) {
          this.get('notifications').warning('MESSAGE COULD NOT BE SENT TO SOME VEHICLES WHICH DO NOT HAVE DRIVER ASSOCIATED.');
        }

        if (!Ember.isEmpty(draftMessages)) {
          this.get('workspace').pushState('addNewMessage', {
            draftMessages
          });
        }
      }
    },

    _sendEmergencyMessage(message, successNotification, errorNotification) {
      const formatedMessage = {
        data: {
          type: 'cannedMessage',
          attributes: {
            messageNr: '0',
            body: message.body,
            createdTime: new Date(),
            priority: message.priority,
            responseType: {
              statusCode: _avlmEmergencyMessage.CANNED_RESPONSE_TYPE_CODES[message.responseTypeText],
              status: message.responseTypeText
            },
            type: 'D',
            driver: {
              id: message.driver.id,
              endPointType: 'recipient'
            },
            vehicle: {
              id: message.vehicleId
            }
          }
        }
      };

      if (message.body === 'acknowledged' || message.body === 'canceled' || message.body === 'resolved') {
        formatedMessage.data.attributes.replyToMsgId = message.replyToMsgId;
      }

      const json = JSON.stringify(formatedMessage);
      const session = this.get('session');
      return this.get('ajax').post(_apiUrls.API.avlmService.host + '/canned-message', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      }).then(() => {
        this.get('notifications').success(successNotification);
      }).catch(function ()
      /*error*/
      {
        this.get('notifications').warning(errorNotification);
      });
    },

    async emergencyAcknowledgeAction(vehicle) {
      const iqVehicle = vehicle;
      const replyToMsgId = iqVehicle.get('latestReceivedEmergencyMessage') ? iqVehicle.get('latestReceivedEmergencyMessage').get('replyToMsgId') : null;
      if (!replyToMsgId) return this.get('notifications').warning('Could not resolve emergency message due to missing reply id');
      let driverId = iqVehicle.get('latestDriver.id');
      driverId = driverId ? driverId : 0;
      const tooltip = this.get('tooltip');
      const newEmergencyMessage = {
        // TODO: should this be `driver.driverId`? latest driver not found
        driver: {
          id: `${driverId}`,
          firstName: `${iqVehicle.get('latestDriver.firstName')}`,
          lastName: `${iqVehicle.get('latestDriver.lastName')}`,
          endPointType: 'recipient'
        },
        messageNr: '0',
        replyToMsgId: replyToMsgId,
        driverEndPointType: 'recipient',
        vehicleId: iqVehicle.get('id'),
        routeId: iqVehicle.get('latestRouteVehicleDriver.route.id'),
        body: 'acknowledged',
        responseTypeText: 'acknowledged',
        responseMessage: 'The message is for Acknowledged',
        priority: 'emergency'
      }; // Update URL with correct endpoint and post data
      // Acknowledge Emergency Message

      tooltip.reset();
      return this._sendEmergencyMessage(newEmergencyMessage, 'Acknowledged Emergency Message Successfully', 'Acknowledge Emergency Message Failed');
    },

    async emergencyAcknowledgeActionAvlmLite(vehicle) {
      const iqVehicle = vehicle;
      const replyToMsgId = iqVehicle.get('latestReceivedEmergencyMessage') ? iqVehicle.get('latestReceivedEmergencyMessage').get('replyToMsgId') : null;
      if (!replyToMsgId) return this.get('notifications').warning('Could not resolve emergency message due to missing reply id');
      let driverId = iqVehicle.get('latestDriver.id');
      driverId = driverId ? driverId : 0;
      const tooltip = this.get('tooltip');
      const newEmergencyMessage = {
        // TODO: should this be `driver.driverId`? latest driver not found
        driver: {
          id: `${driverId}`,
          firstName: `${iqVehicle.get('latestDriver.firstName')}`,
          lastName: `${iqVehicle.get('latestDriver.lastName')}`,
          endPointType: 'recipient'
        },
        messageNr: '0',
        replyToMsgId: replyToMsgId,
        driverEndPointType: 'recipient',
        vehicleId: iqVehicle.get('id'),
        routeId: iqVehicle.get('latestRouteVehicleDriver.route.id'),
        body: 'acknowledged',
        responseTypeText: 'acknowledged',
        responseMessage: 'The message is for Acknowledged',
        priority: 'emergency'
      }; // Update URL with correct endpoint and post data
      // Acknowledge Emergency Message

      tooltip.reset();
      return this._sendEmergencyMessage(newEmergencyMessage, 'Acknowledged Emergency Message Successfully', 'Acknowledge Emergency Message Failed');
    },

    async emergencyCancelAction(vehicle) {
      const iqVehicle = vehicle;
      const replyToMsgId = iqVehicle.get('latestReceivedEmergencyMessage') ? iqVehicle.get('latestReceivedEmergencyMessage').get('replyToMsgId') : null;
      if (!replyToMsgId) return this.get('notifications').warning('Could not resolve emergency message due to missing reply id');
      let driverId = iqVehicle.get('latestDriver.id');
      driverId = driverId ? driverId : 0;
      const newEmergencyMessage = {
        // TODO: should this be `driver.driverId`? latest driver not found
        driver: {
          id: `${driverId}`,
          firstName: `${iqVehicle.get('latestDriver.firstName')}`,
          lastName: `${iqVehicle.get('latestDriver.lastName')}`,
          endPointType: 'recipient'
        },
        messageNr: '0',
        replyToMsgId: replyToMsgId,
        driverEndPointType: 'recipient',
        vehicleId: iqVehicle.get('id'),
        routeId: iqVehicle.get('latestRouteVehicleDriver.route.id'),
        body: 'canceled',
        responseTypeText: 'canceled',
        responseMessage: 'The message is for Resolved',
        priority: 'emergency'
      }; // Update URL with correct endpoint and post data
      // Cancel Emergency Message

      const tooltip = this.get('tooltip');
      const tip = 'This will cancel the driver\'s emergency message. Proceed?';
      const title = 'Cancel Emergency';
      tooltip.setProperties({
        tip,
        title,
        hasOverlay: true,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          return this._sendEmergencyMessage(newEmergencyMessage, 'Canceled Emergency Message Successfully', 'Canceled Emergency Message Failed');
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          tooltip.reset();
          return;
        }
      });
    },

    async emergencyCancelActionAvlmLite(vehicle) {
      const iqVehicle = vehicle;
      const replyToMsgId = iqVehicle.get('latestReceivedEmergencyMessage') ? iqVehicle.get('latestReceivedEmergencyMessage').get('replyToMsgId') : null;
      if (!replyToMsgId) return this.get('notifications').warning('Could not resolve emergency message due to missing reply id');
      let driverId = iqVehicle.get('latestDriver.id');
      driverId = driverId ? driverId : 0;
      const newEmergencyMessage = {
        // TODO: should this be `driver.driverId`? latest driver not found
        driver: {
          id: `${driverId}`,
          firstName: `${iqVehicle.get('latestDriver.firstName')}`,
          lastName: `${iqVehicle.get('latestDriver.lastName')}`,
          endPointType: 'recipient'
        },
        messageNr: '0',
        replyToMsgId: replyToMsgId,
        driverEndPointType: 'recipient',
        vehicleId: iqVehicle.get('id'),
        routeId: iqVehicle.get('latestRouteVehicleDriver.route.id'),
        body: 'canceled',
        responseTypeText: 'canceled',
        responseMessage: 'The message is for Resolved',
        priority: 'emergency'
      }; // Update URL with correct endpoint and post data
      // Cancel Emergency Message

      const tooltip = this.get('tooltip');
      const self = this;
      tooltip.setProperties({
        tip: 'This will cancel the driver\'s emergency message. Proceed?',
        primaryActionText: 'Yes',
        secondaryActionText: 'No',

        primaryAction() {
          self._sendEmergencyMessage(newEmergencyMessage, 'Canceled Emergency Message Successfully', 'Canceled Emergency Message Failed');

          tooltip.reset();
          return;
        },

        secondaryAction: () => {
          tooltip.reset();
          return;
        }
      });
    },

    async emergencyResolveAction(vehicle) {
      const iqVehicle = vehicle;
      const replyToMsgId = iqVehicle.get('latestReceivedEmergencyMessage') ? iqVehicle.get('latestReceivedEmergencyMessage').get('replyToMsgId') : null;
      if (!replyToMsgId) return this.get('notifications').warning('Could not resolve emergency message due to missing reply id');
      let driverId = iqVehicle.get('latestDriver.id');
      driverId = driverId ? driverId : 0;
      const tooltip = this.get('tooltip');
      const newEmergencyMessage = {
        // TODO: should this be `driver.driverId`? latest driver not found
        driver: {
          id: `${driverId}`,
          firstName: `${iqVehicle.get('latestDriver.firstName')}`,
          lastName: `${iqVehicle.get('latestDriver.lastName')}`,
          endPointType: 'recipient'
        },
        messageNr: '0',
        replyToMsgId: replyToMsgId,
        driverEndPointType: 'recipient',
        vehicleId: iqVehicle.get('id'),
        routeId: iqVehicle.get('latestRouteVehicleDriver.route.id'),
        body: 'resolved',
        responseTypeText: 'resolved',
        responseMessage: 'The message is for Resolved',
        priority: 'emergency'
      }; // Update URL with correct endpoint and post data
      // Acknowledge Emergency Message

      tooltip.reset();
      return this._sendEmergencyMessage(newEmergencyMessage, 'Resolved Emergency Message Successfully', 'Resolved Emergency Message Failed');
    },

    async emergencyResolveActionAvlmLite(vehicle) {
      const iqVehicle = vehicle;
      const replyToMsgId = iqVehicle.get('latestReceivedEmergencyMessage') ? iqVehicle.get('latestReceivedEmergencyMessage').get('replyToMsgId') : null;
      if (!replyToMsgId) return this.get('notifications').warning('Could not resolve emergency message due to missing reply id');
      let driverId = iqVehicle.get('latestDriver.id');
      driverId = driverId ? driverId : 0;
      const tooltip = this.get('tooltip');
      const newEmergencyMessage = {
        // TODO: should this be `driver.driverId`? latest driver not found
        driver: {
          id: `${driverId}`,
          firstName: `${iqVehicle.get('latestDriver.firstName')}`,
          lastName: `${iqVehicle.get('latestDriver.lastName')}`,
          endPointType: 'recipient'
        },
        messageNr: '0',
        replyToMsgId: replyToMsgId,
        driverEndPointType: 'recipient',
        vehicleId: iqVehicle.get('id'),
        routeId: iqVehicle.get('latestRouteVehicleDriver.route.id'),
        body: 'resolved',
        responseTypeText: 'resolved',
        responseMessage: 'The message is for Resolved',
        priority: 'emergency'
      }; // Update URL with correct endpoint and post data
      // Acknowledge Emergency Message

      tooltip.reset();
      return this._sendEmergencyMessage(newEmergencyMessage, 'Resolved Emergency Message Successfully', 'Resolved Emergency Message Failed');
    }

  });

  _exports.default = _default;
});