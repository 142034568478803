define("adept-iq/pods/components/generic-components/tab-group/tab-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wt5Qsr5o",
    "block": "{\"symbols\":[],\"statements\":[[0,\"    \"],[7,\"button\"],[12,\"class\",[28,[\"tab-button \",[27,\"if\",[[23,[\"isActive\"]],\"active\",\"\"],null]]]],[12,\"active\",[21,\"isActive\"]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onclick\"],null]],[12,\"aria-pressed\",[27,\"eq\",[[23,[\"wizard\",\"currentStep\"]],[23,[\"transitionName\"]]],null]],[9],[0,\"\\n      \"],[1,[21,\"link\"],false],[0,\"\\n    \"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/generic-components/tab-group/tab-button/template.hbs"
    }
  });

  _exports.default = _default;
});