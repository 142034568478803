define("adept-iq/utils/zone-validation", ["exports", "adept-iq/config/zone-polygon"], function (_exports, _zonePolygon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.insideZone = insideZone;

  function insideZone(point) {
    // ray-casting algorithm based on
    // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
    const x = point[0];
    const y = point[1];
    let inside = false;
    if (x === '' || y === '') return inside;

    for (let i = 0, j = _zonePolygon.default.length - 1; i < _zonePolygon.default.length; j = i++) {
      const xi = _zonePolygon.default[i][0];
      const yi = _zonePolygon.default[i][1];
      const xj = _zonePolygon.default[j][0];
      const yj = _zonePolygon.default[j][1];
      const intersect = yi > y !== yj > y && x < (xj - xi) * (y - yi) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }

    return inside;
  }
});