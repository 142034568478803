define("adept-iq/pods/components/table/cells/check-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EpFvjw6F",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,[\"isAlertCheckBox\"]]],null,{\"statements\":[[0,\"  \"],[7,\"input\"],[11,\"class\",\"can-focus checkbox-disable\"],[12,\"checked\",[27,\"readonly\",[[23,[\"isChecked\"]]],null]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onToggleCheckbox\"],null]],[11,\"disabled\",\"\"],[11,\"type\",\"checkbox\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"input\"],[11,\"class\",\"can-focus\"],[12,\"checked\",[27,\"readonly\",[[23,[\"isChecked\"]]],null]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onToggleCheckbox\"],null]],[11,\"type\",\"checkbox\"],[9],[10],[0,\"\\n    \"],[7,\"i\"],[12,\"class\",[28,[\"fa fa-cog \",[27,\"if\",[[23,[\"isChecked\"]],\"is-checked\"],null]]]],[11,\"aria-hidden\",\"true\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],[23,[\"extra\",\"onRightClick\"]],[23,[\"row\"]]],null]],[9],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/table/cells/check-box/template.hbs"
    }
  });

  _exports.default = _default;
});