define("adept-iq/pods/components/iq-widgets/zones-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/zones-widget',
    rowComponent: 'table/rows/zonestat-formatted-row',
    modelName: 'zone',
    title: 'Zones',
    defaultSortId: 'name',
    defaultSortAsc: false,
    columns: [{
      index: 0,
      id: 'name',
      type: 'text',
      label: 'Name',
      valuePath: 'name',
      hidden: false,
      searchable: true
    }, {
      index: 1,
      id: 'type',
      type: 'text',
      label: 'Type',
      valuePath: 'type',
      searchable: true
    }]
  };
  _exports.default = _default;
});