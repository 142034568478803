define("adept-iq/pods/components/side-drawer/trip-transfer/stubs", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.currentRouteImpactDataStub = _exports.solutionImpactDataStub = _exports.solutionDataStub = void 0;
  const solutionDataStub = [{
    id: '234535',
    gain: 3000000,
    transferSaves: 1200000
  }, {
    id: '213244',
    gain: 3600000,
    transferSaves: 600000
  }, {
    id: '222342',
    gain: 4800000,
    transferSaves: 480000
  }];
  _exports.solutionDataStub = solutionDataStub;
  const currentRouteImpactDataStub = {
    before: {
      delta: 0,
      trips: [{
        id: '182345467',
        action: 'added',
        lastName: 'Jackson',
        stop: 'Pick',
        eta: (0, _moment.default)('06:20 AM', 'hh:mm A'),
        mrt: 0,
        address: '12 Ave.',
        city: 'Bronx',
        pick: '06:20 AM',
        drop: ''
      }, {
        id: '162342345',
        lastName: 'Freeman',
        stop: 'Pick',
        eta: (0, _moment.default)('06:15 AM', 'hh:mm A'),
        mrt: 0,
        address: '30 Ave.',
        city: 'Bronx',
        pick: '06:15 AM',
        drop: ''
      }, {
        id: '182345467',
        action: 'added',
        lastName: 'Jackson',
        stop: 'Pick',
        eta: (0, _moment.default)('06:20 AM', 'hh:mm A'),
        mrt: 0,
        address: '12 Ave.',
        city: 'Bronx',
        pick: '06:20 AM',
        drop: ''
      }]
    },
    after: {
      delta: 0,
      trips: [{
        id: '162342345',
        lastName: 'Freeman',
        stop: 'Pick',
        eta: (0, _moment.default)('06:15 AM', 'hh:mm A'),
        mrt: 0,
        address: '30 Ave.',
        city: 'Bronx',
        pick: '06:15 AM',
        drop: ''
      }]
    }
  };
  _exports.currentRouteImpactDataStub = currentRouteImpactDataStub;
  const solutionImpactDataStub = {
    '234535': {
      before: {
        delta: 0,
        trips: [{
          id: '162342345',
          lastName: 'Freeman',
          stop: 'Pick',
          eta: (0, _moment.default)('06:15 AM', 'hh:mm A'),
          mrt: 0,
          address: '30 Ave.',
          city: 'Bronx',
          pick: '06:15 AM',
          drop: ''
        }, {
          id: '132343244',
          lastName: 'Fallon',
          stop: 'Pick',
          eta: (0, _moment.default)('07:50 AM', 'hh:mm A'),
          mrt: 0,
          address: '33 St.',
          city: 'Bronx',
          pick: '07:50 AM',
          drop: ''
        }, {
          id: '182346908',
          lastName: 'Aniston',
          stop: 'Pick',
          eta: (0, _moment.default)('08:10 AM', 'hh:mm A'),
          mrt: 0,
          address: '22 Ave.',
          city: 'Bronx',
          pick: '08:10 AM',
          drop: ''
        }]
      },
      after: {
        delta: 600000,
        trips: [{
          id: '182345467',
          action: 'added',
          lastName: 'Jackson',
          stop: 'Pick',
          eta: (0, _moment.default)('06:20 AM', 'hh:mm A'),
          mrt: 0,
          address: '12 Ave.',
          city: 'Bronx',
          pick: '06:20 AM',
          drop: ''
        }, {
          id: '162342345',
          lastName: 'Freeman',
          stop: 'Pick',
          eta: (0, _moment.default)('06:35 AM', 'hh:mm A'),
          mrt: 600000,
          otp: 'late',
          address: '30 Ave.',
          city: 'Bronx',
          pick: '06:25 AM',
          drop: ''
        }, {
          id: '182345467',
          action: 'added',
          lastName: 'Jackson',
          stop: 'Drop',
          eta: (0, _moment.default)('07:50 AM', 'hh:mm A'),
          mrt: 0,
          address: '12 Ave.',
          city: 'Bronx',
          pick: '',
          drop: '07:50 AM'
        }, {
          id: '132343244',
          lastName: 'Fallon',
          stop: 'Pick',
          eta: (0, _moment.default)('08:10 AM', 'hh:mm A'),
          mrt: 600000,
          otp: 'late',
          address: '33 St.',
          city: 'Bronx',
          pick: '08:00 AM',
          drop: ''
        }, {
          id: '182346908',
          lastName: 'Aniston',
          stop: 'Pick',
          eta: (0, _moment.default)('08:30 AM', 'hh:mm A'),
          mrt: 600000,
          otp: 'late',
          address: '22 Ave.',
          city: 'Bronx',
          pick: '08:20 AM',
          drop: ''
        }]
      }
    },
    '213244': {
      before: {
        delta: 0,
        trips: [{
          id: '349',
          lastName: 'Capone',
          stop: 'Pick',
          eta: (0, _moment.default)('08:15 AM', 'hh:mm A'),
          mrt: 0,
          address: '80 Ave.',
          city: 'Bronx',
          pick: '08:15 AM',
          drop: ''
        }, {
          id: '3285',
          lastName: 'Doe',
          stop: 'Pick',
          eta: (0, _moment.default)('09:50 AM', 'hh:mm A'),
          mrt: 0,
          address: '99 St.',
          city: 'Bronx',
          pick: '09:50 AM',
          drop: ''
        }, {
          id: '9322',
          lastName: 'Bauer',
          stop: 'Pick',
          eta: (0, _moment.default)('10:10 AM', 'hh:mm A'),
          mrt: 0,
          address: '11 Ave.',
          city: 'Bronx',
          pick: '10:10 AM',
          drop: ''
        }]
      },
      after: {
        delta: 660000,
        trips: [{
          id: '44408',
          action: 'added',
          lastName: 'Jackson',
          stop: 'Pick',
          eta: (0, _moment.default)('08:20 AM', 'hh:mm A'),
          mrt: 0,
          address: '13 Ave.',
          city: 'Bronx',
          pick: '08:20 AM',
          drop: ''
        }, {
          id: '349',
          lastName: 'Capone',
          stop: 'Pick',
          eta: (0, _moment.default)('08:25 AM', 'hh:mm A'),
          mrt: 600000,
          otp: 'late',
          address: '80 Ave.',
          city: 'Bronx',
          pick: '08:25 AM',
          drop: ''
        }, {
          id: '182345467',
          action: 'added',
          lastName: 'Jackson',
          stop: 'Drop',
          eta: (0, _moment.default)('07:50 AM', 'hh:mm A'),
          mrt: 0,
          address: '12 Ave.',
          city: 'Bronx',
          pick: '',
          drop: '07:50 AM'
        }, {
          id: '3285',
          lastName: 'Doe',
          stop: 'Pick',
          eta: (0, _moment.default)('10:00 AM', 'hh:mm A'),
          mrt: 600000,
          otp: 'late',
          address: '99 St.',
          city: 'Bronx',
          pick: '10:00 AM',
          drop: ''
        }, {
          id: '9322',
          lastName: 'Bauer',
          stop: 'Pick',
          eta: (0, _moment.default)('10:20 AM', 'hh:mm A'),
          mrt: 600000,
          otp: 'late',
          address: '11 Ave.',
          city: 'Bronx',
          pick: '10:20 AM',
          drop: ''
        }]
      }
    },
    '222342': {
      before: {
        delta: 0,
        trips: [{
          id: '340825',
          lastName: 'Smith',
          stop: 'Pick',
          eta: (0, _moment.default)('09:20 AM', 'hh:mm A'),
          mrt: 0,
          address: '1 St.',
          city: 'Bronx',
          pick: '09:20 AM',
          drop: ''
        }, {
          id: '34698',
          lastName: 'Jones',
          stop: 'Pick',
          eta: (0, _moment.default)('10:30 AM', 'hh:mm A'),
          mrt: 0,
          address: '2 Ave.',
          city: 'Bronx',
          pick: '10:30 AM',
          drop: ''
        }]
      },
      after: {
        delta: 330000,
        trips: [{
          id: '340825',
          lastName: 'Smith',
          stop: 'Pick',
          eta: (0, _moment.default)('09:20 AM', 'hh:mm A'),
          mrt: 1200000,
          otp: 'late',
          address: '1 St.',
          city: 'Bronx',
          pick: '09:20 AM',
          drop: ''
        }, {
          id: '182345467',
          action: 'added',
          lastName: 'Jackson',
          stop: 'Pick',
          eta: (0, _moment.default)('09:50 AM', 'hh:mm A'),
          mrt: 0,
          address: '12 Ave.',
          city: 'Bronx',
          pick: '09:50 AM',
          drop: ''
        }, {
          id: '34698',
          lastName: 'Jones',
          stop: 'Pick',
          eta: (0, _moment.default)('10:30 AM', 'hh:mm A'),
          mrt: 0,
          address: '2 Ave.',
          city: 'Bronx',
          pick: '10:30 AM',
          drop: ''
        }, {
          id: '182345467',
          action: 'added',
          lastName: 'Jackson',
          stop: 'Drop',
          eta: (0, _moment.default)('11:30 AM', 'hh:mm A'),
          mrt: 0,
          address: '111 Dr.',
          city: 'Bronx',
          pick: '',
          drop: '11:30 AM'
        }]
      }
    },
    '236349': {
      before: {
        delta: 0,
        trips: [{
          id: '34698',
          lastName: 'Zach',
          stop: 'Pick',
          eta: (0, _moment.default)('12:30 PM', 'mm:hh A'),
          mrt: 0,
          address: '2222 Ave.',
          city: 'Bronx',
          pick: '12:30 PM',
          drop: ''
        }]
      },
      after: {
        delta: 330000,
        trips: [{
          id: '182345467',
          action: 'added',
          lastName: 'Jackson',
          stop: 'Pick',
          eta: (0, _moment.default)('09:50 AM', 'hh:mm A'),
          mrt: 0,
          address: '12 Ave.',
          city: 'Bronx',
          pick: '09:50 AM',
          drop: ''
        }, {
          id: '182345467',
          action: 'added',
          lastName: 'Jackson',
          stop: 'Drop',
          eta: (0, _moment.default)('11:30 AM', 'hh:mm A'),
          mrt: 0,
          address: '111 Dr.',
          city: 'Bronx',
          pick: '',
          drop: '11:30 AM'
        }, {
          id: '34698',
          lastName: 'Zach',
          stop: 'Pick',
          eta: (0, _moment.default)('12:50 AM', 'hh:mm A'),
          mrt: 0,
          address: '2222 Ave.',
          city: 'Bronx',
          pick: '12:50 AM',
          drop: ''
        }]
      }
    }
  };
  _exports.solutionImpactDataStub = solutionImpactDataStub;
});