define("adept-iq/pods/components/side-drawer/route-activity-log/component", ["exports", "ember-concurrency", "ember-light-table", "adept-iq/pods/components/side-drawer/route-activity-log/config", "moment", "lodash", "adept-iq/utils/convertDistance", "adept-iq/config/environment"], function (_exports, _emberConcurrency, _emberLightTable, _config, _moment, _lodash, _convertDistance, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const avlmEventMockedEntry = {
    id: '9',
    type: 'routeExecEvent',
    routeId: '1',
    content: {
      type: 'userEntry',
      details: 'User Entry PlaceHolder',
      activity: 'activity placeholder',
      userId: 'User ID PlaceHolder',
      actualTime: null
    },
    attributes: {
      routeId: '1',
      source: {
        type: 'webApp'
      },
      driver: {
        id: '1',
        badgeNr: '1',
        name: 'Arthur Rojas'
      },
      vehicle: {
        id: '10',
        callsign: '10',
        odo: 1000,
        location: {
          coord: {
            lat: 50,
            lng: 50
          },
          time: (0, _moment.default)().toDate()
        }
      }
    }
  };
  let mockedUserEntries = null;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    config: _config.default,
    userActivitySelected: null,
    errors: null,
    details: '',
    columns: _config.columns,
    table: null,
    events: null,
    addActivityLog: false,
    isAvlmLite: _environment.default.APP.avlmLite,
    route: Ember.computed.readOnly('workspace.topOptions.route'),
    displayName: Ember.computed.readOnly('workspace.topOptions.displayName'),
    title: Ember.computed.alias('displayName'),
    isLoading: Ember.computed.alias('fetchEventsTask.isRunning'),
    startDate: Ember.computed.alias('workspace.startDate'),
    endDate: Ember.computed.alias('workspace.endDate'),
    onFilteredEventsChange: Ember.observer('filteredEvents', function () {
      Ember.run.scheduleOnce('afterRender', this, 'updateTableRows');
    }),

    init() {
      this._super(...arguments);

      const currColumns = this.get('columns');
      const table = new _emberLightTable.default(currColumns);
      this.set('table', table);
      mockedUserEntries = []; // initialize key observer

      this.onFilteredEventsChange();
      this.get('fetchEventsTask').perform();
    },

    validateRecords() {
      let valid = true;
      const addActivityLog = this.get('addActivityLog');
      const activity = this.get('userActivitySelected');
      const details = this.get('details');
      this.set('errors', []);

      if (!addActivityLog) {
        this.get('errors').pushObject('Please Open Activity Entry Form through Plus Icon before Approving'); // this.get('notifications').warning('Please Open Activity Entry Form through Plus Icon before Approving');

        return false;
      }

      if (Ember.isEmpty(activity)) {
        valid = false;
        this.get('errors').pushObject('Must Select an Activity');
      }

      if (Ember.isEmpty(details)) {
        valid = false;
        this.get('errors').pushObject('Must Enter Details');
      }

      return valid;
    },

    filteredSections: Ember.computed('editableSections', function () {
      const sections = Ember.copy(this.get('editableSections'), true) || [];
      return sections.map(section => {
        return section;
      }).filter(section => section.fields.length > 0);
    }),
    filteredEvents: Ember.computed('events.[]', function () {
      let events = this.get('events') || []; // sort reverse-chronologically while we're at it

      events = events.sortBy('date');
      events.reverse();
      return events;
    }),
    fetchEventsTask: (0, _emberConcurrency.task)(function* () {
      const routeId = this.get('route.id');
      this.set('events', []);
      const routeExecEvents = yield this.get('store').query('route-exec-event', {
        routeId: routeId
      });
      const events = routeExecEvents.reduce((arr, routeExecEvent) => {
        const event = this._buildEvent(routeExecEvent);

        if (event) {
          arr.push(event);
        }

        return arr;
      }, []);
      const mockedEntries = mockedUserEntries.reduce((arr, routeExecEvent) => {
        const emberObj = Ember.Object.create(routeExecEvent);

        const event = this._buildEvent(emberObj);

        if (event) {
          arr.push(event);
        }

        return arr;
      }, []);

      if (mockedEntries.length > 0) {
        events.push(...mockedEntries);
      }

      this.set('events', events);
    }).drop(),

    updateTableRows() {
      const table = this.get('table');
      const filteredEvents = this.get('filteredEvents');
      table.setRows(filteredEvents);
    },

    // this should be replaced when there is a real API
    _buildEvent(routeExecEvent) {
      const date = new Date(routeExecEvent.get('content.actualTime'));
      const type = routeExecEvent.get('content.type');
      const source = routeExecEvent.get('source');

      const logEntryType = _config.logEntryTypes.findBy('routeExecEventType', type);

      if (!logEntryType) return;
      const {
        condition,
        buildAttrs,
        buildDetails,
        buildUser
      } = logEntryType;
      let {
        activity
      } = logEntryType;
      /*eslint-disable */

      if (condition && !condition(routeExecEvent)) return null;
      const attrs = buildAttrs(routeExecEvent, this.get('store'));

      if (attrs.activity) {
        activity = attrs.activity;
      }

      attrs.odometer = attrs.odometer ? (0, _convertDistance.convertMetersToMiles)(attrs.odometer) : null;
      const details = buildDetails(attrs);
      let user = 'Dispatch';

      if (type === 'noShowRequest' || source !== 'webApp') {
        user = buildUser(attrs);
      }

      return {
        activity,
        date,
        details,
        user
      };
      /*eslint-enable */
    },

    actions: {
      onValueChange(valuePath, options) {
        let value = options;

        if (typeof options === 'object') {
          value = options.get('name');
        }

        this.set(valuePath, value);
      },

      onSaveClick() {
        if (!this.validateRecords()) return;
        this.set('errors', null);
        const activity = this.get('userActivitySelected');
        const details = this.get('details');
        const tooltip = this.get('tooltip');
        const currentUser = localStorage.getItem('currentUser');
        const title = this.get('title');
        avlmEventMockedEntry.content.details = details;
        avlmEventMockedEntry.content.activity = activity;
        avlmEventMockedEntry.content.userId = currentUser;
        avlmEventMockedEntry.content.actualTime = (0, _moment.default)().toDate();
        const newEntry = (0, _lodash.cloneDeep)(avlmEventMockedEntry);
        const fetchEventsTask = this.get('fetchEventsTask');
        const notifications = this.get('notifications');
        tooltip.setProperties({
          tip: 'Are you sure you want to add an entry to the activity log',
          title,
          hasOverlay: true,
          primaryActionText: 'Confirm',
          secondaryActionText: 'Cancel',

          primaryAction() {
            Ember.run.later(() => {
              notifications.success('New Activity Log Entry Added Successfully');
              mockedUserEntries.pushObject(newEntry);
              fetchEventsTask.perform();
              tooltip.reset();
            }, 1000); // return ajax.post(API.avlmService.host + '/route-exec-event', {
            //   method: 'POST',
            //   contentType: 'application/json',
            //   headers: {
            //     'Authorization': `Bearer ${session.data.authenticated.token}`
            //   },
            //   data: json
            // }).then((/*response*/) => {
            //   notifications.success('Added New Activity Log');
            //   onConfirm();
            //   tooltip.reset();
            // })
            //   .catch(() => {
            //     notifications.warning('Failed to add Activity Log');
            //     tooltip.reset();
            //   });
          },

          secondaryAction() {
            tooltip.reset();
          }

        });
      },

      onAddClick() {
        this.set('errors', null);
        this.toggleProperty('addActivityLog');
      }

    }
  });

  _exports.default = _default;
});