define("adept-iq/pods/components/side-drawer/scheduling-auto-reschedule/component", ["exports", "ember-light-table", "ember-concurrency", "adept-iq/utils/unwrapProxy", "lodash", "adept-iq/pods/components/side-drawer/scheduling-auto-reschedule/config"], function (_exports, _emberLightTable, _emberConcurrency, _unwrapProxy, _lodash, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    // passed by the side drawer container component
    onCloseClick: null,
    solutionTable: null,
    rowComponent: 'table/rows/colored-row',
    title: 'Trip Rescheduled',
    previouslyTriedRoutes: null,
    //passed  by the workspace service
    trip: Ember.computed.readOnly('stackFrame.options.trip'),
    rescheduleTrip: null,
    isWaitListedTrip: Ember.computed.readOnly('trip.isWaitlisted'),
    isOriginalRouteActionPerformed: false,
    isDisabledOriginalRouteBtn: Ember.computed('isWaitListedTrip', 'isOriginalRouteActionPerformed', function () {
      return this.get('isWaitListedTrip') || this.get('isOriginalRouteActionPerformed');
    }),

    init() {
      this._super(...arguments);

      const table = new _emberLightTable.default(_config.solutionColumns);
      this.set('solutionTable', table);
      this.set('previouslyTriedRoutes', []);
      this.get('fetchRescheduleTrip').perform();
    },

    didInsertElement() {
      this._super(...arguments); // side panel width just for trip transfer


      Ember.$('.side-drawer-modal').width('600px');
    },

    setupSolutionPreview() {
      const trip = this.get('trip');
      const rescheduleTrip = this.get('rescheduleTrip');
      let tripList = [];
      const rider = (0, _unwrapProxy.unwrapProxy)(trip.get('rider'));
      const rescheduleTripRider = (0, _unwrapProxy.unwrapProxy)(rescheduleTrip.get('rider'));
      trip.get('stops').forEach(stop => {
        const data = {
          tripId: stop.get('trip.tripId'),
          plannedEta: stop.get('plannedEta'),
          stopType: stop.get('type'),
          computedPromiseTime: stop.get('promiseTime'),
          lastName: rider.get('lastName'),
          selectedOrdinalRow: true
        };
        tripList.push(data);
      });
      rescheduleTrip.get('stops').forEach(stop => {
        const data = {
          tripId: stop.get('trip.tripId'),
          plannedEta: stop.get('plannedEta'),
          stopType: stop.get('type'),
          computedPromiseTime: stop.get('promiseTime'),
          lastName: rescheduleTripRider.get('lastName')
        };
        tripList.push(data);
      });
      tripList = (0, _lodash.orderBy)(tripList, tripInfo => {
        return tripInfo.plannedEta;
      }, ['asc']);
      this.get('solutionTable').setRows(tripList);
    },

    fetchRescheduleTrip: (0, _emberConcurrency.task)(function* () {
      const tripId = this.get('trip.id');
      const rescheduleTrip = yield this.get('store').peekRecord('trip', tripId);
      this.set('rescheduleTrip', rescheduleTrip);
      const trip = this.get('rescheduleTrip');
      const routeId = parseInt(trip.get('route.id'), 10);
      this.get('previouslyTriedRoutes').push(routeId);
      this.setupSolutionPreview();
    }).drop(),
    waitListTrip: (0, _emberConcurrency.task)(function* () {
      const scheduleAdapter = this.get('store').adapterFor('schedule');
      const tripId = parseInt(this.get('trip.id'), 10);
      yield scheduleAdapter.waitListTrip(tripId);
    }),
    retryTrip: (0, _emberConcurrency.task)(function* () {
      const scheduleAdapter = this.get('store').adapterFor('schedule');
      const tripId = parseInt(this.get('trip.id'), 10);
      const previouslyTriedRoutes = this.get('previouslyTriedRoutes');
      const payload = {
        'data': {
          'type': 'tripReschedule',
          'attributes': {
            routeUsage: 1,
            routes: previouslyTriedRoutes
          }
        }
      };
      yield scheduleAdapter.autoRescheduleTrip(tripId, payload);
    }),
    originalRouteTrip: (0, _emberConcurrency.task)(function* () {
      const scheduleAdapter = this.get('store').adapterFor('schedule');
      const tripId = parseInt(this.get('trip.id'), 10);
      const routeId = parseInt(this.get('trip.route.id'), 10);
      const payload = {
        'data': {
          'type': 'tripReschedule',
          'attributes': {
            routeUsage: 2,
            routes: [routeId]
          }
        }
      };
      yield scheduleAdapter.autoRescheduleTrip(tripId, payload);
    }),
    actions: {
      onAcceptButtonClick() {
        this.onCloseClick();
      },

      onWaitListButtonClick() {
        const tripId = this.get('trip.tripId');
        const successMsg = `Trip ${tripId} successfully placed on waitlist`;
        const errorMsg = 'Unable to place trip on waitlist.';
        this.get('waitListTrip').perform().then(() => {
          this.get('notifications').success(successMsg);
          this.onCloseClick();
        }).catch(() => {
          this.get('notifications').warning(errorMsg);
        });
      },

      onRetryButtonClick() {
        const tripId = this.get('trip.tripId');
        const routeName = this.get('rescheduleTrip.routeName');
        const successMsg = `Trip ${tripId} successfully rescheduled to route ${routeName}`;
        const errorMsg = 'Unable to retry a different route';
        this.get('retryTrip').perform().then(() => {
          this.get('notifications').success(successMsg);
          this.onCloseClick();
        }).catch(() => {
          this.get('notifications').warning(errorMsg);
          this.get('fetchRescheduleTrip').perform();
        });
      },

      onOriginalRouteButtonClick() {
        const tripId = this.get('trip.tripId');
        const routeName = this.get('trip.routeName');
        const successMsg = `Trip ${tripId} successfully placed back to original route ${routeName}`;
        const errorMsg = 'Unable to return trip to original route';
        this.get('originalRouteTrip').perform().then(() => {
          this.get('notifications').success(successMsg);
          this.set('isOriginalRouteActionPerformed', true);
        }).catch(() => {
          this.get('notifications').warning(errorMsg);
        });
      }

    }
  });

  _exports.default = _default;
});