define("adept-iq/pods/components/side-drawer/add-widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o5nkDAOm",
    "block": "{\"symbols\":[\"drawer\",\"widgetSection\"],\"statements\":[[4,\"generic-widgets/side-drawer\",null,[[\"class\"],[\"add-widget\"]],{\"statements\":[[0,\"\\n  \"],[1,[27,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"header\"]],\"expected `drawer.header` to be a contextual component but found a string. Did you mean `(component drawer.header)`? ('adept-iq/pods/components/side-drawer/add-widget/template.hbs' @ L5:C4) \"],null]],[[\"title\",\"onShrinkClick\",\"onCloseClick\"],[[23,[\"title\"]],[27,\"action\",[[22,0,[]],\"onShrinkClick\"],null],[27,\"action\",[[22,0,[]],\"onCloseClick\"],null]]]],false],[0,\"\\n\\n\"],[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"body\"]],\"expected `drawer.body` to be a contextual component but found a string. Did you mean `(component drawer.body)`? ('adept-iq/pods/components/side-drawer/add-widget/template.hbs' @ L10:C5) \"],null]],null,{\"statements\":[[4,\"each\",[[23,[\"widgetSections\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"side-drawer/add-widget/widget-button-section\",null,[[\"widgetSection\",\"addWidget\"],[[22,2,[]],[27,\"action\",[[22,0,[]],\"addWidget\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/side-drawer/add-widget/template.hbs"
    }
  });

  _exports.default = _default;
});