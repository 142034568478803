define("adept-iq/models/trip", ["exports", "ember-data", "moment", "adept-iq/utils/format-text-extension", "adept-iq/mixins/otp-label", "adept-iq/models/stop-point", "adept-iq/utils/unwrapProxy"], function (_exports, _emberData, _moment, _formatTextExtension, _otpLabel, _stopPoint, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  // TRIP STATUS Notes
  // Requested :  New booking (eligibility verified) and Client (NYCT) agrees to fulfil the trip.
  // Promised :  time Promised (not scheduled) using Demand Smoother.
  // Scheduled : Time Promised and Assigned to Route & Provider (Service Mode / booking with eHail API)
  // Waitlisted : Trip could not be assigned to a route  - either it failed auto scheduling or dispatcher manually Waitlist-ed a Trip.
  // Dispatched : Route on which Trip was assigned now assigned to Vehicle / Driver
  // En route : Trip's route has started but the vehicle is not yet at the trip's pick up stop. Trip not started. (OTW to perform a pick)
  // Active : Pick performed
  // Completed : Drop performed
  // No showed  : Trip's pickup stop was saved as no-show
  // No show requested: driver has requested that dispatch save trip as noshow
  // Callback : More info needed from the passenger to confirm the Booking. Booking is saved and results in a Trip but not Scheduled yet.
  // Will call : Booking failed to be acknowledged and no Promise time added. Trip is saved in IQ but not yet scheduled. an orphaned booking.
  // Failed : Route or Trip failed for other reasons. Can be system failure. Need System Error codes to define this Status further.
  // Canceled : Trip was canceled
  // Late canceled : Trip was cancelled late (as per config definition)
  // Archived: TBD Driver/dispatcher has ended the trip's route.
  // Illegal : no vehicle to service the trip or Max Ride time violated or Leg of A (was on Voucher first or Broker, and then comes back to ADEPT to schedule) forcing promise time of Leg B to be violated
  // The following trip statuses will be displayed in IQ for version R4.x:
  // Scheduled: trip has a route ID and is not completed. In R4.x version only scheduled trips are exported from ADEPT 4.
  // Waitlisted: trip has no route ID
  // Active: the trip's pick stop has been arrived and departed
  // Completed: the trip's drop stop has been arrived and departed
  // Canceled: Trip was canceled. In R4.x version the trip must be cancelled in ADEPT 4 and then the status is sent to IQ.
  // Illegal: engine tried but was unable to schedule the trip.
  const convertStatusDisplay = {
    scheduled: 'Scheduled',
    requested: 'Requested',
    promised: 'Promised',
    dispatched: 'Dispatched',
    active: 'Active',
    completed: 'Completed',
    callback: 'Callback',
    willCall: 'Will call',
    failed: 'Failed',
    canceled: 'Canceled',
    cancelled: 'Canceled',
    lateCancelled: 'Late Cancelled',
    archived: 'Archived',
    unscheduled: 'Unscheduled',
    waitlisted: 'Waitlisted',
    illegal: 'Illegal',
    noShowRequest: 'No Show Request',
    noShow: 'No Show',
    'No Show': 'No Show',
    'En Route': 'En Route',
    'A': 'Active',
    'C': 'Completed'
  };
  const TRIP_CANCELED_STATUS = 'canceled';
  const TRIP_LATE_CANCELED_STATUS = 'lateCancelled';
  const TRIP_WAITLIST_STATUS = 'waitlisted';
  const TRIP_NOSHOW_STATUS = 'noShow';
  const TRIP_SCHEDULED_STATUS = 'scheduled';
  const TRIP_ILLEGAL_STATUS = 'illegal';

  var _default = Model.extend(_otpLabel.default, {
    activeContext: Ember.inject.service(),
    tripId: attr('string'),
    promiseTime: attr('date'),
    status: attr('string'),
    // 	'unscheduled', 'scheduled', 'waitlisted', 'illegal'
    otp: attr('string'),
    schedulingMode: attr('string'),
    statusReason: attr('string'),
    otpValue: attr('number'),
    currentProviderName: attr('string'),
    schedule: belongsTo('schedule'),
    route: belongsTo('route'),
    subscription: belongsTo('subscription'),
    booking: belongsTo('booking'),
    segment: belongsTo('segment'),
    reason: belongsTo('no-show-reason-code'),
    pick: belongsTo('trip-stop', {
      inverse: 'pickTripStop'
    }),
    drop: belongsTo('trip-stop', {
      inverse: 'dropTripStop'
    }),
    provider: belongsTo('provider'),
    stopPoints: hasMany('stop-point'),
    routeAssignments: hasMany('trip-route-assignment'),
    rider: Ember.computed.alias('segment.leg.rider'),
    routeName: Ember.computed.alias('route.name'),
    dispatchRoute: Ember.computed.alias('pickStopPoint.dispatchRoute'),
    travelNeedCounts: Ember.computed.readOnly('segment.leg.travelNeedCounts'),
    isActive: Ember.computed.readOnly('pickStopPoint.isPerformed'),
    isCompleted: Ember.computed.readOnly('dropStopPoint.isPerformed'),
    isScheduled: Ember.computed.equal('status', TRIP_SCHEDULED_STATUS),
    isWaitlisted: Ember.computed.equal('status', TRIP_WAITLIST_STATUS),
    isNoShow: Ember.computed.equal('status', TRIP_NOSHOW_STATUS),
    isIllegal: Ember.computed.equal('status', TRIP_ILLEGAL_STATUS),
    isPickPerformed: Ember.computed.readOnly('pickStopPoint.isPerformed'),
    stops: Ember.computed('pick', 'drop', function () {
      const pick = (0, _unwrapProxy.unwrapProxy)(this.get('pick'));
      const drop = (0, _unwrapProxy.unwrapProxy)(this.get('drop'));
      return [pick, drop];
    }),
    sortedRouteAssignments: Ember.computed('routeAssignments.[]', function () {
      return this.get('routeAssignments').sortBy('ordinal');
    }),
    lastRouteAssignment: Ember.computed('sortedRouteAssignments.[]', function () {
      const routeAssignment = this.get('sortedRouteAssignments').lastObject;
      return routeAssignment;
    }),
    polyline: Ember.computed('segment.polyline', 'pickStopPoint.{lat,lng}', 'dropStopPoint.{lat,lng,computedStatus}', function () {
      const dropStopPointStatus = this.get('dropStopPoint.computedStatus');
      if (dropStopPointStatus === _stopPoint.NOSHOW_STOP_STATUS) return null;
      const segmentPolyline = this.get('segment.polyline');
      if (segmentPolyline) return segmentPolyline; // segment polyline doesn't exist use, dispatch stop points to generate polyline

      const dropStopPoint = this.get('dropStopPoint');
      const pickStopPoint = this.get('pickStopPoint');

      if ([dropStopPoint, pickStopPoint].any(Ember.isNone)) {
        return null;
      }

      const pickLat = pickStopPoint.get('lat');
      const pickLng = pickStopPoint.get('lng');
      const dropLat = dropStopPoint.get('lat');
      const dropLng = dropStopPoint.get('lng');

      if ([pickLat, pickLng, dropLat, dropLng].any(Ember.isNone)) {
        return null;
      }

      return [[dropLat, dropLng], [pickLat, pickLng]];
    }),
    isCanceled: Ember.computed('status', function () {
      const status = this.get('status');
      return status === TRIP_CANCELED_STATUS || status === TRIP_LATE_CANCELED_STATUS;
    }),
    otpValueWithSign: Ember.computed('otp', 'otpValue', function () {
      const otp = this.get('otp');
      const otpValue = this.get('otpValue');
      const sign = otp === 'O' || otp === 'E' ? '-' : '+';

      if (otpValue) {
        return `${sign}${Math.abs(otpValue)}`;
      }

      return '';
    }),
    pickStopPoint: Ember.computed('stopPoints', function () {
      return this.get('stopPoints').findBy('stopType', 'pick');
    }),
    dropStopPoint: Ember.computed('stopPoints', function () {
      return this.get('stopPoints').findBy('stopType', 'drop');
    }),
    noShow: Ember.computed('pickStopPoint.noShow.noShowReasonCode', 'dropStopPoint.noShow.noShowReasonCode', function () {
      const pick = (0, _unwrapProxy.unwrapProxy)(this.get('pickStopPoint.noShow'));
      const drop = (0, _unwrapProxy.unwrapProxy)(this.get('dropStopPoint.noShow'));
      return pick ? pick : drop;
    }),
    ordinalStops: Ember.computed('stops.@each.{ordinal,status}', function () {
      // IQUX-1860 because of an issue in ESS to sent delete record from
      // a cancel or trip transfer, we are just hiding the trip and stop
      // for now
      if (this.get('status') === 'Removed') {
        return [];
      }

      const stops = this.get('stops');
      if (!Ember.isEmpty(stops)) return stops.filter(stop => Ember.isPresent(stop.get('ordinal')));
      return [];
    }),
    formattedTravelNeeds: Ember.computed('segment.leg.equipments', function () {
      const travelNeeds = [];
      const equipments = this.get('segment.leg.equipments');

      if (!Ember.isEmpty(equipments)) {
        equipments.forEach(function (item) {
          const count = item.get('count');
          const name = item.get('travelNeedType.name');
          const text = (0, _formatTextExtension.formatTextExtension)('valueItem', `${count} ${name}`);
          travelNeeds.push(text);
        });
      }

      travelNeeds.push((0, _formatTextExtension.formatTextExtension)('mainItem', ''));
      return {
        values: travelNeeds
      };
    }),
    noOfTravelNeeds: Ember.computed.alias('formattedTravelNeeds.length'),
    tripStartTime: Ember.computed('pick.segmentStop.tripStartTime', 'promiseTime', 'segment.leg.requestTime', function () {
      const pickTime = this.get('pick.content.tripStartTime');
      const requestTime = this.get('segment.leg.requestTime');
      const promiseTime = this.get('promiseTime');

      if (pickTime) {
        return pickTime;
      }

      if (promiseTime) {
        return promiseTime;
      }

      return requestTime;
    }),
    tripEndTime: Ember.computed('drop.segmentStop.tripStartTime', 'promiseTime', 'segment.leg.requestTime', function () {
      const dropTime = this.get('drop.content.tripEndTime');
      const requestTime = this.get('segment.leg.requestTime');
      const promiseTime = this.get('promiseTime');

      if (dropTime) {
        return dropTime;
      }

      if (promiseTime) {
        return promiseTime;
      }

      return requestTime;
    }),
    routeTripStartTime: Ember.computed('tripStartTime', 'dispatchRoute.tripStartTime', function () {
      const routeStart = this.get('dispatchRoute.tripStartTime');
      const tripStart = this.get('tripStartTime');

      if (routeStart) {
        return routeStart;
      }

      return tripStart;
    }),
    routeTripEndTime: Ember.computed('tripEndTime', 'dispatchRoute.tripEndTime', function () {
      const routeEnd = this.get('dispatchRoute.tripEndTime');
      const tripEnd = this.get('tripEndTime');

      if (routeEnd) {
        return routeEnd;
      }

      return tripEnd;
    }),
    anchorStop: Ember.computed('segment.anchor', function () {
      const anchor = this.get('segment.anchor');
      return this.get(`${anchor}`);
    }),
    serviceWindowStartTime: Ember.computed('segment.leg.serviceWindow', function () {
      const serviceWindow = this.get('segment.leg.serviceWindow');
      const earliestPickTime = (0, _moment.default)(this.get('promisedTime'));

      if (!Ember.isEmpty(serviceWindow)) {
        // if the service window information is available, the earliest pick time is the start of the promise window
        const promiseWindowStart = this.get('segment.leg.serviceWindow.promiseWindowStart');
        earliestPickTime.subtract(promiseWindowStart, 'm');
      }

      return earliestPickTime;
    }),
    serviceWindowEndTime: Ember.computed('segment.leg.serviceWindow', function () {
      const serviceWindow = this.get('segment.leg.serviceWindow');
      const earliestDropTime = (0, _moment.default)(this.get('requestedTime'));

      if (serviceWindow && earliestDropTime !== null) {
        // if the service window information is available, the earliest drop time is the start of the promise window
        const dropWindowStart = this.get('segment.leg.serviceWindow.dropWindowStart');
        earliestDropTime.subtract(dropWindowStart, 'm');
      }

      return earliestDropTime;
    }),
    driver: Ember.computed.alias('dispatchRoute.assignedDriver'),
    // currently status is a lowercase string
    // @TODO remove capitalize once status are more standardized
    mapId: Ember.computed('computedStatus', function () {
      const status = this.get('computedStatus');
      if (Ember.isPresent(status)) return Ember.String.capitalize(status);
      return null;
    }),
    type: Ember.computed('type', function () {
      const dsBooking = this.get('booking');
      if (dsBooking.get('subscription.content')) return 'Subscription';
      return 'single';
    }),
    plannedMiles: Ember.computed('pick.plannedDistanceToNext', 'drop.plannedDistanceToNext', function () {
      const pickMiles = parseFloat(this.get('pick.plannedDistanceToNext'));
      const dropMiles = parseFloat(this.get('drop.plannedDistanceToNext'));
      if (!isNaN(pickMiles) && !isNaN(dropMiles)) return `${pickMiles + dropMiles}`;else if (!isNaN(pickMiles)) return pickMiles;
      return dropMiles;
    }),
    // scheduling service / booking service trip statuses: 'unscheduled', 'scheduled', 'waitlisted', 'illegal'
    computedStatus: Ember.computed('status', 'isActive', 'isCompleted', 'pickStopPoint.isNoShow', function () {
      const isActive = this.get('isActive');
      const isCompleted = this.get('isCompleted');
      const isNoShow = this.get('pickStopPoint.isNoShow');
      const result = convertStatusDisplay[this.get('status')];

      if (isCompleted) {
        return convertStatusDisplay.completed;
      } else if (isActive) {
        return convertStatusDisplay.active;
      }

      if (isNoShow) {
        return convertStatusDisplay.noShow;
      }

      return result ? result : '';
    }),
    scheduleLocked: Ember.computed('schedule.locked', function () {
      return this.get('schedule.locked');
    }),
    isLocked: Ember.computed('dispatchRoute.isLocked', function () {
      return this.get('dispatchRoute.isLocked');
    }),
    lockedBy: Ember.computed('dispatchRoute.lockedBy', function () {
      return this.get('dispatchRoute.lockedBy');
    }),
    lockedId: Ember.computed('isLocked', function () {
      return this.get('dispatchRoute.id');
    }),
    isLockedByOtherUser: Ember.computed('dispatchRoute.isLockedByOtherUser', function () {
      return this.get('dispatchRoute.isLockedByOtherUser');
    }),
    currentRoute: Ember.computed('route', 'pickStopPoint.dispatchRoute.route', function () {
      // if trip has reached dispatch, source its route via cluster
      const currentRoute = this.get('pickStopPoint.dispatchRoute.route');
      if (currentRoute && currentRoute.get('id')) return currentRoute; // otherwise, use the route assigned in scheduling service

      return this.get('route');
    }),
    currentRouteName: Ember.computed('computedStatus', 'currentRoute', function () {
      const status = this.get('computedStatus').toLowerCase();

      if (status === TRIP_WAITLIST_STATUS) {
        return '';
      }

      return this.get('currentRoute.name');
    })
  });

  _exports.default = _default;
});