define("adept-iq/utils/flattenData", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.flattenData = void 0;

  const flattenData = data => {
    return Object.entries(data).reduce((arr, [modelName, records]) => {
      records.forEach(record => {
        arr.push({
          modelName,
          record
        });
      });
      return arr;
    }, []);
  };

  _exports.flattenData = flattenData;
});