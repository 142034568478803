define("adept-iq/adapters/schedule", ["exports", "adept-iq/adapters/-ss-schedulingService"], function (_exports, _ssSchedulingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ssSchedulingService.default.extend({
    ajaxService: Ember.inject.service('ajax'),
    // not using "ajax" so there won't be conflict with the base's ajax calls
    session: Ember.inject.service(),

    hypoTrimSlack(currentSchedule) {
      const session = this.get('session');
      const ajax = this.get('ajaxService');
      const uri = this.buildURL('schedule');
      return ajax.patch(`${uri}/${currentSchedule.get('id')}/trimslack`, {
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      });
    },

    hypoRemoveBreak(currentSchedule) {
      const session = this.get('session');
      const ajax = this.get('ajaxService');
      const url = this.buildURL('schedule');
      return ajax.patch(`${url}/${currentSchedule.get('id')}/remove-breaks`, {
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      });
    },

    adjustDriverBreak(id, routes) {
      const session = this.get('session');
      const ajax = this.get('ajaxService');
      const uri = this.buildURL('schedule');
      const type = 'adjustDriverBreaks';
      const json = {
        data: {
          id,
          type,
          links: {},
          meta: {},
          attributes: {
            routes
          }
        }
      };
      return ajax.patch(`${uri}/${id}/adjust-driver-breaks`, {
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    consolidateRoutes(id, routes) {
      const session = this.get('session');
      const ajax = this.get('ajaxService');
      const uri = this.buildURL('schedule');
      const type = 'routeConsolidation';
      const json = {
        data: {
          id,
          type,
          links: {},
          meta: {},
          attributes: {
            routes
          }
        }
      };
      return ajax.patch(`${uri}/${id}/route-consolidation`, {
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    releaseRoutes(currentSchedule) {
      const session = this.get('session');
      const ajax = this.get('ajaxService');
      const uri = this.buildURL('schedule');
      return ajax.patch(`${uri}/${currentSchedule.get('id')}/export`, {
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      });
    },

    routeTripTransfer(tripId, routeId, data) {
      const session = this.get('session');
      const ajax = this.get('ajaxService');
      const url = `${this.host}/route/${routeId}/trip-transfer/${tripId}`;
      return ajax.put(url, {
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: data
      });
    },

    routeBulkTripTransfer(routeId, data) {
      const session = this.get('session');
      const ajax = this.get('ajaxService');
      const url = `${this.host}/route/${routeId}/block-transfer`;
      return ajax.put(url, {
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: data
      });
    },

    autoRescheduleTrip(tripId, payload) {
      const session = this.get('session');
      const ajax = this.get('ajaxService');
      const url = `${this.host}/trip/${tripId}/reschedule`;
      return ajax.put(url, {
        contentType: 'application/json',
        dataType: 'text',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: payload
      });
    },

    waitListTrip(tripId) {
      const session = this.get('session');
      const ajax = this.get('ajaxService');
      const url = `${this.host}/trip/${tripId}/waitlist`;
      return ajax.delete(url, {
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      });
    }

  });

  _exports.default = _default;
});