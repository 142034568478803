define("adept-iq/serializers/subscription", ["exports", "adept-iq/serializers/-bs-bookingService"], function (_exports, _bsBookingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsBookingService.default.extend({
    serialize()
    /* snapshot, options */
    {
      const json = this._super(...arguments);

      if (json.data && json.data.attributes) delete json.data.attributes.serviceWindowName;

      if (json.data && json.data.relationships) {
        const fareTypeRelationship = json.data.relationships.fareType;

        if (!Ember.isNone(fareTypeRelationship)) {
          fareTypeRelationship.data.type = 'fareTypeName';
          json.data.relationships.fareTypeName = json.data.relationships.fareType;
          delete json.data.relationships.fareType;
        }
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.attributes)) {
        if (!Ember.isNone(resourceHash.attributes.fareTypeName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.fareType = {
            data: {
              type: 'fare-type',
              id: resourceHash.attributes.fareTypeName
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.attributes.fareTypeName;
        }

        if (!Ember.isNone(resourceHash.attributes.serviceWindowName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.serviceWindow = {
            data: {
              type: 'service-window',
              id: resourceHash.attributes.serviceWindowName
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.attributes.serviceWindowName;
        }

        if (!Ember.isNone(resourceHash.attributes.providerName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.provider = {
            data: {
              type: 'provider',
              id: resourceHash.attributes.providerName
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.attributes.providerName;
        }
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});