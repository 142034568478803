define("adept-iq/adapters/route-vehicle-driver", ["exports", "adept-iq/adapters/-dispatch", "adept-iq/config/api-urls", "moment"], function (_exports, _dispatch, _apiUrls, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dispatch.default.extend({
    ajaxService: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),

    createRouteVehicleDriver(dispatchRoutePayload, route) {
      const ajax = this.get('ajaxService');
      const session = this.get('session');
      const {
        dispatchSchedule
      } = dispatchRoutePayload.data.relationships;
      const json = {
        data: {
          type: 'routeVehicleDriver',
          attributes: {
            startTime: (0, _moment.default)(route.get('plannedStartTime')).format(),
            endTime: (0, _moment.default)(route.get('plannedEndTime')).format()
          },
          relationships: {
            dispatchRoute: {
              data: {
                id: dispatchRoutePayload.data.id,
                type: 'dispatchRoute'
              }
            },
            dispatchSchedule: {
              data: {
                id: dispatchSchedule.data.id,
                type: 'dispatchSchedule'
              }
            }
          }
        }
      };
      return ajax.post(_apiUrls.API.dispatchService.host + '/route-vehicle-driver/', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    normalizeErrorResponse(status, headers, payload) {
      if (payload && typeof payload === 'object' && payload.errors) {
        return payload.errors; // To deal with the API's error messages that do not comply to JSONAPI Standard.
      } else if (Ember.isPresent(payload.message)) {
        const error = {
          status: `${status}`,
          title: 'The backend responded with an error',
          detail: payload.message
        };

        if (payload.message.status('409') < 0) {
          this.get('notifications').warning(payload.message);
        }

        return [error];
      }

      return [{
        status: `${status}`,
        title: 'The backend responded with an error',
        detail: `${payload}`
      }];
    }

  });

  _exports.default = _default;
});