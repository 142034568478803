define("adept-iq/pods/components/iq-widgets/routes-start-form-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Read Only',
      fields: [{
        id: 'routeId',
        type: 'text',
        label: 'Route Id',
        valuePath: 'name',
        editable: false
      }]
    }, {
      title: 'Route',
      fields: [{
        id: 'routeId',
        type: 'text',
        label: 'Route ID',
        valuePath: 'name',
        editable: true,
        readonlyCell: true,
        hidden: false
      }, {
        id: 'vehicleId',
        type: 'text',
        label: 'Vehicle ID',
        valuePath: 'dispatchRoute.assignedVehicle.name',
        editable: true,
        readonlyCell: true,
        hidden: false
      }, {
        id: 'driverId',
        type: 'text',
        label: 'Driver ID',
        valuePath: 'dispatchRoute.assignedDriver.driverId',
        editable: true,
        readonlyCell: true,
        hidden: false
      }, {
        id: 'driverId',
        type: 'text',
        label: 'Driver Name',
        valuePath: 'dispatchRoute.assignedDriver.fullName',
        editable: true,
        readonlyCell: true,
        hidden: false
      }, {
        id: 'phoneNuber',
        type: 'text',
        label: 'Driver Number',
        valuePath: 'dispatchRoute.latestRouteVehicleDriver.driverPhoneNumber',
        editable: true,
        readonlyCell: true,
        hidden: false
      }]
    }, {
      title: 'Route Start',
      fields: [{
        title: 'Route Start',
        type: 'datetimeflatpickr',
        label: 'Planned Start Time',
        valuePath: 'dispatchRoute.plannedStartTime',
        editable: true,
        format: 'Y-m-d h:i K',
        readonlyCell: true,
        defaultWidth: 50
      }, {
        id: 'timestamp',
        type: 'datetimeflatpickr',
        label: 'Actual Start Time',
        valuePath: 'timestamp',
        editable: true,
        hidden: false,
        format: 'Y-m-d h:i K',
        defaultWidth: 50,
        extra: {
          minTimePath: 'dispatchRoute.plannedStartTime'
        }
      }, {
        id: 'odometer',
        type: 'number',
        placeholder: 'Enter Odometer Value (in Miles)',
        editable: true,
        label: 'Odometer',
        valuePath: 'odometerInMiles'
      }]
    }]
  };
  _exports.default = _default;
});