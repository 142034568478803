define("adept-iq/pods/components/form-components/address-search/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_DEBOUNCE_TIME = 300;

  var _default = Ember.Component.extend({
    classNames: ['address-search-component'],
    classNameBindings: ['isFocused', 'isBlurred', 'hasSelection', 'isEmpty'],
    addressService: Ember.inject.service('address'),
    user: Ember.inject.service(),
    roadSupervisor: Ember.inject.service(),
    text: '',
    selectedAddress: null,
    inputClass: null,
    debounceTime: DEFAULT_DEBOUNCE_TIME,
    onTextChange: () => null,
    onAddressSelect: () => null,
    searchResults: null,
    isFocused: false,
    isExpanded: false,
    isBlurred: Ember.computed.not('isFocused'),
    hasSelection: Ember.computed.bool('selectedAddress'),
    isEmpty: Ember.computed.not('hasSelected'),

    didInsertElement() {
      Ember.run.schedule('afterRender', () => {
        this.$('input').focus();
      });

      this._bodyClickHandler = event => {
        if (this.get('isDestroyed')) return; // close dropdown if we click elsewhere in application

        if (!event.target.closest('.address-search-component')) {
          this.set('isExpanded', false);
        }
      };

      document.body.addEventListener('click', this._bodyClickHandler);
    },

    willRemoveElement() {
      document.body.removeEventListener('click', this._bodyClickHandler);
    },

    searchTask: (0, _emberConcurrency.task)(function* () {
      const text = this.get('text');
      let results; // can't do a blank search

      if (Ember.isEmpty(text)) {
        this.set('searchResults', null);
        this.set('isExpanded', false);
        return;
      }

      this.set('isExpanded', true);

      if (this.user.isRoadSupEnable()) {
        const center = this.roadSupervisor.getLocation();
        results = yield this.get('addressService').fuzzySearch(text, {
          center,
          limit: 25
        });
      } else {
        results = yield this.get('addressService').fuzzySearch(text, {
          limit: 25
        });
      }

      this.set('searchResults', results);
    }).restartable(),

    // wrapped so we can use `debounce`
    performSearchTask() {
      this.get('searchTask').perform();
    },

    actions: {
      onInputFocus() {
        Ember.run.schedule('afterRender', () => {
          this.set('isFocused', true);

          if (!Ember.isEmpty(this.get('searchResults'))) {
            this.set('isExpanded', true);
          }
        });
      },

      onInputBlur() {
        Ember.run.schedule('afterRender', () => {
          this.set('isFocused', false);
        });
      },

      onInput(text) {
        Ember.run.schedule('afterRender', () => {
          this.onAddressSelect(null);
          this.onTextChange(text);
          Ember.run.debounce(this, 'performSearchTask', this.get('debounceTime'));
        });
      },

      onAddressSelect(address) {
        Ember.run.schedule('afterRender', () => {
          this.onAddressSelect(address);
          this.set('isExpanded', false);
        });
      }

    }
  });

  _exports.default = _default;
});