define("adept-iq/serializers/shift-break", ["exports", "adept-iq/serializers/-ss-schedulingService", "moment"], function (_exports, _ssSchedulingService, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TODAY = (0, _moment.default)();

  var _default = _ssSchedulingService.default.extend({
    serialize()
    /* snapshot, options */
    {
      const json = this._super(...arguments);

      const breakTypeRelationship = json.data.relationships.breakType || {};

      if (!Ember.isNone(breakTypeRelationship) && !Ember.isNone(breakTypeRelationship.data)) {
        breakTypeRelationship.data.type = 'breakTypeName';
        json.data.relationships.breakTypeName = json.data.relationships.breakType;
        delete json.data.relationships.breakType;
      }

      if (json.data.attributes.requestTime) {
        json.data.attributes.requestTime = (0, _moment.default)(json.data.attributes.requestTime).format('HH:mm');
        delete json.data.attributes.requestTimestamp;
      }

      if (json.data.attributes.minutesPlannedDuration) {
        json.data.attributes.plannedDuration = Math.floor(json.data.attributes.minutesPlannedDuration / 60);
        delete json.data.attributes.minutesPlannedDuration;
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      resourceHash.attributes = resourceHash.attributes || {};
      resourceHash.relationships = resourceHash.relationships || {};
      const {
        plannedDuration,
        requestTime
      } = resourceHash.attributes;
      const {
        breakTypeName
      } = resourceHash.relationships;

      if (requestTime) {
        const requestTimestamp = (0, _moment.default)(`${TODAY.format('MM/DD/YYYY')} ${requestTime}`).toISOString();
        resourceHash.attributes.requestTime = requestTimestamp;
      }

      if (plannedDuration) {
        const minutesPlannedDuration = plannedDuration / 60; // convert to minutes

        resourceHash.attributes.minutesPlannedDuration = minutesPlannedDuration;
      }

      if (breakTypeName) {
        delete resourceHash.relationships.breakTypeName;
        resourceHash.relationships.breakType = {
          data: {
            type: 'break-type',
            id: breakTypeName.data.id
          }
        };
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});