define("adept-iq/tests/factories/avlm-vehicle-event", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  /* eslint-disable no-use-before-define */
  _emberDataFactoryGuy.default.define('avlm-vehicle-event', {
    polymorphic: false,
    // manually flag this model as NOT polymorphic
    sequences: {
      ids: num => avlmVehicleEvents[num % avlmVehicleEvents.length].id,
      Types: num => avlmVehicleEvents[num % avlmVehicleEvents.length].type,
      EventTypes: num => avlmVehicleEvents[num % avlmVehicleEvents.length].eventType,
      VehicleIds: num => avlmVehicleEvents[num % avlmVehicleEvents.length].vehicleId,
      Lats: num => avlmVehicleEvents[num % avlmVehicleEvents.length].lat,
      Lngs: num => avlmVehicleEvents[num % avlmVehicleEvents.length].lng,
      Headings: num => avlmVehicleEvents[num % avlmVehicleEvents.length].heading,
      Odos: num => avlmVehicleEvents[num % avlmVehicleEvents.length].odo,
      ReceivedAts: num => avlmVehicleEvents[num % avlmVehicleEvents.length].receivedAt,
      Timestamps: num => avlmVehicleEvents[num % avlmVehicleEvents.length].timestamp
    },
    default: {
      id: _emberDataFactoryGuy.default.generate('ids'),
      type: _emberDataFactoryGuy.default.generate('Types'),
      eventType: _emberDataFactoryGuy.default.generate('EventTypes'),
      vehicleId: _emberDataFactoryGuy.default.generate('VehicleIds'),
      lat: _emberDataFactoryGuy.default.generate('Lats'),
      lng: _emberDataFactoryGuy.default.generate('Lngs'),
      heading: _emberDataFactoryGuy.default.generate('Headings'),
      odo: _emberDataFactoryGuy.default.generate('Odos'),
      receivedAt: _emberDataFactoryGuy.default.generate('ReceivedAts'),
      timestamp: _emberDataFactoryGuy.default.generate('Timestamps')
    }
  });

  const avlmVehicleEvents = [{
    id: 453,
    type: 'avl',
    eventType: 'GPS Ping',
    vehicleId: 513,
    lat: 49.13161,
    lng: -123.10324,
    heading: 1,
    odo: 0,
    receivedAt: '2020-06-23T18:32:23.813Z',
    timestamp: '2020-06-23T18:32:22.456Z'
  }, {
    id: 454,
    type: 'avl',
    eventType: 'GPS Ping',
    vehicleId: 513,
    lat: 49.13161,
    lng: -123.10324,
    heading: 1,
    odo: 0,
    receivedAt: '2020-06-23T18:32:23.813Z',
    timestamp: '2020-06-23T18:32:22.456Z'
  }, {
    id: 455,
    type: 'avl',
    eventType: 'GPS Ping',
    vehicleId: 513,
    lat: 49.13161,
    lng: -123.10324,
    heading: 1,
    odo: 0,
    receivedAt: '2020-06-23T18:32:23.813Z',
    timestamp: '2020-06-23T18:32:22.456Z'
  }, {
    id: 456,
    type: 'avl',
    eventType: 'GPS Ping',
    vehicleId: 513,
    lat: 49.13161,
    lng: -123.10324,
    heading: 1,
    odo: 0,
    receivedAt: '2020-06-23T18:32:23.813Z',
    timestamp: '2020-06-23T18:32:22.456Z'
  }, {
    id: 457,
    type: 'avl',
    eventType: 'GPS Ping',
    vehicleId: 513,
    lat: 49.13161,
    lng: -123.10324,
    heading: 1,
    odo: 0,
    receivedAt: '2020-06-23T18:32:23.813Z',
    timestamp: '2020-06-23T18:32:22.456Z'
  }];
});