define("adept-iq/models/driver-availability", ["exports", "ember-data", "moment", "adept-iq/config/environment"], function (_exports, _emberData, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  const TIME_ZONE = _environment.default.moment.timeZone ? _environment.default.moment.timeZone : 'America/Los_Angeles';

  var _default = Model.extend({
    startTime: attr('date', {
      defaultValue() {
        return '';
      }

    }),
    endTime: attr('date', {
      defaultValue() {
        return '';
      }

    }),
    shiftStart: attr('string', {
      defaultValue: ''
    }),
    shiftEnd: attr('string', {
      defaultValue: ''
    }),
    sunday: attr('boolean'),
    monday: attr('boolean'),
    tuesday: attr('boolean'),
    wednesday: attr('boolean'),
    thursday: attr('boolean'),
    friday: attr('boolean'),
    saturday: attr('boolean'),
    driver: belongsTo('driver'),
    localShiftStart: Ember.computed('shiftStart', {
      get()
      /* key */
      {
        const shiftStart = _moment.default.tz((0, _moment.default)().format('Y-MM-DDT') + this.get('shiftStart'), TIME_ZONE);

        const time = shiftStart.format('h:mm a');
        return time ? time : null;
      },

      set(key, value) {
        const shiftStart = _moment.default.tz('2020-01-01T' + value, TIME_ZONE);

        this.set('shiftStart', shiftStart.utc().format('HH:mm:ssZ'));
        return value;
      }

    }),
    localShiftEnd: Ember.computed('shiftEnd', {
      get()
      /* key */
      {
        const shiftEnd = _moment.default.tz((0, _moment.default)().format('Y-MM-DDT') + this.get('shiftEnd'), TIME_ZONE);

        const endTime = shiftEnd.format('h:mm a');
        return endTime ? endTime : null;
      },

      set(key, value) {
        const shiftEnd = _moment.default.tz('2020-01-01T' + value, TIME_ZONE);

        this.set('shiftEnd', shiftEnd.utc().format('HH:mm:ssZ'));
        return value;
      }

    }),
    formattedShiftStart: Ember.computed('shiftStart', function () {
      const shiftTokens = this.get('shiftStart').split(':');
      if (Ember.isEmpty(shiftTokens)) return null;
      return `${shiftTokens[0]}:${shiftTokens[1]}`;
    }),
    formattedShiftEnd: Ember.computed('shiftEnd', function () {
      const shiftTokens = this.get('shiftEnd').split(':');
      if (Ember.isEmpty(shiftTokens)) return null;
      return `${shiftTokens[0]}:${shiftTokens[1]}`;
    }),
    selectedDOWs: Ember.computed('sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', function () {
      const selectedDOWArray = [];
      if (this.get('sunday')) selectedDOWArray.push('sunday');
      if (this.get('monday')) selectedDOWArray.push('monday');
      if (this.get('tuesday')) selectedDOWArray.push('tuesday');
      if (this.get('wednesday')) selectedDOWArray.push('wednesday');
      if (this.get('thursday')) selectedDOWArray.push('thursday');
      if (this.get('friday')) selectedDOWArray.push('friday');
      if (this.get('saturday')) selectedDOWArray.push('saturday');
      return selectedDOWArray;
    })
  });

  _exports.default = _default;
});