define("adept-iq/pods/components/side-drawer/vehicle-activity-log/config", ["exports", "moment", "lodash"], function (_exports, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.logEntryTypes = _exports.columns = void 0;
  const TIME_FORMAT = 'YYYY-MM-DD hh:mmA';
  const TEMPLATE_INTERPOLATION = /{{([\s\S]+?)}}/g;

  const interpolate = (template, attrs) => {
    const compiledTemplate = _lodash.default.template(template, {
      interpolate: TEMPLATE_INTERPOLATION
    });

    return compiledTemplate(attrs);
  };

  const columns = [{
    label: 'Activity',
    valuePath: 'activity'
  }, {
    label: 'Date/Time',
    valuePath: 'date',
    format: date => (0, _moment.default)(date).format(TIME_FORMAT)
  }, {
    label: 'Details',
    valuePath: 'details'
  }, {
    label: 'User',
    valuePath: 'user'
  }];
  _exports.columns = columns;
  const logEntryTypes = [{
    activity: 'Move No Sign On',
    vehicleExecEventType: 'leaveGarageSignOff',

    buildAttrs(vehicleExecEvent)
    /*store*/
    {
      const attrs = {
        driverId: vehicleExecEvent.get('vehicle.callSign'),
        vehicleId: vehicleExecEvent.get('vehicle.id'),
        lat: vehicleExecEvent.get('vehicle.location.coord.lat'),
        lng: vehicleExecEvent.get('vehicle.location.coord.lng'),
        speed: vehicleExecEvent.get('vehicle.mov.speed'),
        type: vehicleExecEvent.get('content.type')
      };
      return attrs;
    },

    buildDetails(attrs) {
      const template = 'Vehicle {{vehicleId}}, Lat:{{lat}}, Lng:{{lng}}, Speed:{{speed}}';
      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'Sign On',
    vehicleExecEventType: 'signOn',

    buildAttrs(vehicleExecEvent)
    /*store*/
    {
      const attrs = {
        driverId: vehicleExecEvent.get('driver.id'),
        vehicleId: vehicleExecEvent.get('vehicle.id')
      };
      return attrs;
    },

    buildDetails(attrs) {
      const template = 'Moved more than the allowed distance without signing on.';
      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'Breakdown',
    vehicleExecEventType: 'breakdown',

    buildAttrs(vehicleExecEvent)
    /*store*/
    {
      const attrs = {
        driverId: vehicleExecEvent.get('vehicle.callSign'),
        vehicleId: vehicleExecEvent.get('vehicle.id'),
        routeId: vehicleExecEvent.get('vehicle.routeId'),
        address: 'Address example',
        city: 'City example',
        lat: vehicleExecEvent.get('vehicle.location.coord.lat'),
        lng: vehicleExecEvent.get('vehicle.location.coord.lng'),
        breakdownReason: 'Breakdown Reason'
      };
      return attrs;
    },

    buildDetails(attrs) {
      const template = 'Breakdown reported. Reason <Breakdown Reason>. Route {{routeId}}. {{address}} {{city}}. Lat <value>. Lng <value>.';
      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'Vehicle Pre Check',
    vehicleExecEventType: 'preCheckDone',

    buildAttrs(vehicleExecEvent)
    /*store*/
    {
      const attrs = {
        driverId: vehicleExecEvent.get('driver.id'),
        vehicleId: vehicleExecEvent.get('vehicle.id')
      };
      return attrs;
    },

    buildDetails(attrs) {
      const template = 'Pre-check performed.';
      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'Vehicle Post Check',
    vehicleExecEventType: 'postCheckDone',

    buildAttrs(vehicleExecEvent)
    /*store*/
    {
      const attrs = {
        driverId: vehicleExecEvent.get('driver.id'),
        vehicleId: vehicleExecEvent.get('vehicle.id')
      };
      return attrs;
    },

    buildDetails(attrs) {
      const template = 'Post-check performed.';
      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'Message Sent',
    vehicleExecEventType: 'dispatchToDriverMessage',

    buildAttrs(vehicleExecEvent)
    /*store*/
    {
      const attrs = {
        driverId: vehicleExecEvent.get('driver.id'),
        vehicleId: vehicleExecEvent.get('vehicle.id'),
        message: vehicleExecEvent.get('message')
      };
      return attrs;
    },

    buildDetails(attrs) {
      const template = 'Message sent "{{message}}", to vehicle	{{vehicleId}}';
      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'Message Received',
    vehicleExecEventType: 'driverToDispatchMessage',

    buildAttrs(vehicleExecEvent)
    /*store*/
    {
      const attrs = {
        driverId: vehicleExecEvent.get('driver.id'),
        vehicleId: vehicleExecEvent.get('vehicle.id')
      };
      return attrs;
    },

    buildDetails(attrs) {
      const template = 'Message received from vehicle	{{vehicleId}}';
      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }];
  _exports.logEntryTypes = logEntryTypes;
});