define("adept-iq/services/road-supervisor", ["exports", "ember-concurrency", "adept-iq/helpers/has-role", "adept-iq/classes/road-supervisor-instance", "adept-iq/classes/road-supervisor-api"], function (_exports, _emberConcurrency, _hasRole, _roadSupervisorInstance, _roadSupervisorApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.RoadSupervisorError = void 0;

  class RoadSupervisorError extends Error {
    constructor(message) {
      super(message);
      this.name = this.constructor.name;
    }

  }

  _exports.RoadSupervisorError = RoadSupervisorError;
  let id = 1;
  const nullInstance = {
    onGPSLocation() {}

  };
  const nullAddress = {
    displayNavigateToAddress() {}

  };

  var _default = Ember.Service.extend({
    addressService: Ember.inject.service('address'),
    user: Ember.inject.service(),
    gps: Ember.inject.service('gps'),
    speech: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    instance: nullInstance,
    address: nullAddress,
    isNavigating: false,
    rsVehicle: null,

    init() {
      this._super(...arguments);

      if ((0, _hasRole.hasRole)(this.get('user.roles'), _roadSupervisorApi.default.getRoleName())) {
        const onGPSLocation = Ember.run.bind(this, 'onGPSLocation');
        this.gps.onLocationChange(onGPSLocation);
        this.api = _roadSupervisorApi.default.getSingleton();
        this.set('rsVehicle', this.store.createRecord('road-supervisor-vehicle'));
      }
    },

    getLocation() {
      const cord = this.api.get('cord');
      return {
        lat: cord[0],
        lon: cord[1]
      };
    },

    onGPSLocation(val) {
      const timestamp = val.timestamp;
      const lat = val.coords.latitude;
      const lng = val.coords.longitude;
      const heading = val.coords.heading;
      const speed = val.coords.speed;
      const rsVehicle = this.get('rsVehicle');
      rsVehicle.setProperties({
        lat,
        lng,
        heading,
        timestamp
      }); // Always update location, we need latest timestamp

      this.api.setLocationHistory(lat, lng, speed, heading, timestamp);
      this.instance.onGPSLocation(val);
      this.api.setLocation(val);
    },

    // don't interrupt, but only keep the latest queued instruction
    _speechTask: (0, _emberConcurrency.task)(function* (text) {
      return yield this.get('speech').speak(text);
    }).keepLatest(),

    onSpeechEvent(text) {
      if (this.get('isNavigating')) {
        this._speechTask.perform(text);
      }
    },

    onLogoutPerformPullIn() {
      return this.api.clearWayPoint(false);
    },

    onAtDestinationEvent() {
      return this.api.clearWayPoint(true);
    },

    getRSConfigurations() {
      const configItens = this.store.peekAll('cs-config-item').filter(configItem => {
        return configItem.category === 'config-RoadSupervisor_Configuration';
      });
      var configurations = {};
      configItens.forEach(item => {
        configurations[item.get('name')] = item.get('value');
      });
      return configurations;
    },

    // TBD if we are using this to determine provider
    jwtProviderNames: Ember.computed('session.data.authenticated.token', function () {
      const token = this.get('session.data.authenticated.token');
      const tokenInfo = JSON.parse(atob(token.split('.')[1]));
      return tokenInfo.providerNames;
    }),

    handleError(err) {
      if (err instanceof RoadSupervisorError) {
        alert(err.message); // eslint-disable-line no-alert
      } else {
        throw err;
      }
    },

    navigateToAddress(address) {
      this.set('isNavigating', true); // fuzzy-matched addresses are not persisted until needed

      if (address.get('isNew')) {
        // address POST is not working
        // return address.save();
        address.set('id', '' + id++);
      }

      return this._buildInstanceWithWaypoint(address);
    },

    displayNavigateToAddress(address) {
      return this._buildInstanceWithWaypointAddress(address);
    },

    navigateToStop(iqStop) {
      this.set('isNavigating', true);
      const lat = iqStop.get('lat');
      const lng = iqStop.get('lng');
      return Ember.RSVP.resolve().then(() => this._queryAddress(`${lat},${lng}`, {
        lat,
        lng
      })).then(address => this.navigateToAddress(address)).then(() => this.displayNavigateToAddress(iqStop.place.fullAddress)).catch(this.handleError);
    },

    navigateToAddressFromMap(address) {
      this.set('isNavigating', true);
      return Ember.RSVP.resolve().then(() => this.navigateToAddress(address)).then(() => this.displayNavigateToAddress(address.fullAddress)).catch(this.handleError);
    },

    navigateToVehicle(vehicle) {
      throw new RoadSupervisorError('navigating to a vehicle is disabled until further notice');
      return this._buildInstanceWithWaypoint(vehicle); // eslint-disable-line no-unreachable
    },

    enter() {
      return Promise.resolve();
    },

    exit() {
      this.api.clearWayPoint();
      const instance = this.get('instance');
      this.set('instance', nullInstance);

      if (instance !== nullInstance) {
        instance.destroy();
      }

      return this.get('workspace').popState('roadSupervisor');
    },

    // FIXME: this seems to be unused?
    // getAccessData() {
    //   return getAccessData(this.user.userId);
    // },
    async _queryAddress(addressString) {
      try {
        const addresses = await this.get('addressService').fuzzySearch(addressString, {
          limit: 1
        });
        const address = addresses.get('firstObject');

        if (!address) {
          this.set('isNavigating', false);
          throw new RoadSupervisorError('no matching address found');
        } // return address.save();


        return address;
      } catch (err) {
        throw new RoadSupervisorError(err);
      }
    },

    // _findCurrentDispatchSchedule() {
    //   // TODO: replace this stub with an actual `dispatch-schedule`
    //   return EmberObject.create({
    //     id: '1'
    //   });
    // },
    _buildInstanceWithWaypointAddress(address) {
      this.set('address', address);
    },

    _buildInstanceWithWaypoint(waypoint) {
      let instance = this.get('instance');

      if (instance === nullInstance) {
        instance = _roadSupervisorInstance.default.create({
          api: this.api,
          delegate: this
        });
        this.set('instance', instance);
      }

      if (!this.workspace.isRoadSupervisorModeEnabled) {
        this.workspace.pushState('roadSupervisor');
      }

      Ember.RSVP.resolve().then(() => this.api.setWayPoint(waypoint));
    }

  });

  _exports.default = _default;
});