define("adept-iq/pods/components/dashboard-save-as/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WO/k7yVr",
    "block": "{\"symbols\":[],\"statements\":[[4,\"secondary-window\",null,[[\"title\",\"onXButtonClick\",\"isLoading\"],[\"Save Workspace As\",[27,\"perform\",[[23,[\"cancelTask\"]]],null],[23,[\"isLoading\"]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"dashboard-save-as-body\"],[9],[0,\"\\n    Workspace Name:\"],[7,\"br\"],[9],[10],[0,\"\\n    \"],[7,\"input\"],[11,\"class\",\"workspace-name-input\"],[12,\"disabled\",[21,\"isRunning\"]],[12,\"value\",[27,\"readonly\",[[23,[\"name\"]]],null]],[12,\"oninput\",[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"name\"]]],null]],[[\"value\"],[\"target.value\"]]]],[11,\"type\",\"text\"],[9],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"saveTask\",\"isRunning\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\"],[11,\"class\",\"mt-3 mb-0\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-circle-o-notch fa-spin\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\" Saving...\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"saveTask\",\"last\",\"isError\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\"],[11,\"class\",\"text-danger mt-3 mb-0\"],[9],[0,\"Error: \"],[1,[23,[\"saveTask\",\"last\",\"error\"]],false],[10],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"dashboard-save-as-controls\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn-primary\"],[12,\"onclick\",[27,\"perform\",[[23,[\"saveTask\"]]],null]],[12,\"disabled\",[21,\"isRunning\"]],[9],[0,\"\\n\\n      Save\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"button\"],[11,\"class\",\"btn-secondary\"],[12,\"onclick\",[27,\"perform\",[[23,[\"cancelTask\"]]],null]],[12,\"disabled\",[21,\"isRunning\"]],[9],[0,\"\\n\\n      Cancel\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/dashboard-save-as/template.hbs"
    }
  });

  _exports.default = _default;
});