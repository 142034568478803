define("adept-iq/pods/components/side-drawer/vehicle-activity-log/component", ["exports", "ember-concurrency", "ember-light-table", "adept-iq/pods/components/side-drawer/vehicle-activity-log/config", "adept-iq/utils/convertDistance", "adept-iq/config/environment"], function (_exports, _emberConcurrency, _emberLightTable, _config, _convertDistance, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    columns: _config.columns,
    vehicle: Ember.computed.readOnly('workspace.topOptions.vehicle'),
    route: Ember.computed.readOnly(_environment.default.APP.avlmLite ? 'vehicle.latestRoute' : 'vehicle.latestDsRoute.route'),
    displayName: Ember.computed.readOnly('workspace.topOptions.displayName'),
    title: Ember.computed.alias('displayName'),
    table: null,
    events: null,
    isLoading: Ember.computed.alias('fetchEventsTask.isRunning'),
    startDate: Ember.computed.alias('workspace.startDate'),
    endDate: Ember.computed.alias('workspace.endDate'),
    onFilteredEventsChange: Ember.observer('filteredEvents', function () {
      Ember.run.scheduleOnce('afterRender', this, 'updateTableRows');
    }),

    init() {
      this._super(...arguments);

      const currColumns = this.get('columns');
      const table = new _emberLightTable.default(currColumns);
      this.set('table', table); // initialize key observer

      this.onFilteredEventsChange();
      this.get('fetchEventsTask').perform();
    },

    filteredEvents: Ember.computed('events.[]', function () {
      let events = this.get('events') || []; // sort reverse-chronologically while we're at it

      events = events.sortBy('date');
      events.reverse();
      return events;
    }),
    fetchEventsTask: (0, _emberConcurrency.task)(function* () {
      this.set('events', []);
      const vehicleExecEvents = yield this.get('store').query('vehicle-event', {
        vehicleId: this.get('vehicle.id')
      });
      const events = vehicleExecEvents.reduce((arr, vehicleExecEvent) => {
        const event = this._buildEvent(vehicleExecEvent);

        if (event) {
          arr.push(event);
        }

        return arr;
      }, []);
      this.set('events', events);
    }).drop(),

    updateTableRows() {
      const table = this.get('table');
      const filteredEvents = this.get('filteredEvents');
      table.setRows(filteredEvents);
    },

    // this should be replaced when there is a real API
    _buildEvent(vehicleExecEvent) {
      const date = new Date(vehicleExecEvent.get('content.actualTime'));
      const type = vehicleExecEvent.get('content.type');

      const logEntryType = _config.logEntryTypes.findBy('vehicleExecEventType', type);

      if (!logEntryType) return;
      const {
        activity,
        buildAttrs,
        buildDetails,
        buildUser
      } = logEntryType;
      /*eslint-disable */

      const attrs = buildAttrs(vehicleExecEvent, this.get('store'));
      attrs.odo = attrs.odo ? (0, _convertDistance.convertMetersToMiles)(attrs.odo) : null;
      const details = buildDetails(attrs);
      const user = buildUser(attrs);
      return {
        activity,
        date,
        details,
        user
      };
      /*eslint-enable */
    }

  });

  _exports.default = _default;
});