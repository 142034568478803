define("adept-iq/pods/components/timeline-picker/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DISPATCH_ROLENAME = 'Dispatch';

  var _default = Ember.Component.extend({
    workspace: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    timeSpan: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    classNames: ['timeline-picker'],
    zoomPercent: 50,
    minZoomPercent: 25,
    maxZoomPercent: 100,
    zoomPercentStep: 1,
    proposedStartDate: null,
    proposedEndDate: null,
    isRelative: null,
    isFullAccess: true,
    fieldValidity: null,
    areFieldsValid: Ember.computed('fieldValidity.{startDate,endDate,calendar}', function () {
      // body
      const fv = this.get('fieldValidity');
      /*eslint-disable */

      for (let state in fv) {
        if (fv[state] === false) {
          return false;
        }
      }
      /*eslint-enable */


      return true;
    }),
    isAbsoluteMode: true,
    minusValue: Ember.computed('timeSpan.minusValue', function () {
      return this.get('timeSpan.minusValue');
    }),
    plusValue: Ember.computed('timeSpan.plusValue', function () {
      return this.get('timeSpan.plusValue');
    }),
    canChooseRelative: Ember.computed('proposedStartDate', 'proposedEndDate', function () {
      const start = this.get('proposedStartDate').getTime();
      const end = this.get('proposedEndDate').getTime();
      const now = Date.now();

      if (now <= start || now > end) {
        return false;
      }

      return true;
    }),

    updateTimeSpanService(minusValue, plusValue) {
      this.get('timeSpan').setSpanMillis(minusValue, plusValue);
    },

    stopTimeSpanService() {
      this.get('timeSpan').stopInterval();
    },

    init() {
      this._super(...arguments);

      this.updateDispathcWorkspaceTimeRange();
      const startDate = this.get('workspace.startDate');
      const proposedStartDate = new Date();
      proposedStartDate.setTime(startDate.getTime());
      this.set('proposedStartDate', proposedStartDate);
      const endDate = this.get('workspace.endDate');
      const proposedEndDate = new Date();
      proposedEndDate.setTime(endDate.getTime());
      this.set('proposedEndDate', proposedEndDate);
      this.set('isRelative', this.get('timeSpan.isTimeSpanRunning'));
      this.set('fieldValidity', {});
      const isFullAccess = this.store.peekAll('cs-config-permission').get('firstObject.isFullAccessTimeline');
      this.set('isFullAccess', isFullAccess);
    },

    async reInitializeStore() {
      const store = this.store;
      const workspace = this.get('workspace');
      const topActiveContext = this.get('activeContext.topActiveContext');
      await this.get('notifications').get('monitorForEmergencyTask').cancelAll(); // clear active context traversal

      topActiveContext.reset(); // dump ember store

      await Ember.run(() => store.init()); // dump all widget data

      topActiveContext.trigger('clear');
      await Ember.run.next(async () => {
        await this.get('store').findAll('cs-config-category');
        await this.get('store').findAll('cs-config-item');
        await store.findAll('cs-config-permission').then(configPermissions => {
          return workspace.set('configPermissions', configPermissions.get('firstObject.value'));
        });
        this.updateTimeRange();
        this.get('notifications').get('monitorForEmergencyTask').perform();
      });
    },

    updateTimeRange() {
      const newStartDate = this.get('proposedStartDate');
      const newEndDate = this.get('proposedEndDate');

      if (this.get('isRelative') && this.get('canChooseRelative')) {
        const now = Date.now();
        this.updateTimeSpanService(now - newStartDate.getTime(), newEndDate.getTime() - now);
      } else {
        this.stopTimeSpanService();
        this.set('workspace.startDate', newStartDate);
        this.set('workspace.endDate', newEndDate);
      }

      this.send('close');
      this.get('workspace').saveTempDashboardState();
    },

    updateDispathcWorkspaceTimeRange() {
      const momentDate = (0, _moment.default)(new Date()); //Dispatch workspace is selected set current date

      if (DISPATCH_ROLENAME === this.get('workspace.dashboardModel.role')) {
        this.workspace.setProperties({
          startDate: momentDate.startOf('day').toDate(),
          endDate: momentDate.endOf('day').toDate()
        });
      }
    },

    actions: {
      onZoomPercentChange(zoomPercent) {
        this.set('zoomPercent', parseInt(zoomPercent, 10));
      },

      checkValidity() {
        return this.get('areFieldsValid');
      },

      onCloseButtonClick() {
        this.send('close');
      },

      async onApplyButtonClick() {
        if (this.get('areFieldsValid')) {
          await this.reInitializeStore();
        }
      },

      onChangeAbsoluteCheckBox() {
        this.toggleProperty('isRelative');
      },

      close() {
        const workspace = this.get('workspace');
        workspace.toggleProperty('isEditingTimeline');
      }

    }
  });

  _exports.default = _default;
});