define("adept-iq/mixins/table-row-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    row: null,
    column: {},
    computedValue: Ember.computed('column', 'row', function () {
      const row = this.get('row');
      const column = this.get('column');
      let valuePath = column.get('valuePath');

      if (valuePath && valuePath !== 'record.undefined') {
        return row.get(column.get('valuePath'));
      }

      const modelName = row.get('record.constructor.modelName');
      const configValuePaths = column.get('valuePaths');
      valuePath = configValuePaths.findBy('modelName', modelName).valuePath;
      return row.get(`record.${valuePath}`);
    })
  });

  _exports.default = _default;
});