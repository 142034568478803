define("adept-iq/pods/components/iq-widgets/vehicle-event-form-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Read Only',
      fields: [{
        id: 'routeId',
        type: 'text',
        label: 'Route Id',
        valuePath: 'name',
        editable: false
      }, {
        id: 'vehicle',
        type: 'text',
        label: 'Vehicle',
        valuePath: 'assignedVehicle.id',
        editable: false
      }]
    }, {
      title: 'Editable',
      fields: [{
        id: 'timestamp',
        type: 'datetimeflatpickr',
        label: 'Date Time',
        valuePath: 'timestamp',
        editable: true,
        hidden: false,
        format: 'Y-m-d h:i K',
        defaultWidth: 50
      }, {
        id: 'odometer',
        type: 'text',
        editable: true,
        label: 'Odometer',
        valuePath: 'odometer'
      }]
    }]
  };
  _exports.default = _default;
});