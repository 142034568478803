define("adept-iq/serializers/avlm-vehicle", ["exports", "adept-iq/serializers/-avlm-avlmService"], function (_exports, _avlmAvlmService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _avlmAvlmService.default.extend({
    normalize(modelClass, resourceHash) {
      if (resourceHash.attributes) {
        if (!Ember.isNone(resourceHash.attributes.providerName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.provider = {
            data: {
              type: 'provider',
              id: resourceHash.attributes.providerName
            }
          };
          resourceHash.relationships = relationships;
        }
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});