define("adept-iq/transforms/guidance", ["exports", "ember-data", "pako"], function (_exports, _emberData, _pako) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize(serialized) {
      if (!serialized) return null;
      const arr = atob(serialized).split(',');

      const inflated = _pako.default.inflate(arr);

      const guidanceJSON = String.fromCharCode.apply(null, inflated);

      try {
        return JSON.parse(guidanceJSON);
      } catch (e) {
        // bad json
        return null;
      }
    },

    serialize()
    /* deserialized */
    {
      return null;
    }

  });

  _exports.default = _default;
});