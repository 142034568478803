define("adept-iq/pods/components/dashboard-picker/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    workspace: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    classNames: ['dashboard-picker'],
    dashboards: null,
    selectedSection: null,
    isLoading: Ember.computed.or('loadDashboardsTask.isRunning', 'transitionTask.isRunning'),

    didInsertElement() {
      this._super(...arguments);

      this.get('loadDashboardsTask').perform().then(() => {
        Ember.run.schedule('afterRender', () => {
          this.$('.open-last-saved').focus();
          this.setupDefaultSection();
        });
      });
    },

    setupDefaultSection() {
      const dashboardModel = this.get('workspace.dashboardModel');
      const sections = this.get('sections');
      if (Ember.isEmpty(sections) || Ember.isEmpty(dashboardModel)) return;
      sections.forEach(section => {
        if (Ember.isEmpty(section.dashboards)) return;
        const found = section.dashboards.find(dashboard => dashboard.name === dashboardModel.name);

        if (Ember.isPresent(found)) {
          this.set('selectedSection', section.title);
        }
      });
    },

    loadDashboardsTask: (0, _emberConcurrency.task)(function* () {
      const dashboards = yield this.get('store').query('dashboard', {});
      this.set('dashboards', dashboards);
    }).drop(),
    transitionTask: (0, _emberConcurrency.task)(function* (dashboard) {
      const workspace = this.get('workspace');
      yield workspace.popState('openWorkspace');

      if (dashboard === workspace.get('dashboardModel')) {
        // setting again will trigger reload
        workspace.set('dashboardModel', dashboard);
      } else {
        yield this.get('router').transitionTo('dashboard', dashboard);
      }
    }).drop(),
    roles: Ember.computed('dashboards.[]', function () {
      const dashboards = this.get('dashboards');
      if (Ember.isEmpty(dashboards)) return [];
      return dashboards.mapBy('role').uniq().sort();
    }),
    sections: Ember.computed('dashboards.[]', 'roles.[]', function () {
      const dashboards = this.get('dashboards');
      const roles = this.get('roles');
      if (Ember.isEmpty(dashboards) || Ember.isEmpty(roles)) return [];
      return roles.map(role => {
        return {
          title: role,
          dashboards: dashboards.filterBy('role', role).sortBy('isDefaultWorkspace:asc')
        };
      });
    }),
    selectedDashboards: Ember.computed('sections', 'selectedSection', function () {
      const sections = this.get('sections');
      const selectedSection = this.get('selectedSection');
      if (Ember.isEmpty(sections) || Ember.isEmpty(selectedSection)) return [];
      return sections.filter(section => {
        return section.title === selectedSection;
      });
    }),
    actions: {
      onXButtonClick() {
        this.get('workspace').popState('openWorkspace');
      },

      selectSectionTab(tab) {
        this.set('selectedSection', tab);
      }

    }
  });

  _exports.default = _default;
});