define("adept-iq/services/routeexecevent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // @TODO service seems unnecessary and should be converted into a util file as its only preparing JSON Objects
  var _default = Ember.Service.extend({
    routeStartData() {
      const routeStartData = {
        'data': {
          'type': 'routeExecEvent',
          'attributes': {
            'routeId': '',
            'content': {
              'type': 'pullOut',
              'actualTime': ''
            },
            'source': {
              'type': 'webApp'
            },
            'driver': {
              'id': '',
              'badgeNr': '',
              'name': ''
            },
            'vehicle': {
              'id': '',
              'callsign': '',
              'location': {
                'coord': {
                  'lat': 0.0,
                  'lng': 0.0
                },
                'time': ''
              },
              'odo': 0
            }
          }
        }
      };
      return routeStartData;
    },

    routeEndData() {
      const routeEndData = {
        'data': {
          'type': 'routeExecEvent',
          'attributes': {
            'routeId': '',
            'content': {
              'type': 'pullIn',
              'actualTime': ''
            },
            'source': {
              'type': 'webApp'
            },
            'driver': {
              'id': '',
              'badgeNr': '',
              'name': ''
            },
            'vehicle': {
              'id': '',
              'callsign': '',
              'location': {
                'coord': {
                  'lat': 0.0,
                  'lng': 0.0
                },
                'time': ''
              },
              'odo': 0
            }
          }
        }
      };
      return routeEndData;
    },

    stopArriveData() {
      const stopArriveData = {
        'data': {
          'type': 'routeExecEvent',
          'attributes': {
            'trackingId': '1',
            'routeId': '',
            'routeVersion': '1',
            'content': {
              'type': 'arrive',
              'stopIndex': 0,
              'stopId': '',
              'actualTime': '',
              'passengerEvents': [{
                'passengerId': '',
                'passengerType': 'client',
                'type': 'pickup',
                'tripId': ''
              }]
            },
            'driver': {
              'id': '',
              'badgeNr': '',
              'name': ''
            },
            'vehicle': {
              'id': '',
              'callsign': '',
              'location': {
                'coord': {
                  'lat': 0.0,
                  'lng': 0.0
                },
                'time': ''
              },
              'odo': 0
            },
            'source': {
              'type': 'webApp'
            }
          }
        }
      };
      return stopArriveData;
    },

    stopDepartData() {
      const stopDepartData = {
        'data': {
          'type': 'routeExecEvent',
          'attributes': {
            'trackingId': '',
            'routeId': '',
            'content': {
              'type': 'depart',
              'stopIndex': 0,
              'stopId': '',
              'actualTime': '',
              'source': {
                'type': 'webApp'
              },
              'passengerEvents': [{
                'passengerId': '',
                'passengerType': 'client',
                'type': 'pickup',
                'tripId': ''
              }]
            },
            'source': {
              'type': 'webApp'
            },
            'driver': {
              'id': '',
              'badgeNr': '',
              'name': ''
            },
            'vehicle': {
              'id': '',
              'callsign': '',
              'location': {
                'coord': {
                  'lat': 0.0,
                  'lng': 0.0
                },
                'time': ''
              },
              'odo': 0.0
            }
          }
        }
      };
      return stopDepartData;
    },

    stopAcceptData() {
      return {
        'data': {
          'type': 'routeExecEvent',
          'attributes': {
            'trackingId': '1',
            'routeId': '',
            'routeVersion': '1',
            'content': {
              'type': 'accept',
              'stopIndex': 0,
              'stopId': '',
              'actualTime': '',
              'passengerEvents': [{
                'passengerId': '',
                'passengerType': '',
                'type': 'noShow',
                'details': {
                  'message': ''
                }
              }]
            },
            'driver': {
              'id': '',
              'badgeNr': '',
              'name': ''
            },
            'vehicle': {
              'id': '',
              'callsign': '',
              'location': {
                'coord': {
                  'lat': 0.0,
                  'lng': 0.0
                },
                'time': ''
              },
              'odo': 0
            },
            'source': {
              'type': 'webApp'
            }
          }
        }
      };
    },

    onBreakData() {
      const onBreakData = {
        'data': {
          'type': 'routeExecEvent',
          'attributes': {
            'trackingId': '1',
            'routeId': '2',
            'source': {
              'type': 'webApp'
            },
            'content': {
              'type': 'onBreak',
              'actualTime': '2018-07-13T16:52:08.777Z',
              'breakId': '13'
            },
            'driver': {
              'id': '19031',
              'badgeNr': '19031'
            },
            'vehicle': {
              'id': '214',
              'callsign': '214',
              'location': {
                'coord': {
                  'lat': 46.05991,
                  'lng': -118.36189
                },
                'time': '2018-07-13T15:52:08.777Z'
              },
              'mov': {
                'speed': 25,
                'heading': 15
              },
              'odo': 154783.6
            }
          }
        }
      };
      return onBreakData;
    },

    offBreakData() {
      const offBreakData = {
        'data': {
          'type': 'routeExecEvent',
          'attributes': {
            'trackingId': '1',
            'routeId': '2',
            'source': {
              'type': 'webApp'
            },
            'content': {
              'type': 'offBreak',
              'actualTime': '2018-07-13T16:52:08.777Z',
              'breakId': '13'
            },
            'driver': {
              'id': '19031',
              'badgeNr': '19031',
              'name': 'DRVR'
            },
            'vehicle': {
              'id': '214',
              'callsign': '214',
              'location': {
                'coord': {
                  'lat': 46.05991,
                  'lng': -118.36189
                },
                'time': '2018-07-13T15:52:08.777Z'
              },
              'mov': {
                'speed': 25,
                'heading': 15
              },
              'odo': 154783.6
            }
          }
        }
      };
      return offBreakData;
    },

    breakAcceptData() {
      const breakAcceptData = {
        'data': {
          'type': 'routeExecEvent',
          'attributes': {
            'trackingId': '1',
            'routeId': '2',
            'source': {
              'type': 'webApp'
            },
            'content': {
              'type': 'accept',
              'actualTime': '2018-07-13T16:52:08.777Z',
              'breakId': '13'
            },
            'driver': {
              'id': '19031',
              'badgeNr': '19031'
            },
            'vehicle': {
              'id': '214',
              'callsign': '214',
              'location': {
                'coord': {
                  'lat': 46.05991,
                  'lng': -118.36189
                },
                'time': '2018-07-13T15:52:08.777Z'
              },
              'mov': {
                'speed': 25,
                'heading': 15
              },
              'odo': 154783.6
            }
          }
        }
      };
      return breakAcceptData;
    },

    noShowData() {
      const noShowData = {
        'data': {
          'type': 'routeExecEvent',
          'attributes': {
            'trackingId': '',
            'routeId': '',
            'content': {
              'type': 'depart',
              'stopIndex': 1,
              'stopId': '',
              'actualTime': '',
              'source': {
                'type': 'webApp'
              },
              'passengerEvents': [{
                'passengerId': '',
                'passengerType': '',
                'type': 'noShow',
                'details': {
                  'code': '',
                  'message': ''
                },
                'tripId': ''
              }]
            },
            'source': {
              'type': 'webApp'
            },
            'driver': {
              'id': '',
              'badgeNr': '',
              'name': ''
            },
            'vehicle': {
              'id': '',
              'callsign': '',
              'location': {
                'coord': {
                  'lat': 0.0,
                  'lng': 0.0
                },
                'time': ''
              },
              'odo': 0.0
            }
          }
        }
      };
      return noShowData;
    },

    tripPaymentData() {
      const tripPaymentData = {
        'data': {
          'type': 'tripPayment',
          'attributes': {
            'status': 'completed',
            'trackingId': '',
            'externalBookingId': '155',
            'tripId': '1',
            'passengerId': '1',
            'fare': 2,
            'components': [{
              'name': 'cash',
              'amount': 2
            }],
            'total': 1,
            'currencyCode': 'CAD'
          }
        }
      };
      return tripPaymentData;
    },

    cancelData() {
      const cancelData = {
        'data': {
          'type': 'cancellation',
          'attributes': {
            'notes': 'client is on vacation'
          },
          'relationships': {
            'cancelTypeName': {
              'data': {
                'id': 'CX_L',
                'type': 'cancelTypeName'
              }
            }
          }
        }
      };
      return cancelData;
    },

    prepareRouteStartData(routeStartData, record) {
      routeStartData.data.attributes.routeId = record.get('id');
      routeStartData.data.attributes.content.actualTime = record.get('actualStartTimestamp');
      const assignedDriver = record.get('dispatchRoute.assignedDriver');
      const assignedVehicle = record.get('dispatchRoute.assignedVehicle');
      routeStartData.data.attributes.driver.id = '' + assignedDriver.get('id');
      routeStartData.data.attributes.driver.badgeNr = '' + assignedDriver.get('driverId');
      routeStartData.data.attributes.driver.name = assignedDriver.get('fullName');
      routeStartData.data.attributes.vehicle.id = assignedVehicle.get('id');
      routeStartData.data.attributes.vehicle.callsign = assignedVehicle.get('name');

      if (Ember.isEmpty(record.get('odometer'))) {
        delete routeStartData.data.attributes.vehicle.odo;
      } else {
        routeStartData.data.attributes.vehicle.odo = parseInt(record.get('odometer'), 10);
      }

      routeStartData.data.attributes.vehicle.location.time = record.get('actualStartTimestamp');

      if (Ember.isNone(record.get('routePlaces.firstObject.place.lat')) || Ember.isNone(record.get('routePlaces.firstObject.place.lng'))) {
        delete routeStartData.data.attributes.vehicle.location;
      } else {
        routeStartData.data.attributes.vehicle.location.coord.lat = record.get('routePlaces.firstObject.place.lat');
        routeStartData.data.attributes.vehicle.location.coord.lng = record.get('routePlaces.firstObject.place.lng');
      }

      return routeStartData;
    },

    prepareRouteEndData(routeEndData, record) {
      routeEndData.data.attributes.routeId = record.get('id');
      routeEndData.data.attributes.content.actualTime = record.get('actualEndTimestamp');
      const assignedDriver = record.get('dispatchRoute.assignedDriver');
      const assignedVehicle = record.get('dispatchRoute.assignedVehicle');
      routeEndData.data.attributes.driver.id = '' + assignedDriver.get('id');
      routeEndData.data.attributes.driver.badgeNr = '' + assignedDriver.get('driverId');
      routeEndData.data.attributes.driver.name = assignedDriver.get('fullName');
      routeEndData.data.attributes.vehicle.id = assignedVehicle.get('id');
      routeEndData.data.attributes.vehicle.callsign = assignedVehicle.get('name');

      if (Ember.isEmpty(record.get('odometer'))) {
        delete routeEndData.data.attributes.vehicle.odo;
      } else {
        routeEndData.data.attributes.vehicle.odo = parseInt(record.get('odometer'), 10);
      }

      routeEndData.data.attributes.vehicle.location.time = record.get('actualEndTimestamp');

      if (Ember.isNone(record.get('routePlaces.firstObject.place.lat')) || Ember.isNone(record.get('routePlaces.firstObject.place.lng'))) {
        delete routeEndData.data.attributes.vehicle.location;
      } else {
        routeEndData.data.attributes.vehicle.location.coord.lat = record.get('routePlaces.firstObject.place.lat');
        routeEndData.data.attributes.vehicle.location.coord.lng = record.get('routePlaces.firstObject.place.lng');
      }

      return routeEndData;
    },

    prepareStopArriveData(stopArriveData, record) {
      stopArriveData.data.attributes.trackingId = '1';
      stopArriveData.data.attributes.routeId = record.get('dispatchRoute.route.id');
      stopArriveData.data.attributes.content.actualTime = record.get('arriveTimestamp');
      stopArriveData.data.attributes.content.eta = record.get('arriveTimestamp');
      stopArriveData.data.attributes.content.stopId = record.get('id');
      stopArriveData.data.attributes.content.stopIndex = record.get('clusterOrdinal');
      const assignedDriver = record.get('dispatchRoute.assignedDriver');
      const assignedVehicle = record.get('dispatchRoute.assignedVehicle');
      stopArriveData.data.attributes.content.passengerEvents[0].passengerId = record.get('trip.rider.id');
      stopArriveData.data.attributes.content.passengerEvents[0].passengerType = 'client';
      stopArriveData.data.attributes.content.passengerEvents[0].tripId = record.get('trip.id');

      if (assignedDriver) {
        stopArriveData.data.attributes.driver.id = '' + assignedDriver.get('id');
        stopArriveData.data.attributes.driver.badgeNr = '' + assignedDriver.get('driverId');
        stopArriveData.data.attributes.driver.name = assignedDriver.get('fullName');
      }

      if (record.get('isDrop')) {
        stopArriveData.data.attributes.content.passengerEvents[0].type = 'dropoff';
      }

      if (assignedVehicle) {
        stopArriveData.data.attributes.vehicle.id = assignedVehicle.get('id');
        stopArriveData.data.attributes.vehicle.callsign = assignedVehicle.get('name');
        stopArriveData.data.attributes.vehicle.location.time = record.get('arriveTimestamp');
      }

      if (Ember.isEmpty(record.get('odometer'))) {
        delete stopArriveData.data.attributes.vehicle.odo;
      } else {
        stopArriveData.data.attributes.vehicle.odo = parseInt(record.get('odometer'), 10);
      }

      if (Ember.isNone(record.get('place.lat')) || Ember.isNone(record.get('place.lng'))) {
        delete stopArriveData.data.attributes.vehicle.location;
      } else {
        stopArriveData.data.attributes.vehicle.location.coord.lat = record.get('place.lat');
        stopArriveData.data.attributes.vehicle.location.coord.lng = record.get('place.lng');
      }

      return stopArriveData;
    },

    prepareStopDepartData(stopDepartData, record) {
      stopDepartData.data.attributes.trackingId = '1';
      stopDepartData.data.attributes.routeId = record.get('dispatchRoute.route.id');
      stopDepartData.data.attributes.content.actualTime = record.get('departTimestamp');
      stopDepartData.data.attributes.content.eta = record.get('departTimestamp');
      stopDepartData.data.attributes.content.stopId = record.get('id');
      stopDepartData.data.attributes.content.stopIndex = record.get('clusterOrdinal');
      const assignedDriver = record.get('dispatchRoute.assignedDriver');
      const assignedVehicle = record.get('dispatchRoute.assignedVehicle');
      const travelNeeds = record.get('travelNeeds'); // "companion" travel needs have passenger-type "companion" and travel-need-type "ambulatory"
      // "PCA" travel needs have passenger-type "pca" and travel-need-type "ambulatory"
      // "service animal" travel needs have passenger-type "client" and travel-need-type "serviceAnimal"

      stopDepartData.data.attributes.content.passengerEvents[0].passengerId = record.get('trip.rider.id');
      stopDepartData.data.attributes.content.passengerEvents[0].passengerType = 'client';
      stopDepartData.data.attributes.content.passengerEvents[0].tripId = record.get('trip.id');

      if (record.get('serviceAnimalCount')) {
        travelNeeds.pushObject({
          type: 'serviceAnimal',
          cnt: 1
        });
      }

      stopDepartData.data.attributes.content.passengerEvents[0].usedAttributes = travelNeeds;

      if (record.get('pcaCount')) {
        const type = record.get('isDrop') ? 'dropoff' : 'pickup';
        stopDepartData.data.attributes.content.passengerEvents.pushObject({
          passengerId: record.get('trip.rider.id'),
          passengerType: 'PCA',
          type,
          tripId: record.get('trip.id'),
          usedAttributes: [{
            type: 'ambulatory',
            cnt: 1
          }]
        });
      }

      if (record.get('companionCount')) {
        const type = record.get('isDrop') ? 'dropoff' : 'pickup';
        stopDepartData.data.attributes.content.passengerEvents.pushObject({
          passengerId: record.get('trip.rider.id'),
          passengerType: 'companion',
          type,
          tripId: record.get('trip.id'),
          usedAttributes: [{
            type: 'ambulatory',
            cnt: record.get('companionCount')
          }]
        });
      }

      if (record.get('isDrop')) {
        stopDepartData.data.attributes.content.passengerEvents[0].type = 'dropoff';
      }

      if (assignedDriver) {
        stopDepartData.data.attributes.driver.id = '' + assignedDriver.get('id');
        stopDepartData.data.attributes.driver.badgeNr = '' + assignedDriver.get('driverId');
        stopDepartData.data.attributes.driver.name = assignedDriver.get('fullName');
      }

      if (assignedVehicle) {
        stopDepartData.data.attributes.vehicle.id = assignedVehicle.get('id');
        stopDepartData.data.attributes.vehicle.callsign = assignedVehicle.get('name');
        stopDepartData.data.attributes.vehicle.location.time = record.get('departTimestamp');
      }

      if (Ember.isEmpty(record.get('odometer'))) {
        delete stopDepartData.data.attributes.vehicle.odo;
      } else {
        stopDepartData.data.attributes.vehicle.odo = parseInt(record.get('odometer'), 10);
      }

      if (Ember.isNone(record.get('place.lat')) || Ember.isNone(record.get('place.lng'))) {
        delete stopDepartData.data.attributes.vehicle.location;
      } else {
        stopDepartData.data.attributes.vehicle.location.coord.lat = record.get('place.lat');
        stopDepartData.data.attributes.vehicle.location.coord.lng = record.get('place.lng');
      }

      return stopDepartData;
    },

    prepareStopAcceptData(acceptData, record, noShowTime, noShowNotes) {
      acceptData.data.attributes.trackingId = '1';
      acceptData.data.attributes.routeId = record.get('dispatchRoute.route.id');

      if (noShowTime) {
        acceptData.data.attributes.content.actualTime = noShowTime || new Date();
      } else {
        acceptData.data.attributes.content.actualTime = record.get('departTimestamp') || new Date();
      }

      acceptData.data.attributes.content.eta = record.get('departTimestamp') || new Date();
      acceptData.data.attributes.content.stopId = record.get('id');
      acceptData.data.attributes.content.stopIndex = record.get('clusterOrdinal');
      const assignedDriver = record.get('dispatchRoute.assignedDriver');
      const assignedVehicle = record.get('dispatchRoute.assignedVehicle');

      if (noShowNotes) {
        acceptData.data.attributes.content.passengerEvents[0].passengerId = record.get('trip.rider.id');
        acceptData.data.attributes.content.passengerEvents[0].passengerType = 'client';
        acceptData.data.attributes.content.passengerEvents[0].details.message = noShowNotes;
      } else {
        delete acceptData.data.attributes.content.passengerEvents;
      }

      if (assignedDriver) {
        acceptData.data.attributes.driver.id = '' + assignedDriver.get('id');
        acceptData.data.attributes.driver.badgeNr = '' + assignedDriver.get('driverId');
        acceptData.data.attributes.driver.name = assignedDriver.get('fullName');
      }

      if (assignedVehicle) {
        acceptData.data.attributes.vehicle.id = assignedVehicle.get('id');
        acceptData.data.attributes.vehicle.callsign = assignedVehicle.get('name');
        acceptData.data.attributes.vehicle.location.time = record.get('departTimestamp') || new Date();
      }

      if (Ember.isEmpty(record.get('odometer'))) {
        delete acceptData.data.attributes.vehicle.odo;
      } else {
        acceptData.data.attributes.vehicle.odo = parseInt(record.get('odometer'), 10);
      }

      return acceptData;
    },

    prepareStopNoShowData(noShowData, record) {
      const date = new Date();
      noShowData.data.attributes.trackingId = '1';
      noShowData.data.attributes.routeId = record.get('dispatchRoute.route.id');
      noShowData.data.attributes.content.actualTime = record.get('timestamp') || date;
      noShowData.data.attributes.content.stopId = record.get('id');
      const assignedDriver = record.get('route.dispatchRoute.assignedDriver');
      const assignedVehicle = record.get('route.dispatchRoute.assignedVehicle');
      noShowData.data.attributes.content.passengerEvents[0].passengerId = record.get('trip.rider.id');
      noShowData.data.attributes.content.passengerEvents[0].passengerType = 'client';

      if (record.get('isDrop')) {
        noShowData.data.attributes.content.passengerEvents[0].type = 'dropoff';
      }

      noShowData.data.attributes.content.passengerEvents[0].tripId = record.get('trip.id');
      const noShow = record.get('noShow');
      noShowData.data.attributes.content.passengerEvents[0].details.code = noShow.get('noShowReasonCode.externalCode');

      if (!Ember.isEmpty(noShow.get('notes'))) {
        noShowData.data.attributes.content.passengerEvents[0].details.message = noShow.get('notes');
      } else {
        // avlm-service requires the string to have at least one character this should be optional
        delete noShowData.data.attributes.content.passengerEvents[0].details.message;
      }

      noShowData.data.attributes.driver.id = '' + assignedDriver.get('id');
      noShowData.data.attributes.driver.badgeNr = '' + assignedDriver.get('driverId');
      noShowData.data.attributes.driver.name = assignedDriver.get('fullName');
      noShowData.data.attributes.vehicle.id = assignedVehicle.get('id');
      noShowData.data.attributes.vehicle.callsign = assignedVehicle.get('name');

      if (Ember.isEmpty(record.get('odometer'))) {
        delete noShowData.data.attributes.vehicle.odo;
      } else {
        noShowData.data.attributes.vehicle.odo = parseInt(record.get('odometer'), 10);
      }

      noShowData.data.attributes.vehicle.location.time = record.timestamp || date;

      if (Ember.isNone(record.get('place.location.lat')) || Ember.isNone(record.get('place.location.lng'))) {
        delete noShowData.data.attributes.vehicle.location;
      } else {
        noShowData.data.attributes.vehicle.location.coord.lat = record.get('place.location.lat');
        noShowData.data.attributes.vehicle.location.coord.lng = record.get('place.location.lng');
      }

      return noShowData;
    },

    prepareTripNoShowRequestData(noShowData, record, noShowTypeDecision) {
      const pickStopPoint = record.get('pickStopPoint');
      const date = new Date();
      delete noShowData.data.attributes.trackingId;
      noShowData.data.attributes.routeId = pickStopPoint.get('dispatchRoute.route.id');
      noShowData.data.attributes.content.actualTime = date;
      noShowData.data.attributes.content.stopId = pickStopPoint.get('id');
      noShowData.data.attributes.content.type = noShowTypeDecision;
      const assignedDriver = pickStopPoint.get('dispatchRoute.assignedDriver');
      const assignedVehicle = pickStopPoint.get('dispatchRoute.assignedVehicle');
      noShowData.data.attributes.content.passengerEvents[0].passengerId = record.get('rider.id');
      noShowData.data.attributes.content.passengerEvents[0].passengerType = 'client';
      noShowData.data.attributes.content.passengerEvents[0].tripId = record.get('id');
      delete noShowData.data.attributes.content.source;
      delete noShowData.data.attributes.content.passengerEvents[0].details.code;
      delete noShowData.data.attributes.content.stopIndex;
      noShowData.data.attributes.content.passengerEvents[0].type = 'pickup';

      if (!Ember.isEmpty(record.get('noshowDetails'))) {
        noShowData.data.attributes.content.passengerEvents[0].details.message = record.get('noshowDetails');
      } else {
        // avlm-service requires the string to have at least one character this should be optional
        noShowData.data.attributes.content.passengerEvents[0].details.message = 'None';
      } //driver data


      noShowData.data.attributes.driver.id = '' + assignedDriver.get('id');
      noShowData.data.attributes.driver.badgeNr = '' + assignedDriver.get('driverId');
      noShowData.data.attributes.driver.name = assignedDriver.get('fullName'); ///vehicle data

      noShowData.data.attributes.vehicle.id = assignedVehicle.get('id');
      noShowData.data.attributes.vehicle.callsign = assignedVehicle.get('name');
      noShowData.data.attributes.vehicle.provider = {
        name: assignedVehicle.get('provider.name')
      };

      if (Ember.isEmpty(record.get('odometer'))) {
        delete noShowData.data.attributes.vehicle.odo;
      } else {
        noShowData.data.attributes.vehicle.odo = parseInt(record.get('odometer'), 10);
      }

      noShowData.data.attributes.vehicle.location.time = date;

      if (Ember.isNone(pickStopPoint.get('place.location.lat')) || Ember.isNone(pickStopPoint.get('place.location.lng'))) {
        delete noShowData.data.attributes.vehicle.location;
      } else {
        noShowData.data.attributes.vehicle.location.coord.lat = pickStopPoint.get('place.location.lat');
        noShowData.data.attributes.vehicle.location.coord.lng = pickStopPoint.get('place.location.lng');
      }

      return noShowData;
    },

    prepareTripNoShowData(noShowData, record) {
      noShowData.data.attributes.trackingId = '1';
      noShowData.data.attributes.routeId = record.get('pickStopPoint.dispatchRoute.route.id');
      noShowData.data.attributes.content.actualTime = record.timestamp;
      noShowData.data.attributes.content.stopId = record.get('pickStopPoint.id');
      const assignedDriver = record.get('pickStopPoint.dispatchRoute.assignedDriver');
      const assignedVehicle = record.get('pickStopPoint.dispatchRoute.assignedVehicle');
      noShowData.data.attributes.content.passengerEvents[0].passengerId = record.get('rider.id');
      noShowData.data.attributes.content.passengerEvents[0].passengerType = 'client';

      if (record.get('type') === 'drop') {
        noShowData.data.attributes.content.passengerEvents[0].type = 'dropoff';
      }

      noShowData.data.attributes.content.passengerEvents[0].tripId = record.get('id');
      const noShowReasonCode = record.get('noShowReason');
      noShowData.data.attributes.content.passengerEvents[0].details.code = noShowReasonCode.get('externalCode');

      if (!Ember.isEmpty(record.get('noShowNotes'))) {
        noShowData.data.attributes.content.passengerEvents[0].details.message = record.get('noShowNotes');
      } else {
        // avlm-service requires the string to have at least one character this should be optional
        noShowData.data.attributes.content.passengerEvents[0].details.message = 'None';
      } //driver data


      noShowData.data.attributes.driver.id = '' + assignedDriver.get('id');
      noShowData.data.attributes.driver.badgeNr = '' + assignedDriver.get('driverId');
      noShowData.data.attributes.driver.name = assignedDriver.get('fullName'); ///vehicle data

      noShowData.data.attributes.vehicle.id = assignedVehicle.get('id');
      noShowData.data.attributes.vehicle.callsign = assignedVehicle.get('name');

      if (Ember.isEmpty(record.get('odometer'))) {
        delete noShowData.data.attributes.vehicle.odo;
      } else {
        noShowData.data.attributes.vehicle.odo = parseInt(record.get('odometer'), 10);
      }

      noShowData.data.attributes.vehicle.location.time = record.timestamp;

      if (Ember.isNone(record.get('pickStopPoint.place.location.lat')) || Ember.isNone(record.get('pickStopPoint.place.location.lng'))) {
        delete noShowData.data.attributes.vehicle.location;
      } else {
        noShowData.data.attributes.vehicle.location.coord.lat = record.get('pickStopPoint.place.location.lat');
        noShowData.data.attributes.vehicle.location.coord.lng = record.get('pickStopPoint.place.location.lng');
      }

      return noShowData;
    },

    prepareTripPaymentData(tripPaymentData, record) {
      tripPaymentData.data.attributes.trackingId = '1';
      tripPaymentData.data.attributes.externalBookingId = record.get('externalId');
      tripPaymentData.data.attributes.tripId = record.get('id');
      tripPaymentData.data.attributes.passengerId = record.get('rider.id');
      tripPaymentData.data.attributes.fare = record.get('fare');
      tripPaymentData.data.attributes.components[0].name = record.get('fareName');
      tripPaymentData.data.attributes.components[0].amount = record.get('fare');
      return tripPaymentData;
    },

    prepareTripCancelData(cancelData, record) {
      cancelData.data.attributes.notes = record.cancelNotes;
      cancelData.data.relationships.cancelTypeName.data.id = record.cancelType.id;
      return cancelData;
    },

    prepareStartBreakData(onBreakData, record) {
      onBreakData.data.attributes.trackingId = '1';
      onBreakData.data.attributes.routeId = record.get('dispatchRoute.route.id');
      onBreakData.data.attributes.content.actualTime = record.get('routeBreak.arriveTimestamp');
      onBreakData.data.attributes.content.breakId = record.get('routeBreak.id');
      const assignedDriver = record.get('dispatchRoute.assignedDriver');
      const assignedVehicle = record.get('dispatchRoute.assignedVehicle');
      onBreakData.data.attributes.driver.id = '' + assignedDriver.get('id');
      onBreakData.data.attributes.driver.badgeNr = '' + assignedDriver.get('driverId');
      onBreakData.data.attributes.vehicle.id = assignedVehicle.get('id');
      onBreakData.data.attributes.vehicle.callsign = assignedVehicle.get('name');

      if (Ember.isEmpty(record.get('odometer'))) {
        delete onBreakData.data.attributes.vehicle.odo;
      } else {
        onBreakData.data.attributes.vehicle.odo = parseInt(record.get('odometer'), 10);
      }

      onBreakData.data.attributes.vehicle.location.time = record.get('routeBreak.arriveTimestamp');

      if (Ember.isNone(record.get('place.location.lat')) || Ember.isNone(record.get('place.location.lng'))) {
        delete onBreakData.data.attributes.vehicle.location;
      } else {
        onBreakData.data.attributes.vehicle.location.coord.lat = record.get('place.location.lat');
        onBreakData.data.attributes.vehicle.location.coord.lng = record.get('place.location.lng');
      }

      return onBreakData;
    },

    prepareEndBreakData(offBreakData, record) {
      offBreakData.data.attributes.trackingId = '1';
      offBreakData.data.attributes.routeId = record.get('dispatchRoute.route.id');
      offBreakData.data.attributes.content.actualTime = record.get('routeBreak.departTimestamp');
      offBreakData.data.attributes.content.breakId = record.get('routeBreak.id');
      const assignedDriver = record.get('dispatchRoute.assignedDriver');
      const assignedVehicle = record.get('dispatchRoute.assignedVehicle');
      offBreakData.data.attributes.driver.id = '' + assignedDriver.get('id');
      offBreakData.data.attributes.driver.badgeNr = '' + assignedDriver.get('driverId');
      offBreakData.data.attributes.vehicle.id = assignedVehicle.get('id');
      offBreakData.data.attributes.vehicle.callsign = assignedVehicle.get('name');

      if (Ember.isEmpty(record.get('odometer'))) {
        delete offBreakData.data.attributes.vehicle.odo;
      } else {
        offBreakData.data.attributes.vehicle.odo = parseInt(record.get('odometer'), 10);
      }

      offBreakData.data.attributes.vehicle.location.time = record.get('routeBreak.departTimestamp');

      if (Ember.isNone(record.get('place.location.lat')) || Ember.isNone(record.get('place.location.lng'))) {
        delete offBreakData.data.attributes.vehicle.location;
      } else {
        offBreakData.data.attributes.vehicle.location.coord.lat = record.get('place.location.lat');
        offBreakData.data.attributes.vehicle.location.coord.lng = record.get('place.location.lng');
      }

      return offBreakData;
    },

    prepareBreakAcceptData(onBreakData, record) {
      const arriveTimestamp = record.get('routeBreak.arriveTimestamp') ? record.get('routeBreak.arriveTimestamp') : new Date();
      onBreakData.data.attributes.trackingId = '1';
      onBreakData.data.attributes.routeId = record.get('dispatchRoute.route.id');
      onBreakData.data.attributes.content.actualTime = arriveTimestamp;
      onBreakData.data.attributes.content.breakId = record.get('routeBreak.id');
      const assignedDriver = record.get('dispatchRoute.assignedDriver');
      const assignedVehicle = record.get('dispatchRoute.assignedVehicle');
      onBreakData.data.attributes.driver.id = '' + assignedDriver.get('id');
      onBreakData.data.attributes.driver.badgeNr = '' + assignedDriver.get('driverId');
      onBreakData.data.attributes.vehicle.id = assignedVehicle.get('id');
      onBreakData.data.attributes.vehicle.callsign = assignedVehicle.get('name');

      if (Ember.isEmpty(record.get('odometer'))) {
        delete onBreakData.data.attributes.vehicle.odo;
      } else {
        onBreakData.data.attributes.vehicle.odo = parseInt(record.get('odometer'), 10);
      }

      onBreakData.data.attributes.vehicle.location.time = arriveTimestamp;

      if (Ember.isNone(record.get('place.location.lat')) || Ember.isNone(record.get('place.location.lng'))) {
        delete onBreakData.data.attributes.vehicle.location;
      } else {
        onBreakData.data.attributes.vehicle.location.coord.lat = record.get('place.location.lat');
        onBreakData.data.attributes.vehicle.location.coord.lng = record.get('place.location.lng');
      }

      return onBreakData;
    }

  });

  _exports.default = _default;
});