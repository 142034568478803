define("adept-iq/pods/components/iq-widgets/roles-form-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Read Only',
      fields: [{
        id: 'role',
        type: 'input',
        label: 'Role Name',
        valuePath: 'displayName',
        editable: false
      }]
    }, {
      title: 'Role',
      fields: [{
        id: 'role',
        type: 'text',
        label: 'Role Name',
        valuePath: 'displayName'
      }]
    }]
  };
  _exports.default = _default;
});