define("adept-iq/pods/components/tile-dashboard/component", ["exports", "adept-iq/config/widget-types", "resize-observer-polyfill"], function (_exports, _widgetTypes, _resizeObserverPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CLIP_COLUMNS_IN_NORMAL_MODE = false;
  const RESIZE_DEBOUNCE_TIME = 250;

  var _default = Ember.Component.extend({
    workspace: Ember.inject.service(),
    classNames: ['tile-dashboard', 'data-test-tile-dashboard'],
    dashboard: null,
    widgetTypesHash: _widgetTypes.widgetTypesHash,
    isEditing: Ember.computed.alias('workspace.isEditing'),
    isEditingPlayback: Ember.computed.alias('workspace.isEditingPlayback'),
    isDragging: Ember.computed.alias('workspace.isDragging'),
    isResizing: Ember.computed.alias('workspace.isResizing'),
    tileSize: Ember.computed.alias('workspace.tileSize'),
    tileSpacing: Ember.computed.alias('workspace.tileSpacing'),
    contentWidth: Ember.computed.alias('workspace.contentWidth'),
    contentHeight: Ember.computed.alias('workspace.contentHeight'),
    modeClass: Ember.computed('isEditing', function () {
      const mode = this.get('isEditing') ? 'edit' : 'normal';
      return `dashboard-mode-${mode}`;
    }),
    tileSizeClass: Ember.computed('tileSize', function () {
      return 'tile-size-' + this.get('tileSize');
    }),
    tileSpacingClass: Ember.computed('tileSpacing', function () {
      return 'tile-spacing-' + this.get('tileSpacing');
    }),
    colsClass: Ember.computed('dashboard.widgets.@each.{x,width}', 'isEditing', 'gridWidth', function () {
      let cols = this.get('gridWidth');
      const widgets = this.get('dashboard.widgets') || [];

      if (!this.get('isEditing') && CLIP_COLUMNS_IN_NORMAL_MODE) {
        const maxWidth = widgets.reduce((max, widget) => {
          const x = widget.get('x');
          const width = widget.get('width');
          return Math.max(max, x + width);
        }, 0);
        cols = Math.min(cols, maxWidth);
      }

      return `cols-${cols}`;
    }),
    rowsClass: Ember.computed('widgets.@each.{y,height}', 'gridHeight', function () {
      const gridHeight = this.get('gridHeight');
      const widgets = this.get('dashboard.widgets') || [];
      const rows = widgets.reduce((max, widget) => {
        const y = widget.get('y');
        const height = widget.get('height');
        return Math.max(max, y + height);
      }, gridHeight);
      return `rows-${rows}`;
    }),
    gridWidth: Ember.computed.alias('workspace.gridWidth'),
    gridHeight: Ember.computed.alias('workspace.gridHeight'),

    didInsertElement() {
      this._onResize = () => {
        if (this.get('isDragging') || this.get('isResizing')) return;
        Ember.run.debounce(this, 'updateContentDimensions', RESIZE_DEBOUNCE_TIME);
      };

      this._resizeObserver = new _resizeObserverPolyfill.default(this._onResize);

      this._resizeObserver.observe(this.$()[0]); // add change listener here instead of in the template; ember-gridstack
      // does not handle event listener teardown properly when attrs change


      this._onGridStackChange = (event, items) => {
        Ember.run.scheduleOnce('afterRender', this, 'onGridStackChange', event, items);
      };

      this.$('.grid-stack').on('change', this._onGridStackChange);
      this.updateContentDimensions();
      this.restoreStaticGridSetting();
    },

    willDestroyElement() {
      this.$('.grid-stack').off('change', this._onGridStackChange);

      this._resizeObserver.disconnect();

      this._resizeObserver = null;
      this._onResize = null;
    },

    restoreWidgetLayout() {
      // unfortunately, we have to drop down to the GridStack level here;
      // the changes we make will trigger `onGridstackChange` actions
      const widgets = this.get('dashboard.widgets');
      if (Ember.isEmpty(widgets)) return;
      Ember.run.scheduleOnce('afterRender', () => {
        const grid = this.$('.grid-stack').data('gridstack');
        widgets.forEach(widget => {
          const id = widget.get('id');
          const el = this.$(`.grid-stack-item[id="${id}"]`);
          const currentX = parseInt(el.attr('data-gs-x'), 10);
          const currentY = parseInt(el.attr('data-gs-y'), 10);
          const x = widget.get('x');
          const y = widget.get('y');

          if (currentX !== x || currentY !== y) {
            grid.move(el, x, y);
          }

          const currentWidth = parseInt(el.attr('data-gs-width'), 10);
          const currentHeight = parseInt(el.attr('data-gs-height'), 10);
          const width = widget.get('width');
          const height = widget.get('height');

          if (currentWidth !== width || currentHeight !== height) {
            grid.resize(el, width, height);
          }
        });
      });
    },

    restoreStaticGridSetting() {
      // gridstack resets to non-static when widgets are added or removed;
      // this happens even when dashboard model changes, so always set here
      this.$('.grid-stack').data('gridstack').setStatic(!this.get('isEditing'));
    },

    updateContentDimensions() {
      const content = this.$();
      if (!content) return;
      this.set('contentWidth', content.innerWidth());
      this.set('contentHeight', content.innerHeight());
    },

    onTileGeometryChange: Ember.observer('tileSize', 'tileSpacing', function () {
      Ember.run.scheduleOnce('afterRender', this, 'restoreWidgetLayout');
    }),
    onGridWidthChange: Ember.observer('gridWidth', function () {
      // in editing mode, we do not respond to window size
      if (this.get('isEditing')) return;
      const debounceInterval = this.get('windowResize.debounceInterval');
      Ember.run.debounce(this, 'restoreWidgetLayout', debounceInterval, false);
    }),
    onIsEditingChange: Ember.observer('isEditing', function () {
      if (this.get('isEditing') === false) {
        this.restoreWidgetLayout();
      }
    }),
    onDashboardChange: Ember.observer('dashboard', function () {
      Ember.run.scheduleOnce('afterRender', this, 'restoreWidgetLayout');
    }),

    onGridStackChange(event, items) {
      this.restoreStaticGridSetting();
      if (!items || !this.get('isEditing')) return;
      const widgets = this.get('dashboard.widgets');
      items.forEach(gridItem => {
        const {
          id,
          x,
          y,
          width,
          height
        } = gridItem;
        const widget = widgets.findBy('id', `${id}`);
        if (!widget) return;
        widget.setProperties({
          x,
          y,
          width,
          height
        });
      });
    },

    actions: {
      onXButtonClick(widget) {
        this.get('dashboard').removeWidget(widget);
        widget.destroy();
      }

    }
  });

  _exports.default = _default;
});