define("adept-iq/pods/components/iq-widgets/trips-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/trips-widget/config", "adept-iq/pods/components/iq-widgets/trips-widget/avlmConfig", "adept-iq/config/environment", "adept-iq/utils/find-row-record", "adept-iq/utils/unwrapProxy", "adept-iq/config/api-urls", "ember-concurrency", "moment", "adept-iq/config/mapped-permIds"], function (_exports, _component, _config, _avlmConfig, _environment, _findRowRecord, _unwrapProxy, _apiUrls, _emberConcurrency, _moment, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = ['rider', 'cluster.route', 'stops', 'stops.place', 'rider.travelNeeds', 'rider.travelNeeds.travelNeedType', 'cluster', 'cluster.route,stops.stopEvents', 'fareType', 'serviceWindow'];

  var _default = _component.default.extend({
    dragCoordinator: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['trips-widget', 'data-test-trips-widget'],
    editModal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    booking: Ember.inject.service(),
    notifications: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    config: _config.default,
    defaultIncludes: INCLUDES,
    noShowComponent: 'iq-widgets/noshow-event-stop-form-widget',
    editTripComponent: 'iq-widgets/edit-trip-form-widget',
    cancelTripComponent: 'iq-widgets/cancel-type-form-widget',
    isScheduleDashboard: Ember.computed.readOnly('workspace.isScheduleDashboard'),
    routeexecevent: Ember.inject.service(),

    init() {
      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      this._super(...arguments);
    },

    isBothPickAndDropChanged(record) {
      const pickPlace = record.get('pick.segmentStop.place');
      const dropPlace = record.get('drop.segmentStop.place');

      if (pickPlace.get('address.dirtyType') && dropPlace.get('address.dirtyType')) {
        return true;
      }

      return false;
    },

    updatePlaceInTrip(tripId, data) {
      const session = this.get('session');
      return this.get('ajax').put(_apiUrls.API.schedulingService.host + '/trip/' + tripId + '/place', {
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        'data': data
      });
    },

    async saveTripRecordFunction(records) {
      const store = this.get('store');
      const locationAdapter = store.adapterFor('location');
      const addressAdapter = store.adapterFor('address');
      const placeAdapter = store.adapterFor('place');
      /*eslint-disable */

      const that = this;
      const trip = records[0];
      /*eslint-enable */
      //todo save leg travelneeds
      // const travelNeedsChangeArray = [];
      //
      // function saveDeleteAllTravelNeeds(objects) {
      //   const deleteObjects =  difference(objects.content.initialState,
      //     objects.content.currentState) || [];
      //
      //   // delete objects that have been removed from the array
      //   return Promise.all(deleteObjects.map(deleteObject => {
      //     travelNeedsChangeArray.push(deleteObject._record.get('travelNeedType.name'));
      //     deleteObject._record.deleteRecord();
      //
      //     return deleteObject.save();
      //   }))
      //     .then(() => {
      //       return Promise.all(objects.map(object => {
      //         if (object.dirtyType) {
      //           travelNeedsChangeArray.push(object.get('travelNeedType.name'));
      //         }
      //         return object.save();
      //       }));
      //     });
      // }
      //
      // function saveNewTravelNeedObjects(rider, oldObjects, newObjects) {
      //   return Promise.all(newObjects.map(newObject => {
      //     if (newObject.get('count') !== null && newObject.get('count') > 0) {
      //       newObject.set('rider', rider);
      //       newObject.save();
      //       travelNeedsChangeArray.push(newObject.get('travelNeedType.name'));
      //       return newObject;
      //     }
      //   }));
      // }

      async function savePlaceObject(place) {
        const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
        const location = (0, _unwrapProxy.unwrapProxy)(place.get('location')); // TODO: We need to deal with how zone name is populated

        if (Ember.isNone(address.get('zoneName.id'))) {
          address.set('zoneName', store.peekAll('zone-type').get('firstObject'));
        }

        const locationResponse = await locationAdapter.postLocation(location);
        const addressResponse = await addressAdapter.postAddress(address);
        const placeResponse = await placeAdapter.postPlace(place, addressResponse.data.id, locationResponse.data.id, 'passenger');
        const createdLocation = await store.findRecord('location', locationResponse.data.id);
        const createdAddress = await store.findRecord('address', addressResponse.data.id);
        const createdPlace = await store.findRecord('place', placeResponse.data.id);
        createdPlace.set('location', createdLocation);
        createdPlace.set('address', createdAddress);
        return createdPlace;
      }

      async function createNewPlace(record) {
        const pickSegmentStop = (0, _unwrapProxy.unwrapProxy)(record.get('pick.segmentStop'));
        const dropSegmentStop = (0, _unwrapProxy.unwrapProxy)(record.get('drop.segmentStop'));
        const pickPlace = (0, _unwrapProxy.unwrapProxy)(pickSegmentStop.get('place'));
        const dropPlace = (0, _unwrapProxy.unwrapProxy)(dropSegmentStop.get('place'));
        const tripId = Number(record.id); //if both pick and drop address are not changed no need to update

        if (!that.isBothPickAndDropChanged(record)) {
          return Promise.resolve();
        } // create new pick and drop place


        const newPickPlace = await savePlaceObject(pickPlace);
        const newDropPlace = await savePlaceObject(dropPlace); //set created  place in pick and drop

        pickSegmentStop.set('place', newPickPlace);
        dropSegmentStop.set('place', newDropPlace); //update segment-stop with new place

        await pickSegmentStop.save();
        await dropSegmentStop.save();
        const json = {
          data: {
            'type': 'tripPlaceUpdate',
            'attributes': {
              'pickPlaceId': newPickPlace.id,
              'dropPlaceId': newDropPlace.id
            }
          }
        };
        return that.updatePlaceInTrip(tripId, json);
      }

      function saveNewAddressObject(record) {
        return createNewPlace(record);
      }

      try {
        // It's only being used for patching specific trips currently
        await records.reduce(async (acu, record) => {
          await saveNewAddressObject(record);
          await (0, _unwrapProxy.unwrapProxy)(record.get('segment.leg')).save();
          await (0, _unwrapProxy.unwrapProxy)(record.get('segment')).save();
          return record.save();
        }, Promise.resolve());
        this.get('notifications').success(`Trip ${trip.get('tripId')} was edited successfully`);
      } catch (e) {
        this.get('notifications').warning(`Edit to trip ${trip.get('tripId')} failed`);
      }
    },

    saveCancelRecordFunction(records) {
      /*eslint-disable */
      const me = this;
      /*eslint-enable */

      return new Promise((resolve, reject) => {
        if (!Ember.isEmpty(records)) {
          records.forEach(record => {
            const bookingId = record.get('leg.booking.id');
            const cancelData = this.get('routeexecevent').cancelData();
            const prepareCancelData = this.get('routeexecevent').prepareTripCancelData(cancelData, record);
            const json = JSON.stringify(prepareCancelData);
            const session = this.get('session');
            return this.get('ajax').post(_apiUrls.API.bookingService.host + '/booking/' + bookingId + '/cancellation', {
              method: 'POST',
              contentType: 'application/json',
              headers: {
                'Authorization': `Bearer ${session.data.authenticated.token}`
              },
              data: json
            }).then(() =>
            /*response*/
            {
              record.set('isForceDirty', false); // record.set('id',response.data.id);

              me.get('notifications').success('Trip successfully canceled');
              me.get('activeContext.topActiveContext').get('refreshTableContent').perform(['route']);
              resolve();
            }).catch(function (error) {
              me.get('notifications').warning('Failed to cancel Trip');
              reject(error);
            });
          });
        }
      });
    },

    saveNoShowRecordFunction(records) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      const tripNoShowAdapter = store.adapterFor('trip-noshow');
      const notifications = this.get('notifications');
      return records.reduce((acu, record) => {
        const dsStop = (0, _unwrapProxy.unwrapProxy)(record);
        const noshow = (0, _unwrapProxy.unwrapProxy)(dsStop.get('noShow'));
        const noshowReason = (0, _unwrapProxy.unwrapProxy)(noshow.get('noShowReasonCode'));
        const trip = (0, _unwrapProxy.unwrapProxy)(dsStop.get('trip'));
        trip.set('noshowDetails', noshow.get('notes'));
        return acu.then(() => routeExecEventAdapter.createStopNoShowEvent(record)).then(() => tripNoShowAdapter.createTripNoShow(trip, noshowReason)).then(() => {
          notifications.success(`Successfully No Showed Trip ${dsStop.get('trip.tripId')}`);
        }).catch(e => {
          notifications.warning(`Failed to No Show Trip ${dsStop.get('trip.tripId')}`);
          throw new Error(e);
        });
      }, Promise.resolve());
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('tripTransferAction', {
        id: 'tripTransfer',
        permId: _mappedPermIds.default.transferTrip,
        name: 'Transfer Trip',
        action: trip => {
          if (this.get('isScheduleDashboard')) {
            this.get('workspace').pushState('schedulingTripTransfer', {
              trip
            });
            return;
          }

          this.get('workspace').pushState('tripTransfer', {
            trip
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('bulkTripTransferAction', [{
        id: 'bulkTripTransfer',
        permId: _mappedPermIds.default.transferTrip,
        name: 'Bulk Trip Transfer',
        action: trips => {
          if (this.get('isScheduleDashboard')) {
            this.get('workspace').pushState('schedulingBulkTripTransfer', {
              trips
            });
            return;
          }

          this.get('workspace').pushState('bulkTripTransfer', {
            trips
          });
        }
      }]);
      this.set('noShowAction', {
        id: 'noShow',
        permId: _mappedPermIds.default.noShowTrip,
        name: 'No Show',
        action: model => {
          const store = this.get('store');
          const pickStopPoint = model.get('pickStopPoint');
          const noShowReasonCodes = this.get('store').peekAll('no-show-reason-code');
          pickStopPoint.set('noShow', store.createRecord('no-show', {
            noShowReasonCode: noShowReasonCodes.firstObject
          }));
          pickStopPoint.set('timestamp', new Date());
          const noShowComponent = this.get('noShowComponent');
          this.get('editModal').open(noShowComponent, [pickStopPoint], this.get('saveNoShowRecordFunction').bind(this), null, {
            title: 'No Show Trip'
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('unPerformNoShowAction', {
        id: 'unperform',
        permId: _mappedPermIds.default.unperformStop,
        name: 'Unperform No Show',
        action: model => {
          const workspace = this.get('workspace');
          const pickStopPoint = model.get('pickStopPoint');
          const title = 'Unperform No Show';
          workspace.pushState('unPerformNoShow', {
            stopPoint: pickStopPoint,
            title
          }, {
            replaceSelf: true
          });
        },
        disableOnLockedRoute: true
      });
      this.set('approveNoShowRequestAction', {
        id: 'approveNoShowRequest',
        permId: _mappedPermIds.default.approveNoShowRequest,
        name: 'Approve NoShow Request',
        action: trip => {
          this.get('workspace').pushState('noShowForm', {
            displayName: 'No Show Approval Form',
            trip,
            sectionId: 'approve'
          });
        },
        disableOnLocked: false
      });
      this.set('denyNoShowRequestAction', {
        id: 'denyNoShowRequest',
        permId: _mappedPermIds.default.denyNoShowRequest,
        name: 'Deny NoShow Request',
        action: trip => {
          this.get('workspace').pushState('noShowForm', {
            displayName: 'No Show Denial Form',
            trip,
            sectionId: 'deny'
          });
        },
        disableOnLocked: true
      });
      this.set('waitlistAction', {
        id: 'waitlist',
        permId: _mappedPermIds.default.waitlist,
        name: 'Waitlist',
        action: model => {
          this.waitlistTrip(model);
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('cancelAction', {
        id: 'cancelTrip',
        permIds: [_mappedPermIds.default.cancelFuture, _mappedPermIds.default.cancelSameDay],
        name: 'Cancel Trip',
        action: model => {
          const booking = (0, _unwrapProxy.unwrapProxy)(model.get('booking.content'));
          booking.set('timestamp', new Date());
          const cancelTripComponent = this.get('cancelTripComponent');
          this.get('editModal').open(cancelTripComponent, [booking.get('legs.firstObject.segments.firstObject')], this.get('saveCancelRecordFunction').bind(this));
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('editAction', {
        id: 'edit',
        permIds: [_mappedPermIds.default.editFuture, _mappedPermIds.default.editPast, _mappedPermIds.default.editSameDay],
        name: 'Edit',
        action: model => {
          const editTripComponent = this.get('editTripComponent');
          this.get('editModal').open(editTripComponent, [model], this.get('saveTripRecordFunction').bind(this));
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('activityLogAction', {
        id: 'activityLog',
        permId: _mappedPermIds.default.activityLog,
        name: 'View Activity Log',
        action: trip => {
          const id = _environment.default.APP.avlmLite ? trip.get('externalId') : trip.get('tripId');
          this.get('workspace').pushState('tripActivityLog', {
            displayName: `Trip ${id} Activity Log`,
            trip
          });
        },
        disableOnLocked: false,
        disableOnLockedRoute: true
      });
      this.set('autoRescheduleAction', {
        id: 'autoReschedule',
        permId: _mappedPermIds.default.autoReschedule,
        name: 'Auto Reschedule',
        action: trip => {
          this.autoRescheduleTrip(trip);
        },
        disableOnLocked: false,
        disableOnLockedRoute: true
      });
    },

    rowGroup: Ember.computed('row', function () {
      return this.get('table.rows');
    }).readOnly(),
    oneDayTimeline: Ember.computed('workspace.{startDate,endDate}', function () {
      const startDate = (0, _moment.default)(this.get('workspace.startDate')).format('YYYY-MMM-D');
      const endDate = (0, _moment.default)(this.get('workspace.endDate')).format('YYYY-MMM-D');
      return startDate === endDate;
    }),

    dragOver(event) {
      event.preventDefault();
      if (this.get('dragCoordinator.widgetType') !== 'routes') return;
      const rowElement = (0, _findRowRecord.findRowElement)(event.target); // User might drop onto widget's other elements.

      if (Ember.isBlank(rowElement) || Ember.isBlank(rowElement.id)) return;
      this.$(`#${rowElement.id}`).addClass('drag-target');
    },

    dragLeave(event) {
      event.preventDefault();
      const rowElement = (0, _findRowRecord.findRowElement)(event.target);
      if (Ember.isBlank(rowElement) || Ember.isBlank(rowElement.id)) return;
      this.$(`#${rowElement.id}`).removeClass('drag-target');
    },

    drop(event) {
      event.preventDefault();
      event.stopPropagation();
      if (this.get('dragCoordinator.widgetType') !== 'routes') return;
      const rowElement = (0, _findRowRecord.findRowElement)(event.target);
      if (Ember.isBlank(rowElement) || Ember.isBlank(rowElement.id)) return;
      this.$(`#${rowElement.id}`).removeClass('drag-target');
      const sourceRowRecord = this.get('dragCoordinator').getSourceRow(event.dataTransfer.getData('text'));
      if (Ember.isBlank(sourceRowRecord)) return;
      const targetRowRecord = (0, _findRowRecord.findRowRecordByElement)(event.target, this.get('rowGroup'));
      this.assignCluster(targetRowRecord, sourceRowRecord);
    },

    assignCluster(tripRow, routeRow) {
      const tripRecord = tripRow.get('record');
      const routeRecord = routeRow.get('record');

      if (routeRecord.constructor.modelName !== 'route') {
        return;
      }

      let cluster = routeRecord.get('clusters').firstObject;

      if (Ember.isBlank(cluster)) {
        cluster = this.get('store').createRecord('cluster', {
          route: routeRecord
        });
        tripRecord.set('cluster', cluster);
        cluster.save();
      } else {
        tripRecord.set('cluster', cluster);
        tripRecord.save();
      }
    },

    performDispatchWaitlist(tripId) {
      const session = this.get('session');
      return this.get('ajax').delete(_apiUrls.API.dispatchService.host + '/trip/' + tripId + '/waitlist', {
        method: 'DELETE',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      });
    },

    performScheduleWaitlist(tripId) {
      const session = this.get('session');
      return this.get('ajax').delete(_apiUrls.API.schedulingService.host + '/trip/' + tripId + '/waitlist', {
        method: 'DELETE',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      });
    },

    performWaitlist(model) {
      if (this.get('isScheduleDashboard')) {
        return this.performScheduleWaitlist(model.get('id'));
      }

      return this.performDispatchWaitlist(model.get('id'));
    },

    waitlistTrip(model) {
      const notifications = this.get('notifications');
      const title = 'Waitlist Trip';
      this.get('tooltip').setProperties({
        tip: `Are you sure you want to waitlist trip ${model.get('tripId')} ?`,
        title,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          return this.performWaitlist(model).then(() => {
            notifications.success(`Successfully Waitlisted Trip ${model.get('tripId')}`);
            return null;
          }).catch(() => {
            notifications.warning(`Failed to Waitlist Trip ${model.get('tripId')}`);
          });
        },
        secondaryActionText: 'Cancel',
        hasOverlay: true
      });
    },

    performAutoReschedule: (0, _emberConcurrency.task)(function* (model) {
      const scheduleAdapter = this.get('store').adapterFor('schedule');
      const tripId = parseInt(model.get('id'), 10);
      const payload = {
        'type': 'tripReschedule'
      };
      yield scheduleAdapter.autoRescheduleTrip(tripId, payload);
    }),

    autoRescheduleTrip(model) {
      const notifications = this.get('notifications');
      const title = 'Auto Reschedule';
      const successMsg = `Trip ${model.get('id')} successfully rescheduled to route ${model.get('route.id')}`;
      const failureMsg = `${model.get('tripId')} failed to reschedule`;
      this.get('tooltip').setProperties({
        tip: `Are you sure you want to automatically reschedule trip  ${model.get('tripId')} ?`,
        title,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          return this.get('performAutoReschedule').perform(model).then(() => {
            notifications.success(successMsg);
            this.get('workspace').pushState('schedulingAutoReschedule', {
              trip: model
            });
          }).catch(() => {
            notifications.warning(failureMsg);
          });
        },
        secondaryActionText: 'Cancel',
        hasOverlay: true
      });
    },

    //TODO
    dynamicSingleActions: Ember.computed('table.rows.@each.{selected,computedStatus}', function () {
      const tripTransferAction = this.get('tripTransferAction');
      const noShowAction = this.get('noShowAction');
      const waitlistAction = this.get('waitlistAction');
      const activityLogAction = this.get('activityLogAction');
      const oneDayTimeline = this.get('oneDayTimeline');
      const approveNoShowRequestAction = this.get('approveNoShowRequestAction');
      const denyNoShowRequestAction = this.get('denyNoShowRequestAction');
      const unPerformNoShowAction = this.get('unPerformNoShowAction');
      const autoRescheduleAction = this.get('autoRescheduleAction');
      const editTrip = this.get('editAction');
      const cancelTrip = this.get('cancelAction');
      const rows = this.get('table.rows') || [];
      const row = rows.findBy('selected', true);
      if (!row) return [];
      const tripStatus = `${row.get('computedStatus')}`.toLowerCase();
      const nextUnperformedStop = row.get('dispatchRoute.firstUnperformedStop');

      switch (tripStatus) {
        case 'scheduled':
          {
            const stopStatus = `${row.get('pickStopPoint.status')}`.toLowerCase();

            switch (stopStatus) {
              case 'arrive':
                if (Ember.isNone(row.get('dropStopPoint.status'))) {
                  return [noShowAction, activityLogAction];
                }

                return [activityLogAction];

              case 'depart':
                return [activityLogAction];

              default:
                if (oneDayTimeline) {
                  // can no-show if stop is the next stop on the list
                  if (nextUnperformedStop && nextUnperformedStop.get('trip.id') === row.id) {
                    return [waitlistAction, activityLogAction, tripTransferAction, noShowAction, editTrip, cancelTrip, autoRescheduleAction];
                  }

                  return [waitlistAction, activityLogAction, tripTransferAction, editTrip, cancelTrip, autoRescheduleAction];
                }

                if (nextUnperformedStop && nextUnperformedStop.get('trip.id') === row.id) {
                  return [waitlistAction, activityLogAction, noShowAction, tripTransferAction, editTrip, cancelTrip];
                }

                return [waitlistAction, activityLogAction, tripTransferAction, editTrip, cancelTrip];
            }
          }

        case 'waitlisted':
          return [activityLogAction, tripTransferAction, editTrip, cancelTrip, autoRescheduleAction];

        case 'illegal':
          return [activityLogAction, tripTransferAction, editTrip, cancelTrip];

        case 'no show':
          return [activityLogAction, unPerformNoShowAction];

        case 'no show request':
          return [approveNoShowRequestAction, denyNoShowRequestAction, activityLogAction, editTrip, cancelTrip];

        case 'active':
          return [activityLogAction, editTrip, cancelTrip];

        case 'completed':
          return [activityLogAction];

        default:
          return [activityLogAction, tripTransferAction, editTrip, cancelTrip];
      }
    }),
    dynamicBulkActions: Ember.computed('table.rows.@each.selected', function () {
      const bulkActions = this.get('bulkTripTransferAction');
      const checkedRows = this.get('table.rows').filterBy('isChecked') || [];

      if (!checkedRows.length) {
        return [];
      }

      const scheduledRows = checkedRows.filter(row => row.get('isScheduled'));
      const waitListedRows = checkedRows.filter(row => row.get('isWaitlisted'));
      const isScheduledOrWaitListedRow = checkedRows.every(row => row.get('isScheduled') || row.get('isWaitlisted')); //User must select only Scheduled or Waitlisted trips for bulk TripTransfer

      if (!isScheduledOrWaitListedRow) {
        return [];
      } //selected rows must be all  Scheduled or all Waitlisted trips


      if (scheduledRows.length && waitListedRows.length) {
        return [];
      }

      return [bulkActions];
    })
  });

  _exports.default = _default;
});