define("adept-iq/services/edit-modal", ["exports", "ember-concurrency", "lodash", "adept-iq/utils/save-records", "ember-inflector", "adept-iq/utils/guid"], function (_exports, _emberConcurrency, _lodash, _saveRecords, _emberInflector, _guid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    first,
    isUndefined
  } = _lodash.default;
  const UNDO_DEBOUNCE_MS = 500;

  var _default = Ember.Service.extend({
    router: Ember.inject.service('router'),
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax'),
    session: Ember.inject.service(),
    editableRecords: null,
    editComponent: null,
    saveRecordFunction: null,
    resetRecordFunction: null,
    isLinkMode: false,
    isSearching: false,
    filter: '',
    undoHistory: null,
    isLastUndoStateCommitted: true,
    errors: null,

    init() {
      this._super(...arguments);

      this.set('editableRecords', []);
      this.set('undoHistory', []);
      this.set('errors', []);
    },

    isOpen: Ember.computed.equal('router.currentRouteName', 'dashboard.modals.edit-form'),
    isDirty: Ember.computed('editableRecords.{@each.hasDirtyAttributes,@each.isForceDirty}', function () {
      const records = this.get('editableRecords');
      return records.any(record => {
        return record.get('hasDirtyAttributes') || record.get('isForceDirty');
      });
    }),
    canUndo: Ember.computed('undoHistory.lastObject.[]', function () {
      return Ember.isPresent(this.get('undoHistory.lastObject'));
    }),
    commitLastUndoState: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(UNDO_DEBOUNCE_MS);
      this.set('isLastUndoStateCommitted', true);
    }).restartable(),

    getLastUndoState() {
      let lastUndoState = this.get('undoHistory.lastObject');
      const isLastUndoStateCommitted = this.get('isLastUndoStateCommitted');

      if (isLastUndoStateCommitted) {
        lastUndoState = this.get('undoHistory').pushObject([]);
        this.set('isLastUndoStateCommitted', false);
      }

      return lastUndoState;
    },

    setLastUndoState(record, valuePath) {
      const lastUndoState = this.getLastUndoState();
      let propertyPath = valuePath.split('.');
      propertyPath = propertyPath[propertyPath.length - 1];
      let recordChanges = lastUndoState.find(changes => changes.record === record);

      if (Ember.isBlank(recordChanges)) {
        recordChanges = lastUndoState.pushObject({
          record: record,
          properties: {}
        });
      }

      if (Ember.isBlank(Ember.get(recordChanges, `properties.${valuePath}`))) {
        Ember.set(recordChanges, `properties.${propertyPath}`, Ember.get(record, valuePath));
      }

      this.get('commitLastUndoState').perform();
    },

    open(editComponent, models, saveRecordFunction, resetRecordFunction, options) {
      this.set('editComponent', editComponent);
      this.set('editableRecords', models);
      this.set('saveRecordFunction', saveRecordFunction);
      this.set('resetRecordFunction', resetRecordFunction);
      this.set('options', options); // Calculate an "activity id" for tracing calls related to this edit

      const activityIdGuid = (0, _guid.guid)();
      const activityIdValue = `AIQ-CLI-${activityIdGuid}:edit-modal:${editComponent}`;
      this.get('ajaxService').setActivityId(activityIdValue);
      const router = this.get('router');
      router.transitionTo('dashboard.modals.edit-form');
    },

    async close() {
      const records = this.get('editableRecords');
      const resetRecordFunction = this.get('resetRecordFunction');

      if (!isUndefined(resetRecordFunction) && resetRecordFunction !== null) {
        resetRecordFunction(records);
      }

      this.reset();
      this.get('ajaxService').setActivityId();
      const router = this.get('router');
      return router.transitionTo('dashboard');
    },

    setRecordValue(record, valuePath, value, options) {
      const relationships = record.constructor.relationshipNames || {
        hasMany: [],
        belongsTo: []
      };
      const isHasMany = relationships.hasMany.includes(valuePath);
      const isBelongsTo = relationships.belongsTo.includes(first(valuePath.split('.')));

      if (isHasMany) {
        this.setLastUndoState(options.record, options.valuePath);
        options.record.set(options.valuePath, value); // Link Mode does not support hasMany records.

        return;
      }

      if (!Ember.isNone(options) && options.useOptionRecord) {
        this.setLastUndoState(options.record, options.valuePath);
        options.record.set(options.valuePath, value);
      } else {
        this.setLastUndoState(record, valuePath);
        record.set(valuePath, value);
      }

      if (this.get('isLinkMode')) {
        const records = this.get('editableRecords');
        /*eslint-disable */

        records.forEach(record => {
          if (isBelongsTo) {
            record = Ember.get(record, first(valuePath.split('.'))).content;
          }
          /*eslint-enable */
          // valuePath may be set to belongsTo model valuePath if isBelongsTo.


          this.setLastUndoState(record, valuePath);
          record.set(valuePath, value);
        });
      }
    },

    // BelongsTo Relationships
    addRecordValue(record, valuePath, modelNameDefault) {
      const inflector = new _emberInflector.default(_emberInflector.default.defaultRules);
      const modelName = !isUndefined(modelNameDefault) ? modelNameDefault : inflector.singularize(valuePath);
      const newRecord = this.get('store').createRecord(modelName);
      record.set('isForceDirty', true);
      record.set(valuePath, newRecord);
      return newRecord;
    },

    removeRecordValue(record, valuePath) {
      record.set('isForceDirty', true);
      record.set(valuePath, null);
    },

    // HasMany Relationships
    pushRecordValue(record, valuePath, modelNameDefault) {
      const inflector = new _emberInflector.default(_emberInflector.default.defaultRules);
      const modelName = !isUndefined(modelNameDefault) ? modelNameDefault : inflector.singularize(valuePath);
      const newRecord = this.get('store').createRecord(modelName);
      record.set('isForceDirty', true);
      Ember.get(record, valuePath).pushObject(newRecord);
      return newRecord;
    },

    pullRecordValue(record, valuePath, removeRecord) {
      record.set('isForceDirty', true);
      Ember.get(record, valuePath).removeObject(removeRecord);
    },

    undo() {
      const lastUndoState = this.get('undoHistory').popObject();
      lastUndoState.forEach(({
        record,
        properties
      }) => {
        record.setProperties(properties);
      });
      this.set('isLastUndoStateCommitted', true);
    },

    undoAll() {
      const undoHistory = this.get('undoHistory');
      if (Ember.isPresent(undoHistory)) while (undoHistory.length > 0) this.undo();
    },

    toggleLinkMode() {
      this.toggleProperty('isLinkMode');
    },

    toggleSearchMode() {
      this.toggleProperty('isSearching');

      if (this.get('isSearching') === false) {
        this.set('filter', '');
      }
    },

    apply(title = '', tip = 'Would you like to commit your changes?', primaryActionText = 'Commit', secondaryActionText = 'Cancel') {
      const commitAction = this.commit.bind(this);
      const resetAction = this.reset.bind(this);
      const tooltip = this.get('tooltip'); // TODO: Integrate against impact API.

      this.setProperties({
        undoHistory: [],
        isLastUndoStateCommitted: true
      });
      tooltip.setProperties({
        tip: tip,
        title: title,
        hasOverlay: true,
        primaryActionText: primaryActionText,
        primaryAction: () => {
          return commitAction();
        },
        secondaryActionText: secondaryActionText,
        secondaryAction: () => {
          resetAction();
        }
      });
    },

    commit() {
      const records = this.get('editableRecords');
      const saveRecordFunction = this.get('saveRecordFunction');
      this.set('errors', []);

      if (!isUndefined(saveRecordFunction) && saveRecordFunction !== null) {
        return saveRecordFunction(records).then(() => {
          this.close();
        }).catch(e => {
          // we should handle these errors in the console for developers i.e adapter failures, network failure details
          // let form / widgets components handle error messages for end users to help ease user feedback
          // most of these errors are not percivable by the user anyways and are for developers
          console.warn(e.message, e); // eslint-disable-line no-console
        });
      }

      return (0, _saveRecords.default)(records).then(() => {
        this.close();
      }).catch(e => {
        // we should handle these errors in the console for developers i.e adapter failures, network failure details
        // let form / widgets components handle error messages for end users to help ease user feedback
        // most of these errors are not percivable by the user anyways and are for developers
        console.warn(e.message, e); // eslint-disable-line no-console
      });
    },

    cancel() {
      this.set('hasApplied', false);
    },

    reset() {
      this.undoAll();
      this.get('tooltip').reset();
      this.setProperties({
        isLinkMode: false,
        isSearching: false,
        filter: '',
        errors: []
      });
    }

  });

  _exports.default = _default;
});