define("adept-iq/initializers/tomtom", ["exports", "tomtom"], function (_exports, _tomtom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    _tomtom.default.setProductInfo('Adept IQ', '0.0.0');
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});