define("adept-iq/pods/components/side-drawer/scheduling-bulk-trip-transfer/component", ["exports", "ember-light-table", "ember-concurrency", "lodash", "moment", "adept-iq/utils/unwrapProxy", "adept-iq/pods/components/side-drawer/scheduling-bulk-trip-transfer/config"], function (_exports, _emberLightTable, _emberConcurrency, _lodash, _moment, _unwrapProxy, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    // passed by the side drawer container component
    onShrinkClick: null,
    onCloseClick: null,
    currentState: null,
    solutionPreviewTable: null,
    routeOverrideOptions: null,
    overrideSelectedRoute: null,
    overrideSelectedProvider: null,
    overrideSelectedOrder: null,
    title: 'Bulk Trip Transfer',
    errorText: null,
    rowComponent: 'table/rows/colored-row',
    canShrink: true,
    //passed  by the workspace service
    trips: Ember.computed.readOnly('stackFrame.options.trips'),
    canSave: Ember.computed('overrideSelectedOrder', function () {
      const overrideSelectedOrder = this.get('overrideSelectedOrder');

      if (!overrideSelectedOrder) {
        return false;
      }

      return true;
    }),
    currentRoute: Ember.computed('trips.[]', function () {
      const route = this.get('trips.firstObject.pickStopPoint.dispatchRoute.route');

      if (!route) {
        return null;
      }

      return route;
    }).readOnly(),
    overrideRouteOptions: Ember.computed('currentRoute', 'overrideSelectedProvider', function () {
      const overrideSelectedProvider = this.get('overrideSelectedProvider');
      const sourceRouteId = this.get('currentRoute.id');
      let sourceTripScheduleId = '';
      sourceTripScheduleId = this.get('trips.firstObject.schedule.id');
      return (0, _lodash.sortBy)(this.get('store').peekAll('route').filter(route => {
        const routeScheduleId = route.get('schedule.id');
        const routeId = route.get('id');
        let sameProvider = false;

        if (!(0, _lodash.isEmpty)(overrideSelectedProvider)) {
          sameProvider = overrideSelectedProvider.id === route.get('provider.id');
        }

        const sameSchedule = routeScheduleId === sourceTripScheduleId;
        const differentId = routeId !== sourceRouteId;
        return sameSchedule && differentId && sameProvider;
      }), route => route.name);
    }),
    overrideOrderOptions: Ember.computed('overrideSelectedRoute', function () {
      const trips = (0, _unwrapProxy.unwrapProxy)(this.get('overrideSelectedRoute.trips'));
      let routeOrdinals = [];

      if (!(0, _lodash.isEmpty)(trips)) {
        trips.forEach(trip => {
          const pick = (0, _unwrapProxy.unwrapProxy)(trip.get('pick'));
          const drop = (0, _unwrapProxy.unwrapProxy)(trip.get('drop'));
          routeOrdinals.push(pick.get('plannedRouteOrdinal'), drop.get('plannedRouteOrdinal'));
        });
        routeOrdinals = (0, _lodash.orderBy)(routeOrdinals, ordinal => {
          return ordinal;
        }, ['asc']);

        if (routeOrdinals.length === 0) {
          routeOrdinals.push(1);
        } else if (routeOrdinals.length > 0) {
          routeOrdinals.push(Math.max(...routeOrdinals) + 1);
        }

        return routeOrdinals;
      }

      return routeOrdinals;
    }),
    overrideProviderOptions: Ember.computed('', function () {
      return this.get('store').peekAll('provider').filter(provider => {
        return provider.get('userCanAccessProvider');
      });
    }),

    init() {
      this._super(...arguments);

      const table = new _emberLightTable.default(_config.solutionPreviewColumns);
      this.set('solutionPreviewTable', table);
    },

    didInsertElement() {
      this._super(...arguments); // side panel width just for trip transfer


      Ember.$('.side-drawer-modal').css('min-width', '600px');
      this.loadCurrentState();
    },

    loadCurrentState() {
      const currentState = this.get('currentState');

      if (currentState) {
        this.set('overrideSelectedProvider', currentState.overrideSelectedProvider);
        this.set('overrideSelectedRoute', currentState.overrideSelectedRoute);
        this.set('overrideSelectedOrder', currentState.overrideSelectedOrder);
      }
    },

    onBulkTransferTrip: (0, _emberConcurrency.task)(function* () {
      const scheduleAdapter = this.get('store').adapterFor('schedule');
      const routeId = this.get('overrideSelectedRoute.id');
      const trips = this.get('trips');
      const tripIds = (0, _lodash.map)(trips, trip => parseInt(trip.get('id'), 10));
      const transferTimeMoment = this.collectTransferTimeData();
      const payload = {
        'data': {
          'type': 'blockTransfer',
          'attributes': {
            transferTime: transferTimeMoment.format(),
            tripIds
          }
        }
      };
      yield scheduleAdapter.routeBulkTripTransfer(routeId, payload);
    }),

    collectTransferTimeData() {
      const trips = (0, _unwrapProxy.unwrapProxy)(this.get('overrideSelectedRoute.trips'));
      const overrideSelectedOrder = this.get('overrideSelectedOrder');
      const overrideSelectedRoute = this.get('overrideSelectedRoute');
      const routePlannedStartTimeEta = (0, _moment.default)(overrideSelectedRoute.get('plannedStartTime'));
      const routePlannedEndTimeEta = (0, _moment.default)(overrideSelectedRoute.get('plannedEndTime'));
      let pickAndDropTrips = [];
      trips.forEach(trip => {
        const drop = (0, _unwrapProxy.unwrapProxy)(trip.get('drop'));
        const pick = (0, _unwrapProxy.unwrapProxy)(trip.get('pick'));
        pickAndDropTrips.push(pick, drop);
      });
      pickAndDropTrips = (0, _lodash.orderBy)(pickAndDropTrips, trip => {
        return trip.get('plannedRouteOrdinal');
      }, ['asc']);
      const selectedOrderStopIndex = (0, _lodash.findIndex)(pickAndDropTrips, trip => trip.get('plannedRouteOrdinal') === overrideSelectedOrder);
      const nextOrderStopIndex = (0, _lodash.findIndex)(pickAndDropTrips, trip => trip.get('plannedRouteOrdinal') === overrideSelectedOrder + 1);

      if (!trips.length || selectedOrderStopIndex === -1 || nextOrderStopIndex === -1) {
        const differencePlannedEta = routePlannedStartTimeEta.diff(routePlannedEndTimeEta, 'minutes');
        routePlannedStartTimeEta.add(differencePlannedEta, 'minutes');
        return routePlannedStartTimeEta;
      }

      const selectedOrderStop = pickAndDropTrips[selectedOrderStopIndex];
      const nextOrderStop = pickAndDropTrips[nextOrderStopIndex];
      const selectedOrderStopEta = (0, _moment.default)(selectedOrderStop.get('plannedStartTime'));
      const nextOrderStopEta = (0, _moment.default)(nextOrderStop.get('plannedEndTime'));
      const differencePlannedEta = selectedOrderStopEta.diff(nextOrderStopEta, 'minutes');
      selectedOrderStopEta.add(differencePlannedEta, 'minutes');
      return selectedOrderStopEta;
    },

    resetSolutionTableData() {
      // reset stops table data
      this.set('solutionPreviewTable.rows', []);
    },

    getPickAndDropDataForSolutionPreview(trip, tripList) {
      const drop = (0, _unwrapProxy.unwrapProxy)(trip.get('drop'));
      const pick = (0, _unwrapProxy.unwrapProxy)(trip.get('pick'));
      const rider = (0, _unwrapProxy.unwrapProxy)(trip.get('rider'));
      const pickData = {
        tripId: trip.get('tripId'),
        plannedRouteOrdinal: pick.get('plannedRouteOrdinal'),
        plannedEta: pick.get('plannedEta'),
        stopType: 'pick',
        computedPromiseTime: trip.get('promiseTime'),
        lastName: rider.get('lastName')
      };
      const dropData = {
        tripId: trip.get('tripId'),
        plannedRouteOrdinal: drop.get('plannedRouteOrdinal'),
        plannedEta: drop.get('plannedEta'),
        stopType: 'drop',
        computedPromiseTime: trip.get('promiseTime'),
        lastName: rider.get('lastName')
      };
      tripList.push(pickData, dropData);
    },

    setupSolutionPreviewForBulk() {
      const trips = (0, _unwrapProxy.unwrapProxy)(this.get('overrideSelectedRoute.trips'));
      let tripList = [];

      if (!trips.length) {
        return;
      }

      trips.forEach(trip => {
        this.getPickAndDropDataForSolutionPreview(trip, tripList);
      });
      tripList = (0, _lodash.orderBy)(tripList, trip => {
        return trip.plannedRouteOrdinal;
      }, ['asc']);
      this.buildOrdinalForSelectedTrips(tripList);
      this.get('solutionPreviewTable').setRows(tripList);
    },

    buildOrdinalForSelectedTrips(tripList) {
      const trips = this.get('trips');
      const overrideSelectedOrder = this.get('overrideSelectedOrder');
      const tripWithPicksAndDrops = [];
      const selectedOrderStopIndex = (0, _lodash.findIndex)(tripList, stop => stop.plannedRouteOrdinal === overrideSelectedOrder);
      trips.forEach(trip => {
        const drop = (0, _unwrapProxy.unwrapProxy)(trip.get('drop'));
        const pick = (0, _unwrapProxy.unwrapProxy)(trip.get('pick'));
        const rider = (0, _unwrapProxy.unwrapProxy)(trip.get('rider'));
        const pickData = {
          tripId: trip.get('tripId'),
          plannedRouteOrdinal: pick.get('plannedRouteOrdinal'),
          plannedEta: pick.get('plannedEta'),
          stopType: 'pick',
          computedPromiseTime: trip.get('promiseTime'),
          lastName: rider.get('lastName'),
          selectedOrdinalRow: true
        };
        const dropData = {
          tripId: trip.get('tripId'),
          plannedRouteOrdinal: drop.get('plannedRouteOrdinal'),
          plannedEta: drop.get('plannedEta'),
          stopType: 'drop',
          computedPromiseTime: trip.get('promiseTime'),
          lastName: rider.get('lastName'),
          selectedOrdinalRow: true
        };
        tripWithPicksAndDrops.push(pickData, dropData);
      });
      tripList.splice(selectedOrderStopIndex + 1, 0, ...tripWithPicksAndDrops);
      tripList.forEach((trip, index) => {
        trip.plannedRouteOrdinal = index + 1;
      });
    },

    actions: {
      onSaveClick() {
        let tip = '';
        let successMsg = '';
        let errorMsg = '';
        const title = 'Bulk Transfer Trip';
        tip = 'Are you sure you want to transfer trips';
        successMsg = 'Bulk Trip Transfer was successful';
        errorMsg = 'Bulk Trip Transfer failed';
        this.get('tooltip').setProperties({
          tip,
          title,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return this.get('onBulkTransferTrip').perform().then(() => {
              this.get('notifications').success(successMsg);
              return this.onCloseClick();
            }).catch(err => {
              // eslint-disable-next-line no-console
              console.warn(err);
              this.get('notifications').warning(errorMsg);
            });
          },
          secondaryActionText: 'Cancel',
          hasOverlay: true
        });
      },

      onShrinkClick() {
        let currentState = this.get('currentState'); // const bulkTripTransfer = this.get('bulkTripTransfer');

        const overrideSelectedProvider = this.get('overrideSelectedProvider');
        const overrideSelectedRoute = this.get('overrideSelectedRoute');
        const overrideSelectedOrder = this.get('overrideSelectedOrder'); // save current manual settings

        currentState = {
          overrideSelectedProvider,
          overrideSelectedRoute,
          overrideSelectedOrder
        };
        this.set('currentState', currentState);
        this.onShrinkClick();
      },

      onChangeProvider(provider) {
        // clear selected route, order
        this.set('overrideSelectedRoute', null);
        this.set('overrideSelectedOrder', null);
        this.set('overrideSelectedProvider', provider);
        this.resetSolutionTableData();
      },

      onChangeRoute(route) {
        // clear selected order
        this.set('overrideSelectedOrder', null);
        this.set('overrideSelectedRoute', route);
        this.resetSolutionTableData();
      },

      onChangeOrder(order) {
        this.set('overrideSelectedOrder', order);
        this.setupSolutionPreviewForBulk();
      }

    }
  });

  _exports.default = _default;
});