define("adept-iq/serializers/vehicle-availability", ["exports", "adept-iq/serializers/-vehicle", "moment"], function (_exports, _vehicle, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _vehicle.default.extend({
    serialize()
    /* snapshot, options */
    {
      const json = this._super(...arguments);

      if (json.data && json.data.attributes) {
        // startDate and endDate should be set to start and end of day.
        const startDate = new Date(json.data.attributes.startTime);
        const endDate = new Date(json.data.attributes.endTime);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        json.data.attributes.startTime = startDate.toISOString();
        json.data.attributes.endTime = endDate.toISOString();
      }

      return json;
    },

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      // this is because of inconsistensies in api
      // returns an array instead of an object after a POST
      if (requestType === 'createRecord' && Ember.typeOf(payload.data) === 'array') {
        if (payload.data.length === 1) {
          payload.data = payload.data[0];
        }
      }

      return this._super(...arguments);
    },

    normalize(modelClass, resourceHash) {
      // handle topic payload
      const {
        days
      } = resourceHash.attributes;

      if (days) {
        delete resourceHash.attributes.days;
        days.forEach((value, i) => {
          const weekday = _moment.default.weekdays(i).toLowerCase();

          resourceHash.attributes[weekday] = value;
        });
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});