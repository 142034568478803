define("adept-iq/pods/components/side-drawer/rescue-route/component", ["exports", "moment", "ember-light-table", "ember-concurrency", "adept-iq/config/api-urls", "adept-iq/pods/components/side-drawer/rescue-route/config", "adept-iq/utils/availability", "adept-iq/utils/unwrapProxy", "adept-iq/mixins/fetchAssignableVehicleDrivers", "lodash"], function (_exports, _moment, _emberLightTable, _emberConcurrency, _apiUrls, _config, _availability, _unwrapProxy, _fetchAssignableVehicleDrivers, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const dispatchRouteHost = _apiUrls.API.dispatchService.host;
  const NUMBER_FORMAT = /^\d*$/;

  var _default = Ember.Component.extend(_fetchAssignableVehicleDrivers.default, {
    tagName: '',
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    routeName: null,
    startTime: null,
    endTime: null,
    selectedVehicle: null,
    selectedDriver: null,
    timeFormat: 'm-d-Y h:i K',
    provider: Ember.computed.readOnly('selectedVehicle.provider'),
    plannedStartTime: Ember.computed.readOnly('startTime'),
    //used in fetch vehicle driver mixin
    plannedEndTime: Ember.computed.readOnly('endTime'),
    //used in fetch vehicle driver mixin
    dispatchRoute: Ember.computed.readOnly('stackFrame.options.dsRoute'),
    route: Ember.computed.readOnly('dispatchRoute.route'),
    drivers: null,
    vehicles: null,
    onShrinkClick: null,
    onCloseClick: null,
    rowComponent: _config.rowComponent,

    init() {
      this._super(...arguments);

      if (!Ember.isEmpty(_config.tripColumns)) {
        _config.tripColumns.forEach(tripColumn => {
          if (tripColumn.highlightable) {
            tripColumn.cellClassNames = ['highlightable'];
          }
        });
      }

      const trips = this.get('dispatchRoute.unPerformedTrips');
      const table = new _emberLightTable.default(_config.tripColumns, trips, {
        enableSync: false
      });
      this.set('timePlaceHolder', 'MM-DD-YYYY hh:mm');
      this.set('tripsTable', table);
    },

    didInsertElement() {
      if (!this.getSchedule()) {
        this.onCloseClick();
        this.get('notifications').warning('Please export a schedule to use rescue route');
      }

      const today = (0, _moment.default)();
      this.fetchDriversTask.perform();
      this.fetchVehiclesTask.perform();
      this.set('date', today.toISOString());
      this.set('startTime', today.toISOString());
      this.set('endTime', (0, _moment.default)().endOf('day').toISOString());
    },

    hasAllSelectedValues: Ember.computed.and('routeName', 'selectedDriver', 'selectedVehicle', 'startTime', 'endTime'),
    selectedStopPoints: Ember.computed('tripsTable.rows.@each.isChecked', function () {
      return (0, _lodash.flatten)(this.get('tripsTable.rows').filterBy('isChecked', true).map(trip => trip.get('stopPoints').toArray()));
    }),
    availableDrivers: Ember.computed('startTime', 'endTime', 'drivers.[]', function () {
      const drivers = this.get('drivers');
      const selectedDriver = this.get('selectedDriver'); // always show selected driver

      if (selectedDriver && !drivers.includes(selectedDriver)) {
        drivers.unshift(selectedDriver);
      }

      if (!drivers || !drivers.length) return [];
      return drivers;
    }),
    availableVehicles: Ember.computed('startTime', 'endTime', 'vehicles.[]', function () {
      const vehicles = this.get('vehicles');
      const selectedVehicle = this.get('selectedVehicle'); // always show selected vehicle

      if (selectedVehicle && !vehicles.includes(selectedVehicle) && !selectedVehicle.get('placeholder')) {
        vehicles.unshift(selectedVehicle);
      }

      if (!vehicles || !vehicles.length) return [];
      return vehicles;
    }),
    driverAvailability: Ember.computed('startTime', 'endTime', 'selectedDriver.driverAvailabilities.@each.{startTime,endTime}', function () {
      const driverAvailabilities = this.get('selectedDriver.driverAvailabilities');
      if (Ember.isEmpty(driverAvailabilities)) return null;
      const plannedStart = (0, _moment.default)(this.get('startTime'));
      const plannedEnd = (0, _moment.default)(this.get('endTime'));
      const solution = (0, _availability.solveAvailability)(plannedStart, plannedEnd, driverAvailabilities);
      if (Ember.isEmpty(solution)) return null;
      return solution[0];
    }),
    driverAvailabilityShiftStart: Ember.computed('driverAvailability', function () {
      const availability = this.get('driverAvailability');
      if (Ember.isEmpty(availability) || Ember.isEmpty(availability.firstObject) || Ember.isEmpty(availability.firstObject.matchingAvailability)) return null;
      return availability.firstObject.matchingAvailability.get('formattedShiftStart');
    }),
    driverAvailabilityShiftEnd: Ember.computed('driverAvailability', function () {
      const availability = this.get('driverAvailability');
      if (Ember.isEmpty(availability) || Ember.isEmpty(availability.lastObject) || Ember.isEmpty(availability.lastObject.matchingAvailability)) return null;
      return availability.lastObject.matchingAvailability.get('formattedShiftEnd');
    }),

    getSchedule() {
      const schedule = (0, _unwrapProxy.unwrapProxy)(this.get('route.schedule'));
      if (!schedule) return false;
      this.set('schedule', schedule);
      return true;
    },

    formatBulkTransfer(stopPoints, sourceId, destinationId) {
      return stopPoints.map(stop => {
        return {
          id: stop.id,
          type: 'stop',
          source: sourceId,
          destination: destinationId,
          ordinal: stop.routeOrdinal
        };
      });
    },

    saveRescueRouteTask: (0, _emberConcurrency.task)(function* () {
      const {
        routeName,
        selectedDriver,
        selectedVehicle,
        startTime,
        endTime,
        schedule
      } = this.getProperties('routeName', 'selectedDriver', 'selectedVehicle', 'startTime', 'endTime', 'schedule');
      const ajax = this.get('ajax');
      const token = this.get('session.data.authenticated.token');
      const startGarage = this.get('dispatchRoute.startGarage');
      const endGarage = this.get('dispatchRoute.endGarage');
      const vehicleId = selectedVehicle ? selectedVehicle.get('id') : null;
      const driverId = selectedDriver ? selectedDriver.get('id') : null;
      const providerNameId = selectedVehicle ? selectedVehicle.get('provider.id') : null;
      const dispatchScheduleId = schedule ? schedule.get('dispatchSchedule.id') : null;
      const startGarageId = startGarage ? startGarage.get('id') : null;
      const endGarageId = endGarage ? endGarage.get('id') : null;
      const json = {
        data: {
          type: 'specialRoute',
          attributes: {
            name: routeName,
            routeType: 'rescue replacement',
            shiftStartTime: startTime,
            shiftEndTime: endTime
          },
          relationships: {
            dispatchSchedule: {
              data: {
                id: dispatchScheduleId,
                type: 'dispatchSchedule'
              }
            },
            driver: {
              data: {
                id: driverId,
                type: 'driver'
              }
            },
            providerName: {
              data: {
                id: providerNameId,
                type: 'providerName'
              }
            },
            vehicle: {
              data: {
                id: vehicleId,
                type: 'vehicle'
              }
            },
            startGaragePlace: {
              data: {
                id: startGarageId,
                type: 'place'
              }
            },
            endGaragePlace: {
              data: {
                id: endGarageId,
                type: 'place'
              }
            }
          }
        }
      };
      return yield ajax.post(`${dispatchRouteHost}/special-route`, {
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        data: json
      }).catch(e => {
        throw e;
      });
    }).keepLatest(),
    saveTripsTask: (0, _emberConcurrency.task)(function* (newDispatchRoute) {
      const ajax = this.get('ajax');
      const token = this.get('session.data.authenticated.token');
      const selectedStopPoints = this.get('selectedStopPoints');
      const sourceId = this.get('dispatchRoute.id');
      const destinationId = newDispatchRoute.data.id;
      const tripInstructions = this.formatBulkTransfer(selectedStopPoints, sourceId, destinationId);
      const json = {
        data: {
          type: 'recipe',
          attributes: {
            instructions: tripInstructions
          }
        }
      };
      return yield ajax.post(`${dispatchRouteHost}/bulk-operation`, {
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        data: json
      }).catch(e => {
        throw e;
      });
    }).keepLatest(),

    isValidEntry() {
      const selectedStopPoints = this.get('selectedStopPoints');
      const hasAllSelectedValues = this.get('hasAllSelectedValues');
      const tripCount = selectedStopPoints.length / 2; // 2 stops per trip

      const isAfter = (0, _moment.default)(this.get('endTime')).isAfter((0, _moment.default)(this.get('startTime')));

      if (!tripCount) {
        this.set('errorMessage', 'Please select at least one trip.');
        return false;
      }

      if (!isAfter) {
        this.set('errorMessage', 'Please set end time after start time');
        return false;
      }

      if (hasAllSelectedValues) {
        // reset error message
        this.set('errorMessage');
      } else {
        // show error message, missing properties
        this.set('errorMessage', 'Please fill out all of the fields before saving.');
        return false;
      }

      return true;
    },

    actions: {
      onChangeTime(valuePath, value) {
        const momentTime = (0, _moment.default)(value[0]);
        this.set(valuePath, momentTime.toISOString());
      },

      onCancel() {
        this.onCloseClick();
      },

      onRouteNameInput(name) {
        if (!NUMBER_FORMAT.test(name)) {
          return this.notifyPropertyChange('routeName');
        }

        this.set('routeName', name);
      },

      onSaveClick() {
        if (!this.isValidEntry()) {
          return false;
        }

        const tooltip = this.get('tooltip');
        const saveRescueRouteTask = this.get('saveRescueRouteTask');
        const routeName = this.get('routeName');
        const oldRouteName = this.get('route.name');
        const selectedStopPoints = this.get('selectedStopPoints');
        const notifications = this.get('notifications');
        const title = 'Rescue Route';
        const tripCount = selectedStopPoints.length / 2; // 2 stops per trip

        const tripCountPlural = tripCount > 1;
        tooltip.setProperties({
          tip: `You are rescuing ${tripCount} late ${tripCountPlural ? 'trips' : 'trip'} from route ${oldRouteName} to new route ${routeName}`,
          title,
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return saveRescueRouteTask.perform().catch(error => {
              let message;

              switch (error.status) {
                case 400:
                  message = 'There was a problem with one of the fields. Please check over the form and try again.';
                  break;

                case 409:
                  message = `The route name ${this.get('routeName')} is already taken, please try another.`;
                  break;

                default:
                  message = 'There was a problem creating the route, please try again.';
                  break;
              }

              this.set('errorMessage', message);
              notifications.warning(message);
              throw error;
            }).then(newDispatchRoute => {
              return this.saveTripsTask.perform(newDispatchRoute).then(() => {
                notifications.success(`Successfully transferred ${tripCount} late ${tripCountPlural ? 'trips' : 'trip'} to new route ${routeName}`);
                this.onCloseClick();
                return Promise.resolve();
              }).catch(error => {
                this.set('errorMessage', 'There was a problem transferring the trips to the rescue route. Please try again');
                notifications.warning(`Failed to transfer ${tripCount} late ${tripCountPlural ? 'trips' : 'trip'} to new route ${routeName}`);
                throw error;
              });
            }).catch(error => {
              console.error(error); //eslint-disable-line no-console
            }).finally(() => {
              tooltip.reset();
            });
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => tooltip.reset()
        });
      },

      onRefreshClick() {
        this.fetchDriversTask.perform();
        this.fetchVehiclesTask.perform(); // Reset form

        this.setProperties({
          routeName: null,
          startTime: null,
          endTime: null,
          selectedVehicle: null,
          selectedDriver: null
        });
      },

      onCheckAll() {
        const tripsTable = this.get('tripsTable');
        const tripsTableList = tripsTable.get('rows') || [];
        tripsTableList.forEach(row => {
          row.set('isChecked', true);
        });
      },

      onUncheckAll() {
        const tripsTable = this.get('tripsTable');
        const trips = tripsTable.get('rows') || [];
        trips.forEach(row => {
          row.set('isChecked', false);
        });
      }

    }
  });

  _exports.default = _default;
});