define("adept-iq/pods/components/side-drawer/system-config/providers/table/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    modelName: 'provider',
    fetchModelData: true,
    title: 'Providers',
    showSelectedRowLength: false,
    selectFirstRow: true,
    defaultSortId: 'provider',
    defaultSortAsc: false,
    // hideWidgetControls: true,
    columns: [{
      id: 'id',
      index: 0,
      type: 'string',
      label: 'ID',
      valuePath: 'id',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'name',
      index: 1,
      type: 'string',
      label: 'Name',
      valuePath: 'name',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'status',
      index: 2,
      type: 'text',
      label: 'Status',
      valuePath: 'status',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'type',
      index: 3,
      type: 'text',
      label: 'Type',
      valuePath: 'providerType',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'contactName',
      index: 4,
      type: 'string',
      label: 'Contact Name',
      valuePath: 'contactName',
      defaultWidth: 100,
      searchable: true
    }]
  };
  _exports.default = _default;
});