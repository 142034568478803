define("adept-iq/services/stops-reorder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    reorderInProgress: false,
    isHidden: false,
    widget: null,
    currentState: null,
    sortByStr: null,

    init() {
      this._super(...arguments);

      Ember.set(this, 'currentState', {});
    },

    // called when a user starts a reorder in stops widget
    startReorder(widget, sortByStr) {
      Ember.set(this, 'sortByStr', sortByStr);
      Ember.set(this, 'widget', widget);
      this.sortByETA();
      Ember.set(this, 'reorderInProgress', true);
    },

    toggleHiddenFlag() {
      this.toggleProperty('isHidden');
    },

    // called when a user stops a reorder in stops widget
    stopReorder() {
      let currentState = this.get('currentState');
      Ember.set(this, 'widget.state', {
        sortId: 'id',
        sortAsc: true
      });

      if (Object.keys(currentState).length === 0 && currentState.constructor === Object) {
        currentState = {
          sortId: 'id',
          sortAsc: true
        };
      }

      setTimeout(function () {
        Ember.set(this, 'widget.state', currentState);
        Ember.set(this, 'currentState', null);
        Ember.set(this, 'reorderInProgress', false);
      }.bind(this), 1000);
    },

    sortByETA() {
      const sortByStr = this.get('sortByStr');
      const oldState = this.get('widget.state') || {};
      this.set('widget.state', {
        sortId: sortByStr,
        sortAsc: true
      });
      Ember.set(this, 'currentState', oldState);
    }

  });

  _exports.default = _default;
});