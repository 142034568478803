define("adept-iq/pods/components/generic-components/progress-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vtjnaTj4",
    "block": "{\"symbols\":[],\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"progress\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"progress-bar progress-bar-striped progress-bar-animated\"],[11,\"role\",\"progressbar\"],[12,\"aria-valuenow\",[28,[[21,\"progress\"]]]],[11,\"aria-valuemin\",\"0\"],[11,\"aria-valuemax\",\"100\"],[12,\"style\",[28,[\"width: \",[21,\"progress\"],\"%\"]]],[9],[10],[0,\"\\n      \"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/generic-components/progress-bar/template.hbs"
    }
  });

  _exports.default = _default;
});