define("adept-iq/tests/factories/driver-availability", ["ember-data-factory-guy", "faker", "moment"], function (_emberDataFactoryGuy, _faker, _moment) {
  "use strict";

  const date = new Date();

  _emberDataFactoryGuy.default.define('driver-availability', {
    sequences: {
      randomBoolean: () => _faker.default.random.boolean(),
      randomStartTime: () => _faker.default.date.past(1, date),
      randomEndTime: () => _faker.default.date.future(3, date),
      randomShiftStart: () => (0, _moment.default)().startOf('day').add(6, 'hours').format('HH:mm'),
      randomShiftEnd: () => (0, _moment.default)().endOf('day').subtract(6, 'hours').format('HH:mm')
    },
    default: {
      startTime: _emberDataFactoryGuy.default.generate('randomStartTime'),
      endTime: _emberDataFactoryGuy.default.generate('randomEndTime'),
      shiftStart: _emberDataFactoryGuy.default.generate('randomShiftStart'),
      shiftEnd: _emberDataFactoryGuy.default.generate('randomShiftEnd'),
      sunday: _emberDataFactoryGuy.default.generate('randomBoolean'),
      monday: _emberDataFactoryGuy.default.generate('randomBoolean'),
      tuesday: _emberDataFactoryGuy.default.generate('randomBoolean'),
      wednesday: _emberDataFactoryGuy.default.generate('randomBoolean'),
      thursday: _emberDataFactoryGuy.default.generate('randomBoolean'),
      friday: _emberDataFactoryGuy.default.generate('randomBoolean'),
      saturday: _emberDataFactoryGuy.default.generate('randomBoolean')
    }
  });
});