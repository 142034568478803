define("adept-iq/pods/components/side-drawer/system-config/modals/driver-master/component", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy", "adept-iq/models/break-type", "adept-iq/pods/components/side-drawer/system-config/modals/driver-master/config"], function (_exports, _emberConcurrency, _unwrapProxy, _breakType, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    // passed in attributes
    isDriverBreakModalEnabled: false,
    availability: null,
    breakTypes: null,
    // properties
    tableRef: null,
    searchText: null,
    selectedBreakType: null,
    undoShiftBreak: false,
    isSearchEnabled: false,
    disableForms: true,
    hasOverlay: true,
    // default to true
    isTaskRunning: false,
    locationConfig: _config.locationFieldConfig,
    selectedRecord: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    isFixedBreak: Ember.computed.equal('selectedBreakType.name', _breakType.FIXED_BREAK),
    isFloatingBreak: Ember.computed.equal('selectedBreakType.name', _breakType.FLOATING_BREAK),
    disableAddressSearch: Ember.computed('disableForms', 'isFloatingBreak', function () {
      const disableForms = this.get('disableForms');
      const isFloatingBreak = this.get('isFloatingBreak');
      return isFloatingBreak || disableForms;
    }),
    title: Ember.computed('availability.{routeTemplate.name,day}', function () {
      const name = this.get('availability.routeTemplate.name');
      const day = Ember.String.capitalize(this.get('availability.day'));
      const title = name ? `Driver Break(s) for Route ${name} - ${day}` : `Driver Break(s) for New Route - ${day}`;
      return title;
    }),
    shiftBreakChangeSet: Ember.computed('selectedRecord', 'undoShiftBreak', function () {
      const shiftBreak = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));

      if (shiftBreak) {
        this.onBreakTypeChange((0, _unwrapProxy.unwrapProxy)(shiftBreak.get('breakType')));
      }

      return this.setDefaultProperties(shiftBreak);
    }),

    init() {
      this._super(...arguments);

      this.get('refreshTask').perform();
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('dropdownActions', [{
        id: 'print',
        name: 'Download',
        action: () => {
          const tableRef = this.get('tableRef');
          tableRef.exportData();
        }
      }]);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {}),

    onCloseModal() {
      this.set('isDriverBreakModalEnabled', false);
    },

    onBreakTypeChange(breakType) {
      if (breakType) {
        this.set('selectedBreakType', breakType);
      }
    },

    onDateChange(record, path, dateStr) {
      record.set(path, dateStr);
    },

    createPlaceRecord(record, store) {
      const location = store.createRecord('location');
      const address = store.createRecord('address', {
        locations: [location]
      });
      const place = store.createRecord('place', {
        address: address,
        location: location
      });
      record.place = place;
    },

    rollbackPlaceRecord(place) {
      place.get('address').rollbackAttributes();
      place.get('location').rollbackAttributes();
      place.rollbackAttributes();
    },

    setDefaultProperties(record = null, disableForms = true) {
      const store = this.get('store');
      const modelClass = store.modelFor('shift-break');
      const floatBreakType = this.get('breakTypes').find(breakType => breakType.get('name') === _breakType.FLOATING_BREAK);
      const fields = Array.from(modelClass.attributes.keys()).reduce((acu, key) => {
        acu[key] = null;
        return acu;
      }, {});
      fields.place = null;
      fields.breakType = floatBreakType;
      fields.requestTime = new Date();

      if (!Ember.isEmpty(record)) {
        Object.entries(fields).forEach(([key]) => {
          fields[key] = record.get(key);
        });
        fields.id = record.get('id');
        fields.place = (0, _unwrapProxy.unwrapProxy)(record.get('place')); // relationship

        fields.breakType = (0, _unwrapProxy.unwrapProxy)(record.get('breakType')); // relationship
      }

      if (!fields.place) this.createPlaceRecord(fields, store); // this.resetErrors();

      if (record) {
        const disableBtns = {
          new: false,
          edit: false,
          delete: false,
          undo: true
        };
        Ember.run.scheduleOnce('afterRender', this, () => {
          this.set('disableBtns', disableBtns);
          this.set('disableForms', disableForms);
        });
      }

      return Ember.Object.create(fields);
    },

    onSaveShiftBreakTask: (0, _emberConcurrency.task)(function* () {
      const tableRef = this.get('tableRef');
      const availabilityChangeSet = this.get('availability');
      const availability = availabilityChangeSet.data;
      const shiftBreakChangeSet = this.get('shiftBreakChangeSet');
      const selectedRecord = this.get('selectedRecord');
      const store = this.get('store');
      const shiftBreaks = availability.get('shiftBreaks');
      let pendingRecord = null;
      shiftBreakChangeSet.id = Ember.guidFor(shiftBreakChangeSet); //temporary id for ember table data join

      if (selectedRecord) {
        selectedRecord.setProperties(shiftBreakChangeSet);
        pendingRecord = selectedRecord;
      } else {
        pendingRecord = store.createRecord('shift-break', shiftBreakChangeSet);
      }

      if (pendingRecord.get('isNew')) {
        shiftBreaks.pushObject(pendingRecord);
      }

      availabilityChangeSet.set('shiftBreaks', shiftBreaks);
      yield availabilityChangeSet.execute(); // must save route template and availability if they are new before shift-break
      // try {
      //   // changeset save
      //   yield pendingRecord.save();
      //   yield availability.save();
      //   yield routeTemplate.save();
      // }
      // catch (e) {
      //   console.warn(e); //eslint-disable-line
      // }
      // pendingRecord.set('selected', true); // saved item will be selected after save

      yield Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
    }),
    onDeleteShiftBreakTask: (0, _emberConcurrency.task)(function* () {
      const selectedRecord = this.get('selectedRecord');
      const tableRef = this.get('tableRef');
      const availabilityChangeSet = this.get('availability');

      if (selectedRecord) {
        selectedRecord.deleteRecord(); // mark the record as deleted, then in master save call save function to persist deletion
      }

      yield availabilityChangeSet.execute();
      yield Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
    }),
    actions: {
      // header button functions
      newDriverBreak() {
        const disableBtns = {
          new: true,
          edit: true,
          delete: true,
          undo: true
        };
        const selectedRecord = this.get('selectedRecord');
        if (selectedRecord) selectedRecord.set('selected', false); // triggers cp and on save it should create a new record unless user selects a new record

        this.set('disableBtns', disableBtns);
        this.set('disableForms', false);
      },

      editDriverBreak() {
        const disableBtns = {
          new: true,
          edit: false,
          delete: true,
          undo: false
        };
        this.set('disableBtns', disableBtns);
        this.set('disableForms', false);
      },

      undoDriverBreak() {
        this.toggleProperty('undoShiftBreak');
      },

      saveDriverBreak() {
        this.get('onSaveShiftBreakTask').perform();
      },

      deleteDriverBreak() {
        this.get('onDeleteShiftBreakTask').perform();
      },

      onPrimaryActionButtonClick() {
        const selectedRecord = this.get('selectedRecord');

        if (selectedRecord) {
          this.get('queryModal.primaryActionTask').perform(selectedRecord).then(() => {
            this.onCloseModal();
          });
        }
      },

      onCloseModal() {
        this.onCloseModal();
      },

      onClickOverlayModal() {
        const hasOverlay = this.get('hasOverlay');

        if (hasOverlay) {
          return false;
        }

        this.get('queryModal').reset();
      },

      onHelp() {},

      toggleSearchInput() {
        this.toggleProperty('isSearchEnabled');
      },

      onSearchTextChange(searchText) {
        const previousSearchText = this.get('searchText');

        if (searchText === previousSearchText) {
          return;
        }

        const tableRef = this.get('tableRef');

        if (!tableRef) {
          return;
        }

        this.set('searchText', searchText);
        tableRef.onSearchTextChange(searchText);
      },

      onClearSearchText() {
        this.set('searchText', '');
        const tableRef = this.get('tableRef');

        if (!tableRef) {
          return;
        }

        tableRef.onClearSearchText();
      },

      onDropDownActionClick(action, dropdown) {
        dropdown.actions.close();
        if (action.action) action.action();
      },

      onDateChange(record, path, value) {
        this.onDateChange(record, path, value);
      },

      onCellValueChange(record, valuePath, value, options) {
        if (options.valuePath) {
          return record.set(`${valuePath}.${options.valuePath}`, value);
        }

        return record.set(valuePath, value);
      },

      onInputValueChange(record, valuePath, value) {
        return record.set(valuePath, value);
      },

      // power select event handler
      onBreakTypeChange(option) {
        this.set('shiftBreakChangeSet.breakType', option);
        this.onBreakTypeChange(option);
      }

    }
  });

  _exports.default = _default;
});