define("adept-iq/topic-transforms/stop-point", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transform;

  function transform(payload
  /*, ENV*/
  ) {
    const transformedPayloads = [];
    if (!payload.data || !payload.data.attributes) return transformedPayloads;

    if (payload.data.attributes.parentType === 'break') {
      payload.data.attributes.stopType = 'break'; // dispatch driver break created by default is 'pick' needs to convert the pick to a break stop type
    }

    transformedPayloads.push(payload);
    return transformedPayloads;
  }
});