define("adept-iq/pods/components/form-components/eligibilities/component", ["exports", "moment", "adept-iq/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-eligibilities'],
    store: Ember.inject.service(),
    value: null,
    eligibilityTypes: null,
    eligibilities: Ember.computed.readOnly('value'),
    fromPath: Ember.computed.readOnly('extra.fromPath'),
    toPath: Ember.computed.readOnly('extra.toPath'),
    eligibilityTypePath: Ember.computed.readOnly('extra.eligibilityTypePath'),

    init() {
      this.set('datePlaceHolder', _environment.default.dateTimeFormat.dateMoment);
      this.set('timePlaceHolder', _environment.default.dateTimeFormat.timeMoment);
      this.set('dateFormat', _environment.default.dateTimeFormat.dateFlatPickr);
      this.set('timeFormat', _environment.default.dateTimeFormat.timeFlatPickr);

      this._super(...arguments);
    },

    didInsertElement: function () {
      const eligibilityTypeModelName = this.get('extra.eligibilityTypeModelName');
      this.set('eligibilityTypes', this.get('store').peekAll(eligibilityTypeModelName));
    },
    actions: {
      onInput(record, valuePath, value) {
        const options = {
          record,
          valuePath
        };
        this.get('onChange')(value, options);
      },

      onChangeDate(record, valuePath, value) {
        const options = {
          record,
          valuePath
        };
        this.set('record.isForceDirty', true);
        this.get('onChange')(value[0], options);
      },

      onClose(currentValue, stringValue, datePicker) {
        const currentValueMoment = (0, _moment.default)(currentValue[0]);
        const newValueMoment = (0, _moment.default)(stringValue);

        if (!newValueMoment._isValid) {
          // $(`#${datePicker.element.id}`).focus();
          this.set('editModal.errors', ['Date entered is invalid.']);
        }

        if (datePicker.config.allowInput && datePicker._input.value && newValueMoment._isValid && !currentValueMoment.isSame(newValueMoment)) {
          // date format is for database so should not be from config
          datePicker.setDate(newValueMoment.format('YYYY-MM-DD'), true);
        } // $(datePicker.altInput.className).select();
        // $(datePicker.element).select();
        // $('.effective-start').select();

      },

      onAddNewEligibility() {
        const eligibility = this.get('onCreate')();
        eligibility.set('eligibilityType', '');
      },

      onRemoveEligibility(record, valuePath, value) {
        const options = {
          record,
          valuePath
        };
        record.deleteRecord();
        this.get('onRemove')(value, options);
      },

      onEligibilityTypeChange(record, valuePath, value) {
        record.set(valuePath, value);
      }

    }
  });

  _exports.default = _default;
});