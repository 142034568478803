define("adept-iq/pods/components/side-drawer/system-config/modals/driver-master/table/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    modelName: 'shiftBreaks',
    title: 'Driver Break Templates',
    defaultSortId: 'breakStart',
    defaultSortAsc: true,
    fetchModelData: false,
    selectFirstRow: true,
    hideWidgetControls: true,
    columns: [{
      id: 'breakStart',
      index: 1,
      type: 'time',
      label: 'Start Break',
      valuePath: 'requestTime',
      defaultWidth: 100,
      format: 'hh:mm A',
      searchable: true
    }, {
      id: 'breakLength',
      index: 2,
      type: 'number',
      label: 'Break Length',
      valuePath: 'minutesPlannedDuration',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'breakType',
      index: 3,
      type: 'text',
      label: 'Break Type',
      valuePath: 'breakType.name',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'notes',
      index: 4,
      type: 'text',
      label: 'Notes',
      valuePath: 'notes',
      defaultWidth: 100,
      searchable: true
    }]
  };
  _exports.default = _default;
});