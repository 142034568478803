define("adept-iq/models/zone", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.AFFINITY_NO_SERVICE = _exports.AFFINITY_LAST_RESORT = _exports.AFFINITY_NORMAL = _exports.AFFINITY_PREFERRED = void 0;
  const {
    Model,
    attr
  } = _emberData.default;
  const AFFINITY_PREFERRED = {
    type: 'Preferred',
    val: 5
  };
  _exports.AFFINITY_PREFERRED = AFFINITY_PREFERRED;
  const AFFINITY_NORMAL = {
    type: 'Normal',
    val: 4
  };
  _exports.AFFINITY_NORMAL = AFFINITY_NORMAL;
  const AFFINITY_LAST_RESORT = {
    type: 'Last Resort',
    val: 1
  };
  _exports.AFFINITY_LAST_RESORT = AFFINITY_LAST_RESORT;
  const AFFINITY_NO_SERVICE = {
    type: 'No Service',
    val: 0
  };
  _exports.AFFINITY_NO_SERVICE = AFFINITY_NO_SERVICE;

  var _default = Model.extend({
    name: attr('string'),
    displayName: attr('string'),
    type: attr('string'),
    points: attr(),
    convertAffinityValue: Ember.computed('affinityValue', function () {
      const affinityValue = this.get('affinityValue');

      switch (affinityValue) {
        case AFFINITY_PREFERRED.val:
          {
            return AFFINITY_PREFERRED.type;
          }

        case AFFINITY_NORMAL.val:
          {
            return AFFINITY_NORMAL.type;
          }

        case AFFINITY_LAST_RESORT.val:
          {
            return AFFINITY_LAST_RESORT.type;
          }

        default:
          {
            return AFFINITY_NO_SERVICE.type;
          }
      }
    }),
    noOfPoints: Ember.computed('points', function () {
      return this.get('points') ? this.get('points.length') : 0;
    }),
    polypoints: Ember.computed('points', 'noOfPoints', function () {
      const polygonPoints = [];
      const points = this.get('points');

      if (!points && this.get('noOfPoints') === 1) {
        const item = points[1];
        return item;
      } else if (this.get('points') !== null) {
        points.forEach(item => {
          const polygonPoint = [];
          polygonPoint.push(item.lat);
          polygonPoint.push(item.lng);
          polygonPoints.push(polygonPoint);
        });
      }

      return polygonPoints;
    }),
    status: Ember.computed('points', function () {
      return 'normal';
    }),
    calculateCenter: Ember.computed('points', 'noOfPoints', function () {
      let minlat = false;
      let minlng = false;
      let maxlat = false;
      let maxlng = false;
      let lat = null;
      let lng = null;
      const points = this.get('points');

      if (!Ember.isEmpty(points)) {
        points.forEach(item => {
          if (minlat === false) {
            minlat = item.lat;
          } else {
            minlat = item.lat < minlat ? item.lat : minlat;
          }

          if (maxlat === false) {
            maxlat = item.lat;
          } else {
            maxlat = item.lat > maxlat ? item.lat : maxlat;
          }

          if (minlng === false) {
            minlng = item.lng;
          } else {
            minlng = item.lng < minlng ? item.lng : minlng;
          }

          if (maxlng === false) {
            maxlng = item.lng;
          } else {
            maxlng = item.lng > maxlng ? item.lng : maxlng;
          }
        }); // Calculate the center

        lat = maxlat - (maxlat - minlat) / 2;
        lng = maxlng - (maxlng - minlng) / 2;
      }

      return {
        lat: lat,
        lng: lng
      };
    }),
    lat: Ember.computed.alias('calculateCenter.lat'),
    lng: Ember.computed.alias('calculateCenter.lng')
  });

  _exports.default = _default;
});