define("adept-iq/adapters/trip-transfer", ["exports", "adept-iq/adapters/-cos-continuousOptService"], function (_exports, _cosContinuousOptService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _cosContinuousOptService.default.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    postTransfer(tripIds, dispatchScheduleId, destinationRouteId) {
      const session = this.get('session');
      const url = `${this.host}/optimizer/trip-transfer`;
      const json = {
        data: {
          type: 'tripTransfer',
          attributes: {
            dispatchScheduleId,
            tripIds,
            destinationRouteId
          }
        }
      };
      return this.get('ajax').post(url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    postManualTransfer(trips, dispatchScheduleId, destinationRouteId) {
      const session = this.get('session');
      const url = `${this.host}/optimizer/manual-trip-transfer`;
      const json = {
        data: {
          type: 'manualTripTransfer',
          attributes: {
            dispatchScheduleId,
            trips,
            destinationRouteId
          }
        }
      };
      return this.get('ajax').post(url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    }

  });

  _exports.default = _default;
});