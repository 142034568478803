define("adept-iq/pods/components/generic-widgets/form-widget/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-widget'],
    layoutName: 'components/generic-widgets/form-widget',
    editableRecords: null,
    editableSections: null,
    service: null,
    formType: Ember.computed('title', function () {
      if (this.get('title') !== '') {
        return this.get('title').split(' ')[1];
      }

      return '';
    }),
    title: Ember.computed('editModal.editComponent', function () {
      const editComponent = this.get('editModal.editComponent');
      const componentNames = editComponent.split(/[-/]+/);
      const editFormTitle = this.get('editModal.options.title');

      if (editComponent === 'iq-widgets/no-show-request-approval-form-widget') {
        return 'NoShow Approval Form';
      }

      switch (componentNames[2]) {
        case 'noshow':
          if (editFormTitle) {
            return editFormTitle;
          }

          return 'No Show';

        case 'new':
          return 'Add Trip';

        case 'edit':
          return 'Edit Trip';

        case 'cancel':
          return 'Cancel Trip';

        case 'assign':
          return 'Assign Trip to Route';

        case 'driver':
          return 'Edit Break';

        case 'passengers':
          if (this.get('editableRecords')[0].id) {
            return 'Edit Passenger';
          }

          return 'Add Passenger';

        case 'vehicles':
          if (componentNames[3] === 'breakdown') {
            return 'Vehicle Breakdown';
          }

          if (this.get('editableRecords')[0].id) {
            return 'Edit Vehicle';
          }

          return 'Add Vehicle';

        case 'drivers':
          if (this.get('editableRecords')[0].id) {
            return 'Edit Driver';
          }

          return 'Add Driver';

        case 'routes':
          if (componentNames[3] === 'start') {
            return 'Start Route';
          }

          if (componentNames[3] === 'end') {
            return 'End Route';
          }

          return 'Edit Route';

        case 'breakdown':
          return 'Add Replacement Route';

        case 'roles':
          return 'Edit Role';

        default:
          return '';
      }
    }),
    readOnlyFields: Ember.computed('editableRecords.[]', function () {
      const editableSections = this.get('editableSections');
      const readOnlyFields = editableSections.find(x => x.title === 'Read Only');
      var readOnlyItems = {};

      if (!Ember.isNone(readOnlyFields)) {
        for (let i = 0; i < readOnlyFields.fields.length; i++) {
          const o = readOnlyFields.fields[i];
          readOnlyItems[o.id] = o;
        }
      }

      return readOnlyItems;
    }),

    init() {
      this._super(...arguments);

      (true && !(Ember.isArray(this.get('editableSections'))) && Ember.assert('{{generic-widgets/form-widget}} requires extending components to provide an `editableSections` array', Ember.isArray(this.get('editableSections'))));
      (true && !(Ember.isPresent(this.get('service'))) && Ember.assert('{{generic-widgets/form-widget}} requires extending components to provide a `service` service', Ember.isPresent(this.get('service'))));
      (true && !(Ember.isArray(this.get('editableRecords'))) && Ember.assert('{{generic-widgets/form-widget}} requires an `editableRecords` array', Ember.isArray(this.get('editableRecords'))));
    },

    didInsertElement() {
      this._super(...arguments); // auto-focus on first input control


      const el = this.$('input');

      if (el && el[0]) {
        el[0].focus();
      } // install keyboard listener


      this._onKeyDown = event => {
        Ember.run.schedule('actions', this, 'onKeyDown', event);
      };

      document.addEventListener('keydown', this._onKeyDown);
    },

    willDestroyElement() {
      document.removeEventListener('keydown', this._onKeyDown);
    },

    filteredSections: Ember.computed('editableSections', 'service.filter', function () {
      const sections = Ember.copy(this.get('editableSections'), true) || [];
      const filter = this.get('service.filter');
      return sections.map(section => {
        /*eslint-disable */
        Ember.set(section, 'fields', section.fields.filter(field => {
          return new RegExp(`^${filter}`, 'i').test(field.label);
        }));
        /*eslint-enable */

        return section;
      }).filter(section => section.fields.length > 0);
    }),

    onKeyDown(event) {
      // can check to see if focused element is in this component:
      // let el = this.$()[0];
      // if (!el.contains(document.activeElement)) return;
      if (event.key === 'Enter') {
        return this.onEnter();
      }

      if (event.key === 'z' && (event.metaKey || event.ctrlKey)) {
        this.get('service').undo();
      }
    },

    onEnter() {
      if (!document.activeElement) {
        this.get('service').apply();
        return;
      } // buttons handle enter button on their own


      if (document.activeElement.tagName === 'BUTTON') {
        return;
      }

      const {
        classList
      } = document.activeElement; // power selects handle enter button on their own

      if (classList.contains('ember-power-select-trigger')) {
        return;
      } // enter toggles an on/off switch


      if (classList.contains('onoffswitch-switch')) {
        document.activeElement.parentElement.click();
        return;
      }

      const title = this.get('title');
      this.get('service').apply(title);
    },

    actions: {
      onCellValueChange(record, valuePath, value, options) {
        this.get('service').setRecordValue(record, valuePath, value, options);

        if (valuePath === 'vehicleType' || valuePath === 'noShowReason' || valuePath === 'assignRoute' || valuePath === 'noShowNotes') {
          record.set('isForceDirty', true);
          record.set(valuePath, value);
        }
      },

      onCellValueAppend(record, valuePath, modelName) {
        return this.get('service').pushRecordValue(record, valuePath, modelName);
      },

      onCellValueRemove(record, valuePath, modelName, options) {
        this.get('service').pullRecordValue(record, valuePath, options.record);
      },

      onCloseClick() {
        this.get('service').close();
      },

      onUndoClick() {
        this.get('service').undo();
      },

      onLinkToggle() {
        this.get('service').toggleLinkMode();
      },

      onSearchToggle() {
        this.get('service').toggleSearchMode();
      },

      onApplyClick() {
        const title = this.get('title');
        this.get('service').apply(title);
      },

      onItemButtonClick()
      /*item*/
      {
        /*eslint-disable */
        alert('Clicked button');
        /*eslint-enable */
      }

    }
  });

  _exports.default = _default;
});