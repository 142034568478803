define("adept-iq/pods/components/table/cells/base-cell/component", ["exports", "adept-iq/pods/components/table/cells/base-cell/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['table-base-cell', 'data-test-table-base-cell'],
    attributeBindings: ['tabindex'],
    layout: _template.default,
    tooltipText: Ember.computed('row.isLocked', function () {
      const isLocked = this.get('row.isLocked');

      if (Ember.isNone(isLocked)) {
        return null;
      }

      if (isLocked) {
        return `Locked by ${this.get('row.lockedBy')}`;
      }
    }),
    displayTooltip: Ember.computed('tooltipText', 'row.isLocked', function () {
      const tooltipText = this.get('tooltipText');
      const isLocked = this.get('row.isLocked');
      return Ember.isPresent(tooltipText) && isLocked;
    }),
    tabindex: 0
  });

  _exports.default = _default;
});