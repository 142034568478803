define("adept-iq/models/dispatch-route", ["exports", "ember-data", "adept-iq/utils/format-text-extension", "lodash", "adept-iq/utils/unwrapProxy", "moment", "adept-iq/models/vehicle"], function (_exports, _emberData, _formatTextExtension, _lodash, _unwrapProxy, _moment, _vehicle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ROUTE_DISPATCHED_STATUS = _exports.ROUTE_SIGNED_ON_STATUS = _exports.ROUTE_BREAKDOWN_STATUS = _exports.ROUTE_AWAITING_STATUS = _exports.ROUTE_EMERGENCY_STATUS = _exports.ROUTE_COMPLETED_STATUS = _exports.ROUTE_ON_BREAK_STATUS = _exports.ROUTE_IN_PROGRESS_STATUS = _exports.ROUTE_STARTED_STATUS = _exports.ROUTE_NOT_STARTED_STATUS = _exports.ROUTE_NO_DRIVER_STATUS = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  const ROUTE_NO_DRIVER_STATUS = 'No Driver';
  _exports.ROUTE_NO_DRIVER_STATUS = ROUTE_NO_DRIVER_STATUS;
  const ROUTE_NOT_STARTED_STATUS = 'Not Started';
  _exports.ROUTE_NOT_STARTED_STATUS = ROUTE_NOT_STARTED_STATUS;
  const ROUTE_STARTED_STATUS = 'Started';
  _exports.ROUTE_STARTED_STATUS = ROUTE_STARTED_STATUS;
  const ROUTE_IN_PROGRESS_STATUS = 'In Progress';
  _exports.ROUTE_IN_PROGRESS_STATUS = ROUTE_IN_PROGRESS_STATUS;
  const ROUTE_ON_BREAK_STATUS = 'On Break';
  _exports.ROUTE_ON_BREAK_STATUS = ROUTE_ON_BREAK_STATUS;
  const ROUTE_COMPLETED_STATUS = 'Route Ended';
  _exports.ROUTE_COMPLETED_STATUS = ROUTE_COMPLETED_STATUS;
  const ROUTE_EMERGENCY_STATUS = 'Emergency';
  _exports.ROUTE_EMERGENCY_STATUS = ROUTE_EMERGENCY_STATUS;
  const ROUTE_AWAITING_STATUS = 'Awaiting Status';
  _exports.ROUTE_AWAITING_STATUS = ROUTE_AWAITING_STATUS;
  const ROUTE_BREAKDOWN_STATUS = 'Breakdown';
  _exports.ROUTE_BREAKDOWN_STATUS = ROUTE_BREAKDOWN_STATUS;
  const ROUTE_SIGNED_ON_STATUS = 'Signed On';
  _exports.ROUTE_SIGNED_ON_STATUS = ROUTE_SIGNED_ON_STATUS;
  const ROUTE_DISPATCHED_STATUS = 'Dispatched';
  _exports.ROUTE_DISPATCHED_STATUS = ROUTE_DISPATCHED_STATUS;

  var _default = Model.extend({
    session: Ember.inject.service(),
    orderedClustersSortKeys: ['routeOrdinal'],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    lockedForOptimization: attr('boolean'),
    provider: belongsTo('provider'),
    route: belongsTo('route'),
    dispatchRouteLock: belongsTo('lock'),
    performedPolyline: belongsTo('polyline'),
    dispatchSchedule: belongsTo('dispatch-schedule'),
    clusters: hasMany('cluster'),
    routeVehicleDrivers: hasMany('route-vehicle-driver'),
    // breakdown replacement attributes
    replacementRouteId: attr('string'),
    replacementStartTime: attr('date'),
    replacementEndTime: attr('date'),
    replacementDriver: belongsTo('driver'),
    replacementVehicle: belongsTo('vehicle'),
    replacementMoveAllTrips: attr('boolean'),
    breakdownPlace: belongsTo('place'),
    isCompleted: Ember.computed.equal('status', 'Complete'),
    // used for map polyline layer filtering
    isNavigating: Ember.computed.not('isPerformed'),
    isPerformed: Ember.computed.readOnly('isCompleted'),
    vehiclePolyline: Ember.computed.readOnly('route.navigationPolyline'),
    otp: Ember.computed.readOnly('route.otp'),
    lockedId: Ember.computed.readOnly('id'),
    stopPoints: Ember.computed.alias('route.stopPoints'),
    // used for pullout and pullin
    plannedStartTime: Ember.computed.alias('latestRouteVehicleDriver.startTime'),
    plannedEndTime: Ember.computed.alias('latestRouteVehicleDriver.endTime'),
    startGarage: Ember.computed.alias('assignedVehicle.startGarage'),
    endGarage: Ember.computed.alias('assignedVehicle.endGarage'),
    assignedDriverPhoneNumber: Ember.computed.alias('latestRouteVehicleDriver.driverPhoneNumber'),
    assignedDriver: Ember.computed.alias('latestRouteVehicleDriver.driver'),
    orderedClusters: Ember.computed.sort('clusters', 'orderedClustersSortKeys'),
    isEmptyRoute: Ember.computed.empty('clusters'),

    /**
     * /**
      * @param {Object} param - stop point model represents the row the user clicked on.
      * @return {Array} - returns all unperformed stops between the pullout garage and k stops
    */
    findUnperformedStopsInSequence(stopPoint) {
      const routeOrdinal = stopPoint.get('realRouteOrdinal');
      const orderedStopsWithGaragesAndBreaks = this.get('orderedStopsWithGaragesAndBreaks') || [];
      const stops = orderedStopsWithGaragesAndBreaks.filter(stop => {
        if (!stop.get('isPerformed') && !stop.get('isBreakPerformed') && !stop.get('isGarage') && stop.get('realRouteOrdinal') > 0 && stop.get('realRouteOrdinal') <= routeOrdinal) {
          return stop;
        }
      });
      return stops;
    },

    // get unperformed Stops in Sequence after ordinal param
    getUnperformedStopsInSequence(ordinal) {
      const orderedStopsWithBreaks = this.get('orderedStopsWithBreaks') || [];
      const stops = orderedStopsWithBreaks.filter(stop => {
        if (!stop.get('isPerformed') && stop.get('routeOrdinal') > 0 && stop.get('routeOrdinal') > ordinal) {
          return stop;
        }
      });
      return stops;
    },

    // find a place to insert a driver break based on the requested time
    // and duration
    findNewBreakRoutePosition(driverBreak) {
      const estimatedStartTime = (0, _moment.default)(driverBreak.get('requestTime'));
      const estimatedEndTime = (0, _moment.default)(driverBreak.get('projectedEndDate'));
      const orderedStopsWithBreaks = this.get('orderedStopsWithBreaks') || [];
      const lastIndex = orderedStopsWithBreaks.length - 1;
      let routeOrdinal = -1;
      let onBoardCount = 0;
      orderedStopsWithBreaks.forEach((currentStop, currentIndex) => {
        const nextIndex = currentIndex + 1;
        const nextStop = orderedStopsWithBreaks[nextIndex];
        const isLastStop = currentIndex === lastIndex;
        const isFirstStop = currentIndex === 0;
        const stopStartTime = (0, _moment.default)(currentStop.get('computedStartTime')); // if break end time is before the first stop

        if (isFirstStop) {
          if (estimatedEndTime.isBefore(stopStartTime)) {
            routeOrdinal = 1;
          }
        } // continue if we have not found a spot


        if (routeOrdinal === -1) {
          if (isLastStop) {
            // if it is the last stop and requested time is after the current stop
            if (estimatedStartTime.isAfter(stopStartTime)) {
              routeOrdinal = currentStop.get('routeOrdinal') + 1;
            }
          } else {
            if (currentStop.stopType === 'pick') {
              onBoardCount++;
            }

            if (currentStop.stopType === 'drop') {
              onBoardCount--;
            }

            const stopEndTime = (0, _moment.default)(nextStop.get('computedStartTime')); // if there are no passenger on board and the break range fits between
            // the current stop and the next

            if (onBoardCount === 0 && estimatedStartTime.isBetween(stopStartTime, stopEndTime) && estimatedEndTime.isBetween(stopStartTime, stopEndTime)) {
              routeOrdinal = currentStop.get('routeOrdinal') + 1;
            }
          }
        }
      });
      return routeOrdinal;
    },

    allStopsPerformed() {
      let orderedStops = this.get('orderedStops');
      if (!orderedStops) return false;
      orderedStops = orderedStops.filterBy('isPickOrDrop'); // empty route

      if (!orderedStops || orderedStops.length === 0) return false;
      const allStopsPerformed = orderedStops.every(stop => stop.get('isPerformed') || stop.get('isNoShow'));
      return allStopsPerformed;
    },

    navigationPolyline: Ember.computed('vehiclePolyline', 'orderedStops.@each.isPerformed', 'assignedVehicle.{lat,lng}', 'computedStatus', function () {
      const computedStatus = this.get('computedStatus'); // hide navigation polyline once the route has ended

      if (computedStatus === ROUTE_COMPLETED_STATUS) return null;
      const vehiclePolyline = (0, _unwrapProxy.unwrapProxy)(this.get('vehiclePolyline'));
      const lat = this.get('assignedVehicle.lat');
      const lng = this.get('assignedVehicle.lng'); // return navigation polyline as driver app is in use

      if (Ember.isPresent(lat) && Ember.isPresent(lng)) {
        return vehiclePolyline;
      } // edge case dispatcher performs stops for driver app or the driver app never sends actual gps pings
      // use stop polyline to give the dispatch an indication of the driver's direction


      const pulloutGarageStop = this.get('pulloutGarageStop');
      const orderedStops = this.get('orderedStops');
      const currentPerformedStop = (0, _lodash.findLast)(orderedStops, stop => stop.get('isPerformed'));
      let stopPolyline = Ember.isPresent(currentPerformedStop) ? (0, _unwrapProxy.unwrapProxy)(currentPerformedStop.get('polyline')) : null; // started but none of the stops have been performed use garage pullout polyline

      if (!stopPolyline) {
        stopPolyline = Ember.isPresent(pulloutGarageStop) ? (0, _unwrapProxy.unwrapProxy)(pulloutGarageStop.get('polyline')) : null;
      }

      return Ember.isPresent(stopPolyline) ? stopPolyline : vehiclePolyline;
    }),
    isEmergency: Ember.computed('assignedVehicle.lastEmergencyMessage.{isEmergency,isAcknowledged}', function () {
      const vehicle = this.get('assignedVehicle');
      return vehicle ? vehicle.get('lastEmergencyMessage.isEmergency') || vehicle.get('lastEmergencyMessage.isAcknowledged') : false;
    }),
    isLocked: Ember.computed('dispatchRouteLock.{isLocked,owner.firstName}', function () {
      return this.get('dispatchRouteLock.isLocked');
    }),
    lockedBy: Ember.computed('dispatchRouteLock.owner.firstName', function () {
      return `${this.get('dispatchRouteLock.owner.firstName')} ${this.get('dispatchRouteLock.owner.lastName')}`;
    }),
    isLockedByOtherUser: Ember.computed('dispatchRouteLock.isLocked', function () {
      const userId = this.get('session').data.authenticated.userId;
      return this.get('dispatchRouteLock.isLocked') && this.get('dispatchRouteLock.owner.id') !== userId;
    }),
    pullinGarageStop: Ember.computed('stopPoints.[]', function () {
      const stopPoints = this.get('stopPoints');
      if (!stopPoints) return null;
      return stopPoints.find(stop => stop.get('stopType') === 'pullinGarage');
    }),
    pulloutGarageStop: Ember.computed('stopPoints.[]', function () {
      const stopPoints = this.get('stopPoints');
      if (!stopPoints) return null;
      return stopPoints.find(stop => stop.get('stopType') === 'pulloutGarage');
    }),
    orderedStops: Ember.computed('orderedClusters.@each.orderedStops', function () {
      const orderedClusters = this.get('orderedClusters');
      if (Ember.isEmpty(orderedClusters)) return null;
      return orderedClusters.reduce((arr, cluster) => {
        let stops = cluster.get('orderedStops') || [];
        stops = stops.filter(stop => stop.get('parentType') === 'trip' || stop.get('parentType') === 'break');
        arr.push(...stops);
        return arr;
      }, []).sortBy('routeOrdinal');
    }),
    firstUnperformedStop: Ember.computed('orderedStops', 'orderedStops.@each.status', function () {
      const orderedStops = this.get('orderedStops');
      if (Ember.isEmpty(orderedStops)) return null;
      return orderedStops.find(stop => {
        if (stop.get('isPickOrDrop')) {
          return Ember.isEmpty(stop.get('status'));
        }

        return false;
      });
    }),
    lastPerformedStop: Ember.computed('orderedStops', 'orderedStops.@each.computedStatus', function () {
      const stops = this.get('orderedStops'); //const stopsWithBreaks = this.get('orderedStopsAndBreaks');

      const order = [];
      if (Ember.isEmpty(stops)) return null;
      stops.filter(function (stop) {
        if (stop.get('computedStatus') === 'Depart' || stop.get('computedStatus') === 'Arrive' || stop.get('computedStatus') === 'OffBreak') {
          order.push(stop.get('id'));
        }
      });
      order.pop();
      return order;
    }),
    lastDropStop: Ember.computed('orderedStopsWithGaragesAndBreaks', function () {
      const stops = this.get('orderedStopsWithGaragesAndBreaks');
      if (Ember.isEmpty(stops)) return null;
      const stopLength = stops.length - 1;
      return stops[stopLength - 1]; // stop before garage pullin
    }),
    orderedStopsWithGarages: Ember.computed('orderedClusters.@each.orderedStops', function () {
      const pulloutGarage = this.get('pulloutGarageStop');
      const pullinGarage = this.get('pullinGarageStop');
      const orderedClusters = this.get('orderedClusters');
      const stops = pulloutGarage ? [pulloutGarage] : [];
      if (Ember.isEmpty(orderedClusters)) return null;
      const ordered = orderedClusters.reduce((arr, cluster) => {
        let orderedStops = cluster.get('orderedStops');
        orderedStops = orderedStops.filter(stop => stop.get('parentType') === 'trip');
        arr.push(...orderedStops);
        return arr;
      }, stops);

      if (pullinGarage) {
        ordered.push(pullinGarage);
      }

      return ordered;
    }),
    orderedStopsWithBreaks: Ember.computed('orderedClusters.@each.orderedStops', function () {
      const breaks = [];
      const orderedClusters = this.get('orderedClusters');
      if (Ember.isEmpty(orderedClusters)) return null;
      const stopsWithBreaks = orderedClusters.reduce((arr, cluster) => {
        const stops = cluster.get('orderedStops');
        arr.push(...stops);
        return arr;
      }, breaks);
      return (0, _lodash.orderBy)(stopsWithBreaks, stop => {
        return stop.get('realRouteOrdinal');
      }, ['asc']);
    }),
    isSortableByETA: Ember.computed('route.{etaRoute.etaStops.@each.eta,routeBreaks.etaNavRouteBreak.@each.eta}', function () {
      const etaStops = this.get('route.etaRoute.etaStops.content') || [];
      const etaBreakStops = this.get('route.routeBreaks.etaNavRouteBreak.content') || [];
      const hasETAs = etaStops.every(stop => Ember.isPresent(stop.get('eta')));
      const hasBreakETAs = etaBreakStops.every(stop => Ember.isPresent(stop.get('eta')));
      return hasETAs && hasBreakETAs;
    }),
    orderedStopsWithGaragesAndBreaks: Ember.computed('isSortableByETA', 'orderedStopsWithBreaks.@each.realRouteOrdinal', function () {
      const isSortableByETA = this.get('isSortableByETA');
      const pulloutGarage = this.get('pulloutGarageStop');
      const pullinGarage = this.get('pullinGarageStop');
      const stopsWithBreaks = this.get('orderedStopsWithBreaks');
      const sortKey = isSortableByETA ? 'computedEta' : 'realRouteOrdinal';
      const orderedEtaStops = pulloutGarage ? [pulloutGarage] : [];

      if (stopsWithBreaks) {
        orderedEtaStops.push(...stopsWithBreaks);
      }

      if (pullinGarage) {
        const lastOrdinal = orderedEtaStops.get('lastObject.routeOrdinal');
        pullinGarage.set('routeOrdinal', lastOrdinal + 1);
        orderedEtaStops.push(pullinGarage);
      }

      return orderedEtaStops.sortBy(sortKey);
    }),
    trips: Ember.computed('orderedStopsWithGaragesAndBreaks', function () {
      const stops = this.get('orderedStopsWithGaragesAndBreaks');
      if (Ember.isEmpty(stops)) return null;
      const orderedPickStops = stops.filterBy('isPick');
      return orderedPickStops.map(pick => (0, _unwrapProxy.unwrapProxy)(pick.get('trip')));
    }),
    unPerformedTrips: Ember.computed('trips', function () {
      const trips = this.get('trips') || [];
      return trips.filter(trip => trip.get('pickStopPoint.notPerformed') && trip.get('dropStopPoint.notPerformed'));
    }),
    plannedPolylinePoints: Ember.computed('orderedStopsWithGarages.@each.polylinePoints', function () {
      const stopPoints = this.get('orderedStopsWithGarages');
      if (Ember.isEmpty(stopPoints)) return []; // this returns an array of arrays; it _could_ be flattened, but tomtom
      // is ok with the data in this format, and this allows for discontiguity

      return stopPoints.reduce((arr, stopPoint) => {
        const points = stopPoint.get('polylinePoints');
        if (Ember.isEmpty(points)) return arr;
        arr.push(points);
        return arr;
      }, []);
    }),
    currentStopOrdinalId: Ember.computed('orderedStopsWithGaragesAndBreaks.@each.status', function () {
      const orderedStops = this.get('orderedStopsWithGaragesAndBreaks');
      if (!orderedStops) return null;

      for (let i = 0; i < orderedStops.length; i++) {
        const status = orderedStops[i].get('computedStatus');

        if (!status) {
          return orderedStops[i].get('id');
        }
      }

      return null;
    }),
    scheduleLocked: Ember.computed('dispatchSchedule.locked', function () {
      return this.get('dispatchSchedule.locked');
    }),
    latestRouteVehicleDriver: Ember.computed('routeVehicleDrivers.@each.startTime', function () {
      const routeVehicleDrivers = (0, _unwrapProxy.unwrapProxy)(this.get('routeVehicleDrivers'));
      if (!routeVehicleDrivers) return null;
      return routeVehicleDrivers.sortBy('startTime').get('lastObject');
    }),
    assignedVehicle: Ember.computed('latestRouteVehicleDriver.vehicle', 'route.vehicle', function () {
      const rvdVehicle = this.get('latestRouteVehicleDriver.vehicle');

      if ((0, _unwrapProxy.unwrapProxy)(rvdVehicle)) {
        return rvdVehicle;
      }

      return this.get('route.vehicle');
    }),
    actualDriverBreaks: Ember.computed('assignedVehicle.shiftBreaks.@each.{start,end}', function () {
      const driverBreaks = Ember.get(this, 'assignedVehicle.shiftBreaks');
      const driverBreaksTaken = [];

      if (!Ember.isEmpty(driverBreaks)) {
        const completedDriverBreaks = (0, _lodash.filter)(driverBreaks.toArray(), driverBreakEvent => {
          return !Ember.isEmpty(driverBreakEvent.get('end'));
        });
        completedDriverBreaks.forEach(function (item) {
          driverBreaksTaken.push((0, _formatTextExtension.formatTextExtension)('valueItem', `${item.get('breakType.name')}: ${item.get('start')} - ${item.get('end')}`));
        });
      }

      return {
        values: driverBreaksTaken
      };
    }),
    plannedDriverBreaks: Ember.computed('assignedVehicle.shiftBreaks.@each,{estimatedStart,estimatedEnd}', function () {
      const driverBreaks = [];
      const shiftBreaks = this.get('assignedVehicle.shiftBreaks');

      if (!Ember.isEmpty(shiftBreaks)) {
        shiftBreaks.forEach(function (item) {
          driverBreaks.push((0, _formatTextExtension.formatTextExtension)('valueItem', `${item.get('breakType.name')}: ${item.get('estimatedStart')} - ${item.get('estimatedEnd')}`));
        });
      }

      return {
        values: driverBreaks
      };
    }),
    tripStartTime: Ember.computed('clusters.@each.tripStartTime', 'plannedStartTime', function () {
      const clusters = this.get('clusters').toArray();
      let min = this.get('plannedStartTime') ? this.get('plannedStartTime').getTime() : null;

      if (!Ember.isEmpty(clusters)) {
        clusters.forEach(cluster => {
          if (cluster.get('tripStartTime')) {
            min = Math.min(cluster.get('tripStartTime').getTime(), min);
          }
        });
      }

      return min ? new Date(min) : null;
    }),
    tripEndTime: Ember.computed('clusters.@each.tripEndTime', 'plannedEndTime', function () {
      const clusters = this.get('clusters').toArray();
      let max = this.get('plannedEndTime') ? this.get('plannedEndTime').getTime() : null;

      if (!Ember.isEmpty(clusters)) {
        clusters.forEach(cluster => {
          if (cluster.get('tripEndTime')) {
            max = Math.max(cluster.get('tripEndTime').getTime(), max);
          }
        });
      }

      return max ? new Date(max) : null;
    }),
    maxTravelNeedCounts: Ember.computed('orderedStops', function () {
      const initialCounts = {
        wheelchair: 0,
        wideWheelchair: 0,
        ambulatory: 0,
        wideAmbulatory: 0,
        serviceAnimal: 0
      };
      const maxCounts = {
        wheelchair: 0,
        wideWheelchair: 0,
        ambulatory: 0,
        wideAmbulatory: 0,
        serviceAnimal: 0
      };
      const orderedStops = this.get('orderedStops');
      if (Ember.isEmpty(orderedStops)) return null;
      orderedStops.forEach(stopPoint => {
        const travelNeedCounts = stopPoint.get('trip.travelNeedCounts');
        const stopType = stopPoint.get('stopType');
        if (Ember.isEmpty(travelNeedCounts)) return maxCounts;

        if (stopType === 'pick') {
          Object.entries(travelNeedCounts).forEach(([travelNeedTypeName]) => {
            initialCounts[travelNeedTypeName] += travelNeedCounts[travelNeedTypeName];
          });
        } else if (stopType === 'drop') {
          Object.entries(travelNeedCounts).forEach(([travelNeedTypeName]) => {
            initialCounts[travelNeedTypeName] -= travelNeedCounts[travelNeedTypeName];
          });
        }

        Object.entries(initialCounts).forEach(([travelNeedTypeName, count]) => {
          maxCounts[travelNeedTypeName] = Math.max(maxCounts[travelNeedTypeName], count);
        });
      });
      return maxCounts;
    }),
    status: Ember.computed.readOnly('computedStatus'),
    // keep status field untill it's uneeded
    //Using if/else statements to allow multiple possible scenarios
    computedStatus: Ember.computed('isEmergency', 'assignedDriver', 'assignedVehicle.vehicleStatus', 'route.{actualStartTimestamp,actualEndTimestamp,status}', 'latestRouteVehicleDriver.avlmDriverStateEvent.isSignedOn', function () {
      const currentStatus = this.get('route.status');
      const vehicleStatus = this.get('assignedVehicle.vehicleStatus');
      const driver = (0, _unwrapProxy.unwrapProxy)(this.get('assignedDriver'));
      const vehicle = (0, _unwrapProxy.unwrapProxy)(this.get('assignedVehicle'));
      const start = this.get('route.actualStartTimestamp');
      const end = this.get('route.actualEndTimestamp');
      const emergency = this.get('isEmergency');
      const signedOn = this.get('latestRouteVehicleDriver.avlmDriverStateEvent.isSignedOn');

      if (emergency) {
        return ROUTE_EMERGENCY_STATUS;
      } else if (vehicleStatus === _vehicle.VEHICLE_BREAKDOWN_STATUS) {
        return ROUTE_BREAKDOWN_STATUS;
      } else if (!driver && vehicle) {
        return ROUTE_NO_DRIVER_STATUS;
      } else if (signedOn && !start) {
        return ROUTE_SIGNED_ON_STATUS;
      } else if (currentStatus === 'Not Started' && !start) {
        return ROUTE_NOT_STARTED_STATUS;
      } else if (currentStatus === 'Pullout' && start && !end) {
        return ROUTE_STARTED_STATUS;
      } else if ((currentStatus === 'Arrive' || currentStatus === 'OffBreak' || currentStatus === 'Depart') && start && !end) {
        return ROUTE_IN_PROGRESS_STATUS;
      } else if (currentStatus === 'OnBreak') {
        return ROUTE_ON_BREAK_STATUS;
      } else if (end || currentStatus === 'Complete' || currentStatus === 'Pullin') {
        return ROUTE_COMPLETED_STATUS;
      } else if (currentStatus === 'normal' && driver && vehicle && !vehicle.placeholder) {
        return ROUTE_DISPATCHED_STATUS;
      }

      return ROUTE_AWAITING_STATUS; //Default case for anomalies
    })
  });

  _exports.default = _default;
});