define("adept-iq/pods/components/header-nav/component", ["exports", "moment", "adept-iq/config/mapped-permIds", "adept-iq/config/environment"], function (_exports, _moment, _mappedPermIds, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import RoadSupervisorApi from 'adept-iq/classes/road-supervisor-api';
  const showWorkDiagnostics = localStorage.showWorkDiagnostics ? localStorage.showWorkDiagnostics === 'true' : _environment.default.work.showDiagnostics;

  var _default = Ember.Component.extend({
    editModal: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    scheduleGeneration: Ember.inject.service(),
    notifications: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    stopsReorder: Ember.inject.service(),
    store: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    roadSupervisor: Ember.inject.service(),
    interaction: Ember.inject.service(),
    work: Ember.inject.service(),
    user: Ember.inject.service(),
    classNames: ['row', 'no-gutters', 'header-wrapper', 'header-nav'],
    classNameBindings: ['isLightMode:is-light:is-dark', 'isReordering'],
    dashboards: null,
    transitionTo: null,
    configureWorkspaceAllowed: false,
    saveWorkspaceAllowed: false,
    saveAsWorkspaceAllowed: false,
    deleteWorkspaceAllowed: false,
    openWorkspaceAllowed: false,
    generateScheduleAllowed: false,
    closeScheduleAllowed: false,
    systemConfigurationAllowed: false,
    resetPasswordAllowed: false,
    aboutAdeptIQAllowed: true,
    logoutAllowed: true,
    avlmLite: false,
    clearButtonEnabled: Ember.computed.gt('activeContext.topActiveContext.checkedItems.length', 0),
    isReordering: Ember.computed.alias('stopsReorder.reorderInProgress'),
    isEditing: Ember.computed.alias('workspace.isEditing'),
    isLightMode: Ember.computed.alias('workspace.isLightMode'),
    isAuthenticated: Ember.computed.alias('session.isAuthenticated'),
    isHidden: Ember.computed.alias('stopsReorder.isHidden'),
    showWorkDiagnostics,

    didInsertElement() {
      this._super(...arguments); // exposes console commands `showDiagnostics()` and `hideDiagnostics()`
      // to view queue states; setting persists in `localStorage` for reload


      window.showDiagnostics = () => {
        this.set('showWorkDiagnostics', true);
        localStorage.showWorkDiagnostics = true;
      };

      window.hideDiagnostics = () => {
        this.set('showWorkDiagnostics', false);
        localStorage.showWorkDiagnostics = false;
      }; //this.$('.ember-modal-dialog').draggable();

    },

    didRender() {
      this._super(...arguments);

      this.$('.ember-modal-dialog').draggable();
    },

    configPermissionsChange: Ember.observer('workspace.configPermissions', 'permissionLayer.userPermissionHash', function () {
      this.resetHeaderFunctions();
      this.configureHeaderPermission();
    }),
    oneDayTimelineNotFuture: Ember.computed('workspace.{startDate,endDate}', function () {
      const startDate = (0, _moment.default)(this.get('workspace.startDate'));
      const endDate = (0, _moment.default)(this.get('workspace.endDate'));
      const currentDate = (0, _moment.default)().startOf('day');

      if (startDate.format('YYYY-MMM-D') !== endDate.format('YYYY-MMM-D')) {
        return false;
      }

      if (endDate.startOf('day').isAfter(currentDate)) {
        return false;
      }

      const routes = _environment.default.APP.avlmLite ? this.get('activeContext.workspaceData.avlm-route') : this.get('activeContext.workspaceData.route'); // Only 1 date is selected by this point
      // make sure that the schedule is not locked already.

      if (routes) {
        if (routes.some(route => {
          return route.get('schedule.locked');
        })) {
          return false;
        }
      }

      return true;
    }),

    init() {
      this._super(...arguments);

      this.configureHeaderPermission();
    },

    resetHeaderFunctions() {
      this.set('configureWorkspaceAllowed', false);
      this.set('saveWorkspaceAllowed', false);
      this.set('saveAsWorkspaceAllowed', false);
      this.set('deleteWorkspaceAllowed', false);
      this.set('openWorkspaceAllowed', false);
      this.set('generateScheduleAllowed', false);
      this.set('closeScheduleAllowed', false);
      this.set('systemConfigurationAllowed', false);
      this.set('resetPasswordAllowed', false);
      this.set('aboutAdeptIQAllowed', true);
      this.set('logoutAllowed', true);
    },

    configureHeaderPermission() {
      const configPermissions = this.get('workspace.configPermissions');

      if (!Ember.isNone(configPermissions)) {
        const headerFunctions = configPermissions.headerFunctions;
        const timelineFunction = configPermissions.timeline;

        if (!Ember.isNone(timelineFunction)) {
          if (timelineFunction[0] === 'fullAccess') {
            this.set('timeline', true && this.get('permissionLayer').permInUserHash(_mappedPermIds.default.timeline, null));
          }
        }

        if (!Ember.isNone(headerFunctions)) {
          headerFunctions.forEach(headerFunction => {
            if (headerFunction === 'all') {
              this.set('configureWorkspaceAllowed', true && this.get('permissionLayer').permInUserHash(_mappedPermIds.default.configureWorkspace, null));
              this.set('saveWorkspaceAllowed', true && this.get('permissionLayer').permInUserHash(_mappedPermIds.default.saveWorkspace, null));
              this.set('saveAsWorkspaceAllowed', true && this.get('permissionLayer').permInUserHash(_mappedPermIds.default.saveAsWorkspace, null));
              this.set('deleteWorkspaceAllowed', true && this.get('permissionLayer').permInUserHash(_mappedPermIds.default.deletePublicWorkspace, null));
              this.set('openWorkspaceAllowed', true && this.get('permissionLayer').permInUserHash(_mappedPermIds.default.openWorkspace, null));
              this.set('generateScheduleAllowed', true && this.get('permissionLayer').permInUserHash(_mappedPermIds.default.generateSchedule, null));
              this.set('closeScheduleAllowed', true && this.get('permissionLayer').permInUserHash(_mappedPermIds.default.closeSchedule, null));
              this.set('systemConfigurationAllowed', true && this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showSystemConfig, null));
              this.set('aboutAdeptIQAllowed', true);
              this.set('resetPasswordAllowed', false);
              this.set('logoutAllowed', true);
            } else {
              const allowedFunctionName = headerFunction + 'Allowed';
              let permGranted = false;

              switch (headerFunction) {
                case 'openWorkspace':
                  permGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.openWorkspace, null);
                  break;

                case 'configureWorkspace':
                  permGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.configureWorkspace, null);
                  break;

                case 'saveWorkspace':
                  permGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.saveWorkspace, null);
                  break;

                case 'saveAsWorkspace':
                  permGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.saveAsWorkspace, null);
                  break;

                case 'deleteWorkspace':
                  permGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.deletePublicWorkspace, null);
                  break;

                case 'generateSchedule':
                  permGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.generateSchedule, null);
                  break;

                case 'closeSchedule':
                  permGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.closeSchedule, null);
                  break;

                case 'systemConfiguration':
                  permGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showSystemConfig, null);
                  break;

                case 'resetPassword':
                  permGranted = false;
                  break;

                case 'logout':
                  permGranted = true;
                  break;

                default:
                  permGranted = false;
              }

              this.set(allowedFunctionName, true && permGranted);
            }
          });
        }
      }
    },

    actions: {
      onClearButtonClick() {
        this.get('activeContext.topActiveContext').clearCheckedItems();
      },

      onCloseReorder() {
        this.get('stopsReorder').stopReorder();
      },

      onEditWorkspaceClick(dropdown) {
        const workspace = this.get('workspace');
        dropdown.actions.close();
        if (this.get('isEditing')) return;
        const displayName = 'Add Widgets';

        if (workspace.get('dashboard.role') === 'Playback') {
          workspace.pushState('editPlayback', {
            displayName
          }).then(() => {// Q: state pushed?
          }).catch(() => {// denied access
          });
        } else {
          workspace.pushState('editWorkspace', {
            displayName
          }).then(() => {// Q: state pushed?
          }).catch(() => {// denied access
          });
        }
      },

      onSaveWorkspaceClick(dropdown) {
        dropdown.actions.close();
        const dashboardModel = this.get('workspace.dashboardModel');

        if (dashboardModel.get('isDefaultWorkspace')) {
          const tooltip = this.get('tooltip');
          tooltip.setProperties({
            title: 'Unable to Save',
            tip: 'Unable to Save. You must use the "Save Workspace As" option to save changes made to a default workspace.',
            warning: true,
            primaryActionText: 'Ok',

            primaryAction() {
              tooltip.reset();
            }

          });
          return;
        }

        const dashboardInstance = this.get('workspace.dashboard');
        dashboardModel.commitInstance(dashboardInstance);
        /*eslint-disable */

        return dashboardModel.save();
        /*eslint-enable */
      },

      onSaveWorkspaceAsClick(dropdown) {
        dropdown.actions.close();
        this.get('workspace').pushState('saveWorkspaceAs');
      },

      onDeleteWorkspaceClick(dropdown) {
        dropdown.actions.close();
        const dashboardModel = this.get('workspace.dashboardModel');

        if (dashboardModel.get('isDefaultWorkspace') || dashboardModel.get('isPlaybackWorkspace')) {
          const tooltip = this.get('tooltip');
          tooltip.setProperties({
            title: 'Unable to delete',
            warning: true,
            tip: 'Unable to delete. Default workspaces cannot be deleted.',
            primaryActionText: 'Ok',

            primaryAction() {
              tooltip.reset();
            }

          });
          return;
        }

        this.get('workspace').pushState('deleteWorkspace');
      },

      onOpenWorkspaceClick(dropdown) {
        dropdown.actions.close();
        this.get('workspace').pushState('openWorkspace');
      },

      toggleTimelinePicker() {
        const workspace = this.get('workspace');
        workspace.toggleProperty('isEditingTimeline');
      },

      onGenerateScheduleClick(dropdown) {
        dropdown.actions.close();
        this.get('scheduleGeneration.task').perform();
      },

      toggleAboutAdeptIQ() {
        const workspace = this.get('workspace');
        workspace.toggleProperty('isShowAbout');
      },

      transitionToForgotPassword(dropdown, link) {
        dropdown.actions.close();
        this.transitionTo(link);
      },

      transitionToModalAndClose(dropdown, modal) {
        dropdown.actions.close();
        this.transitionTo(`index.modals.${modal}`);
      },

      toggleLayoutType(dropdown) {
        dropdown.actions.close();
        this.toggleProperty('isLightMode');
      },

      onEditApplyCancel() {
        this.get('editModal').cancel();
      },

      onEditApplyCommit() {
        this.get('editModal').commit();
      },

      onCloseScheduleClick(dropdown) {
        dropdown.actions.close();
        this.get('workspace').pushState('closeSchedule');
      },

      onSystemConfigurationClick(dropdown) {
        dropdown.actions.close();
        this.get('workspace').pushState('editSystemConfiguration', {
          displayName: 'System Configuration'
        });
      },

      onLogout(dropdown) {
        dropdown.actions.close();
        this.onLogout();
      }

    }
  });

  _exports.default = _default;
});