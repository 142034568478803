define("adept-iq/utils/availability", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.solveAvailability = void 0;
  // TODO: change this to UTC when NY data is correct
  const DEFAULT_SYSTEM_TZ = 'America/Los_Angeles'; // TODO: get from config service (or change EST when NY data is correct)
  // const DEFAULT_USER_TZ = 'America/Los_Angeles';
  // determines if a set of availabilities covers an arbitrary time window
  //
  // The old strategy here broke the window down into per-day segments and then attempted to apply availability to each day (but had trouble with supporting multiple availabilities covering a timerange)
  // The new strategy here is similar... break the window down into per-day segments... but after that, mark parts of each day (or partial day) as matched by a particular availability.
  //   After iterating over all the availabilities, all the time segments should have been converted into one or more partial-day segments that can be covered by a specific availability.
  //   A solution is one that has no remaining un-matched segments.

  const solveAvailability = (start, end, availabilities, opts = {}) => {
    // Need time zone because availabilities have time fields that do not have a timezone, and specify days of the week (each of which start at midnight in the local timezone)
    //  As a result, we'll just use system time for everything
    const systemTz = opts.systemTz || DEFAULT_SYSTEM_TZ;
    const startTz = start.clone().tz(systemTz);
    const endTz = end.clone().tz(systemTz); // Array that will start with the per-day time ranges and will get modified as we go

    let intervals = []; // Initially populate the array with one element per system-timezone-day

    let intervalStart = startTz;

    do {
      const intervalStartPlusOneDay = intervalStart.clone().add('1', 'day').startOf('day');
      const intervalEnd = endTz.isBefore(intervalStartPlusOneDay) ? endTz : intervalStartPlusOneDay;
      intervals.push({
        start: intervalStart,
        end: intervalEnd,
        matchingAvailability: null
      });
      intervalStart = intervalEnd;
    } while (intervalStart.isBefore(endTz)); // Now iterate over each availability to update the interval list


    availabilities.forEach(availability => {
      const modifiedIntervals = []; // For each interval in the list, we will do one of a few things:
      // * If there's no overlap between the availability and the interval, copy the interval to the "modified" array without modifications
      // * If there's 100% overlap between the availalbility and the interval, copy the interval to the "modified" array, but mark the matchingAvailability
      // * If the availability matches the beginning or the end, create two new intervals, one of which will be marked as matching this availalility
      // * If the availability is within the interval, then create three new intervals where the start and end intervals are unmatched, and the middle one is matched.

      intervals.forEach(interval => {
        // Only need to process this interval if it hasn't been matched yet
        if (!interval.matchingAvailability) {
          // get the day-of-the-week
          const intervalWeekdayName = interval.start.format('dddd').toLowerCase();
          const availabilityStart = (0, _moment.default)(availability.get('startTime'));
          const availabilityEnd = (0, _moment.default)(availability.get('endTime'));

          if (!availability.get(intervalWeekdayName) || availabilityStart.isAfter(interval.end) || availabilityEnd.isBefore(interval.start)) {
            modifiedIntervals.push(interval);
          } else {
            const intervalStartOfDay = interval.start.startOf('day');

            const availabilityStartAsDuration = _moment.default.duration(availability.get('shiftStart'));

            const availabilityEndAsDuration = _moment.default.duration(availability.get('shiftEnd'));

            if (availabilityStartAsDuration.milliseconds() === availabilityEndAsDuration.milliseconds()) {
              availabilityEndAsDuration.add(1, 'day');
            }

            const actualAvailabilityStart = _moment.default.max(intervalStartOfDay.clone().add(availabilityStartAsDuration), availabilityStart);

            const actualAvailabilityEnd = _moment.default.min(intervalStartOfDay.clone().add(availabilityEndAsDuration), availabilityEnd);

            if (actualAvailabilityEnd.isBefore(interval.start) || actualAvailabilityStart.isAfter(interval.end)) {
              modifiedIntervals.push(interval);
            } else {
              // If the availability starts after the start of the interval, we'll need to create an unmatched-interval record
              if (actualAvailabilityStart.isAfter(interval.start)) {
                modifiedIntervals.push({
                  start: interval.start,
                  end: actualAvailabilityStart,
                  matchingAvailability: null
                }); // overwrite the interval start with the start of the availability to make the next part easier to calculate

                interval.start = actualAvailabilityStart;
              }

              if (actualAvailabilityEnd.isAfter(interval.end)) {
                // this interval is covered by this availability (note that it could be just part of the interval after the beginning was examined)
                interval.matchingAvailability = availability;
                modifiedIntervals.push(interval);
              } else {
                // Need to push two intervals -- the first one is matched by this availability, and the second is still unmatched
                modifiedIntervals.push({
                  start: interval.start,
                  end: actualAvailabilityEnd,
                  matchingAvailability: availability
                });
                interval.start = actualAvailabilityEnd;
                modifiedIntervals.push(interval);
              }
            }
          }
        }
      }); // Now at least one record has been added to modifiedIntervals for each interval.  Swap it for the next availability to work on

      intervals = modifiedIntervals;
    }); // Now if there are any intervals without a matching availability, return null (indicating no solution)

    if (intervals.some(interval => !interval.matchingAvailability)) {
      return null;
    } // To make it clean, merge adjacent intervals that are satisfied by the same availability


    const reducedIntervals = intervals.reduce((reducedArray, currentInterval) => {
      if (reducedArray.length === 0 || reducedArray.lastObject.matchingAvailability !== currentInterval.matchingAvailability) {
        reducedArray.push(currentInterval);
      } else {
        reducedArray.lastObject.end = currentInterval.end;
      }

      return reducedArray;
    }, []);
    return reducedIntervals;
  };

  _exports.solveAvailability = solveAvailability;
});