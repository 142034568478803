define("adept-iq/classes/active-contexts/schedule/map-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.mapContextModels = _exports.mapContextGraph = _exports.mapContextNodes = _exports.ssRouteBreakNode = _exports.ssTripStopNode = _exports.ssTripNode = _exports.ssRouteNode = _exports.zoneNode = _exports.vehicleNode = _exports.driverNode = _exports.riderNode = void 0;
  // Rider Managament Related Nodes
  const riderNode = {
    id: 'rider',
    modelName: 'rider',
    links: [{
      type: 'hasMany',
      nodeId: 'ss-trip',
      path: 'trips'
    }],
    providerFilterKey: 'providers'
  }; // Driver Related Nodes

  _exports.riderNode = riderNode;
  const driverNode = {
    id: 'driver',
    modelName: 'driver',
    links: [{
      type: 'belongsTo',
      nodeId: 'vehicle',
      path: 'latestVehicle'
    }],
    providerFilterKey: 'provider'
  }; // Vehicle Related Nodes

  _exports.driverNode = driverNode;
  const vehicleNode = {
    id: 'vehicle',
    modelName: 'vehicle',
    links: [],
    providerFilterKey: 'provider'
  }; // Zone Related Nodes

  _exports.vehicleNode = vehicleNode;
  const zoneNode = {
    id: 'zone',
    modelName: 'zone',
    links: [],
    providerFilterKey: false
  }; // Scheduling Related Nodes

  _exports.zoneNode = zoneNode;
  const ssRouteNode = {
    id: 'ss-route',
    modelName: 'route',
    links: [{
      type: 'hasMany',
      nodeId: 'ss-trip',
      path: 'trips'
    }, {
      type: 'hasMany',
      nodeId: 'ss-route-break',
      path: 'routeBreaks'
    }],
    isActive: true,
    providerFilterKey: 'provider',
    leftTimeConstraint: 'plannedStartTime',
    rightTimeConstraint: 'plannedEndTime'
  };
  _exports.ssRouteNode = ssRouteNode;
  const ssTripNode = {
    id: 'ss-trip',
    modelName: 'trip',
    links: [{
      type: 'hasMany',
      nodeId: 'ss-trip-stop',
      path: 'stops'
    }],
    isActive: true,
    providerFilterKey: 'pickStopPoint.dispatchRoute.provider',
    leftTimeConstraint: 'routeTripStartTime',
    rightTimeConstraint: 'routeTripEndTime'
  };
  _exports.ssTripNode = ssTripNode;
  const ssTripStopNode = {
    id: 'ss-trip-stop',
    modelName: 'trip-stop',
    links: [],
    isActive: true,
    providerFilterKey: 'dispatchRoute.provider',
    leftTimeConstraint: 'routeTripStartTime',
    rightTimeConstraint: 'routeTripEndTime'
  };
  _exports.ssTripStopNode = ssTripStopNode;
  const ssRouteBreakNode = {
    id: 'ss-route-break',
    modelName: 'route-break',
    links: [],
    isActive: true,
    providerFilterKey: 'dispatchRoute.provider',
    leftTimeConstraint: 'routeTripStartTime',
    rightTimeConstraint: 'routeTripEndTime'
  };
  _exports.ssRouteBreakNode = ssRouteBreakNode;
  const mapContextNodes = [// vehicle graph
  vehicleNode, // driver graph
  driverNode, // zone graph,
  zoneNode, // scheduling graph
  ssRouteNode, ssTripStopNode, ssTripNode, ssRouteBreakNode, // rider management graph
  riderNode];
  _exports.mapContextNodes = mapContextNodes;
  const mapContextGraph = mapContextNodes.reduce((obj, node) => {
    obj[node.id] = node;
    return obj;
  }, {});
  _exports.mapContextGraph = mapContextGraph;
  const mapContextModels = mapContextNodes.reduce((obj, node) => {
    obj[node.modelName] = node;
    return obj;
  }, {});
  _exports.mapContextModels = mapContextModels;
  var _default = mapContextGraph;
  _exports.default = _default;
});