define("adept-iq/pods/components/search-modal/table/component", ["exports", "adept-iq/pods/components/generic-components/table/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['route-master-table'],
    store: Ember.inject.service(),
    config: null,
    showHeader: false,

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    fetchDataQuery() {
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});