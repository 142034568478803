define("adept-iq/pods/register/route", ["exports", "fetch", "adept-iq/config/api-urls"], function (_exports, _fetch, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),

    model({
      invitationId
    }) {
      if (Ember.isBlank(invitationId)) return;
      return this.store.findRecord('invitation', invitationId).catch(e => {
        if (e.errors.firstObject.status !== '404') throw e;
      });
    },

    setupController(controller, model) {
      controller.set('data', {
        invitation: model
      });
    },

    makeRequest(url, options) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        (0, _fetch.default)(url, options).then(response => {
          response.json().then(body => {
            if (!response.ok) {
              reject(body);
            } else {
              resolve(body);
            }
          });
        }).catch(reject);
      });
    },

    actions: {
      register(userName, password) {
        const {
          host
        } = _apiUrls.API.ssoService;
        const url = host + '/signup';
        const data = {
          userName,
          password,
          displayName: userName
        };
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        };
        this.makeRequest(url, options).then(tokenPayload => {
          const session = this.get('session');
          const authenticator = 'authenticator:sso';
          session.authenticate(authenticator, userName, password, tokenPayload).then(() => {
            this.transitionTo('index');
          });
        }).catch(e => {
          this.set('controller.data.errors', [e.message]);
        });
      }

    }
  });

  _exports.default = _default;
});