define("adept-iq/pods/components/side-drawer/scheduling-trip-transfer/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.solutionPreviewColumns = void 0;
  const solutionPreviewColumns = [{
    label: 'Trip ID',
    valuePath: 'tripId',
    cellComponent: 'table/cells/base-cell',
    resizable: true,
    minResizeWidth: 50,
    width: '80px'
  }, {
    label: 'Last Name',
    valuePath: 'lastName',
    cellComponent: 'table/cells/base-cell',
    resizable: true,
    minResizeWidth: 50,
    width: '80px'
  }, {
    label: 'Address',
    valuePath: 'simpleAddress',
    cellComponent: 'table/cells/base-cell',
    minResizeWidth: 50,
    resizable: true,
    width: '80px'
  }, {
    label: 'City',
    valuePath: 'locality',
    cellComponent: 'table/cells/base-cell',
    minResizeWidth: 50,
    resizable: true,
    width: '60px'
  }, {
    label: 'Stop',
    valuePath: 'stopType',
    cellComponent: 'table/cells/base-cell',
    minResizeWidth: 50,
    resizable: true,
    width: '60px'
  }, {
    label: 'ETA',
    valuePath: 'plannedEta',
    cellComponent: 'table/cells/trip-eta',
    minResizeWidth: 50,
    resizable: true,
    width: '60px'
  }, {
    label: 'Promise Time',
    valuePath: 'computedPromiseTime',
    cellComponent: 'table/cells/date-cell',
    minResizeWidth: 50,
    resizable: true,
    format: 'HH:mm',
    width: '80px'
  }, {
    label: 'Appt Time',
    valuePath: 'computedApptTime',
    cellComponent: 'table/cells/date-cell',
    resizable: true,
    minResizeWidth: 50,
    format: 'HH:mm',
    width: '80px'
  }, {
    label: 'Stop Break',
    valuePath: 'plannedRouteOrdinal',
    cellComponent: 'table/cells/base-cell',
    minResizeWidth: 60,
    resizable: true,
    width: '80px'
  }];
  _exports.solutionPreviewColumns = solutionPreviewColumns;
});