define("adept-iq/serializers/stop-point", ["exports", "adept-iq/serializers/-dispatch"], function (_exports, _dispatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dispatch.default.extend({
    normalize(modelClass, resourceHash) {
      const normalized = this._super(modelClass, resourceHash);

      if (normalized.data.attributes.parentType === 'Break') {
        normalized.data.attributes.stopType = 'break';
      }

      return normalized;
    }

  });

  _exports.default = _default;
});