define("adept-iq/pods/components/side-drawer/map-filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    mapService: Ember.inject.service('map'),
    // map-context layers are ephemeral, so use `id` for selection state
    selectedLayerId: null,
    title: 'Map Layers',
    layers: Ember.computed.readOnly('mapService.topMapContext.layers'),
    selectedLayer: Ember.computed('layers', 'selectedLayerId', function () {
      const layerId = this.get('selectedLayerId');
      if (Ember.isNone(layerId)) return null;
      const layers = this.get('layers');
      if (Ember.isEmpty(layers)) return null;
      return layers.findBy('id', layerId);
    }),
    isAgencyLayer: Ember.computed.equal('selectedLayerId', 'agency'),
    disabled: Ember.computed.not('selectedLayerId'),

    didInsertElement() {
      this._super(...arguments);

      const layers = this.get('layers');
      if (Ember.isEmpty(layers)) return;
      this.selectLayer(layers[0]);
    },

    selectLayer(layer) {
      this.set('selectedLayerId', layer.id);
    },

    actions: {
      onLayerSelect(layer) {
        this.selectLayer(layer);
      },

      onLayerVisibilityCheck(event) {
        const layer = this.get('selectedLayer');
        this.get('mapService').setLayerVisibility(layer.id, event.target.checked);
      },

      onTransparencySliderChange(value) {
        const layer = this.get('selectedLayer');
        this.get('mapService').setLayerOpacity(layer.id, value);
      }

    }
  });

  _exports.default = _default;
});