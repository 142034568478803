define("adept-iq/tests/factories/segment", ["ember-data-factory-guy", "moment"], function (_emberDataFactoryGuy, _moment) {
  "use strict";

  _emberDataFactoryGuy.default.define('segment', {
    default: {
      promiseTime: (0, _moment.default)().startOf('day').add(1, 'day').add(8, 'hours').toDate(),
      anchor: 'pick',
      fare: 2,
      travelMode: 'bus',
      segmentType: 1,
      noSharing: false,
      legOrdinal: 1
    }
  });
});