define("adept-iq/adapters/trip-noshow", ["exports", "adept-iq/adapters/-dispatch"], function (_exports, _dispatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dispatch.default.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    createTripNoShow(trip, noshowReason) {
      const session = this.get('session');
      const url = `${this.host}/trip/${trip.get('id')}/no-show`;
      const dispatchSchedule = trip.get('schedule.dispatchSchedule');
      const json = {
        data: {
          id: trip.get('id'),
          type: 'noShow',
          attributes: {
            notes: trip.get('noshowDetails')
          },
          relationships: {
            noShowCodeName: {
              data: {
                id: noshowReason.get('id'),
                type: 'noShowCodeName'
              }
            },
            dispatchSchedule: {
              data: {
                id: dispatchSchedule.get('id'),
                type: 'dispatchSchedule'
              }
            }
          }
        }
      };
      return this.get('ajax').post(url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    deleteTripNoShow(trip) {
      const session = this.get('session');
      const url = `${this.host}/trip/${trip.get('id')}/no-show`;
      return this.get('ajax').del(url, {
        method: 'DELETE',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        }
      });
    }

  });

  _exports.default = _default;
});