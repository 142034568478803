define("adept-iq/pods/components/reoptimize-modal/operation-list/config", ["exports", "adept-iq/classes/vertical-menu-models/operation-list-model"], function (_exports, _operationListModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const reoptimizeItem = _operationListModel.default.create({
    title: 'Reoptimize',
    summary: 'The "Reoptimize" operation takes all the trips scheduled for a certain date, processes them, and places them on routes based on your company strategy.',
    componentPath: _operationListModel.REOPTIMIZE_VIEW_PATH
  });

  const removeDriverBreakItem = _operationListModel.default.create({
    title: 'Remove Driver Breaks',
    summary: 'The "Remove Driver Breaks" operation automatically removes driver breaks from routes based on what is defined as a short route. Currently, this is configured as 360 minutes.',
    componentPath: 'remove-driver-break'
  });

  const adjustDriverBreakItem = _operationListModel.default.create({
    title: 'Adjust Driver Breaks',
    summary: 'The "Adjust Driver Breaks" operation shifts all driver breaks to the "middle" of the route. Currently, this is configured to be between 3 and 6 hours into the shift.',
    componentPath: 'adjust-driver-break'
  });

  const trimSlackItem = _operationListModel.default.create({
    title: 'Trim Slack',
    summary: 'The "Trim Slack" operation removes slack time from the beginning and the end of tomorrow’s routes.',
    componentPath: 'trim-slack'
  });

  const consolidateRouteItem = _operationListModel.default.create({
    title: 'Consolidate Routes',
    summary: 'The "Consolidate Routes" operation attempts to condense tomorrow\'s trip onto fewer routes.',
    summary1: 'Currently, this is configured to consider rescheduling trips from routes that have less than 3 trips as well as those routes that have less than 4.0 hours of total work.',
    componentPath: 'consolidate-routes'
  });

  const releaseRouteItem = _operationListModel.default.create({
    title: 'Release Routes',
    summary: 'Release Route enables dispatchers to work with tomorrow\'s routes.',
    componentPath: 'release-routes'
  });

  const operationItems = [reoptimizeItem, removeDriverBreakItem, adjustDriverBreakItem, trimSlackItem, consolidateRouteItem, releaseRouteItem];
  var _default = operationItems;
  _exports.default = _default;
});