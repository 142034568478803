define("adept-iq/models/booking", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    subscription: belongsTo('subscription'),
    legs: hasMany('leg'),
    cancellation: belongsTo('cancellation'),
    provider: belongsTo('provider'),
    group: belongsTo('group'),
    trips: hasMany('trip'),
    isSameDayBooking: Ember.computed('legs.[].requestTime', function () {
      const today = (0, _moment.default)().startOf('day');
      const endToday = (0, _moment.default)().endOf('day');

      if (!Ember.isEmpty(this.get('legs.firstObject.requestTime'))) {
        const legTime = (0, _moment.default)(this.get('legs').firstObject.get('requestTime'));
        return legTime.isBetween(today, endToday);
      }

      return null;
    }),
    // format equipment per each leg
    equipments: Ember.computed('legs.[].equipments', function () {
      const legs = this.get('legs');
      const equipments = [];

      if (!Ember.isEmpty(legs)) {
        legs.forEach(leg => {
          const legEquipments = leg.get('equipments');
          equipments.push(...legEquipments);
        });
      }

      return equipments;
    }),
    tripStartTime: Ember.computed('legs.[]', 'trips.@each.routeTripStartTime', function () {
      const legs = this.get('legs');
      const trips = this.get('trips');
      let min = null;

      if (legs.firstObject) {
        min = legs.firstObject.get('requestTime').getTime();
      }

      if (trips.firstObject) {
        min = trips.firstObject.get('routeTripStartTime');
      }

      if (!Ember.isEmpty(legs)) {
        legs.forEach(leg => {
          min = Math.min(min, leg.get('requestTime').getTime());
        });
      }

      if (!Ember.isEmpty(trips)) {
        trips.forEach(trip => {
          const time = trip.get('routeTripStartTime');
          if (time) min = Math.min(min, time.getTime());
        });
      }

      return min ? new Date(min) : null;
    }),
    tripEndTime: Ember.computed('legs.[]', 'trips.@each.routeTripStartTime', function () {
      const legs = this.get('legs');
      const trips = this.get('trips');
      let max = null;

      if (legs.firstObject) {
        max = legs.firstObject.get('requestTime');
      }

      if (trips.firstObject) {
        max = trips.firstObject.get('routeTripEndTime');
      }

      if (!Ember.isEmpty(legs)) {
        legs.forEach(leg => {
          max = Math.max(max, leg.get('requestTime').getTime());
        });
      }

      if (!Ember.isEmpty(trips)) {
        trips.forEach(trip => {
          const time = trip.get('routeTripEndTime');
          if (time) max = Math.max(max, time.getTime());
        });
      }

      return max ? new Date(max) : null;
    })
  });

  _exports.default = _default;
});