define("adept-iq/pods/components/table/cells/trip-eta/component", ["exports", "adept-iq/pods/components/table/cells/base-cell/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['table-trip-eta-cell'],
    classNameBindings: ['row.otp'],
    tooltipText: Ember.computed('row.mrt', function () {
      const mrt = this.get('row.mrt');

      const duration = _moment.default.duration(mrt); // don't show the tooltip text if the mrt value is less than 1 minute
      // assuming that mrt values will be in milliseconds


      if (mrt < 60000) {
        return false;
      }

      return `+${duration.humanize()}`;
    })
  });

  _exports.default = _default;
});