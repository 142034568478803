define("adept-iq/pods/components/reoptimize-modal/operation-views/trim-slack/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['trim-slack'],
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    isTaskRunning: Ember.computed.or('hypoTrimSlackTask.isRunning', 'applyTrimSlack.isRunning'),
    // Passed In Attributes
    title: null,
    currentSchedule: null,
    tableRef: null,
    checkedRows: Ember.computed.alias('tableRef.checkedRows'),
    hasRowsSelected: Ember.computed.lt('checkedRows.length', 1),
    disableApplyButton: Ember.computed.or('hasRowsSelected', 'isTaskRunning'),

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('currentAPITask', this.get('applyTrimSlack'));
      this.hypoTrimSlackTask.perform();
    },

    hypoTrimSlackTask: (0, _emberConcurrency.task)(function* () {
      const currentSchedule = this.get('currentSchedule');
      const store = this.get('store');
      const scheduleAdapter = store.adapterFor('schedule');

      try {
        const {
          deductData
        } = yield scheduleAdapter.hypoTrimSlack(currentSchedule);
        deductData.forEach(trim => {
          const route = store.peekRecord('route', trim.routeId);

          if (route) {
            route.setProperties({
              adjustedEndTime: trim.adjustedEndTime,
              adjustedStartTime: trim.adjustedStartTime
            });
          }
        });
      } catch (e) {
        this.get('notifications').warning('Failed to retrieve adjusted start and end times');
      }
    }),
    // be able to trim slack from selected rows
    applyTrimSlack: (0, _emberConcurrency.task)(function* (routes) {
      routes.forEach(route => {
        const adjustedStartTime = (0, _moment.default)(route.get('adjustedStartTime')).toDate();
        const adjustedEndTime = (0, _moment.default)(route.get('adjustedEndTime')).toDate();

        if (adjustedStartTime && adjustedEndTime) {
          route.set('plannedStartTime', adjustedStartTime);
          route.set('plannedEndTime', adjustedEndTime);
        }
      });
      let failureRoutes = 0;

      try {
        yield routes.reduce(async (acu, route) => {
          return acu.then(() => route.save().catch(() => {
            route.rollbackAttributes();
            route.set('result', 'Failed');
            failureRoutes++;
          }));
        }, Promise.resolve());
      } catch (e) {
        failureRoutes++;
      }

      if (failureRoutes) {
        this.get('notifications').warning(`Failed to trim slack from ${failureRoutes} Routes`);
      }

      this.get('activeContext').refreshTableContent.perform(['route']);
    }).drop(),
    actions: {
      onTrimSlackClick() {
        const routes = this.get('checkedRows') || [];
        this.applyTrimSlack.perform(routes);
      }

    }
  });

  _exports.default = _default;
});