define("adept-iq/config/api-urls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.API = _exports.BUILD_VERSION = void 0;
  const BUILD_VERSION = {
    commitId: '22ae862'
  }; // Build 22ae862
  // S3 bucket iq.ui.awsdev.ddsdeploytest.com

  _exports.BUILD_VERSION = BUILD_VERSION;
  const API = {
    aboutPageService: {
      host: 'https://iq.awsdev.ddsdeploytest.com/1.0/versions'
    },
    addressService: {
      host: 'https://iq.awsdev.ddsdeploytest.com/address'
    },
    avlService: {
      host: 'https://iq.awsdev.ddsdeploytest.com/avl'
    },
    avlmService: {
      host: 'https://iq.awsdev.ddsdeploytest.com/avlm'
    },
    avlmBookingService: {
      host: 'https://iq.awsdev.ddsdeploytest.com/rider-booking'
    },
    bookingService: {
      host: 'https://iq.awsdev.ddsdeploytest.com/booking'
    },
    configService: {
      host: 'https://iq.awsdev.ddsdeploytest.com/config'
    },
    dispatchService: {
      host: 'https://iq.awsdev.ddsdeploytest.com/dispatch'
    },
    driverService: {
      host: 'https://iq.awsdev.ddsdeploytest.com/driver'
    },
    etaService: {
      host: 'https://iq.awsdev.ddsdeploytest.com/eta'
    },
    geocodeService: {
      host: 'https://iq.awsdev.ddsdeploytest.com/geocoding'
    },
    riderManagementService: {
      host: 'https://iq.awsdev.ddsdeploytest.com/rider-management'
    },
    schedulingService: {
      host: 'https://iq.awsdev.ddsdeploytest.com/scheduling'
    },
    ssoService: {
      host: 'https://iq.awsdev.ddsdeploytest.com/sso',
      useMirage: false
    },
    vehicleService: {
      host: 'https://iq.awsdev.ddsdeploytest.com/vehicle'
    },
    streamingService: {
      host: 'wss://events.awsdev.ddsdeploytest.com:443'
    },
    continuousOptService: {
      host: 'https://iq.awsdev.ddsdeploytest.com/continuous-opt'
    },
    tomtom: {
      host: 'https://api.tomtom.com/'
    },
    iquxService: {
      host: 'https://iq.awsdev.ddsdeploytest.com/iqux-service'
    }
  };
  _exports.API = API;
});