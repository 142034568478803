define("adept-iq/models/widget-config", ["exports", "ember-data", "lodash"], function (_exports, _emberData, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    componentName: attr('string'),
    title: attr('string'),
    width: attr('number'),
    minWidth: attr('number'),
    height: attr('number'),
    minHeight: attr('number'),
    x: attr('number'),
    y: attr('number'),
    options: attr('object', {
      defaultValue: () => {}
    }),
    rowComponent: attr('string'),
    hideWidgetControls: attr('boolean'),

    mergeOptions(changes = {}) {
      const options = this.get('options');

      const mergedOptions = _lodash.default.merge({}, options, changes);

      this.set('options', mergedOptions);
      this.notifyPropertyChange('options');
    }

  });

  _exports.default = _default;
});