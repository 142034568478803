define("adept-iq/models/route-vehicle-driver", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    startTime: attr('date'),
    endTime: attr('date'),
    driverPhoneNumber: attr('string'),
    dispatchSchedule: belongsTo('dispatch-schedule'),
    dispatchRoute: belongsTo('dispatch-route'),
    driver: belongsTo('driver'),
    vehicle: belongsTo('vehicle'),
    avlmDriverStateEvent: belongsTo('avlm-driver-state-event', {
      inverse: 'routeVehicleDriver'
    }),
    // previously, the `dispatchRoute` relationship was incorrectly named `route`;
    // this alias preserves backward compatibility after the fix
    route: Ember.computed.alias('dispatchRoute')
  });

  _exports.default = _default;
});