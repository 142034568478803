define("adept-iq/pods/components/generic-components/table/columns/check-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZALpRZMV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\"],[12,\"checked\",[27,\"readonly\",[[23,[\"isChecked\"]]],null]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onCheckboxToggle\"],null]],[11,\"type\",\"checkbox\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/generic-components/table/columns/check-box/template.hbs"
    }
  });

  _exports.default = _default;
});