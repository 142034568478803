define("adept-iq/classes/vertical-menu-models/operation-list-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.REOPTIMIZE_VIEW_PATH = void 0;
  const REOPTIMIZE_VIEW_PATH = 'reoptimize';
  _exports.REOPTIMIZE_VIEW_PATH = REOPTIMIZE_VIEW_PATH;

  var _default = Ember.Object.extend({
    title: null,
    summary: null,
    componentPath: null,
    currentSchedule: null,
    selected: false,
    disabled: Ember.computed('currentSchedule.{isGenerated,isUnScheduled}', 'componentPath', function () {
      const componentPath = this.get('componentPath');
      const isGenerated = this.get('currentSchedule.isGenerated');
      const isUnScheduled = this.get('currentSchedule.isUnScheduled');
      if (componentPath === REOPTIMIZE_VIEW_PATH) return false;else if (isGenerated || isUnScheduled) return true;
      return false;
    })
  });

  _exports.default = _default;
});