define("adept-iq/pods/components/iq-widgets/drivers-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/drivers-form-widget/config", "moment"], function (_exports, _component, _config, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    editableSections: _config.default.editableSections,
    editModal: Ember.inject.service(),
    notifications: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    validateRecords() {
      let valid = true;
      const records = this.get('editableRecords') || [];
      this.set('editModal.errors', []);
      records.forEach(record => {
        const availabilities = record.driverAvailabilities || [];
        const phoneNumbers = record.phoneNumbers || [];
        const firstName = record.firstName;
        const lastName = record.lastName;
        const provider = record.provider;

        if (Ember.isEmpty(firstName)) {
          valid = false;
          this.get('editModal.errors').pushObject('Driver must have a first name');
        }

        if (Ember.isEmpty(lastName)) {
          valid = false;
          this.get('editModal.errors').pushObject('Driver must have a last name');
        }

        if (Ember.isEmpty(provider.get('id'))) {
          valid = false;
          this.get('editModal.errors').pushObject('Driver must have a provider');
        }

        if (Ember.isNone(record.driverId) || record.driverId.trim().length < 1) {
          this.get('editModal.errors').pushObject('Driver must have a Driver Id');
        }

        if (availabilities.length < 1) {
          valid = false;
          this.get('editModal.errors').pushObject('Driver must have an Availability');
        }

        phoneNumbers.forEach(phoneNumber => {
          if (Ember.isEmpty(phoneNumber.get('phoneNumber'))) {
            phoneNumber.set('phoneNumber', ' ');
          }

          if (Ember.isEmpty(phoneNumber.get('areaCode'))) {
            phoneNumber.set('areaCode', ' ');
          }
        });
        availabilities.forEach(availability => {
          const startTime = (0, _moment.default)(availability.startTime);
          const endTime = (0, _moment.default)(availability.endTime);
          const shiftStartLocal = (0, _moment.default)('2020-01-01T' + availability.get('shiftStart')); // converts time with timezone

          const shiftEndLocal = (0, _moment.default)('2020-01-01T' + availability.get('shiftEnd')); // converts time with timezone

          const shiftStart = (0, _moment.default)(shiftStartLocal.format('HH:mm:ss'), 'HH:mm:ss'); // get local time only

          const shiftEnd = (0, _moment.default)(shiftEndLocal.format('HH:mm:ss'), 'HH:mm:ss'); // get local time only

          if (!shiftStart) {
            valid = false;
            this.get('editModal.errors').pushObject('Shift Start is Required');
          }

          if (!shiftEnd) {
            valid = false;
            this.get('editModal.errors').pushObject('Shift End is Required');
          }

          if (!startTime.isValid()) {
            valid = false;
            this.get('editModal.errors').pushObject('Must have a valid Start Date');
          }

          if (!endTime.isValid()) {
            valid = false;
            this.get('editModal.errors').pushObject('Must have a valid End Date');
          }

          if (endTime.isBefore(startTime)) {
            valid = false;
            this.get('editModal.errors').pushObject('Effective end date cannot be before effective start date');
          }

          if (!shiftStart.isValid()) {
            valid = false;
            this.get('editModal.errors').pushObject('Must have a valid Start Time');
          }

          if (!shiftEnd.isValid()) {
            valid = false;
            this.get('editModal.errors').pushObject('Must have a valid End Time');
          }

          if (shiftEnd.isBefore(shiftStart)) {
            valid = false;
            this.get('editModal.errors').pushObject('Shift end cannot be before shift start');
          }
        });
      });
      return valid;
    },

    actions: {
      // override undo because we will have to deal with undoing created
      // models for addresses, travel needs, and eligibility later.
      // IQUX-510
      onUndoClick() {
        const lastUndoState = this.get('editModal.undoHistory').popObject();

        if (lastUndoState === null) {
          const records = this.get('editableRecords') || [];
          records.forEach(record => {
            record.set('isForceDirty', false);
          });
        } else {
          lastUndoState.forEach(({
            record,
            properties
          }) => {
            record.setProperties(properties);
          });
        }

        this.set('isLastUndoStateCommitted', true);
      },

      onApplyClick() {
        if (this.validateRecords()) {
          this.get('service').apply();
        }
      } // onCellValueChange(record, valuePath, value, options) {
      //   if (valuePath === 'vehicles.firstObject.name') {
      //     let vehicle = record.get('vehicles.firstObject');
      //     record.vehicles.removeObject(vehicle);
      //   }
      //   else {
      //     this.get('service').setRecordValue(record, valuePath, value, options);
      //   }
      // }


    }
  });

  _exports.default = _default;
});