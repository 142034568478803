define("adept-iq/pods/components/generic-widgets/map-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/base-widget/component", "tomtom", "adept-iq/config/environment"], function (_exports, _component, _tomtom, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    interaction: Ember.inject.service(),
    maximizer: Ember.inject.service(),
    classNames: ['map-widget'],
    layoutName: 'components/generic-widgets/map-widget',
    classNameBindings: ['testMapWidget'],
    testMapWidget: false,
    centerLat: _environment.default.tomtom.search.center.lat,
    centerLng: _environment.default.tomtom.search.center.lon,
    zoom: 12,
    autoZoom: true,
    map: null,
    isWidgetMaximized: Ember.computed.not('maximizer.isWidgetMinified'),
    // tomtom will only accept an element ID, so create a unique one
    mapElementId: Ember.computed('elementId', function () {
      const elementId = this.get('elementId');
      return `${elementId}-map`;
    }),

    init() {
      this._super(...arguments);

      this._didZoomMap = () => {
        Ember.run.scheduleOnce('afterRender', this, 'didZoomMap');
      };

      this._didWheelZoom = () => {
        Ember.run.scheduleOnce('afterRender', this, 'didWheelZoom');
      };

      this._didPanMap = () => {
        Ember.run.scheduleOnce('afterRender', this, 'didPanMap');
      };
    },

    didInsertElement() {
      this._super(...arguments);

      const mapElementId = this.get('mapElementId');

      const centerLat = this.get('centerLat') || _environment.default.tomtom.search.center.lat;

      const centerLng = this.get('centerLng') || _environment.default.tomtom.search.center.lon;

      const zoom = this.get('zoom');

      const map = _tomtom.default.map(mapElementId, {
        zoom,
        scale: true,
        center: [centerLat, centerLng],
        key: _environment.default.tomtom.key,
        source: _environment.default.tomtom.source,
        basePath: _environment.default.tomtom.basePath,
        styleUrlMapping: _environment.default.tomtom.styleUrlMapping,
        isMaximized: this.get('isWidgetMaximized')
      });

      map.on('zoomstart', this._didZoomMap);
      map.on('mouseup', this._didPanMap);
      this.set('map', map);
    },

    willDestroyElement() {
      const map = this.get('map');
      map.off('zoomstart', this._didZoomMap);
      map.off('mouseup', this._didPanMap);
      map.remove();
      this.set('map', null);

      this._super(...arguments);
    },

    destroy() {
      this._didZoomMap = null;

      this._super(...arguments);
    },

    didZoomMap() {
      this.get('interaction').didZoomMap();
    },

    didPanMap() {
      this.set('autoZoom', false);
    },

    didWheelZoom() {
      this.set('autoZoom', false);
    },

    enableAutoZoom() {
      this.set('autoZoom', true);
    },

    onWidgetResize() {
      this._super(...arguments);

      Ember.run.scheduleOnce('afterRender', this, 'invalidateMapSize');
    },

    boundDistance: Ember.computed('bounds._southWest', function () {
      const bounds = this.get('bounds');
      const map = this.get('map');

      if (bounds._southWest && map) {
        const northEastCorner = bounds.getNorthEast();
        const southWestCorner = bounds.getSouthWest();
        const distance = map.distance(northEastCorner, southWestCorner);
        return distance;
      }

      return null;
    }),
    mapBoundDistance: Ember.computed('mapBounds._southWest', function () {
      const bounds = this.get('mapBounds');
      const map = this.get('map');
      if (!bounds || !map) return;

      if (bounds._southWest) {
        const northEastCorner = bounds.getNorthEast();
        const southWestCorner = bounds.getSouthWest();
        const distance = map.distance(northEastCorner, southWestCorner);
        return distance;
      }

      return null;
    }),
    shouldRefreshMapZoom: Ember.computed('mapBoundsID', 'mapBoundDistance', 'boundsID', 'boundDistance', function () {
      const mapBoundId = this.get('mapBoundsID');
      const boundsID = this.get('boundsID');

      if (boundsID === mapBoundId) {
        // singular vehicle(s) moving might create different boundaries with negligible difference in distance
        // we want to capture a boundary actually shrinking or enlarging when user selects more items or deselects less items while vehicle(s) are in motion
        const boundDistance = Math.floor(this.get('boundDistance'));
        const mapBoundDistance = Math.floor(this.get('mapBoundDistance'));
        return boundDistance >= mapBoundDistance;
      }

      return true;
    }),
    onCenterOrZoomChange: Ember.observer('bounds._southWest', function () {
      Ember.run.scheduleOnce('afterRender', this, 'updateMapView');
    }),
    // bound id is a computed property determined by changes in the checked item array
    onCheckedItemsChange: Ember.observer('boundsID', function () {
      this.enableAutoZoom();
    }),

    invalidateMapSize() {
      const map = this.get('map');
      if (!map) return;
      map.invalidateSize();
    },

    /**
     *
     * @summary A generic function to reset map view with correct zoom and center value unless re-center button is clicked only center map view
     * @param {bool} manual - recenter map view without zoom level.
    */
    reCenterMapView(manual = false) {
      const map = this.get('map');
      const bounds = this.get('bounds');
      const boundId = this.get('boundsID');
      let center = null;
      if (!map) return;
      let zoom = map.getZoom(); // get user's current zoom level

      if (bounds._southWest) {
        center = bounds.getCenter();
      } // no boundary map view needs a zoom level of at least greater or equal to 12 when returning to hard coded lat, lng


      if (!bounds._southWest && !manual) {
        zoom = zoom >= this.get('zoom') ? this.get('zoom') : zoom;
      }

      const centerLat = center ? center.lat : this.get('centerLat');
      const centerLng = center ? center.lng : this.get('centerLng');
      if (Ember.isNone(centerLat) || Ember.isNone(centerLng) || Ember.isNone(zoom)) return;
      const mapViewDigest = JSON.stringify([boundId, centerLat, centerLng]);

      if (mapViewDigest !== this._mapViewDigest || manual) {
        map.setView([centerLat, centerLng], zoom);
        this._mapViewDigest = mapViewDigest;
        this.set('mapBoundsID', boundId);
        this.set('mapBounds', bounds);
      }
    },

    /**
     *
     * @summary A generic function to update map view based on a new boundary
    */
    updateMapView() {
      const map = this.get('map');
      const bounds = this.get('bounds');
      const boundId = this.get('boundsID');
      const autoZoom = this.get('autoZoom');
      if (!map || !autoZoom || !bounds) return;

      if (Ember.isNone(bounds._southWest)) {
        return this.reCenterMapView(); // either we default to hard-coded center or use the current center lat / center lng
      }

      const shouldRefreshMapZoom = this.get('shouldRefreshMapZoom');
      const center = bounds.getCenter();
      const centerLat = center.lat;
      const centerLng = center.lng;
      const mapViewDigest = JSON.stringify([boundId, centerLat, centerLng]); // don't do anything if center hasn't changed

      if (mapViewDigest !== this._mapViewDigest && shouldRefreshMapZoom) {
        map.fitBounds(bounds);
        this._mapViewDigest = mapViewDigest;
        this.set('mapBoundsID', boundId);
        this.set('mapBounds', bounds);
      }
    }

  });

  _exports.default = _default;
});