define("adept-iq/serializers/route-template-availability", ["exports", "adept-iq/serializers/-ss-schedulingService", "moment"], function (_exports, _ssSchedulingService, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ssSchedulingService.default.extend({
    serialize()
    /* snapshot, options */
    {
      const json = this._super(...arguments);

      if (json.data.attributes.tripLimit) {
        delete json.data.attributes.tripLimit;
      }

      if (json.data.attributes.routeLength) {
        delete json.data.attributes.routeLength;
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      resourceHash.attributes = resourceHash.attributes || {};
      resourceHash.relationships = resourceHash.relationships || {};
      const {
        shiftStart,
        shiftEnd
      } = resourceHash.attributes;

      if (shiftStart && shiftEnd) {
        const startMoment = (0, _moment.default)(`${shiftStart}`);
        const endMoment = (0, _moment.default)(`${shiftEnd}`);
        const diff = Math.abs(startMoment.diff(endMoment));

        const duration = _moment.default.duration(diff);

        const routeLength = Math.floor(duration.asHours()) + _moment.default.utc(diff).format(':mm');

        resourceHash.attributes.routeLength = routeLength;
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});