define("adept-iq/pods/components/side-drawer/add-new-message/component", ["exports", "adept-iq/pods/components/side-drawer/add-new-message/config", "adept-iq/config/api-urls", "adept-iq/config/environment"], function (_exports, _config, _apiUrls, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Canned Message Response Types currently Hard-Coded
  const CANNED_RESPONSE_TYPES = ['None', 'Acknowledge', 'Yes/No'];
  const MESSAGE_TYPES = ['textMessage', 'cannedMessage'];
  const CANNED_RESPONSE_TYPE_CODES = {
    'None': 'X',
    'Acknowledge': 'A',
    'Yes/No': 'Y'
  };

  var _default = Ember.Component.extend({
    tagName: '',
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    workspace: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    user: Ember.inject.service(),
    title: 'Send Message',
    onShrinkClick: null,
    currentState: null,
    sections: _config.default.sections,
    bannerSuccessMessage: '',
    bannerFailedMessage: '',
    templates: Ember.computed('', () => []),
    selectedTemplate: null,
    selectedResponse: null,
    responseTypes: CANNED_RESPONSE_TYPES,
    messageTypes: MESSAGE_TYPES,
    freeMessage: '',
    freeMessageIsDisable: false,
    cannedResponseIsDisable: false,
    isEmptyMessageBody: true,
    disableResponseType: false,
    displayVehicleDetails: true,
    draftMessages: Ember.computed('workspace.topOptions.draftMessages', function () {
      const workspace = this.get('workspace.topOptions.draftMessages') || [];
      workspace.forEach(message => {
        message.set('isOpened', false);
        message.set('hasNoDriver', Ember.isEmpty(message.get('driver.badgeNumber')) && Ember.isEmpty(message.get('avlmDriver.badgeNumber')));
      });
      return this.get('workspace.topOptions.draftMessages');
    }),

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      if (this.user.isRoadSupEnable()) {
        this.set('disableResponseType', true);
        this.set('displayVehicleDetails', false);
      }

      this.fetchTemplates().then(templates => {
        this.set('templates', templates);
      });
      this.loadCurrentState();
    },

    loadCurrentState() {
      const currentState = this.get('currentState');

      if (currentState) {
        this.set('freeMessage', currentState.freeMessage);
        this.set('selectedTemplate', currentState.selectedTemplate);
        this.set('selectedResponse', currentState.selectedResponse);
        this.checkDisableInputs();
      }
    },

    fetchTemplates() {
      const templates = this.store.peekAll('cs-config-item').filter(message => {
        return message.category === 'config-Canned_Messages-to_driver';
      }); // check if to_driver messages already exist in store

      if (!Ember.isEmpty(templates)) {
        return Ember.RSVP.resolve(templates.sortBy('displayName'));
      }

      return this.store.query('cs-config-item', {
        category: 'config-Canned_Messages-to_driver'
      }).then(configs => {
        if (configs) {
          configs.forEach(message => {
            templates.push(message);
          });
        }
      }).then(() => {
        return templates;
      });
    },

    sendMessage() {
      let draftMessages = this.get('draftMessages') || [];
      let successMessageCount = 0;
      let failureMessageCount = 0;

      if (!this.user.isRoadSupEnable()) {
        // it will not send api call for the messages which do not have badgeNumber
        draftMessages = draftMessages.filter(function (draftMessage) {
          if (!draftMessage.hasNoDriver) return true;
          return false;
        });
      }

      const responseType = this.get('selectedResponse');
      const selectedTemplate = this.get('selectedTemplate');
      let type = MESSAGE_TYPES[0];

      if (selectedTemplate) {
        type = MESSAGE_TYPES[1];
      }

      draftMessages.forEach(message => {
        if (message) {
          if (type === MESSAGE_TYPES[1]) {
            message.set('body', this.get('selectedTemplate.value.message'));
          } else {
            message.set('body', this.get('freeMessage'));
          }

          message.set('responseTypeText', responseType);
          message.set('type', 'avlm-message');
        }
      });
      return Ember.RSVP.all(draftMessages.map(message => {
        let endPointType = 'recipient';
        let driverId = message.get('driver.id');

        if (!driverId) {
          driverId = message.get('driverId') ? message.get('driverId') : '';
        }

        let vehicleId = message.get('latestVehicle') ? message.get('latestVehicle.id') : message.get('latestRouteVehicleDriver.vehicle.id');
        const vehicleName = message.get('latestVehicle') ? message.get('latestVehicle.name') : message.get('latestRouteVehicleDriver.vehicle.name');

        if (_environment.default.APP.avlmLite) {
          driverId = message.get('avlmDriver.id') ? message.get('avlmDriver.id') : message.get('avlmDriver.driverId');
          vehicleId = message.get('avlmVehicle') ? message.get('avlmVehicle.id') : '';

          if (!vehicleId) {
            vehicleId = message.get('latestVehicle.id');
          }
        }

        if (this.user.isRoadSupEnable()) {
          if (!driverId) {
            driverId = message.get('driverId') ? message.get('driverId') : '';
          }

          endPointType = 'sender';
        }

        const formatedMessage = {
          data: {
            type: 'cannedMessage',
            attributes: {
              messageNr: '0',
              body: message.get('body'),
              createdTime: message.get('createdTime'),
              priority: message.get('priority'),
              responseType: {
                statusCode: CANNED_RESPONSE_TYPE_CODES[responseType],
                status: responseType
              },
              type: 'avlm-message',
              driver: {
                id: driverId + '',
                endPointType: endPointType
              },
              vehicle: {
                id: vehicleId + ''
              }
            }
          }
        };

        if (!vehicleId) {
          delete formatedMessage.data.attributes.vehicle;
        }

        const json = JSON.stringify(formatedMessage);
        const session = this.get('session');
        const self = this;
        return this.get('ajax').post(_apiUrls.API.avlmService.host + '/canned-message', {
          method: 'POST',
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          },
          data: json
        }).then(() =>
        /*response*/
        {
          successMessageCount++;

          if (this.user.isRoadSupEnable()) {
            this.set('bannerSuccessMessage', 'MESSAGE SUCCESSFULLY SENT TO DISPATCH');
          } else if (successMessageCount > 1) {
            this.set('bannerSuccessMessage', `BULK MESSAGE TO ${successMessageCount} VEHICLES SENT SUCCESSFULLY`);
          } else {
            this.set('bannerSuccessMessage', `MESSAGE TO VEHICLE ${vehicleName} SENT SUCCESSFULLY`);
          }
        }).catch(function ()
        /*error*/
        {
          failureMessageCount++;

          if (self.user.isRoadSupEnable()) {
            self.set('bannerFailedMessage', 'MESSAGE SENT TO DISPATCH FAILED');
          } else if (failureMessageCount > 1) {
            self.set('bannerFailedMessage', `BULK MESSAGE TO ${failureMessageCount} VEHICLES FAILED`);
          } else {
            self.set('bannerFailedMessage', `MESSAGE TO VEHICLE ${vehicleName} FAILED`);
          }
        });
      }));
    },

    cancelMessage() {
      this.get('tooltip').reset();
    },

    clearForm() {
      this.set('selectedTemplate', null);
      this.set('selectedResponse', null);
      this.set('isEmptyMessageBody', true);
      this.set('freeMessage', '');
      this.checkDisableInputs();
    },

    checkDisableInputs() {
      const selectedTemplate = this.get('selectedTemplate');
      const freeMessage = this.get('freeMessage');

      if (selectedTemplate) {
        this.set('freeMessageIsDisable', true);
      } else {
        this.set('freeMessageIsDisable', false);
      }

      if (freeMessage.length > 0) {
        this.set('cannedResponseIsDisable', true);
      } else {
        this.set('cannedResponseIsDisable', false);
      }

      if (freeMessage.length > 0 || selectedTemplate) {
        this.set('isEmptyMessageBody', false);
        this.checkSelectedResponse();
      } else {
        this.set('isEmptyMessageBody', true);
        this.set('selectedResponse', null);
      }
    },

    checkSelectedResponse() {
      const selectedResponse = this.get('selectedResponse');

      if (!selectedResponse) {
        this.set('selectedResponse', CANNED_RESPONSE_TYPES[0]);
      }
    },

    actions: {
      onSendMessageButtonClick() {
        const tooltip = this.get('tooltip');
        const title = this.get('title');
        tooltip.setProperties({
          tip: 'Send new message?',
          title,
          primaryActionText: 'Confirm',
          secondaryActionText: 'Cancel',
          primaryAction: () => {
            tooltip.reset();
            this.sendMessage().then(() => {
              const bannerSuccessMessageDisplay = this.get('bannerSuccessMessage');
              const bannerFailedMessageDisplay = this.get('bannerFailedMessage');

              if (bannerSuccessMessageDisplay) {
                this.get('notifications').success(`${bannerSuccessMessageDisplay}`);
              }

              if (bannerFailedMessageDisplay) {
                this.get('notifications').warning(`${bannerFailedMessageDisplay}`);
              }

              if (this.get('workspace.topState')) {
                this.get('workspace').popState();
              }
            });
          },
          secondaryAction: () => {
            tooltip.reset();
            this.cancelMessage();
          }
        });
      },

      onResponseTypeSelect(responseType) {
        this.set('selectedResponse', responseType);
      },

      onTemplateSelect(template) {
        this.set('selectedTemplate', template);
        this.checkDisableInputs();
      },

      onCloseButtonClick() {
        this.get('workspace').popState();
      },

      onClearButtonClick() {
        this.clearForm();
      },

      onTextChangeFreeMessage() {
        this.checkDisableInputs();
      },

      onTextFocusFreeMessage() {
        this.set('isEmptyMessageBody', false);
        this.set('cannedResponseIsDisable', true);
        this.checkSelectedResponse();
      },

      onClickExpandDetails(message
      /*, event*/
      ) {
        message.set('isOpened', !message.get('isOpened'));
      },

      onShrinkClick() {
        let currentState = this.get('currentState');
        const freeMessage = this.get('freeMessage');
        const selectedTemplate = this.get('selectedTemplate');
        const selectedResponse = this.get('selectedResponse'); // save current manual settings

        currentState = {
          freeMessage,
          selectedTemplate,
          selectedResponse
        };
        this.set('currentState', currentState);
        this.onShrinkClick();
      }

    }
  });

  _exports.default = _default;
});