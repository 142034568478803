define("adept-iq/topic-transforms/lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transform;

  function transform(payload
  /*, ENV*/
  ) {
    const transformedPayloads = [];
    if (!payload.data || !payload.data.relationships) return transformedPayloads;
    const {
      dispatchRoute
    } = payload.data.relationships;
    const {
      attributes
    } = payload.data;

    if (dispatchRoute) {
      attributes.entityType = 'dispatchRoute';
      transformedPayloads.push(dispatchRoute);

      if (attributes) {
        payload.data.id = dispatchRoute.data.id;
        attributes.entityId = dispatchRoute.data.id;
        attributes.owner = payload.data.attributes.user;

        if (!attributes.expirationTime) {
          attributes.persist = true;
        }
      }
    }

    if (attributes.owner) {
      const owner = {
        data: {
          type: 'sso-user',
          id: attributes.owner
        }
      };
      payload.data.relationships.owner = owner;
    }

    transformedPayloads.push(payload);
    return transformedPayloads;
  }
});