define("adept-iq/services/user", ["exports", "adept-iq/helpers/has-role", "adept-iq/classes/road-supervisor-api"], function (_exports, _hasRole, _roadSupervisorApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    userId: Ember.computed.alias('session.data.authenticated.tokenInfo.userId'),
    roles: Ember.computed.alias('session.data.authenticated.tokenInfo.roleNames'),

    hasRole(roleName) {
      return (0, _hasRole.hasRole)(this.roles, roleName);
    },

    isRoadSupEnable() {
      return this.roles && (0, _hasRole.hasRole)(this.roles, _roadSupervisorApi.default.getRoleName());
    }

  });

  _exports.default = _default;
});