define("adept-iq/services/interaction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    // read-only; do not set directly
    isScrolling: false,
    isResizingColumn: false,
    isMouseDown: false,
    isMouseUp: false,
    // mouse _can_ be "up" and "down" at the same time
    isWheelActive: false,
    isContextMenuOpen: false,
    isZoomingMap: false,
    // time to pause after scrolling is detected
    scrollTime: 250,
    // time to wait after map zoom is initiated
    mapZoomTime: 750,
    // max time to wait after detecting mousedown
    mousedownMaxTime: 10000,
    // time to wait after detecting mouseup (cancels mousedown wait)
    mouseupCooldownTime: 250,
    // time to wait after detecting mousewheel
    wheelCooldownTime: 500,
    // time to wait after detecting context menu open; it is impossible to know
    // when the menu has been closed, so we just wait a fixed time
    contextMenuTime: 3000,
    _window: null,
    // timers
    _clickCooldownTimeout: null,
    _clickMaxTimeout: null,
    _wheelTimeout: null,
    _contextmenuTimeout: null,
    _scrollTimeout: null,
    _mapZoomTimeout: null,
    // callback wrappers
    _onMousedown: null,
    _onMouseup: null,
    _onWheel: null,
    _onContextmenu: null,
    isBusy: Ember.computed.or('isScrolling', 'isResizingColumn', 'isMouseDown', 'isContextMenuOpen', 'isWheelActive', 'isZoomingMap'),
    isIdle: Ember.computed.not('isBusy'),

    init() {
      this._super(...arguments);

      this.set('window', window);
    },

    destroy() {
      clearTimeout(this._clickCooldownTimeout);
      clearTimeout(this._clickMaxTimeout);
      clearTimeout(this._wheelTimeout);
      clearTimeout(this._contextmenuTimeout);
      clearTimeout(this._scrollTimeout);
      clearTimeout(this._mapZoomTimeout);
      this.set('window', null);

      this._super(...arguments);
    },

    window: Ember.computed({
      get()
      /* key */
      {
        return this._window;
      },

      set(key, value) {
        if (this._window) {
          this._window.removeEventListener('mousedown', this._onMousedown);

          this._window.removeEventListener('mouseup', this._onMouseup);

          this._window.removeEventListener('wheel', this._onWheel);

          this._window.removeEventListener('contextmenu', this._onContextmenu);

          this._onMousedown = null;
          this._onMouseup = null;
          this._onWheel = null;
          this._onContextmenu = null;
        }

        this._window = value;

        if (this._window) {
          this._onMousedown = () => Ember.run.once(this, 'onMousedown');

          this._window.addEventListener('mousedown', this._onMousedown);

          this._onMouseup = () => Ember.run.once(this, 'onMouseup');

          this._window.addEventListener('mouseup', this._onMouseup);

          this._onWheel = () => Ember.run.once(this, 'onWheel');

          this._window.addEventListener('wheel', this._onWheel, {
            passive: true
          });

          this._onContextmenu = () => Ember.run.once(this, 'onContextmenu');

          this._window.addEventListener('contextmenu', this._onContextmenu);
        }

        return value;
      }

    }),

    // call when a widget has been scrolled
    didScrollWidget() {
      const isScrolling = this.get('isScrolling');

      if (!isScrolling) {
        this.set('isScrolling', true);
      }

      clearTimeout(this._scrollTimeout);
      this._scrollTimeout = setTimeout(() => {
        this.set('isScrolling', false);
      }, this.get('scrollTime'));
    },

    // call when user starts dragging a column
    didStartResizingColumn() {
      const isResizingColumn = this.get('isResizingColumn');

      if (!isResizingColumn) {
        this.set('isResizingColumn', true);
      }
    },

    // call when user stops dragging a column
    didStopResizingColumn() {
      const isResizingColumn = this.get('isResizingColumn');
      if (!isResizingColumn) return;
      this.set('isResizingColumn', false);
    },

    // call when user initiates a map zoom
    didZoomMap() {
      const isZoomingMap = this.get('isZoomingMap');

      if (!isZoomingMap) {
        this.set('isZoomingMap', true);
      }

      clearTimeout(this._mapZoomTimeout);
      this._mapZoomTimeout = setTimeout(() => {
        this.set('isZoomingMap', false);
      }, this.get('mapZoomTime'));
    },

    onMousedown() {
      clearTimeout(this._clickCooldownTimeout);
      clearTimeout(this._clickMaxTimeout);
      this._clickMaxTimeout = setTimeout(() => {
        clearTimeout(this._clickCooldownTimeout);
        this.set('isMouseDown', false);
      }, this.get('mousedownMaxTime'));
      this.set('isMouseDown', true);
      this.set('isMouseUp', false);
    },

    onMouseup() {
      clearTimeout(this._clickCooldownTimeout);
      clearTimeout(this._clickMaxTimeout);
      this._clickCooldownTimeout = setTimeout(() => {
        this.set('isMouseDown', false);
        this.set('isMouseUp', false);
      }, this.get('mouseupCooldownTime'));
      this.set('isMouseUp', true);
    },

    onWheel() {
      clearTimeout(this._wheelTimeout);
      this.set('isWheelActive', true);
      this._wheelTimeout = setTimeout(() => {
        this.set('isWheelActive', false);
      }, this.get('wheelCooldownTime'));
    },

    onContextmenu() {
      const isContextMenuOpen = this.get('isContextMenuOpen');

      if (!isContextMenuOpen) {
        this.set('isContextMenuOpen', true);
      } // also triggers mousedown _without_ a mouseup


      clearTimeout(this._clickMaxTimeout);
      clearTimeout(this._clickCooldownTimeout);
      this.set('isMouseDown', false); // we don't really know when this closes, so just a wait a bit

      clearTimeout(this._contextmenuTimeout);
      this._contextmenuTimeout = setTimeout(() => {
        this.set('isContextMenuOpen', false);
      }, this.get('contextMenuTime'));
    }

  });

  _exports.default = _default;
});