define("adept-iq/pods/components/timeline-picker/date-picker/component", ["exports", "moment", "adept-iq/utils/encoding"], function (_exports, _moment, _encoding) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DATE_FORMAT = 'ddd YYYY-MM-DD';
  const KEY_CODE_ENTER = 13;
  const INVALID_DATE_STRING = 'Invalid';

  var _default = Ember.Component.extend({
    workspace: Ember.inject.service(),
    classNames: ['timeline-date-picker', 'data-test-timeline-date-picker'],
    classNameBindings: ['isValid', 'isInvalid', 'isDirty'],
    startDate: null,
    endDate: null,
    fieldValidityObject: null,
    dateString: null,
    rangeStart: null,
    rangeEnd: null,
    isValid: Ember.computed.readOnly('isDateStringValid'),
    isInvalid: Ember.computed.not('isValid'),
    isDirty: Ember.computed.readOnly('isDateStringDirty'),
    isDateStringValid: Ember.computed('dateString', function () {
      const dateString = this.get('dateString');
      return this.validateDateString(dateString);
    }),
    isDateStringDirty: Ember.computed('dateString', 'startDate', 'endDate', function () {
      const dateString = this.get('dateString');
      if (!this.validateDateString(dateString)) return true; // take the provided dates, build a date string, then parse it

      const startDate = this.get('startDate');
      const endDate = this.get('endDate');
      const effectiveDateString = this.buildDateString(startDate, endDate);
      const [effectiveStartDate, effectiveEndDate] = this.parseDateString(effectiveDateString); // compare against the parsed values from the current time string

      const [encodedStartDate, encodedEndDate] = this.parseDateString(dateString); // string is dirty when parsed times don't match

      return effectiveStartDate.getTime() !== encodedStartDate.getTime() || effectiveEndDate.getTime() !== encodedEndDate.getTime();
    }),
    isRangeStartDirty: Ember.computed('rangeStart', 'startDate', function () {
      const rangeStart = this.get('rangeStart');
      if (!rangeStart) return true;
      const startDate = this.get('startDate');
      const start = (0, _moment.default)(startDate).startOf('day');
      return start.valueOf() !== rangeStart.valueOf();
    }),
    isRangeEndDirty: Ember.computed('rangeStart', 'rangeEnd', 'endDate', function () {
      const rangeStart = this.get('rangeStart');
      if (!rangeStart) return true;
      const rangeEnd = this.get('rangeEnd');
      const endDate = this.get('endDate');
      const end = (0, _moment.default)(endDate).startOf('day'); // it's ok for no end to be specified, so long as window is single date

      if (!rangeEnd) {
        return end.valueOf() !== rangeStart.valueOf();
      }

      return end.valueOf() !== rangeEnd.valueOf();
    }),
    center: Ember.computed('startDate', function () {
      const startDate = this.get('startDate');
      return (0, _moment.default)(startDate).startOf('day');
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      const startDate = this.get('startDate');
      const endDate = this.get('endDate');

      if (this.get('isDateStringDirty')) {
        const dateString = this.buildDateString(startDate, endDate);
        this.set('dateString', dateString);
      } // check this before rangeStart!


      if (this.get('isRangeEndDirty')) {
        const rangeEnd = (0, _moment.default)(endDate).startOf('day');
        this.set('rangeEnd', rangeEnd);
      }

      if (this.get('isRangeStartDirty')) {
        const rangeStart = (0, _moment.default)(startDate).startOf('day');
        this.set('rangeStart', rangeStart);
      }
    },

    buildDateString(startDate, endDate) {
      let start = (0, _moment.default)(startDate);
      let end = (0, _moment.default)(endDate);
      if (!start.isValid() || !end.isValid()) return INVALID_DATE_STRING;
      start = start.startOf('day');
      end = end.startOf('day');

      if (start.valueOf() === end.valueOf()) {
        return start.format(DATE_FORMAT);
      }

      return `${start.format(DATE_FORMAT)} to ${end.format(DATE_FORMAT)}`;
    },

    validateDateString(dateString) {
      if (Ember.typeOf(dateString) !== 'string') return false;

      if (dateString.includes(' to ')) {
        const arr = dateString.split(' to ');
        if (arr.length !== 2) return false;
        if (!arr.every(_encoding.isEncodedDate)) return false;
        const [startDate, endDate] = arr.map(_encoding.parseDate);
        return startDate.getTime() <= endDate.getTime();
      }

      return (0, _encoding.isEncodedDate)(dateString);
    },

    parseDateString(dateString) {
      let start, end;

      if (dateString.includes(' to ')) {
        const [startString, endString] = dateString.split(' to ');
        start = (0, _moment.default)(startString).startOf('day');
        end = (0, _moment.default)(endString).endOf('day');
      } else {
        start = (0, _moment.default)(dateString).startOf('day');
        end = start.clone().endOf('day');
      }

      return [start.toDate(), end.toDate()];
    },

    checkValidity(startDate, endDate) {
      if (startDate > endDate) {
        return false;
      }

      return true;
    },

    setDates(startDate, endDate) {
      // first: get the hours of the existing dates and apply those
      const existingStart = this.get('startDate');
      startDate.setHours(existingStart.getHours());
      startDate.setMinutes(existingStart.getMinutes());
      const existingEnd = this.get('endDate');
      endDate.setHours(existingEnd.getHours());
      endDate.setMinutes(existingEnd.getMinutes());
      this.set('fieldValidityObject.calendar', this.checkValidity(startDate, endDate));
      this.set('startDate', startDate);
      this.set('endDate', endDate);
    },

    actions: {
      onInputTextChange(text) {
        this.set('dateString', text);
      },

      onKeydown(keyCode) {
        if (keyCode === KEY_CODE_ENTER && this.get('isDateStringValid')) {
          const dateString = this.get('dateString');
          const [startDate, endDate] = this.parseDateString(dateString);
          this.setDates(startDate, endDate);
        }
      },

      onYesterdayButtonClick(dropdown) {
        dropdown.actions.close();
        const start = (0, _moment.default)().startOf('day').subtract(1, 'day');
        const end = start.clone().endOf('day');
        this.setDates(start.toDate(), end.toDate());
      },

      onTodayButtonClick(dropdown) {
        dropdown.actions.close();
        const start = (0, _moment.default)().startOf('day');
        const end = start.clone().endOf('day');
        this.setDates(start.toDate(), end.toDate());
      },

      onTomorrowButtonClick(dropdown) {
        dropdown.actions.close();
        const start = (0, _moment.default)().startOf('day').add(1, 'day');
        const end = start.clone().endOf('day');
        this.setDates(start.toDate(), end.toDate());
      },

      onRangeSelect(range) {
        this.set('rangeStart', range.start);
        this.set('rangeEnd', range.end);
        let end;

        if (range.end) {
          end = range.end.clone().endOf('day');
        } else {
          end = range.start.clone().endOf('day');
        }

        this.setDates(range.start.toDate(), end.toDate());
      },

      onDoneButtonClick(dropdown) {
        dropdown.actions.close();
      }

    }
  });

  _exports.default = _default;
});