define("adept-iq/adapters/continous-opt-route-break", ["exports", "adept-iq/config/api-urls", "adept-iq/adapters/-cos-continuousOptService"], function (_exports, _apiUrls, _cosContinuousOptService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _cosContinuousOptService.default.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    continuousOptHost: _apiUrls.API.continuousOptService.host,

    postAddBreak(attributes) {
      const session = this.get('session');
      const url = `${this.continuousOptHost}/optimizer/add-break`;
      const json = {
        data: {
          type: 'addBreak',
          attributes
        }
      };
      return this.get('ajaxService').post(url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    }

  });

  _exports.default = _default;
});