define("adept-iq/serializers/driver", ["exports", "adept-iq/serializers/-driver"], function (_exports, _driver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _driver.default.extend({
    attrs: {
      providerName: {
        serialize: false
      }
    },

    serialize()
    /* snapshot, options */
    {
      const json = this._super(...arguments);

      if (json.data && json.data.relationships) {
        const providerRelationship = json.data.relationships.provider;

        if (!Ember.isNone(providerRelationship)) {
          providerRelationship.data.type = 'providerName';
          json.data.relationships.providerName = json.data.relationships.provider;
          delete json.data.relationships.provider;
        }
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.attributes) && !Ember.isNone(resourceHash.attributes.providerName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.provider = {
          data: {
            type: 'provider',
            id: resourceHash.attributes.providerName
          }
        };
        resourceHash.relationships = relationships;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});