define("adept-iq/utils/workspace-availability", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.processTimeConstraints = processTimeConstraints;

  function processTimeConstraints(leftTimeConstraint, rightTimeConstraint, startTime, endTime) {
    let leftTime, rightTime;

    if (leftTimeConstraint) {
      const date = leftTimeConstraint;
      if (!(date instanceof Date)) return false;
      leftTime = Ember.isPresent(date) ? date.getTime() : null;
    }

    if (rightTimeConstraint) {
      const date = rightTimeConstraint;
      if (!(date instanceof Date)) return false;
      rightTime = Ember.isPresent(date) ? date.getTime() : null;
    }

    if (Ember.isPresent(leftTime) && Ember.isPresent(rightTime)) {
      if (leftTime <= endTime && rightTime >= startTime) return true;
    }

    return false;
  }
});