define("adept-iq/services/address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    geocode: Ember.inject.service(),
    store: Ember.inject.service(),

    reverseGeocode(latlng) {
      const store = this.get('store');
      return this.get('geocode').reverseGeocode(latlng).then(result => {
        const latlngArray = result.position.split(',');
        return store.createRecord('address', {
          freeformAddress: result.address.freeformAddress,
          streetNumber: result.address.streetNumber,
          streetAddress: result.address.streetName,
          region: result.address.countrySubdivisionName,
          locality: result.address.countrySecondarySubdivision,
          subLocality: result.address.countryTertiarySubdivision,
          postalCode: result.address.postalCode,
          country: result.address.country,
          lat: latlngArray[0],
          lng: latlngArray[1]
        });
      });
    },

    // address models returned by this function are not saved by default
    fuzzySearch(query, options = {}) {
      const store = this.get('store');
      return this.get('geocode').fuzzySearch(query, options).then(results => {
        return results.map(result => {
          // translate from tomtom fields
          return store.createRecord('address', {
            freeformAddress: result.address.freeformAddress,
            streetNumber: result.address.streetNumber,
            streetAddress: result.address.streetName,
            region: result.address.countrySubdivisionName,
            locality: result.address.countrySecondarySubdivision,
            subLocality: result.address.countryTertiarySubdivision,
            postalCode: result.address.postalCode,
            country: result.address.country,
            lat: result.position.lat,
            lng: result.position.lon
          });
        });
      });
    }

  });

  _exports.default = _default;
});