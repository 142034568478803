define("adept-iq/models/cs-config-category", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    name: attr('string'),
    displayName: attr('string', {
      defaultValue: ''
    }),
    path: attr('string'),
    depth: attr('number'),
    canDisplay: attr('boolean'),
    canAdd: attr('boolean'),
    canDelete: attr('boolean'),
    minimumItem: attr('number'),
    parent: belongsTo('cs-config-category', {
      inverse: 'children'
    }),
    children: hasMany('cs-config-category', {
      inverse: 'parent'
    }),
    configItems: hasMany('cs-config-item'),
    categoryChain: Ember.computed('parent.categoryChain', function () {
      let categoryChain = [];
      const parent = this.get('parent');

      if (parent && parent.get('id')) {
        categoryChain = parent.get('categoryChain');
      }

      categoryChain.push(this);
      return categoryChain;
    })
  });

  _exports.default = _default;
});