define("adept-iq/services/gps-simulation", ["exports", "adept-iq/config/environment", "adept-iq/utils/geolib"], function (_exports, _environment, _geolib) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SECOND = 1000.0;
  const DEFAULT_LOCATION = [_environment.default.tomtom.search.center.lat, _environment.default.tomtom.search.center.lon];

  var _default = Ember.Service.extend({
    fps: 30,
    // frames/s
    speed: 50.0 * 1000 / 3600,
    // m/s
    angularSpeed: 360,
    // deg/s
    loop: false,
    t: 0,
    // seconds
    d: 0,
    // m
    bearing: null,
    segmentIndex: null,
    segmentLength: null,
    segmentOffset: null,
    location: DEFAULT_LOCATION,
    simulatedGpsPolyline: null,
    polylineLength: null,
    isRunning: false,
    _interval: null,
    _keydownHandler: null,

    init() {
      this._super(...arguments);

      this._keydownHandler = e => {
        switch (e.which) {
          case 38:
            {
              // up
              this.set('speed', this.get('speed') + 10);
              e.preventDefault();
              break;
            }

          case 40:
            {
              // down
              this.set('speed', this.get('speed') - 10);
              e.preventDefault();
              break;
            }

          case 32:
            {
              // sp
              this.set('speed', 0);
              e.preventDefault();
              break;
            }

          default:
            return;
          // exit this handler for other keys
        }
      };
    },

    start() {
      if (this.get('isRunning')) return;
      this.set('isRunning', true);
      const fps = this.get('fps');
      const polyline = this.get('simulatedGpsPolyline');
      const segments = (0, _geolib.computePolylineSegments)(polyline);
      const polylineLength = (0, _geolib.computePolylineLength)(polyline);
      this.setProperties({
        t: 0,
        d: 0,
        bearing: segments[0].bearing,
        location: segments[0].points[0],
        segments: segments,
        segmentIndex: 0,
        segmentLength: segments[0].length,
        segmentOffset: 0,
        polylineLength
      });
      const interval = setInterval(() => {
        Ember.run.schedule('afterRender', () => {
          this.tick();
        });
      }, SECOND / fps);
      this.set('_interval', interval);
      document.addEventListener('keydown', this._keydownHandler);
    },

    tick() {
      const fps = this.get('fps');
      const speed = this.get('speed');
      const angularSpeed = this.get('angularSpeed');
      const segments = this.get('segments');
      const totalLength = this.get('polylineLength');
      let t = this.get('t');
      let d = this.get('d');
      let bearing = this.get('bearing');
      t += SECOND / fps;
      d = Math.min(d + speed / fps, totalLength);
      const {
        segment,
        segmentOffset,
        point
      } = (0, _geolib.projectDistanceOnPolyline)(d, segments); // add noise
      // point[0] += (Math.random() - 0.5) * 0.0001;
      // point[1] += (Math.random() - 0.5) * 0.0001;

      const distanceUp = (segment.bearing - bearing + 360) % 360;
      const distanceDown = (bearing - segment.bearing + 360) % 360;

      if (distanceUp < distanceDown) {
        bearing += Math.min(angularSpeed / fps, distanceUp);
      } else if (distanceUp > distanceDown) {
        bearing -= Math.min(angularSpeed / fps, distanceDown);
      }

      this.setProperties({
        t,
        d,
        bearing,
        location: point,
        segmentIndex: segments.indexOf(segment),
        segmentLength: segment.length,
        segmentOffset
      });
      const eventHandler = this.get('eventHandler');
      if (eventHandler) eventHandler(point);

      if (this.get('loop') && d >= totalLength) {
        // reset simulation
        t = 0;
      }
    },

    stop() {
      if (!this.get('isRunning')) return;
      const interval = this.get('_interval');
      clearInterval(interval);
      document.removeEventListener('keydown', this._keydownHandler);
      this.set('isRunning', false);
    },

    getLocation() {
      return this.get('location');
    },

    onLocationChange(eventHandler) {
      this.set('eventHandler', eventHandler);
    },

    offLocationChange()
    /* eventHandler */
    {
      this.set('eventHandler', null);
    }

  });

  _exports.default = _default;
});