define("adept-iq/serializers/avlm-vehicle-event", ["exports", "adept-iq/serializers/-avlService"], function (_exports, _avlService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _avlService.default.extend({
    normalize(modelClass, resourceHash) {
      resourceHash.id = `${resourceHash.attributes.vehicleId}-${resourceHash.attributes.timestamp}`;

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeQueryResponse(store, clazz, payload) {
      // workaround for this API not returns payload type
      if (!Ember.isEmpty(payload.data)) {
        payload.data.forEach(d => {
          if (!d.type) {
            // only for archive avl data
            d.attributes = { ...d,
              receivedAt: d.receivedat,
              vehicleId: d.vehicleid
            };
            d.type = 'avl';
          }
        });
      }

      return this._super(store, clazz, payload);
    },

    modelNameFromPayloadKey(key) {
      if (key === 'avl') {
        return 'avlm-vehicle-event';
      }

      return this._super(key);
    }

  });

  _exports.default = _default;
});