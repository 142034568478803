define("adept-iq/utils/vehicle-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareVehicleInfoData = _exports.vehicleInfoData = void 0;

  const vehicleInfoData = () => {
    const vehicleInfo = {
      'data': {
        'type': 'vehicleInfo',
        'id': '',
        'attributes': {
          'state': 'active',
          'callsign': '',
          'device': {
            'hardwareId': null,
            'type': '',
            'OS': '',
            'appVersion': ''
          }
        }
      }
    };
    return vehicleInfo;
  };

  _exports.vehicleInfoData = vehicleInfoData;

  const prepareVehicleInfoData = (vehicleInfoJSON, record) => {
    vehicleInfoJSON.data.id = record.get('name');
    vehicleInfoJSON.data.attributes.callsign = record.get('name');
    return vehicleInfoJSON;
  };

  _exports.prepareVehicleInfoData = prepareVehicleInfoData;
});