define("adept-iq/pods/components/iq-widgets/subscriptions-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/subscriptions-widget',
    rowComponent: 'table/rows/text-formatted-row',
    modelName: 'subscription',
    title: 'Subscriptions',
    columns: [{
      id: 'id',
      type: 'uuid',
      label: 'ID',
      valuePath: 'bookings.firstObject.id',
      searchable: true,
      defaultWidth: 60,
      hidden: true
    }, {
      id: 'riderFirstName',
      index: 0,
      type: 'text',
      label: 'First Name',
      valuePath: 'rider.firstName',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 75
    }, {
      id: 'riderLastName',
      index: 1,
      type: 'text',
      label: 'Last Name',
      valuePath: 'rider.lastName',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 75
    }, {
      id: 'riderPhone',
      index: 2,
      type: 'text',
      label: 'Rider Phone',
      valuePath: 'rider.lastName',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 70
    }, {
      id: 'status',
      index: 3,
      type: 'text',
      label: 'Status',
      valuePath: 'status',
      editable: true,
      hidden: false,
      defaultWidth: 60
    }, {
      id: 'externalId',
      index: 4,
      type: 'text',
      label: 'Subscription ID',
      valuePath: 'id',
      editable: true,
      hidden: false,
      defaultWidth: 60
    }, {
      id: 'Start Date',
      index: 5,
      type: 'datetime',
      label: 'Start Date',
      valuePath: 'startDate',
      editable: true,
      format: 'MM/DD/YYYY',
      defaultWidth: 50
    }, {
      id: 'datetime',
      index: 6,
      type: 'datetime',
      label: 'End Date',
      valuePath: 'endDate',
      editable: true,
      format: 'MM/DD/YYYY',
      defaultWidth: 50
    }, {
      id: 'recurrence',
      index: 7,
      type: 'enum',
      label: 'Recurrence',
      valuePath: 'formattedRecurrence',
      cellDesc: 'Recurrences',
      editable: false,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'riderId',
      index: 8,
      type: 'text',
      label: 'Passenger ID',
      valuePath: 'rider.externalId',
      editable: true,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'requestedTime',
      index: 9,
      type: 'time',
      label: 'Request Time',
      valuePath: 'requestTime',
      editable: true,
      hidden: true,
      format: 'HH:MM',
      defaultWidth: 50
    }, {
      id: 'serviceStartTime',
      index: 10,
      type: 'date',
      label: 'Service Start Time',
      valuePath: 'serviceWindowStartTime',
      editable: true,
      hidden: true,
      format: 'HH:MM',
      defaultWidth: 50
    }, {
      id: 'serviceEndTime',
      index: 11,
      type: 'date',
      label: 'Service End Time',
      valuePath: 'serviceWindowEndTime',
      editable: true,
      hidden: true,
      format: 'HH:MM',
      defaultWidth: 50
    }, {
      id: 'pickupaddress',
      index: 12,
      type: 'text',
      label: 'Pickup Address ',
      valuePath: 'origin.address.fullAddress',
      editable: true,
      hidden: true,
      defaultWidth: 80
    }, {
      id: 'dropaddress',
      index: 13,
      type: 'text',
      label: 'Dropoff Address',
      valuePath: 'destination.address.fullAddress',
      editable: true,
      hidden: true,
      defaultWidth: 80
    }, {
      id: 'anchor',
      index: 14,
      type: 'text',
      label: 'Anchor',
      valuePath: 'anchor',
      editable: true,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'notes',
      index: 15,
      type: 'text',
      label: 'Notes',
      valuePath: 'legNotes',
      editable: true,
      hidden: true,
      searchable: true,
      defaultWidth: 50
    }, {
      id: 'passengerNotes',
      index: 16,
      type: 'text',
      label: 'Passenger Notes',
      valuePath: 'rider.notes',
      editable: true,
      hidden: true,
      searchable: true,
      defaultWidth: 80
    }, {
      id: 'travelNeeds',
      index: 17,
      type: 'number',
      label: 'Travel Needs',
      valuePath: 'noOfTravelNeeds',
      editable: true,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'fare',
      index: 18,
      type: 'text',
      label: 'Fare',
      valuePath: 'fare',
      editable: true,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'paymentType',
      index: 19,
      type: 'text',
      label: 'Payment Type',
      valuePath: 'fareType.name',
      editable: true,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'otp',
      index: 20,
      type: 'number',
      label: 'OTP',
      valuePath: 'otp',
      editable: false,
      hidden: false,
      highlightable: true
    }]
  };
  _exports.default = _default;
});