define("adept-iq/services/demo-data-loader", ["exports", "moment", "adept-iq/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const stops1 = [{
    "address": "234 LOGAN ST",
    "city": "BROOKLYN",
    "eta": "2019-09-16T09:30:00.000Z",
    "firstName": "Shift Start",
    "lastName": "Shift Start",
    "lat": 40.68068,
    "lng": -73.87791
  }, {
    "address": "15933 100TH ST",
    "city": "HOWARD BEACH",
    "eta": "2019-09-16T09:44:00.000Z",
    "firstName": "OGAWA",
    "lastName": "IVANKA",
    "lat": 40.65921,
    "lng": -73.83285
  }, {
    "address": "3611 14TH AVE",
    "city": "BROOKLYN",
    "eta": "2019-09-16T10:24:00.000Z",
    "firstName": "OGAWA",
    "lastName": "IVANKA",
    "lat": 40.63687,
    "lng": -73.74254
  }, {
    "address": "11515 218TH ST",
    "city": "CAMBRIA HEIGHTS",
    "eta": "2019-09-16T11:07:00.000Z",
    "firstName": "HATADA",
    "lastName": "ILEA",
    "lat": 40.7002,
    "lng": -73.7392
  }, {
    "address": "1333 CARROLL ST",
    "city": "ROOKLYN",
    "eta": "2019-09-16T11:51:00.000Z",
    "firstName": "RELYEA",
    "lastName": "DOMONIK",
    "lat": 40.667185,
    "lng": -73.943796
  }, {
    "address": "29 GALLATIN PL",
    "city": "BROOKLYN",
    "eta": "2019-09-16T12:24:00.000Z",
    "firstName": "HATADA",
    "lastName": "ILEA",
    "lat": 40.690706,
    "lng": -73.986702
  }, {
    "address": "1 WHITEHALL ST",
    "city": "NEW YORK",
    "eta": "2019-09-16T12:54:00.000Z",
    "firstName": "RELYEA",
    "lastName": "DOMONIK",
    "lat": 40.704073,
    "lng": -74.012866
  }, {
    "address": "11630 193RD ST",
    "city": "SAINT ALBANS",
    "eta": "2019-09-16T13:55:00.000Z",
    "firstName": "CLASSEN",
    "lastName": "AVERETT",
    "lat": 40.69338,
    "lng": -73.75918
  }, {
    "address": "300 SKILLMAN AVE",
    "city": "BROOKLYN",
    "eta": "2019-09-16T14:54:00.000Z",
    "firstName": "LASSEN",
    "lastName": "AVERETT",
    "lat": 40.716934,
    "lng": -73.938413
  }, {
    "address": "234 LOGAN ST",
    "city": "BROOKLYN",
    "eta": "2019-09-16T15:30:00.000Z",
    "firstName": "Shift End",
    "lastName": "Shift End",
    "lat": 40.68068,
    "lng": -73.87791
  }];
  const stops2 = [{
    address: '25 WORTMAN AVE',
    city: 'BROOKLYN',
    eta: (0, _moment.default)('11:00', 'HH:mm'),
    firstName: 'Shift Start',
    lastName: 'Start Shift'
  }, {
    address: '15725 ROCKAWAY BEACH',
    city: 'BLVD ROCKAWAY PARK',
    eta: (0, _moment.default)('12:30', 'HH:mm'),
    firstName: 'VISSERING',
    lastName: 'MAKHARI'
  }, {
    address: '3115 BRIGHTON 4TH',
    city: 'ST  BROOKLYN',
    eta: (0, _moment.default)('12:54', 'HH:mm'),
    firstName: 'BRAXTON',
    lastName: 'LEONIDUS'
  }, {
    address: '2044 E 18TH',
    city: 'ST  BROOKLYN',
    eta: (0, _moment.default)('13:11', 'HH:mm'),
    firstName: 'VISSERING',
    lastName: 'MAKHARI'
  }, {
    address: '1915 OCEAN AVE',
    city: 'BROOKLYN',
    eta: (0, _moment.default)('13:24', 'HH:mm'),
    firstName: 'BRAXTON',
    lastName: 'LEONIDUS'
  }, {
    address: '26 BRIGHTON 11TH',
    city: 'ST BROOKLYN',
    eta: (0, _moment.default)('13:45', 'HH:mm'),
    firstName: 'SAMRAH',
    lastName: 'BRENDIN'
  }, {
    address: '1824 SHORE PKWY',
    city: 'BROOKLYN',
    eta: (0, _moment.default)('14:03', 'HH:mm'),
    firstName: 'KLECHA',
    lastName: 'VANIYAH'
  }, {
    address: '8871 20TH AVE',
    city: 'BROOKLYN',
    eta: (0, _moment.default)('14:22', 'HH:mm'),
    firstName: 'SAMRAH',
    lastName: 'BRENDIN'
  }, {
    address: '551 2ND AVE',
    city: 'BROOKLYN',
    eta: (0, _moment.default)('14:45', 'HH:mm'),
    firstName: 'IANACONE',
    lastName: 'LEILYNN'
  }, {
    address: '475 CARLTON AVE',
    city: 'BROOKLYN',
    eta: (0, _moment.default)('15:13', 'HH:mm'),
    firstName: 'KLECHA',
    lastName: 'VANIYAH'
  }, {
    address: '475 CARLTON AVE',
    city: 'BROOKLYN',
    eta: (0, _moment.default)('15:17', 'HH:mm'),
    firstName: 'IANACONE',
    lastName: 'LEILYNN'
  }, {
    address: '175 LAWRENCE AVE',
    city: 'BROOKLYN',
    eta: (0, _moment.default)('15:36', 'HH:mm'),
    firstName: 'WARSAW',
    lastName: 'JAICEE'
  }, {
    address: '86 DIKEMAN ST',
    city: 'BROOKLYN',
    eta: (0, _moment.default)('16:16', 'HH:mm'),
    firstName: 'WARSAW',
    lastName: 'JAICEE'
  }, {
    address: '180 LIVINGSTON ST',
    city: 'BROOKLYN',
    eta: (0, _moment.default)('17:15', 'HH:mm'),
    firstName: 'PEASLEY',
    lastName: 'UMME'
  }, {
    address: '155 DEAN ST',
    city: 'BROOKLYN',
    eta: (0, _moment.default)('17:22', 'HH:mm'),
    firstName: 'BREACH',
    lastName: 'ROCKELLE'
  }, {
    address: '35 PAERDEGAT 3RD',
    city: 'ST BROOKLYN',
    eta: (0, _moment.default)('18:06', 'HH:mm'),
    firstName: 'BREACH',
    lastName: 'ROCKELLE'
  }, {
    address: '9815 158TH AVE',
    city: 'HOWARD BEACH',
    eta: (0, _moment.default)('18:31', 'HH:mm'),
    firstName: 'PEASLEY',
    lastName: 'UMME'
  }, {
    address: '63 PENNSYLVANIA AVE',
    city: 'BROOKLYN',
    eta: (0, _moment.default)('19:00', 'HH:mm'),
    firstName: 'TEALER',
    lastName: 'ANNALISSE'
  }, {
    address: '85 SHEPHERD AVE',
    city: 'BROOKLYN',
    eta: (0, _moment.default)('19:22', 'HH:mm'),
    firstName: 'TEALER',
    lastName: 'ANNALISSE'
  }, {
    address: '25 WORTMAN AVE',
    city: 'BROOKLYN',
    eta: (0, _moment.default)('21:00', 'HH:mm'),
    firstName: 'Shift End',
    lastName: 'Shift End'
  }];

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),

    load() {
      const workspaceContext = this.get('workspaceContext'); // look at `app/pods/components/iq-widgets/vehicles-widget/config.js`
      // this is the model that backs the row

      workspaceContext.pushPayload({
        data: {
          id: '124714',
          type: 'iq-vehicle',
          attributes: {
            otp: 'O'
          },
          relationships: {
            // note: `dsVehicle` is the relationship, but type is `vehicle`
            dsVehicle: {
              data: {
                id: 124714,
                type: 'vehicle'
              }
            }
          }
        }
      });
      workspaceContext.pushPayload({
        data: {
          id: 124714,
          type: 'vehicle',
          attributes: {
            name: '124714',
            latestDriver: {
              id: '123',
              fullName: 'John Trueman'
            },
            currentRoute: {
              name: 'Daily Route'
            }
          },
          relationships: {
            vehicleType: {
              data: {
                id: 'vehicle-type-1',
                type: 'vehicle-type'
              }
            }
          }
        }
      }); // these are necessary to make a "type" appear

      workspaceContext.pushPayload({
        data: {
          id: 'vehicle-type-1',
          type: 'vehicle-type',
          attributes: {
            name: 'Car'
          },
          relationships: {}
        }
      });
      workspaceContext.pushPayload({
        data: {
          id: '124714',
          // must match vehicle id
          type: 'avl-location',
          attributes: {
            heading: 0,
            lat: _environment.default.tomtom.search.center.lat,
            lng: _environment.default.tomtom.search.center.lon // sic

          },
          relationships: {
            iqVehicle: {
              data: {
                id: '124714',
                type: 'iq-vehicle'
              }
            }
          }
        }
      });
      workspaceContext.pushPayload({
        data: {
          id: 32647,
          type: 'driver',
          attributes: {
            driverId: '3214',
            firstName: 'Bellami',
            lastName: 'Folkins',
            vehicleId: '3214',
            routeId: '21415'
          },
          relationships: {}
        }
      });
      workspaceContext.pushPayload({
        data: {
          id: 'avlm-vehicle-1',
          type: 'avlm-vehicle',
          attributes: {
            name: '123124'
          }
        }
      });
      workspaceContext.pushPayload({
        data: {
          id: 'avlm-canned-message-1',
          type: 'avlm-canned-message',
          attributes: {
            deliveredTime: (0, _moment.default)().toDate(),
            createdTime: (0, _moment.default)().toDate(),
            body: 'Arrived at Med Center with Driver'
          },
          relationships: {
            route: {
              data: {
                id: 'avlm-route-1',
                type: 'avlm-route'
              }
            },
            driver: {
              data: {
                id: 32647,
                type: 'driver'
              }
            },
            vehicle: {
              data: {
                id: 'avlm-vehicle-1',
                type: 'avlm-vehicle'
              }
            },
            driver: {
              data: {
                id: 32647,
                type: 'driver'
              }
            },
            iqRoute: {
              data: {
                id: 120856,
                type: 'iq-route'
              }
            }
          }
        }
      });
      workspaceContext.pushPayload({
        data: {
          id: 'avlm-canned-message-2',
          type: 'avlm-canned-message',
          attributes: {
            deliveredTime: (0, _moment.default)().add(30, 'minutes').toDate(),
            createdTime: (0, _moment.default)().add(30, 'minutes').toDate(),
            body: 'En Route to Carrier with Driver'
          },
          relationships: {
            route: {
              data: {
                id: 'avlm-route-1',
                type: 'avlm-route'
              }
            },
            driver: {
              data: {
                id: 32647,
                type: 'driver'
              }
            },
            vehicle: {
              data: {
                id: 'avlm-vehicle-1',
                type: 'avlm-vehicle'
              }
            },
            driver: {
              data: {
                id: 32647,
                type: 'driver'
              }
            },
            iqRoute: {
              data: {
                id: 120856,
                type: 'iq-route'
              }
            }
          }
        }
      });
      workspaceContext.pushPayload({
        data: {
          id: 'avlm-canned-message-3',
          type: 'avlm-canned-message',
          attributes: {
            deliveredTime: (0, _moment.default)().add(70, 'minutes').toDate(),
            createdTime: (0, _moment.default)().add(70, 'minutes').toDate(),
            body: 'Requesting Additional Supervisory Assistance'
          },
          relationships: {
            route: {
              data: {
                id: 'avlm-route-1',
                type: 'avlm-route'
              }
            },
            driver: {
              data: {
                id: 32647,
                type: 'driver'
              }
            },
            vehicle: {
              data: {
                id: 'avlm-vehicle-1',
                type: 'avlm-vehicle'
              }
            },
            driver: {
              data: {
                id: 32647,
                type: 'driver'
              }
            },
            iqRoute: {
              data: {
                id: 120856,
                type: 'iq-route'
              }
            }
          }
        }
      });
      workspaceContext.pushPayload({
        data: {
          id: 'avlm-route-1',
          type: 'avlm-route',
          attributes: {},
          relationships: {
            iqRoute: {
              data: {
                id: 120856,
                type: 'iq-route'
              }
            }
          }
        }
      });
      workspaceContext.pushPayload({
        data: {
          id: 120856,
          type: 'iq-route',
          attributes: {},
          relationships: {
            dsRoute: {
              data: {
                id: 'dispatch-route-1',
                type: 'dispatch-route'
              }
            }
          }
        }
      });
      workspaceContext.pushPayload({
        data: {
          id: 'dispatch-route-1',
          type: 'dispatch-route',
          attributes: {
            name: 'Daily Route'
          },
          relationships: {
            route: {
              data: {
                id: 'route-1',
                type: 'route'
              }
            }
          }
        }
      });
      workspaceContext.pushPayload({
        data: {
          id: 'route-1',
          type: 'route',
          attributes: {
            plannedStartTime: (0, _moment.default)().startOf('day').toDate(),
            plannedEndTime: (0, _moment.default)().endOf('day').toDate()
          },
          relationships: {}
        }
      });
      stops1.forEach(function (stop, index) {
        workspaceContext.pushPayload({
          data: {
            id: 'avlm-stop-' + index,
            type: 'avlm-stop-point',
            attributes: {
              otp: 'O',
              eta: stop.eta,
              stopType: 'pick' //index % 2 ? 'drop' : 'pick'

            },
            relationships: {}
          }
        });
        workspaceContext.pushPayload({
          data: {
            id: index,
            type: 'iq-stop',
            attributes: {
              address: stop.address,
              passengerLastName: stop.lastName,
              passengerFirstName: stop.firstName,
              city: stop.city,
              lat: stop.lat,
              lng: stop.lng
            },
            relationships: {
              avlmStop: {
                data: {
                  id: 'avlm-stop-' + index,
                  type: 'avlm-stop-point'
                }
              }
            }
          }
        });
      });
      workspaceContext.flushChanges();
      this.store.pushPayload('dashboard', {
        "data": {
          "type": "dashboard",
          "id": "workspaces-users/monitor",
          // ?
          "attributes": {
            "name": "Monitor",
            "role": "Road Supervisor",
            // ?
            "category": "monitor",
            // ?
            "startDate": "2019-09-03T04:00:00.000Z",
            "endDate": "2019-09-04T03:59:59.999Z",
            "referenceDate": "2019-09-03T04:00:00.000Z",
            "mapState": {},
            "widgets": [{
              "id": "47806f66-355b-002e-1f57-6124913c1518",
              "typeId": "vehicles",
              "width": 6,
              "height": 3,
              "x": 0,
              "y": 0,
              "state": {
                "columns": {
                  "routeName": {
                    "index": 3,
                    "hidden": false,
                    "width": 104
                  },
                  "name": {
                    width: 60,
                    "index": 0,
                    "hidden": false
                  },
                  "typeName": {
                    "index": 4,
                    "hidden": true
                  },
                  "availability": {
                    "index": 5,
                    "hidden": true
                  },
                  "breaks": {
                    "index": 6,
                    "hidden": true
                  },
                  "noOfAmbulatorySeats": {
                    "index": 7,
                    "hidden": true
                  },
                  "noOfWheelChairSeats": {
                    "index": 8,
                    "hidden": true
                  },
                  "active": {
                    "index": 9,
                    "hidden": true
                  },
                  "driverId": {
                    "index": 10
                  },
                  "driverName": {
                    "index": 1,
                    "hidden": false,
                    "width": 113
                  },
                  "garageStartAddress": {
                    "index": 11
                  },
                  "garageEndAddress": {
                    "index": 12
                  },
                  "driverAPPVersion": {
                    "index": 13
                  },
                  "driverAppId": {
                    "index": 14
                  },
                  "hardewareId": {
                    "index": 15
                  },
                  "cellularCarrier": {
                    "index": 16,
                    "hidden": true
                  },
                  "cellularType": {
                    "index": 17
                  },
                  "otp": {
                    "index": 2,
                    "width": 98
                  },
                  "provider": {
                    "index": 18
                  },
                  "vehicleStatus": {
                    "index": 19,
                    "hidden": true
                  },
                  "gpsTimeStamp": {
                    "index": 20,
                    "hidden": true
                  },
                  "lat": {
                    "index": 21,
                    "hidden": true
                  },
                  "lng": {
                    "index": 22,
                    "hidden": true
                  }
                },
                "sortId": "name",
                "sortAsc": true
              }
            }, {
              "id": "f4ab3fba-e869-e30b-56d1-d713288daef9",
              "typeId": "stops",
              "width": 6,
              "height": 3,
              "x": 0,
              "y": 3,
              "state": {
                "columns": {
                  "checkbox": {
                    "filterTypeId": "null",
                    "filterValues": null
                  },
                  "type": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "hidden": true,
                    "index": 5
                  },
                  "tripId": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "hidden": true,
                    "index": 6
                  },
                  "riderLastName": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "hidden": false,
                    "index": 3,
                    "width": 144
                  },
                  "riderFirstName": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "hidden": false,
                    "index": 4,
                    "width": 109
                  },
                  "OTPstatus": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "hidden": false,
                    "index": 2,
                    "width": 83
                  },
                  "ETA": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "hidden": false,
                    "index": 1,
                    "width": 72
                  },
                  "requestedTime": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "hidden": true,
                    "index": 7
                  },
                  "status": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "hidden": true,
                    "index": 8
                  },
                  "actualArrivalTime": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "hidden": true,
                    "index": 9
                  },
                  "actualDepartTime": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "hidden": true,
                    "index": 10
                  },
                  "routeName": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "index": 11
                  },
                  "address": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "index": 0,
                    "hidden": false,
                    "width": 200
                  },
                  "date": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "index": 12
                  },
                  "promisedTime": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "index": 13
                  },
                  "plannedDepartTime": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "index": 14
                  },
                  "serviceWindow": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "index": 15
                  },
                  "riderPhone": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "index": 16
                  },
                  "riderId": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "index": 17
                  },
                  "id": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "index": 18
                  },
                  "BreakStartTime": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "index": 19
                  },
                  "BreakEndTime": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "index": 20
                  },
                  "BreakType": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "index": 21
                  },
                  "BreakCategory": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "index": 22
                  },
                  "slackMinutes": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "index": 23
                  },
                  "onboardCount": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "index": 24
                  },
                  "notes": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "index": 25
                  },
                  "odometer": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "index": 26
                  },
                  "otp": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "index": 27
                  },
                  "arriveLat": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "index": 28,
                    "hidden": true
                  },
                  "arriveLng": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "index": 29,
                    "hidden": true
                  },
                  "departLat": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "index": 30,
                    "hidden": true
                  },
                  "departLng": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "index": 31,
                    "hidden": true
                  },
                  "city": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "index": 32
                  },
                  "stopBreakOrd": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "index": 33
                  },
                  "iqId": {
                    "filterTypeId": "null",
                    "filterValues": null,
                    "index": 34,
                    "hidden": true
                  },
                  "spacer": {
                    "filterTypeId": "null",
                    "filterValues": null
                  }
                },
                "sortId": "ETA",
                "sortAsc": false
              }
            }, {
              "id": "c5542cc8-5188-3dcc-19d1-907cf828b4ea",
              "typeId": "map",
              "width": 8,
              "height": 6,
              "x": 6,
              "y": 0,
              "state": {}
            }, {
              "id": "47806f22-355b-002e-1f57-6124913c1518",
              "typeId": "messages",
              "width": 14,
              "height": 6,
              "x": 0,
              "y": 6,
              "state": {
                "columns": {
                  time: {
                    index: 1,
                    hidden: false,
                    width: 100
                  },
                  deliveredTime: {
                    index: 2,
                    hidden: false,
                    width: 100
                  },
                  status: {
                    index: 3,
                    width: 55,
                    hidden: false
                  },
                  message: {
                    index: 4,
                    hidden: false,
                    width: 360
                  },
                  vehicleId: {
                    hidden: false,
                    index: 5,
                    width: 100
                  },
                  lastName: {
                    index: 6,
                    hidden: true,
                    width: 100
                  },
                  driverId: {
                    index: 7,
                    width: 100,
                    hidden: true
                  },
                  routeName: {
                    index: 8,
                    width: 200,
                    hidden: false
                  },
                  read: {
                    hidden: true
                  },
                  firstName: {
                    hidden: true
                  },
                  responseType: {
                    hidden: true
                  },
                  responseMessage: {
                    hidden: true
                  },
                  ackTime: {
                    hidden: true
                  }
                }
              }
            }]
          }
        }
      });
      this.store.pushPayload('dashboard', {
        "data": {
          "type": "dashboard",
          "id": "workspaces-users/messages",
          // ?
          "attributes": {
            "name": "Messages",
            "role": "Road Supervisor",
            // ?
            "category": "workspaces-users",
            // ?
            "startDate": "2019-09-03T04:00:00.000Z",
            "endDate": "2019-09-04T03:59:59.999Z",
            "referenceDate": "2019-09-03T04:00:00.000Z",
            "mapState": {},
            "widgets": [{
              "id": "47806f22-355b-002e-1f57-6124913c1518",
              "typeId": "messages",
              "width": 8,
              "height": 3,
              "x": 0,
              "y": 0,
              "state": {
                "columns": {
                  time: {
                    index: 1,
                    hidden: false,
                    width: 100
                  },
                  deliveredTime: {
                    index: 2,
                    hidden: false,
                    width: 100
                  },
                  status: {
                    index: 3,
                    width: 55,
                    hidden: false
                  },
                  message: {
                    index: 4,
                    hidden: false,
                    width: 260
                  },
                  vehicleId: {
                    hidden: false,
                    index: 5,
                    width: 100
                  },
                  lastName: {
                    index: 6,
                    hidden: true,
                    width: 100
                  },
                  driverId: {
                    index: 7,
                    width: 100,
                    hidden: true
                  },
                  routeName: {
                    index: 8,
                    width: 100,
                    hidden: false
                  },
                  read: {
                    hidden: true
                  },
                  firstName: {
                    hidden: true
                  },
                  responseType: {
                    hidden: true
                  },
                  responseMessage: {
                    hidden: true
                  },
                  ackTime: {
                    hidden: true
                  }
                }
              }
            }]
          }
        }
      });
    }

  });

  _exports.default = _default;
});