define("adept-iq/pods/components/side-drawer/system-config/route-master/component", ["exports", "ember-changeset", "ember-concurrency", "adept-iq/utils/unwrapProxy", "adept-iq/pods/components/side-drawer/system-config/route-master/vehicle-search-config", "adept-iq/models/route-template", "adept-iq/models/zone", "adept-iq/utils/regex-functions", "moment"], function (_exports, _emberChangeset, _emberConcurrency, _unwrapProxy, _vehicleSearchConfig, _routeTemplate, _zone, _regexFunctions, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MAX_DATE = (0, _moment.default)(8640000000000000);
  const MAX_AVAILABILITIES = 8;
  const daysOfService = {
    '0': 'monday',
    '1': 'tuesday',
    '2': 'wednesday',
    '3': 'thursday',
    '4': 'friday',
    '5': 'saturday',
    '6': 'sunday',
    '7': 'holiday'
  };

  var _default = Ember.Component.extend({
    classNames: ['route-master-view'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    daysOfService,
    tableRef: null,
    zoneTableRef: null,
    isDriverBreakModalEnabled: false,
    driverBreakAvailability: null,
    routeTemplate: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    routeTemplateChangeSet: Ember.computed('routeTemplate', function () {
      const routeTemplate = (0, _unwrapProxy.unwrapProxy)(this.get('routeTemplate'));
      return this.setDefaultProperties(routeTemplate);
    }),
    sortedAvailabilities: Ember.computed('routeTemplateChangeSet', function () {
      const routeTemplate = this.get('routeTemplateChangeSet');

      if (routeTemplate) {
        const availabilities = routeTemplate.get('sortedAvailabilities'); // this.set('disableForm', true);
        // this.setDefaultProperties(selectedRow);

        return this.configureRouteTemplateAvailabilities(availabilities);
      }

      return [];
    }),
    hasRowsSelected: Ember.computed.lt('checkedRows.length', 1),

    async init() {
      this._super(...arguments);

      const store = this.get('store');
      const breakTypes = store.peekAll('break-type');
      this.set('breakTypes', breakTypes);
      this.set('affinityTypes', [_zone.AFFINITY_PREFERRED, _zone.AFFINITY_NORMAL, _zone.AFFINITY_LAST_RESORT, _zone.AFFINITY_NO_SERVICE]);
      await this.get('store').findAll('route-template-availability'); // find a better place for api

      await this.get('store').findAll('shift-break'); // find a better place for api
    },

    async willDestroy() {
      const store = this.get('store');
      const routeTemplates = store.peekAll('route-template');
      await routeTemplates.reduce((acu, template) => {
        return acu.then(() => {
          if (template.get('isNew')) {
            template.rollbackAttributes(); // delete any created records
          }
        });
      }, Promise.resolve());

      this._super(...arguments);
    },

    setDefaultProperties(record = null)
    /* disableForms = true */
    {
      const store = this.get('store');
      let pendingRecord = this.get('pendingRecord');

      if (record) {
        pendingRecord = record;
      } else if (!pendingRecord) {
        pendingRecord = store.createRecord('route-template');
        this.set('pendingRecord', pendingRecord);
      }

      const oldAffinities = pendingRecord.get('affinities') || []; // this.resetErrors();
      // if (record) {
      //   const disableBtns = {
      //     new: false,
      //     edit: false,
      //     delete: false,
      //     undo: true
      //   };
      //   this.set('disableBtns', disableBtns);
      //   this.set('disableForms', disableForms);
      // }

      const zoneTableRef = this.get('zoneTableRef');
      if (zoneTableRef) Ember.run.scheduleOnce('afterRender', zoneTableRef, 'refreshData');
      this.set('oldAffinities', oldAffinities);
      return new _emberChangeset.default(pendingRecord);
    },

    configureOptimizationFlags(checked, bitFlag) {
      const routeTemplate = this.get('routeTemplateChangeSet');
      const optimizationFlags = routeTemplate.get('optimizationFlags');

      if (checked) {
        routeTemplate.set('optimizationFlags', optimizationFlags | bitFlag); // turn bit on
      } else {
        routeTemplate.set('optimizationFlags', optimizationFlags & ~bitFlag); // turn bit off
      }
    },

    configureRouteTemplateAvailabilities(availabilities) {
      const store = this.get('store');
      const routeTemplate = this.get('routeTemplate'); // need to create availabilities for mon-sunday + holiday excluding other pre-loaded availabilities

      if (availabilities.length < MAX_AVAILABILITIES) {
        const diffLength = MAX_AVAILABILITIES - availabilities.length;

        for (let i = 0; i < diffLength; i++) {
          const availability = store.createRecord('route-template-availability', {
            routeTemplate
          });
          availabilities.pushObject(availability);
        }
      }

      availabilities.forEach((availability, index) => {
        this.resetRouteTemplateAvailability(availability, index);
      });
      const changesets = availabilities.map(availability => new _emberChangeset.default(availability));
      return changesets;
    },

    onVehicleChange(record, vehicle, path = 'vehicle') {
      record.set(path, vehicle);
    },

    resetRouteTemplateAvailability(availability, daysOfServiceIndex) {
      const daysTime = (0, _moment.default)().day(daysOfServiceIndex);
      const day = daysOfService[daysOfServiceIndex];
      const checked = availability[day];
      availability.setProperties({
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        holiday: false,
        startTime: daysTime.toDate(),
        endTime: MAX_DATE.toDate()
      });
      if (day) availability.set(day, checked);
    },

    saveRecords(records) {
      return records.reduce((acu, record) => {
        return acu.then(() => record.save());
      }, Promise.resolve());
    },

    rollbackPlaceRecord(place) {
      place.get('address').rollbackAttributes();
      place.get('location').rollbackAttributes();
      place.rollbackAttributes();
    },

    saveShiftBreakRecords(records) {
      return records.reduce((acu, record) => {
        return acu.then(async () => {
          if (!record.get('isFloatingBreak')) {
            const place = record.get('place');
            const location = place.get('location');
            const address = place.get('address');
            await location.save();
            await address.save();
            await place.save();
          } else {
            this.rollbackPlaceRecord(record.get('place'));
          }

          record.save();
        });
      }, Promise.resolve());
    },

    onRouteMasterSaveTask: (0, _emberConcurrency.task)(function* () {
      const tableRef = this.get('tableRef');
      const routeTemplate = this.get('routeTemplate');
      const routeTemplateChangeSet = this.get('routeTemplateChangeSet');
      const sortedAvailabilities = this.get('sortedAvailabilities');
      const availabilities = this.get('routeTemplate.availabilities');
      yield routeTemplateChangeSet.save(); // save changeset
      // save all route template availability changesets

      sortedAvailabilities.forEach(availability => {
        const availabilityEndDate = (0, _moment.default)(availability.get('calcEndDate')).toISOString();
        availability.set('shiftEnd', availabilityEndDate);
        availability.save(); // changeset save
      });

      try {
        yield availabilities.reduce((acu, avail) => {
          return acu.then(() => this.saveShiftBreakRecords(avail.get('shiftBreaks')));
        }, Promise.resolve());
        yield this.saveRecords(availabilities);
        yield routeTemplate.save();
        routeTemplate.set('selected', true);
      } catch (e) {
        console.warn(e); //eslint-disable-line
      }

      this.set('pendingRecord', null); // newly record has been saved or been edited

      yield Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
    }),

    onDateChange(record, path, dateStr) {
      record.set(path, dateStr);
      record.execute(); // changeset to execute underlying changes to update computed route length property
    },

    actions: {
      onAdjustDriverBreakCheckBoxClick(event) {
        const checked = event.target.checked;
        this.configureOptimizationFlags(checked, _routeTemplate.ADJUST_BREAK_FLAG);
      },

      onConsolidateCheckBoxClick(event) {
        const checked = event.target.checked;
        this.configureOptimizationFlags(checked, _routeTemplate.CONSOLIDATE_ROUTE_FLAG);
      },

      onSchedulingModeClick(event) {
        const checked = event.target.checked;
        const routeTemplate = this.get('routeTemplateChangeSet');

        if (checked) {
          routeTemplate.set('schedulingMode', _routeTemplate.MANUAL_SCHEDULING_MODE);
        } else {
          routeTemplate.set('schedulingMode', _routeTemplate.AUTOMATIC_SCHEDULING_MODE);
        }
      },

      onKeeperTypeClick(event) {
        const checked = event.target.checked;
        const routeTemplate = this.get('routeTemplateChangeSet');

        if (checked) {
          routeTemplate.set('type', _routeTemplate.ROUTE_TEMPLATE_TYPE_KEEPER);
        } else {
          routeTemplate.set('type', _routeTemplate.ROUTE_TEMPLATE_TYPE_REGULAR);
        }
      },

      newRouteMaster() {},

      editRouteMaster() {},

      undoRouteMaster() {},

      saveRouteMaster() {
        const routeTemplateChangeSet = this.get('routeTemplateChangeSet');
        routeTemplateChangeSet.save(); // testing change set functionality
      },

      deleteRouteMaster() {},

      openSearchModal(availability) {
        const onVehicleChange = this.get('onVehicleChange').bind(this);
        const searchVehicleObj = Ember.Object.create({
          title: 'Find Vehicles',
          hasOverlay: true,
          config: _vehicleSearchConfig.default,
          primaryActionText: 'Select',
          primaryAction: record => {
            onVehicleChange(availability, record);
          }
        });
        this.set('modalAPI', searchVehicleObj);
      },

      openBreakModal(availability, day) {
        availability.set('day', day);
        this.set('isDriverBreakModalEnabled', true);
        this.set('driverBreakAvailability', availability);
      },

      openZoneModal(routeTemplateChangeSet) {
        this.set('zonesAPI', {
          title: 'Assign/Remove Zones',
          routeTemplateChangeSet,
          zoneTableRef: this.get('zoneTableRef'),
          oldAffinities: this.get('oldAffinities')
        });
      },

      onDateChange(record, path, value) {
        this.onDateChange(record, path, value);
      },

      onRouteLengthChange(availability, event) {
        const value = event.target.value;
        const oldVal = availability.get('routeLength');

        if (!_regexFunctions.REGEX_HHMM.test(value)) {
          availability.set('routeLength', oldVal);
          availability.execute();
          return;
        }

        availability.set('routeLength', value);
        availability.execute();
      },

      onAffinitySelect(option) {
        this.set('selectedAffinityType', option);
        const zones = this.get('zoneTableRef.checkedRows');
        zones.setEach('affinityValue', option.val);
      }

    }
  });

  _exports.default = _default;
});