define("adept-iq/pods/components/side-drawer/system-config/route-master/table/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    modelName: 'route-template',
    title: 'Route Master',
    defaultSortId: 'routeName',
    defaultSortAsc: true,
    fetchModelData: true,
    selectFirstRow: true,
    // hideWidgetControls: true,
    columns: [{
      id: 'routeName',
      index: 0,
      type: 'text',
      label: 'Route Name',
      valuePath: 'name',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'active',
      index: 1,
      type: 'boolean',
      label: 'Active',
      valuePath: 'active',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'manualTripsOnly',
      index: 2,
      type: 'text',
      label: 'Manual Trips Only',
      valuePath: 'schedulingMode',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'mon',
      index: 3,
      type: 'date',
      label: 'Mon',
      valuePath: 'monday',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'optimizationFlags',
      index: 4,
      type: 'number',
      label: 'Flags',
      valuePath: 'optimizationFlags',
      defaultWidth: 100,
      searchable: true
    }]
  };
  _exports.default = _default;
});