define("adept-iq/pods/components/workspace-navbar/component", ["exports", "ember-concurrency", "moment", "lodash", "adept-iq/utils/workspace-availability", "adept-iq/models/dashboard"], function (_exports, _emberConcurrency, _moment, _lodash, _workspaceAvailability, _dashboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly,
    and
  } = Ember.computed;
  const DEFAULT_TERM = 'default';
  const BOOKING_TIMELINE_CONFIG_PATH = 'config-Book_Trips/Future_Booking_Timeline';

  var _default = Ember.Component.extend({
    classNames: ['workspace-navbar'],
    classNameBindings: ['reoptimizeInProgress'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    timeFormat: 'm-d-Y',
    currentSchedule: null,
    schedules: null,
    scheduleDate: null,
    isReoptimizeOpen: false,
    isScheduleDashboard: readOnly('workspace.isScheduleDashboard'),
    isDispatchDashboard: readOnly('workspace.isDispatchDashboard'),
    reoptimizeInProgress: readOnly('workspace.reoptimizeInProgress'),
    isExported: readOnly('workspace.isExported'),
    canShowScheduleDate: and('isScheduleDashboard', 'maxScheduleDate', 'minScheduleDate'),

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);
    },

    workspaceName: Ember.computed('workspace.dashboardModel.{category,role,name}', function () {
      const dashboard = this.get('workspace.dashboardModel');

      if (dashboard) {
        const dashboardRole = dashboard.get('role');
        const dashboardName = dashboard.get('name');
        const category = dashboard.get('category');

        if (category && category.includes(DEFAULT_TERM)) {
          return `${dashboardRole} [default]`;
        } else if (dashboardName && dashboardRole) return `${dashboardRole} - ${dashboardName}`;

        return dashboardRole;
      }

      return null;
    }),
    minScheduleDate: Ember.computed('schedules.@each.isExported', function () {
      const schedules = this.get('schedules');
      let schedule = null;

      if (schedules) {
        for (let i = schedules.length - 1; i >= 0; i--) {
          if (schedules[i].get('isExported')) {
            schedule = schedules[i];
            break;
          }
        }
      }

      return this.getMinScheduleDate(schedule);
    }),

    getMinScheduleDate(schedule) {
      const tomorrow = (0, _moment.default)().add(1, 'day').startOf('day').toDate();

      if (!schedule) {
        return tomorrow;
      }

      const scheduleDate = (0, _moment.default)(schedule.get('end')).startOf('day').toDate();

      if ((0, _moment.default)(tomorrow).isAfter(scheduleDate)) {
        return tomorrow;
      }

      return scheduleDate;
    },

    maxScheduleDate: Ember.computed('bookingWindow.value', function () {
      const bookingWindowVal = parseInt(this.get('bookingWindow.value'), 10);
      return bookingWindowVal ? (0, _moment.default)().endOf('day').add(bookingWindowVal, 'days').toDate() : null;
    }),
    progress: Ember.computed('currentSchedule.{isGenerated,status,isScheduled}', function () {
      const isGenerated = this.get('currentSchedule.isGenerated');
      const scheduled = this.get('currentSchedule.isScheduled');

      if (isGenerated) {
        return 50;
      } else if (scheduled) {
        return 100;
      }

      return 0;
    }),
    fetchBookingWindow: (0, _emberConcurrency.task)(function* () {
      const config = yield this.store.findRecord('cs-config-item', BOOKING_TIMELINE_CONFIG_PATH);

      if (config) {
        this.set('bookingWindow', config);
      }
    }),
    fetchSchedulesTask: (0, _emberConcurrency.task)(function* () {
      let schedules = yield this.store.findAll('schedule');

      if (schedules) {
        schedules = schedules.sortBy('end');
      }

      this.set('schedules', schedules);
      this.findOrCreateScheduleToday(schedules);
    }),

    async findOrCreateScheduleToday(schedules) {
      const {
        startDate,
        endDate
      } = this.workspace.getProperties('startDate', 'endDate');
      let schedule = (0, _lodash.findLast)(schedules, s => (0, _workspaceAvailability.processTimeConstraints)(startDate, endDate, s.get('start'), s.get('end')) && !s.get('isExported'));

      if (!schedule) {
        // create schedule if not found
        schedule = await this.createSchedule();
      }

      this.set('currentSchedule', schedule);
      this.workspace.set('currentSchedule', schedule);
    },

    async createSchedule() {
      const {
        startDate,
        endDate
      } = this.workspace.getProperties('startDate', 'endDate');
      let scheduleConfiguration = await this.get('store').findAll('schedule-configuration');

      if (scheduleConfiguration) {
        scheduleConfiguration = scheduleConfiguration.get('firstObject');
      } // ensure name is unique


      const baseName = (0, _moment.default)(startDate).format('YYYY-MM-DD');
      const ssSchedule = this.get('store').createRecord('schedule', {
        start: startDate,
        end: endDate,
        name: baseName,
        status: 'unscheduled',
        scheduleConfiguration
      });
      return ssSchedule.save();
    },

    updateTimeRange: Ember.observer('workspace.dashboardModel.role', function () {
      const currentDate = new Date();
      const nextDay = (0, _moment.default)().add(1, 'day').toDate();
      let startDate = this.get('workspace.startDate') ? this.get('workspace.startDate') : nextDay; //Dispatch workspace is selected set current date

      if (_dashboard.DISPATCH_ROLENAME === this.get('workspace.dashboardModel.role')) {
        this.updateDispatchWorkspaceTimeRange(currentDate);
        return;
      }

      if (startDate.getTime() < nextDay.getTime()) {
        startDate = nextDay;
      }

      this.updateSchedulingWorkspaceTimeRange(startDate);
    }),

    updateDispatchWorkspaceTimeRange(date) {
      const momentDate = (0, _moment.default)(date);
      this.workspace.setProperties({
        startDate: momentDate.startOf('day').toDate(),
        endDate: momentDate.endOf('day').toDate()
      });
    },

    updateSchedulingWorkspaceTimeRange(date) {
      const momentDate = (0, _moment.default)(date);
      this.workspace.setProperties({
        startDate: momentDate.startOf('day').toDate(),
        endDate: momentDate.endOf('day').toDate()
      });
      this.get('fetchSchedulesTask').perform();
      this.get('fetchBookingWindow').perform();
      this.set('scheduleDate', date); // clear out any checked items

      this.get('activeContext.topActiveContext').reset();
    },

    actions: {
      onUpdateTimeRangeScheduleWorkspace(selectedDate) {
        this.updateSchedulingWorkspaceTimeRange(selectedDate);
      },

      openReoptimizeModal() {
        const currentSchedule = this.get('currentSchedule');
        this.get('workspace').pushState('reoptimize', {
          currentSchedule
        });
      }

    }
  });

  _exports.default = _default;
});