define("adept-iq/models/schedule", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SCHEDULE_UNSCHEDULED_STATUS = _exports.SCHEDULE_GENERATED_STATUS = _exports.SCHEDULE_EXPORT_STATUS = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  const SCHEDULE_EXPORT_STATUS = 'exported';
  _exports.SCHEDULE_EXPORT_STATUS = SCHEDULE_EXPORT_STATUS;
  const SCHEDULE_GENERATED_STATUS = 'in-progress';
  _exports.SCHEDULE_GENERATED_STATUS = SCHEDULE_GENERATED_STATUS;
  const SCHEDULE_UNSCHEDULED_STATUS = 'unscheduled';
  _exports.SCHEDULE_UNSCHEDULED_STATUS = SCHEDULE_UNSCHEDULED_STATUS;

  var _default = Model.extend({
    name: attr('string'),
    start: attr('ss-date'),
    end: attr('ss-date'),
    status: attr('string', {
      defaultValue: 'unscheduled'
    }),
    // E.g. 'scheduled', 'unscheduled', 'in progress', 'exported'
    engineDatasetId: attr('string'),
    metric: belongsTo('metric'),
    provider: belongsTo('provider'),
    dispatchSchedule: belongsTo('dispatch-schedule'),
    scheduleConfiguration: belongsTo('schedule-configuration'),
    routes: hasMany('route'),
    trips: hasMany('trip'),
    isUnScheduled: Ember.computed.equal('status', SCHEDULE_UNSCHEDULED_STATUS),
    isGenerated: Ember.computed.equal('status', SCHEDULE_GENERATED_STATUS),
    isExported: Ember.computed.equal('status', SCHEDULE_EXPORT_STATUS),
    locked: Ember.computed.alias('dispatchSchedule.locked')
  });

  _exports.default = _default;
});