define("adept-iq/pods/components/iq-widgets/schedule-column-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/schedule-column-widget/config"], function (_exports, _component, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['analytics-widget', 'schedule-column-widget'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    table: null,
    config: _config.default,

    init() {
      this._super(...arguments); // eslint-disable-next-line no-console


      console.log(this.get('workspace.isScheduleDashboard'));
    },

    fetchDataQuery() {
      const store = this.get('store');
      return store.findAll('metric');
    }

  });

  _exports.default = _default;
});