define("adept-iq/pods/components/side-drawer/add-break/component", ["exports", "ember-light-table", "adept-iq/utils/unwrapProxy", "moment", "ember-concurrency", "lodash", "adept-iq/pods/components/side-drawer/add-break/config", "adept-iq/utils/save-records", "adept-iq/classes/impact-stop"], function (_exports, _emberLightTable, _unwrapProxy, _moment, _emberConcurrency, _lodash, _config, _saveRecords, _impactStop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FIXED_BREAK_TYPE = 'fixed';

  var _default = Ember.Component.extend({
    classNames: ['add-break-side-drawer'],
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    onShrinkClick: null,
    onCloseClick: null,
    onSolutionRowClick: null,
    usePlaceHolderTask: true,
    dispatchSchedule: null,
    stopsTable: null,
    currentState: null,
    driverBreak: Ember.computed.readOnly('stackFrame.options.driverBreak'),
    trips: Ember.computed.readOnly('stackFrame.options.trips'),
    saveRecordFunction: Ember.computed.readOnly('stackFrame.options.saveDriverBreakFunction'),
    timeFormat: 'm-d-Y h:i K',
    requestTime: null,
    minDate: Ember.computed.readOnly('driverBreak.route.plannedStartTime'),
    value: null,
    disabled: false,
    addressOptions: null,
    breakTypes: null,
    fields: _config.fields,
    selectedImpactPeriod: 'after',
    rowComponent: 'table/rows/colored-row',
    errorText: null,
    selectedBreakTypeId: null,
    fieldsChangeObserver: Ember.observer('driverBreak.breakType', 'driverBreak.place', 'driverBreak.requestTime', 'driverBreak.plannedDuration', function () {
      if (this.get('getAddBreakRecords.isRunning') || Ember.isEmpty(this.get('driverBreak.breakType')) || Ember.isEmpty(this.get('driverBreak.plannedDuration')) || Ember.isEmpty(this.get('driverBreak.requestTime')) || Ember.isEmpty(this.get('driverBreak.place.address'))) {
        return;
      }

      this.addBreak();
    }),
    observeSelectImpactPeriod: Ember.observer('selectedImpactPeriod', function () {
      const period = this.get('selectedImpactPeriod'); // TODO: Can the table rows be updated without an observer?

      this.get('stopsTable').setRows(this.get(`impact.${period}.stops`));
    }),

    init() {
      this._super(...arguments);

      const stopsTable = new _emberLightTable.default(_config.stopColumns); // eslint-disable-next-line new-cap

      this.set('solutions', Ember.A([]));
      this.set('stopsTable', stopsTable);
      this.set('driverBreak.requestTime', null);
      this.set('driverBreak.breakType', null);
      this.set('timePlaceHolder', 'YYYY-MM-DD HH:MM');
    },

    didInsertElement() {
      const breakTypes = this.get('store').peekAll('break-type');
      this.set('breakTypes', breakTypes);
      this.loadCurrentState(); // side panel width for add-break

      Ember.$('.side-drawer-modal').width('600px');
    },

    loadCurrentState() {
      const currentState = this.get('currentState');

      if (currentState) {
        this.set('driverBreak.breakType', currentState.selectedBreakType);
        this.set('driverBreak.requestTime', currentState.requestTime);
        this.set('driverBreak.plannedDuration', currentState.duration);
      }
    },

    getRouteImpact(solutions) {
      const store = this.get('store');
      const currentSolution = solutions || {};
      const currentRouteStops = this.get('driverBreak.route.dispatchRoute.orderedStopsWithBreaks') || [];
      const currentStops = solutions.stops || [];
      let routeOrdinal = 1; // @TODO we should refactor to make it more flexible for map context

      const impact = Ember.Object.create({
        routeOtp: currentSolution.otp,
        otpDelta: currentSolution.otp.deltaValue,
        before: {
          otp: currentSolution.otp.originalValue,
          stops: currentRouteStops.map(stop => {
            const stopId = stop.id.replace(/(B)/img, '');
            const stopPoint = store.peekRecord('stop-point', stopId);

            if (!stopPoint) {
              // eslint-disable-next-line no-console
              console.log('Is the record Empty');
            }

            return _impactStop.default.create({
              id: stop.id,
              eta: stopPoint.get('eta'),
              otp: stop.get('otp'),
              promiseTime: stop.get('promisedTime'),
              otpValue: stop.get('otpValue'),
              polyline: null,
              mrt: null,
              routeOrdinal: stopPoint.get('routeOrdinal'),
              stopPoint: stopPoint
            });
          })
        },
        after: {
          otp: currentSolution.otp.newStatus,
          stops: currentStops.map(stop => {
            const stopId = stop.id.replace(/(B)/img, '');
            const stopPoint = store.peekRecord('stop-point', stopId) || (0, _unwrapProxy.unwrapProxy)(this.get('driverBreak.stopPoint'));
            return _impactStop.default.create({
              id: stop.id,
              eta: stop.eta.newValue,
              otp: stop.otp.newStatus,
              otpValue: stop.otp,
              mrt: stop.otp.delta,
              promiseTime: stop.promiseTime,
              polyline: stop.polyline,
              routeOrdinal: routeOrdinal++,
              stopPoint,
              coloredRow: stopPoint.isNew
            });
          })
        }
      });
      return impact;
    },

    getAddBreakRecords: (0, _emberConcurrency.task)(function* () {
      const continousOptRouteBreakAdapter = this.get('store').adapterFor('continous-opt-route-break');
      const dispatchScheduleId = this.get('driverBreak.route.dispatchRoute.dispatchSchedule.id');
      const destinationRouteId = this.get('driverBreak.route.id');
      const plannedDuration = this.get('driverBreak.plannedDurationSeconds'); //Payload request is passed in seconds

      const breakType = this.get('driverBreak.breakType.id');
      const requestTime = this.get('driverBreak.requestTime');
      let breakStop = {};

      if (breakType === 'fixed') {
        const position = this.get('driverBreak.place.address.position');
        const geoNode = this.get('driverBreak.place.address.places.firstObject.location.geoNode');
        breakStop = {
          xyCoord: {
            x: position.lat,
            y: position.lng
          },
          geoNode
        };
      }

      const payload = {
        dispatchScheduleId,
        destinationRouteId,
        break: {
          requestTime,
          plannedDuration,
          breakStop
        }
      };

      if (breakType === 'floating') {
        delete payload.break.breakStop;
      } // reset stops table data


      this.set('stopsTable.rows', []);
      const {
        data: {
          attributes: {
            destinationRoutes: solutions
          }
        }
      } = yield continousOptRouteBreakAdapter.postAddBreak(payload);
      return solutions;
    }).restartable(),

    addBreak() {
      const valid = this.validateRecords();
      this.set('canSave', valid);

      if (valid) {
        const period = this.get('selectedImpactPeriod');
        this.get('getAddBreakRecords').perform().then(solutions => {
          if (!Ember.isEmpty(solutions)) {
            const impact = this.getRouteImpact(solutions[0]);
            this.set('impact', impact);
            this.get('stopsTable').setRows(impact[period].stops);
          }
        });
      }
    },

    validateRecords() {
      let valid = true;
      const record = this.get('driverBreak') || [];
      this.set('errorText', '');
      const routeBreakType = this.get('selectedBreakTypeId');
      const route = (0, _unwrapProxy.unwrapProxy)(record.get('route'));
      const estimatedStartMoment = (0, _moment.default)(record.get('requestTime'));
      const estimatedEndMoment = (0, _moment.default)(record.get('projectedEndDate'));
      const routePlannedStartTime = (0, _moment.default)(route.get('plannedStartTime'));
      const routeActualEndTime = route.get('actualEndTimestamp') ? (0, _moment.default)(route.get('actualEndTimestamp')) : null;
      const routeOrdinal = (0, _unwrapProxy.unwrapProxy)(route.get('dispatchRoute')).findNewBreakRoutePosition(record);

      if (routeOrdinal === -1) {
        valid = false;
        this.set('errorText', 'Cannot find a route position for the break start time.');
      }

      record.set('plannedRouteOrdinal', routeOrdinal);

      if (Ember.isNone(routeBreakType)) {
        valid = false;
        this.set('errorText', 'Must select a break type');
      }

      if (routeBreakType === FIXED_BREAK_TYPE && Ember.isEmpty(record.get('place.location.lat'))) {
        valid = false;
        this.set('errorText', 'Fixed break type must have an address.');
      }

      if (estimatedStartMoment.isSameOrAfter(estimatedEndMoment)) {
        valid = false;
        this.set('errorText', 'Break start time must be before Break end time.');
      }

      if (estimatedStartMoment.isBefore(routePlannedStartTime)) {
        valid = false;
        this.set('errorText', 'Break start time must be after the route\'s planned start time.');
      }

      if (routeActualEndTime) {
        if (estimatedEndMoment.isAfter(routeActualEndTime)) {
          valid = false;
          this.set('errorText', 'Break end time must be before the route\'s actual end time.');
        }
      }

      record.set('estimatedStart', estimatedStartMoment.toDate());
      record.set('estimatedEnd', estimatedEndMoment.toDate());
      return valid;
    },

    commit() {
      const records = [];
      const saveRecordFunction = this.get('saveRecordFunction');
      const driverBreak = this.get('driverBreak');
      records.push(driverBreak);

      if (!(0, _lodash.isUndefined)(saveRecordFunction) && saveRecordFunction !== null) {
        return saveRecordFunction(records).then(() => {
          this.onCloseClick();
        }).catch(e => {
          // we should handle these errors in the console for developers i.e adapter failures, network failure details
          // let form / widgets components handle error messages for end users to help ease user feedback
          // most of these errors are not percivable by the user anyways and are for developers
          console.warn(e.message, e); // eslint-disable-line no-console
        });
      }

      return (0, _saveRecords.default)(records).then(() => {
        this.onCloseClick();
      }).catch(e => {
        // we should handle these errors in the console for developers i.e adapter failures, network failure details
        // let form / widgets components handle error messages for end users to help ease user feedback
        // most of these errors are not percivable by the user anyways and are for developers
        console.warn(e.message, e); // eslint-disable-line no-console
      });
    },

    resetSelections() {
      const driverBreak = this.get('driverBreak');
      driverBreak.set('requestTime', '');
      driverBreak.set('breakType', null);
      driverBreak.set('plannedDuration', 15);
      driverBreak.set('notes', null);
      this.set('selectedBreakTypeId', null);
      this.rollback((0, _unwrapProxy.unwrapProxy)(driverBreak.get('place.address')));
      this.rollback((0, _unwrapProxy.unwrapProxy)(driverBreak.get('place.location')));
    },

    rollback(model) {
      const changedAttributeModel = model.changedAttributes();

      if (changedAttributeModel) {
        (0, _lodash.forEach)(changedAttributeModel, function (attributevalues, attributeName) {
          model.set(attributeName, attributevalues[0]);
        });
      }
    },

    actions: {
      onbreakTypeChange(val) {
        const record = this.get('driverBreak');
        this.set('selectedBreakTypeId', val.name);
        record.set('breakType', val);
      },

      onChangeTime(valuePath, value) {
        const momentTime = (0, _moment.default)(value[0]);
        const record = this.get('driverBreak');
        record.set(valuePath, momentTime.toDate());
      },

      onRefreshButtonClick() {
        this.resetSelections();
      },

      onCellValueChange(record, valuePath, value, options) {
        record.set(`${valuePath}.${options.valuePath}`, value);
      },

      onSaveClick() {
        const commitAction = this.commit.bind(this);
        const tip = 'Would you like to Commit your changes';
        const title = 'Add Driver Break';
        this.get('tooltip').setProperties({
          tip,
          title,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return commitAction();
          },
          secondaryActionText: 'Cancel',
          hasOverlay: true
        });
      },

      onShrinkClick() {
        let currentState = this.get('currentState');
        const selectedBreakType = this.get('driverBreak.breakType');
        const requestTime = this.get('driverBreak.requestTime');
        const duration = this.get('driverBreak.plannedDuration'); // save current manual settings

        currentState = {
          selectedBreakType,
          requestTime,
          duration
        };
        this.set('currentState', currentState);
        this.onShrinkClick();
      }

    }
  });

  _exports.default = _default;
});