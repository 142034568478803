define("adept-iq/serializers/trip", ["exports", "adept-iq/serializers/-ss-schedulingService"], function (_exports, _ssSchedulingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const convertStatusReason = {
    'No Show No Answer': 'noShowNoAnswer',
    'No Show Same Day Cancel No Fault': 'noShowSameDayCancelNoFault',
    'No Show Same Day Cancel': 'noShowSameDayCancel',
    'No Show Cancel At Door': 'noShowCancelAtDoor',
    'No Show Not Ready': 'noShowNotReady',
    'No Show No Fault': 'noShowNoFault',
    NSNA: 'noShowNoAnswer',
    NSSDCNF: 'noShowSameDayCancelNoFault',
    NSSDC: 'noShowSameDayCancel',
    NSCD: 'noShowCancelAtDoor',
    NSNR: 'noShowNotReady',
    NSNF: 'noShowNoFault'
  };

  var _default = _ssSchedulingService.default.extend({
    serialize(snapshot, options) {
      const json = this._super(snapshot, options);

      json.data.attributes = json.data.attributes || {};

      if (Ember.isPresent(json.data.relationships)) {
        const scheduleRelationship = json.data.relationships.schedule;
        const subscriptionRelationship = json.data.relationships.subscription;
        const segmentRelationship = json.data.relationships.segment;
        const bookingRelationship = json.data.relationships.booking;
        const providerRelationship = json.data.relationships.provider;
        const reason = json.data.relationships.reason;
        const currentProviderName = json.data.attributes.currentProviderName;

        if (Ember.isPresent(scheduleRelationship)) {
          delete json.data.relationships.schedule;
        }

        if (Ember.isPresent(subscriptionRelationship)) {
          delete json.data.relationships.subscription;
        }

        if (Ember.isPresent(segmentRelationship)) {
          delete json.data.relationships.segment;
        }

        if (Ember.isPresent(bookingRelationship)) {
          delete json.data.relationships.booking;
        }

        if (Ember.isPresent(providerRelationship)) {
          delete json.data.relationships.provider;
        }

        if (Ember.isPresent(reason)) {
          delete json.data.relationships.reason;
        }

        if (Ember.isPresent(currentProviderName)) {
          const currentProviderNameJson = {
            'data': {
              'type': 'providerName',
              'id': currentProviderName
            }
          };
          json.data.relationships.currentProviderName = currentProviderNameJson;
        }
      }

      if (Ember.isPresent(json.data.attributes)) {
        delete json.data.attributes.otp;
        delete json.data.attributes.otpValue;
        delete json.data.attributes.statusReason;
        delete json.data.attributes.currentProviderName;
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      resourceHash.attributes = resourceHash.attributes || {};
      resourceHash.relationships = resourceHash.relationships || {};

      if (resourceHash.attributes) {
        // the status reason arrive from either avlm-route-exec-event as a code or trip topic as the actual id of the no-show-reason
        // evaulate based on either and use the correct id to associate the no-show-reason-code to trip
        if (Ember.isPresent(resourceHash.attributes.statusReason)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          let {
            statusReason
          } = resourceHash.attributes;
          statusReason = convertStatusReason[statusReason] || statusReason;
          relationships.reason = {
            data: {
              type: 'no-show-reason-code',
              id: statusReason
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.attributes.statusReason;
        }

        if (Ember.isPresent(resourceHash.attributes.currentProviderName)) {
          resourceHash.relationships.provider = {
            data: {
              type: 'provider',
              id: resourceHash.attributes.currentProviderName
            }
          };
          delete resourceHash.attributes.currentProviderName;
        }
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});