define("adept-iq/classes/road-supervisor-instance", ["exports", "ember-concurrency", "adept-iq/utils/geolib", "adept-iq/utils/turn-by-turn", "adept-iq/utils/turn-by-turn-icon", "moment"], function (_exports, _emberConcurrency, _geolib, _turnByTurn, _turnByTurnIcon, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EPSILON = 0.05; // how much history to retain; bigger applies more smoothing

  var LOCATION_HISTORY_DEPTH = 1000;
  var GUIDANCE_HISTORY_DEPTH = 5;
  var SPOKEN_HISTORY_DEPTH = 5; // number of (most recent) points used to determine speed

  const SPEED_AVERAGING_WINDOW = 2; // number of (most recent) meters to consider when computing heading

  const HEADING_AVERAGING_WINDOW = 10; // compensation for GPS delay (s)

  var LOOKAHEAD_SECONDS = 0; // how close to the polyline we must be to be "on" it (m)

  var NAVIGATION_POLYLINE_PROXIMITY_THRESHOLD = 50; // how many degrees our bearing must be within to be "on" the polyline

  var BEARING_TOLERANCE = 150; // if within threshold, guidance spoken automatically (m)

  var SPOKEN_GUIDANCE_DISTANCE_THRESHOLD = 160; // how long before maneuver is instruction spoken (s)

  var SPOKEN_GUIDANCE_TIME_THRESHOLD = 5; // don't repeat any instruction within this window (ms)

  var SPOKEN_GUIDANCE_DEBOUNCE_TIME = 5000; // ignore heading at beginning and end of each segment (m); allows for sharp turns

  const SEGMENT_HEADING_EXCLUSION_MARGIN = 10; // don't examine heading speed if moving slowly

  const MIN_HEADING_SPEED = 3.0;

  function formatDistance(distanceInMeter) {
    if (!distanceInMeter) {
      // eslint-disable-next-line no-undefined
      return undefined;
    }

    const meterToMiConvert = 1 / 1609.344;
    const num = distanceInMeter * meterToMiConvert;

    if (num < '0.2') {
      const distFt = Math.ceil(num * 5280);

      if (distFt < 100) {
        return Math.ceil(distFt / 10) * 10 + ' feet';
      } else if (distFt < 300) {
        return Math.ceil(distFt / 25) * 25 + ' feet';
      }

      return Math.ceil(distFt / 100) * 100 + ' feet';
    }

    return num.toFixed(1) + ' miles';
  }

  var _default = Ember.Object.extend({
    isAtDestination: false,
    bearing: 0,
    speed: 0,
    gpsSpeed: null,
    isOffRoute: false,
    _locationHistory: null,
    _guidanceHistory: null,
    _spokenHistory: null,
    tick: function () {
      const nextTick = Ember.run.later(this, function () {
        if (this.get('isDestroyed')) return;
        this.notifyPropertyChange('_travelTime');
        this.tick();
      }, 10000);
      this.set('nextTick', nextTick);
    },
    navigationPolyline: Ember.computed.readOnly('api.polyline'),
    guidanceArray: Ember.computed.readOnly('api.guidance'),
    travelTime: Ember.computed.readOnly('_travelTime'),
    endGuidance: Ember.computed.readOnly('guidanceArray.lastObject'),
    destination: Ember.computed.readOnly('navigationPolyline.lastObject'),
    location: Ember.computed.readOnly('_locationHistory.lastObject.point'),
    guidance: Ember.computed.readOnly('_guidanceHistory.lastObject.guidance'),
    // distance from location to nearest "snapped" location on polyline
    navigationPolylineDistance: Ember.computed.readOnly('_projection.distance'),
    // polyline bearing at the "snapped" location
    navigationPolylineBearing: Ember.computed.readOnly('_projection.bearing'),
    // "snapped" location moved ahead on polyline
    navigationPolylineLocation: Ember.computed.readOnly('_advancedProjection.point'),
    // distance from start of polyline to advanced "snapped" location
    navigationPolylineOffset: Ember.computed.readOnly('_advancedProjection.offset'),
    _travelTime: Ember.computed('api.currentRoute.eta', function () {
      // We don't want keep calculating _travelTime, we only calculate once per tick.
      const eta = this.get('api.currentRoute.eta');
      if (Ember.isPresent(eta) && eta > _moment.default.utc().format()) return (0, _moment.default)(eta).diff(_moment.default.utc());
      return null;
    }),
    previousGuidance: Ember.computed('guidance', 'guidanceArray', function () {
      const guidance = this.get('guidance');
      const guidanceArray = this.get('guidanceArray');
      const index = guidanceArray ? guidanceArray.indexOf(guidance) : -1;
      return index > 1 ? guidanceArray[index - 1] : null;
    }),
    guidanceMessage: Ember.computed('guidance', 'previousGuidance', 'endGuidance', 'navigationPolylineOffset', 'travelTime', function () {
      const d = this.get('navigationPolylineOffset');
      const guidance = this.get('guidance');
      const previousGuidance = this.get('previousGuidance');
      const endGuidance = this.get('endGuidance');
      const travelTime = this.get('travelTime');
      if (Ember.isNone(d) || !guidance || !endGuidance) return null; // `d` is where we are; d0 < d < d1
      // `[d0, d1]` is the leg from previous guidance to current
      // `[d1, d2]` is the remainder from current guidance to end

      const t0 = previousGuidance ? previousGuidance.travelTimeInSeconds : 0;
      const d0 = previousGuidance ? previousGuidance.routeOffsetInMeters : 0;
      const t1 = guidance.travelTimeInSeconds;
      const d1 = guidance.routeOffsetInMeters;
      const t2 = endGuidance.travelTimeInSeconds;
      const d2 = endGuidance.routeOffsetInMeters;

      if (d < d0 || d > d1) {
        console.warn('failed sanity check: projected distance does not lie between previous and next guidance events'); // eslint-disable-line no-console
      }

      if (t0 > t1 || d0 > d1) {
        console.warn('failed sanity check: previous guidance occurs after next guidance'); // eslint-disable-line no-console
      }

      if (t1 > t2 || d0 > d2) {
        console.warn('failed sanity check: next guidance occurs after end guidance'); // eslint-disable-line no-console
      } // travel time of leg, adjusted by distance remaining


      const travelTimeToNextGuide = d1 - d0 > 0 ? (t1 - t0) * (1.0 - (d - d0) / (d1 - d0)) : null;
      const travelDistanceToNextGuide = d1 - d; // const travelTime = travelTimeToNextGuide + (t2 - t1);

      const travelDistance = d2 - d;
      return Object.assign({
        previousGuidance,
        // time and distance to next instruction
        travelTimeToNextGuide,
        travelDistanceToNextGuide: formatDistance(travelDistanceToNextGuide),
        // time and distance to end
        travelTime,
        travelDistance: formatDistance(travelDistance),
        instructIcon: (0, _turnByTurnIcon.renderTurnByTurnIcon)(guidance),
        text: (0, _turnByTurn.renderTurnByTurnGuidance)(guidance)
      }, guidance);
    }),
    // intermediates
    _projection: Ember.computed('navigationPolyline', 'location', function () {
      const navigationPolyline = this.get('navigationPolyline');
      const location = this.get('location');
      if (!navigationPolyline || !location) return null;
      const locationHistory = this.get('_locationHistory');
      const lastLocationHistory = locationHistory.get('lastObject');
      const segementTravelledDistance = lastLocationHistory.distance;
      return (0, _geolib.projectPointOnPolyline)(location, navigationPolyline, segementTravelledDistance);
    }),
    _advancedProjection: Ember.computed('_projection', 'navigationPolyline', 'navigationPolylineLength', 'speed', function () {
      const projection = this.get('_projection');
      const navigationPolyline = this.get('navigationPolyline');
      const navigationPolylineLength = this.get('navigationPolylineLength');
      if (!projection || !navigationPolyline) return null;
      const speed = this.get('speed') || 0;
      const lookaheadOffset = LOOKAHEAD_SECONDS * speed;
      let advancedOffset = projection.offset + lookaheadOffset; // don't project beyond end of polyline

      if (!Ember.isNone(navigationPolylineLength)) {
        advancedOffset = Math.min(navigationPolylineLength, advancedOffset);
      }

      return (0, _geolib.projectDistanceOnPolyline)(advancedOffset, navigationPolyline);
    }),
    navigationPolylineLength: Ember.computed('navigationPolyline', function () {
      const navigationPolyline = this.get('navigationPolyline');
      if (!navigationPolyline) return null;
      return (0, _geolib.computePolylineLength)(navigationPolyline);
    }),

    init() {
      this._super(...arguments);

      this.set('_locationHistory', []);
      this.set('_guidanceHistory', []);
      this.set('_spokenHistory', []);
      this.setRSConfigurations();
      this.tick();
    },

    willDestroyElement() {
      const nextTick = this.get('nextTick');
      Ember.run.cancel(nextTick);
    },

    setRSConfigurations() {
      const configuration = this.delegate.getRSConfigurations();
      EPSILON = configuration.epsilon ? Number(configuration.epsilon) : EPSILON;
      LOCATION_HISTORY_DEPTH = configuration.locationHistoryDepth ? Number(configuration.locationHistoryDepth) : LOCATION_HISTORY_DEPTH;
      GUIDANCE_HISTORY_DEPTH = configuration.guindanceHistoryDepth ? Number(configuration.guindanceHistoryDepth) : GUIDANCE_HISTORY_DEPTH;
      SPOKEN_HISTORY_DEPTH = configuration.spokenHistoryDepth ? Number(configuration.spokenHistoryDepth) : SPOKEN_HISTORY_DEPTH;
      LOOKAHEAD_SECONDS = configuration.lookAheadSeconds ? Number(configuration.lookAheadSeconds) : LOOKAHEAD_SECONDS;
      NAVIGATION_POLYLINE_PROXIMITY_THRESHOLD = configuration.navigationPolylineProximityThreshold ? Number(configuration.navigationPolylineProximityThreshold) : NAVIGATION_POLYLINE_PROXIMITY_THRESHOLD;
      BEARING_TOLERANCE = configuration.bearingTolerance ? Number(configuration.bearingTolerance) : BEARING_TOLERANCE;
      SPOKEN_GUIDANCE_DISTANCE_THRESHOLD = configuration.spokenGuidanceDistanceThreshold ? Number(configuration.spokenGuidanceDistanceThreshold) : SPOKEN_GUIDANCE_DISTANCE_THRESHOLD;
      SPOKEN_GUIDANCE_TIME_THRESHOLD = configuration.spokenGuidanceTimeThreshold ? Number(configuration.spokenGuidanceTimeThreshold) : SPOKEN_GUIDANCE_TIME_THRESHOLD;
      SPOKEN_GUIDANCE_DEBOUNCE_TIME = configuration.spokenGuidanceDebounceTime ? Number(configuration.spokenGuidanceDebounceTime) : SPOKEN_GUIDANCE_DEBOUNCE_TIME;
    },

    onGPSLocation(val) {
      const location = [val.coords.latitude, val.coords.longitude];
      const locationHistory = this.get('_locationHistory');
      const t = Date.now(); // update location, bearing, speed

      if (locationHistory.length === 0) {
        locationHistory.pushObject({
          t,
          point: location,
          distance: 0
        });
      } else {
        const previous = locationHistory.get('lastObject');
        const [segment] = (0, _geolib.computePolylineSegments)([previous.point, location]);
        locationHistory.pushObject({
          t,
          point: location,
          distance: segment.length,
          gpsSpeed: val.coords.speed
        });

        while (locationHistory.length > LOCATION_HISTORY_DEPTH) {
          locationHistory.shiftObject();
        }

        if (locationHistory.length > 1) {
          const [last] = locationHistory.slice(-1); // update speed

          const [speedFirst] = locationHistory.slice(-SPEED_AVERAGING_WINDOW);
          const [speedSegment] = (0, _geolib.computePolylineSegments)([speedFirst.point, last.point]);
          const speed = 1000.0 * speedSegment.length / (last.t - speedFirst.t);
          this.set('speed', speed);

          if (!Ember.isNone(speed)) {
            this.set('gpsSpeed', val.coords.speed);
          } // update heading


          let headingSegment;

          for (let i = locationHistory.length - 2; i >= 0; i--) {
            headingSegment = (0, _geolib.computePolylineSegments)([locationHistory[i].point, last.point])[0];
            if (headingSegment.length >= HEADING_AVERAGING_WINDOW || i === 0) break;
          } //do not update bearing everytime


          if (val.coords.speed > MIN_HEADING_SPEED) {
            this.set('bearing', headingSegment.bearing);
          }
        } else {
          const navigationPolylineBearing = this.get('navigationPolylineBearing'); // Avoid bearing diff cause recalculating.

          if (!Ember.isNone(navigationPolylineBearing)) {
            this.set('bearing', navigationPolylineBearing);
          }
        }
      } // If IsOffRoute we will wait new guidanceArray and update guidance after that.


      if (!this.checkIsOffRoute()) {
        this.updateGuidance();
      }

      if (this.checkifAtDestination(location)) {
        this.delegate.onAtDestinationEvent();
        this.set('isAtDestination', true);
      }
    },

    onGuidanceArrayChanged: Ember.observer('guidanceArray', function () {
      this.updateGuidance();
      this.notifyPropertyChange('_travelTime');
    }),

    updateGuidance() {
      const guidanceHistory = this.get('_guidanceHistory');
      const spokenHistory = this.get('_spokenHistory');
      const t = Date.now();

      const guidance = this._computeActiveGuidance();

      const lastGuidance = guidanceHistory ? guidanceHistory.get('lastObject.guidance') : null;

      if (guidance !== lastGuidance) {
        guidanceHistory.pushObject({
          t,
          guidance
        }); // can be null

        while (guidanceHistory.length > GUIDANCE_HISTORY_DEPTH) {
          guidanceHistory.shiftObject();
        }
      } // speak if necessary


      if (this._canSpeakGuidance(guidance)) {
        const guidanceMessage = this.get('guidanceMessage');
        const speakAtDist = guidanceMessage.travelDistanceToNextGuide.replace(' ft', 'feet');
        const message = guidanceMessage.message + ' in ' + speakAtDist;
        this.delegate.onSpeechEvent(message);
        spokenHistory.pushObject({
          t,
          guidance
        });

        while (spokenHistory.length > SPOKEN_HISTORY_DEPTH) {
          spokenHistory.shiftObject();
        }
      }
    },

    checkifAtDestination(location) {
      if (this.destination) {
        const distance = geolib.getDistance((0, _geolib.toGeolib)(this.destination), (0, _geolib.toGeolib)(location));
        return distance <= 50;
      }
    },

    checkIsOffRoute() {
      const wasOffRoute = this.get('isOffRoute');
      const guidanceArray = this.get('guidanceArray');
      const offset = this.get('navigationPolylineOffset');
      const distance = this.get('navigationPolylineDistance');
      const navigationPolylineBearing = this.get('navigationPolylineBearing');
      const bearing = this.get('bearing');
      const speed = this.get('speed');
      const gpsSpeed = this.get('gpsSpeed');
      let isOffRoute = false;

      if (!guidanceArray || Ember.isNone(offset) || Ember.isNone(navigationPolylineBearing) || Ember.isNone(bearing)) {// not enough data available
      } else if (speed < MIN_HEADING_SPEED || !Ember.isNone(gpsSpeed) && gpsSpeed < MIN_HEADING_SPEED) {// too slow to examine heading
      } else if (distance > NAVIGATION_POLYLINE_PROXIMITY_THRESHOLD) {
        // too far from polyline
        isOffRoute = true;
      } else if (Math.min(Math.abs(bearing - navigationPolylineBearing), 360 - Math.abs(bearing - navigationPolylineBearing)) > BEARING_TOLERANCE) {
        // heading is too far off
        const projection = this.get('_projection'); // ignore first and last N meters of each polyline segment to allow
        // vehicle the opportunity to make shap turns

        if (projection.segmentOffset > SEGMENT_HEADING_EXCLUSION_MARGIN && projection.segmentOffset < projection.segments.length - SEGMENT_HEADING_EXCLUSION_MARGIN) {
          isOffRoute = true;
        }
      }

      if (isOffRoute !== wasOffRoute) {
        // only update if it changed
        this.set('isOffRoute', isOffRoute);
      }

      return isOffRoute;
    },

    isOffRouteChanged: Ember.observer('isOffRoute', function () {
      if (this.get('isOffRoute')) {
        this.set('_guidanceHistory', []);
        this.set('_spokenHistory', []);
        const taskPerformed = this.api.pingAndUpdatePolyLine.perform().catch(e => {
          if (!(0, _emberConcurrency.didCancel)(e)) {
            throw e;
          }
        });

        if (taskPerformed) {
          this.set('isOffRoute', false);
        }

        console.log('Vehicle has gone off route.'); //eslint-disable-line no-console
      } else {
        console.log('vehicle has returned to route'); //eslint-disable-line no-console
      }
    }),

    _computeActiveGuidance() {
      let guidanceArray = this.get('guidanceArray');
      const offset = this.get('navigationPolylineOffset');
      const navigationPolylineBearing = this.get('navigationPolylineBearing');
      const bearing = this.get('bearing');
      if (!guidanceArray || Ember.isNone(offset) || Ember.isNone(navigationPolylineBearing) || Ember.isNone(bearing)) return null;
      if (this.get('isOffRoute')) return null; // get the first instruction beyond current offset

      guidanceArray = guidanceArray.filter(obj => {
        return obj.routeOffsetInMeters > offset;
      });
      let baseWaypointIndex = 0;

      if (guidanceArray.length > 1 && guidanceArray[baseWaypointIndex] && (guidanceArray[baseWaypointIndex].instructionType === 'LOCATION_WAYPOINT' || guidanceArray[baseWaypointIndex].instructionType === 'LOCATION_DEPARTURE')) {
        baseWaypointIndex = 1;
      }

      const guidance = guidanceArray[baseWaypointIndex];
      return guidance;
    },

    _canSpeakGuidance(guidance) {
      const navigationPolylineOffset = this.get('navigationPolylineOffset');
      if (!guidance || Ember.isNone(navigationPolylineOffset)) return false;
      const speed = this.get('speed');
      const spokenHistory = this.get('_spokenHistory');
      const guidanceHistory = this.get('_guidanceHistory');
      const last = guidanceHistory.get('lastObject');
      const lastSpoken = spokenHistory.get('lastObject');
      const guidanceMessage = this.get('guidanceMessage');
      let speakAt = '400 feet'; // Speed less than equals to 40 km/h

      if (speed > '11' && speed <= '16') {
        // Speed greater than 40 km/h and less than equals to 60 km/h
        speakAt = '500 feet';
      } else if (speed > '16' && speed < '22') {
        // Speed greater than 60 km/h and less than 80 km/h
        speakAt = '600 feet';
      } else if (speed >= '22') {
        // Speed greater than equals to 80 km/h
        speakAt = '700 feet';
      } // not enough info available
      // already spoke this and guidance hasn't changed


      if (last && lastSpoken && last.guidance === guidance && lastSpoken.guidance === guidance) {
        return false;
      }

      if (spokenHistory.length === 0 && guidanceHistory.length === 0) {
        return false;
      }

      if (guidanceMessage.travelDistanceToNextGuide.replace(' feet', '') <= Number(speakAt.replace(' feet', ''))) {
        return true;
      }
    }

  });

  _exports.default = _default;
});