define("adept-iq/pods/components/generic-components/table/classes/sort-column", ["exports", "ember-light-table/classes/Column", "adept-iq/config/column-types"], function (_exports, _Column, _columnTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SortColumn extends _Column.default {}

  _exports.default = SortColumn;
  SortColumn.reopen({
    id: null,
    config: null,
    valuePathMap: Ember.computed('valuePaths', function () {
      const valuePaths = this.get('valuePaths');
      if (!valuePaths) return {};
      const valuePathMap = valuePaths.reduce((obj, {
        modelName,
        valuePath
      }) => {
        obj[modelName] = valuePath;
        return obj;
      }, {});
      return valuePathMap;
    }),
    columnType: Ember.computed('config.type', function () {
      const columnTypeId = this.get('config.type');

      const columnType = _columnTypes.default.findBy('id', columnTypeId);

      if (!columnType) {
        /*eslint-disable */
        console.error(`no column type with id '${columnTypeId}'`);
        /*eslint-enable */

        return null;
      }

      return columnType;
    })
  });
});