define("adept-iq/utils/geolib", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.projectDistanceOnPolyline = _exports.projectPointOnPolyline = _exports.projectPointOnLine = _exports.computePolylineSegments = _exports.computePolylineLength = _exports.getHeading = _exports.getDistance = _exports.fromGeolib = _exports.toGeolib = _exports.geolib = void 0;
  const EPSILON = 1.0e-10;
  const geolib = window.geolib;
  _exports.geolib = geolib;

  const toGeolib = p => {
    if (!p) return {
      latitude: null,
      longitude: null
    };
    const latitude = Array.isArray(p) ? p[0] : p.latitude || p.lat;
    const longitude = Array.isArray(p) ? p[1] : p.longitude || p.lng || p.lon;
    return {
      latitude,
      longitude
    };
  };

  _exports.toGeolib = toGeolib;

  const fromGeolib = p => [p.latitude, p.longitude];

  _exports.fromGeolib = fromGeolib;

  const getDistance = (a, b) => geolib.getDistance(a, b, EPSILON);

  _exports.getDistance = getDistance;

  const getHeading = (a, b) => {
    return geolib.getRhumbLineBearing(toGeolib(a), toGeolib(b));
  };

  _exports.getHeading = getHeading;

  const computePolylineLength = polyline => {
    return geolib.getPathLength(polyline.map(toGeolib));
  };

  _exports.computePolylineLength = computePolylineLength;

  const computePolylineSegments = polyline => {
    const segments = [];
    let start = 0;

    for (let i = 0; i < polyline.length - 1; i++) {
      const a = toGeolib(polyline[i]);
      const b = toGeolib(polyline[i + 1]);
      let length = 0;

      if (a.latitude && a.longitude && b.latitude && b.longitude) {
        length = geolib.getDistance(a, b, EPSILON);
      }

      segments.push({
        points: [polyline[i], polyline[i + 1]],
        bearing: geolib.getRhumbLineBearing(a, b),
        length,
        start,
        stop: start + length,
        isSegment: true
      });
      start += length;
    }

    segments.isSegmentArray = true;
    return segments;
  };

  _exports.computePolylineSegments = computePolylineSegments;

  const projectPointOnLine = (point, line) => {
    const segment = line.isSegment ? line : computePolylineSegments(line)[0];
    const gp = toGeolib(point);
    const ga = toGeolib(segment.points[0]);

    if (segment.length < EPSILON) {
      return {
        distance: geolib.getDistance(gp, ga),
        offset: 0,
        point: segment.points[0],
        segment
      };
    }

    const gb = toGeolib(segment.points[1]);
    const d1 = geolib.getDistance(ga, gp, EPSILON);
    const d2 = geolib.getDistance(gp, gb, EPSILON);
    const d3 = segment.length;

    if (d1 < EPSILON) {
      return {
        distance: 0,
        offset: 0,
        point: segment.points[0],
        segment
      };
    }

    if (d2 < EPSILON) {
      return {
        distance: 0,
        offset: segment.length,
        point: segment.points[1],
        segment
      };
    }

    const clamp = (x, a = -1, b = 1) => Math.min(Math.max(x, a), b); // alpha is the angle between the line from start to point, and from start to end


    const x1 = clamp((d1 * d1 + d3 * d3 - d2 * d2) / (2 * d1 * d3));
    const alpha = Math.acos(x1); // beta is the angle between the line from end to point and from end to start //

    const x2 = clamp((d2 * d2 + d3 * d3 - d1 * d1) / (2 * d2 * d3));
    const beta = Math.acos(x2); // if the angle is greater than 90 degrees, then the minimum distance is the
    // line from the start to the point

    if (alpha > Math.PI / 2) {
      return {
        distance: d1,
        offset: 0,
        point: segment.points[0],
        segment
      };
    }

    if (beta > Math.PI / 2) {
      // same for the beta
      return {
        distance: d2,
        offset: segment.length,
        point: segment.points[1],
        segment
      };
    } // otherwise the minimum distance is achieved through a line perpendicular
    // to the start-end line, which goes from the start-end line to the point


    const dx = Math.cos(alpha) * d1;
    const dy = Math.sin(alpha) * d1;
    const dest = geolib.computeDestinationPoint(ga, dx, segment.bearing);
    return {
      distance: dy,
      offset: dx,
      point: fromGeolib(dest),
      segment
    };
  };

  _exports.projectPointOnLine = projectPointOnLine;

  const projectPointOnPolyline = (point, polyline) => {
    const segments = polyline.isSegmentArray ? polyline : computePolylineSegments(polyline);
    const results = segments.reduce((arr, segment) => {
      const projection = projectPointOnLine(point, segment);
      arr.push(projection);
      return arr;
    }, []);
    const [best] = results.sortBy('distance');
    if (!best) return null;
    return {
      point: best.point,
      distance: best.distance,
      segmentOffset: best.offset,
      bearing: best.segment.bearing,
      segments,
      // this is the _total_ offset from beginning of polyline
      offset: best.segment.start + best.offset
    };
  };
  /**
    * Returns point on polyline paramaterized by distance d
    * @param {double} d - distance along polyline in km
    * @param {Polyline} polyline - array of lat/lng pairs
    */


  _exports.projectPointOnPolyline = projectPointOnPolyline;

  const projectDistanceOnPolyline = (d, polyline) => {
    const segments = polyline.isSegmentArray ? polyline : computePolylineSegments(polyline);
    const segment = segments.find(s => s.start <= d && s.stop > d) || segments[segments.length - 1];
    const segmentOffset = d - segment.start;
    const destination = geolib.computeDestinationPoint(toGeolib(segment.points[0]), segmentOffset, segment.bearing);
    return {
      point: fromGeolib(destination),
      segment,
      segmentOffset,
      bearing: segment.bearing,
      offset: d
    };
  };

  _exports.projectDistanceOnPolyline = projectDistanceOnPolyline;
});