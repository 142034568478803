define("adept-iq/pods/components/iq-widgets/new-trip-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/new-trip-form-widget/config", "moment", "adept-iq/config/mapped-permIds"], function (_exports, _component, _config, _moment, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    editModal: Ember.inject.service(),
    geocode: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    editableSections: _config.default.editableSections,
    classNames: ['new-trip-form-widget', 'data-test-new-trip-form-widget'],

    init() {
      this._super(...arguments);

      this.configureAddTripPermission();
    },

    configureAddTripPermission() {
      const record = this.get('editableRecords')[0];
      const sameDayTripPermGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.createSameDay, null);
      const FutureDayTripPermGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.createFuture, null);
      let maxDate = null;
      let minDate = null;

      if (sameDayTripPermGranted && !FutureDayTripPermGranted) {
        maxDate = (0, _moment.default)().endOf('day');
      }

      if (FutureDayTripPermGranted && !sameDayTripPermGranted) {
        minDate = (0, _moment.default)().startOf('day').add(1, 'day').toDate();
      }

      record.set('tripMaxDate', maxDate);
      record.set('tripMinDate', minDate);
    },

    validateRecords() {
      let valid = true;
      const records = this.get('editableRecords') || [];
      this.set('editModal.errors', []);
      records.forEach(record => {
        const isEligible = record.get('rider.riderEligibilities') || {};
        const requestedTime = record.get('leg.content.requestTime');

        if (Ember.isNone(requestedTime)) {
          valid = false;
          this.get('editModal.errors').pushObject('Date should be there');
        }

        const eligibleRecord = isEligible.find(value => {
          const from = (0, _moment.default)(Ember.get(value, 'from'), 'DD/MM/YYYY');
          const to = (0, _moment.default)(Ember.get(value, 'to'), 'DD/MM/YYYY');
          const tripDate = (0, _moment.default)(requestedTime, 'DD/MM/YYYY');
          return tripDate.isAfter(from) && tripDate.isBefore(to);
        });

        if (Ember.isNone(eligibleRecord)) {
          valid = false;
          this.get('editModal.errors').pushObject('This passenger is not eligible on the selected date.');
        }

        if (Ember.isEmpty(record.get('pick.place.location.latlng'))) {
          valid = false;
          this.get('editModal.errors').pushObject('Pickup address should be there');
        } else if (Ember.isEmpty(record.get('pick.place.location.geoNode'))) {
          valid = false;
          this.get('editModal.errors').pushObject('Pickup address must be a valid mailing address');
        }

        if (Ember.isEmpty(record.get('drop.place.location.latlng'))) {
          valid = false;
          this.get('editModal.errors').pushObject('Drop address should be there');
        } else if (Ember.isEmpty(record.get('drop.place.location.geoNode'))) {
          valid = false;
          this.get('editModal.errors').pushObject('Drop address must be a valid mailing address');
        }

        if (Ember.isEmpty(record.get('fare'))) {
          valid = false;
          this.get('editModal.errors').pushObject('Fare should be entered');
        } //TODO: activate once we have subscription working
        //validate subscriptions
        // record.subscriptions.forEach(subscription => {
        //   subscription.recurrencePatterns.forEach(recurrencePattern => {
        //     if (recurrencePattern.recurring) {
        //       if (recurrencePattern.type === 'monthly') {
        //         if (isNone(recurrencePattern.weekOfMonth) &&
        //           isNone(recurrencePattern.dayOfMonth)) {
        //           valid = false;
        //           this.get('editModal.errors').pushObject('Day of month and week of month cannot be both blank.');
        //         } else if (!isNone(recurrencePattern.weekOfMonth) &&
        //           (recurrencePattern.selectedDOWs.length < 1)) {
        //           valid = false;
        //           this.get('editModal.errors').pushObject('Select day of the week.');
        //         }
        //       } else if (recurrencePattern.selectedDOWs.length < 1) {
        //         valid = false;
        //         this.get('editModal.errors').pushObject('Select the recurring day for subscription.');
        //       }
        //       if (isNone(recurrencePattern.subscriptionStartDate)) {
        //         valid = false;
        //         this.get('editModal.errors').pushObject('Must select a start date for subscription.');
        //       }
        //     }
        //   });
        // });

      });
      return valid;
    },

    actions: {
      // override undo because we will have to deal with undoing created
      // models for addresses, travel needs, and eligibility later.
      // IQUX-510
      onUndoClick() {
        const lastUndoState = this.get('editModal.undoHistory').popObject();

        if (lastUndoState === null) {
          const records = this.get('editableRecords') || [];
          records.forEach(record => {
            record.set('isForceDirty', false);
          });
        } else {
          lastUndoState.forEach(({
            record,
            properties
          }) => {
            record.setProperties(properties);
          });
        }

        this.set('isLastUndoStateCommitted', true);
      },

      onApplyClick() {
        if (this.validateRecords()) {
          Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
          this.get('geocode').activateGeocode(false);
          this.set('addressRecord', null);
          this.get('service').apply();
        }
      }

    }
  });

  _exports.default = _default;
});