define("adept-iq/pods/components/generic-widgets/form-widget/section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vT5ElAqm",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"section\",\"title\"]]],null,{\"statements\":[[7,\"tr\"],[12,\"class\",[28,[\"header \",[27,\"if\",[[27,\"not\",[[23,[\"isOpen\"]]],null],\"is-closed\"],null]]]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onHeaderClick\"],null]],[9],[0,\"\\n\\n  \"],[7,\"th\"],[11,\"colspan\",\"100\"],[11,\"class\",\"cell-header\"],[9],[0,\"\\n    \"],[7,\"h4\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"fa fa-sort-down\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n      \"],[1,[23,[\"section\",\"title\"]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"isOpen\"]]],null,{\"statements\":[[0,\"  \"],[14,1,[[23,[\"section\",\"fields\"]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/generic-widgets/form-widget/section/template.hbs"
    }
  });

  _exports.default = _default;
});