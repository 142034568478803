define("adept-iq/pods/components/side-drawer/assign-vehicle-driver-to-route/vehicle-cell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['meetsTravelNeeds'],
    meetsTravelNeeds: Ember.computed('row.vehicle.{noOfWheelchairSeats,noOfAmbulatorySeats,noOfServiceAnimalSeats}', 'extra.travelNeedCounts', function () {
      const vehicle = this.get('row.vehicle');
      const needs = this.get('extra.travelNeedCounts');
      const typesToCheck = ['wheelchair', 'ambulatory', 'serviceAnimal'];

      if (!vehicle || this.get('row.isNoVehicle')) {
        return false;
      }

      const needsNotMet = typesToCheck.some(need => {
        return needs[need] > vehicle.get(`noOf${Ember.String.capitalize(need)}Seats`);
      });

      if (needsNotMet) {
        return 'needs-not-met';
      }
    })
  });

  _exports.default = _default;
});