define("adept-iq/pods/components/iq-widgets/new-trip-form-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Read Only',
      fields: [{
        id: 'id',
        type: 'text',
        label: 'ID',
        valuePath: 'leg.booking.id',
        editable: false
      }, {
        id: 'fullName',
        type: 'text',
        label: 'Passenger First Name',
        valuePath: 'leg.rider.fullName',
        editable: false
      }, {
        id: 'riderId',
        type: 'text',
        label: 'Passenger ID',
        valuePath: 'leg.rider.riderId',
        editable: false
      }]
    }, {
      title: 'Trip Details',
      fields: [{
        id: 'requestedTime',
        type: 'datetimeflatpickr',
        label: 'Request Time',
        valuePath: 'leg.requestTime',
        editable: true,
        hidden: false,
        format: 'Y-m-d h:i K',
        defaultWidth: 50,
        extra: {
          maxTimePath: 'tripMaxDate',
          minTimePath: 'tripMinDate'
        }
      }, {
        id: 'anchor',
        type: 'enum',
        label: 'Anchor',
        valuePath: 'anchor',
        cellDesc: 'anchor',
        editable: true,
        hidden: false,
        extra: {
          options: ['pick', 'drop'],
          allowMultiple: false
        }
      }]
    }, {
      title: 'Pickup Address',
      fields: [{
        id: 'originaddresses',
        type: 'locations',
        label: '',
        valuePath: 'pick.place',
        modelName: 'place',
        isMultiType: true,
        extra: {
          aliasPath: 'address.content.alias',
          notesPath: 'address.content.notes',
          premisePath: 'address.content.premise',
          streetNumberPath: 'address.content.streetNumber',
          streetNamePath: 'address.content.streetAddress',
          localityPath: 'address.content.locality',
          regionPath: 'address.content.region',
          subRegionPath: 'address.content.subRegion',
          postalCodePath: 'address.content.postalCode',
          countryPath: 'address.content.country',
          freeformAddressPath: 'address.content.freeformAddress',
          tomtomFormattedAddressPath: 'address.content.tomtomFormattedAddress',
          useOptionRecord: true,
          streetAddressPath: 'address.content.streetAddress',
          locationsPath: 'address.locations.content.firstObject.latlng'
        }
      }]
    }, {
      title: 'Dropoff Address',
      fields: [{
        id: 'destinationaddresses',
        type: 'locations',
        label: 'Drop-off Address',
        valuePath: 'drop.place',
        modelName: 'place',
        isMultiType: true,
        extra: {
          aliasPath: 'address.content.alias',
          notesPath: 'address.content.notes',
          premisePath: 'address.content.premise',
          streetNumberPath: 'address.content.streetNumber',
          streetNamePath: 'address.content.streetAddress',
          localityPath: 'address.content.locality',
          regionPath: 'address.content.region',
          subRegionPath: 'address.content.subRegion',
          postalCodePath: 'address.content.postalCode',
          countryPath: 'address.content.country',
          freeformAddressPath: 'address.content.freeformAddress',
          tomtomFormattedAddressPath: 'address.content.tomtomFormattedAddress',
          useOptionRecord: true,
          streetAddressPath: 'address.content.streetAddress',
          locationsPath: 'address.locations.content.firstObject.latlng'
        }
      }]
    }, {
      title: 'Travel Needs',
      fields: [{
        id: 'travelNeeds',
        type: 'bs-travel-needs',
        label: '',
        valuePath: 'leg.legTravelNeeds',
        modelName: 'leg-travel-need',
        isMultiType: true,
        extra: {
          travelNeedTypeModelName: 'travel-need-type',
          passengerTypeModelName: 'passenger-type',
          countPath: 'count',
          travelNeedTypePath: 'travelNeedType',
          passengerTypePath: 'passengerType'
        }
      }]
    }, {
      title: 'Notes',
      fields: [{
        id: 'passengerNotes',
        type: 'text',
        label: 'Passenger Notes',
        valuePath: 'leg.rider.notes',
        editable: false
      }, {
        id: 'driverNotes',
        type: 'text',
        label: 'Driver Notes',
        valuePath: 'leg.notes',
        editable: true
      }]
    }, {
      title: 'Fare',
      fields: [{
        id: 'type',
        type: 'enum',
        label: 'Payment Type',
        valuePath: 'fareType',
        cellDesc: 'fareType',
        editable: true,
        hidden: false,
        extra: {
          optionModelName: 'fare-type',
          optionSearchPath: 'name'
        }
      }, {
        id: 'fare',
        type: 'number',
        label: 'Amount',
        valuePath: 'fare',
        editable: true
      }]
    } // , {
    //   title: 'Subscription',
    //   fields: [{
    //     id: 'subscriptions',
    //     type: 'bs-subscriptions',
    //     label: '',
    //     valuePath: 'leg.booking.subscriptions.firstObject.recurrencePatterns',
    //     modelName: 'subscription-recurence-pattern',
    //     isMultiType: true,
    //     hidden: true,
    //     extra: {
    //       recurringPath: 'recurring',
    //       dailyConfigPath: 'type',
    //       selectedWOMPath: 'selectedWOM',
    //       startDateTimePath: 'subscriptionStartDate',
    //       endDateTimePath: 'subscriptionEndDate',
    //       sundayPath: 'sunday',
    //       mondayPath: 'monday',
    //       tuesdayPath: 'tuesday',
    //       wednesdayPath: 'wednesday',
    //       thursdayPath: 'thursday',
    //       fridayPath: 'friday',
    //       saturdayPath: 'saturday',
    //       excludeStartDateTimePath: 'exclusionStartDate',
    //       excludeEndDateTimePath: 'exclusionEndDate',
    //       maxOccurencesPath: 'subscriptionMaxOccurences',
    //       separationCountPath: 'separationCount',
    //       dayOfMonthPath: 'dayOfMonth',
    //       useOptionRecord: true
    //     }
    //   }
    // ]
    // }
    ]
  };
  _exports.default = _default;
});