define("adept-iq/models/route", ["exports", "ember-data", "adept-iq/mixins/otp-label", "moment"], function (_exports, _emberData, _otpLabel, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Scheduling Route Entity
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend(_otpLabel.default, {
    affinities: attr(),
    name: attr('string'),
    plannedStartTime: attr('date'),
    plannedEndTime: attr('date'),
    type: attr('string'),
    schedulingMode: attr('string'),
    tripLimit: attr('number', {
      defaultValue: 9
    }),
    distanceLimit: attr('number', {
      defaultValue: 99
    }),
    dispatchRoute: belongsTo('dispatch-route'),
    etaRoute: belongsTo('etanav-route'),
    navigationPolyline: belongsTo('polyline'),
    scheduledPolyline: belongsTo('schedule-polyline'),
    vehicle: belongsTo('vehicle'),
    schedule: belongsTo('schedule'),
    provider: belongsTo('provider'),
    routeTemplate: belongsTo('route-template'),
    trips: hasMany('trip'),
    routeBreaks: hasMany('route-break'),
    cannedMessages: hasMany('avlm-canned-message'),
    avlmAlert: hasMany('avlm-alert'),
    emergencyMessages: hasMany('avlm-emergency-message'),
    stopPoints: hasMany('stop-point'),
    // used for pullout and pullin
    // populated by route-exec-event
    status: attr('string'),
    otp: attr('string'),
    otpValue: attr('number'),
    odometer: attr('string'),
    // in meters
    actualStartTimestamp: attr('date'),
    actualEndTimestamp: attr('date'),
    isAdjusted: attr('boolean', {
      defaultValue: false
    }),
    isDriverBreakRemoved: attr('boolean', {
      defaultValue: false
    }),
    noOfTrips: Ember.computed.readOnly('trips.length'),
    hasRouteTemplateConsolidated: Ember.computed.readOnly('routeTemplate.isConsolidateRoute'),
    hasRouteTemplateAdjusted: Ember.computed.readOnly('routeTemplate.isAdjustBreak'),
    canShowRemoveDriverBreak: Ember.computed('trimStartTime', 'trimEndTime', 'isDriverBreakRemoved', function () {
      const trimStartTime = this.get('trimStartTime');
      const trimEndTime = this.get('trimEndTime');
      const isDriverBreakRemoved = this.get('isDriverBreakRemoved');
      const routeBreaks = this.get('routeBreaks');
      return trimStartTime && trimEndTime && !isDriverBreakRemoved && !Ember.isEmpty(routeBreaks);
    }),
    containsSlack: Ember.computed('plannedStartTime', 'plannedEndTime', function () {
      const plannedStartTime = (0, _moment.default)(this.get('plannedStartTime'));
      const plannedEndTime = (0, _moment.default)(this.get('plannedEndTime'));
      const newStart = (0, _moment.default)(this.get('adjustedStartTime'));
      const newEnd = (0, _moment.default)(this.get('adjustedEndTime'));
      return !plannedStartTime.isSame(newStart) || !plannedEndTime.isSame(newEnd);
    }),
    otpValueWithSign: Ember.computed('otp', 'otpValue', function () {
      const otp = this.get('otp');
      const otpValue = this.get('otpValue');
      const sign = otp === 'O' || otp === 'E' ? '-' : '+';

      if (otpValue) {
        return `${sign}${Math.abs(otpValue)}`;
      }

      return '';
    }),
    tripStartTime: Ember.computed('trips.@each.tripStartTime', 'plannedStartTime', function () {
      const trips = this.get('trips');
      let min = this.get('plannedStartTime') ? this.get('plannedStartTime').getTime() : null;

      if (!Ember.isEmpty(trips)) {
        trips.forEach(trip => {
          if (trip && trip.get('tripStartTime')) {
            min = Math.min(trip.get('tripStartTime').getTime(), min);
          }
        });
      }

      return min ? new Date(min) : null;
    }),
    tripEndTime: Ember.computed('trips.@each.tripEndTime', 'plannedEndTime', function () {
      const trips = this.get('trips');
      let max = this.get('plannedEndTime') ? this.get('plannedEndTime').getTime() : null;

      if (!Ember.isEmpty(trips)) {
        trips.forEach(trip => {
          if (trip && trip.get('tripEndTime')) {
            max = Math.max(trip.get('tripEndTime').getTime(), max);
          }
        });
      }

      return max ? new Date(max) : null;
    }),
    travelNeedCounts: Ember.computed('dispatchRoute.maxTravelNeedCounts', function () {
      const initialCounts = {
        wheelchair: 0,
        wideWheelchair: 0,
        ambulatory: 0,
        wideAmbulatory: 0,
        serviceAnimal: 0
      };
      const travelNeedCounts = this.get('dispatchRoute.maxTravelNeedCounts');
      return travelNeedCounts ? travelNeedCounts : initialCounts;
    }),
    shiftLength: Ember.computed('plannedStartTime', 'plannedEndTime', function () {
      const plannedStartTime = (0, _moment.default)(this.get('plannedStartTime'));
      const plannedEndTime = (0, _moment.default)(this.get('plannedEndTime'));

      const duration = _moment.default.duration(plannedEndTime.diff(plannedStartTime));

      return Math.floor(duration.asHours());
    }),
    routeMinutes: Ember.computed('trimStartTime', 'trimEndTime', function () {
      const trimStartTime = (0, _moment.default)(this.get('trimStartTime'));
      const trimEndTime = (0, _moment.default)(this.get('trimEndTime'));

      const duration = _moment.default.duration(trimEndTime.diff(trimStartTime));

      return duration.isValid() ? Math.floor(duration.asMinutes()) : null;
    })
  });

  _exports.default = _default;
});