define("adept-iq/classes/widget", ["exports", "lodash", "md5", "adept-iq/utils/guid"], function (_exports, _lodash, _md, _guid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SCHEMA_VERSION = 1;
  const Widget = Ember.Object.extend({
    typeId: null,
    width: null,
    height: null,
    x: null,
    y: null,
    dashboard: null,
    _models: null,
    _id: null,
    _state: null,
    models: Ember.computed('_models', {
      get() {
        let models = this.get('_models');

        if (!models) {
          models = [];
          /*eslint-disable */

          this.set('_models', models);
          /*eslint-enable */
        }

        return models;
      },

      set(key, value) {
        this.set('_models', value);
        return value;
      }

    }),
    id: Ember.computed('_id', {
      get() {
        let id = this.get('_id');

        if (!id) {
          id = (0, _guid.guid)();
          /*eslint-disable */

          this.set('_id', id);
          /*eslint-enable */
        }

        return id;
      },

      set(key, value) {
        this.set('_id', value);
        return value;
      }

    }),
    state: Ember.computed('_state', {
      get() {
        let state = this.get('_state');

        if (!state) {
          state = {};
          /*eslint-disable */

          this.set('_state', state);
          /*eslint-enable */
        }

        return state;
      },

      set(key, value) {
        this.set('_state', value);
        return value;
      }

    }),

    // use this method to mutate state without replacing
    mergeState(changes = {}) {
      const state = this.get('state');

      const columns = _lodash.default.pickBy(state.columns, (value, key) => {
        if (changes.hasOwnProperty('columns')) {
          if (changes.columns.hasOwnProperty(key)) {
            return changes.columns[key] !== value;
          }
        }

        return true;
      });

      state.columns = columns;

      const mergedState = _lodash.default.merge({}, state, changes);

      this.set('state', mergedState); // TODO: test if this is necessary

      this.notifyPropertyChange('state');
      this.set('dashboard.hasDirtyWidgets', true);
    },

    serialize() {
      const attributes = {
        id: this.get('id'),
        typeId: this.get('typeId'),
        width: this.get('width'),
        height: this.get('height'),
        x: this.get('x'),
        y: this.get('y'),
        state: _lodash.default.cloneDeep(this.get('state'))
      };
      const serialized = {
        schemaVersion: SCHEMA_VERSION,
        timestamp: new Date().getTime(),
        hash: (0, _md.default)(JSON.stringify(attributes)),
        attributes
      };
      return serialized;
    }

  });
  Widget.reopenClass({
    deserialize(serialized) {
      // for legacy, missing schema version means 1
      const {
        schemaVersion = 1
      } = serialized;

      if (schemaVersion !== 1) {
        /*eslint-disable */
        throw 'unknown schema version';
        /*eslint-enable */
      }

      const {
        id,
        typeId,
        width,
        height,
        x,
        y,
        state
      } = serialized.attributes;
      return Widget.create({
        id,
        typeId,
        width,
        height,
        x,
        y,
        state: _lodash.default.cloneDeep(state)
      });
    }

  });
  var _default = Widget;
  _exports.default = _default;
});