define("adept-iq/pods/components/reoptimize-modal/operation-views/release-routes/component", ["exports", "ember-concurrency", "adept-iq/mixins/async-schedule-operation"], function (_exports, _emberConcurrency, _asyncScheduleOperation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_asyncScheduleOperation.default, {
    classNames: ['release-routes'],
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    isTaskRunning: Ember.computed.oneWay('releaseRoutes.isRunning'),
    // Passed In Attributes
    title: null,
    currentSchedule: null,
    tableRef: null,
    refreshData: null,

    init() {
      this._super(...arguments);
    },

    // be able to adjust from selected rows
    releaseRoutes: (0, _emberConcurrency.task)(function* () {
      const schedule = this.get('currentSchedule');
      const notifications = this.get('notifications');
      const workspace = this.get('workspace');
      const startDate = workspace.get('startDate');
      const exportedDate = workspace.get('exportedDate') || [];

      try {
        const res = yield this.createScheduleAsyncOperation.perform(schedule, 'export');

        if (res) {
          notifications.success('Routes successfully released.');
          workspace.popState('reoptimize');
          exportedDate.push(startDate);
          workspace.set('exportedDate', exportedDate);
        } else {
          notifications.warning('Routes failed to release.');
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          // re-throw the non-cancelation error
          notifications.warning('Routes failed to release.');
          throw e;
        }
      }
    }).drop(),
    actions: {
      onReleaseRoutesClick() {
        const tooltip = this.get('tooltip');
        tooltip.setProperties({
          title: 'Release Route',
          hasOverlay: true,
          tip: 'Are you sure you want to release the routes? Once released, the routes will be unavailable for edting in Scheduling and dispatchers will be allowed to work with the routes.',
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return this.releaseRoutes.perform();
          },
          secondaryActionText: 'Cancel'
        });
      }

    }
  });

  _exports.default = _default;
});