define("adept-iq/models/vehicle-event", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    trackingId: attr('string'),
    vehicleId: attr('string'),
    type: attr('string'),
    lat: attr('number'),
    lng: attr('number'),
    odo: attr('number'),
    note: attr('string'),
    preCheckedId: attr('number'),
    postCheckedId: attr('number'),
    content: attr('object'),
    vehicle: attr('object'),
    message: attr('string'),
    // TODO: map these to `avlm-driver` associations
    driver: attr('object')
  });

  _exports.default = _default;
});