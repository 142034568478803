define("adept-iq/pods/components/iq-widgets/drivers-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/drivers-widget/config", "adept-iq/pods/components/iq-widgets/drivers-widget/avlmConfig", "adept-iq/config/environment", "lodash", "adept-iq/config/mapped-permIds"], function (_exports, _component, _config, _avlmConfig, _environment, _lodash, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['drivers-widget', 'data-test-drivers-widget'],
    editModal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    editComponent: 'iq-widgets/drivers-form-widget',
    // need to sub-class this in specific widget
    config: _config.default,
    defaultIncludes: INCLUDES,

    init() {
      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      this._super(...arguments);
    },

    // TODO: saveRecordFunction shall be moved to a service later on
    // This save is specific to saving drivers and all related models.
    saveRecordFunction(records) {
      function saveDeleteAllObjects(objects) {
        const deleteObjects = _lodash.default.difference(objects.content.initialState, objects.content.currentState) || []; // delete objects that have been removed from the array

        return Promise.all(deleteObjects.map(deleteObject => {
          deleteObject._record.deleteRecord();

          return deleteObject.save();
        })).then(() => {
          return Promise.all(objects.map(object => object.save()));
        });
      }

      this.set('editModal.errors', []);
      return new Promise((resolve, reject) => {
        const saveRecords = records ? records : [];
        saveRecords.forEach(async record => {
          const isNew = record.isNew;

          try {
            await record.save();
            await saveDeleteAllObjects(record.get('phoneNumbers'));
            await saveDeleteAllObjects(record.get('driverAvailabilities'));
            this.get('activeContext').get('refreshTableContent').perform(['driver']);

            if (isNew) {
              this.get('notifications').success(`DRIVER ${record.driverId} SAVE CONFIRMED`);
            } else {
              this.get('notifications').success(`DRIVER ${record.driverId} EDIT CONFIRMED`);
            }

            resolve();
          } catch (error) {
            if (isNew) {
              this.get('notifications').warning(`DRIVER ${record.driverId} SAVE CONFIRMATION FAILED`);
            } else {
              this.get('notifications').warning(`DRIVER ${record.driverId} EDIT CONFIRMATION FAILED`);
            }

            reject(error);
          }
        });
      });
    },

    didInsertElement() {
      this._super(...arguments); // disable new/edit driver for 4.2. This will be added back
      // later in 5.0


      this.get('tableActions').unshiftObject({
        id: 'newDriver',
        name: 'New Driver',
        permId: _mappedPermIds.default.newDriver,
        action: function () {
          const modelName = this.get('config.modelName');
          const driverPhoneNumber = this.get('store').createRecord('driver-phone-number');
          const provider = this.get('store').createRecord('provider');
          const newModel = this.get('store').createRecord(modelName, {
            phoneNumbers: [driverPhoneNumber],
            provider: provider
          });
          this.get('records').pushObject(newModel);
          this.set('editModal.editableRecords', []);
          const editComponent = this.get('editComponent');
          this.get('editModal').open(editComponent, [newModel], this.get('saveRecordFunction').bind(this));
        }.bind(this)
      });
      this.set('singleActions', [{
        id: 'edit',
        name: 'Edit',
        permId: _mappedPermIds.default.editDriver,
        action: model => {
          const editComponent = this.get('editComponent');
          const saveRecordFn = this.get('saveRecordFunction').bind(this);

          if (Ember.isNone(model.get('phoneNumber'))) {
            const driverPhoneNumber = this.get('store').createRecord('driver-phone-number');
            model.set('phoneNumbers', [driverPhoneNumber]);
          }

          this.get('editModal').open(editComponent, [model], saveRecordFn);
        }
      }, {
        id: 'sendMessage',
        name: 'Send Message',
        permId: _mappedPermIds.default.sendMessageDriver,
        action: model => {
          this.sendMessageToDrivers([model], 'singleMessage');
        }
      }]); // disable new vehicle for 4.2. This will be added back
      // later in 5.0

      this.set('bulkActions', [// {
      //   id: 'bulkEdit',
      //   name: 'Bulk Edit',
      //   action: (models) => {
      //     const editComponent = this.get('editComponent');
      //     this.get('editModal').open(editComponent, models);
      //   }
      // },
      {
        id: 'bulkMessage',
        name: 'Bulk Message',
        permId: _mappedPermIds.default.editDriver,
        action: drivers => {
          this.sendMessageToDrivers(drivers, 'bulkMessage');
        }
      }]);
    },

    async sendMessageToDrivers(drivers, messageType) {
      const driverList = drivers ? drivers : [];
      const draftMessages = driverList.map(driver => {
        const trueDriver = driver;
        const driverId = driver.get('id');
        const badgeNumber = driver.get('driverId');

        if (messageType === 'singleMessage' && !badgeNumber) {
          this.get('notifications').warning('Can not send message due to missing driver id');
          return null;
        }

        if (messageType === 'bulkMessage' && !badgeNumber) {
          return null;
        }

        trueDriver.set('driver', {
          id: driverId,
          endPointType: 'recipient',
          fullName: driver.get('fullName'),
          badgeNumber: badgeNumber
        });
        const vehicleId = trueDriver.get('latestVehicle.id') ? trueDriver.get('latestVehicle.id') : trueDriver.get('latestRouteVehicleDriver.vehicle.id');
        const vehicleName = trueDriver.get('latestVehicle.name') ? trueDriver.get('latestVehicle.name') : trueDriver.get('latestRouteVehicleDriver.vehicle.name');
        trueDriver.set('driverVehicle', {
          id: vehicleId,
          name: vehicleName
        });
        const routeId = trueDriver.get('latestRouteVehicleDriver.dispatchRoute.route.id');
        const routeName = trueDriver.get('latestRouteVehicleDriver.dispatchRoute.route.name');
        trueDriver.set('route', {
          id: routeId,
          name: routeName
        });

        if (_environment.default.APP.avlmLite) {
          trueDriver.set('avlmDriver', {
            id: driverId,
            endPointType: 'recipient',
            fullName: driver.get('fullName'),
            badgeNumber: badgeNumber
          });
          trueDriver.set('avlmVehicle', {
            id: vehicleId
          });
        }

        return trueDriver;
      }).filter(driver => !Ember.isNone(driver));

      if (messageType === 'singleMessage' && draftMessages.firstObject) {
        this.get('workspace').pushState('addNewMessage', {
          draftMessages
        });
      } else if (messageType === 'bulkMessage') {
        if (draftMessages.lenght < drivers.length) {
          this.get('notifications').warning('Message could not be sent to some drivers which do not have driver id.');
        }

        if (!Ember.isEmpty(draftMessages)) {
          this.get('workspace').pushState('addNewMessage', {
            draftMessages
          });
        }
      }
    }

  });

  _exports.default = _default;
});