define("adept-iq/services/booking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    bookingData() {
      const bookingData = {
        'data': {
          'type': 'booking',
          'attributes': {
            'trackingId': '12',
            'requestedProvider': {
              'id': '1',
              'type': 'dedicated'
            },
            'source': {
              'type': 'callAgent'
            },
            'passengers': [{
              'id': '0',
              'type': 'client',
              'name': '',
              'lastName': '',
              'firstName': '',
              'phone1': {
                'number': '',
                'areaCode': ''
              },
              'bookingNote': '',
              'requiredAttributes': []
            }],
            'stopPoints': [{
              'index': 1,
              'address': {
                'formattedAddress': '',
                'streetName': '',
                'streetNr': '',
                'region': '',
                'postCode': '',
                'city': '',
                'state': '',
                'pointOfInterest': {
                  'alias': ''
                },
                'geoNode': '',
                'coord': {
                  'lat': 0,
                  'lng': 0
                }
              },
              'passengerEvents': [{
                'type': 'pickup',
                'passengerId': '1',
                'time': '',
                'anchor': true,
                'driverNote': ''
              }]
            }, {
              'index': 2,
              'address': {
                'formattedAddress': '',
                'streetName': '',
                'streetNr': '',
                'region': '',
                'postCode': '',
                'city': '',
                'state': '',
                'pointOfInterest': {
                  'alias': ''
                },
                'geoNode': '',
                'coord': {
                  'lat': 0,
                  'lng': 0
                }
              },
              'passengerEvents': [{
                'type': 'dropoff',
                'passengerId': '1',
                'time': '',
                'anchor': false
              }]
            }],
            'price': {
              'estimate': 0
            }
          }
        }
      };
      return bookingData;
    },

    bookingScheduleData() {
      const bookingScheduleData = {
        'data': {
          'id': '',
          'type': 'booking',
          'attributes': {
            'destination': 'dispatch'
          }
        }
      };
      return bookingScheduleData;
    },

    prepareBookingData(bookingData, record, currentTravelNeeds) {
      const pickLocation = record.get('originlocations.firstObject');
      const dropLocation = record.get('destination');
      const rider = record.get('rider.content');
      const anchor = record.get('anchor');
      const requiredAttributes = [];
      const newTravelNeeds = record.get('newTravelNeeds');
      newTravelNeeds.map(newObject => {
        if (!newObject.get('passengerType.isPca') && !newObject.get('passengerType.isCompanion')) {
          if (newObject.get('count') !== null && newObject.get('count') > 0) {
            requiredAttributes.push({
              'type': newObject.get('travelNeedType.name'),
              'cnt': newObject.get('count')
            });
          }
        }

        if ((newObject.get('passengerType.isCompanion') || newObject.get('passengerType.isPca')) && newObject.get('count') !== null && newObject.get('count') > 0) {
          const additonalPassengerData = {
            'id': rider.riderId,
            'type': newObject.get('passengerType.name'),
            'requiredAttributes': []
          };

          if (newObject.get('count') !== null && newObject.get('count') > 0) {
            additonalPassengerData.requiredAttributes.push({
              'type': newObject.get('travelNeedType.name'),
              'cnt': newObject.get('count')
            });
          } else {
            additonalPassengerData.requiredAttributes.push({
              'type': newObject.get('travelNeedType.name'),
              'cnt': 1
            });
          }

          bookingData.data.attributes.passengers.push(additonalPassengerData);
        }
      });
      const riderTravelNeeds = record.get(currentTravelNeeds);
      riderTravelNeeds.map(newObject => {
        if (!newObject.get('passengerType.isPca') && !newObject.get('passengerType.isCompanion')) {
          if (newObject.get('count') !== null && newObject.get('count') > 0) {
            if (Ember.isNone(requiredAttributes.find(object => {
              return object.type === newObject.get('travelNeedType.name');
            }))) {
              requiredAttributes.push({
                'type': newObject.get('travelNeedType.name'),
                'cnt': newObject.get('count')
              });
            }
          }
        }

        if ((newObject.get('passengerType.isCompanion') || newObject.get('passengerType.isPca')) && newObject.get('count') !== null && newObject.get('count') > 0) {
          const additonalPassengerData = {
            'id': rider.riderId,
            'type': newObject.get('passengerType.name'),
            'requiredAttributes': []
          };

          if (newObject.get('count') !== null && newObject.get('count') > 0) {
            if (Ember.isNone(bookingData.data.attributes.passengers.find(object => {
              return object.type === newObject.get('passengerType.name');
            }))) {
              additonalPassengerData.requiredAttributes.push({
                'type': newObject.get('travelNeedType.name'),
                'cnt': newObject.get('count')
              });
              bookingData.data.attributes.passengers.push(additonalPassengerData);
            }
          }
        }
      });
      bookingData.data.attributes.stopPoints[0].address.formattedAddress = pickLocation.tomtomFormattedAddress.address.freeformAddress;

      if (pickLocation.premise !== null) {
        bookingData.data.attributes.stopPoints[0].address.premise = pickLocation.premise;
      } else {
        bookingData.data.attributes.stopPoints[0].address.premise = '';
      }

      bookingData.data.attributes.stopPoints[0].address.streetName = pickLocation.streetName;
      bookingData.data.attributes.stopPoints[0].address.streetNr = pickLocation.streetNumber;

      if (pickLocation.county !== null) {
        bookingData.data.attributes.stopPoints[0].address.region = pickLocation.county;
      } else {
        bookingData.data.attributes.stopPoints[0].address.region = '';
      }

      bookingData.data.attributes.stopPoints[0].address.postCode = pickLocation.postalCode;
      bookingData.data.attributes.stopPoints[0].address.city = pickLocation.city;

      if (pickLocation.state !== null) {
        bookingData.data.attributes.stopPoints[0].address.state = pickLocation.state;
      } else {
        bookingData.data.attributes.stopPoints[0].address.state = pickLocation.city;
      }

      bookingData.data.attributes.stopPoints[0].address.coord.lat = pickLocation.lat;
      bookingData.data.attributes.stopPoints[0].address.coord.lng = pickLocation.lng;
      bookingData.data.attributes.stopPoints[0].address.pointOfInterest.alias = pickLocation.alias;
      bookingData.data.attributes.stopPoints[0].address.geoNode = pickLocation.geoNode;
      bookingData.data.attributes.stopPoints[1].address.formattedAddress = dropLocation.tomtomFormattedAddress.address.freeformAddress;

      if (dropLocation.premise !== null) {
        bookingData.data.attributes.stopPoints[1].address.premise = dropLocation.premise;
      } else {
        bookingData.data.attributes.stopPoints[1].address.premise = '';
      }

      bookingData.data.attributes.stopPoints[1].address.streetName = dropLocation.streetName;
      bookingData.data.attributes.stopPoints[1].address.streetNr = dropLocation.streetNumber;

      if (dropLocation.county !== null) {
        bookingData.data.attributes.stopPoints[1].address.region = dropLocation.city;
      } else {
        bookingData.data.attributes.stopPoints[1].address.region = '';
      }

      bookingData.data.attributes.stopPoints[1].address.postCode = dropLocation.postalCode;
      bookingData.data.attributes.stopPoints[1].address.city = dropLocation.city;

      if (dropLocation.state !== null) {
        bookingData.data.attributes.stopPoints[1].address.state = dropLocation.state;
      } else {
        bookingData.data.attributes.stopPoints[1].address.state = dropLocation.city;
      }

      bookingData.data.attributes.stopPoints[1].address.coord.lat = dropLocation.lat;
      bookingData.data.attributes.stopPoints[1].address.coord.lng = dropLocation.lng;
      bookingData.data.attributes.stopPoints[1].address.pointOfInterest.alias = dropLocation.alias;
      bookingData.data.attributes.stopPoints[1].address.geoNode = dropLocation.geoNode;
      bookingData.data.attributes.passengers[0].firstName = rider.firstName;
      bookingData.data.attributes.passengers[0].lastName = rider.lastName;
      bookingData.data.attributes.passengers[0].name = rider.firstName + ' ' + rider.lastName;
      bookingData.data.attributes.passengers[0].id = rider.riderId;
      bookingData.data.attributes.passengers[0].bookingNote = record.bookingNotes;
      bookingData.data.attributes.passengers[0].requiredAttributes = requiredAttributes;
      bookingData.data.attributes.price.estimate = record.fare;

      for (let count = 0; count < bookingData.data.attributes.stopPoints[0].passengerEvents.length; count++) {
        if (anchor === 'drop') {
          bookingData.data.attributes.stopPoints[0].passengerEvents[count].anchor = false;
          bookingData.data.attributes.stopPoints[1].passengerEvents[count].anchor = true;
          bookingData.data.attributes.stopPoints[1].passengerEvents[count].time = record.requestedTime.toISOString();
        } else {
          bookingData.data.attributes.stopPoints[0].passengerEvents[count].anchor = true;
          bookingData.data.attributes.stopPoints[1].passengerEvents[count].anchor = false;
          bookingData.data.attributes.stopPoints[0].passengerEvents[count].time = record.requestedTime.toISOString();
        }

        if (count === 0) {
          bookingData.data.attributes.stopPoints[0].passengerEvents[count].passengerId = rider.riderId;
          bookingData.data.attributes.stopPoints[1].passengerEvents[count].passengerId = rider.riderId;
        }

        bookingData.data.attributes.stopPoints[0].passengerEvents[count].driverNote = record.driverNotes;
      }

      return bookingData;
    }

  });

  _exports.default = _default;
});