define("adept-iq/pods/components/side-drawer/perform-stop/break/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['perform-stop-drawer'],
    stopPoint: Ember.computed.readOnly('stackFrame.options.stopPoint')
  });

  _exports.default = _default;
});