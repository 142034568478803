define("adept-iq/pods/components/iq-widgets/map-widget/config/colors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SCHEDULE_COLOR = _exports.OTP_LATE_COLOR = _exports.OTP_DANGER_COLOR = _exports.PERFORMED_COLOR = _exports.PENDING_COLOR = _exports.PLANNED_DARK_COLOR = _exports.DISTANCE_COLOR = _exports.PLANNED_COLOR = void 0;
  const PLANNED_COLOR = '#A9B2B5';
  _exports.PLANNED_COLOR = PLANNED_COLOR;
  const DISTANCE_COLOR = '#303E44'; // used for trips to improve contrast against stop polylines

  _exports.DISTANCE_COLOR = DISTANCE_COLOR;
  const PLANNED_DARK_COLOR = '#555e63';
  _exports.PLANNED_DARK_COLOR = PLANNED_DARK_COLOR;
  const PENDING_COLOR = '#0067FC';
  _exports.PENDING_COLOR = PENDING_COLOR;
  const PERFORMED_COLOR = '#615F60';
  _exports.PERFORMED_COLOR = PERFORMED_COLOR;
  const OTP_DANGER_COLOR = '#E3621B';
  _exports.OTP_DANGER_COLOR = OTP_DANGER_COLOR;
  const OTP_LATE_COLOR = '#d13730';
  _exports.OTP_LATE_COLOR = OTP_LATE_COLOR;
  const SCHEDULE_COLOR = '#FFA701';
  _exports.SCHEDULE_COLOR = SCHEDULE_COLOR;
  var _default = {
    PLANNED_COLOR,
    PLANNED_DARK_COLOR,
    PENDING_COLOR,
    PERFORMED_COLOR,
    OTP_DANGER_COLOR,
    OTP_LATE_COLOR,
    DISTANCE_COLOR
  };
  _exports.default = _default;
});