define("adept-iq/pods/components/iq-widgets/playback-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/map-widget/component", "adept-iq/config/environment", "adept-iq/pods/components/iq-widgets/playback-widget/config", "adept-iq/pods/components/iq-widgets/playback-widget/avlmConfig", "ember-light-table", "adept-iq/mixins/fetch-vehicle-events", "adept-iq/config/icon-paths", "moment", "tomtom"], function (_exports, _component, _environment, _config, _avlmConfig, _emberLightTable, _fetchVehicleEvents, _iconPaths, _moment, _tomtom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_fetchVehicleEvents.default, {
    interaction: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    store: Ember.inject.service(),
    geocode: Ember.inject.service(),
    editModal: Ember.inject.service(),
    classNames: ['playback-widget'],
    layoutName: 'components/iq-widgets/playback-widget',
    classNameBindings: ['testPlaybackWidget'],
    testPlaybackWidget: false,
    config: _config.default,
    // playback
    animSpeed: null,
    isSlideMoving: false,
    // custom playback map
    isSearchingLocation: false,
    searchCenter: null,
    // tables
    vehiclesTable: null,
    vehicleEventsTable: null,
    // mixin
    activeEvents: Ember.computed('vehicleEvents', function () {
      const events = this.get('vehicleEvents');
      if (Ember.isNone(events)) return [];
      return events.filter(event => event.isChecked);
    }),
    sliderIndexMax: Ember.computed('activeEvents', function () {
      const events = this.get('activeEvents');
      return events.length > 0 ? events.length - 1 : 0;
    }),
    totolTimeInSeconds: null,
    durationInSeconds: null,
    elapsedTimeObserver: Ember.observer('sliderIndex', 'elapsedTime', function () {
      const activeEvents = this.get('activeEvents');
      const sliderIndex = this.get('sliderIndex');
      if (Ember.isEmpty(activeEvents) || Ember.isNone(sliderIndex) || sliderIndex >= activeEvents.length) return;
      const currentEvent = activeEvents[this.get('sliderIndex')];
      const elapsedTime = this.get('elapsedTime');
      this.set('durationInSeconds', (0, _moment.default)(currentEvent.get('timestamp')).valueOf() - (0, _moment.default)(this.get('activeEvents.firstObject.timestamp')).valueOf() + elapsedTime);
    }),
    activeStartTime: Ember.computed('activeEvents.firstObject.time', function () {
      return this.get('activeEvents.firstObject.time') || 'Start Time';
    }),
    activeEndTime: Ember.computed('activeEvents.lastObject.time', function () {
      return this.get('activeEvents.lastObject.time') || 'End Time';
    }),
    polylinePoints: Ember.computed('activeEvents', function () {
      const events = this.get('activeEvents');
      const points = events.reduce((arr, event) => {
        arr.push(event.latlng);
        return arr;
      }, []);
      return points;
    }),
    movingMarker: Ember.computed('activeEvents.@each.{latlng}', function () {
      const events = this.get('activeEvents');

      const vehicleIcon = _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.vehicles.vehicle,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5]
      });

      let movingMarker, previousPointsTime;

      if (!Ember.isEmpty(events)) {
        events.forEach(event => {
          if (!movingMarker) {
            movingMarker = _tomtom.default.L.Marker.movingMarker([event.latlng], 0, {
              autostart: false,
              loop: false,
              icon: vehicleIcon,
              rotationAngle: null,
              rotationOrigin: '11.5px 11.5px'
            });
            previousPointsTime = (0, _moment.default)(event.timestamp).valueOf();
          } else {
            const currentPointsTime = (0, _moment.default)(event.timestamp).valueOf();
            movingMarker.addLatLng(event.latlng, currentPointsTime - previousPointsTime);
            previousPointsTime = currentPointsTime;
          }
        });
      }

      return movingMarker;
    }),
    radiusMarkersGroup: Ember.computed('radiusVehicles.@each.{latlng,heading}', function () {
      const radiusVehicles = this.get('radiusVehicles');

      const vehicleIcon = _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.vehicles.vehicle,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5]
      });

      const markers = _tomtom.default.L.markerClusterGroup();

      if (!Ember.isEmpty(radiusVehicles)) {
        radiusVehicles.forEach(vehicle => {
          const marker = _tomtom.default.L.marker(vehicle.latlng, {
            icon: vehicleIcon,
            rotationAngle: vehicle.heading / 2,
            rotationOrigin: '11.5px 11.5px'
          });

          markers.addLayer(marker);
          vehicle.set('marker', marker);
        });
        return markers;
      }

      return null;
    }),
    isRunning: Ember.computed('_movingMarker', function () {
      const movingMarker = this.get('_movingMarker');

      if (movingMarker) {
        return movingMarker.isRunning();
      }

      return false;
    }),
    // Tabs
    activeTabModeId: Ember.computed.alias('activeContext.topActiveContext.activeTabModeId'),
    // data
    vehicles: Ember.computed('workspaceData', function () {
      const workspaceData = this.get('workspaceData');
      if (Ember.isNone(workspaceData)) return [];
      const vehicleConfig = this.get('config.vehicle');
      const vehicles = workspaceData[vehicleConfig.model];
      return vehicles;
    }),
    events: null,
    markerLocation: null,
    radiusVehicles: Ember.computed.alias('activeContext.topActiveContext.radiusVehicles'),
    vehicleEvents: Ember.computed.alias('activeContext.topActiveContext.vehicleEvents'),
    selectedAddress: Ember.computed.alias('activeContext.topActiveContext.selectedAddress'),
    selectedRadius: Ember.computed.alias('activeContext.topActiveContext.selectedRadius'),
    selectedDateTimeRage: Ember.computed.alias('activeContext.topActiveContext.selectedDateTimeRage'),
    selectedProvider: Ember.computed.alias('activeContext.topActiveContext.selectedProvider'),
    selectedRoute: Ember.computed.alias('activeContext.topActiveContext.selectedRoute'),
    selectedVehicle: Ember.computed.alias('activeContext.topActiveContext.selectedVehicle'),
    selectedRadiusVehicle: Ember.computed.alias('activeContext.topActiveContext.selectedRadiusVehicle'),
    selectedDriver: Ember.computed('selectedRoute', 'selectedVehicle', function () {
      const routeConfig = this.get('config.route');
      const vehicleConfig = this.get('config.vehicle');
      const driverConfig = this.get('config.driver');
      const route = this.get('selectedRoute.model');
      let driver;

      if (Ember.isPresent(route)) {
        driver = route.get(routeConfig.driverPath);

        if (Ember.isPresent(driver)) {
          return {
            id: driver.get(driverConfig.indexPath),
            model: driver
          };
        }
      }

      const vehicle = this.get('selectedVehicle.model');

      if (Ember.isPresent(vehicle)) {
        driver = vehicle.get(vehicleConfig.driverPath);

        if (Ember.isPresent(driver)) {
          return {
            id: driver.get(driverConfig.indexPath),
            model: driver
          };
        }
      }

      return null;
    }),

    clearAll() {
      this.set('selectedProvider', null);
      this.set('selectedRoute', null);
      this.set('selectedVehicle', null);
      this.set('selectedAddress', null);
      this.set('selectedRadius', null);
      this.set('selectedDateTimeRage', {
        today: (0, _moment.default)().hours(0).minutes(0).format('YYYY-MM-DD'),
        date: null,
        starttime: null,
        endtime: null,
        startofday: null,
        endofday: null
      });
      this.set('selectedRadiusVehicle', null);
      this.set('totolTimeInSeconds', null);
      this.set('durationInSeconds', null);
      this.searchCenterReset();
      this.set('pbounds', null); // TODO: see if we need to clear events when hit clear button.

      this.get('radiusVehicles').clear();
      this.notifyPropertyChange('radiusVehicles');
      this.get('vehicleEvents').clear();
      this.notifyPropertyChange('vehicleEvents');
    },

    workspaceData: Ember.computed.alias('activeContext.topActiveContext.workspaceData'),
    providerSortKeys: ['name'],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    providerOptions: Ember.computed.sort('activeContext.topActiveContext.selectedProviders', 'providerSortKeys'),
    routeOptions: Ember.computed('workspaceData', 'selectedProvider', function () {
      const workspaceData = this.get('workspaceData');
      const routeConfig = this.get('config.route');
      const routes = workspaceData[routeConfig.model];
      const options = routes.reduce((arr, route) => {
        if (this.get('selectedProvider') && route.get('provider.id') !== this.get('selectedProvider.id')) return arr;
        arr.push({
          id: route.get(routeConfig.indexPath),
          model: route
        });
        return arr;
      }, []);
      return options;
    }),
    dummyVehicleOptions: null,
    vehicleOptions: Ember.computed('vehicles', 'dummyVehicleOptions', function () {
      const vehicles = this.get('vehicles');
      const vehicleConfig = this.get('config.vehicle');
      if (Ember.isNone(vehicles)) return [];
      const dummyOptions = this.get('dummyVehicleOptions');
      const options = vehicles.reduce((arr, vehicle) => {
        if (this.get('selectedProvider') && vehicle.get('provider.id') !== this.get('selectedProvider.id')) return arr;
        arr.push({
          id: vehicle.get(vehicleConfig.indexPath),
          model: vehicle
        });
        return arr;
      }, []);
      return options.concat(dummyOptions).sortBy('id');
    }),

    markerPinRegion(coord) {
      const map = this.get('map');
      let radius = this.get('selectedRadius.value') | 1;
      radius = radius * 1609.344;
      return _tomtom.default.L.circle(coord, {
        radius
      }).addTo(map);
    },

    onMarkerRadiusChanged(radius) {
      const searchCenter = this.get('searchCenter');

      if (searchCenter) {
        searchCenter.setRadius(radius * 1609.344);
      }
    },

    init() {
      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      this._super(...arguments);

      const config = this.get('config');
      const vehiclesTable = new _emberLightTable.default(config.vehiclesTableColumns);
      this.set('vehiclesTable', vehiclesTable);
      const vehicleEventsTable = new _emberLightTable.default(config.vehicleEventsTableColumns);
      this.set('vehicleEventsTable', vehicleEventsTable); // TODO: move these two to active context.

      this.set('durationInSeconds', 0);
      this.set('animSpeed', 1);
      this.set('dummyVehicleOptions', []);

      if (this.get('selectedAddress')) {
        Ember.run.scheduleOnce('afterRender', this, 'restoreSearchCenter');
      }

      if (this.get('radiusVehicles')) {
        Ember.run.scheduleOnce('afterRender', this, 'updateVehiclesTable');
      }

      if (this.get('vehicleEvents')) {
        Ember.run.scheduleOnce('afterRender', this, 'updateVehicleEventsTable');
      }
    },

    didInsertElement() {
      this._super(...arguments);
    },

    willDestroyElement() {
      this._super(...arguments);
    },

    updateVehiclesTable: Ember.observer('radiusVehicles', function () {
      const radiusVehicles = this.get('radiusVehicles');
      if (Ember.isNone(radiusVehicles)) return;
      this.get('vehiclesTable').setRows(radiusVehicles); // Update Table selected

      const rows = this.get('vehiclesTable.rows.content');
      const selectedRow = rows.findBy('selected');
      const selectedRadiusVehicle = this.get('selectedRadiusVehicle');

      if (!selectedRow && selectedRadiusVehicle) {
        const row = rows.findBy('id', selectedRadiusVehicle.get('id'));
        row.set('selected', true);
      } // Update Map


      const map = this.get('map');

      if (this.get('_radiusMarkersGroup')) {
        map.removeLayer(this.get('_radiusMarkersGroup'));
        this.set('_radiusMarkersGroup', null);
      }

      const radiusMarkersGroup = this.get('radiusMarkersGroup');

      if (Ember.isPresent(radiusMarkersGroup)) {
        this.set('_radiusMarkersGroup', radiusMarkersGroup);
        map.addLayer(radiusMarkersGroup);
      }
    }),
    updateVehicleEventsTable: Ember.observer('vehicleEvents', function () {
      const vehicleEvents = this.get('vehicleEvents');
      this.get('vehicleEventsTable').setRows(vehicleEvents);
      this.notifyPropertyChange('activeEvents');
    }),
    updatePlayback: Ember.observer('activeEvents', function () {
      const points = this.get('polylinePoints');

      if (!Ember.isEmpty(points)) {
        const polyline = _tomtom.default.L.polyline(points, {
          color: '#A9B2B5',
          dashArray: '3 8',
          opacity: 1,
          weight: 3
        });

        const bounds = _tomtom.default.L.latLngBounds(points);

        this.set('pbounds', bounds);
        this.set('polyline', polyline);
        this.set('totolTimeInSeconds', (0, _moment.default)(this.get('activeEvents.lastObject.timestamp')).valueOf() - (0, _moment.default)(this.get('activeEvents.firstObject.timestamp')).valueOf());
      } else {
        // If empty points, let us keep bounds
        this.set('polyline', null);
      }

      this.updateContent();
    }),
    sliderIndex: null,
    elapsedTime: 0,
    targetRow: null,
    sliderIndexObserver: Ember.observer('sliderIndex', function () {
      const rows = this.get('vehicleEventsTable.rows.content');
      const sliderIndex = this.get('sliderIndex');
      if (Ember.isEmpty(rows) || Ember.isNone(sliderIndex)) return;
      const currentRow = rows[sliderIndex];
      const previousRow = rows.findBy('selected');

      if (currentRow.get('isChecked')) {
        if (previousRow) {
          previousRow.set('selected', false);
        }

        currentRow.set('selected', true);
        this.set('targetRow', currentRow);
      }
    }),

    updateContent() {
      const map = this.get('map');
      if (!this.get('map')) return;

      if (this.get('_polyline')) {
        this.get('_polyline').remove(map);
        this.set('_polyline', null);
      }

      if (this.get('_playedPolyline')) {
        this.get('_playedPolyline').remove(map);
        this.set('_playedPolyline', null);
      }

      if (this.get('_movingMarker')) {
        map.removeLayer(this.get('_movingMarker'));
        this.set('_movingMarker', null);
      }

      const polyline = this.get('polyline');
      const pbounds = this.get('pbounds');
      const movingMarker = this.get('movingMarker');
      this.set('_polyline', polyline);
      this.set('_movingMarker', movingMarker);

      if (polyline) {
        polyline.addTo(map);
      }

      if (pbounds) {
        map.fitBounds(pbounds);
      }

      if (movingMarker) {
        const layer = movingMarker.addTo(map);
        layer.on('lineUpdate', this.get('updateIndex').bind(this));
        layer.on('end', this.get('playbackEnd').bind(this));
      }

      this.set('sliderIndex', null);
      this.set('elapsedTime', 0);
    },

    updatePlayedPolyline(e) {
      if (e.lineIndex > 0) {
        const activeEvents = this.get('activeEvents');
        const map = this.get('map');
        const playedEvents = activeEvents.slice(0, e.lineIndex + 2);
        const playedPoints = playedEvents.reduce((arr, event) => {
          arr.push(event.latlng);
          return arr;
        }, []);

        if (playedEvents.length > 2) {
          const point = _tomtom.default.L.interpolatePosition(playedEvents[playedEvents.length - 2], playedEvents[playedEvents.length - 1], e.currentDuration, e.elapsedTime); // Replace the last points


          playedPoints[playedPoints.length - 1] = point;
        }

        const playedPolyline = _tomtom.default.L.polyline(playedPoints, {
          color: '#555e63',
          dashArray: '3 8',
          opacity: 1,
          weight: 3
        });

        if (this.get('_playedPolyline')) {
          this.get('_playedPolyline').remove(map);
        }

        this.set('_playedPolyline', playedPolyline);

        if (playedPolyline) {
          playedPolyline.addTo(map);
        }
      }
    },

    updateIndex(e) {
      // If slider is dragged, stop updating sliderIndex
      if (!this.get('isSlideMoving')) {
        // lineIndex is index for duration i to i+1
        if (this.get('sliderIndex') !== e.lineIndex) {
          this.set('sliderIndex', e.lineIndex);
        }

        if (e.elapsedTime) {
          this.set('elapsedTime', e.elapsedTime);
        }

        this.updatePlayedPolyline(e);
      }
    },

    playbackEnd() {
      this.set('sliderIndex', this.get('sliderIndexMax'));
      this.notifyPropertyChange('isRunning');
    },

    updateMapView() {},

    restoreSearchCenter() {
      const address = this.get('selectedAddress');

      if (address) {
        this.setSearchCenter(_tomtom.default.L.latLng(address.get('lat'), address.get('lng')));
      }
    },

    // overwirte mapwidgets
    updateMap() {
      // don't try to update until map is initialized
      if (!this.get('map')) return;
      const map = this.get('map');
      map.on('zoom', this._mapZoomMethodHandler);
    },

    reCenterMapView() {
      const map = this.get('map');
      const pbounds = this.get('pbounds');
      let zoom = map.getZoom(); // get user's current zoom level

      if (!map) return;

      if (pbounds) {
        map.fitBounds(pbounds);
      } else {
        const centerLat = this.get('centerLat');
        const centerLng = this.get('centerLng');
        zoom = zoom >= this.get('zoom') ? this.get('zoom') : zoom;
        map.setView([centerLat, centerLng], zoom);
      }
    },

    setSearchCenter(coord) {
      if (this.get('searchCenter')) {
        this.get('searchCenter').remove();
      }

      this.set('searchCenter', this.markerPinRegion(coord));
      this.set('markerLocation', { ...coord,
        lon: coord.lng
      });
    },

    searchCenterReset() {
      if (this.get('searchCenter')) {
        this.get('searchCenter').remove();
      }

      this.setProperties({
        searchCenter: null
      });
    },

    canSearch: Ember.computed('activeTabModeId', 'selectedVehicle', 'selectedAddress', 'selectedRadius', 'selectedDateTimeRage.{date,starttime,endtime}', function () {
      const selectedVehicleId = this.get('selectedVehicle.id');
      const selectedAddress = this.get('selectedAddress');
      const selectedRadius = this.get('selectedRadius');
      const selectedStarttime = this.get('selectedDateTimeRage.starttime');
      const selectedendtime = this.get('selectedDateTimeRage.endtime');
      const activeTabModeId = this.get('activeTabModeId');

      if (activeTabModeId === 'byVehicle' && Ember.isNone(selectedVehicleId)) {
        return false;
      }

      if (activeTabModeId === 'byLocation' && (Ember.isNone(selectedAddress) || Ember.isNone(selectedRadius))) {
        return false;
      }

      if (selectedStarttime && selectedendtime && (0, _moment.default)(selectedStarttime) < (0, _moment.default)(selectedendtime)) {
        return true;
      }

      return false;
    }),
    canPlay: Ember.computed('activeEvents', function () {
      const activeEvents = this.get('activeEvents');
      if (activeEvents.length > 1) return true;
      return false;
    }),
    actions: {
      onProviderOptionSelect(option) {
        this.set('selectedProvider', option);
        this.notifyPropertyChange('vehicleOptions');
        this.set('selectedRoute', null);
        this.set('selectedVehicle', null);
      },

      onRouteOptionsSelect(option) {
        this.set('selectedRoute', option);
        const routeConfig = this.get('config.route');
        const vehicleConfig = this.get('config.vehicle');
        const route = option.model;
        const vehicle = route.get(routeConfig.vehiclePath);

        if (Ember.isPresent(vehicle)) {
          const selectedVehicle = this.get('vehicleOptions').findBy('id', vehicle.get(vehicleConfig.indexPath));
          this.set('selectedVehicle', selectedVehicle);
        }
      },

      onVehicleOptionsOpen() {
        this.get('fetchVehiclesTask').perform();
      },

      onVehicleOptionsSelect(option) {
        this.set('selectedVehicle', option);
        const routeConfig = this.get('config.route');
        const vehicleConfig = this.get('config.vehicle');
        const vehicle = option.model;
        if (Ember.isNone(vehicle)) return; // This could be a dummy option

        const route = vehicle.get(vehicleConfig.routePath);

        if (Ember.isPresent(route)) {
          const selectedRoute = this.get('routeOptions').findBy('id', route.get(routeConfig.indexPath));
          this.set('selectedRoute', selectedRoute);
        }
      },

      onVehicleKeydown(dropdown, e) {
        if (e.keyCode !== 13) return;
        const vehicleId = e.target.value;

        if (vehicleId.length > 0 && dropdown.results.length === 0) {
          const dummyOption = {
            id: vehicleId,
            model: null
          };
          this.get('dummyVehicleOptions').pushObject(dummyOption);
          this.notifyPropertyChange('dummyVehicleOptions');
          this.set('selectedVehicle', dummyOption);
        }
      },

      onRadiusOptionsSelect(option) {
        this.set('selectedRadius', option);
        this.onMarkerRadiusChanged(option.value);
      },

      onValueChange(valuePath, value) {
        this.set(valuePath, value);
      },

      onChangeTime(valuePath, value) {
        const momentTime = (0, _moment.default)(value[0]);
        this.set(valuePath, momentTime.toISOString());

        if (valuePath === 'selectedDateTimeRage.date') {
          this.set('selectedDateTimeRage.startime', null);
          this.set('selectedDateTimeRage.endtime', null);
          this.set('selectedDateTimeRage.startofday', momentTime.startOf('day').toISOString());
          this.set('selectedDateTimeRage.endofday', momentTime.endOf('day').toISOString());
        }
      },

      onClearButtonClick() {
        this.clearAll();
      },

      onSearchButtonClick() {
        this.set('selectedRadiusVehicle', null);
        this.get('vehicleEvents').clear();
        this.get('radiusVehicles').clear();
        this.notifyPropertyChange('vehicleEvents');
        this.notifyPropertyChange('radiusVehicles');

        if (this.get('activeTabModeId') === 'byVehicle') {
          this.get('fetchEventsTask').perform();
        } else {
          this.get('fetchRadiusVehiclesTask').perform();
        }
      },

      onVehicleRowClick(row) {
        const rows = this.get('vehiclesTable.rows.content');
        const selectedRow = rows.findBy('selected');

        const vehicleIcon = _tomtom.default.L.icon({
          iconUrl: _iconPaths.default.vehicles.vehicle,
          iconSize: [23, 23],
          iconAnchor: [11.5, 11.5]
        });

        const selectedVehicleIcon = _tomtom.default.L.icon({
          iconUrl: _iconPaths.default.vehicles.vehicle,
          iconSize: [23, 23],
          iconAnchor: [11.5, 11.5],
          shadowUrl: _iconPaths.default.maps.highlight,
          shadowSize: [27, 27],
          shadowAnchor: [13.5, 13.5]
        });

        if (selectedRow && row.get('id') === selectedRow.get('id')) return;

        if (!Ember.isEmpty(this.get('vehicleEvents'))) {
          this.get('vehicleEvents').clear();
          this.notifyPropertyChange('vehicleEvents');
        }

        rows.forEach(_row => {
          if (_row.get('id') === row.get('id')) {
            _row.content.marker.setIcon(selectedVehicleIcon);
          } else {
            _row.content.marker.setIcon(vehicleIcon);
          }
        });
        this.set('selectedRadiusVehicle', row.content);
        this.get('fetchEventsTask').perform();
      },

      onToggleCheckbox() {
        this.notifyPropertyChange('activeEvents', 'sliderIndexMax');
      },

      onEventRowClick(row) {
        const rows = this.get('vehicleEventsTable.rows.content');
        const activeEvents = this.get('activeEvents');
        let index = 0;

        for (const _row of rows) {
          if (_row === row) {
            break;
          }

          if (_row.get('isChecked')) {
            index++;
          }
        }

        if (this.get('_movingMarker')) {
          if (index === activeEvents.length - 1) {
            this.get('movingMarker').stop();
          } else {
            this.get('_movingMarker').setTo(index);
          }

          this.set('sliderIndex', index);
          this.set('elapsedTime', 0);
        }
      },

      onEventColumnClick() {},

      onRowRightClick() {},

      onCheckAll() {
        const vehicleEvents = this.get('vehicleEvents');
        vehicleEvents.forEach(event => {
          event.set('isChecked', true);
        });
        this.notifyPropertyChange('activeEvents');
      },

      onUncheckAll() {
        const vehicleEvents = this.get('vehicleEvents');
        vehicleEvents.forEach(event => {
          event.set('isChecked', false);
        });
        this.notifyPropertyChange('activeEvents');
      },

      onLocationButtonClick() {
        this.set('isSearchingLocation', !this.get('isSearchingLocation'));

        if (this.get('isSearchingLocation')) {
          Ember.$('html,body,.tomtom-map').addClass('pinpoint-cursor');
          this.get('map').on('click', e => {
            this.setSearchCenter(e.latlng);
            this.set('isSearchingLocation', false);
            Ember.$('html,body,.tomtom-map').removeClass('pinpoint-cursor');
            this.get('map').off('click');
          });
        } else {
          Ember.$('html,body,.tomtom-map').removeClass('pinpoint-cursor');
          this.get('map').off('click');
        }
      },

      selectCurrentModeTab(mode) {
        this.set('activeTabModeId', mode);
      },

      onPlayButtonClick() {
        const currentMarker = this.get('_movingMarker');

        if (currentMarker) {
          if (currentMarker.isEnded()) {
            this.set('sliderIndex', null);
            this.set('elapsedTime', 0);
          }

          if (currentMarker.isRunning()) {
            currentMarker.pause();
          } else {
            // Set speed everytime to avoid new marker not get speed setting.
            currentMarker.setSpeed(this.get('animSpeed'));
            currentMarker.start();
          }

          this.notifyPropertyChange('isRunning');
        }
      },

      onForwardButtonClick() {
        const sliderIndex = this.get('sliderIndex');

        if (this.get('_movingMarker')) {
          const forwardIndex = Math.min(sliderIndex + 10, this.get('activeEvents').length - 2);
          this.get('_movingMarker').setTo(forwardIndex);
          this.set('sliderIndex', forwardIndex);
          this.set('elapsedTime', 0);
        }
      },

      onBackwardButtonClick() {
        const sliderIndex = this.get('sliderIndex');

        if (this.get('_movingMarker')) {
          const backwardIndex = Math.max(sliderIndex - 10, 0);
          this.get('_movingMarker').setTo(backwardIndex);
          this.set('sliderIndex', backwardIndex);
          this.set('elapsedTime', 0);
        }
      },

      onSpeedButtonClick() {
        if (this.get('animSpeed') >= 8) {
          this.set('animSpeed', 1);
        } else {
          this.set('animSpeed', this.get('animSpeed') * 2);
        }

        if (this.get('_movingMarker')) {
          this.get('_movingMarker').setSpeed(this.get('animSpeed'));
        }
      },

      onSliderAction(e) {
        if (e === 'sliderStart') {
          this.set('isSlideMoving', true);
        } else if (e === 'sliderStop') {
          this.set('isSlideMoving', false);
        }
      },

      onSliderValueChange(value) {
        const marker = this.get('_movingMarker');

        if (marker) {
          if (marker.isEnded() || !marker.isStarted()) {
            // If animate done, just restart it.
            marker.start();
            marker.pause();
          }

          marker.setTo(value);
          this.set('sliderIndex', value);
        }
      }

    }
  });

  _exports.default = _default;
});