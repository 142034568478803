define("adept-iq/pods/components/schedule-generate-modal/component", ["exports", "ember-concurrency", "adept-iq/config/api-urls", "adept-iq/mixins/async-schedule-operation"], function (_exports, _emberConcurrency, _apiUrls, _asyncScheduleOperation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly,
    equal
  } = Ember.computed;
  const scheduleHost = _apiUrls.API.schedulingService.host;
  const TIMEOUT_DELAY = 10000; // 10 second tries

  var _default = Ember.Component.extend(_asyncScheduleOperation.default, {
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    // passed in from stackFrame
    timeout: TIMEOUT_DELAY,
    currentSchedule: readOnly('stackFrame.options.currentSchedule'),
    radioValue: readOnly('stackFrame.options.radioValue'),
    isReoptimized: equal('radioValue', 'reoptimize'),
    title: 'Reoptimization',
    description: Ember.computed('isReoptimized', function () {
      const isReoptimized = this.get('isReoptimized');
      return isReoptimized ? 'Reoptimization in progress' : 'Attempting to waitlist trips in progress';
    }),
    progress: 0,

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this.onLoadTask.perform();
    },

    didRender() {
      this._super(...arguments);
    },

    onReset() {
      this.get('workspace').popState('schedule-generate');
    },

    onLoadTask: (0, _emberConcurrency.task)(function* () {
      const isReoptimized = this.get('isReoptimized');
      const onGenerateSchedule = this.onGenerateSchedule;
      const attemptToWaitlistTrips = this.attemptToWaitlistTrips;

      try {
        if (isReoptimized) {
          this.set('workspace.triggeredReoptimize', true);
          yield onGenerateSchedule.perform();
        } else yield attemptToWaitlistTrips.perform();
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          // re-throw the non-cancelation error
          console.error(e); // eslint-disable-line no-console
        }
      } finally {
        yield (0, _emberConcurrency.timeout)(2000);
        this.set('workspace.triggeredReoptimize', false);
        this.onReset();
      }
    }),
    onGenerateSchedule: (0, _emberConcurrency.task)(function* () {
      const schedule = this.get('currentSchedule');
      const progress = this.get('progress');
      let res = null;

      try {
        res = yield this.createScheduleAsyncOperation.perform(schedule, 'import');
        if (Ember.isPresent(progress)) this.set('progress', 50);
        res = res ? yield this.createScheduleAsyncOperation.perform(schedule, 'generate') : false;

        if (res) {
          if (Ember.isPresent(progress)) this.set('progress', 100);
          this.get('notifications').success('SCHEDULE SUCCESSFULLY REOPTIMIZED');
        } else {
          this.get('notifications').warning('SCHEDULE FAILED TO REOPTIMIZE');
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          // re-throw the non-cancelation error
          this.get('notifications').warning('SCHEDULE FAILED TO REOPTIMIZE');
          throw e;
        }
      }

      return Promise.resolve(res);
    }),
    attemptToWaitlistTrips: (0, _emberConcurrency.task)(function* () {
      const session = this.get('session');
      const schedule = this.get('currentSchedule');
      yield this.get('ajax').patch(`${scheduleHost}/${schedule.get('id')}/schedule-waitlist`, {
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      });
      this.set('progress', 100);
    })
  });

  _exports.default = _default;
});