define("adept-iq/pods/components/side-drawer/system-config/route-master/table/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/side-drawer/system-config/route-master/table/config"], function (_exports, _component, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['route-master-table'],
    config: _config.default,
    store: Ember.inject.service(),
    paginationEnabled: true,

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    fetchDataQuery() {
      return this._super(...arguments).then(records => {
        return records.filter(record => !record.get('isNew'));
      });
    }

  });

  _exports.default = _default;
});