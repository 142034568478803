define("adept-iq/pods/components/generic-widgets/tree-widget/tree-widget-row/component", ["exports", "adept-iq/pods/components/generic-widgets/tree-widget/tree-widget-row/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    systemConfig: Ember.inject.service(),
    layout: _template.default,
    classNames: ['tree-widget-row'],
    classNameBindings: ['isExpanded', 'isContent', 'isExpandable', 'isCategory', 'isObject', 'isValue', 'isNumber', 'isString', 'isBoolean', 'rowDepthClass', 'isModified', 'isOutdated'],
    node: null,
    isSearchEnabled: false,
    isSearchEffective: Ember.computed.readOnly('systemConfig.isSearchEffective'),
    // immutable
    label: Ember.computed.readOnly('node.label'),
    depth: Ember.computed.readOnly('node.depth'),
    searchText: Ember.computed.readOnly('node.searchText'),
    isSearchMatch: Ember.computed.readOnly('node.isSearchMatch'),
    isValueSearchMatch: Ember.computed.readOnly('node.isValueSearchMatch'),
    canEdit: Ember.computed.readOnly('node.canEdit'),
    isExpandable: Ember.computed.readOnly('node.isExpandable'),
    isNew: Ember.computed.readOnly('node.isNew'),
    isCategory: Ember.computed.readOnly('node.isCategory'),
    isCollection: Ember.computed.readOnly('node.isCollection'),
    isObject: Ember.computed.readOnly('node.isObject'),
    isValue: Ember.computed.readOnly('node.isValue'),
    isNumber: Ember.computed.readOnly('node.isNumber'),
    isString: Ember.computed.readOnly('node.isString'),
    isBoolean: Ember.computed.readOnly('node.isBoolean'),
    isColor: Ember.computed.readOnly('node.isColor'),
    // component doesn't know about hidden/deleted children
    children: Ember.computed.readOnly('node.visibleChildren'),
    canAdd: Ember.computed('node.{isCollection,canAdd}', function () {
      return this.get('node.isCollection') && this.get('node.canAdd');
    }),
    canRemove: Ember.computed('node.parent.{isCollection,canRemove,visibleChildren.length}', function () {
      let isWithinMinimum = true;
      const minimumItem = this.get('node.parent.minimumItem');
      let visibleLength = 0;

      if (this.get('node.parent.visibleChildren')) {
        visibleLength = this.get('node.parent.visibleChildren').filter(child => {
          return !child.isNew;
        }).length;
      }

      if (minimumItem) {
        isWithinMinimum = visibleLength > minimumItem;
      }

      return this.get('node.parent.isCollection') && this.get('node.parent.canRemove') && isWithinMinimum;
    }),
    // mutable
    value: Ember.computed.alias('node.value'),
    isExpanded: Ember.computed.alias('node.isExpanded'),
    // state
    isOutdated: Ember.computed.alias('node.isTreeOutdated'),
    isModified: Ember.computed.alias('node.isTreeModified'),
    isContent: Ember.computed('isExpandable', function () {
      return !this.get('isExpandable');
    }),
    // indentation styling
    rowDepthClass: Ember.computed('depth', function () {
      const depth = this.get('depth');
      return `row-depth-${depth}`;
    }),
    highlightedLabel: Ember.computed('label', 'searchText', 'isSearchEnabled', 'isSearchMatch', function () {
      const label = this.get('label');
      const searchText = this.get('searchText');
      const isSearchEnabled = this.get('isSearchEnabled');
      const isSearchMatch = this.get('isSearchMatch');
      const isSearchEffective = isSearchEnabled && Ember.isPresent(searchText) && searchText.length >= 3;

      if (isSearchEffective && isSearchMatch) {
        const regex = new RegExp(searchText, 'gi');
        return label.replace(regex, function (str) {
          return `<span class="search-highlighted">${str}</span>`;
        });
      }

      return label;
    }),
    isValueInputMatched: Ember.computed('isSearchEnabled', 'isSearchEffective', 'value', 'isValueSearchMatch', function () {
      return this.get('isSearchEnabled') && this.get('isSearchEffective') && Ember.isPresent(this.get('value')) && this.get('isValueSearchMatch');
    }),

    // turn off every other radio node to false
    turnOffAllOtherRadioSettings() {
      const label = this.get('node.label');
      const categoryNode = this.get('node.parent.parent');
      const siblings = categoryNode.get('children') || [];
      siblings.forEach(child => {
        const radioNode = child.children.find(node => node.label === label);

        if (radioNode && radioNode.value === true) {
          radioNode.set('value', false);
        }
      });
    },

    actions: {
      onExpandButtonToggle() {
        this.toggleProperty('node.isExpanded');
        this.set('systemConfig.showRightPanel', false);
      },

      onRemoveButtonClicked() {
        this.set('node.isRemoved', true);
      },

      onAddButtonClicked() {
        const node = this.get('node');
        this.systemConfig.insertNewItemTree(node);
      },

      onColorChanged(colorValue) {
        this.set('node.value', colorValue);
      },

      onCheckboxClick() {
        // if this is a radio button node and we are trying to turn on
        // then turn off the other ones
        if (!this.value && this.get('node.radioButton')) {
          this.turnOffAllOtherRadioSettings();
        }

        this.toggleProperty('value');
      },

      onTextInput(text) {
        this.set('node.value', text);
      },

      onDisplayNameTextInput(text) {
        this.set('node.configItem.displayName', text);
        this.set('node.label', text);
      }

    }
  });

  _exports.default = _default;
});