define("adept-iq/classes/polylines/trips/iq", ["exports", "adept-iq/classes/polylines/trips/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    record: null,
    // `BasePolyline` overrides
    isActive: true,
    // `BaseTripPolyline` overrides
    otp: Ember.computed.readOnly('record.otp'),
    points: Ember.computed.readOnly('record.polyline')
  });

  _exports.default = _default;
});