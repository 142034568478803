define("adept-iq/services/ajax", ["exports", "ember-ajax/services/ajax", "adept-iq/config/api-urls"], function (_exports, _ajax, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    activityId: null,
    // We need to set a host so that the underlying methods will consume the headers map
    //  for everything that goes to any IQ endpoint.  Since it's just comparing hosts (not full URL),
    //  we can use any host from the API map.  Since config service should be present all the time,
    //  use it.
    host: _apiUrls.API.configService.host,

    setActivityId(aid) {
      this.set('activityId', aid);
    },

    headers: Ember.computed('activityId', function () {
      const aid = this.get('activityId');
      const result = aid ? {
        'AIQ-Client-Activity-ID': aid
      } : {};
      return result;
    })
  });

  _exports.default = _default;
});