define("adept-iq/utils/rql", ["exports", "adept-iq/config/filter-types"], function (_exports, _filterTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildQueryParams = void 0;

  const renderSortsRQL = sorts => {
    const rqlFragments = sorts.map(({
      path,
      asc
    }) => {
      const sign = asc ? '+' : '-';
      return `${sign}${path}`;
    });
    return rqlFragments.uniq().join(',');
  };

  const renderCompoundFilter = (type, args) => {
    if (!args) return null;
    /*eslint-disable */

    const renderedArgs = args.map(renderFilterRQL).compact();
    /*eslint-enable */

    if (renderedArgs.length === 0) return null;
    if (renderedArgs.length === 1) return renderedArgs[0];
    return `${type}(${renderedArgs.join(',')})`;
  };

  const renderFilterRQL = node => {
    if (!node) return null; // allow pre-rendered fragments

    if (Ember.typeOf(node) === 'string') return node;

    switch (node.type) {
      case 'and':
      case 'or':
        return renderCompoundFilter(node.type, node.args);

      default:
        {
          const {
            rql
          } = _filterTypes.filterTypesHash[node.type];
          return rql(node.rql.path, node.rql.values);
        }
    }
  };

  const renderIncludesRQL = includes => {
    // TODO: handle configurable inner joins
    return includes.sort().uniq().join(',');
  };

  const buildQueryParams = ({
    sorts,
    filter,
    includes,
    page
  }) => {
    const query = {};

    if (!Ember.isEmpty(sorts)) {
      query.sort = renderSortsRQL(sorts);
    }

    if (filter) {
      const filterRQL = renderFilterRQL(filter);

      if (filterRQL) {
        query.filter = filterRQL;
      }
    }

    if (!Ember.isEmpty(includes)) {
      query.include = renderIncludesRQL(includes);
    }

    if (Ember.isPresent(page)) {
      query['page[offset]'] = page.offset;
      query['page[limit]'] = page.limit;
    }

    return query;
  };

  _exports.buildQueryParams = buildQueryParams;
});