define("adept-iq/pods/components/side-drawer/system-config/route-master/vehicle-search-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    modelName: 'vehicle',
    title: 'Vehicle',
    defaultSortId: 'name',
    defaultSortAsc: true,
    fetchModelData: false,
    selectFirstRow: true,
    hideWidgetControls: true,
    columns: [{
      id: 'name',
      index: 0,
      type: 'text',
      label: 'Name',
      valuePath: 'name',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'active',
      index: 1,
      type: 'boolean',
      label: 'Active',
      valuePath: 'active',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'placeholder',
      index: 2,
      type: 'text',
      label: 'Placeholder',
      valuePath: 'placeholder',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'provider',
      index: 3,
      type: 'date',
      label: 'Provider',
      valuePath: 'provider.id',
      defaultWidth: 100,
      searchable: true
    }]
  };
  _exports.default = _default;
});