define("adept-iq/pods/components/side-drawer/perform-stop/all/component", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy"], function (_exports, _emberConcurrency, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    isNextDisabled: null,
    // passed in by side-drawer-container component
    onShrinkClick: null,
    onCloseClick: null,
    // passed in by stops widget
    stopPoints: Ember.computed.readOnly('workspace.topOptions.stopPoints'),
    currentStop: null,
    currentStopPosition: Ember.computed('currentStop', function () {
      const stops = this.get('stopPoints');
      const currentStop = this.get('currentStop');
      return stops.indexOf(currentStop) + 1;
    }),
    currentStopIndex: Ember.computed('currentStopPosition', function () {
      return this.get('currentStopPosition') - 1;
    }),
    hasNextStop: Ember.computed('currentStop', 'stopPoints', function () {
      const stops = this.get('stopPoints');
      return stops.indexOf(this.get('currentStop')) < stops.length - 1;
    }),
    title: Ember.computed('currentStop.stopType', function () {
      const stopType = this.getWithDefault('currentStop.stopType', '');
      return `Perform ${Ember.String.capitalize(stopType)}`;
    }),
    canSave: Ember.computed('currentStopPosition', 'stopPoints.[]', 'isNextDisabled', function () {
      return this.get('currentStopPosition') >= this.get('stopPoints.length') && !this.get('isNextDisabled');
    }),
    nextButtonDisabled: Ember.computed('isNextDisabled', 'hasNextStop', function () {
      const isNextDisabled = this.get('isNextDisabled');
      const hasNextStop = !this.get('hasNextStop');
      return isNextDisabled || hasNextStop;
    }),

    didInsertElement() {
      const stops = this.get('stopPoints') || [];
      const uniqueRouteIds = stops.map(stop => stop.get('dispatchRoute.id')).uniq();

      if (uniqueRouteIds.length > 1) {
        this.get('notifications').info('Please only select stops from the same route');
        this.onCloseClick();
      } else {
        this.set('routeId', uniqueRouteIds[0]);
      }

      this.set('currentStop', stops.get('firstObject'));
      this.set('isNextDisabled', true);
    },

    saveTask() {
      const notifications = this.get('notifications');
      const stopPoints = this.get('stopPoints');
      return stopPoints.reduce((acu, stopPoint) => {
        const stopType = Ember.String.capitalize(stopPoint.get('stopType'));

        if (stopPoint.get('arrive') || stopPoint.get('depart')) {
          return acu.then(() => this.get(`save${stopType}Task`).perform(stopPoint).catch(e => {
            if (!(0, _emberConcurrency.didCancel)(e)) {
              notifications.warning('Failed to Perform All Stops');
              throw e;
            }
          }));
        }

        return acu.then(() => Promise.resolve());
      }, Promise.resolve());
    },

    prepStopPointInSequence(stopPoint, stopContainer) {
      const trip = stopPoint.get('trip');
      const route = stopPoint.get('dispatchRoute.route');
      const isArrived = stopContainer.get('isArrived');
      stopPoint.set('isArrived', isArrived);
      stopPoint.set('arrive', stopContainer.get('arrive'));
      stopPoint.set('depart', stopContainer.get('depart'));
      const actualArriveTime = stopContainer.get('actualArriveTime');
      const actualPickTime = stopContainer.get('actualPickTime') || stopContainer.get('actualDropTime');
      stopPoint.set('arriveTimestamp', actualArriveTime);
      stopPoint.set('departTimestamp', actualPickTime);

      if (stopPoint.get('isPick')) {
        route.set('actualStartTimestamp', actualArriveTime); // Handle Travel Needs

        stopPoint.set('serviceAnimalCount', stopContainer.get('serviceAnimalCount'));
        stopPoint.set('pcaCount', stopContainer.get('pcaCount'));
        stopPoint.set('companionCount', stopContainer.get('companionCount'));
        stopPoint.set('travelNeeds', stopContainer.get('travelNeedItems').map(travelNeedItem => ({
          type: travelNeedItem.get('type'),
          cnt: travelNeedItem.get('count')
        })));
        trip.set('fare', stopContainer.get('amount'));
        trip.set('fareName', stopContainer.get('selectedPaymentType'));
      }
    },

    savePickTask: (0, _emberConcurrency.task)(function* (stopPoint) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      const tripPaymentAdapter = store.adapterFor('trip-payment');
      const trip = stopPoint.get('trip');
      const isArrived = stopPoint.get('isArrived');

      if (stopPoint.get('arrive') && !isArrived) {
        yield routeExecEventAdapter.createStopArriveEvent(stopPoint);
      }

      if (stopPoint.get('depart')) {
        yield routeExecEventAdapter.createStopDepartEvent(stopPoint);
      }

      if (stopPoint.get('amount')) {
        yield tripPaymentAdapter.createTripPaymentEvent(trip);
      }
    }).enqueue(),
    saveDropTask: (0, _emberConcurrency.task)(function* (stopPoint) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      const isArrived = stopPoint.get('isArrived');
      const savePromises = [];

      if (stopPoint.get('arrive') && !isArrived) {
        const arrive = yield routeExecEventAdapter.createStopArriveEvent(stopPoint);
        savePromises.push(arrive);
      }

      if (stopPoint.get('depart')) {
        const depart = yield routeExecEventAdapter.createStopDepartEvent(stopPoint);
        savePromises.push(depart);
      }

      return Ember.RSVP.all(savePromises);
    }).enqueue(),
    saveBreakTask: (0, _emberConcurrency.task)(function* (stopPoint) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      const isArrived = this.get('isArrived');
      const savePromises = [];

      if (this.get('arrive') && !isArrived) {
        const arrive = yield routeExecEventAdapter.createStartBreakEvent(stopPoint);
        savePromises.push(arrive);
      }

      if (this.get('depart')) {
        const depart = yield routeExecEventAdapter.createEndBreakEvent(stopPoint);
        savePromises.push(depart);
      }

      return Ember.RSVP.all(savePromises);
    }).enqueue(),
    actions: {
      onRefreshButtonClick() {
        const currentStopContainer = this.get('currentStopContainer');
        currentStopContainer.get('refreshTask').perform();
      },

      onCloseButtonClick() {
        const tooltip = this.get('tooltip');
        const currentStopPosition = (0, _unwrapProxy.unwrapProxy)(this.get('currentStopPosition'));
        const currentStop = (0, _unwrapProxy.unwrapProxy)(this.get('currentStop'));
        const notifications = this.get('notifications');
        const close = this.onCloseClick;
        const saveTask = this.get('saveTask').bind(this);
        const title = 'Progress will be saved';

        if (currentStopPosition === 1 && !(currentStop.get('arrive') || currentStop.get('depart'))) {
          return close(); // the user hasn't made any changes and decided to close anyways
        }

        tooltip.setProperties({
          tip: 'All stops completed will be performed and saved. Do you want to close this panel?',
          title,
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return saveTask().then(() => {
              notifications.success(`Performed ${currentStopPosition} Stops Successfully`);
            }).finally(() => close());
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            return;
          }
        });
      },

      onPerformButtonClick() {
        const tooltip = this.get('tooltip');
        const currentStopContainer = this.get('currentStopContainer');
        const currentInUseStop = (0, _unwrapProxy.unwrapProxy)(this.get('currentStop'));
        const notifications = this.get('notifications');
        const close = this.onCloseClick;
        const title = 'Perform Stop';
        const saveTask = this.get('saveTask').bind(this);
        this.prepStopPointInSequence(currentInUseStop, currentStopContainer);
        tooltip.setProperties({
          tip: 'Perform all stops in the sequence?',
          title,
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return saveTask().then(() => {
              notifications.success('Performed All Stops Successfully');
              close();
            });
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            return;
          }
        });
      },

      onSelectStop(stop) {
        stop.rollbackAttributes(); // undo stop attributes back to square one

        this.set('currentStop', stop);
        this.set('isNextDisabled', true);
      },

      nextStop() {
        // will only prep all stop points before last stop point
        const currentStopContainer = this.get('currentStopContainer');
        const currentInUseStop = (0, _unwrapProxy.unwrapProxy)(this.get('currentStop'));
        this.prepStopPointInSequence(currentInUseStop, currentStopContainer);
        if (!this.get('hasNextStop')) return false;
        const stops = this.get('stopPoints');
        const currentStop = this.get('currentStop');
        const currentIndex = stops.indexOf(currentStop);
        const nextStop = stops[currentIndex + 1];
        this.set('currentStop', nextStop);
        this.set('isNextDisabled', true);
      },

      setStopContainerReference(stopContainerReference) {
        if (stopContainerReference) {
          this.set('currentStopContainer', stopContainerReference);
        }
      }

    }
  });

  _exports.default = _default;
});