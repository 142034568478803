define("adept-iq/models/cluster", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    state: attr('string'),
    routeOrdinal: attr('number'),
    dispatchRoute: belongsTo('dispatch-route'),
    dispatchSchedule: belongsTo('dispatch-schedule'),
    stopPoints: hasMany('stop-point'),
    startTime: Ember.computed.alias('dispatchRoute.plannedStartTime'),
    endTime: Ember.computed.alias('dispatchRoute.plannedEndTime'),
    orderedStops: Ember.computed.sort('stopPoints', 'orderedStopsSortKeys'),
    orderedStopsSortKeys: ['routeOrdinal'],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    tripStartTime: Ember.computed('stopPoints.@each.tripStartTime', 'startTime', function () {
      const stopPoints = this.get('stopPoints').toArray();
      let min = this.get('startTime') ? this.get('startTime') : null;

      if (!Ember.isEmpty(stopPoints)) {
        stopPoints.forEach(stopPoint => {
          if (min) {
            const tripStartTime = stopPoint.get('tripStartTime');

            if (tripStartTime) {
              min = Math.min(tripStartTime.getTime(), min);
            }
          } else if (stopPoint.get('tripStartTime')) {
            min = stopPoint.get('tripStartTime').getTime();
          }
        });
      }

      return min ? new Date(min) : null;
    }),
    tripEndTime: Ember.computed('stopPoints.@each.tripEndTime', 'endTime', function () {
      const stopPoints = this.get('stopPoints').toArray();
      let max = this.get('endTime') ? this.get('endTime') : null;

      if (!Ember.isEmpty(stopPoints)) {
        stopPoints.forEach(stopPoint => {
          if (stopPoint.get('tripEndTime')) {
            max = Math.max(stopPoint.get('tripEndTime').getTime(), max);
          }
        });
      }

      return max ? new Date(max) : null;
    })
  });

  _exports.default = _default;
});