define("adept-iq/pods/components/side-drawer/assign-vehicle-driver-to-route/vehicle-row/component", ["exports", "ember-light-table/components/lt-row"], function (_exports, _ltRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ltRow.default.extend({
    classNameBindings: ['isUnderCapacity', 'isDisabled'],
    vehicle: Ember.computed.readOnly('row.vehicle'),
    isDisabled: Ember.computed.readOnly('vehicle.placeholder'),
    isUnderCapacity: Ember.computed('extra.travelNeedCounts', 'vehicle.capacityCounts', function () {
      const travelNeedCounts = this.get('extra.travelNeedCounts');
      const capacityCounts = this.get('vehicle.capacityCounts');
      if (!capacityCounts) return false;
      return Object.entries(travelNeedCounts).some(([travelNeedTypeName, travelNeedCount]) => {
        const capacityCount = capacityCounts[travelNeedTypeName] || 0;
        return capacityCount < travelNeedCount;
      });
    })
  });

  _exports.default = _default;
});