define("adept-iq/pods/components/table/cells/base-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Tl0e8CsB",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"value\"],false],[0,\"\\n\"],[4,\"if\",[[23,[\"displayTooltip\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"ember-tooltip\",null,[[\"side\",\"delay\",\"popperContainer\",\"text\"],[\"bottom\",500,\"body\",[23,[\"tooltipText\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/table/cells/base-cell/template.hbs"
    }
  });

  _exports.default = _default;
});