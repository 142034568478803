define("adept-iq/pods/components/side-drawer/compose-role/component", ["exports", "ember-concurrency", "lodash", "adept-iq/config/api-urls"], function (_exports, _emberConcurrency, _lodash, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['compose-role-side-drawer'],
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),
    onShrinkClick: null,
    onCloseClick: null,
    roles: Ember.computed.readOnly('stackFrame.options.roles'),
    title: Ember.computed.readOnly('stackFrame.options.displayName'),

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this.refresh();
    },

    canSave: Ember.computed('roles.@each.displayName', function () {
      const roles = this.get('roles');
      const canSave = roles.every(role => {
        const displayName = role.get('displayName');

        if (displayName) {
          return displayName.length > 0;
        }

        return false;
      });
      return canSave;
    }),

    initSections() {
      const sections = this.preparePermissionSections();
      this.set('sections', sections);
    },

    createPermissionSection(permissions) {
      if (permissions.length === 0) return;
      const permission = permissions[0];
      return {
        title: Ember.String.capitalize(`${permission.category} Permissions`),
        fields: [{
          id: `${permission.category}-Permissions`,
          type: 'permission-section',
          valuePath: 'permissionHash',
          modelName: 'sso-permission',
          isLabel: false,
          extra: {
            category: `${permission.category}`,
            groupedPermissions: permissions
          }
        }]
      };
    },

    preparePermissionSections() {
      const groupedPermissions = this.get('groupedPermissions');
      const permKeys = (0, _lodash.keys)(groupedPermissions) || [];
      this.set('categories', permKeys);
      const sections = permKeys.map(key => {
        return this.createPermissionSection(groupedPermissions[key]);
      });
      return sections;
    },

    refresh() {
      this.get('refreshTask').perform();
    },

    reset() {},

    refreshTask: (0, _emberConcurrency.task)(function* () {
      yield this.get('refreshPermissionTask').perform();
    }),
    refreshPermissionTask: (0, _emberConcurrency.task)(function* () {
      const roles = this.get('roles') || [];
      const permissions = yield this.get('store').findAll('sso-permission');
      const groupedPermissions = (0, _lodash.groupBy)(permissions.toArray(), 'category');
      this.set('permissions', permissions);
      this.set('groupedPermissions', groupedPermissions);
      roles.forEach(role => {
        role.reconfigurePermissionHash(permissions);
      });
      this.initSections();
    }),
    saveTask: (0, _emberConcurrency.task)(function* () {
      // using custom api call because sso api is non standard
      // and in-consistent.
      const ajax = this.get('ajax');
      const session = this.get('session');
      const roles = this.get('roles') || [];
      const notifications = this.get('notifications');
      const rolePermAssociations = yield ajax.request(`${_apiUrls.API.ssoService.host}/role-permission`, {
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      });
      yield Promise.all(roles.map(async record => {
        let currentPerms = record.get('permissionList');
        const displayName = record.get('displayName');
        const roleName = record.get('roleName') ? record.get('roleName') : record.get('displayName').replace(/\s+/, '');
        const rolePermAssocByRole = record.get('isNew') ? [] : rolePermAssociations.filter(rolePerm => rolePerm.roleId === roleName);
        currentPerms = currentPerms.sort((a, b) => a - b); // create a new role

        if (record.get('isNew')) {
          const json = {
            roleName: roleName,
            status: 'active',
            displayName
          };
          await ajax.post(`${_apiUrls.API.ssoService.host}/role`, {
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            },
            data: json
          }).catch(e => {
            notifications.warning(`Unable to Create Role ${displayName}`);
            return Promise.reject(e); // failed to create role, won't be able to assign permissions

            console.error(e); // eslint-disable-line
          });
        } // otherwise patch the role
        else {
            const json = {
              displayName,
              status: 'active'
            };
            await ajax.patch(`${_apiUrls.API.ssoService.host}/role/${roleName}`, {
              contentType: 'application/json',
              headers: {
                'Authorization': `Bearer ${session.data.authenticated.token}`
              },
              data: json
            }).catch(e => {
              notifications.warning(`Unable to Update Role ${displayName}`);
              console.error(e); // eslint-disable-line
            });
          }

        const newPermList = Object.entries(record.get('permissionHash')).map(([key, value]) => {
          if (value) {
            return parseInt(key, 10);
          }

          return null;
        }).filter(val => Ember.isPresent(val)).sort((a, b) => a - b);
        const removedPermList = Object.entries(record.get('permissionHash')).map(([key, value]) => {
          if (!value) {
            return parseInt(key, 10);
          }

          return null;
        }).filter(val => Ember.isPresent(val)).sort((a, b) => a - b); // remove any permissions

        const removedPermissions = (0, _lodash.intersection)(currentPerms, removedPermList) || [];
        await removedPermissions.reduce((acu, permId) => {
          const removedPermAssocId = rolePermAssocByRole.find(rolePerm => rolePerm.permissionId === permId);
          return acu.then(() => ajax.del(`${_apiUrls.API.ssoService.host}/role-permission/${removedPermAssocId}`, {
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            }
          }).catch(e => {
            notifications.warning(`Unable to Remove Permission ${permId}`);
            console.error(e); // eslint-disable-line
          }));
        }, Promise.resolve()); // add any new permissions

        const newPermissions = (0, _lodash.difference)(newPermList, currentPerms) || [];
        return newPermissions.reduce((acu, permId) => {
          const json = {
            roleId: roleName,
            permissionId: permId
          };
          return acu.then(() => ajax.post(`${_apiUrls.API.ssoService.host}/role-permission`, {
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            },
            data: json
          }).catch(e => {
            notifications.warning(`Unable to Save New Permission ${permId}`);
            console.error(e); // eslint-disable-line
          }));
        }, Promise.resolve());
      })).then(async () => {
        await this.get('store').findAll('sso-role');
        await this.get('workspaceContext').get('reloadWorkspace').perform();
        return this.get('activeContext').refreshTableContent(['sso-role']);
      }).catch(e => {
        console.error(e); // eslint-disable-line
      });
    }),
    actions: {
      onValueChange(record, valuePath, options) {
        const value = options;
        record.set(valuePath, value);
      },

      onPermChange(record, valuePath, options) {
        const permHash = record.get(valuePath);
        const {
          id,
          state
        } = options;
        Ember.set(permHash, id, state);
        record.set(valuePath, permHash);
      },

      onSaveRoleButtonClick() {
        const tooltip = this.get('tooltip');
        const role = this.get('roles')[0];
        const isNewRole = role.get('isNew');
        const tip = isNewRole ? 'Create Role and Permissions' : 'Update Role and Permissions';
        const notification = isNewRole ? 'Created Role and Permissions' : 'Updated role and permission';
        tooltip.setProperties({
          tip,
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return this.get('saveTask').perform().then(() => {
              this.get('notifications').success(`${notification} successfully`);
              return this.onCloseClick();
            }).catch(() => {
              this.get('notifications').warning(`${notification} failed`);
            });
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            return;
          }
        });
      },

      onRefreshButtonClick() {
        this.refresh();
      },

      onCloseClick() {
        const roles = this.get('roles') || []; // rollback any dirty attributes unless it's new, remove from store

        roles.forEach(role => {
          role.rollbackAttributes();
        });
        this.onCloseClick();
      }

    }
  });

  _exports.default = _default;
});