define("adept-iq/pods/components/form-components/text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "++oUvR6z",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\"],[11,\"class\",\"form-control\"],[12,\"value\",[27,\"readonly\",[[23,[\"value\"]]],null]],[12,\"placeholder\",[27,\"readonly\",[[23,[\"placeholder\"]]],null]],[12,\"readonly\",[27,\"readonly\",[[23,[\"disabled\"]]],null]],[12,\"oninput\",[27,\"action\",[[22,0,[]],\"onInput\"],[[\"value\"],[\"target.value\"]]]],[12,\"readonly\",[21,\"readonlyCell\"]],[9],[10],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/form-components/text/template.hbs"
    }
  });

  _exports.default = _default;
});