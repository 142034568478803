define("adept-iq/models/vehicle-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    hasMany,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    name: attr('string'),
    displayName: attr('string'),
    vehicleCapacityConfigs: hasMany('vehicle-capacity-config'),
    vehicle: hasMany('vehicle'),
    // TODO: Ankur -  Hardcoded will be remove to some configuration
    noOfAmbulatorySeats: Ember.computed.readOnly('capacityCounts.ambulatory'),
    noOfWideAmbulatorySeats: Ember.computed.readOnly('capacityCounts.wideAmbulatory'),
    noOfWheelChairSeats: Ember.computed.readOnly('capacityCounts.wheelchair'),
    noOfWideWheelChairSeats: Ember.computed.readOnly('capacityCounts.wideWheelchair'),
    noOfServiceAnimalSeats: Ember.computed.readOnly('capacityCounts.serviceAnimal'),
    noOfFlexSeats: Ember.computed.readOnly('capacityCounts.flexSeats'),
    extraWideLift: Ember.computed.gt('capacityCounts.extraWideLift', 0),
    capacityCounts: Ember.computed('vehicleCapacityConfigs.@each.{vehicleCapacityTypeName,count}', function () {
      const initialCounts = {
        wheelchair: 0,
        wideWheelchair: 0,
        ambulatory: 0,
        wideAmbulatory: 0,
        serviceAnimal: 0,
        extraWideLift: 0,
        flexSeats: 0
      };
      const ambulatory = [];
      const vehicleCapacityConfigs = this.get('vehicleCapacityConfigs') || [];
      let capacityCount = initialCounts;
      let ambulatoryMinMax = [];
      capacityCount = vehicleCapacityConfigs.reduce((obj, vehicleCapacityConfig) => {
        const vehicleCapacityTypeName = vehicleCapacityConfig.get('vehicleCapacityTypeName');
        const count = vehicleCapacityConfig.get('count');

        if (vehicleCapacityTypeName === 'ambulatory') {
          ambulatory.push(count);
        }

        obj[vehicleCapacityTypeName] = count;
        return obj;
      }, initialCounts);

      if (!Ember.isEmpty(ambulatory)) {
        ambulatoryMinMax = ambulatory.sort((a, b) => a + b);
      }

      if (ambulatory.length > 1) {
        capacityCount.flexSeats = ambulatoryMinMax[0] - ambulatoryMinMax[ambulatory.length - 1];
      } else {
        capacityCount.flexSeats = ambulatoryMinMax[0];
      }

      return capacityCount;
    })
  });

  _exports.default = _default;
});