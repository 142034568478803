define("adept-iq/models/subscription", ["exports", "ember-data", "lodash", "adept-iq/utils/format-text-extension"], function (_exports, _emberData, _lodash, _formatTextExtension) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var hasMany = _emberData.default.hasMany;
  const {
    sample
  } = _lodash.default;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    requestTime: attr('date'),
    anchor: attr('string'),
    purpose: attr('string'),
    fare: attr('number'),
    originNotes: attr('string'),
    destinationNotes: attr('string'),
    legNotes: attr('string'),
    startDate: attr('date', {
      defaultValue: null
    }),
    endDate: attr('date', {
      defaultValue: null
    }),
    maximumOccurrences: attr('number'),
    serviceWindowName: attr('string'),
    remainingOccurrences: attr('number', {
      defaultValue: 0
    }),
    origin: belongsTo('place', {
      inverse: 'originSubscription'
    }),
    destination: belongsTo('place', {
      inverse: 'destinationSubscription'
    }),
    rider: belongsTo('rider'),
    group: belongsTo('subscription-group'),
    serviceWindow: belongsTo('service-window'),
    fareType: belongsTo('fare-type'),
    bookings: hasMany('bookings'),
    exclusions: hasMany('subscription-exclusion'),
    recurrencePatterns: hasMany('subscription-recurrence-pattern'),
    subscriptionTravelNeeds: hasMany('subscription-travel-need'),
    provider: belongsTo(),
    status: Ember.computed('', function () {
      return sample(['Paused', 'Active']);
    }),
    origins: Ember.computed('origin', function () {
      return [this.get('origin.content')];
    }),
    destinations: Ember.computed('destination', function () {
      return [this.get('destination.content')];
    }),
    noOfTravelNeeds: Ember.computed.alias('subscriptionTravelNeeds.length'),
    equipments: Ember.computed.filterBy('subscriptionTravelNeeds', 'isEquipment', true),
    extraPassengers: Ember.computed.filterBy('subscriptionTravelNeeds', 'isExtraPassenger', true),
    formattedRecurrence: Ember.computed('recurrencePatterns', function () {
      const formattedObject = {
        header: null,
        values: []
      };
      const recurrencePatterns = this.get('recurrencePatterns.firstObject');
      const selectedDOWArray = [];
      if (Ember.isEmpty(recurrencePatterns)) return [];

      if (recurrencePatterns.get('sunday')) {
        selectedDOWArray.push((0, _formatTextExtension.formatTextExtension)('valueItem', 'Monday'));
      }

      if (recurrencePatterns.get('monday')) {
        selectedDOWArray.push((0, _formatTextExtension.formatTextExtension)('valueItem', 'Tuesday'));
      }

      if (recurrencePatterns.get('tuesday')) {
        selectedDOWArray.push((0, _formatTextExtension.formatTextExtension)('valueItem', 'Wednesday'));
      }

      if (recurrencePatterns.get('wednesday')) {
        selectedDOWArray.push((0, _formatTextExtension.formatTextExtension)('valueItem', 'Thursday'));
      }

      if (recurrencePatterns.get('thursday')) {
        selectedDOWArray.push((0, _formatTextExtension.formatTextExtension)('valueItem', 'Friday'));
      }

      if (recurrencePatterns.get('friday')) {
        selectedDOWArray.push((0, _formatTextExtension.formatTextExtension)('valueItem', 'Saturday'));
      }

      if (recurrencePatterns.get('saturday')) {
        selectedDOWArray.push((0, _formatTextExtension.formatTextExtension)('valueItem', 'Sunday'));
      }

      formattedObject.values = selectedDOWArray;
      return formattedObject;
    })
  });

  _exports.default = _default;
});