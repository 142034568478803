define("adept-iq/pods/components/side-drawer/system-config/providers/component", ["exports", "moment", "adept-iq/utils/regex-functions"], function (_exports, _moment, _regexFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const daysOfService = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'holiday'];

  var _default = Ember.Component.extend({
    classNames: ['providers'],
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    workspace: Ember.inject.service(),
    daysOfService,
    dayOfWeeks: null,
    value: null,
    selected: null,
    status: null,
    providerTypes: null,
    disableBtns: null,
    minTripCountDate: null,
    maxTripCountDate: null,
    tripCountDay: null,
    tripCountWeekDays: null,
    zipCodes: null,
    zipCodesSelected: null,
    zoneNames: null,
    zones: null,
    selectedZone: null,
    selectedCostZoneOne: null,
    selectedCostZoneTwo: null,
    newZoneName: null,
    firstZoneName: null,
    secondZoneName: null,
    zoneCost: null,
    cost: null,
    isNewProvider: false,
    disableForm: true,
    inValidEmail: false,
    disableTripCount: true,
    addZoneName: false,
    addCostName: false,
    inValidZone: false,
    inValidCostZone: false,
    isNewZoneCost: true,
    disableZoneActions: Ember.computed('newZoneName', 'zipCodesSelected.@each', 'selectedZone.@each', function () {
      const zoneName = this.get('newZoneName');
      const zipCodesSelected = this.get('zipCodesSelected') || [];
      const selectedZone = this.get('selectedZone') || [];

      if (!Ember.isEmpty(zoneName) && !Ember.isEmpty(zipCodesSelected) || !Ember.isEmpty(selectedZone) && !Ember.isEmpty(zipCodesSelected)) {
        return false;
      }

      return true;
    }),
    disableCostActions: Ember.computed('selectedCostZoneOne.@each', 'selectedCostZoneTwo.@each', 'cost', function () {
      const zone1 = this.get('selectedCostZoneOne') || [];
      const zone2 = this.get('selectedCostZoneTwo') || [];
      const cost = this.get('cost');
      return Ember.isEmpty(zone1) || Ember.isEmpty(zone2) || Ember.isEmpty(cost);
    }),
    selectedRow: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    showBrokerPanel: Ember.computed('provider.providerType', function () {
      const type = this.get('provider.providerType');
      return type !== 'provider';
    }),
    disableSavebtn: Ember.computed('disableForm', 'provider.{id,name,status,providerType,contactName,contactPhone,contactEmail}', function () {
      const disableForm = this.get('disableForm');

      if (disableForm) {
        return true;
      }

      const hasValues = Object.values(this.get('provider')).every(input => !Ember.isEmpty(input));
      return !hasValues;
    }),
    disableUndobtn: Ember.computed('disableForm', 'provider.{id,name,status,providerType,contactName,contactPhone,contactEmail}', function () {
      const disableForm = this.get('disableForm');

      if (disableForm) {
        return true;
      }

      const hasValues = Object.values(this.get('provider')).some(input => !Ember.isEmpty(input));
      return !hasValues;
    }),
    selectedRowChange: Ember.observer('selectedRow', function () {
      const selectedRow = this.get('selectedRow');
      this.setDefaultProperties(selectedRow);
    }),

    init() {
      this._super(...arguments);

      this.set('status', ['Active', 'Inactive']);
      this.set('providerTypes', ['provider', 'broker']); // Hard coded for now and need to be removed once we have the model data.

      const zone = [{
        id: 'zone1',
        zipCodes: [{
          code: '1011'
        }, {
          code: '10112'
        }, {
          code: '1111'
        }, {
          code: '11133'
        }],
        selectedZipCodes: [{
          code: '1011'
        }, {
          code: '1111'
        }]
      }, {
        id: 'zone2',
        zipCodes: [{
          code: '2011'
        }, {
          code: '20112'
        }, {
          code: '2111'
        }, {
          code: '21133'
        }]
      }, {
        id: 'zone3',
        zipCodes: [{
          code: '3011'
        }, {
          code: '3201'
        }, {
          code: '3333'
        }, {
          code: '32265'
        }]
      }];
      const zoneCost = [{
        'zone1-zone1': '$10'
      }, {
        'zone2-zone1': '$15'
      }, {
        'zone2-zone2': '$12'
      }];
      this.set('zones', zone);
      this.set('zoneCost', zoneCost);
      this.setDefaultProperties();
      this.defaultSettings();
      const today = new Date().toDateString();
      const minDate = (0, _moment.default)(today).add(0, 'days').toDate();
      const maxDate = (0, _moment.default)(today).add(2, 'days').toDate();
      this.set('maxTripCountDate', maxDate);
      this.set('minTripCountDate', minDate);
    },

    setDefaultProperties(record = []) {
      const fields = {
        id: '',
        name: '',
        status: '',
        providerType: '',
        contactName: '',
        contactPhone: '',
        contactEmail: ''
      };
      const tripCountWeekDays = [];

      if (!Ember.isEmpty(record)) {
        Object.entries(fields).forEach(key => {
          fields[key[0]] = record.get(key[0]);
        });
        this.set('disableForm', true);
      }

      this.get('daysOfService').forEach(() => {
        tripCountWeekDays.push({
          targetCount: 0,
          underage: 0,
          overage: 0
        });
      });
      this.set('tripCountDay', {
        date: null,
        targetCount: 0,
        underage: 0,
        overage: 0
      });
      this.set('tripCountWeekDays', tripCountWeekDays);
      this.set('provider', Ember.Object.create(fields));
      this.set('inValidEmail', false);
      this.set('disableTripCount', true);
    },

    defaultSettings() {
      const disableBtns = {
        new: false,
        edit: false,
        delete: false,
        save: true,
        undo: true
      };
      this.set('disableBtns', disableBtns);
    },

    validateEmail() {
      const email = this.get('provider.contactEmail');

      const isValid = _regexFunctions.REGEX_EMAIL.test(email);

      if (isValid) {
        this.set('inValidEmail', false);
        return true;
      }

      const tooltip = this.get('tooltip');
      tooltip.setProperties({
        title: 'Providers-Contact Information',
        hasoverlay: true,
        tip: 'Invalid Email',
        primaryActionText: 'ok',
        primaryAction: () => {
          this.set('inValidEmail', true);
        }
      });
      return false;
    },

    validateServiceZone(newZoneName) {
      const availableZone = this.get('zones').filter(zone => zone.id === newZoneName) || [];
      const existingZipCodes = availableZone.firstObject.selectedZipCodes || [];

      if (!existingZipCodes.length) {
        return true;
      }

      const tooltip = this.get('tooltip');
      tooltip.setProperties({
        title: 'Providers-Service Zone',
        hasoverlay: true,
        tip: `${newZoneName} already exist`,
        primaryActionText: 'ok',
        primaryAction: () => {
          this.set('inValidZone', true);
        }
      });
      return false;
    },

    fetchZoneCost() {
      const zoneCost = this.get('zoneCost');
      const zone1 = this.get('selectedCostZoneOne') || {};
      const zone2 = this.get('selectedCostZoneTwo') || {};
      this.set('cost', null);
      this.set('isNewZoneCost', true);
      if (Ember.isEmpty(zone1) || Ember.isEmpty(zone2)) return;
      const zoneCombination1 = `${zone1.id}-${zone2.id}`;
      const zoneCombination2 = `${zone2.id}-${zone1.id}`;
      const cost = zoneCost.filter(item => !Ember.isEmpty(item[zoneCombination1]) || !Ember.isEmpty(item[zoneCombination2]));

      if (!Ember.isEmpty(cost)) {
        const value = cost.firstObject[zoneCombination1] || cost.firstObject[zoneCombination2];
        this.set('cost', value);
        this.set('isNewZoneCost', false);
      }
    },

    actions: {
      newProviders() {
        this.setDefaultProperties();
        this.setProperties({
          isNewProvider: true,
          disableForm: false
        });
        this.get('tableRef').onUncheckAll();
      },

      editProviders() {
        const selectedRow = this.get('selectedRow');
        this.setDefaultProperties(selectedRow);
        this.setProperties({
          isNewProvider: false,
          disableForm: false
        });
      },

      deleteProviders() {
        const tooltip = this.get('tooltip');
        tooltip.setProperties({
          title: 'System Configuration',
          hasoverlay: true,
          tip: 'Are you sure you want to Delete these changes?',
          primaryActionText: 'Confirm',
          primaryAction: () => {//return this.get('delete').perform();
          },
          secondaryActionText: 'Cancel'
        });
      },

      saveProviders() {
        if (this.validateEmail()) {
          const tooltip = this.get('tooltip');
          tooltip.setProperties({
            title: 'System Configuration',
            hasoverlay: true,
            tip: 'Are you sure you want to save these changes?',
            primaryActionText: 'Confirm',
            primaryAction: () => {//return this.get('save').perform();
            },
            secondaryActionText: 'Cancel'
          });
        }
      },

      onStatusChange(val) {
        this.set('provider.status', val);
      },

      onProviderTypeChange(val) {
        this.set('provider.providerType', val);
      },

      onProviderChange() {},

      onUpdateTripCountDate(selectedDate) {
        this.set('tripCountDay', {
          date: selectedDate,
          targetCount: 0,
          underage: 0,
          overage: 0
        });
        this.set('disableTripCount', false);
      },

      onAddServiceZone() {
        this.setProperties({
          zipCodesSelected: null,
          selectedZone: null,
          addZoneName: true
        });
      },

      onRemoveServiceZone() {
        const tooltip = this.get('tooltip');
        const selectedZone = this.get('selectedZone');
        tooltip.setProperties({
          title: 'Providers-Service Zone',
          hasoverlay: true,
          tip: `Do you want to delete ${selectedZone.id}?`,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            this.get('zones').removeObject(selectedZone);
          },
          secondaryActionText: 'Cancel'
        });
      },

      onSaveServiceZone() {
        const tooltip = this.get('tooltip');
        const newZoneName = this.get('newZoneName');
        const isValid = this.validateServiceZone(newZoneName);

        if (isValid) {
          tooltip.setProperties({
            title: 'Providers-Service Zone',
            hasoverlay: true,
            tip: `Do you want to save ${newZoneName}?`,
            primaryActionText: 'Confirm',
            primaryAction: () => {
              this.set('addZoneName', false);
            },
            secondaryActionText: 'Cancel'
          });
        }
      },

      removeSelectedZipCode(zipCode) {
        const selectedZipcodes = this.get('zipCodesSelected') || [];
        selectedZipcodes.removeObject(zipCode);
        this.set('zipCodesSelected', selectedZipcodes);
      },

      onZoneChange(selectedZone) {
        const selectedZipCodes = selectedZone.selectedZipCodes || [];
        this.set('selectedZone', selectedZone);
        this.set('zipCodes', selectedZone.zipCodes);
        this.set('zipCodesSelected', selectedZipCodes);
      },

      onZoneOneChange(selectedZone) {
        this.set('selectedCostZoneOne', selectedZone);
        this.fetchZoneCost();
      },

      onZoneTwoChange(selectedZone) {
        this.set('selectedCostZoneTwo', selectedZone);
        this.fetchZoneCost();
      },

      findZipCode(newZoneName) {
        const availableZone = this.get('zones').filter(zone => zone.id === newZoneName) || [];
        const zipCodes = availableZone.length ? availableZone.firstObject.zipCodes : [];
        this.set('zipCodes', zipCodes);
        this.set('newZoneName', newZoneName);
        this.set('inValidZone', false);
        this.set('zipCodesSelected', []);
      },

      onRemoveCostZone() {
        const tooltip = this.get('tooltip'); //const selectedZone = this.get('selectedZone');

        const zone1 = this.get('selectedCostZoneOne');
        const zone2 = this.get('selectedCostZoneTwo');
        tooltip.setProperties({
          title: 'Providers-Cost',
          hasoverlay: true,
          tip: `Do you want to delete cost for ${zone1.id} to ${zone2.id}?`,
          primaryActionText: 'Confirm',
          primaryAction: () => {//this.get('zoneCost').removeObject(selectedZone);
          },
          secondaryActionText: 'Cancel'
        });
      },

      onAddCostZone() {
        this.setProperties({
          selectedCostZoneOne: null,
          selectedCostZoneTwo: null,
          cost: null,
          addCostName: true
        });
      },

      onSaveCostZone() {
        const tooltip = this.get('tooltip');
        const zone1 = this.get('selectedCostZoneOne');
        const zone2 = this.get('selectedCostZoneTwo');
        const isNewZoneCost = this.get('isNewZoneCost');
        let tip = 'Modify?';

        if (isNewZoneCost) {
          tip = `Do you want to modify cost for ${zone1.id} to ${zone2.id}?`;
        }

        tooltip.setProperties({
          title: 'Providers-Cost',
          hasoverlay: true,
          tip,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            this.setProperties({
              selectedCostZoneOne: null,
              selectedCostZoneTwo: null,
              cost: null,
              addCostName: false
            });
          },
          secondaryActionText: 'Cancel'
        });
      }

    }
  });

  _exports.default = _default;
});