define("adept-iq/pods/components/generic-components/table/columns/check-box/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['table-check-box-column'],
    isChecked: Ember.computed('table.rows.@each.isChecked', function () {
      const rows = this.get('table.rows'); // it's weird to see checkbox when no records are shown

      if (!rows || rows.length === 0) return false;
      return rows.isEvery('isChecked');
    }),
    actions: {
      onCheckboxToggle(event) {
        // these are passed by the generic column widget's template
        const {
          checkAll,
          uncheckAll
        } = this.get('extra');

        if (event.target.checked && checkAll) {
          Ember.run.next(checkAll);
        } else if (uncheckAll) {
          Ember.run.next(uncheckAll);
        }
      }

    }
  });

  _exports.default = _default;
});