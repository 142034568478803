define("adept-iq/classes/map-contexts/layered", ["exports", "adept-iq/classes/map-contexts/base", "adept-iq/classes/markers/vehicles/road-supervisor", "adept-iq/config/column-types", "adept-iq/config/filter-types", "adept-iq/utils/compute-ordered-ids", "moment", "lodash", "tomtom", "cheap-ruler", "ember-inflector", "adept-iq/utils/filters"], function (_exports, _base, _roadSupervisor, _columnTypes, _filterTypes, _computeOrderedIds, _moment, _lodash, _tomtom, _cheapRuler, _emberInflector, _filters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // base class for layered map contexts; use this when lots of entities are
  // present, or where filtering/opacity is required
  // need to understand what this is
  const CIRCLE_RADIUS_FOR_MARKER_BOUNDARY = 100; // Street Details Scale at 100m a single marker

  const DEFAULT_ZOOM_PAD_BUFFER_RATIO = 0.5; // Leave some space from invisible rectangular box

  var _default = _base.default.extend({
    polylineService: null,
    // set these in extending subclass
    markersData: Ember.computed('', () => []),
    polylineData: Ember.computed('', () => []),
    shapeData: Ember.computed('', () => []),
    polygonData: Ember.computed('', () => []),
    layeredRecords: Ember.computed('', () => []),
    //used to render rs vehicle if required
    roadSupervisor: null,
    mapStateProvider: Ember.computed.readOnly('workspace.dashboard'),
    isSearchAllowed: Ember.computed.bool('workspace.configPermissions.map.searchAllowed'),
    isFilterAllowed: Ember.computed.bool('workspace.configPermissions.map.filterAllowed'),
    editContextMenuOption: Ember.computed('', function () {
      return {
        id: 'edit',
        name: 'Edit',
        action: model => {
          const inflector = new _emberInflector.default(_emberInflector.default.defaultRules);
          const modelName = inflector.pluralize(model.constructor.modelName); // TODO: this was the existing behaviour, but it is insufficient;
          // generally, iq-models are passed in, which results in an incorrect
          // widget component name

          const component = `iq-widgets/${modelName}-form-widget`;
          this.get('editModal').open(component, [model]);
        }
      };
    }),

    init() {
      this._super(...arguments);

      const layerConfig = this.get('layersConfig'); // these are needed for all related computed properties

      const objectForFunctions = ['polygon', 'polyline', 'marker', 'shape'];
      const classForFunctions = ['polygon', 'polyline', 'marker', 'shape'];
      objectForFunctions.forEach(name => {
        const func = this.get(`_${name}ObjectFor`);
        (true && !(Ember.isPresent(func)) && Ember.assert(`base class layered requires derived classes to override function _${name}ObjectForLayer`, Ember.isPresent(func)));
      });
      classForFunctions.forEach(name => {
        const func = this.get(`_${name}ClassForLayer`);
        (true && !(Ember.isPresent(func)) && Ember.assert(`base class layered requires derived classes to override function _${name}ClassForLayer`, Ember.isPresent(func)));
      });
      (true && !(Ember.isPresent(layerConfig)) && Ember.assert('base class layered requires derived classes to override layer config property', Ember.isPresent(layerConfig)));
    },

    bounds: Ember.computed('activeContext.topActiveContext.implicitMarkers.@each.{lat,lng}', 'activeContext.topActiveContext.activeVehicles.@each.{lat,lng}', function () {
      const activeVehicles = this.get('activeContext.topActiveContext.activeVehicles') || [];
      let markers = this.get('activeContext.topActiveContext.implicitMarkers') || [];
      let validMarkers = 0; // zooming boundaries use active vehicles otherwise implicit context takes priority

      if (markers.length === 0) {
        markers = activeVehicles;
      }

      const padRatio = DEFAULT_ZOOM_PAD_BUFFER_RATIO / markers.length;

      const bounds = _tomtom.default.L.latLngBounds();

      markers.forEach(marker => {
        if (marker.lat && marker.lng) {
          bounds.extend([marker.lat, marker.lng]);
          validMarkers++;
        }
      });

      if (validMarkers === 1) {
        const circleBounds = this._createBounds(markers[0]);

        if (circleBounds) {
          return circleBounds.pad(padRatio);
        }
      }

      return bounds._southWest ? bounds.pad(padRatio) : bounds;
    }),
    boundsID: Ember.computed('activeContext.topActiveContext.checkedItems.[]', function () {
      const itemsSelected = this.get('activeContext.topActiveContext.checkedItems');
      return itemsSelected.map(item => item.record.id).join(',');
    }),
    contextMenuOptions: Ember.computed('workspace.configPermissions.map.singleActions.[]', function () {
      const contextMenuOptions = [this.get('editContextMenuOption')];
      const permittedSingleActions = this.get('workspace.configPermissions.map.singleActions');
      if (Ember.isEmpty(permittedSingleActions)) return [];
      if (permittedSingleActions.includes('all')) return contextMenuOptions;
      return contextMenuOptions.filter(({
        id
      }) => {
        return permittedSingleActions.includes(id);
      });
    }),
    isFiltered: Ember.computed('layers', function () {
      return this.get('layers').any(({
        labels
      }) => {
        return labels.any(label => {
          const {
            filterTypeId,
            filterValues
          } = label;
          const filterType = _filterTypes.filterTypesHash[filterTypeId];
          return (0, _filters.testFilterValues)(filterType, filterValues);
        });
      });
    }),
    // merges the map state on top of the layers config; re-computed every time
    // the underlying state changes
    layers: Ember.computed('mapState', 'layersConfig', function () {
      const config = _lodash.default.cloneDeep(this.get('layersConfig'));

      const mapState = this.get('mapState'); // remove labels that are un-available

      config.forEach(currConfig => {
        const labels = [];
        currConfig.labels.forEach(label => {
          if (!label.unAvailable) {
            labels.push(label);
          }
        });

        if (labels.length > 0) {
          currConfig.labels = labels;
        }
      });
      if (!mapState) return config;
      Object.entries(mapState).forEach(([layerId, layerState]) => {
        const layer = config.findBy('id', layerId);
        if (!layer) return;
        ['isVisible', 'opacity', 'isDisplayMapIconLabel'].forEach(property => {
          if (Ember.isNone(layerState[property])) return;
          layer[property] = layerState[property];
        });

        if (layerState.isDisplayMapIconLabel) {
          layer.selectedDisplay = 'Always';
        } else {
          layer.selectedDisplay = 'On Left Click';
        }

        if (layerState.types) {
          Object.entries(layerState.types).forEach(([typeId, typeState]) => {
            const type = layer.types.findBy('id', typeId);
            if (!type) return;
            ['isVisible'].forEach(property => {
              if (Ember.isNone(typeState[property])) return;
              type[property] = typeState[property];
            });
          });
        }

        if (layerState.labels) {
          Object.entries(layerState.labels).forEach(([labelId, labelState]) => {
            const label = layer.labels.findBy('id', labelId);
            if (!label) return;
            ['isVisible', 'index', 'filterTypeId', 'filterValues'].forEach(property => {
              if (Ember.isNone(labelState[property])) return;
              label[property] = labelState[property];
            });
          });
        }

        layer.labels = layer.labels || []; // ensure every label has index

        const orderedIds = (0, _computeOrderedIds.default)(layer.labels);
        orderedIds.forEach((labelId, index) => {
          const label = layer.labels.findBy('id', labelId);
          label.index = index;
        });
      });
      return config;
    }),
    markers: Ember.computed('layers', 'layeredRecords', 'searchText', function () {
      const searchText = this.get('searchText');
      const layers = this.get('layers').filterBy('type', 'markers');
      const layeredRecords = this.get('layeredRecords');
      const layerMarkers = layers.map(layer => {
        const layerRecords = layeredRecords[layer.id] || [];
        const filterNode = (0, _filters.buildCompoundFilterNode)('and', [this._buildSearchFilterNode(layer, searchText), this._buildColumnFilterNode(layer)]);
        const filter = (0, _filters.buildFilterFunction)(filterNode);
        return layer.types.map(type => {
          if (type.isVisible === false) return [];
          let typeRecords = layerRecords;

          if (type.filterKey) {
            typeRecords = layerRecords.filter(record => {
              if (type.hasOwnProperty('filterValue')) {
                return record.get(type.filterKey) === type.filterValue;
              }

              return record.get(type.filterKey);
            });
          }

          const filteredRecords = typeRecords.filter(filter);
          return filteredRecords.reduce((arr, record) => {
            const marker = this._markerObjectFor(layer, type, record);

            if (marker.get('marker')) {
              if (layer.isDisplayMapIconLabel) {
                if (!marker.get('marker').isPopupOpen()) marker.get('marker').bindPopup(this._getLabel(record, layer), {
                  closeButton: false,
                  autoClose: false,
                  closeOnClick: false,
                  autoPan: false
                }).openPopup();
              } else if (marker.get('marker').isPopupOpen() && !marker.get('marker._popup.options.autoPan')) {
                marker.get('marker').closePopup();
                marker.get('marker').bindPopup(this._getLabel(record, layer), {
                  closeButton: false
                });
              }
            }

            marker.setProperties({
              opacity: layer.opacity,
              // marker can update its own style based on layer config
              layer,
              layerType: type,
              // tells marker that it may appear/disappear based on AC
              isActive: layer.isActive,
              // this should be moved to inside marker class
              label: this._getLabel(record, layer)
            });
            arr.push(marker);
            return arr;
          }, []);
        });
      }); // @TODO might be better to create a different context, this automatically creates a bunch rs vehicle markers for no reason other than vehicles

      layerMarkers.push(_roadSupervisor.default.create({
        record: this.get('roadSupervisor.rsVehicle')
      }));
      return _lodash.default.flattenDeep(layerMarkers);
    }),
    polylines: Ember.computed('layeredRecords', 'layers', 'searchText', function () {
      // each item is a store record, with `otp` if so endowed
      const searchText = this.get('searchText');
      const layers = this.get('layers').filterBy('type', 'polylines');
      const layeredRecords = this.get('layeredRecords');
      const layerPolylines = layers.map(layer => {
        if (layer.isVisible === false) return [];
        const layerRecords = layeredRecords[layer.id] || [];
        const filterNode = (0, _filters.buildCompoundFilterNode)('and', [this._buildSearchFilterNode(layer, searchText), this._buildColumnFilterNode(layer)]);
        const filter = (0, _filters.buildFilterFunction)(filterNode);
        const stopsIsVisible = this.get('layers').findBy('id', 'stops').isVisible;
        return layer.types.map(type => {
          if (!type.isVisible) return [];
          let typeRecords = layerRecords;

          if (type.filterKey) {
            typeRecords = typeRecords.filter(record => {
              if (type.hasOwnProperty('filterValue')) {
                return record.get(type.filterKey) === type.filterValue;
              }

              return record.get(type.filterKey);
            });
          }

          const filteredRecords = typeRecords.filter(filter);
          return filteredRecords.map(record => {
            const polyline = this._polylineObjectFor(layer, type, record);

            const label = this._getLabel(record, layer);

            if (polyline.get('polyline')) {
              if (layer.isDisplayMapIconLabel) {
                if (!polyline.get('polyline').isPopupOpen()) polyline.get('polyline').bindPopup(this._getLabel(record, layer), {
                  closeButton: false,
                  autoClose: false,
                  closeOnClick: false,
                  autoPan: false
                }).openPopup();
              } else if (polyline.get('polyline').isPopupOpen() && !polyline.get('polyline._popup.options.autoPan')) {
                polyline.get('polyline').closePopup();
                polyline.get('polyline').bindPopup(this._getLabel(record, layer), {
                  closeButton: false
                });
              }
            }

            polyline.setProperties({
              layer,
              layerType: type,
              label: label,
              opacity: layer.opacity
            });

            if (layer.id === 'trips') {
              polyline.set('stopsIsVisible', stopsIsVisible);
            }

            return polyline;
          });
        });
      });
      return _lodash.default.flattenDeep(layerPolylines);
    }),
    polygons: Ember.computed('layeredRecords', 'layers', 'searchText', function () {
      const searchText = this.get('searchText');
      const layers = this.get('layers').filterBy('type', 'polygons');
      const layeredRecords = this.get('layeredRecords');
      const layerPolygons = layers.map(layer => {
        if (layer.isVisible === false) return [];
        let layerRecords;

        if (layer.isActive) {
          layerRecords = layeredRecords[layer.id] || [];
        } else {
          // TODO: query `workspace-context` instead of store
          layerRecords = this.get('store').peekAll(layer.modelName);
        }

        const filterNode = (0, _filters.buildCompoundFilterNode)('and', [this._buildSearchFilterNode(layer, searchText), this._buildColumnFilterNode(layer)]);
        const filter = (0, _filters.buildFilterFunction)(filterNode);
        return layer.types.map(type => {
          if (type.isVisible === false) return [];
          let typeRecords = layerRecords;

          if (type.filterKey) {
            typeRecords = typeRecords.filter(record => {
              if (type.hasOwnProperty('filterValue')) {
                return record.get(type.filterKey) === type.filterValue;
              }

              return record.get(type.filterKey);
            });
          }

          const filteredRecords = typeRecords.filter(filter);
          return filteredRecords.reduce((arr, record) => {
            const polygon = this._polygonObjectFor(layer, type, record); // TODO: move this to polygon model


            const polygonStyle = type.style;
            polygon.setProperties({
              style: polygonStyle,
              type: type.id,
              label: this._getLabel(record, layer),
              parentLabel: this._getParentLabel(record, layer),
              opacity: layer.opacity,
              isActive: layer.isActive
            });
            arr.push(polygon);
            return arr;
          }, []);
        });
      });
      return _lodash.default.flattenDeep(layerPolygons);
    }),
    shapes: Ember.computed('layeredRecords', 'layers', 'searchText', function () {
      const searchText = this.get('searchText');
      const layers = this.get('layers').filterBy('type', 'shapes');
      const layeredRecords = this.get('layeredRecords');
      const layerShapes = layers.map(layer => {
        if (!layer.isVisible || !layer.isActive) return [];
        const layerRecords = layeredRecords[layer.id] || [];
        const filterNode = (0, _filters.buildCompoundFilterNode)('and', [this._buildSearchFilterNode(layer, searchText), this._buildColumnFilterNode(layer)]);
        const filter = (0, _filters.buildFilterFunction)(filterNode);
        return layer.types.map(type => {
          if (type.isVisible === false) return [];
          let typeRecords = layerRecords;

          if (type.filterKey) {
            typeRecords = typeRecords.filter(record => {
              if (type.hasOwnProperty('filterValue')) {
                return record.get(type.filterKey) === type.filterValue;
              }

              return record.get(type.filterKey);
            });
          }

          const filteredRecords = typeRecords.filter(filter);
          return filteredRecords.reduce((arr, record) => {
            const shape = this._shapeObjectFor(layer, type, record);

            const shapeStyle = type.style;
            shape.setProperties({
              style: shapeStyle,
              type: type.id,
              label: this._getLabel(record, layer),
              parentLabel: this._getParentLabel(record, layer),
              opacity: layer.opacity,
              isActive: layer.isActive,
              radius: record.get('radius'),
              noOfPoints: record.get('noOfPoints')
            });
            arr.push(shape);
            return arr;
          }, []);
        });
      });
      return _lodash.default.flattenDeep(layerShapes);
    }),

    setLayerVisibility(layerId, isVisible) {
      this.get('mapStateProvider').mergeMapState({
        [layerId]: {
          isVisible
        }
      });
    },

    setDisplayMapIconLabelAllTime(layerId, isDisplayMapIconLabel) {
      this.get('mapStateProvider').mergeMapState({
        [layerId]: {
          isDisplayMapIconLabel
        }
      });
    },

    setLayerOpacity(layerId, opacity) {
      this.get('mapStateProvider').mergeMapState({
        [layerId]: {
          opacity
        }
      });
    },

    setLayerTypeVisibility(layerId, typeId, isVisible) {
      this.get('mapStateProvider').mergeMapState({
        [layerId]: {
          types: {
            [typeId]: {
              isVisible
            }
          }
        }
      });
    },

    setLayerLabelVisibility(layerId, labelId, isVisible) {
      this.get('mapStateProvider').mergeMapState({
        [layerId]: {
          labels: {
            [labelId]: {
              isVisible
            }
          }
        }
      });
    },

    setLayerLabelFilterType(layerId, labelId, filterTypeId) {
      this.get('mapStateProvider').mergeMapState({
        [layerId]: {
          labels: {
            [labelId]: {
              filterTypeId
            }
          }
        }
      });
    },

    setLayerLabelFilterValues(layerId, labelId, filterValues) {
      this.get('mapStateProvider').mergeMapState({
        [layerId]: {
          labels: {
            [labelId]: {
              filterValues
            }
          }
        }
      });
    },

    setLayerLabelsOrder(layerId, labelIds) {
      const labels = labelIds.reduce((obj, labelId, index) => {
        obj[labelId] = {
          index
        };
        return obj;
      }, {});
      this.get('mapStateProvider').mergeMapState({
        [layerId]: {
          labels
        }
      });
    },

    _buildSearchFilterNode(layer, searchText) {
      if (Ember.isBlank(searchText) || !layer.isVisible) return null;
      if (!layer.isVisible) return null;
      const filterNodes = layer.labels.reduce((arr, label) => {
        if (!label.isSearchable) return arr; // TODO: determine if we shuld be searching hidden labels
        // if (!label.isVisible) return arr;

        const filterType = _columnTypes.columnTypesHash[label.type].searchFilterType;
        const filterNode = (0, _filters.buildValueFilterNode)(filterType, label.valuePath, searchText);
        arr.push(filterNode);
        return arr;
      }, []);
      return (0, _filters.buildCompoundFilterNode)('or', filterNodes);
    },

    _buildColumnFilterNode(layer) {
      if (!layer.isVisible) return null;
      const filterNodes = layer.labels.reduce((arr, label) => {
        if (!label.isVisible || !label.isSearchable) return arr;
        const filterType = _filterTypes.filterTypesHash[label.filterTypeId];
        let filterValues = label.filterValues;
        if (!(0, _filters.testFilterValues)(filterType, filterValues)) return arr;
        filterValues = label.filterValues.map(filterType.parseValue);
        const filterNode = (0, _filters.buildValueFilterNode)(filterType, label.valuePath, filterValues);
        arr.push(filterNode);
        return arr;
      }, []);
      return (0, _filters.buildCompoundFilterNode)('and', filterNodes);
    },

    _getLabel(record, layer) {
      const lines = [];
      const sortedLabels = layer.labels.sort((a, b) => a.index - b.index);
      sortedLabels.forEach(label => {
        if (!label.isVisible) {
          return;
        }

        let recordValue = record.get(`${label.valuePath}`); // We do not have enums such as vehicle availabilities etc in avlmlite
        // This only prevents enums from displaying on the map label

        if (label.type === 'enum' && Ember.isPresent(recordValue)) {
          const values = Ember.isArray(recordValue) ? recordValue : recordValue.values;
          let header = '';
          const listItems = [];
          values.forEach(value => {
            if (value.className === 'mainItem') {
              header = `${label.label}: ${value.value}`;
            } else {
              listItems.push(`<li>${value.value}</li>`);
            }
          });
          const list = `${header} <ul style="margin: 0;">${listItems.join('')}</ul>`;
          lines.push(list);
        } else {
          if (label.format) {
            recordValue = (0, _moment.default)(recordValue).format(label.format);
          }

          lines.push(`${label.label}: ${recordValue || recordValue === false ? recordValue : ''}`);
        }
      });
      return `${lines.join('<br/>')}`;
    },

    _getParentLabel(record, layer) {
      const lines = [];
      const sortedLabels = layer.labels.sort((a, b) => a.index - b.index);
      sortedLabels.forEach(label => {
        if (!label.isVisible || !label.parentLabel) return;
        lines.push(`${record.get(label.valuePath)}`);
      });
      return `${lines.join('<br/>')}`;
    },

    _createBounds(marker) {
      const {
        lat,
        lng
      } = marker;

      if (lat && lng) {
        const ruler = (0, _cheapRuler.default)(lat, 'meters');
        const bbox = ruler.bufferPoint([lat, lng], CIRCLE_RADIUS_FOR_MARKER_BOUNDARY);

        const p1 = _tomtom.default.L.latLng(bbox[0], bbox[1]);

        const p2 = _tomtom.default.L.latLng(bbox[2], bbox[3]);

        return _tomtom.default.L.latLngBounds(p1, p2);
      }

      return null;
    }

  });

  _exports.default = _default;
});