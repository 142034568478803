define("adept-iq/utils/enoughVehicleCapacity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.enoughCapacity = void 0;

  const enoughCapacity = (travelNeedCounts, capacityCounts) => {
    if (!capacityCounts) return false;
    return Object.entries(travelNeedCounts).some(([travelNeedTypeName, travelNeedCount]) => {
      const capacityCount = capacityCounts[travelNeedTypeName] || 0;
      return capacityCount >= travelNeedCount;
    });
  };

  _exports.enoughCapacity = enoughCapacity;
});