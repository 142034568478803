define("adept-iq/pods/components/table/cells/text-extension/component", ["exports", "adept-iq/pods/components/table/cells/base-cell/component", "adept-iq/mixins/table-row-value"], function (_exports, _component, _tableRowValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_tableRowValue.default, {
    classNames: ['table-text-extension-cell'],
    textContent: Ember.computed('value', function () {
      const value = this.get('value.values');
      if (!value || value.length < 1) return '';
      const length = value.filter(val => val.className === 'valueItem').length; // show the actual value when there is only one item

      if (length > 1) {
        return `${length} ${this.get('column.cellDesc')}`;
      } else if (length === 1) {
        return value.firstObject.value;
      }

      return '';
    }),
    actions: {
      showDropdown(dropdown, event) {
        if (this.value.values.length > 1) {
          dropdown.actions.open(event);
        }
      },

      hideDropdown(dropdown, event) {
        if (this.value.values.length > 1) {
          dropdown.actions.close(event);
        }
      }

    }
  });

  _exports.default = _default;
});