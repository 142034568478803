define("adept-iq/models/segment-stop-phone-number", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    areaCode: attr('string'),
    phoneNumber: attr('string'),
    extension: attr('string'),
    type: attr('string', {
      defaultValue: 'home'
    }),
    segmentStop: belongsTo('segment-stop'),
    formattedPhoneNumber: Ember.computed('areaCode', 'phoneNumber', 'extension', function () {
      const phone = this.get('phoneNumber');
      const extension = this.get('extension');
      const areaCode = this.get('areaCode');

      if (extension) {
        return `(${areaCode})${phone}-#${extension}`;
      }

      return `(${areaCode})${phone}`;
    })
  });

  _exports.default = _default;
});