define("adept-iq/pods/components/generic-components/text-area/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['text-area-container'],
    placeholder: 'description',
    value: null,
    disabled: false,
    maxLength: 500,
    showHint: true,

    init() {
      this._super(...arguments);

      const onChange = this.get('onChange'); // eslint-disable-next-line no-console

      if (!onChange) console.warn('Component Output to Parent Component requires an onChange method to be passed into text-area component');
    },

    actions: {
      onInput() {
        const value = this.get('value');
        this.get('onChange')(value.trim());
      }

    }
  });

  _exports.default = _default;
});