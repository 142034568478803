define("adept-iq/pods/components/side-drawer/no-show-form/component", ["exports", "adept-iq/pods/components/side-drawer/no-show-form/config", "adept-iq/utils/unwrapProxy"], function (_exports, _config, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    editableSections: null,
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    ajax: Ember.inject.service(),
    noshowReason: null,
    noshowDetails: null,
    errors: null,
    trip: Ember.computed.alias('workspace.topOptions.trip'),
    reason: Ember.computed.readOnly('trip.reason'),
    title: Ember.computed.readOnly('workspace.topOptions.displayName'),
    sectionId: Ember.computed.readOnly('workspace.topOptions.sectionId'),
    isNoShowApprovalForm: Ember.computed.equal('sectionId', 'approve'),

    init() {
      this._super(...arguments);

      const store = this.get('store');
      const sectionId = this.get('sectionId');
      const isApprovalForm = this.get('isNoShowApprovalForm');

      const editableSections = _config.default.editableSections.filter(section => sectionId === section.id && section.id !== 'readOnly');

      if (isApprovalForm) {
        const noshowReasons = store.peekAll('no-show-reason-code');
        let reason = this.get('reason');
        if (!reason) reason = noshowReasons.firstObject;
        this.set('noshowReason', reason);
      }

      this.set('editableSections', editableSections);
    },

    onNoShowRequest() {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      const tripNoShowAdapter = store.adapterFor('trip-noshow');
      const trip = this.get('trip');
      const tooltip = this.get('tooltip');
      const isApprovalForm = this.get('isNoShowApprovalForm');
      const type = isApprovalForm ? 'noShowAccepted' : 'noShowDeclined';
      const notifications = this.get('notifications');
      const title = this.get('title');
      const noshowReason = (0, _unwrapProxy.unwrapProxy)(this.get('noshowReason'));
      const onCloseClick = this.onCloseClick;
      const message = isApprovalForm ? 'Approve' : 'Decline';
      const noshowDetails = this.get('noshowDetails');
      trip.set('noshowDetails', noshowDetails);
      tooltip.setProperties({
        tip: `${message} No Show Request`,
        title,
        hasOverlay: true,
        primaryActionText: 'Confirm',
        secondaryActionText: 'Cancel',

        primaryAction() {
          return routeExecEventAdapter.createTripNoShowRequestEvent(trip, type).then(() => {
            if (isApprovalForm) {
              return tripNoShowAdapter.createTripNoShow(trip, noshowReason);
            }

            return Promise.resolve();
          }).then(() =>
          /*response*/
          {
            notifications.success(`Successfully ${message}d No Show`);
            onCloseClick();
            tooltip.reset();
          }).catch(() => {
            notifications.warning(`Failed to ${message} No Show`);
            tooltip.reset();
          });
        },

        secondaryAction() {
          tooltip.reset();
        }

      });
    },

    actions: {
      onClearClick() {
        const editableSectionList = this.get('editableSections') || [];
        const editableSections = editableSectionList.filter(section => section.title !== 'Read Only');
        editableSections.forEach(section => {
          const fields = section.fields || [];
          fields.forEach(field => {
            if (field.editable) this.set(field.valuePath, '');
          });
        });
      },

      onValueChange(valuePath, options) {
        const value = options;
        this.set(valuePath, value);
      },

      onSaveClick() {
        return this.onNoShowRequest();
      }

    }
  });

  _exports.default = _default;
});