define("adept-iq/services/maximizer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    router: Ember.inject.service('router'),
    workspace: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    dashboard: Ember.computed.alias('workspace.dashboard'),
    maximizedWidget: null,
    checkedItems: null,
    isWidgetMinified: true,

    maximize(widget) {
      this.set('isWidgetMinified', false);
      this.set('maximizedWidget', widget);
      this.set('checkedItems', this.get('activeContext.checkedItems'));
      const workspace = this.get('workspace');
      const topState = workspace.get('topState');

      if (topState === 'filterColumnWidget') {
        setTimeout(() => {
          workspace.popState('filterColumnWidget');
        }, 100);
      }
    },

    minimize() {
      this.set('isWidgetMinified', true);
      this.set('maximizedWidget', null);
    }

  });

  _exports.default = _default;
});