define("adept-iq/pods/components/side-drawer/system-config/contract-management/component", ["exports", "ember-concurrency", "adept-iq/utils/regex-functions"], function (_exports, _emberConcurrency, _regexFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['contract-management'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    providers: null,
    selectedProvider: null,
    disableBtns: null,
    contractManagement: null,
    isNewContract: false,
    routeError: false,
    minRouteLenError: false,
    disableForm: true,
    selectedRow: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    disableSavebtn: Ember.computed('disableForm', 'contractManagement.{providerName,startRouteName,endRouteName,adjustMins,minRouteLength,pullOutMinCap,pullOutMaxCap,pullInMinCap,pullInMaxCap}', function () {
      const disableForm = this.get('disableForm');

      if (disableForm) {
        return true;
      }

      const hasValues = Object.values(this.get('contractManagement')).every(input => input !== '');
      return !hasValues;
    }),
    disableUndobtn: Ember.computed('disableForm', 'contractManagement.{providerName,startRouteName,endRouteName,adjustMins,minRouteLength,pullOutMinCap,pullOutMaxCap,pullInMinCap,pullInMaxCap}', function () {
      const disableForm = this.get('disableForm');

      if (disableForm) {
        return true;
      }

      const hasValues = Object.values(this.get('contractManagement')).some(input => input !== '');
      return !hasValues;
    }),
    selectedRowChange: Ember.observer('selectedRow', function () {
      const selectedRow = this.get('selectedRow');
      this.setDefaultProperties(selectedRow);
    }),

    init() {
      this._super(...arguments);

      const providers = [];
      this.get('store').findAll('provider').then(record => {
        record.forEach(val => {
          providers.push(val.get('id'));
        });
        this.set('providers', providers);
      });
      this.setDefaultProperties();
      this.defaultSettings();
    },

    setDefaultProperties(record = []) {
      const fields = {
        providerName: '',
        startRouteName: '',
        endRouteName: '',
        trimSlack: true,
        adjustMins: '',
        convertToHourMinutes: '',
        pullOutMinCap: '',
        pullOutMaxCap: '',
        pullInMinCap: '',
        pullInMaxCap: ''
      };

      if (!Ember.isEmpty(record)) {
        Object.entries(fields).forEach(key => {
          fields[key[0]] = record.get(key[0]);
        });
        this.set('disableForm', true);
      }

      this.resetErrors();
      this.set('contractManagement', Ember.Object.create(fields));
    },

    resetErrors() {
      this.setProperties({
        'routeError': false,
        'minRouteLenError': false
      });
    },

    defaultSettings() {
      const disableBtns = {
        new: false,
        edit: false,
        delete: false,
        save: true,
        undo: true
      };
      this.set('disableBtns', disableBtns);
    },

    convertToMinutes(hours) {
      const fieldArray = hours.split(':');
      return parseInt(fieldArray[1], 10) + 60 * parseInt(fieldArray[0], 10);
    },

    save: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      let convertToHourMinutes = this.get('contractManagement.convertToHourMinutes');

      if (!convertToHourMinutes.includes(':')) {
        convertToHourMinutes = `00:${convertToHourMinutes}`;
      }

      this.set('contractManagement.convertToHourMinutes', convertToHourMinutes);
      const mins = this.convertToMinutes(convertToHourMinutes);
      this.set('contractManagement.minRouteLength', mins);
      const attributes = this.get('contractManagement');
      const notifications = this.get('notifications');
      let record = null;

      if (!this.get('isNewContract')) {
        record = this.get('selectedRow');
        const fields = this.get('contractManagement');
        Object.entries(fields).forEach(([key, val]) => {
          record.set(key, val);
        });
      } else {
        record = store.createRecord('trim-slack-contract-management', attributes);
      }

      try {
        yield record.save();
        notifications.success('Record successfully saved');
        this.get('tableRef').refreshData(); //to refresh table data
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        notifications.warning('Record failed to save');
      }
    }).drop(),
    delete: (0, _emberConcurrency.task)(function* () {
      const record = this.get('selectedRow');
      const notifications = this.get('notifications');

      try {
        record.deleteRecord();
        yield record.save();
        notifications.success('Record successfully deleted.');
        this.get('tableRef').refreshData(); //to refresh table data
      } catch (error) {
        notifications.warning('Record failed to delete.');
      }
    }).drop(),

    validateRecords() {
      let valid = true;
      let errorText = '';
      const store = this.get('store');
      const record = this.get('contractManagement') || [];
      const convertToHourMinutes = this.get('contractManagement.convertToHourMinutes');
      const startRouteName = parseInt(record.startRouteName, 10);
      const endRouteName = parseInt(record.endRouteName, 10);

      const isHHMMformat = _regexFunctions.REGEX_HHMM.test(convertToHourMinutes);

      if (startRouteName > endRouteName) {
        valid = false;
        errorText = 'Start Route must be less than End Route.';
      } else {
        const data = store.peekAll('trim-slack-contract-management');
        data.forEach(contract => {
          if (startRouteName === contract.get('startRouteName') && endRouteName === contract.get('endRouteName')) {
            valid = false;
            errorText = 'Route Number range cannot overlap an existing Route Number range.';
            return false;
          }
        });
      }

      if (!isHHMMformat) {
        errorText += 'Min Route Length value must be entered in hh:mm format.';
        valid = false;
      }

      if (valid) {
        return valid;
      }

      const tooltip = this.get('tooltip');
      tooltip.setProperties({
        title: 'System Configuration',
        hasoverlay: true,
        tip: errorText,
        primaryActionText: 'ok',
        primaryAction: () => {
          if (startRouteName > endRouteName) {
            this.set('routeError', true);
          }

          if (!isHHMMformat) {
            this.set('minRouteLenError', true);
          }
        }
      });
    },

    actions: {
      onProviderChange(item) {
        this.set('contractManagement.providerName', item);
      },

      onRouteLengthChange(contractManagement, event) {
        const value = event.target.value;

        if (!_regexFunctions.REGEX_HHMM.test(value)) {
          contractManagement.notifyPropertyChange('convertToHourMinutes');
          return;
        }

        contractManagement.set('convertToHourMinutes', value);
      },

      newContract() {
        this.setDefaultProperties();
        this.set('isNewContract', true);
        this.set('disableForm', false);
        this.get('tableRef').onUncheckAll();
      },

      editContract() {
        const selectedRow = this.get('selectedRow');
        this.setDefaultProperties(selectedRow);
        this.set('isNewContract', false);
        this.set('disableForm', false);
      },

      deleteContract() {
        const tooltip = this.get('tooltip');
        tooltip.setProperties({
          title: 'System Configuration',
          hasoverlay: true,
          tip: 'Are you sure you want to Delete these changes?',
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return this.get('delete').perform();
          },
          secondaryActionText: 'Cancel'
        });
        this.resetErrors();
      },

      saveContract() {
        this.resetErrors();

        if (this.validateRecords()) {
          const tooltip = this.get('tooltip');
          tooltip.setProperties({
            title: 'System Configuration',
            hasoverlay: true,
            tip: 'Are you sure you want to save these changes?',
            primaryActionText: 'Confirm',
            primaryAction: () => {
              return this.get('save').perform();
            },
            secondaryActionText: 'Cancel'
          });
        }
      },

      undoContract() {
        this.resetErrors();

        if (this.get('isNewContract')) {
          this.setDefaultProperties();
        } else {
          const selectedRow = this.get('selectedRow');
          this.set('disableForm', true);
          this.setDefaultProperties(selectedRow);
        }
      }

    }
  });

  _exports.default = _default;
});