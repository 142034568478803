define("adept-iq/pods/components/iq-widgets/stops-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/stops-widget/config", "adept-iq/pods/components/iq-widgets/stops-widget/avlmConfig", "adept-iq/pods/components/iq-widgets/stops-widget/scheduleConfig", "adept-iq/config/environment", "adept-iq/utils/find-row-record", "adept-iq/config/api-urls", "moment", "adept-iq/utils/unwrapProxy", "adept-iq/config/mapped-permIds", "adept-iq/models/dispatch-route", "adept-iq/models/dashboard"], function (_exports, _component, _config, _avlmConfig, _scheduleConfig, _environment, _findRowRecord, _apiUrls, _moment, _unwrapProxy, _mappedPermIds, _dispatchRoute, _dashboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = ['clusters', 'vehicleRoutes', 'vehicleRoutes.driverShifts', 'vehicleRoutes.vehicle', 'vehicleRoutes.driverShifts.driver', 'vehicleRoutes.vehicle.vehicleType', 'driverBreaks', 'driverBreaks.driverBreakEvents', 'vehicleRoutes.vehicle.vehicleEvents', 'clusters.trips', 'clusters.trips.stops'];

  var _default = _component.default.extend({
    classNames: ['stops-widget'],
    classNameBindings: ['isReordering'],
    editModal: Ember.inject.service(),
    dragCoordinator: Ember.inject.service(),
    stopsReorder: Ember.inject.service(),
    workspace: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    roadSupervisor: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    routeexecevent: Ember.inject.service(),
    user: Ember.inject.service(),
    editComponent: 'iq-widgets/stops-form-widget',
    driverBreakEditComponent: 'iq-widgets/driver-breaks-form-widget',
    vehicleEventComponent: 'iq-widgets/vehicle-event-form-widget',
    noShowComponent: 'iq-widgets/noshow-event-stop-form-widget',
    routesEndComponent: 'iq-widgets/routes-end-form-widget',
    routesStartComponent: 'iq-widgets/routes-start-form-widget',
    cancelTripComponent: 'iq-widgets/cancel-type-form-widget',
    config: _config.default,
    defaultIncludes: INCLUDES,
    sourceRowId: null,
    dragTarget: null,
    isReordering: Ember.computed.alias('stopsReorder.reorderInProgress'),
    onReorderChange: Ember.observer('stopsReorder.reorderInProgress', function () {
      Ember.run.scheduleOnce('afterRender', this, 'refreshData');
    }),
    dynamicSingleActions: Ember.computed('table.rows.@each.{selected,status}', function () {
      const role = this.get('workspace.dashboardModel.role');

      if (_environment.default.APP.avlmLite) {
        return this._dynamicDispatchActions();
      }

      if (!_environment.default.APP.avlmLite) {
        switch (role) {
          case _dashboard.SCHEDULE_ROLENAME:
            return this._dynamicScheduleActions();

          case _dashboard.DISPATCH_ROLENAME:
          default:
            return this._dynamicDispatchActions();
        }
      }
    }),
    dragRowGroup: Ember.computed('row', function () {
      return this.get('table.rows');
    }).readOnly(),
    dragDirection: Ember.computed('dragTarget', 'sourceRowId', function () {
      const targetRow = this.get('dragTarget');

      if (targetRow) {
        const rows = this.get('dragRowGroup');
        const targetIdx = rows.indexOf((0, _findRowRecord.findRowRecordByElement)(targetRow, rows));
        const sourceIdx = rows.indexOf(rows.findBy('rowId', this.get('sourceRowId')));
        return sourceIdx - targetIdx;
      }

      return null;
    }).readOnly(),
    dragOverClass: Ember.computed('dragDirection', function () {
      const direction = this.get('dragDirection') < 0 ? 'below' : 'above';
      return ` drag-target-${direction} drag-target`;
    }),

    init() {
      const role = this.get('workspace.dashboardModel.role');

      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      if (!_environment.default.APP.avlmLite) {
        switch (role) {
          case _dashboard.SCHEDULE_ROLENAME:
            this.set('config', _scheduleConfig.default);
            break;

          case _dashboard.DISPATCH_ROLENAME:
          default:
            this.set('config', _config.default);
            break;
        }
      }

      this._super(...arguments);
    },

    fetchDataQuery() {
      return this._super(...arguments).then(records => {
        // if it is a trip active context
        // only include the pick and drop stops
        if (this.get('activeContext.topActiveContext').tripActiveContext()) {
          return records.filter(record => {
            return ['pick', 'drop'].includes(record.get('stopType'));
          });
        }

        return records;
      });
    },

    cursorWait() {
      Ember.$('html,body').css('cursor', 'wait');
    },

    cursorDefault() {
      Ember.$('html,body').css('cursor', 'default');
    },

    saveCancelRecordFunction(records) {
      /*eslint-disable */
      const me = this;
      /*eslint-enable */

      return new Ember.RSVP.Promise((resolve, reject) => {
        if (!Ember.isEmpty(records)) {
          records.forEach(record => {
            const cancelData = this.get('routeexecevent').cancelData();
            const prepareCancelData = this.get('routeexecevent').prepareTripCancelData(cancelData, record);
            const json = JSON.stringify(prepareCancelData);
            const session = this.get('session');
            return this.get('ajax').post(_apiUrls.API.bookingService.host + '/booking/' + record.get('id') + '/cancellation', {
              method: 'POST',
              contentType: 'application/json',
              headers: {
                'Authorization': `Bearer ${session.data.authenticated.token}`
              },
              data: json
            }).then(() =>
            /*response*/
            {
              record.set('isForceDirty', false); // record.set('id',response.data.id);

              me.get('activeContext.topActiveContext').get('refreshTableContent').perform('route');
              me.sendMessagesToVehicles(record, 'No Show');
            }).catch(function (error) {
              reject(error);
            });
          });
        }
      });
    },

    saveRouteStartRecordFunction(records) {
      return Ember.RSVP.all(records.map(record => {
        if (Ember.isNone(record.get('dispatchRoute.assignedDriver.id'))) {
          return Ember.RSVP.Promise.reject(new Error('You cannot start a route that does not have a driver.'));
        }

        if (Ember.isNone(record.get('dispatchRoute.assignedVehicle.id'))) {
          return Ember.RSVP.Promise.reject(new Error('You cannot start a route that does not have a vehicle.'));
        }

        record.set('actualStartTimestamp', record.get('timestamp'));
        const routeStartData = this.get('routeexecevent').routeStartData();
        const preparedRouteStartData = this.get('routeexecevent').prepareRouteStartData(routeStartData, record);
        const json = JSON.stringify(preparedRouteStartData);
        const session = this.get('session');
        return this.get('ajax').post(_apiUrls.API.avlmService.host + '/route-exec-event', {
          method: 'POST',
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          },
          data: json
        }).then(() =>
        /*response*/
        {
          record.set('isForceDirty', false);
          this.get('activeContext.topActiveContext').get('refreshTableContent').perform('route');
          this.get('notifications').success('Start route successful');
        }).catch(() => {
          this.get('notifications').warning('Start route failed');
        });
      }));
    },

    saveRouteEndRecordFunction(records) {
      return Ember.RSVP.all(records.map(record => {
        if (Ember.isNone(record.get('dispatchRoute.assignedDriver.id'))) {
          return Ember.RSVP.Promise.reject(new Error('You cannot end a route that does not have a driver.'));
        }

        if (Ember.isNone(record.get('dispatchRoute.assignedVehicle.id'))) {
          return Ember.RSVP.Promise.reject(new Error('You cannot end a route that does not have a vehicle.'));
        }

        record.set('actualEndTimestamp', record.get('timestamp'));
        const routeEndData = this.get('routeexecevent').routeEndData();
        const preparedRouteEndData = this.get('routeexecevent').prepareRouteEndData(routeEndData, record);
        const json = JSON.stringify(preparedRouteEndData);
        const session = this.get('session');
        return this.get('ajax').post(_apiUrls.API.avlmService.host + '/route-exec-event', {
          method: 'POST',
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          },
          data: json
        }).then(() =>
        /*response*/
        {
          record.set('isForceDirty', false);
          this.get('activeContext').get('refreshTableContent').perform('route');
          this.get('notifications').success('Route completed successful');
          return Ember.RSVP.Promise.resolve();
        }).catch(e => {
          this.get('notifications').warning('Failed to complete route');
          return Ember.RSVP.Promise.reject(e);
        });
      }));
    },

    saveDriverBreakFunction(records) {
      const store = this.get('store');
      const locationAdapter = store.adapterFor('location');
      const addressAdapter = store.adapterFor('address');
      const placeAdapter = store.adapterFor('place');
      const routeBreakAdapter = store.adapterFor('route-break');
      const dispatchRouteAdapter = store.adapterFor('dispatch-route');
      this.set('editModal.errors', []);
      return records.reduce(async (acu, record) => {
        const requestTime = record.get('requestTime');
        const projectedEndDate = record.get('projectedEndDate');
        const place = (0, _unwrapProxy.unwrapProxy)(record.get('place'));
        const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
        const location = (0, _unwrapProxy.unwrapProxy)(place.get('location'));
        const locationExist = !Ember.isEmpty(location.get('lat'));
        let placeResponse;
        place.set('type', 'driverBreak');
        place.set('schedule', (0, _unwrapProxy.unwrapProxy)(record.get('schedule')));
        record.set('estimatedStart', requestTime);
        record.set('estimatedEnd', projectedEndDate); // temporary assignment of zone type until we figure out how to deal with
        // zone types

        address.set('zoneName', store.peekAll('zone-type').get('firstObject')); // creating new driver break

        if (record.get('isNew')) {
          try {
            if (locationExist) {
              record.set('place', place);
              const locationResponse = await locationAdapter.postLocation(location);
              const addressResponse = await addressAdapter.postAddress(address);
              await addressAdapter.postAddressLocation(locationResponse, addressResponse);
              placeResponse = await placeAdapter.postPlace(place, addressResponse.data.id, locationResponse.data.id, 'passenger');
            }

            const placeId = Ember.isNone(placeResponse) ? '' : placeResponse.data.id;
            const routeBreak = await routeBreakAdapter.postRouteBreak(record, placeId);
            return acu.then(() => dispatchRouteAdapter.createDriverBreak((0, _unwrapProxy.unwrapProxy)(record.get('route.dispatchRoute')), routeBreak.data.attributes.plannedRouteOrdinal, routeBreak.data.id)).then(() => {
              this.get('notifications').success(`Successfully added driver break B${routeBreak.data.id} to route ${record.get('route.name')}`);
              this.get('activeContext.topActiveContext').get('refreshTableContent').perform([this.get('modelName'), 'stop-point']);
              return Ember.RSVP.Promise.resolve();
            });
          } catch (e) {
            this.get('notifications').warning(`Failed to add driver break to route ${record.get('route.name')}`);
            return Ember.RSVP.Promise.reject();
          }
        } // patching driver break


        try {
          let placeId;

          if (locationExist) {
            if (!Ember.isEmpty(place.id)) {
              await place.save();
              await location.save();
              await address.save();
              placeId = place.get('id');
            } else {
              const locationResponse = await locationAdapter.postLocation(location);
              const addressResponse = await addressAdapter.postAddress(address);
              await addressAdapter.postAddressLocation(locationResponse, addressResponse);
              placeResponse = await placeAdapter.postPlace(place, addressResponse.data.id, locationResponse.data.id, 'passenger');
              placeId = Ember.isNone(placeResponse) ? '' : placeResponse.data.id;
            }
          }

          return routeBreakAdapter.patchRouteBreak(record, placeId).then(() => {
            this.get('notifications').success(`Successfully added driver break B${record.get('id')} to route ${record.get('route.name')}`);
            return Ember.RSVP.Promise.resolve();
          });
        } catch (e) {
          this.get('notifications').warning(`Failed to add driver break to route ${record.get('route.name')}`);
          return Ember.RSVP.Promise.reject();
        }
      }, Ember.RSVP.Promise.resolve());
    },

    saveNoShowRecordFunction(records) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      const tripNoShowAdapter = store.adapterFor('trip-noshow');
      const notifications = this.get('notifications');
      return records.reduce((acu, record) => {
        const dsStop = (0, _unwrapProxy.unwrapProxy)(record);
        const noshow = (0, _unwrapProxy.unwrapProxy)(dsStop.get('noShow'));
        const noshowReason = (0, _unwrapProxy.unwrapProxy)(noshow.get('noShowReasonCode'));
        const trip = (0, _unwrapProxy.unwrapProxy)(dsStop.get('trip'));
        trip.set('noshowDetails', noshow.get('notes'));
        return acu.then(() => routeExecEventAdapter.createStopNoShowEvent(record)).then(() => tripNoShowAdapter.createTripNoShow(trip, noshowReason)).then(() => {
          notifications.success(`Successfully No Showed Trip ${dsStop.get('trip.tripId')}`);
        }).catch(e => {
          notifications.warning(`Failed to No Show Trip ${dsStop.get('trip.tripId')}`);
          throw new Error(e);
        });
      }, Ember.RSVP.Promise.resolve());
    },

    reorderTripInRoute(routeId, tripId, pickOrdinal, dropOrdinal, sourceStop, targetStop) {
      /*eslint-disable */
      const me = this;
      /*eslint-enable */

      return new Ember.RSVP.Promise((resolve, reject) => {
        const session = this.get('session');
        const reorderUrl = `/dispatch-route/${routeId}/tripTransfer/${tripId}?pickOrdinal=${pickOrdinal}&dropOrdinal=${dropOrdinal}`;
        const targetStopType = targetStop.get('stopType');
        const sourceStopType = sourceStop.get('stopType'); // change pointer to working so user knows

        me.cursorWait();
        return this.get('ajax').post(_apiUrls.API.dispatchService.host + reorderUrl, {
          method: 'PUT',
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          }
        }).then(() =>
        /*response*/
        {
          // switch pointer back to default
          me.cursorDefault();
          me.get('stopsReorder').stopReorder();

          if (targetStopType === 'pullinGarage') {
            me.get('notifications').success(`Successfully moved Trip ${sourceStop.get('trip.tripId')} ${sourceStopType} stop to the bottom`);
          } else if (targetStopType === 'pulloutGarage') {
            me.get('notifications').success(`Successfully moved Trip ${sourceStop.get('trip.tripId')} ${sourceStopType} stop to the top`);
          } else {
            me.get('notifications').success(`Successfully swapped Trip ${sourceStop.get('trip.tripId')} ${sourceStopType} stop with Trip ${targetStop.get('trip.tripId')} ${targetStopType} stop`);
          }
        }).catch(function (error) {
          let message = '';

          if (!Ember.isNone(error) && !Ember.isNone(error.payload) && !Ember.isNone(error.payload.message)) {
            message = error.payload.message;
          } // switch pointer back to default


          me.cursorDefault();
          me.get('stopsReorder').stopReorder();
          me.get('notifications').warning(`Failed to Reorder Trip. ${message}`);
          reject(error);
        });
      });
    },

    reorderBreakInRoute(routeId, driverBreak, breakOrdinal) {
      // eslint-disable-next-line consistent-this
      const me = this;
      const session = this.get('session');
      const reorderUrl = `/dispatch-route/${routeId}/break/${driverBreak.get('routeBreak.id')}?ordinal=${breakOrdinal}`; // change pointer to working so user knows

      me.cursorWait();
      return this.get('ajax').post(_apiUrls.API.dispatchService.host + reorderUrl, {
        method: 'PUT',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      }).then(() =>
      /*response*/
      {
        // switch pointer back to default
        me.cursorDefault();
        me.get('stopsReorder').stopReorder();
        me.get('notifications').success(`Successfully reordered Driver Break ${driverBreak.get('id')}`);
      }).catch(function (error) {
        let message = '';

        if (!Ember.isNone(error) && !Ember.isNone(error.payload) && !Ember.isNone(error.payload.message)) {
          message = error.payload.message;
        } // switch pointer back to default


        me.cursorDefault();
        me.get('stopsReorder').stopReorder();
        me.get('notifications').warning(`Failed to Reorder Driver Break. ${message}`);
      });
    },

    validateReorder(sourceType, sourceRowRecord, adjustedTargetRowRecord, pickOrdinal, dropOrdinal) {
      if (!Ember.isNone(sourceRowRecord.get('status'))) {
        this.get('notifications').warning('Cannot move stop because trip have already been started.');
        return false;
      }

      if (sourceType === 'break' && !Ember.isNone(sourceRowRecord.get('status'))) {
        this.get('notifications').warning('Cannot move break because it have already been started.');
        return false;
      }

      if (!Ember.isNone(adjustedTargetRowRecord.get('status'))) {
        this.get('notifications').warning('Cannot move stop before a trip that have already been started.');
        return false;
      }

      if (adjustedTargetRowRecord.get('stopType') === 'break' && !Ember.isNone(adjustedTargetRowRecord.get('status'))) {
        this.get('notifications').warning('Cannot move stop before a break that have already been started.');
        return false;
      }

      if (sourceType !== 'break' && dropOrdinal <= pickOrdinal) {
        this.get('notifications').warning('Drop-off cannot be before the Pickup');
        return false;
      }

      return true;
    },

    doReorder(sourceRowRecord, targetRowRecord) {
      const tooltip = this.get('tooltip'); // the next index on the ordered list

      const orderedStopsWithBreaks = targetRowRecord.get('dispatchRoute.orderedStopsWithBreaks');
      let adjustedTargetRowRecord = null;
      const sourceType = sourceRowRecord.get('stopType');
      const title = 'Reordering Stops';
      let routeId = null;
      let tripId = null;
      let pickOrdinal = null;
      let dropOrdinal = null;
      let breakOrdinal = null;

      if (sourceType === 'pulloutGarage' || sourceType === 'pullinGarage') {
        return false;
      } // collect data for validation


      if (targetRowRecord.get('stopType') === 'pulloutGarage') {
        // pullout should always be on top
        adjustedTargetRowRecord = orderedStopsWithBreaks[0];
      } else if (targetRowRecord.get('stopType') === 'pullinGarage') {
        // pullout should always be on top
        adjustedTargetRowRecord = orderedStopsWithBreaks[orderedStopsWithBreaks.length - 1];
      } else {
        adjustedTargetRowRecord = targetRowRecord;
      }

      if (sourceType === 'drop') {
        routeId = sourceRowRecord.get('dispatchRoute.id');
        tripId = sourceRowRecord.get('trip.id');
        pickOrdinal = sourceRowRecord.get('trip.pick.stopPoint.routeOrdinal');

        if (targetRowRecord.get('stopType') === 'pullinGarage') {
          dropOrdinal = adjustedTargetRowRecord.get('routeOrdinal') + 1;
        } else {
          dropOrdinal = adjustedTargetRowRecord.get('routeOrdinal');
        }
      } else if (sourceType === 'pick') {
        routeId = sourceRowRecord.get('dispatchRoute.id');
        tripId = sourceRowRecord.get('trip.id');
        pickOrdinal = adjustedTargetRowRecord.get('routeOrdinal');
        dropOrdinal = sourceRowRecord.get('trip.drop.stopPoint.routeOrdinal');
      } else if (sourceType === 'break') {
        routeId = sourceRowRecord.get('dispatchRoute.id');

        if (targetRowRecord.get('stopType') === 'pulloutGarage') {
          breakOrdinal = 1;
        } else {
          breakOrdinal = parseInt(targetRowRecord.get('routeOrdinal'), 10);
        }
      } else {
        this.get('notifications').warning('Invalid Source');
        return false;
      } // confirm


      this.get('stopsReorder').toggleHiddenFlag();
      tooltip.setProperties({
        tip: `Move ${sourceRowRecord.get('stopType')} stop of Trip ${sourceRowRecord.get('trip.tripId')} to position ${adjustedTargetRowRecord.get('routeOrdinal')}`,
        title,
        hasOverlay: true,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          if (!this.validateReorder(sourceType, sourceRowRecord, adjustedTargetRowRecord, pickOrdinal, dropOrdinal)) {
            this.get('stopsReorder').stopReorder();
            this.get('stopsReorder').toggleHiddenFlag();
            return false;
          }

          if (sourceType === 'break') {
            this.reorderBreakInRoute(routeId, sourceRowRecord, breakOrdinal);
          } else {
            this.reorderTripInRoute(routeId, tripId, pickOrdinal, dropOrdinal, (0, _unwrapProxy.unwrapProxy)(sourceRowRecord), (0, _unwrapProxy.unwrapProxy)(targetRowRecord));
          }

          this.get('stopsReorder').toggleHiddenFlag();
          return true;
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          this.get('stopsReorder').toggleHiddenFlag();
          tooltip.reset();
        }
      });
    },

    // generic function to append the stop type to an action label
    appendStopTypeToActionLabel(action, stopPoint) {
      switch (action.id) {
        case 'unperform':
          {
            this.set('unPerformAction.name', 'Unperform');

            if (stopPoint.get('isNoShow')) {
              this.set('unPerformAction.name', 'Unperform No Show');
            }

            break;
          }

        default:
      }
    },

    actions: {
      onColumnClick()
      /*column, event*/
      {
        if (this.get('stopsReorder').reorderInProgress) return;

        this._super(...arguments);
      },

      onSearchButtonClick() {
        if (this.get('stopsReorder').reorderInProgress) return;

        this._super(...arguments);
      },

      onFilterButtonClick() {
        if (this.get('stopsReorder').reorderInProgress) return;

        this._super(...arguments);
      },

      onRowRightClick()
      /*row, event*/
      {
        if (this.get('stopsReorder').reorderInProgress) return;

        this._super(...arguments);
      }

    },

    didInsertElement() {
      this._super(...arguments);

      const role = this.get('workspace.dashboardModel.role');

      if (_environment.default.APP.avlmLite) {
        this._initDispatchSingleActions();
      }

      if (!_environment.default.APP.avlmLite) {
        switch (role) {
          case _dashboard.SCHEDULE_ROLENAME:
            this._initScheduleSingleActions();

            break;

          case _dashboard.DISPATCH_ROLENAME:
          default:
            this._initDispatchSingleActions();

            break;
        }
      }
    },

    dragStart(event) {
      const row = (0, _findRowRecord.findRowRecordByElement)(event.target, this.get('dragRowGroup'));
      if (Ember.isBlank(row)) return;
      this.set('sourceRowId', row.get('rowId'));
      const dragId = Ember.guidFor(row);
      this.get('dragCoordinator').setSourceRow(dragId, row, 'stops');
      event.dataTransfer.setData('text', dragId);
    },

    dragOver(event) {
      event.preventDefault();
      if (this.get('dragCoordinator.widgetType') !== 'stops') return;
      const rowElement = (0, _findRowRecord.findRowElement)(event.target); // User might drag over other widget elements.

      if (Ember.isBlank(rowElement)) {
        return;
      }

      this.set('dragTarget', rowElement);
      this.$(`#${rowElement.id}`).addClass(this.get('dragOverClass'));
    },

    dragLeave(event) {
      event.preventDefault();
      const rowElement = (0, _findRowRecord.findRowElement)(event.target); // User might drag over other widget elements.

      if (Ember.isBlank(rowElement)) {
        return;
      }

      this.$(`#${rowElement.id}`).removeClass(['drag-target-above', 'drag-target-below', 'drag-target']);
    },

    drop(event) {
      if (this.get('dragCoordinator.widgetType') !== 'stops') return;
      const rowElement = (0, _findRowRecord.findRowElement)(event.target); // User might drop onto other widget elements.

      if (Ember.isBlank(rowElement)) {
        return;
      }

      this.$(`#${rowElement.id}`).removeClass(['drag-target-above', 'drag-target-below', 'drag-target']);
      const table = this.get('table');
      const rows = this.get('dragRowGroup'); // let sourceRowRecord = rows.findBy('rowId', this.get('sourceRowId'));
      // // User might drop elements that are not Stops widget rows.
      // if (isBlank(sourceRowRecord)) {
      //   return;
      // }

      const sourceRowRecord = this.get('dragCoordinator').getSourceRow(event.dataTransfer.getData('text'));
      if (Ember.isBlank(sourceRowRecord)) return;
      const targetRowRecord = (0, _findRowRecord.findRowRecordByElement)(event.target, rows);

      const _rows = rows.toArray();

      const targetRowIdx = _rows.indexOf(targetRowRecord) + (this.get('dragDirection') < 0 ? 0 : 0);
      event.dataTransfer.dropEffect = 'move';
      event.preventDefault();
      event.stopPropagation();
      table.notifyPropertyChange('rows');

      _rows.removeObject(sourceRowRecord);

      _rows.insertAt(targetRowIdx, sourceRowRecord);

      const result = this.doReorder(sourceRowRecord, targetRowRecord);

      if (result) {
        rows.setObjects(_rows);
      }

      table.notifyPropertyChange('rows');
    },

    dragEnd() {
      this.set('sourceRowId', null);
      this.set('dragTarget', null);
      this.get('dragCoordinator').clearSourceRows();
    },

    _initDispatchSingleActions() {
      this.set('noShowAction', {
        id: 'noShow',
        permId: _mappedPermIds.default.noShowTrip,
        name: 'No Show',
        action: model => {
          const store = this.get('store');
          const noShowReasonCodes = this.get('store').peekAll('no-show-reason-code');
          model.set('noShow', store.createRecord('no-show', {
            noShowReasonCode: noShowReasonCodes.firstObject
          }));
          model.set('timestamp', new Date());
          const noShowComponent = this.get('noShowComponent');
          this.get('editModal').open(noShowComponent, [model], this.get('saveNoShowRecordFunction').bind(this), null, {
            title: 'No Show Stop'
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('removeAction', {
        id: 'remove',
        name: 'Remove',
        action: () =>
        /* model */
        {},
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('editAction', {
        id: 'edit',
        name: 'Edit',
        action: () =>
        /* model */
        {},
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('cancelAction', {
        id: 'cancelTrip',
        permIds: [_mappedPermIds.default.cancelFuture, _mappedPermIds.default.cancelSameDay],
        name: 'Cancel Trip',
        action: model => {
          const cancelTripComponent = this.get('cancelTripComponent');
          const trip = (0, _unwrapProxy.unwrapProxy)(model.get('trip'));
          const booking = (0, _unwrapProxy.unwrapProxy)(trip.get('booking'));
          this.get('editModal').open(cancelTripComponent, [booking.get('legs.firstObject.segments.firstObject')], this.get('saveCancelRecordFunction').bind(this));
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('addDriverBreakAction', {
        id: 'addDriverBreak',
        permId: _mappedPermIds.default.addBreak,
        name: 'Add Driver Break',
        action: model => {
          const store = this.get('store');
          const route = (0, _unwrapProxy.unwrapProxy)(model.get('dispatchRoute.route'));
          const lastStop = route.get('dispatchRoute.orderedStopsWithBreaks').lastObject;
          const trips = (0, _unwrapProxy.unwrapProxy)(model.get('trip'));
          const driverBreak = store.createRecord('route-break', {
            estimatedStart: route.get('plannedStartTime'),
            requestTime: route.get('plannedStartTime'),
            route: route,
            schedule: route.get('schedule'),
            notes: '',
            plannedRouteOrdinal: lastStop.get('routeOrdinal') + 1,
            plannedDuration: 15,
            state: 'planned',
            status: 'scheduled',
            promisedStart: route.get('plannedStartTime')
          });

          if (Ember.isNone(driverBreak.get('place.id'))) {
            const locationModel = store.createRecord('location');
            const addressModel = store.createRecord('address', {
              locations: [locationModel]
            });
            const placeModel = store.createRecord('place', {
              address: addressModel,
              location: locationModel
            });
            driverBreak.set('place', placeModel);
          }

          if (Ember.isNone(driverBreak.get('stopPoint.id'))) {
            const stopPoint = store.createRecord('stop-point');
            driverBreak.set('stopPoint', stopPoint);
          }

          const saveDriverBreakFunction = this.get('saveDriverBreakFunction').bind(this);
          this.get('workspace').pushState('addBreak', {
            driverBreak,
            trips,
            saveDriverBreakFunction
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('delDriverBreakAction', {
        id: 'deleteDriverBreak',
        permId: _mappedPermIds.default.deleteBreak,
        name: 'Remove Driver Break',
        action: model => {
          const dispatchRouteAdapter = this.get('store').adapterFor('dispatch-route');
          const routeBreak = model.get('routeBreak');
          const routeBreakId = routeBreak.get('id');
          const dispatchRouteId = routeBreak.get('route.dispatchRoute.id');
          dispatchRouteAdapter.removeDriverBreak(dispatchRouteId, routeBreakId).then(() => {
            this.get('notifications').success(`Successfully removed driver break B${routeBreakId} from route ${routeBreak.get('route.name')}`);
          }).catch(() => {
            this.get('notifications').warning(`Failed to remove driver break B${routeBreakId}`);
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('editDriverBreakAction', {
        id: 'editDriverBreak',
        permId: _mappedPermIds.default.editBreak,
        name: 'Edit',
        action: model => {
          const store = this.get('store');
          const driverBreak = (0, _unwrapProxy.unwrapProxy)(model.get('routeBreak'));
          const estimatedStart = (0, _moment.default)(Ember.get(driverBreak, 'estimatedStart'));
          const estimatedEnd = (0, _moment.default)(Ember.get(driverBreak, 'estimatedEnd'));
          driverBreak.set('plannedDuration', estimatedEnd.diff(estimatedStart, 'minutes'));

          if (Ember.isNone(driverBreak.get('place.id'))) {
            const locationModel = store.createRecord('location');
            const addressModel = store.createRecord('address', {
              locations: [locationModel]
            });
            const placeModel = store.createRecord('place', {
              address: addressModel,
              location: locationModel
            });
            driverBreak.set('place', placeModel);
          }

          this.get('records').pushObject(driverBreak);
          const editComponent = this.get('driverBreakEditComponent');
          this.get('editModal').open(editComponent, [driverBreak], this.get('saveDriverBreakFunction').bind(this));
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('reorderStopsAction', {
        id: 'reorderStops',
        permId: _mappedPermIds.default.reorderStops,
        name: 'Reorder Stops',
        action: () =>
        /*model*/
        {
          const widget = this.get('widget');
          const sortByStr = 'stopBreakOrd'; // route ordinal

          this.get('stopsReorder').startReorder(widget, sortByStr);
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('navigateToAction', {
        id: 'navigateTo',
        permId: _mappedPermIds.default.navigateStop,
        name: 'Navigate To',
        action: model => {
          const tooltip = this.get('tooltip');
          tooltip.setProperties({
            tip: 'Navigate To ' + model.place.fullAddress,
            primaryActionText: 'Yes',
            secondaryActionText: 'No',
            secondaryAction: () => {
              tooltip.reset();
              this.roadSupervisor.exit();
            },
            primaryAction: () => {
              tooltip.reset();
              this.set('loading', true);
              this.roadSupervisor.enter().finally(() => this.set('loading', false));
              this.get('roadSupervisor').navigateToStop(model);
            }
          });
        }
      });
      this.set('performAction', {
        id: 'perform',
        permIds: [_mappedPermIds.default.markStopArrived, _mappedPermIds.default.markStopDeparted],
        name: 'Perform',
        action: model => {
          const dispatchRoute = model.get('dispatchRoute');
          const unperformedIQStops = dispatchRoute.findUnperformedStopsInSequence(model);

          if (unperformedIQStops.length > 1) {
            this.get('workspace').pushState('performAllStop', {
              stopPoints: unperformedIQStops
            }, {
              replaceSelf: true
            });
            return;
          }

          switch (model.get('stopType')) {
            case 'pick':
              {
                this.get('workspace').pushState('performPickStop', {
                  stopPoint: model
                }, {
                  replaceSelf: true
                });
                break;
              }

            case 'drop':
              {
                this.get('workspace').pushState('performDropStop', {
                  stopPoint: model
                }, {
                  replaceSelf: true
                });
                break;
              }

            case 'pulloutGarage':
              {
                const route = model.get('dispatchRoute.route');
                const routesStartComponent = this.get('routesStartComponent');
                route.set('odometer', '');
                route.set('timestamp', new Date());
                this.get('editModal').open(routesStartComponent, [route], this.get('saveRouteStartRecordFunction').bind(this));
                break;
              }

            case 'pullinGarage':
              {
                const route = model.get('dispatchRoute.route');
                const routesEndComponent = this.get('routesEndComponent');
                route.set('odometer', '');
                route.set('timestamp', new Date());
                this.get('editModal').open(routesEndComponent, [route], this.get('saveRouteEndRecordFunction').bind(this));
                break;
              }

            case 'break':
              {
                this.get('workspace').pushState('performDriverBreak', {
                  stopPoint: model
                }, {
                  replaceSelf: true
                });
                break;
              }

            default:
              break;
          }
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('unPerformAction', {
        id: 'unperform',
        permId: _mappedPermIds.default.unperformStop,
        name: 'Unperform',
        action: model => {
          const workspace = this.get('workspace');
          let stopType = model.get('stopType');
          let title = `Unperform ${Ember.String.capitalize(stopType)} Stop`;

          if (model.get('isNoShow')) {
            const trip = (0, _unwrapProxy.unwrapProxy)(model.get('trip'));
            stopType = trip.get('pickStopPoint.stopType'); // eslint-disable-next-line no-param-reassign

            model = (0, _unwrapProxy.unwrapProxy)(trip.get('pickStopPoint'));
            title = 'Unperform No Show';
            workspace.pushState('unPerformNoShow', {
              stopPoint: model,
              title
            }, {
              replaceSelf: true
            });
            return;
          }

          if (stopType === 'break') {
            title = 'Unperform Break';
          }

          switch (stopType) {
            case 'pick':
              {
                workspace.pushState('unPerformPickStop', {
                  stopPoint: model,
                  title
                }, {
                  replaceSelf: true
                });
                break;
              }

            case 'drop':
              {
                workspace.pushState('unPerformDropStop', {
                  stopPoint: model,
                  title
                }, {
                  replaceSelf: true
                });
                break;
              }

            case 'break':
              {
                workspace.pushState('unPerformBreak', {
                  stopPoint: model,
                  title
                }, {
                  replaceSelf: true
                });
                break;
              }

            default:
              break;
          }
        },
        disableOnLockedRoute: true
      });
      this.set('bulkActions', [{
        id: 'bulkEdit',
        permId: _mappedPermIds.default.editStop,
        name: 'Bulk Edit',
        action: models => {
          const editComponent = this.get('editComponent');
          this.get('editModal').open(editComponent, models);
        }
      }]);
    },

    _initScheduleSingleActions() {
      this.set('cancelAction', {
        id: 'cancelTrip',
        permIds: [_mappedPermIds.default.cancelFuture, _mappedPermIds.default.cancelSameDay],
        name: 'Cancel Trip',
        action: model => {
          const cancelTripComponent = this.get('cancelTripComponent');
          const trip = (0, _unwrapProxy.unwrapProxy)(model.get('trip'));
          const booking = (0, _unwrapProxy.unwrapProxy)(trip.get('booking'));
          this.get('editModal').open(cancelTripComponent, [booking.get('legs.firstObject.segments.firstObject')], this.get('saveCancelRecordFunction').bind(this));
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
    },

    _dynamicDispatchActions() {
      const noshowAction = this.get('noShowAction');
      const breakEditAction = this.get('editDriverBreakAction');
      const breakAddAction = this.get('addDriverBreakAction');
      const cancelAction = this.get('cancelAction');
      const breakDelAction = this.get('delDriverBreakAction');
      const reorderAction = this.get('reorderStopsAction');
      const navigateToAction = this.get('navigateToAction');
      const performAction = this.get('performAction');
      const unperformAction = this.get('unPerformAction');
      const rows = this.get('table.rows') || [];
      const row = rows.findBy('selected', true);
      const record = row ? row.get('record') : null;
      const stopType = row ? row.get('stopType') : null;
      const isLastPerformedStop = row.get('dispatchRoute.lastPerformedStop');
      if (!row) return [];

      if (this.get('stopsReorder.reorderInProgress')) {
        return [];
      }

      this.appendStopTypeToActionLabel(unperformAction, record); // there's an off chance the avlm-route-exec event won't update the route status to started / in-progress to keep no-show available

      let routeStarted = [_dispatchRoute.ROUTE_IN_PROGRESS_STATUS, _dispatchRoute.ROUTE_STARTED_STATUS, _dispatchRoute.ROUTE_AWAITING_STATUS].includes(row.get('dispatchRoute.computedStatus'));
      let route = record.get('dispatchRoute') || record.get('avlmCluster.route');
      let routeChecked = false;

      if (route) {
        const model = route;
        routeChecked = this.get('activeContext.topActiveContext').isRecordChecked(model);
      }

      const multiRecordsChecked = this.get('activeContext.topActiveContext').isMultiRecordsChecked(route);
      const isTimelineSpanMultipleDays = this.get('workspace.isTimelineSpanMultipleDays');
      let reorderEligible = false;

      if (routeChecked && !multiRecordsChecked && !isTimelineSpanMultipleDays) {
        reorderEligible = true;
      }

      const isRoleSupervisor = this.user.isRoadSupEnable();

      switch (stopType) {
        case 'pick':
          {
            switch (row.get('status')) {
              case 'Arrive':
                {
                  if (isRoleSupervisor) {
                    return [breakAddAction, noshowAction, performAction, navigateToAction];
                  }

                  return [breakAddAction, noshowAction, performAction];
                }

              case 'Depart':
                {
                  if (isRoleSupervisor) {
                    if (isLastPerformedStop.includes(row.id)) {
                      return [breakAddAction, navigateToAction];
                    }

                    return [breakAddAction, navigateToAction, unperformAction];
                  }

                  if (isLastPerformedStop.includes(row.id)) {
                    return [breakAddAction];
                  }

                  return [breakAddAction, unperformAction];
                }

              case 'Scheduled':
                {
                  if (row.get('actualArrivalTime') === null || Ember.isNone(row.get('actualArrivalTime'))) {
                    return [breakAddAction];
                  }

                  return [breakAddAction];
                }

              case 'Cancelled':
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  return [];
                }

              case 'No Show':
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  return [unperformAction];
                }

              case 'pending':
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  return [];
                }

              default:
                {
                  const nextUnperformedStop = row.get('dispatchRoute.firstUnperformedStop');

                  if (routeStarted) {
                    if (reorderEligible) {
                      if (nextUnperformedStop.get('id') === row.id) {
                        return [breakAddAction, reorderAction, performAction];
                      }

                      return [breakAddAction, reorderAction, performAction];
                    }

                    return [performAction];
                  }

                  if (!Ember.isNone(route)) {
                    if (reorderEligible) {
                      return [breakAddAction, reorderAction, performAction];
                    }

                    if (isRoleSupervisor) {
                      return [navigateToAction];
                    }

                    return [breakAddAction, performAction, cancelAction];
                  }

                  return [performAction];
                }
            }
          }

        case 'drop':
          {
            switch (row.get('status')) {
              case 'Arrive':
                {
                  if (isRoleSupervisor) {
                    return [breakAddAction, performAction, navigateToAction];
                  }

                  return [breakAddAction, performAction];
                }

              case 'Depart':
                {
                  if (isRoleSupervisor) {
                    if (isLastPerformedStop.includes(row.id)) {
                      return [breakAddAction, navigateToAction];
                    }

                    return [breakAddAction, navigateToAction, unperformAction];
                  }

                  if (isLastPerformedStop.includes(row.id)) {
                    return [breakAddAction];
                  }

                  return [breakAddAction, unperformAction];
                }

              case 'Cancelled':
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  return [];
                }

              case 'No Show':
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  return [unperformAction];
                }

              case 'pending':
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  return [];
                }

              default:
                {
                  if (routeStarted) {
                    if (reorderEligible) {
                      return [breakAddAction, reorderAction, performAction];
                    }

                    return [breakAddAction, performAction];
                  }

                  if (!Ember.isNone(route)) {
                    if (reorderEligible) {
                      return [breakAddAction, reorderAction, performAction];
                    }

                    if (isRoleSupervisor) {
                      return [navigateToAction];
                    }

                    return [breakAddAction, performAction, cancelAction];
                  }

                  return [];
                }
            }
          }

        case 'break':
          {
            routeStarted = ['In Progress', 'Started'].includes(row.get('dispatchRoute.computedStatus'));
            route = row.get('dispatchRoute');

            switch (row.get('routeBreak.status')) {
              case 'OnBreak':
                return [breakAddAction, performAction, unperformAction];

              case 'OffBreak':
                if (isLastPerformedStop.includes(row.id)) {
                  return [breakAddAction, unperformAction];
                }

                return [breakAddAction, unperformAction];

              default:
                {
                  if (routeStarted) {
                    if (routeChecked && !multiRecordsChecked) {
                      return [breakAddAction, breakDelAction, breakEditAction, reorderAction, performAction];
                    }

                    return [breakAddAction, breakDelAction, breakEditAction, performAction];
                  }

                  if (!Ember.isNone(route)) {
                    if (routeChecked && !multiRecordsChecked) {
                      return [breakAddAction, breakDelAction, breakEditAction, reorderAction, performAction];
                    }

                    return [breakAddAction, breakDelAction, breakEditAction, performAction];
                  }

                  return [];
                }
            }
          }

        case 'pulloutGarage':
          {
            routeStarted = ['In Progress', 'Started'].includes(row.get('dispatchRoute.computedStatus'));

            if (isRoleSupervisor) {
              return [breakAddAction, navigateToAction];
            }

            if (!routeStarted) {
              return [breakAddAction, performAction];
            }

            return [breakAddAction];
          }

        case 'pullinGarage':
          {
            routeStarted = ['In Progress', 'Started'].includes(row.get('dispatchRoute.computedStatus'));

            if (isRoleSupervisor) {
              return [breakAddAction, navigateToAction];
            }

            if (routeStarted) {
              return [breakAddAction, performAction];
            }

            return [breakAddAction];
          }

        default:
          {
            return [];
          }
      }
    },

    _dynamicScheduleActions() {
      const cancelAction = this.get('cancelAction');
      const rows = this.get('table.rows') || [];
      const row = rows.findBy('selected', true);
      const record = row ? row.get('record') : null;
      const stopType = record ? record.get('type') : null;
      const trip = (0, _unwrapProxy.unwrapProxy)(record.get('trip'));
      if (!row) return [];

      switch (stopType) {
        case 'pick':
          {
            if (trip.get('isScheduled')) {
              return [cancelAction];
            }

            return [];
          }

        case 'drop':
          {
            if (trip.get('isScheduled')) {
              return [cancelAction];
            }

            return [];
          }

        case 'break':
          {
            return [];
          }

        default:
          return [];
      }
    }

  });

  _exports.default = _default;
});