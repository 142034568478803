define("adept-iq/utils/remove-focus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.removeFocus = removeFocus;

  function removeFocus() {
    Ember.$('.column-widget-search-box').blur();
    Ember.$('.address-search-input').blur();
    return true;
  }
});