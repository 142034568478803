define("adept-iq/tests/factories/schedule", ["ember-data-factory-guy", "moment"], function (_emberDataFactoryGuy, _moment) {
  "use strict";

  const normalStart = (0, _moment.default)().startOf('day').add(6, 'hours');
  const normalEnd = (0, _moment.default)().startOf('day').add(18, 'hours');

  _emberDataFactoryGuy.default.define('schedule', {
    sequences: {
      scheduleNames: num => `schedule ${num}`,
      engineDatasetIds: num => num
    },
    default: {
      name: _emberDataFactoryGuy.default.generate('scheduleNames'),
      start: normalStart.toDate(),
      end: normalEnd.toDate(),
      engineDatasetId: _emberDataFactoryGuy.default.generate('engineDatasetIds')
    }
  });
});