define("adept-iq/adapters/bulk-operation", ["exports", "adept-iq/adapters/-dispatch"], function (_exports, _dispatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dispatch.default.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    postRecipe(recipe) {
      const session = this.get('session');
      const url = `${this.host}/bulk-operation`;
      const json = {
        data: {
          type: 'recipe',
          attributes: recipe
        }
      };
      return this.get('ajax').post(url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    }

  });

  _exports.default = _default;
});