define("adept-iq/pods/components/reoptimize-inprogress/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['reoptimize-in-progress'],
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    editModal: Ember.inject.service(),
    // Passed In Attributes
    title: null,
    description: null,
    selectedVal: null,

    didInsertElement() {
      this._super(...arguments);

      const tooltip = this.get('tooltip');
      const workspace = this.get('workspace');
      const editModal = this.get('editModal');

      if (!workspace.get('triggeredReoptimize')) {
        tooltip.setProperties({
          title: 'Reoptimization In Progress',
          hasOverlay: true,
          tip: 'Another scheduler is currently reoptimizing. Information is read only at this time.',
          primaryActionText: 'OK',

          primaryAction() {
            workspace.reset();
            tooltip.reset();

            if (editModal.get('isOpen')) {
              editModal.close();
            }
          }

        });
      }
    }

  });

  _exports.default = _default;
});