define("adept-iq/pods/components/iq-widgets/routes-form-widget/scheduleConfig", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Read Only',
      fields: [{
        id: 'routeId',
        type: 'text',
        label: 'Route Id',
        valuePath: 'name',
        editable: false
      }]
    }, {
      title: 'Edit Route',
      fields: [{
        id: 'name',
        type: 'text',
        label: 'Route ID',
        valuePath: 'name',
        readonlyCell: true
      }, {
        id: 'date',
        type: 'date',
        label: 'Date',
        valuePath: 'plannedStartTime',
        format: 'Y-m-d',
        // date flatpickr uses a different formating than moment
        readonlyCell: true
      }, {
        id: 'start',
        type: 'datetimeflatpickr',
        editable: true,
        hidden: false,
        label: 'Start Time',
        valuePath: 'plannedStartTime'
      }, {
        id: 'end',
        type: 'datetimeflatpickr',
        label: 'End Time',
        editable: true,
        hidden: false,
        valuePath: 'plannedEndTime'
      }, {
        id: 'tripLimit',
        type: 'number',
        label: 'Trip Limit',
        valuePath: 'tripLimit',
        readonlyCell: false,
        editable: true
      }, {
        id: 'distanceLimit',
        type: 'number',
        label: 'Distance Limit',
        valuePath: 'distanceLimit',
        readonlyCell: false,
        editable: true
      }, {
        id: 'vehicleId',
        type: 'number',
        label: 'Vehicle ID',
        valuePath: 'vehicle.name',
        readonlyCell: true,
        editable: true
      }, {
        id: 'driverId',
        type: 'text',
        label: 'Driver ID',
        valuePath: 'assignedDriver.driverId',
        editable: true,
        readonlyCell: true
      }, {
        id: 'schedulingMode',
        type: 'enum',
        label: 'Scheduling Mode',
        valuePath: 'schedulingMode',
        cellDesc: 'schedulingMode',
        editable: true,
        hidden: false,
        extra: {
          options: ['automatic', 'manual'],
          allowMultiple: false
        }
      }]
    }]
  };
  _exports.default = _default;
});