define("adept-iq/serializers/segment-stop", ["exports", "adept-iq/serializers/-bs-bookingService"], function (_exports, _bsBookingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsBookingService.default.extend({
    attrs: {
      onboardCount: {
        serialize: false
      }
    },

    serialize()
    /*snapshot, options*/
    {
      const json = this._super(...arguments);

      const tripStopRelationship = json.data.relationships.tripStop;
      const pickSegmentRelationship = json.data.relationships.pickSegment;
      const dropSegmentRelationship = json.data.relationships.dropSegment;

      if (Ember.isPresent(tripStopRelationship)) {
        delete json.data.relationships.tripStop;
      }

      if (Ember.isPresent(pickSegmentRelationship)) {
        delete json.data.relationships.pickSegment;
      }

      if (Ember.isPresent(dropSegmentRelationship)) {
        delete json.data.relationships.dropSegment;
      }

      return json;
    }

  });

  _exports.default = _default;
});