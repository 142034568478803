define("adept-iq/pods/components/side-drawer/route-activity-log/config", ["exports", "moment", "lodash", "adept-iq/config/environment"], function (_exports, _moment, _lodash, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.logEntryTypes = _exports.editableSections = _exports.columns = void 0;
  const TIME_FORMAT = 'YYYY-MM-DD hh:mmA'; // matches {{handleBars}}-style interpolation

  const TEMPLATE_INTERPOLATION = /{{([\s\S]+?)}}/g;

  const interpolate = (template, attrs) => {
    const compiledTemplate = _lodash.default.template(template, {
      interpolate: TEMPLATE_INTERPOLATION
    });

    return compiledTemplate(attrs);
  };

  const columns = [{
    label: 'Activity',
    valuePath: 'activity'
  }, {
    label: 'Date/Time',
    valuePath: 'date',
    format: date => (0, _moment.default)(date).format(TIME_FORMAT)
  }, {
    label: 'Details',
    valuePath: 'details'
  }, {
    label: 'User',
    valuePath: 'user'
  }];
  _exports.columns = columns;
  const editableSections = [{
    title: 'Add Activity Log Entry',
    fields: [{
      id: 'activity-type',
      type: 'enum',
      label: 'Activity',
      valuePath: 'userActivitySelected',
      cellDesc: 'Activity',
      editable: true,
      hidden: false,
      extra: {
        optionModelName: 'route-activity-log-type',
        optionSearchPath: 'name'
      }
    }, {
      id: 'Details',
      type: 'text-area',
      editable: true,
      label: 'Details',
      valuePath: 'details'
    }]
  }];
  _exports.editableSections = editableSections;
  const logEntryTypes = [{
    activity: 'No Show Trip Cancel',
    routeExecEventType: 'noShowCancelled',

    buildAttrs(routeExecEvent
    /*, store*/
    ) {
      return {
        driverId: routeExecEvent.get('driver.badgeNr'),
        driverName: routeExecEvent.get('driver.name'),
        vehicleName: routeExecEvent.get('vehicle.callSign'),
        odometer: routeExecEvent.get('vehicle.odo'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng'),
        trip: routeExecEvent.get('content.passengerEvents[0].tripId')
      };
    },

    buildDetails(attrs) {
      let template = 'Trip {{trip}} no show request cancelled.';

      if (attrs.lat) {
        template += ' Lat {{lat}}.';
      }

      if (attrs.lng) {
        template += ' Lng {{lng}}.';
      }

      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'Vehicle Pre Check',
    routeExecEventType: 'preCheckDone',

    buildAttrs(routeExecEvent)
    /*store*/
    {
      const attrs = {
        driverId: routeExecEvent.get('driver.badgeNr')
      };
      return attrs;
    },

    buildDetails(attrs) {
      const template = 'Pre-check performed.';
      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'Vehicle Post Check',
    routeExecEventType: 'postCheckDone',

    buildAttrs(routeExecEvent)
    /*store*/
    {
      const attrs = {
        driverId: routeExecEvent.get('driver.badgeNr')
      };
      return attrs;
    },

    buildDetails(attrs) {
      const template = 'Post-check performed.';
      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'Sign On',
    routeExecEventType: 'signOn',

    buildAttrs(routeExecEvent
    /*, store*/
    ) {
      return {
        driverId: routeExecEvent.get('driver.badgeNr'),
        driverName: routeExecEvent.get('driver.name'),
        vehicleName: routeExecEvent.get('vehicle.callSign'),
        odometer: routeExecEvent.get('vehicle.odo'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng')
      };
    },

    buildDetails(attrs) {
      let template = 'Route signed on. Driver {{driverName}}. Vehicle {{vehicleName}}. Odometer {{odometer}}.';

      if (attrs.lat) {
        template += ' Lat {{lat}}.';
      }

      if (attrs.lng) {
        template += ' Lng {{lng}}.';
      }

      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'Sign Off',
    routeExecEventType: 'signOff',

    buildAttrs(routeExecEvent
    /*, store*/
    ) {
      return {
        driverId: routeExecEvent.get('driver.badgeNr'),
        driverName: routeExecEvent.get('driver.name'),
        vehicleName: routeExecEvent.get('vehicle.callSign'),
        odometer: routeExecEvent.get('vehicle.odo'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng')
      };
    },

    buildDetails(attrs) {
      let template = 'Route signed off. Driver {{driverName}}. Vehicle {{vehicleName}}. Odometer {{odometer}}.';

      if (attrs.lat) {
        template += ' Lat {{lat}}.';
      }

      if (attrs.lng) {
        template += ' Lng {{lng}}.';
      }

      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'Alternate Path',
    routeExecEventType: 'altPath',

    buildAttrs(routeExecEvent
    /*, store*/
    ) {
      return {
        driverId: routeExecEvent.get('driver.badgeNr'),
        driverName: routeExecEvent.get('driver.name'),
        vehicleName: routeExecEvent.get('vehicle.callSign'),
        odometer: routeExecEvent.get('vehicle.odo'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng')
      };
    },

    buildDetails(attrs) {
      let template = 'Alternate Path for Vehicle {{vehicleName}}, choosen at';

      if (attrs.lat) {
        template += ' Lat {{lat}}.';
      }

      if (attrs.lng) {
        template += ' Lng {{lng}}.';
      }

      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'Emergency Received',
    routeExecEventType: 'emergencyOn',

    buildAttrs(routeExecEvent
    /*, store*/
    ) {
      return {
        driverId: routeExecEvent.get('driver.badgeNr'),
        driverName: routeExecEvent.get('driver.name'),
        vehicleName: routeExecEvent.get('vehicle.callSign'),
        odometer: routeExecEvent.get('vehicle.odo'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng')
      };
    },

    buildDetails(attrs) {
      let template = 'Emergency message received from Vehicle {{vehicleName}}.';

      if (attrs.lat) {
        template += ' Lat {{lat}}.';
      }

      if (attrs.lng) {
        template += ' Lng {{lng}}.';
      }

      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'Emergency Acknowledged',
    routeExecEventType: 'emergencyAck',

    buildAttrs(routeExecEvent
    /*, store*/
    ) {
      return {
        driverId: routeExecEvent.get('driver.badgeNr'),
        driverName: routeExecEvent.get('driver.name'),
        vehicleName: routeExecEvent.get('vehicle.callSign'),
        odometer: routeExecEvent.get('vehicle.odo'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng')
      };
    },

    buildDetails(attrs) {
      const template = 'Emergency acknowledged for Vehicle {{vehicleName}}.';
      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      // eslint-disable-next-line no-unused-vars
      let adeptUser = JSON.parse(localStorage.getItem('ember_simple_auth-session'));
      adeptUser = adeptUser.authenticated.tokenInfo.firstName + adeptUser.authenticated.tokenInfo.lastName;
      const template = 'Dispatch'; //adeptUser;

      return interpolate(template, attrs);
    }

  }, {
    activity: 'Emergency Resolved',
    routeExecEventType: 'emergencyClear',

    buildAttrs(routeExecEvent
    /*, store*/
    ) {
      return {
        driverId: routeExecEvent.get('driver.badgeNr'),
        driverName: routeExecEvent.get('driver.name'),
        vehicleName: routeExecEvent.get('vehicle.callSign'),
        odometer: routeExecEvent.get('vehicle.odo'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng')
      };
    },

    buildDetails(attrs) {
      const template = 'Emergency resolved for Vehicle {{vehicleName}}.';
      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      // eslint-disable-next-line no-unused-vars
      let adeptUser = JSON.parse(localStorage.getItem('ember_simple_auth-session'));
      adeptUser = adeptUser.authenticated.tokenInfo.firstName + adeptUser.authenticated.tokenInfo.lastName;
      const template = 'Dispatch'; //adeptUser;

      return interpolate(template, attrs);
    }

  }, {
    activity: 'Emergency Canceled',
    routeExecEventType: 'emergencyCancel',

    buildAttrs(routeExecEvent
    /*, store*/
    ) {
      return {
        driverId: routeExecEvent.get('driver.badgeNr'),
        driverName: routeExecEvent.get('driver.name'),
        vehicleName: routeExecEvent.get('vehicle.callSign'),
        odometer: routeExecEvent.get('vehicle.odo'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng')
      };
    },

    buildDetails(attrs) {
      const template = 'Emergency canceled for Vehicle {{vehicleName}}.';
      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      // eslint-disable-next-line no-unused-vars
      let adeptUser = JSON.parse(localStorage.getItem('ember_simple_auth-session'));
      adeptUser = adeptUser.authenticated.tokenInfo.firstName + adeptUser.authenticated.tokenInfo.lastName;
      const template = 'Dispatch'; //adeptUser;

      return interpolate(template, attrs);
    }

  }, {
    activity: 'Start',
    routeExecEventType: 'pullOut',

    buildAttrs(routeExecEvent
    /*, store*/
    ) {
      return {
        driverId: routeExecEvent.get('driver.badgeNr'),
        driverName: routeExecEvent.get('driver.name'),
        vehicleName: routeExecEvent.get('vehicle.callSign'),
        odometer: routeExecEvent.get('vehicle.odo'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng')
      };
    },

    buildDetails(attrs) {
      let template = 'Route started. Driver {{driverId}}. Vehicle {{vehicleName}}.'; // these aren't always available yet

      if (attrs.odometer) {
        const convertedDistance = attrs.odometer;
        template += ` Odometer ${convertedDistance} ${_environment.default.APP.distanceUnit}.`;
      }

      if (attrs.lat) {
        template += ' Lat {{lat}}.';
      }

      if (attrs.lng) {
        template += ' Lng {{lng}}.';
      }

      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'End',
    routeExecEventType: 'pullIn',

    buildAttrs(routeExecEvent
    /*, store*/
    ) {
      return {
        driverId: routeExecEvent.get('driver.badgeNr'),
        driverName: routeExecEvent.get('driver.name'),
        vehicleName: routeExecEvent.get('vehicle.callSign'),
        odometer: routeExecEvent.get('vehicle.odo'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng')
      };
    },

    buildDetails(attrs) {
      let template = 'Route ended. Driver {{driverId}}. Vehicle {{vehicleName}}.'; // these aren't always available yet

      if (attrs.odometer) {
        const convertedDistance = attrs.odometer;
        template += ` Odometer ${convertedDistance} ${_environment.default.APP.distanceUnit}.`;
      }

      if (attrs.lat) {
        template += ' Lat {{lat}}.';
      }

      if (attrs.lng) {
        template += ' Lng {{lng}}';
      }

      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'Arrive Stop',
    routeExecEventType: 'arrive',

    buildAttrs(routeExecEvent, store) {
      let passengerEvents = routeExecEvent.get('content.passengerEvents');
      let dsTripId;
      passengerEvents = Ember.makeArray(passengerEvents);
      const pickupEvent = passengerEvents.findBy('type', 'pickup');
      const dropoffEvent = passengerEvents.findBy('type', 'dropoff');
      const attrs = {
        driverId: routeExecEvent.get('driver.badgeNr'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng')
      };

      if (pickupEvent) {
        dsTripId = Ember.get(pickupEvent, 'tripId');
        attrs.stopType = 'pickup';
      } else if (dropoffEvent) {
        dsTripId = Ember.get(dropoffEvent, 'tripId');
        attrs.stopType = 'drop-off';
      } else if (passengerEvents[0]) {
        dsTripId = Ember.get(passengerEvents[0], 'tripId');
        attrs.stopType = 'pickup';
      }

      const trip = _environment.default.APP.avlmLite ? store.peekRecord('avlm-trip', dsTripId) : store.peekRecord('trip', dsTripId);
      const tripId = _environment.default.APP.avlmLite ? trip.get('externalId') : trip.get('tripId');
      attrs.tripId = tripId || '<loading>';
      return attrs;
    },

    buildDetails(attrs) {
      let template = 'Trip {{tripId}} {{stopType}} arrived.';

      if (attrs.lat && attrs.lng) {
        template += ' Lat {{lat}}. Lng {{lng}}.';
      }

      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'Depart Stop',
    routeExecEventType: 'depart',

    condition(routeExecEvent) {
      const passengerEvents = routeExecEvent.get('content.passengerEvents');
      return Ember.makeArray(passengerEvents).any(passengerEvent => {
        return ['pickup', 'dropoff'].includes(Ember.get(passengerEvent, 'type'));
      });
    },

    buildAttrs(routeExecEvent, store) {
      let passengerEvents = routeExecEvent.get('content.passengerEvents');
      let dsTripId;
      passengerEvents = Ember.makeArray(passengerEvents);
      const pickupEvent = passengerEvents.findBy('type', 'pickup');
      const dropoffEvent = passengerEvents.findBy('type', 'dropoff');
      const noShowEvent = passengerEvents.findBy('type', 'noShow');
      const attrs = {
        driverId: routeExecEvent.get('driver.badgeNr'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng')
      };

      if (pickupEvent) {
        dsTripId = pickupEvent.tripId;
        attrs.stopType = 'pickup';
      } else if (dropoffEvent) {
        dsTripId = dropoffEvent.tripId;
        attrs.stopType = 'drop-off';
      } else if (noShowEvent) {
        dsTripId = noShowEvent.tripId;
        attrs.stopType = 'no-show';
      } else if (passengerEvents[0]) {
        dsTripId = Ember.get(passengerEvents[0], 'tripId');
        attrs.stopType = 'pickup';
      }

      const trip = _environment.default.APP.avlmLite ? store.peekRecord('avlm-trip', dsTripId) : store.peekRecord('trip', dsTripId);
      const tripId = _environment.default.APP.avlmLite ? trip.get('externalId') : trip.get('tripId');
      attrs.tripId = tripId || '<loading>';
      return attrs;
    },

    buildDetails(attrs) {
      let template = 'Trip {{tripId}} {{stopType}} departed.';

      if (attrs.lat && attrs.lng) {
        template += ' Lat {{lat}}. Lng {{lng}}.';
      }

      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'No Show Trip',
    routeExecEventType: 'noShow',

    condition(routeExecEvent) {
      const passengerEvents = Ember.makeArray(routeExecEvent.get('content.passengerEvents')); // there must be a client noShow event to render this activity log event

      return passengerEvents.any(() =>
      /*passengerEv*/
      {
        return routeExecEvent.get('content.type') === 'noShow';
      });
    },

    buildAttrs(routeExecEvent, store) {
      const passengerEvents = routeExecEvent.get('content.passengerEvents');
      const passengerEvent = Ember.makeArray(passengerEvents).find(() =>
      /*passengerEv*/
      {
        return routeExecEvent.get('content.type') === 'noShow';
      });
      const internalId = Ember.get(passengerEvent, 'tripId');
      const trip = _environment.default.APP.avlmLite ? store.peekRecord('avlm-trip', internalId) : store.peekRecord('trip', internalId);
      const tripId = _environment.default.APP.avlmLite ? trip.get('externalId') : trip.get('tripId');
      return {
        tripId: tripId || '<loading>',
        stopType: Ember.get(passengerEvent, 'type'),
        details: Ember.get(passengerEvent, 'details.message'),
        code: Ember.get(passengerEvent, 'details.code'),
        driverId: routeExecEvent.get('driver.badgeNr'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng')
      };
    },

    buildDetails(attrs) {
      let template = 'Trip {{tripId}}';

      if (attrs.stopType) {
        template += ' {{stopType}} no-showed.';
      }

      if (attrs.code || attrs.details) {
        template += ' No-show reason {{code}} {{details}}.';
      }

      if (attrs.lat && attrs.lng) {
        template += ' Lat {{lat}}. Lng {{lng}}.';
      }

      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'No Show Trip Request',
    routeExecEventType: 'noShowRequest',

    condition(routeExecEvent) {
      const passengerEvents = Ember.makeArray(routeExecEvent.get('content.passengerEvents')); // there must be a client noShow event to render this activity log event

      return passengerEvents.any(() =>
      /*passengerEv*/
      {
        return routeExecEvent.get('content.type') === 'noShowRequest';
      });
    },

    buildAttrs(routeExecEvent, store) {
      const passengerEvents = routeExecEvent.get('content.passengerEvents');
      const passengerEvent = Ember.makeArray(passengerEvents).find(() =>
      /*passengerEv*/
      {
        return routeExecEvent.get('content.type') === 'noShowRequest';
      });
      const internalId = Ember.get(passengerEvent, 'tripId');
      const trip = _environment.default.APP.avlmLite ? store.peekRecord('avlm-trip', internalId) : store.peekRecord('trip', internalId);
      const tripId = _environment.default.APP.avlmLite ? trip.get('externalId') : trip.get('tripId');
      return {
        tripId: tripId || '<loading>',
        driverId: routeExecEvent.get('driver.badgeNr'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng')
      };
    },

    buildDetails(attrs) {
      let template = 'Trip {{tripId}} requested as no show.';

      if (attrs.lat && attrs.lng) {
        template += ' Lat {{lat}}. Lng {{lng}}.';
      }

      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'No Show Request Accepted',
    routeExecEventType: 'noShowAccepted',

    condition(routeExecEvent) {
      const passengerEvents = Ember.makeArray(routeExecEvent.get('content.passengerEvents')); // there must be a client noShow event to render this activity log event

      return passengerEvents.any(() =>
      /*passengerEv*/
      {
        return routeExecEvent.get('content.type') === 'noShowAccepted';
      });
    },

    buildAttrs(routeExecEvent, store) {
      const passengerEvents = routeExecEvent.get('content.passengerEvents');
      const passengerEvent = Ember.makeArray(passengerEvents).find(() =>
      /*passengerEv*/
      {
        return routeExecEvent.get('content.type') === 'noShowAccepted';
      });
      const internalId = Ember.get(passengerEvent, 'tripId');
      const trip = _environment.default.APP.avlmLite ? store.peekRecord('avlm-trip', internalId) : store.peekRecord('trip', internalId);
      const tripId = _environment.default.APP.avlmLite ? trip.get('externalId') : trip.get('tripId');
      return {
        tripId: tripId || '<loading>',
        driverId: routeExecEvent.get('driver.badgeNr'),
        details: Ember.get(passengerEvent, 'details.message'),
        code: Ember.get(passengerEvent, 'details.code'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng')
      };
    },

    buildDetails(attrs) {
      let template = 'Trip {{tripId}} no show request accepted.';

      if (attrs.details) {
        template += ' Reason {{details}}.';
      }

      if (attrs.lat && attrs.lng) {
        template += ' Lat {{lat}}. Lng {{lng}}.';
      }

      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'No Show Request Declined',
    routeExecEventType: 'noShowDeclined',

    condition(routeExecEvent) {
      const passengerEvents = Ember.makeArray(routeExecEvent.get('content.passengerEvents')); // there must be a client noShow event to render this activity log event

      return passengerEvents.any(() =>
      /*passengerEv*/
      {
        return routeExecEvent.get('content.type') === 'noShowDeclined';
      });
    },

    buildAttrs(routeExecEvent, store) {
      const passengerEvents = routeExecEvent.get('content.passengerEvents');
      const passengerEvent = Ember.makeArray(passengerEvents).find(() =>
      /*passengerEv*/
      {
        return routeExecEvent.get('content.type') === 'noShowDeclined';
      });
      const internalId = Ember.get(passengerEvent, 'tripId');
      const trip = _environment.default.APP.avlmLite ? store.peekRecord('avlm-trip', internalId) : store.peekRecord('trip', internalId);
      const tripId = _environment.default.APP.avlmLite ? trip.get('externalId') : trip.get('tripId');
      return {
        tripId: tripId || '<loading>',
        driverId: routeExecEvent.get('driver.badgeNr'),
        details: Ember.get(passengerEvent, 'details.message'),
        code: Ember.get(passengerEvent, 'details.code'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng')
      };
    },

    buildDetails(attrs) {
      let template = 'Trip {{tripId}} no show request declined.';

      if (attrs.details) {
        template += ' Reason {{details}}.';
      }

      if (attrs.lat && attrs.lng) {
        template += ' Lat {{lat}}. Lng {{lng}}.';
      }

      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'Break Start',
    routeExecEventType: 'onBreak',

    condition(routeExecEvent) {
      return routeExecEvent.get('content.type') === 'onBreak';
    },

    buildAttrs(routeExecEvent) {
      return {
        driverId: routeExecEvent.get('driver.badgeNr'),
        vehicleId: routeExecEvent.get('vehicle.callSign'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng')
      };
    },

    buildDetails(attrs) {
      let template = 'Break started. Driver {{driverId}}. Vehicle {{vehicleId}}';

      if (attrs.lat) {
        template += ' Lat {{lat}}.';
      }

      if (attrs.lng) {
        template += ' Lng {{lng}}.';
      }

      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'Break End',
    routeExecEventType: 'offBreak',

    buildAttrs(routeExecEvent) {
      return {
        driverId: routeExecEvent.get('driver.badgeNr'),
        vehicleId: routeExecEvent.get('vehicle.callSign'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng')
      };
    },

    buildDetails(attrs) {
      let template = 'Break ended. Driver {{driverId}}. Vehicle {{vehicleId}}.';

      if (attrs.lat) {
        template += ' Lat {{lat}}.';
      }

      if (attrs.lng) {
        template += ' Lng {{lng}}.';
      }

      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'Manual Entry',
    routeExecEventType: 'userEntry',

    buildAttrs(routeExecEvent) {
      return {
        activity: routeExecEvent.get('content.activity'),
        userId: routeExecEvent.get('content.userId'),
        type: routeExecEvent.get('content.type'),
        details: routeExecEvent.get('content.details'),
        actualTime: routeExecEvent.get('content.actualTime')
      };
    },

    buildDetails(attrs) {
      let template = '';

      if (attrs.details) {
        template += '{{details}}.';
      }

      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = '{{userId}}';
      return interpolate(template, attrs);
    }

  }, // the following are not currently represented by routeExecEvents:
  {
    activity: 'Remove Vehicle',
    attrs: () => {},
    details: () => 'Vehicle <vehicleName> removed.',
    user: () => null
  }, {
    activity: 'Add Vehicle',
    attrs: () => {},
    details: () => 'Vehicle <vehicleName> added.',
    user: () => null
  }, {
    activity: 'Cancel Trip',
    routeExecEventType: 'cancel',

    buildAttrs(routeExecEvent, store) {
      const passengerEvents = routeExecEvent.get('content.passengerEvents');
      const passengerEvent = Ember.makeArray(passengerEvents).find(() =>
      /*passengerEv*/
      {
        return routeExecEvent.get('content.type') === 'cancel';
      });
      let dsTripId = null;
      const attrs = {
        userId: routeExecEvent.get('user.userId'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng')
      };

      if (passengerEvent) {
        dsTripId = Ember.get(passengerEvent, 'tripId');
        attrs.details = passengerEvent.get('details.message');
        attrs.code = passengerEvent.get('details.code');
      }

      const trip = _environment.default.APP.avlmLite ? store.peekRecord('avlm-trip', dsTripId) : store.peekRecord('trip', dsTripId);
      const tripId = _environment.default.APP.avlmLite ? trip.get('externalId') : trip.get('tripId');
      attrs.tripId = tripId || '<loading>';
      return attrs;
    },

    buildDetails(attrs) {
      let template = 'Trip {{tripId}} cancelled. Cancel reason {{code}} {{details}}';

      if (attrs.lat && attrs.lng) {
        template += ' Lat {{lat}}. Lng {{lng}}.';
      }

      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'User {{userId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'Transfer Trip',
    attrs: () => {},
    details: () => 'Trip <tripId> transferred to route <routeName>.',
    user: () => null
  }, {
    activity: 'Waitlist Trip',
    routeExecEventType: 'waitlist',

    buildAttrs(routeExecEvent, store) {
      const passengerEvents = routeExecEvent.get('content.passengerEvents');
      const passengerEvent = Ember.makeArray(passengerEvents).find(() =>
      /*passengerEv*/
      {
        return routeExecEvent.get('content.type') === 'waitlist';
      });
      let dsTripId;
      const attrs = {
        userId: routeExecEvent.get('user.userId'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng')
      };

      if (passengerEvent) {
        dsTripId = Ember.get(passengerEvent, 'tripId');
        attrs.details = passengerEvent.get('details.message');
        attrs.code = passengerEvent.get('details.code');
      }

      const trip = _environment.default.APP.avlmLite ? store.peekRecord('avlm-trip', dsTripId) : store.peekRecord('trip', dsTripId);
      const tripId = _environment.default.APP.avlmLite ? trip.get('externalId') : trip.get('tripId');
      attrs.tripId = tripId || '<loading>';
      return attrs;
    },

    buildDetails(attrs) {
      let template = 'Trip {{tripId}} waitlisted.';

      if (attrs.lat && attrs.lng) {
        template += ' Lat {{lat}}. Lng {{lng}}.';
      }

      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'User {{userId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'Message Received',
    attrs: () => {},
    details: () => 'Message <cannedMessage> received from vehicle <vehicleName>.',
    user: () => null
  }, {
    activity: 'Message Sent',
    attrs: () => {},
    details: () => 'Message <cannedMessage> sent to vehicle <vehicleName>.',
    user: () => null
  }, {
    activity: 'Edit',
    attrs: () => {},
    details: () => 'Route edited. Route\'s start odometer changed from <odometerValue> to <odometerValue>.',
    user: () => null
  }, {
    activity: 'Close',
    attrs: () => {},
    details: () => 'Route\'s date of <date> was closed.',
    user: () => null
  }, {
    activity: 'Create',
    attrs: () => {},
    details: () => 'Route created.',
    user: () => null
  }, {
    activity: 'Add Trip',
    attrs: () => {},
    details: () => 'Trip <tripId> added',
    user: () => null
  }];
  _exports.logEntryTypes = logEntryTypes;
});