define("adept-iq/models/route-template", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CONSOLIDATE_ROUTE_FLAG = _exports.ADJUST_BREAK_FLAG = _exports.ROUTE_TEMPLATE_TYPE_REGULAR = _exports.ROUTE_TEMPLATE_TYPE_KEEPER = _exports.AUTOMATIC_SCHEDULING_MODE = _exports.MANUAL_SCHEDULING_MODE = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var hasMany = _emberData.default.hasMany;
  // scheduling modes
  const MANUAL_SCHEDULING_MODE = 'manual';
  _exports.MANUAL_SCHEDULING_MODE = MANUAL_SCHEDULING_MODE;
  const AUTOMATIC_SCHEDULING_MODE = 'automatic'; // route types

  _exports.AUTOMATIC_SCHEDULING_MODE = AUTOMATIC_SCHEDULING_MODE;
  const ROUTE_TEMPLATE_TYPE_KEEPER = 'keeper';
  _exports.ROUTE_TEMPLATE_TYPE_KEEPER = ROUTE_TEMPLATE_TYPE_KEEPER;
  const ROUTE_TEMPLATE_TYPE_REGULAR = 'regular'; // optimization flag bitfield

  _exports.ROUTE_TEMPLATE_TYPE_REGULAR = ROUTE_TEMPLATE_TYPE_REGULAR;
  const ADJUST_BREAK_FLAG = 0b00000001;
  _exports.ADJUST_BREAK_FLAG = ADJUST_BREAK_FLAG;
  const CONSOLIDATE_ROUTE_FLAG = 0b00000010;
  _exports.CONSOLIDATE_ROUTE_FLAG = CONSOLIDATE_ROUTE_FLAG;

  var _default = Model.extend({
    name: attr('string'),
    schedulingMode: attr('string'),
    type: attr('string'),
    notes: attr('string'),
    tripLimit: attr('number'),
    distanceLimit: attr('number'),
    active: attr('boolean'),
    optimizationFlags: attr('number', {
      defaultValue: 3
    }),
    // bit field
    affinities: attr(),
    // string array ex "[1,2,3,4]"
    availabilities: hasMany('route-template-availability'),
    sortedAvailabilities: Ember.computed('availabilities', function () {
      const availabilities = this.get('availabilities');
      return availabilities.sortBy('startTime');
    }),
    isManualTrips: Ember.computed.equal('schedulingMode', MANUAL_SCHEDULING_MODE),
    isKeeperType: Ember.computed.equal('type', ROUTE_TEMPLATE_TYPE_KEEPER),
    isAdjustBreak: Ember.computed('optimizationFlags', function () {
      const optimizationFlags = this.get('optimizationFlags');
      return ADJUST_BREAK_FLAG & optimizationFlags;
    }),
    isConsolidateRoute: Ember.computed('optimizationFlags', function () {
      const optimizationFlags = this.get('optimizationFlags');
      return CONSOLIDATE_ROUTE_FLAG & optimizationFlags;
    })
  });

  _exports.default = _default;
});