define("adept-iq/models/route-template-availability", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    startTime: attr('date'),
    endTime: attr('date'),
    shiftStart: attr('string'),
    shiftEnd: attr('string'),
    routeLength: attr('string'),
    tripLimit: attr('number'),
    sunday: attr('boolean', {
      defaultValue: false
    }),
    monday: attr('boolean', {
      defaultValue: false
    }),
    tuesday: attr('boolean', {
      defaultValue: false
    }),
    wednesday: attr('boolean', {
      defaultValue: false
    }),
    thursday: attr('boolean', {
      defaultValue: false
    }),
    friday: attr('boolean', {
      defaultValue: false
    }),
    saturday: attr('boolean', {
      defaultValue: false
    }),
    holiday: attr('boolean', {
      defaultValue: false
    }),
    vehicle: belongsTo('vehicle'),
    // place holder vehicle
    routeTemplate: belongsTo('route-template'),
    // place holder vehicle
    shiftBreaks: hasMany('shift-break'),
    markedDeletedShiftBreaks: Ember.computed('shiftBreaks.@each.isDeleted', function () {
      return this.get('shiftBreaks').filter(shift => !shift.get('isDeleted'));
    }),
    calcEndDate: Ember.computed('startTime', 'routeLength', function () {
      const start = this.get('startTime');
      const routeLength = this.get('routeLength');
      if (!start || !routeLength) return null;
      const clone = (0, _moment.default)(start);
      let duration, hours, minutes;

      if (routeLength.includes(':')) {
        duration = routeLength.split(':');
        hours = duration[0];
        minutes = duration.length > 1 ? duration[1] : 0;
        clone.add(hours, 'hours');
        clone.add(minutes, 'minutes');
        return clone.toDate();
      }

      minutes = routeLength;
      clone.add(minutes, 'minutes');
      return clone.toDate();
    })
  });

  _exports.default = _default;
});