define("adept-iq/classes/data-joins/map/polyline", ["exports", "adept-iq/classes/data-joins/map/base", "lodash", "tomtom", "adept-iq/pods/components/iq-widgets/map-widget/config/polyline", "adept-iq/pods/components/iq-widgets/map-widget/config/patterns"], function (_exports, _base, _lodash, _tomtom, _polyline, _patterns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    onContextMenu: null,
    onDblClick: null,
    queueName: 'map-polyline',

    refresh(node) {
      return [this.refreshPolyline(node), this.refreshDecorator(node)].any(Boolean);
    },

    refreshPolyline(node) {
      const map = this.map;
      const isMaximized = map.options.isMaximized;
      const isJoined = node.get('isJoined');
      const isHidden = node.get('isHidden');
      const points = node.get('points');
      const polyline = isMaximized ? node.get('polylineMaximized') : node.get('polyline');

      if (!isJoined || isHidden || Ember.isEmpty(points)) {
        if (polyline) {
          return this.removePolyline(node, isMaximized);
        }

        return false;
      }

      if (!polyline) {
        return this.createPolyline(node, isMaximized);
      }

      return this.updatePolyline(node, isMaximized);
    },

    refreshDecorator(node) {
      const map = this.map;
      const isMaximized = map.options.isMaximized;
      const isJoined = node.get('isJoined');
      const isHidden = node.get('isHidden');
      const pattern = node.get('pattern');
      const polyline = node.get('polyline');
      const decorator = isMaximized ? node.get('decoratorMaximized') : node.get('decorator');

      if (!isJoined || isHidden || !polyline || !pattern) {
        if (decorator) {
          return this.removeDecorator(node, isMaximized);
        }

        return false;
      }

      if (!decorator) {
        return this.createDecorator(node, isMaximized);
      }

      return this.updateDecorator(node, isMaximized);
    },

    createPolyline(node, isMaximized) {
      const options = this._computePolylineOptions(node);

      const polyline = _tomtom.default.L.polyline(node.get('points'), options);

      if (isMaximized) {
        node.set('polylineMaximized', polyline);
      } else {
        node.set('polyline', polyline);
      }

      polyline.addTo(this.get('map')); // bind tooltip & pop-up

      const label = node.get('label');

      if (label) {
        polyline.bindPopup(label, {
          closeButton: false
        });
        polyline.bindTooltip(label);
      } // used for click handler callbacks


      const record = node.get('record');
      polyline.iq = {
        isActive: node.get('isActive'),
        modelId: record ? record.get('id') : null,
        modelName: record ? record.constructor.modelName : null
      }; // register click handlers

      polyline.on('contextmenu', e => {
        node.trigger('contextmenu', e);

        if (this.onContextMenu) {
          Ember.run(() => this.onContextMenu(e, node));
        }
      });
      polyline.on('dblclick', e => {
        node.trigger('dblclick', e);

        if (this.onDblClick) {
          _tomtom.default.L.DomEvent.stop(e);

          Ember.run(() => this.onDblClick(e, node));
        }
      }); // initialize cache

      node.cache = node.cache || {};
      node.cache.polyline = {
        label,
        optionsDigest: JSON.stringify(options),
        pointsDigest: node.get('isDynamic') ? node.get('pointsDigest') : null
      };
      return true;
    },

    updatePolyline(node, isMaximized) {
      const polyline = isMaximized ? node.get('polylineMaximized') : node.get('polyline');
      const cache = node.cache.polyline;
      let isDirty = false; // update style options

      const options = this._computePolylineOptions(node);

      const optionsDigest = JSON.stringify(options);

      if (optionsDigest !== cache.optionsDigest) {
        polyline.setStyle(options);
        cache.optionsDigest = optionsDigest;
        isDirty = true;
      } // update polyline points; only if dynamic!


      if (node.get('isDynamic')) {
        const pointsDigest = node.get('pointsDigest');

        if (pointsDigest !== cache.pointsDigest) {
          polyline.setLatLngs(node.get('points'));
          cache.pointsDigest = pointsDigest;
          isDirty = true;
        }
      } // update popup & tooltip


      const label = node.get('label');

      if (label !== cache.label) {
        if (!label) {
          polyline.unbindPopup();
          polyline.unbindTooltip();
        } else if (!cache.label) {
          polyline.bindPopup(label, {
            closeButton: false
          });
          polyline.bindTooltip(label);
        } else {
          polyline.setPopupContent(label);
          polyline.setTooltipContent(label);
        }

        cache.label = label;
        isDirty = true;
      }

      return isDirty;
    },

    removePolyline(node, isMaximized) {
      const polyline = isMaximized ? node.get('polylineMaximized') : node.get('polyline');
      polyline.iq = null;
      polyline.off('contextmenu');
      polyline.off('dblclick');
      polyline.remove();

      if (isMaximized) {
        node.set('polylineMaximized', null);
      } else {
        node.set('polyline', null);
      }

      if (!node.cache && !node.cache.polyline) {
        node.cache.polyline = null;
      }

      if (!node.cache.decorator) {
        node.cache = null;
      }

      return true;
    },

    createDecorator(node, isMaximized) {
      const polyline = isMaximized ? node.get('polylineMaximized') : node.get('polyline');

      const options = this._computeDecoratorOptions(node);

      const decorator = _tomtom.default.L.polylineDecorator(polyline, options);

      if (isMaximized) {
        node.set('decoratorMaximized', decorator);
      } else {
        node.set('decorator', decorator);
      }

      decorator.addTo(this.get('map'));
      node.cache = node.cache || {};
      node.cache.decorator = {
        patternsDigest: JSON.stringify(options.patterns),
        pointsDigest: node.get('isDynamic') ? node.get('pointsDigest') : null
      };
      return true;
    },

    updateDecorator(node, isMaximized) {
      const decorator = isMaximized ? node.get('decoratorMaximized') : node.get('decorator');
      const cache = node.cache.decorator;
      let isDirty = false; // update patterns; this includes opacity

      const {
        patterns
      } = this._computeDecoratorOptions(node);

      const patternsDigest = JSON.stringify(patterns);

      if (patternsDigest !== cache.patternsDigest) {
        decorator.setPatterns(patterns);
        cache.patternsDigest = patternsDigest;
        isDirty = true;
      } // update points, but only if dynamic


      if (node.get('isDynamic')) {
        const pointsDigest = node.get('pointsDigest');

        if (pointsDigest !== cache.pointsDigest) {
          const points = node.get('points');
          decorator.setPaths(points);
          cache.pointsDigest = pointsDigest;
          isDirty = true;
        }
      }

      return isDirty;
    },

    removeDecorator(node, isMaximized) {
      const decorator = isMaximized ? node.get('decoratorMaximized') : node.get('decorator');
      decorator.remove();

      if (isMaximized) {
        node.set('decoratorMaximized', null);
      } else {
        node.set('decorator', null);
      }

      if (!node.cache && !node.cache.decorato) {
        node.cache.decorator = null;
      }

      if (!node.cache.polyline) {
        node.cache = null;
      }

      return true;
    },

    _computeDecoratorOptions(node) {
      const patternName = node.get('pattern');

      if (!_patterns.default.hasOwnProperty(patternName)) {
        throw new Error(`no pattern preset matching '${patternName}'`);
      }

      const presetPatterns = _patterns.default[patternName];
      const opacity = node.get('opacity');
      const patterns = presetPatterns.map(presetPattern => {
        const pattern = _lodash.default.cloneDeep(presetPattern);

        const pathOptions = Ember.get(pattern, 'symbol.options.pathOptions');
        if (!pathOptions) return pattern; // some pattern presets have both opacities, some only one; this is why
        // we need to bake opacity into the patterns array

        if (pathOptions.hasOwnProperty('opacity')) {
          pathOptions.opacity = opacity;
        }

        if (pathOptions.hasOwnProperty('fillOpacity')) {
          pathOptions.fillOpacity = opacity;
        }

        return pattern;
      });
      return {
        patterns
      };
    },

    _computePolylineOptions(node) {
      const styleObjects = [];
      const opacity = node.get('opacity');

      if (!Ember.isNone(opacity)) {
        styleObjects.push({
          opacity
        });
      }

      const nodeOptions = node.get('options');

      if (nodeOptions) {
        styleObjects.push(nodeOptions);
      }

      const style = node.get('style');

      if (style) {
        const presetOptions = _polyline.default[style];

        if (!presetOptions) {
          throw new Error(`no style preset matching '${style}'`);
        }

        styleObjects.push(presetOptions);
      }

      return _lodash.default.defaultsDeep({}, ...styleObjects);
    }

  });

  _exports.default = _default;
});