define("adept-iq/models/vehicle-breakdown", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    time: attr('date'),
    description: attr('string'),
    vehicle: belongsTo(),
    place: belongsTo('place', {
      inverse: 'breakdownVehicle'
    }),
    type: belongsTo('breakdown-type')
  });

  _exports.default = _default;
});