define("adept-iq/pods/components/side-drawer/system-config/modals/assign-remove-zones/component", ["exports", "ember-concurrency", "adept-iq/models/zone"], function (_exports, _emberConcurrency, _zone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    // passed in attributes
    modalAPI: null,
    // properties
    assignTableRef: null,
    unassignTableRef: null,
    searchText: null,
    selectedBreakType: null,
    undoZone: false,
    isSearchEnabled: false,
    disableForms: true,
    hasOverlay: true,
    // default to true
    isTaskRunning: false,
    title: 'Assign/Remove Zones',
    availableZones: Ember.computed.readOnly('unassignTableRef.checkedRows'),
    availableRows: Ember.computed.readOnly('unassignTableRef.table.selectedRows'),
    assignedZones: Ember.computed.readOnly('assignTableRef.checkedRows'),
    assignedRows: Ember.computed.readOnly('assignTableRef.table.selectedRows'),

    init() {
      this._super(...arguments);

      this.get('refreshTask').perform();
    },

    didInsertElement() {
      this._super(...arguments);
    },

    onCloseModal() {
      const tooltip = this.get('tooltip');
      const zoneTableRef = this.get('modalAPI.zoneTableRef');
      const oldAffinities = this.get('modalAPI.oldAffinities');
      const affinities = this.get('modalAPI.routeTemplateChangeSet.affinities');
      const everyAffinityIsNull = affinities ? affinities.every(aff => !aff) : true;

      if (!affinities || !affinities.length || everyAffinityIsNull) {
        tooltip.setProperties({
          title: 'System Configuration',
          tip: 'There must be at least one zone assigned to the route in order to save.',
          warning: true,
          primaryActionText: 'OK',

          primaryAction() {
            tooltip.reset();
          }

        });
        return;
      }

      if (oldAffinities.length >= affinities.length) {
        for (let i = 0; i < affinities.length; i++) {
          affinities[i] = oldAffinities[i];
        }
      } else {
        oldAffinities.forEach((affVal, index) => {
          affinities[index] = affVal;
        });
      }

      this.set('modalAPI.routeTemplateChangeSet.affinities', affinities);
      if (zoneTableRef) Ember.run.scheduleOnce('afterRender', zoneTableRef, 'refreshData'); // routeTemplateChangeSet.execute();

      this.set('modalAPI', {
        title: null,
        routeTemplateChangeSet: null,
        zoneTableRef: null,
        oldAffinities: null
      });
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {}),
    onSaveZoneTask: (0, _emberConcurrency.task)(function* () {// yield run.scheduleOnce('afterRender', tableRef, 'refreshData');
    }),
    onRefreshTablesTask: (0, _emberConcurrency.task)(function* () {
      const assignTableRef = this.get('assignTableRef');
      const unassignTableRef = this.get('unassignTableRef');
      yield Ember.run.scheduleOnce('afterRender', this, () => {
        assignTableRef.refreshData();
        unassignTableRef.refreshData();
      });
    }),
    actions: {
      // header button functions
      addZone(routeTemplateChangeSet) {
        const availableZones = this.get('availableZones');
        const availableRows = this.get('availableRows');
        if (!availableZones && !availableZones.length) return;
        const zoneIds = availableZones.map(zone => parseInt(zone.get('id'), 10));
        const affinities = routeTemplateChangeSet.get('affinities') || [];
        const maxZoneId = Math.max(...zoneIds, affinities.length);
        const oldAffinities = this.get('modalAPI.oldAffinities');
        const newSize = Math.max(maxZoneId, affinities.length);
        const newAffinities = Array(newSize).fill(null);
        affinities.forEach((affVal, index) => {
          newAffinities[index] = affVal;
        });
        zoneIds.forEach(zoneId => {
          newAffinities[zoneId] = oldAffinities[zoneId] || _zone.AFFINITY_NORMAL.val; // default to normal
        });
        routeTemplateChangeSet.set('affinities', newAffinities);
        availableRows.setEach('selected', false); // removing "rows" from available zone table and need to set those to false if they are added back

        this.get('onRefreshTablesTask').perform();
      },

      removeZone(routeTemplateChangeSet) {
        const assignedZones = this.get('assignedZones');
        const assignedRows = this.get('assignedRows');
        if (!assignedZones && !assignedZones.length) return;
        const zoneIds = assignedZones.map(zone => parseInt(zone.get('id'), 10));
        const affinities = routeTemplateChangeSet.get('affinities') || [];
        const newSize = Math.max(...zoneIds, affinities.length);
        const newAffinities = Array(newSize).fill(null);
        affinities.forEach((affVal, index) => {
          newAffinities[index] = affVal;
        });
        zoneIds.forEach(zoneId => {
          newAffinities[zoneId] = null;
        });
        routeTemplateChangeSet.set('affinities', newAffinities);
        assignedRows.setEach('selected', false); // removing "row" from assigned zone table

        this.get('onRefreshTablesTask').perform();
      },

      undoZone(routeTemplateChangeSet) {
        const oldAffinities = this.get('modalAPI.oldAffinities');
        routeTemplateChangeSet.set('affinities', oldAffinities);
        this.get('onRefreshTablesTask').perform();
      },

      saveZone() {
        this.get('onSaveZoneTask').perform();
      },

      onCloseModal() {
        this.onCloseModal();
      },

      onClickOverlayModal() {
        const hasOverlay = this.get('hasOverlay');

        if (hasOverlay) {
          return false;
        }

        this.get('queryModal').reset();
      }

    }
  });

  _exports.default = _default;
});