define("adept-iq/serializers/leg-travel-need", ["exports", "adept-iq/serializers/-bs-bookingService"], function (_exports, _bsBookingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsBookingService.default.extend({
    serialize()
    /*snapshot, options*/
    {
      const json = this._super(...arguments);

      const travelNeedTypeNameRelationship = json.data.relationships.travelNeedType;

      if (!Ember.isNone(travelNeedTypeNameRelationship) && !Ember.isNone(travelNeedTypeNameRelationship.data)) {
        travelNeedTypeNameRelationship.data.type = 'travelNeedTypeName';
        json.data.relationships.travelNeedTypeName = json.data.relationships.travelNeedType;
        delete json.data.relationships.travelNeedType;
      }

      const passengerTypeNameRelationship = json.data.relationships.passengerType;

      if (!Ember.isNone(passengerTypeNameRelationship) && !Ember.isNone(passengerTypeNameRelationship.data)) {
        passengerTypeNameRelationship.data.type = 'passengerTypeName';
        json.data.relationships.passengerTypeName = json.data.relationships.passengerType;
        delete json.data.relationships.passengerType;
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.attributes)) {
        if (!Ember.isNone(resourceHash.attributes.travelNeedTypeName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.travelNeedType = {
            data: {
              type: 'travel-need-type',
              id: resourceHash.attributes.travelNeedTypeName
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.attributes.travelNeedTypeName;
        }

        if (!Ember.isNone(resourceHash.attributes.passengerTypeName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.passengerType = {
            data: {
              type: 'passenger-type',
              id: resourceHash.attributes.passengerTypeName
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.attributes.passengerTypeName;
        }
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});