define("adept-iq/pods/components/generic-widgets/base-widget/component", ["exports", "resize-observer-polyfill"], function (_exports, _resizeObserverPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    workspace: Ember.inject.service(),
    classNames: ['dashboard-widget'],
    classNameBindings: ['disabled'],
    // override with per-widget static configuration
    config: null,
    // widget model (see: `app/classes/widget.js`)
    widget: null,
    width: Ember.computed.alias('widget.width'),
    height: Ember.computed.alias('widget.height'),
    state: Ember.computed.alias('widget.state'),
    isEditing: Ember.computed.alias('workspace.isEditing'),
    disabled: Ember.computed.readOnly('isEditing'),

    didInsertElement() {
      this._super(...arguments);

      this._resizeObserver = new _resizeObserverPolyfill.default(() => {
        Ember.run.debounce(this, 'onWidgetResize', 250);
      });

      this._resizeObserver.observe(this.$()[0]);
    },

    willDestroyElement() {
      this._super(...arguments);

      if (this._resizeObserver) {
        this._resizeObserver.disconnect();

        this._resizeObserver = null;
      }
    },

    // override this to resize 3rd party integrations (e.g. map)
    onWidgetResize() {}

  });

  _exports.default = _default;
});