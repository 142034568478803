define("adept-iq/pods/components/iq-widgets/trips-form-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Read Only',
      fields: [{
        id: 'tripId',
        type: 'text',
        label: 'Id',
        valuePath: 'id',
        editable: false
      }, {
        id: 'firstName',
        type: 'text',
        label: 'First Name',
        valuePath: 'leg.rider.firstName'
      }, {
        id: 'lastName',
        type: 'text',
        label: 'Last Name',
        valuePath: 'leg.rider.lastName'
      }]
    }, {
      title: 'Editable',
      fields: [{
        id: 'notes',
        type: 'text',
        label: 'Notes',
        valuePath: 'notes'
      }]
    }, {
      title: 'Origin Addresses',
      fields: [{
        id: 'originaddresses',
        type: 'places',
        label: '',
        valuePath: 'leg.origin',
        modelName: 'location',
        extra: {
          aliasPath: 'alias',
          notesPath: 'notes',
          streetNumberPath: 'streetNumber',
          streetAddressPath: 'streetAddress',
          localityPath: 'locality',
          regionPath: 'region',
          postalCodePath: 'postalCode',
          countryPath: 'country'
        }
      }]
    }, {
      title: 'Destination Addresses',
      fields: [{
        id: 'destinationaddresses',
        type: 'places',
        label: '',
        valuePath: 'leg.destination',
        modelName: 'bs-location',
        extra: {
          aliasPath: 'alias',
          notesPath: 'notes',
          streetNumberPath: 'streetNumber',
          streetAddressPath: 'streetAddress',
          localityPath: 'locality',
          regionPath: 'region',
          postalCodePath: 'postalCode',
          countryPath: 'country'
        }
      }]
    }]
  };
  _exports.default = _default;
});