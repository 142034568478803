define("adept-iq/serializers/route-break", ["exports", "adept-iq/serializers/-ss-schedulingService"], function (_exports, _ssSchedulingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ssSchedulingService.default.extend({
    attrs: {
      type: {
        serialize: false
      }
    },

    serialize()
    /* snapshot, options */
    {
      const json = this._super(...arguments);

      if (Ember.isPresent(json.data.attributes.plannedDurationSeconds)) {
        json.data.attributes.plannedDuration = json.data.attributes.plannedDurationSeconds;
        delete json.data.attributes.plannedDurationSeconds;
      }

      const {
        breakType,
        stopPoint
      } = json.data.relationships;
      breakType.data.type = 'breakTypeName';
      json.data.relationships.breakTypeName = breakType;
      delete json.data.relationships.breakType;

      if (Ember.isPresent(stopPoint)) {
        delete json.data.relationships.stopPoint;
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      if (Ember.isPresent(resourceHash.attributes)) {
        if (Ember.isPresent(resourceHash.attributes.breakTypeName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.breakType = {
            data: {
              type: 'break-type',
              id: resourceHash.attributes.breakTypeName
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.attributes.breakTypeName;
        }

        if (Ember.isPresent(resourceHash.attributes.plannedDuration)) {
          resourceHash.attributes.plannedDuration = Math.floor(resourceHash.attributes.plannedDuration / 60);
        }
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});