define("adept-iq/pods/components/iq-widgets/routes-end-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/routes-end-form-widget/config", "adept-iq/utils/convertDistance"], function (_exports, _component, _config, _convertDistance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['routes-end-form-widget'],
    editableSections: _config.default.editableSections,
    editModal: Ember.inject.service(),

    validateRecords() {
      let valid = true;
      const records = this.get('editableRecords') || [];
      this.set('editModal.errors', []);
      records.forEach(record => {
        const odometerInMiles = record.get('odometerInMiles');

        if (odometerInMiles.length <= 0) {
          this.get('editModal.errors').pushObject('Odometer cannot be empty');
          valid = false;
        }

        if (odometerInMiles.length > 0 && isNaN(parseInt(odometerInMiles, 10))) {
          this.get('editModal.errors').pushObject('Odometer must be numeric');
          valid = false;
        }

        if (parseInt(odometerInMiles, 10) >= _convertDistance.AVLM_ODOMETER_MILES_RESTRICTION) {
          this.get('editModal.errors').pushObject(`Odometer must be less than ${_convertDistance.AVLM_ODOMETER_MILES_RESTRICTION}`);
          valid = false;
        } // convert temporary attribute in miles to odometer in meters


        record.set('odometer', (0, _convertDistance.convertMilesToMeters)(odometerInMiles));
      });
      return valid;
    },

    actions: {
      // override undo because we will have to deal with undoing created
      // models for addresses, travel needs, and eligibility later.
      // IQUX-510
      onUndoClick() {},

      onApplyClick() {
        if (this.validateRecords()) {
          const title = this.get('title');
          this.get('service').apply(title);
        }
      }

    }
  });

  _exports.default = _default;
});