define("adept-iq/classes/map-contexts/map-configs/avlm-layers", ["exports", "adept-iq/config/icon-paths", "adept-iq/pods/components/iq-widgets/routes-widget/avlmConfig", "adept-iq/pods/components/iq-widgets/trips-widget/avlmConfig", "adept-iq/pods/components/iq-widgets/stops-widget/avlmConfig", "adept-iq/pods/components/iq-widgets/vehicles-widget/avlmConfig"], function (_exports, _iconPaths, _avlmConfig, _avlmConfig2, _avlmConfig3, _avlmConfig4) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // @TODO: make this JSON-only
  // @TODO: it would be better to look up values from static config at time of use,
  // but for now this handles the differences in config format
  const extractLabelsFromWidgetConfig = widgetConfig => {
    return widgetConfig.columns.map(columnConfig => {
      const {
        id,
        index,
        type,
        label,
        valuePath,
        valuePreview,
        searchable,
        isMapLabelVisible,
        unAvailable
      } = columnConfig;
      return {
        id,
        index,
        type,
        label,
        valuePath,
        valuePreview,
        isSearchable: searchable,
        isVisible: isMapLabelVisible,
        unAvailable: unAvailable
      };
    });
  };

  const layers = [{
    id: 'agency',
    type: 'markers',
    label: 'Agency',
    modelName: 'agency-marker',
    isVisible: false,
    isActive: false,
    // does not respond to active context
    opacity: 1,
    selectedDisplay: 'On Click',
    types: [{
      id: 'location',
      label: 'Agency Location',
      style: 'location',
      isVisible: true,
      display: true,
      canFilter: true
    }],
    labelDisplay: 'select',
    labels: []
  }, {
    id: 'stops',
    type: 'markers',
    label: 'Stops',
    modelName: 'avlm-stop-point',
    isVisible: true,
    isActive: true,
    opacity: 1,
    selectedDisplay: 'On Click',
    types: [{
      id: 'pullinGarage',
      label: 'Pullin Garage',
      filterKey: 'mapId',
      filterValue: 'pullinGarage',
      style: 'garage',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.garagePlanned
    }, {
      id: 'pulloutGarage',
      label: 'Pullout Garage',
      filterKey: 'mapId',
      filterValue: 'pulloutGarage',
      style: 'garage',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.garagePlanned
    }, {
      id: 'break',
      label: 'Break',
      filterKey: 'mapId',
      filterValue: 'break',
      style: 'break',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.break
    }, {
      id: 'deployStop',
      label: 'Deploy Stop',
      filterKey: 'mapId',
      filterValue: 'deployStop',
      style: 'pick',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.planned
    }, {
      id: 'pick',
      label: 'Pickup',
      filterKey: 'mapId',
      filterValue: 'pick',
      style: 'pick',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.pickPlanned
    }, {
      id: 'drop',
      label: 'Drop Off',
      filterKey: 'mapId',
      filterValue: 'drop',
      style: 'drop',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.dropPlanned
    }, {
      id: 'performedStop',
      label: 'Performed Stop',
      filterKey: 'mapId',
      filterValue: 'performedStop',
      style: 'Depart',
      canFilter: true,
      isVisible: true,
      display: true,
      iconPath: _iconPaths.default.stops.pickCompleted
    }, {
      id: 'next',
      label: 'Next Stop',
      filterKey: 'mapId',
      filterValue: 'next',
      style: 'drop',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.pickNext
    }, {
      id: 'wheelchair',
      label: 'Wheelchair Stop',
      filterKey: 'mapId',
      filterValue: 'wheelchair',
      style: 'drop',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.pickPlannedWheelchair
    }, {
      id: 'NextWheelchair',
      label: 'Wheelchair Next Stop',
      filterKey: 'mapId',
      filterValue: 'NextWheelchair',
      style: 'drop',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.pickNextWheelchair
    }, {
      id: 'noshow',
      label: 'No Show Stop',
      filterKey: 'mapId',
      filterValue: 'noshow',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.noshow
    }],
    labelDisplay: 'select',
    labels: extractLabelsFromWidgetConfig(_avlmConfig3.default)
  }, {
    id: 'routes',
    type: 'polylines',
    label: 'Routes',
    modelName: 'avlm-route',
    isVisible: true,
    isActive: true,
    opacity: 1,
    selectedDisplay: 'On Left Click',
    types: [{
      id: 'planned',
      label: 'Day of Service - Planned',
      style: 'solid',
      styleDecorator: 'solidPlanned',
      isVisible: true,
      canFilter: true,
      display: true,
      iconPath: _iconPaths.default.routes.planned,
      linePath: _iconPaths.default.routes.plannedLine
    }, {
      id: 'navigation',
      label: 'Navigation',
      style: 'solid',
      styleDecorator: 'solidNavigation',
      isVisible: true,
      canFilter: true,
      filterKey: 'status',
      filterValue: 'A',
      display: true,
      iconPath: _iconPaths.default.routes.navigation,
      linePath: _iconPaths.default.routes.navigationLine
    }, {
      id: 'performed',
      label: 'Performed',
      // needs to be present for any selected routes, even if it has no data
      // filterKey: 'isPerformed',
      isVisible: false,
      canFilter: true,
      display: true,
      iconPath: _iconPaths.default.routes.performed,
      linePath: _iconPaths.default.routes.performedLine
    }],
    labelDisplay: 'select',
    labels: extractLabelsFromWidgetConfig(_avlmConfig.default)
  }, {
    id: 'trips',
    type: 'polylines',
    label: 'Trips',
    modelName: 'avlm-trip',
    isVisible: true,
    isActive: true,
    opacity: 1,
    selectedDisplay: 'On Left Click',
    types: [{
      id: 'Waitlisted',
      label: 'Waitlist',
      filterKey: 'mapId',
      filterValue: 'Waitlisted',
      style: 'dotted',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.planned
    }, {
      id: 'Scheduled',
      label: 'Scheduled',
      filterKey: 'mapId',
      filterValue: 'Scheduled',
      style: 'dotted',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.planned
    }, {
      id: 'Active',
      label: 'Active',
      filterKey: 'mapId',
      filterValue: 'Active',
      style: 'solid',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.performed
    }, {
      id: 'Completed',
      label: 'Completed',
      filterKey: 'mapId',
      filterValue: 'Completed',
      style: 'solid',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.performed
    }],
    labelDisplay: 'select',
    labels: extractLabelsFromWidgetConfig(_avlmConfig2.default)
  }, {
    id: 'vehicles',
    type: 'markers',
    label: 'Vehicles',
    modelName: 'avlm-vehicle',
    isVisible: true,
    isActive: true,
    selectedDisplay: 'On Left Click',
    opacity: 1,
    types: [{
      id: 'avlm-vehicle-communication-failure',
      label: 'Communication failure',
      filterKey: 'mapId',
      filterValue: 'avlm-vehicle-communication-failure',
      style: 'vehicle',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.vehicles.vehicleCommunicationFailed
    }, {
      id: 'avlm-vehicle-gps-failure',
      label: 'Vehicle failed to ping GPS',
      filterKey: 'mapId',
      filterValue: 'avlm-vehicle-gps-failure',
      style: 'vehicle',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.vehicles.vehicleGPSFailed
    }, {
      id: 'avlm-vehicle-not-signedin',
      label: 'Vehicle not signed-on',
      filterKey: 'mapId',
      filterValue: 'avlm-vehicle-not-signedin',
      style: 'vehicle',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.vehicles.vehicleNotSigned
    }, {
      id: 'avlm-vehicle',
      label: 'Vehicle route started. OTP on time.',
      filterKey: 'mapId',
      filterValue: 'avlm-vehicle',
      style: 'vehicle',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.vehicles.atWork
    }, {
      id: 'avlm-vehicle-danger',
      label: 'Vehicle route started. OTP getting late.',
      filterKey: 'mapId',
      filterValue: 'avlm-vehicle-danger',
      style: 'vehicle',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.vehicles.atWorkDanger
    }, {
      id: 'avlm-vehicle-late',
      label: 'Vehicle route started. OTP late.',
      filterKey: 'mapId',
      filterValue: 'avlm-vehicle-late',
      style: 'vehicle',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.vehicles.atWorkLate
    }, {
      id: 'avlm-vehicle-emergency',
      label: 'Vehicle Emergency',
      filterKey: 'mapId',
      filterValue: 'avlm-vehicle-emergency',
      style: 'vehicle',
      isVisible: true,
      display: true,
      canFilter: false,
      iconPath: _iconPaths.default.vehicles.atWorkEmergency
    }, {
      id: 'avlm-vehicle-break',
      label: 'Vehicle on break',
      filterKey: 'mapId',
      filterValue: 'avlm-vehicle-break',
      style: 'vehicle',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.vehicles.onBreak
    }, {
      id: 'avlm-vehicle-breakdown',
      label: 'Vehicle broken down',
      filterKey: 'mapId',
      filterValue: 'avlm-vehicle-breakdown',
      style: 'vehicle',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.vehicles.vehicleBreakdown
    }, {
      id: 'avlm-vehicle-roadsup',
      label: 'Road Supervisor',
      filterKey: 'mapId',
      filterValue: 'avlm-vehicle-roadsup',
      style: 'vehicle',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.vehicles.loggedRoadSupervisor
    }],
    labelDisplay: 'select',
    labels: extractLabelsFromWidgetConfig(_avlmConfig4.default)
  }];
  var _default = layers;
  _exports.default = _default;
});