define("adept-iq/pods/components/reoptimize-modal/operation-views/remove-driver-break/component", ["exports", "ember-concurrency", "lodash", "adept-iq/utils/unwrapProxy"], function (_exports, _emberConcurrency, _lodash, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const REMOVE_DRIVER_BREAK_FAILED = 'Failed';

  var _default = Ember.Component.extend({
    classNames: ['remove-driver-break'],
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    // Passed In Attributes
    title: null,
    currentSchedule: null,
    tableRef: null,
    toggleDisableActions: null,
    isTaskRunning: Ember.computed.or('removeSelectedRouteDriverBreaks.isRunning', 'hypoRemoveBreakTask.isRunning'),
    checkedRows: Ember.computed.readOnly('tableRef.checkedRows'),
    hasRowsSelected: Ember.computed.lt('checkedRows.length', 1),
    disableApplyButton: Ember.computed.or('hasRowsSelected', 'isTaskRunning'),

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('currentAPITask', this.get('removeSelectedRouteDriverBreaks'));
      this.hypoRemoveBreakTask.perform();
    },

    hypoRemoveBreakTask: (0, _emberConcurrency.task)(function* () {
      const currentSchedule = this.get('currentSchedule');
      const store = this.get('store');
      const scheduleAdapter = store.adapterFor('schedule');

      try {
        const {
          results
        } = yield scheduleAdapter.hypoRemoveBreak(currentSchedule);
        const result = JSON.parse(results);
        result.shortRoutes.forEach(shortRoute => {
          const route = store.peekRecord('route', shortRoute.routeId);

          if (route) {
            route.setProperties({
              trimStartTime: shortRoute.adjustedStartTime,
              trimEndTime: shortRoute.adjustedEndTime
            });
          }
        });
      } catch (e) {
        this.get('notifications').warning('Failed to retrieve short routes');
      }
    }),

    /**
     *remove route-breaks to a selected routes
     *
     */
    removeSelectedRouteDriverBreaks: (0, _emberConcurrency.task)(function* (routes) {
      yield this.deleteDriverBreaks(routes);
    }).drop(),

    deleteDriverBreaks(routes) {
      const tableRef = this.get('tableRef');
      const store = this.get('store');
      const routeBreakAdapter = store.adapterFor('route-break');
      const routeBreaks = this.getRouteBreaks(routes);
      let failedRoutbreaksCount = 0;
      routeBreaks.reduce(async (previousPromise, driverBreak) => {
        const routeBreakId = Number(driverBreak.content.currentState[0].id);
        return routeBreakAdapter.deleteRouteBreak(routeBreakId).catch(() => {
          ++failedRoutbreaksCount;
          const route = (0, _unwrapProxy.unwrapProxy)(driverBreak.content.firstObject.route);
          route.set('result', REMOVE_DRIVER_BREAK_FAILED);
          return Promise.reject();
        });
      }, Promise.resolve()).then(() => {
        this.get('notifications').success('Driver breaks successfully removed');
      }).catch(() => {
        this.get('notifications').warning(`${failedRoutbreaksCount} Driver breaks failed to be removed`);
      }).finally(() => {
        routes.filter(r => Ember.isEmpty(r.result)).forEach(route => route.set('isDriverBreakRemoved', true));
        tableRef.refreshData(); // driver breaks removed or failed re-fetch  the records

        tableRef.onUncheckAll();
      });
    },

    getRouteBreaks(routes) {
      const routeBreaks = (0, _lodash.flatten)(routes.map(route => route.routeBreaks));
      return routeBreaks.filter(routeBreak => routeBreak.content.currentState.length);
    },

    actions: {
      onRemoveClick() {
        const routes = this.get('checkedRows') || [];
        this.removeSelectedRouteDriverBreaks.perform(routes);
      }

    }
  });

  _exports.default = _default;
});