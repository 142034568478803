define("adept-iq/serializers/application", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    JSONAPISerializer
  } = _emberData.default;

  var _default = JSONAPISerializer.extend({
    modelPrefix: '',

    keyForAttribute(key) {
      return key;
    },

    keyForRelationship(key) {
      return key;
    },

    // deprecated; will change to `payloadTypeFromModelName`
    payloadKeyFromModelName(modelName) {
      const modelPrefix = this.get('modelPrefix');
      let currModelName = modelName;

      if (Ember.isPresent(modelPrefix) && currModelName.startsWith(`${modelPrefix}-`)) {
        currModelName = currModelName.slice(modelPrefix.length + 1);
      }

      return Ember.String.camelize(currModelName);
    },

    // deprecated; will change to `modelNameFromPayloadType`
    modelNameFromPayloadKey(key) {
      const modelPrefix = this.get('modelPrefix');
      let modelName = Ember.String.dasherize((0, _emberInflector.singularize)(key));

      if (Ember.isPresent(modelPrefix) && !modelName.startsWith(`${modelPrefix}-`)) {
        modelName = `${modelPrefix}-${modelName}`;
      }

      return modelName;
    }

  });

  _exports.default = _default;
});