define("adept-iq/serializers/vehicle", ["exports", "adept-iq/serializers/-vehicle"], function (_exports, _vehicle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _vehicle.default.extend({
    attrs: {
      xmin: {
        serialize: false
      },
      serviceStatus: {
        serialize: false
      },
      lastGpsPing: {
        serialize: false
      },
      mileage: {
        serialize: false
      },
      otp: {
        serialize: false
      },
      breakdownDate: {
        serialize: false
      },
      etaRoute: {
        serialize: false
      },
      revenueVehicle: {
        serialize: false
      } //disabled until backend supports it

    },

    serialize()
    /* snapshot, options */
    {
      const json = this._super(...arguments); // delete json.data.relationships.startGarage;
      // delete json.data.relationships.endGarage;


      if (json.data && json.data.relationships) {
        delete json.data.relationships.breakdownType;
        delete json.data.relationships.iqVehicle;
        const providerRelationship = json.data.relationships.provider;

        if (!Ember.isNone(providerRelationship)) {
          providerRelationship.data.type = 'providerName';
          json.data.relationships.providerName = json.data.relationships.provider;
          delete json.data.relationships.provider;
        }

        const vehicleTypeRelationship = json.data.relationships.vehicleType;

        if (!Ember.isNone(vehicleTypeRelationship)) {
          vehicleTypeRelationship.data.type = 'vehicleTypeName';
          json.data.relationships.vehicleTypeName = json.data.relationships.vehicleType;
          delete json.data.relationships.vehicleType;
        }

        const startGarageRelationship = json.data.relationships.startGarage;

        if (!Ember.isNone(startGarageRelationship)) {
          json.data.relationships.startGaragePlace = json.data.relationships.startGarage;
          delete json.data.relationships.startGarage;
        }

        const endGarageRelationship = json.data.relationships.endGarage;

        if (!Ember.isNone(endGarageRelationship)) {
          json.data.relationships.endGaragePlace = json.data.relationships.endGarage;
          delete json.data.relationships.endGarage;
        }
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      if (resourceHash.attributes) {
        if (!Ember.isNone(resourceHash.attributes.vehicleTypeName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.vehicleType = {
            data: {
              type: 'vehicle-type',
              id: resourceHash.attributes.vehicleTypeName
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.attributes.vehicleTypeName;
        }

        if (!Ember.isNone(resourceHash.attributes.providerName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.provider = {
            data: {
              type: 'provider',
              id: resourceHash.attributes.providerName
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.attributes.providerName;
        }
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});