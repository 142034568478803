define("adept-iq/pods/components/iq-widgets/passengers-form-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    title: 'Add Passenger',
    editableSections: [{
      title: 'Read Only',
      fields: [{
        id: 'id',
        type: 'text',
        label: 'ID',
        valuePath: 'riderId',
        editable: false
      }, {
        id: 'fullName',
        type: 'text',
        label: 'Full Name',
        valuePath: 'fullName'
      }]
    }, {
      title: 'Personal Information',
      fields: [{
        id: 'firstName',
        type: 'text',
        label: 'First Name',
        valuePath: 'firstName'
      }, {
        id: 'middleName',
        type: 'text',
        label: 'Middle Name',
        valuePath: 'middleName'
      }, {
        id: 'lastName',
        type: 'text',
        label: 'Last Name',
        valuePath: 'lastName'
      }, {
        id: 'dateOfBirth',
        type: 'date',
        format: 'MM/DD/YYYY',
        label: 'Date Of Birth',
        valuePath: 'dateOfBirth'
      }, {
        id: 'areaCode',
        type: 'text',
        label: 'Area Code',
        valuePath: 'riderPhoneNumber.areaCode'
      }, {
        id: 'phoneNumber',
        type: 'text',
        label: 'Phone Number',
        valuePath: 'riderPhoneNumber.phoneNumber'
      }, {
        id: 'notes',
        type: 'text',
        label: 'Passenger Notes',
        valuePath: 'notes'
      }]
    }, {
      title: 'Primary Address',
      fields: [{
        id: 'primaryAddresses',
        type: 'places',
        label: '',
        displayPath: 'primaryAddresses',
        valuePath: 'primaryAddresses',
        modelName: 'rider-place',
        isMultiType: true,
        addressType: 'primary',
        extra: {
          aliasPath: 'place.address.alias',
          notesPath: 'place.address.notes',
          premisePath: 'place.address.premise',
          streetNumberPath: 'place.address.streetNumber',
          streetAddressPath: 'place.address.streetAddress',
          localityPath: 'place.address.locality',
          subLocalityPath: 'place.address.subLocality',
          regionPath: 'place.address.region',
          subRegionPath: 'place.address.subRegion',
          postalCodePath: 'place.address.postalCode',
          countryPath: 'place.address.country',
          locationsPath: 'place.address.locations.firstObject.latlng',
          tomtomFormattedAddressPath: 'place.address.tomtomFormattedAddress',
          useOptionRecord: true
        }
      }]
    }, {
      title: 'Other Addresses',
      fields: [{
        id: 'favoriteAddresses',
        type: 'places',
        label: '',
        displayPath: 'favoriteAddresses',
        valuePath: 'favoriteAddresses',
        modelName: 'rider-place',
        addressType: 'favorite',
        isMultiType: true,
        extra: {
          aliasPath: 'place.address.alias',
          notesPath: 'place.address.notes',
          premisePath: 'place.address.premise',
          streetNumberPath: 'place.address.streetNumber',
          streetAddressPath: 'place.address.streetAddress',
          localityPath: 'place.address.locality',
          subLocalityPath: 'place.address.subLocality',
          regionPath: 'place.address.region',
          subRegionPath: 'place.address.subRegion',
          postalCodePath: 'place.address.postalCode',
          countryPath: 'place.address.country',
          locationsPath: 'place.address.locations.firstObject.latlng',
          tomtomFormattedAddressPath: 'place.address.tomtomFormattedAddress',
          useOptionRecord: true
        }
      }]
    }, {
      title: 'Travel Needs',
      fields: [{
        id: 'travelNeeds',
        type: 'travel-needs',
        label: '',
        valuePath: 'riderTravelNeeds',
        modelName: 'rider-travel-need',
        isMultiType: true,
        extra: {
          travelNeedTypeModelName: 'travel-need-type',
          passengerTypeModelName: 'passenger-type',
          countPath: 'count',
          travelNeedTypePath: 'travelNeedType',
          passengerTypePath: 'passengerType'
        }
      }]
    }, {
      title: 'Eligibilities',
      fields: [{
        id: 'eligibilities',
        type: 'eligibilities',
        label: '',
        valuePath: 'riderEligibilities',
        modelName: 'rider-eligibility',
        isMultiType: true,
        extra: {
          eligibilityTypeModelName: 'eligibility-type',
          fromPath: 'from',
          toPath: 'to',
          eligibilityTypePath: 'eligibilityType'
        }
      }]
    }]
  };
  _exports.default = _default;
});