define("adept-iq/utils/encoding", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseTime = _exports.parseDate = _exports.parseInteger = _exports.parseNumber = _exports.parseBoolean = _exports.parseString = _exports.isEncodedTime = _exports.isEncodedDate = _exports.isEncodedNumber = _exports.isEncodedBoolean = _exports.isNonEmptyString = _exports.isDate = _exports.isString = _exports.isNumber = void 0;
  const TIME_PARSING_FORMATS = ['h:mm A', 'HH:mm']; // strict type validations

  const isString = s => Ember.typeOf(s) === 'string';

  _exports.isString = isString;

  const isNumber = x => Ember.typeOf(x) === 'number' && !isNaN(x);

  _exports.isNumber = isNumber;

  const isDate = date => {
    const prototypeString = Object.prototype.toString.call(date);
    return prototypeString === '[object Date]' && !isNaN(date);
  }; // used to check if a user-supplied string represents a valid filter value


  _exports.isDate = isDate;

  const isNonEmptyString = s => isString(s) && Ember.isPresent(s);

  _exports.isNonEmptyString = isNonEmptyString;

  const isEncodedBoolean = s => s === 'true' || s === 'false';

  _exports.isEncodedBoolean = isEncodedBoolean;

  const isEncodedNumber = s => isNonEmptyString(s) && !isNaN(parseFloat(s));

  _exports.isEncodedNumber = isEncodedNumber;

  const isEncodedDate = s => isNonEmptyString(s) && (0, _moment.default)(s).isValid();

  _exports.isEncodedDate = isEncodedDate;

  const isEncodedTime = s => {
    return isNonEmptyString(s) && (0, _moment.default)(s, TIME_PARSING_FORMATS).isValid();
  }; // use to coerce a string to the given type


  _exports.isEncodedTime = isEncodedTime;

  const parseString = str => str;

  _exports.parseString = parseString;

  const parseNumber = str => parseFloat(str);

  _exports.parseNumber = parseNumber;

  const parseInteger = str => parseInt(str, 10);

  _exports.parseInteger = parseInteger;

  const parseBoolean = str => str === 'true';

  _exports.parseBoolean = parseBoolean;

  const parseDate = str => (0, _moment.default)(str).toDate();

  _exports.parseDate = parseDate;

  const parseTime = str => (0, _moment.default)(str, TIME_PARSING_FORMATS).toDate();

  _exports.parseTime = parseTime;
});