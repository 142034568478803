define("adept-iq/utils/filters", ["exports", "adept-iq/config/filter-types"], function (_exports, _filterTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.testFilterValues = testFilterValues;
  _exports.buildFilterFunction = buildFilterFunction;
  _exports.buildCompoundFilterNode = _exports.buildValueFilterNode = void 0;

  const alwaysTrue = () => true;

  function testFilterValues(filterType, values) {
    if (!filterType || filterType === _filterTypes.nullFilter) return false;
    const {
      arity,
      isValue,
      parseValue,
      validateValues
    } = filterType;

    if (!values) {
      if (arity === '+') return false;
      if (Ember.typeOf(arity) === 'number' && arity > 0) return false;
      if (validateValues) return validateValues([]);
      return true;
    }

    if (arity === '+' && values.length === 0) return false;
    if (Ember.typeOf(arity) === 'number' && values.length < arity) return false;
    let args = values.slice(0, arity);
    if (isValue && !args.every(isValue)) return false;

    if (parseValue) {
      args = args.map(parseValue);
    }

    if (validateValues) return validateValues(args);
    return true;
  }

  const buildValueFilterNode = (filterType, path, values, dateFormat) => {
    if (!filterType || filterType === _filterTypes.nullFilter) return null;
    /*eslint-disable */

    if (Ember.isNone(values)) {
      values = [];
    } else if (Ember.typeOf(values) !== 'array') {
      values = [values];
    }
    /*eslint-enable */


    return {
      type: filterType.id,
      rql: {
        path,
        values
      },
      local: {
        dateFormat,
        path,
        values
      }
    };
  };

  _exports.buildValueFilterNode = buildValueFilterNode;

  const buildCompoundFilterNode = (type, args) => {
    if (Ember.isEmpty(args)) return null;
    /*eslint-disable */

    args = args.compact();
    /*eslint-enable */

    if (args.length === 0) return null;
    if (args.length === 1) return args[0];
    return {
      type,
      args
    };
  };

  _exports.buildCompoundFilterNode = buildCompoundFilterNode;

  const buildValueFilterFunction = node => {
    if (!node) return alwaysTrue;
    const {
      type,
      local: {
        dateFormat,
        path,
        values
      }
    } = node;
    const filterType = _filterTypes.filterTypesHash[type];
    const fn = filterType.fn;

    const filterFunction = x => {
      const targetValue = x.get(path);
      return fn(targetValue, values, dateFormat);
    };

    return filterFunction;
  };

  const buildCompoundFilterFunction = ({
    type,
    args
  }) => {
    if (!args) return alwaysTrue;
    /*eslint-disable */

    const functions = args.map(buildFilterFunction).compact();
    /*eslint-enable */

    if (functions.length === 0) return alwaysTrue;
    if (functions.length === 1) return functions[0];

    switch (type) {
      case 'and':
        return x => functions.every(f => f(x));

      case 'or':
        return x => functions.any(f => f(x));

      default:
        /*eslint-disable */
        throw 'invalid compound filter type';

      /*eslint-enable */
    }
  };

  function buildFilterFunction(node) {
    if (!node) return alwaysTrue;

    switch (node.type) {
      case 'and':
      case 'or':
        return buildCompoundFilterFunction(node);

      default:
        return buildValueFilterFunction(node);
    }
  }
});