define("adept-iq/topic-transforms/avlm-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transform;
  _exports.EMERGENCY_PRIORITY_CODE = _exports.CANNED_RESPONSE_TYPES = void 0;
  // these must be kept in sync with `models/avlm-emergency-message.js`
  const CANNED_RESPONSE_TYPES = {
    'X': 'None',
    'A': 'Acknowledge',
    'Y': 'Yes/No',
    'C': 'Cancel',
    'R': 'Resolve'
  };
  _exports.CANNED_RESPONSE_TYPES = CANNED_RESPONSE_TYPES;
  const EMERGENCY_PRIORITY_CODE = 'E';
  _exports.EMERGENCY_PRIORITY_CODE = EMERGENCY_PRIORITY_CODE;

  function transform(payload, ENV) {
    payload.data.type = 'avlm-canned-message';
    const transformedPayloads = [];
    const {
      replyToMessage
    } = payload.data.relationships;

    if (payload.data.attributes.priority === EMERGENCY_PRIORITY_CODE) {
      payload.data.type = 'avlm-emergency-message';
    }

    if (replyToMessage) {
      delete payload.data.relationships.replyToMessage;
      payload.data.attributes.replyToMsgId = replyToMessage.data.id;
    } else {
      payload.data.attributes.replyToMsgId = payload.data.id;
    }

    payload.data.attributes.isNewMessageReceived = true;
    payload.data.attributes.responseType = {
      status: CANNED_RESPONSE_TYPES[payload.data.attributes.responseType]
    };

    if (payload.data.relationships) {
      if (payload.data.attributes.ackTime && payload.data.attributes.responseMessage === null) {
        payload.data.attributes.responseMessage = 'Acknowledged';
      }

      if (!payload.data.attributes.ackTime && !payload.data.attributes.responseMessage && payload.data.attributes.responseType.status !== 'None') {
        payload.data.attributes.responseMessage = 'None';
      }

      if (ENV.APP.avlmLite) {
        if (payload.data.relationships.route) {
          const {
            route
          } = payload.data.relationships;
          payload.data.relationships.avlmRoute = route;
          delete payload.data.relationships.route;
        }

        if (payload.data.relationships.driver) {
          const {
            driver
          } = payload.data.relationships;
          payload.data.relationships.avlmDriver = driver;
          delete payload.data.relationships.driver;
        }

        if (payload.data.relationships.vehicle) {
          const {
            vehicle
          } = payload.data.relationships;
          payload.data.relationships.avlmVehicle = vehicle;
          delete payload.data.relationships.route;
        }

        if (payload.data.relationships.trip) {
          const {
            trip
          } = payload.data.relationships;
          payload.data.relationships.avlmTrip = trip;
          delete payload.data.relationships.trip;
        }

        if (payload.data.relationships.routeVehicleDriver) {
          const {
            routeVehicleDriver
          } = payload.data.relationships;
          payload.data.relationships.avlmRouteVehicleDriver = routeVehicleDriver;
          delete payload.data.relationships.routeVehicleDriver;
        }
      }
    }

    transformedPayloads.push(payload);
    return transformedPayloads;
  }
});