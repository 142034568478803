define("adept-iq/pods/components/iq-widgets/vehicles-form-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Read Only',
      fields: [{
        id: 'vehicleId',
        type: 'text',
        label: 'ID',
        valuePath: 'name',
        editable: false
      }]
    }, {
      title: 'Editable',
      fields: [{
        id: 'name',
        type: 'text',
        label: 'Vehicle Id',
        valuePath: 'name'
      }, {
        id: 'type',
        type: 'enum',
        label: 'Type',
        valuePath: 'vehicleType',
        cellDesc: 'vehicleType',
        editable: true,
        hidden: false,
        extra: {
          optionModelName: 'vehicle-type',
          optionSearchPath: 'name'
        }
      }, {
        id: 'noOfAmbulatorySeats',
        type: 'text',
        label: 'Ambulatory Seats',
        valuePath: 'noOfAmbulatorySeats',
        editable: true,
        hidden: false,
        readonlyCell: true
      }, {
        id: 'noOfWheelChairSeats',
        type: 'text',
        label: 'Wheelchair Spaces',
        valuePath: 'noOfWheelChairSeats',
        editable: true,
        hidden: false,
        readonlyCell: true
      }, {
        id: 'provider',
        type: 'enum',
        label: 'Service Provider',
        valuePath: 'provider',
        cellDesc: 'provider',
        editable: true,
        hidden: false,
        extra: {
          optionModelName: 'provider',
          optionSearchPath: 'name'
        }
      }, {
        id: 'revenueVehicle',
        type: 'booleanSlider',
        label: 'Revenue Vehicle',
        valuePath: 'revenueVehicle',
        editable: true,
        hidden: false,
        extra: {
          sliderId: 'vehicle-revenue-switch'
        }
      }, {
        id: 'active',
        type: 'booleanSlider',
        label: 'Active',
        valuePath: 'active',
        editable: true,
        hidden: false,
        extra: {
          sliderId: 'vehicle-active-switch'
        }
      }]
    }, {
      title: 'Garage Addresses',
      fields: [{
        id: 'originaddresses',
        type: 'locations',
        label: 'Starting Garage',
        valuePath: 'startGarageCopy',
        modelName: 'place',
        addressType: 'originlocations',
        isMultiType: true,
        extra: {
          aliasPath: 'address.content.alias',
          notesPath: 'address.content.notes',
          premisePath: 'address.content.premise',
          streetNumberPath: 'address.content.streetNumber',
          streetNamePath: 'address.content.streetAddress',
          localityPath: 'address.content.locality',
          regionPath: 'address.content.region',
          subRegionPath: 'address.content.subRegion',
          postalCodePath: 'address.content.postalCode',
          countryPath: 'address.content.country',
          freeformAddressPath: 'address.content.freeformAddress',
          tomtomFormattedAddressPath: 'address.content.tomtomFormattedAddress',
          useOptionRecord: true,
          streetAddressPath: 'address.content.streetAddress',
          locationsPath: 'address.locations.content.firstObject.latlng'
        }
      }, {
        id: 'destinationaddresses',
        type: 'locations',
        label: 'Ending Garage',
        addressType: 'destinationlocations',
        valuePath: 'endGarageCopy',
        modelName: 'place',
        isMultiType: true,
        extra: {
          aliasPath: 'address.content.alias',
          notesPath: 'address.content.notes',
          premisePath: 'address.content.premise',
          streetNumberPath: 'address.content.streetNumber',
          streetNamePath: 'address.content.streetAddress',
          localityPath: 'address.content.locality',
          regionPath: 'address.content.region',
          subRegionPath: 'address.content.subRegion',
          postalCodePath: 'address.content.postalCode',
          countryPath: 'address.content.country',
          freeformAddressPath: 'address.content.freeformAddress',
          tomtomFormattedAddressPath: 'address.content.tomtomFormattedAddress',
          useOptionRecord: true,
          streetAddressPath: 'address.content.streetAddress',
          locationsPath: 'address.locations.content.firstObject.latlng'
        }
      }]
    }, {
      title: 'Availabilities',
      fields: [{
        id: 'availabilities',
        type: 'scheduleavailabilities',
        label: '',
        valuePath: 'vehicleAvailabilities',
        modelName: 'vehicle-availability',
        isMultiType: true,
        extra: {
          startTimePath: 'startTime',
          endTimePath: 'endTime',
          shiftStartPath: 'localShiftStart',
          shiftEndPath: 'localShiftEnd'
        }
      }]
    }, {
      title: 'Breaks',
      fields: [{
        id: 'shiftBreaks',
        type: 'shift-breaks',
        label: '',
        valuePath: 'shiftBreaks',
        modelName: 'shift-break',
        isMultiType: true,
        extra: {
          startTimePath: 'startTime',
          endTimePath: 'endTime',
          sundayPath: 'sunday',
          mondayPath: 'monday',
          tuesdayPath: 'tuesday',
          wednesdayPath: 'wednesday',
          thursdayPath: 'thursday',
          fridayPath: 'friday',
          saturdayPath: 'saturday',
          shiftBreaksPath: 'shiftBreaks',
          breakTypeModelName: 'break-type',
          shiftBreakTypePath: 'breakType',
          aliasPath: 'address.content.alias',
          notesPath: 'address.content.notes',
          streetNumberPath: 'address.content.streetNumber',
          streetAddressPath: 'address.content.streetAddress',
          streetNamePath: 'address.content.streetAddress',
          localityPath: 'address.content.locality',
          regionPath: 'address.content.region',
          subRegionPath: 'address.content.subRegion',
          postalCodePath: 'address.content.postalCode',
          countryPath: 'address.content.country',
          locationsPath: 'address.locations.content.firstObject.latlng',
          tomtomFormattedAddressPath: 'address.content.tomtomFormattedAddress',
          breakPromisedStartPath: 'shiftStart',
          breakPromisedEndPath: 'shiftEnd',
          breakDurationPath: 'plannedDuration'
        }
      }]
    }]
  };
  _exports.default = _default;
});