define("adept-iq/models/rider", ["exports", "ember-data", "adept-iq/utils/format-text-extension", "moment", "adept-iq/config/environment", "adept-iq/utils/unwrapProxy"], function (_exports, _emberData, _formatTextExtension, _moment, _environment, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    externalId: attr('string'),
    // TRA-255 related; riderId can't be null because kafka takes the new rider before we update the actual riderId as the database Id
    riderId: attr('string', {
      defaultValue: '???'
    }),
    firstName: attr('string'),
    lastName: attr('string'),
    middleName: attr('string'),
    dateOfBirth: attr('date'),
    loadTime: attr('number'),
    unloadTime: attr('number'),
    notes: attr('string'),
    type: attr('string', {
      defaultValue: 'client'
    }),
    legs: hasMany('leg'),
    riderEligibilities: hasMany('rider-eligibility'),
    riderTravelNeeds: hasMany('rider-travel-need'),
    riderPlaces: hasMany('rider-place'),
    riderPhoneNumbers: hasMany('rider-phone-number'),
    segments: Ember.computed('legs.@each.segment', function () {
      const legs = this.get('legs');
      const segments = [];

      if (!Ember.isEmpty(legs)) {
        legs.forEach(leg => {
          // The leg is made up of multiple segments they all should be under the same provider
          const segment = (0, _unwrapProxy.unwrapProxy)(leg.get('segment'));
          if (segment) segments.push(segment);
        });
      }

      return segments;
    }),
    trips: Ember.computed('segments.[]', function () {
      const trips = [];
      const segments = this.get('segments');

      if (!Ember.isEmpty(segments)) {
        segments.forEach(segment => {
          const tripArr = (0, _unwrapProxy.unwrapProxy)(segment.get('trips')).toArray();
          trips.push(...tripArr);
        });
      }

      return trips;
    }),
    providers: Ember.computed('segments.[].trips.firstObject.provider', function () {
      const segments = this.get('segments');
      const providers = [];
      const providerHash = {};

      if (!Ember.isEmpty(segments)) {
        segments.forEach(segment => {
          // The leg is made up of multiple segments they all should be under the same provider
          const provider = (0, _unwrapProxy.unwrapProxy)(segment.get('trips.firstObject.provider')); // the array should be unique to only new providers

          if (provider && !providerHash[provider.get('id')]) {
            providers.push(provider);
            providerHash[provider.get('id')] = true;
          }
        });
      }

      return providers;
    }),
    riderPhoneNumber: Ember.computed('riderPhoneNumbers.[]', function () {
      return this.get('riderPhoneNumbers.firstObject');
    }),
    primaryAddresses: Ember.computed('riderPlaces.[]', function () {
      const riderPlaces = this.get('riderPlaces');
      const primaryAddresses = [];

      if (!Ember.isEmpty(riderPlaces)) {
        riderPlaces.forEach(riderPlace => {
          if (riderPlace.get('riderPlaceTypeName') === 'primary') {
            primaryAddresses.push(riderPlace);
          }
        });
      }

      return primaryAddresses;
    }),
    favoriteAddresses: Ember.computed('riderPlaces.[]', function () {
      const riderPlaces = this.get('riderPlaces');
      const favoriteAddresses = [];

      if (!Ember.isEmpty(riderPlaces)) {
        riderPlaces.forEach(riderPlace => {
          if (riderPlace.get('riderPlaceTypeName') === 'favorite') {
            favoriteAddresses.push(riderPlace);
          }
        });
      }

      return favoriteAddresses;
    }),
    fullName: Ember.computed('firstName', 'middleName', 'lastName', function () {
      return `${Ember.get(this, 'firstName')} ${Ember.get(this, 'middleName') || ''} ${Ember.get(this, 'lastName')}`.replace(/\s+/, ' ');
    }),
    // primaryAddresses: hasMany('address', { inverse: 'ridersPrimary' }),
    // favoriteAddresses: hasMany('address', { inverse: 'ridersFavorite' }),
    equipments: Ember.computed.filterBy('riderTravelNeeds', 'isEquipment', true),
    extraPassengers: Ember.computed.filterBy('riderTravelNeeds', 'isExtraPassenger', true),
    formattedPhoneNumbers: Ember.computed('riderPhoneNumbers.{[],@each.areaCode}', function () {
      const phoneNumbers = [];
      const riderPhoneNumbers = this.get('riderPhoneNumbers');

      if (!Ember.isEmpty(riderPhoneNumbers)) {
        riderPhoneNumbers.forEach(function (item) {
          phoneNumbers.push((0, _formatTextExtension.formatTextExtension)('valueItem', `${item.type}: ${item.get('formattedPhoneNumber')}`));
        });
      }

      return {
        values: phoneNumbers
      };
    }),
    formattedPrimaryAddresses: Ember.computed('primaryAddresses', function () {
      const addresses = [];
      const primaryAddresses = this.get('primaryAddresses');

      if (!Ember.isEmpty(primaryAddresses)) {
        primaryAddresses.forEach(function (item) {
          let address = `${item.get('place.address.streetNumber')} ${item.get('place.address.streetAddress')} ${item.get('place.address.locality')}`;

          if (!Ember.isEmpty(item.get('place.address.premise'))) {
            address = `${item.get('place.address.premise')} - ` + address;
          }

          addresses.push((0, _formatTextExtension.formatTextExtension)('valueItem', address));
        });
      }

      return {
        values: addresses
      };
    }),
    formattedFavoriteAddresses: Ember.computed('favoriteAddresses', function () {
      const addresses = [];
      const favoriteAddresses = this.get('favoriteAddresses');

      if (!Ember.isEmpty(favoriteAddresses)) {
        favoriteAddresses.forEach(function (item) {
          let address = `${item.get('place.address.streetNumber')} ${item.get('place.address.streetAddress')} ${item.get('place.address.locality')}`;

          if (!Ember.isEmpty(item.get('place.address.premise'))) {
            address = `${item.get('place.address.premise')} - ` + address;
          }

          addresses.push((0, _formatTextExtension.formatTextExtension)('valueItem', address));
        });
      }

      return {
        values: addresses
      };
    }),
    formattedProviders: Ember.computed('providers', function () {
      const providers = [];
      const provider = this.get('providers');

      if (!Ember.isEmpty(provider)) {
        provider.forEach(function (item) {
          if (item) {
            const providerName = `${item.get('providerName')}`;
            providers.push((0, _formatTextExtension.formatTextExtension)('valueItem', providerName));
          }
        });
      }

      return {
        values: providers
      };
    }),
    riderAddresses: Ember.computed('primaryAddresses', 'favoriteAddresses', function () {
      const addresses = [];
      const primaryAddresses = this.get('primaryAddresses');

      if (!Ember.isEmpty(primaryAddresses)) {
        primaryAddresses.forEach(function (item) {
          addresses.push(item);
        });
        this.get('favoriteAddresses').forEach(function (item) {
          addresses.push(item);
        });
      }

      return addresses;
    }),
    formattedRiderTravelNeeds: Ember.computed('equipments.@each.{count,travelNeedTypeName}', 'extraPassengers.@each.{count,passengerTypeName}', function () {
      const riderTravelNeeds = [];
      const equipments = this.get('equipments');

      if (!Ember.isEmpty(equipments)) {
        equipments.forEach(function (item) {
          const count = item.get('count');
          const name = item.get('travelNeedType.name');
          const text = (0, _formatTextExtension.formatTextExtension)('valueItem', `${count} ${name}`);
          riderTravelNeeds.push(text);
        });
      }

      const extraPassengers = this.get('extraPassengers');

      if (!Ember.isEmpty(extraPassengers)) {
        extraPassengers.forEach(function (item) {
          const count = item.get('count');
          const name = item.get('passengerType.name');
          const text = (0, _formatTextExtension.formatTextExtension)('valueItem', `${count} ${name}`);
          riderTravelNeeds.push(text);
        });
      }

      return {
        values: riderTravelNeeds
      };
    }),
    formattedRiderEligibilities: Ember.computed('riderEligibilities.@each.{eligibilityTypeName,from,to}', function () {
      const riderEligibilities = this.get('riderEligibilities').map(item => {
        const eligibilityTypeName = item.get('eligibilityTypeName');
        const startMoment = (0, _moment.default)(item.get('from'));
        const endMoment = (0, _moment.default)(item.get('to'));
        const startDate = startMoment.format(_environment.default.dateTimeFormat.dateMoment);
        const endDate = endMoment.format(_environment.default.dateTimeFormat.dateMoment);
        const text = `${eligibilityTypeName} ${startDate} - ${endDate}`;
        return (0, _formatTextExtension.formatTextExtension)('valueItem', text);
      });
      return {
        values: riderEligibilities
      };
    }),
    riderEligibilityDates: Ember.computed('riderEligibilities.@each.{from,to}', function () {
      const dates = [];
      const riderEligibilities = this.get('riderEligibilities');

      if (!Ember.isEmpty(riderEligibilities)) {
        riderEligibilities.forEach(item => {
          const startMoment = {
            value: (0, _moment.default)(item.get('from')).toDate()
          };
          const endMoment = {
            value: (0, _moment.default)(item.get('to')).toDate()
          };
          dates.push(...[startMoment, endMoment]);
        });
      }

      return {
        values: dates
      };
    })
  });

  _exports.default = _default;
});