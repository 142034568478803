define("adept-iq/serializers/avlm-emergency-message", ["exports", "adept-iq/serializers/-avlm-avlmService", "lodash"], function (_exports, _avlmAvlmService, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CANNED_RESPONSE_TYPE_CODES = {
    'None': 'X',
    'Acknowledge': 'A',
    'Yes/No': 'Y'
  };

  var _default = _avlmAvlmService.default.extend({
    attrs: {
      responseMessage: {
        serialize: false
      },
      ackTime: {
        serialize: false
      }
    },

    serialize()
    /* snapshot, options */
    {
      const json = this._super(...arguments);

      const responseType = json.data.attributes.responseType;

      const clone = _lodash.default.cloneDeep(json);

      clone.data.attributes.dispatcher = {
        endPointType: 'sender'
      };
      clone.data.attributes.responseType = {
        statusCode: CANNED_RESPONSE_TYPE_CODES[responseType]
      };
      return clone;
    }

  });

  _exports.default = _default;
});